import React from "react";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import ReactToggle from "react-switch";
import axios from 'axios';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
// @material-ui/core components
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons
import { RecentActorsIcon, NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import ViewArrayIcon from '@material-ui/icons/ViewArray';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

import { formStyle } from "assets/jss/customStyle/allCustomStyle";
import Moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import Checkbox from '@material-ui/core/Checkbox';
import Cities from "../../SetupManagement/PrimaryDataTabs/Cities";
import UserGroups from "../../ItManagement/UserGroups";

var Base_URL = localStorage.BaseURL;
class InActiveInfo extends React.Component {
    constructor(props) {
        debugger;
        super(props);
        this.state = {
            generalError: false,
            /*isBlackList: true,*/
            saveUpdateBtnDisabled:false,
            //InacttiveInfo:[],
            noticeDate: new Date(),
            noticeDateState: "",
            endDate: new Date(),
            endDateState: "",
            inActiveDate: new Date(),
            inActiveDateState:"",
            //  #region Dropdown
            reasonDD: [{ reasonId: -1, reason: "Select" }],
            reasonId: -1,
            reasonIdIndex: 0,
            reasonIdState: "",
            appliedByDD: [{ appliedById: -1, appliedBy:"Select"}],
            appliedById: -1,
            appliedByIdIndex: 0,
            appliedByIdState:"",
            //#endregion
        };
    }
    //#region ComponentDidMount
   componentDidMount() {
        this.loadReasonDD();
       this.loadAppliedByDD();
       this.LoadInactiveInfo();

    }
    loadReasonDD() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/SchoolLeavingReason").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ reasonId: -1, reason: "Select" }];
                    arr = arr.concat(data.studentLeaveReasons);
                    //if (this.state.reasonId !== 0 && this.state.reasonId !== null && this.state.reasonId !== -1) {
                    //    this.setState({ reasonDD: arr });
                    //    var ReasonIndex = this.state.reasonDD.findIndex(o => o.cityId === this.state.cityId);
                    //    this.setState({
                    //        reasonIdIndex: ReasonIndex,
                    //        reasonIdState: 'success'
                    //    })
                    //}
                    //else {
                        this.setState({ reasonDD: arr, reasonId: arr[0]['reasonId'] });
                        this.state.reasonId = arr[0]['reasonId'];
                   /* }*/
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    loadAppliedByDD() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/AppliedBy").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ appliedById: -1, appliedBy: "Select" }];
                    arr = arr.concat(data.schoolLeaveAppliedby);
                    //if (this.state.appliedById !== 0 && this.state.appliedById !== null && this.state.appliedById !== -1) {
                    //    this.setState({ appliedByDD: arr });
                    //    var AppliedIndex = this.state.appliedByDD.findIndex(o => o.appliedById === this.state.appliedById);
                    //    this.setState({
                    //        appliedByIdIndex: AppliedIndex,
                    //        appliedByIdState: 'success'
                    //    })
                    //}
                    /*else {*/
                        this.setState({ appliedByDD: arr, appliedById: arr[0]['appliedById'] });
                        this.state.appliedById = arr[0]['appliedById'];
                    /*}*/
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
   LoadInactiveInfo=()=> {
        debugger
        fetch(Base_URL + "/api/StudentRegistration/InactiveStudent?RegistrationNo=" + this.props.data.RegistrationNo).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({
                        noticeDate: data.studentInactiveData.noticeDate,
                        endDate: data.studentInactiveData.schoolLeavingDate,
                        inActiveDate: data.studentInactiveData.schoolInactiveDate,
                        reasonId: data.studentInactiveData.reasonForLeavingId,
                        appliedById: data.studentInactiveData.reasonOfLeaveForLetterId,
                    })
                    if (this.state.appliedById !== 0 && this.state.appliedById !== null && this.state.appliedById !== -1) {
                        var AppliedIndex = this.state.appliedByDD.findIndex(o => o.appliedById === this.state.appliedById);
                        this.setState({
                            appliedByIdIndex: AppliedIndex,
                            appliedByIdState: 'success'
                        })
                    }
                    if (this.state.reasonId !== 0 && this.state.reasonId !== null && this.state.reasonId !== -1) {
                        var ReasonIndex = this.state.reasonDD.findIndex(o => o.reasonId === this.state.reasonId);
                        this.setState({
                            reasonIdIndex: ReasonIndex,
                            reasonIdState: 'success'
                        })
                    }
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }

    //#endregion
    //#region SaveData
    SaveInactiveInformation = () => {
        debugger;
        this.setState({ saveUpdateBtnDisabled: true });
        var InActiveDate = Moment(this.state.inActiveDate).format('YYYY-MM-DD');
        var NoticeDate = Moment(this.state.noticeDate).format('YYYY-MM-DD');
        var EndDate = Moment(this.state.endDate).format('YYYY-MM-DD');
        axios.post(Base_URL + "/api/StudentRegistration/SaveInactiveInformation?NoticeDate=" + NoticeDate + "&InactiveDate=" + InActiveDate +
            " &SchoolLeaveDate=" + EndDate + "&AppliedBy=" + this.state.appliedById + "&ReasonForLeave=" + this.state.reasonId + "&StudentRegistration=" + this.props.data.RegistrationNo)
            .then(json => {
                debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.vmResponse.status, json.data.vmResponse.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })

    }
//#endregion
    //#region ignored
   
   
    hideAlert() {
        this.setState({
            alert: null
        });
        this.LoadInactiveInfo();
    }
    ShowHide = () => {
        this.setState({
            clearTextBtn: 'none',
        });
    }
   
    //#endregion
    //#region Functions
    verifyDate(value) {
        debugger
        var DateRex = /\d\d\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/;
        if (DateRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type,) {
        debugger;
        switch (type) {
            case "noticeDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        noticeDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        noticeDate: event.target.value
                    });
                }
                break;
            case "endDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        endDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        endDate: event.target.value
                    });
                }
                break;
            case "inActiveDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        inActiveDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        inActiveDate: event.target.value
                    });
                }
                break;
        }
    }
    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
    }
     //#endregion
    validateData = (event) => {
        this.state.generalError = false;
        debugger;
        var InActiveDate = Moment(this.state.inActiveDate).format('YYYY-MM-DD');
        var NoticeDate = Moment(this.state.noticeDate).format('YYYY-MM-DD');
        var EndDate = Moment(this.state.endDate).format('YYYY-MM-DD');
        if (this.state.reasonId < 0) {
            this.setState({ reasonIdState: "error", generalError: true });
            return;
        }
        else if (this.state.appliedById < 0) {
            this.setState({ appliedByIdState: "error", generalError: true });
            return;
        }
        else if (this.state.generalError === true) {
            this.AlertMessage('Error', 'Please enter the value in required fields.')
            return;
        }
        else {
            this.ConfirmMessage("Update Data", "Do you want to update the Student!");
        }
    }
    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveInactiveInformation()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        // this.ClearTextfieldStates();
    }

    //#endregion
    render() {
        const { classes } = this.props;
        const { reasonDD, reasonIdState, reasonIdIndex, appliedByDD, appliedByIdIndex, appliedByIdState
        } = this.state;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Typography color="textPrimary">Admission Challan</Typography>
                        </Breadcrumbs>

                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                </CardHeader>
                <GridContainer>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }} className={classes.mainDiv}>
                        <CardBody>
                            <GridContainer style={{ placeContent: "center" }}>
                                <GridForm xs={12} sm={6} md={4}>
                                    {this.state.alert}
                                    <div style={{ textAlign:"center" }}>
                                    <Card>
                                        <CardHeader color="info" icon>
                                            <h4 className={classes.cardIconTitle}>Inactive Information </h4>
                                        </CardHeader>
                                        <CardBody>
                                                <div style={{ paddingBottom: 50, paddingTop: 30 }}>
                                                    <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <TextField
                                                            success={this.state.noticeDateState === "success"}
                                                            error={this.state.noticeDateState === "error"}
                                                            size="small"
                                                            label="Notice Date"
                                                            id="noticeDate"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={Moment(this.state.noticeDate).format('YYYY-MM-DD')}
                                                            type="date"
                                                            inputProps={{ onChange: event => this.change(event, "noticeDate", "noticeDate") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <TextField
                                                            success={this.state.endDateState === "success"}
                                                             error={this.state.endDateState === "error"}
                                                            size="small"
                                                            label="Leaving Date"
                                                             id="endDate"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={Moment(this.state.endDate).format('YYYY-MM-DD')}
                                                            type="date"
                                                            inputProps={{ onChange: event => this.change(event, "endDate", "endDate") }}
                                                        />
                                                    </GridTextbox>
                                                    </GridContainer>
                                                    <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={12}>
                                                    <Autocomplete
                                                        disableClearable
                                                       id="appliedById"
                                                        size="small"
                                                      options={appliedByDD}
                                                      getOptionLabel={(option) => option.appliedBy}
                                                     value={appliedByDD[appliedByIdIndex]}
                                                     onChange={(event, value) => { this.setDDValue(event, value) }}
                                                     renderInput={(params) => <TextField {...params} success={appliedByIdState === "success"}
                                                     error={appliedByIdState === "error"} label="Applied By" variant="outlined" />}
                                                    />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                success={this.state.inActiveDateState === "success"}
                                                                error={this.state.inActiveDateState === "error"}
                                                                size="small"
                                                                label="Inactive Date"
                                                                id="inActiveDate"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={Moment(this.state.inActiveDate).format('YYYY-MM-DD')}
                                                                type="date"
                                                                inputProps={{ onChange: event => this.change(event, "inActiveDate", "inActiveDate") }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <Autocomplete
                                                                disableClearable
                                                                id="reasonId"
                                                                size="small"
                                                                options={reasonDD}
                                                                getOptionLabel={(option) => option.reason}
                                                                value={reasonDD[reasonIdIndex]}
                                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                renderInput={(params) => <TextField {...params} success={reasonIdState === "success"}
                                                                    error={reasonIdState === "error"} label="Reason For Leaving" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        {/*<GridTextbox xs={12} sm={12} md={3}>*/}
                                                        {/*    <label htmlFor="small-radius-switch">*/}
                                                        {/*        <ReactToggle onChange={(event) => this.handleToggleChange(event, "isBlackList")}*/}
                                                        {/*            checked={isBlackList} onColor="#3b5998" className={classes.toggleStyle}*/}
                                                        {/*            borderRadius={4}*/}
                                                        {/*            handleDiameter={22}*/}
                                                        {/*            uncheckedIcon={<div className={classes.toggleOff}>No</div>}*/}
                                                        {/*            checkedIcon={<div className={classes.toggleOn}>Yes</div>*/}
                                                        {/*            } />*/}
                                                        {/*        <span>Is BlackList</span>*/}
                                                        {/*    </label>*/}
                                                        {/*</GridTextbox>*/}
                                                    </GridContainer>
                                                    <GridContainer>
                                                        </GridContainer>
                                            </div>
                                        </CardBody>
                                        </Card>
                                    </div>
                                </GridForm>

                            </GridContainer>
                                <div style={{ padding: "5px 15px" }}>
                                    <Breadcrumbs className={classes.MuiBreadcrumbsCenter} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "25px", paddingTop: "25px", paddingLeft: "5px" }}>
                                        <div style={{ textAlign: "center", placeContent: "center" }}>
                                        <Button color="facebook" onClick={(event) => { this.validateData(event) }} disabled={this.state.saveUpdateBtnDisabled}>Save</Button>
                                            <Button color="facebook">Print Challan</Button>
                                        </div>
                                    </Breadcrumbs>

                                    <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                                </div>
                          
                        </CardBody>
                    </div>
                </GridContainer>
            </Card>
        );
    }
}
InActiveInfo.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(formStyle)(InActiveInfo);
