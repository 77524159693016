import React, { PureComponent, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ReactToggle from "react-switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { GroupAdd as SupplierIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";

const dateVal = {
    currentDate: new Date().toISOString().substring(0, 10)
};

class FeeChallanDetailReport extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            options: [
                {
                    value: '-- Select report name --',
                },
                {
                    value: 'Matrix',
                },
                {
                    value: 'Master-Detail',

                },
                {
                    value: 'QR-Codes',
                },
                {
                    value: 'Highlight',
                },
                {
                    value: 'Image',
                }
            ]
        };

    }
    handleChange = (event) => {
        this.setState({ value: event.target.value });
        this.name = event.target.value;
    }
    static createMarkup(name) {
        //debugger;
        var Base_URL = '';
        Base_URL = localStorage.getItem('BaseURL');
        if (Base_URL.includes('localhost:58487')) {
            Base_URL = 'http://localhost:56468/';
        }
        var oReq = new XMLHttpRequest();
        oReq.open("GET", Base_URL+'/api/Inventory/ItemStutusWiseReport?dbContext=Lahore', true);
        oReq.onload = function (oEvent) {
            debugger;
            let base64 = JSON.parse(oReq.responseText);
            var ifr = document.getElementById('rpt1');
            ifr.setAttribute('src', 'data:application/pdf;base64,' + base64);
        };
        oReq.onerror = function (e) {
            alert(oReq.statusText);
        };
        oReq.send(null);
        return {
            __html: '<iframe width="100%" height="1000px" src="' + Base_URL+ '/Reports/CrystalReportViewer/Accounts/FeeChallanDetailReport.aspx"></iframe> <br /> <iframe id="rpt1" width="100%" height="1000px"</iframe>'
        };
    }

    static getReport(name) {
        return (
            <div dangerouslySetInnerHTML={FeeChallanDetailReport.createMarkup(name)} />
        )
    }
    loadReport() {
        FeeChallanDetailReport.getReport(this.name);
    }
    render() {
        const { options, value } = this.state;
        let contents = this.name ? FeeChallanDetailReport.getReport('') : <div />;
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <SupplierIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Fee Challan Report</h4>
                    <hr style={{ marginBottom: "3px" }} />
                </CardHeader>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="success" icon>
                                        <h4 className={classes.cardIconTitle}>Search Items</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} lg={3}>
                                                    <Button color="facebook" onClick={this.loadReport}> Load Report </Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={12}>
                                                    <div>
                                                        <div>
                                                            <Fragment>
                                                                <select onChange={this.handleChange} value={value}>
                                                                    {options.map(item => (
                                                                        <option key={item.value} value={item.value}>
                                                                            {item.value}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </Fragment>
                                                        </div>
                                                        {contents}
                                                    </div>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>

        );
    }
}


export default withStyles(extendedFormsStyle)(FeeChallanDetailReport);
