//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class HealthInsurance extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            hiId: 0,
            HealthInsuranceList: [],
            certificateId: "",
            certificateIdState: "",
            fromDate: new Date(),
            fromDateState: "",
            validDate: new Date(),
            validDateState: "",
            planTypeId: "",
            planTypeIdIndex: 0,
            planTypeIdState: "",
            cardStatusId: "",
            cardStatusIdIndex: 0,
            cardStatusIdState: "",
            healthInsuranceId: "",
            healthInsuranceIdIndex: 0,
            healthInsuranceIdState: "",
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            PlanTypeDD: [{ planTypeId: -1, planType: "Select" }],
            CardStatusDD: [{ cardStatusId: -1, cardStatus: "Select" }],
            HealthInsuranceDD: [{ healthInsuranceId: -1, healthInsurance: "Select" }],
            generalError: false, alert: null,
        }
    }
    async componentDidMount() {
        debugger
        this.LoadPlanTypeDD();
        this.LoadCardStatusDD();
        this.LoadHealthInsuranceData();
    }
    async LoadPlanTypeDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/PlanTypeDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ planTypeId: -1, planType: "Select" });
                allList = allList.concat(data.hiPlanTypeDDList);
                this.setState({
                    PlanTypeDD: allList
                });
                if (data.length > 0) {
                    this.setState({
                        planTypeId: allList[0].planTypeId,
                        planTypeIdIndex: 0,
                        planTypeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        planTypeId: allList[0]['planTypeId'],
                        planTypeIdIndex: 0,
                        planTypeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadCardStatusDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/CardStatusDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    CardStatusDD: data.cardStatusDDList
                });
                if (data.cardStatusDDList.length > 0) {
                    this.setState({
                        cardStatusId: data.cardStatusDDList[0].cardStatusId,
                        cardStatusIdIndex: 0,
                        cardStatusIdState: "success"
                    });
                }
                else {
                    this.setState({
                        cardStatusId: 0,
                        cardStatusIdIndex: -1,
                        cardStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadHealthInsuranceDD(planTypeId) {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/HealthInsuranceDD?PlanTypeId=" + planTypeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    HealthInsuranceDD: data.healthInsuranceDDList
                });
                if (data.healthInsuranceDDList.length > 0) {
                    this.setState({
                        healthInsuranceId: data.healthInsuranceDDList[0].healthInsuranceId,
                        healthInsuranceIdIndex: 0,
                        healthInsuranceIdState: "success"
                    });
                }
                else {
                    this.setState({
                        healthInsuranceId: 0,
                        healthInsuranceIdIndex: -1,
                        healthInsuranceIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadHealthInsuranceValue(planTypeId, healthInsuranceId) {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/HealthInsuranceDD?PlanTypeId=" + planTypeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    HealthInsuranceDD: data.healthInsuranceDDList
                });
                if (data.healthInsuranceDDList.length > 0) {
                    this.setState({
                        healthInsuranceId: healthInsuranceId,
                        healthInsuranceIdIndex: this.state.HealthInsuranceDD.findIndex(x => x.healthInsuranceId === healthInsuranceId),
                        healthInsuranceIdState: "success"
                    });
                }
                else {

                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadHealthInsuranceData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadHealthInsuranceData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    HealthInsuranceList: data.healthInsuranceDatadataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    SaveHealthInsuranceData = () => {
        debugger;
        if (this.state.certificateId === "" || this.state.certificateIdState === "error") {
            this.setState({ certificateIdState: "error", generalError: true });
            return;
        }
        if ((this.state.planTypeId == 0) || (this.state.planTypeId == -1) || (this.state.planTypeIdState === "error")) {
            this.setState({ planTypeIdState: "error", generalError: true });
            return;
        }
        else if (this.state.healthInsuranceId === "" || this.state.healthInsuranceId === 0 || this.state.healthInsuranceIdState === "error") {
            this.setState({ healthInsuranceIdState: "error", generalError: true });
            return;
        }
        else if (this.state.cardStatusId === "" || this.state.cardStatusId == 0 || this.state.cardStatusIdState === "error") {
            this.setState({ cardStatusIdState: "error", generalError: true });
            return;
        }
        else if (this.state.fromDate === "" || (this.state.fromDate === null)) {
            this.setState({
                fromDateState: "error", generalError: true
            });
        }
        else if (this.state.validDate === "" || (this.state.validDate === null)) {
            this.setState({
                validDateState: "error", generalError: true
            });
        }
        let HealthInsuranceInfo = {
            EmpHIId: this.state.hiId,
            EmployeeId: this.state.employeeId,
            CertificateNo: this.state.certificateId,
            CardStatusId: this.state.cardStatusId,
            PlanTypeId: this.state.planTypeId,
            HIId: this.state.healthInsuranceId,
            EffectDate: Moment(this.state.fromDate).format('YYYY-MM-DD'),
            ValidDate: Moment(this.state.validDate).format('YYYY-MM-DD'),
        };
        console.log(HealthInsuranceInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeHealthInsurance', HealthInsuranceInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.HealthInsuranceList.find(o => o.empHIId === id);
        if (obj != null) {
            this.setState({
                hiId: obj.empHIId, certificateId: obj.certificateNo, fromDate: Moment(obj.effectDate).format('YYYY-MM-DD'), validDate: Moment(obj.validDate).format('YYYY-MM-DD'), cardStatusIdState: "success", saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block'

            });
            if (this.state.PlanTypeDD.find(x => x.planTypeId === obj.planTypeId) != null) {
                this.setState({
                    planTypeId: obj.planTypeId,
                    planTypeIdIndex: this.state.PlanTypeDD.findIndex(x => x.planTypeId === obj.planTypeId),
                    cardStatusId: obj.cardStatusId,
                    cardStatusIdIndex: this.state.CardStatusDD.findIndex(x => x.cardStatusId === obj.cardStatusId),

                });
                this.LoadHealthInsuranceValue(obj.planTypeId, obj.healthInsuranceId);
            }

            else {
                this.setState({
                    planTypeId: 0,
                    planTypeIdIndex: -1,
                    healthInsuranceId: 0,
                    healthInsuranceIdIndex: -1,
                    cardStatusId: 0,
                    cardStatusIdIndex: -1,

                });
            }
        }

        else {
            this.setState({
                incId: 0,
                incAmount: "",
                insDate: new Date(),
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    AlertMessage(status, msg) {
        debugger
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ),
                saveUpdateBtnDisabled: false,
                saveUpdateBtnDisabled: false,
            });

        }
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    hideAlert() {
        debugger;
        this.setState({
            alert: null,
            hiId: 0,
            saveUpdateBtnText: 'save',
            clearTextBtn: 'none',
            fromDate: new Date(),
            validDate: new Date(),
            certificateId: "",
            healthInsuranceId: 0,
            healthInsuranceIdIndex: -1,

        });
        this.LoadHealthInsuranceData();
        this.LoadPlanTypeDD();
        this.LoadCardStatusDD();
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "planTypeId":
                debugger
                if (value.planTypeId != null) {

                    // }
                    // else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.PlanTypeDD.findIndex(x => x[stateName] === value[stateName]),
                        planTypeId: value.planTypeId,
                        [stateName + "State"]: "success"
                    });

                    //let typeName = this.state.FuelQtyAmountDD.find(x => x[stateName] === value[stateName]).type;
                    //if (typeName === 'Liter' || typeName === 'liter') {
                    //    this.LoadFuelAllowanceDD();
                    //    this.setState({
                    //        hideFuelTypeDD: 'block'
                    //    });
                    //}
                    //else {
                    //    this.setState({
                    //        fuelTypeId: -1,
                    //        fuelTypeIdIndex: 0,
                    //        hideFuelTypeDD: 'none'
                    //    });
                    //  }
                    this.LoadHealthInsuranceDD(value.planTypeId);
                }
                break;
            case "healthInsuranceId":
                debugger
                if (this.state.HealthInsuranceDD.find(x => x.healthInsuranceId === value.healthInsuranceId)) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.HealthInsuranceDD.findIndex(x => x[stateName] === value[stateName]),
                        healthInsuranceId: value.healthInsuranceId,
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.HealthInsuranceDD.findIndex(x => x[stateName] === value[stateName]),
                        healthInsuranceId: value.healthInsuranceId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "cardStatusId":
                debugger
                if (this.state.CardStatusDD.find(x => x.cardStatusId === value.cardStatusId)) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.CardStatusDD.findIndex(x => x[stateName] === value[stateName]),
                        cardStatusId: value.cardStatusId,
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.CardStatusDD.findIndex(x => x[stateName] === value[stateName]),
                        cardStatusId: value.cardStatusId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "certificateId":
                debugger
                if (event.target.value != null) {
                    this.setState({
                        stateName: stateName,
                        certificateId: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        amount: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "fromDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "validDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        validDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { HealthInsuranceList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Plan Type </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <TextField
                                            success={this.state.certificateIdState === "success"}
                                            error={this.state.certificateIdState === "error"}
                                            value={this.state.certificateId}
                                            size="small"
                                            label="Certificate ID"
                                            id="certificateId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "certificateId", "certificateId", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2} >
                                        <Autocomplete
                                            size="small"
                                            options={this.state.PlanTypeDD}
                                            getOptionLabel={(option) => option.planType}
                                            id="planTypeId"
                                            value={this.state.PlanTypeDD[this.state.planTypeIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "planTypeId", "planTypeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Plan Type" variant="outlined"
                                                success={this.state.planTypeIdState === "success"}
                                                error={this.state.planTypeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.HealthInsuranceDD}
                                            getOptionLabel={(option) => option.healthInsurance}
                                            id="healthInsuranceId"
                                            value={this.state.HealthInsuranceDD[this.state.healthInsuranceIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "healthInsuranceId", "healthInsuranceId", value)}
                                            renderInput={(params) => <TextField {...params} label="H.I Plan " variant="outlined"
                                                success={this.state.healthInsuranceIdState === "success"}
                                                error={this.state.healthInsuranceIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.fromDateState === "success"}
                                            error={this.state.fromDateState === "error"}
                                            size="small"
                                            label="Effect From Date"
                                            id="fromDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.validDateState === "success"}
                                            error={this.state.validDateState === "error"}
                                            size="small"
                                            label="Valid Till Date"
                                            id="fromDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.validDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "validDate", "validDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2} >
                                        <Autocomplete
                                            size="small"
                                            options={this.state.CardStatusDD}
                                            getOptionLabel={(option) => option.cardStatus}
                                            id="cardStatusId"
                                            value={this.state.CardStatusDD[this.state.cardStatusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "cardStatusId", "cardStatusId", value)}
                                            renderInput={(params) => <TextField {...params} label="H.I Card Status" variant="outlined"
                                                success={this.state.cardStatusIdState === "success"}
                                                error={this.state.cardStatusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <Button color="facebook" onClick={() => { this.SaveHealthInsuranceData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Fuel Allowance"}
                                    data={HealthInsuranceList}
                                    columns={[
                                        {
                                            name: "empHIId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "certificateNo", label: " Certificate No" },
                                        { name: "planType", label: " Plan Type" },
                                        { name: "healthInsurance", label: "H.I Plan" },
                                        { name: "effectDate", label: "Effect From Date" },
                                        { name: "validDate", label: "Valid Till Date" }
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(HealthInsurance);