
//Import from Globel Component";
import {
          React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
          SearchSharp, AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
          GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
          muiWithToolbar, tbleWithPrint, Moment, ErrorOutline, DateRange
        } from '../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class ManageDueDays extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            studentfineanable: true,
            employeefineanable: true,
        };

    };

    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <DateRange />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>  Manage Due Days</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/library" > Library</Link>
                            <Typography color="textPrimary"> Manage Due Days</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardHeader color="success" icon>
                                                    <h4 className={classes.cardIconTitle}>Student</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Due Days"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                             />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Grace Period"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Overdue Fine Per Day"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                            <label htmlFor="small-radius-switch">
                                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "studentfineanable")}
                                                                    checked={this.state.studentfineanable}
                                                                    onColor="#3b5998"
                                                                    className={classes.toggleStyle}
                                                                    borderRadius={4}
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                    } />
                                                                <hr />
                                                                <span>Fine Enabled</span>
                                                            </label>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook"><SaveOutlined /> Update </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>    
                                    </GridContainer>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardHeader color="success" icon>
                                                    <h4 className={classes.cardIconTitle}>Employee</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Due Days"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Grace Period"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Overdue Fine Per Day"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                            <label htmlFor="small-radius-switch">
                                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "employeefineanable")}
                                                                    checked={this.state.employeefineanable}
                                                                    onColor="#3b5998"
                                                                    className={classes.toggleStyle}
                                                                    borderRadius={4}
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                    } />
                                                                <hr />
                                                                <span>Fine Enabled</span>
                                                            </label>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook"><SaveOutlined /> Update </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(ManageDueDays);

