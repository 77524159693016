//Import from Globel Component";
import {
    React, axios, Moment, Button, SweetAlert, formStyle, withStyles, Autocomplete, TextField, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, GridItem, PictureUpload
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;

class EmployeeCredentials extends React.Component {
    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData[0].EmployeeId),
            emailProfessional: editRegData[0].ProfessionalEmail,
            emailProfessionalState: "",
            userName: editRegData[0].UserPortalLogin,
            userNameState: "",
            password: editRegData[0].EmployeePassword,
            userGroupId: parseInt(editRegData[0].UserGroupId),
            userGroupIdIndex: 0,
            userGroupIdState: "",
            UserGroupDD: [{ userGroupId: -1, userGroupName: "Select" }],
        }
    };
    async componentDidMount() {
        debugger
        this.LoadGroupDD();
    }
    async LoadGroupDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/UserGroupDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ userGroupId: -1, userGroupName: "Select" });
                allList = allList.concat(data.userGroupDDList);
                this.setState({
                    UserGroupDD: allList
                });
                if (allList.length > 0) {
                    if (this.state.userGroupId != 0) {
                        this.setState({
                            userGroupId: this.state.UserGroupDD.find(x => x.userGroupId === this.state.userGroupId).userGroupId,
                            userGroupIdIndex: this.state.UserGroupDD.findIndex(x => x.userGroupId === this.state.userGroupId),
                            userGroupIdState: "success"
                        });
                    }
                    else {
                        this.setState({
                            userGroupId: allList[0]['userGroupId'],
                            userGroupIdIndex: 0,
                            userGroupIdState: "success"
                        });
                    }

                }
                else {
                    this.setState({
                        userGroupId: 0,
                        userGroupIdIndex: -1,
                        userGroupIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    verifyEmail(value) {
        debugger
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ),
                saveUpdateincBtnDisabled: false,
                saveUpdateAlloBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            alert: null,
        });
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.UpdateEmployeeCredential()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, Update it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Employee will be updated!
                </SweetAlert>
            )
        });
    }
    UpdateEmployeeCredential = () => {
        this.hideAlert();
        debugger;
        if ((this.state.userGroupId === -1) || (this.state.userGroupId === "") || (this.state.userGroupId === 0)) {
            this.setState({ userGroupIdState: "error", generalError: true });
            return;
        }
        else if (this.state.emailProfessional === "" || this.state.emailProfessional === null || this.state.emailProfessional === undefined) {
            this.setState({ emailProfessionalState: "error", generalError: true });
            return;
        }
        let EmployeeCredential = {
            EmployeeId: this.state.employeeId,
            UserGroupId: this.state.userGroupId,
            ProfessionalEmail: this.state.emailProfessional,
            EmployeePassword: this.state.password,
            UserPortalLogin: this.state.userName,
        };
        console.log(EmployeeCredential);
        axios.post(Base_URL + '/api/EditNewEmployee/UpdateEmployeeCredential', EmployeeCredential)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "emailProfessional":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        emailProfessional: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        emailProfessional: event.target.value

                    });
                }
                break;
            case "userName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        userName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        userName: event.target.value,
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "password":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        password: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        password: event.target.value,
                    });
                }
                break;
            case "userGroupId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.UserGroupDD.findIndex(x => x[stateName] === value[stateName]),
                    userGroupId: value.userGroupId,
                    [stateName + "State"]: "success"
                })
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { AllowanceList, } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Photo</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <PictureUpload />
                                        <hr style={{ marginBottom: "12px" }} />
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={12} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Credentials</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.emailProfessionalState === "success"}
                                            error={this.state.emailProfessionalState === "error"}
                                            size="small"
                                            label="Email(Professional)"
                                            placeholder="someone@mail.com"
                                            id="emailPersonal"
                                            value={this.state.emailProfessional}
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                onChange: event => this.change(event, "emailProfessional", "emailProfessional")
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.userNameState === "success"}
                                            error={this.state.userNameState === "error"}
                                            size="small"
                                            label="User Name"
                                            id="employeeName"
                                            variant="outlined"
                                            value={this.state.userName}
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "userName", "userName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.UserGroupDD}
                                            getOptionLabel={(option) => option.userGroupName}
                                            id="userGroupId"
                                            value={this.state.UserGroupDD[this.state.userGroupIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "userGroupId", "userGroupId", value)}
                                            renderInput={(params) => <TextField {...params} label="User Group" variant="outlined"
                                                success={this.state.userGroupIdState === "success"}
                                                error={this.state.userGroupIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.passwordState === "success"}
                                            error={this.state.passwordState === "error"}
                                            size="small"
                                            label="Password"
                                            id="password"
                                            variant="outlined"
                                            value={this.state.password}
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "password", "password", value, 3),
                                                type: "password",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.confirmMessageUpdate() }}>Update</Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(EmployeeCredentials);