import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import {
    NavigateNext as NavigateNextIcon,
    LibraryAdd as StdIcon, Home as HomeIcon, DoneAll,
    Receipt, Adjust, Edit, Dehaze, Payment, Search, Create, Pageview, AccountBalance, Money, Note, SearchOutlined,
    AccountBalanceWallet, Exposure, EditAttributes, Equalizer, AccountTree, AccountBox, AccountBalanceWalletOutlined,
    Report, Assessment, MoneyOff, AttachMoney, MonetizationOn, LocalAtm, Queue, Block, Notes, Euro, Security, History, DiscFull,
    ThumbDown, AddToQueue, DevicesOther, Refresh, TrendingUp, TransferWithinAStation, Https, FindInPage, RemoveCircle, Computer, CreditCard, DataUsage
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { events } from "variables/general.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
function handleClick(event) {
    alert("Breadcrumb");
}
class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: events
        };
    }
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <AccountBalanceWalletOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Accounts Management</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Typography color="textPrimary">Accounts</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/ChallanCreation"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <StdIcon />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Fee Challan</p>
                                                <h3 className={classes.cardTitle}>Creation</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <StdIcon />
                                                    Fee Challans Creation
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/multifeepayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <DoneAll />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Multi Fee</p>
                                                <h3 className={classes.cardTitle}> Payment </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <DoneAll />
                                                    Multi Fee Payment
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/otherheadcharges"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <StdIcon />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Other Head</p>
                                                <h3 className={classes.cardTitle}> Charges </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <StdIcon />
                                                    Fee Head Charges
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/finalsettlement"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <Receipt />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Final </p>
                                                <h3 className={classes.cardTitle}> Settlement </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Receipt />
                                                    Final Settlement
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/FeeAdjustmentForm"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Adjust />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Adjustment </p>
                                                <h3 className={classes.cardTitle}>Form </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Adjust />
                                                    Fee Adjustment
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/studentfeestructure"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Edit />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Student Fee </p>
                                                <h3 className={classes.cardTitle}>Structure </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Edit />
                                                    Edit Student Fee Structure
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/brs"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <Dehaze />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>BRS  </p>

                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Dehaze />
                                                    Bank Reconciliation Statement
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/chequeediting"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Payment />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Cheque  </p>
                                                <h3 className={classes.cardTitle}>Editing </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Payment />
                                                    Cheque Editing System
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/createvoucher"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Create />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Create </p>
                                                <h3 className={classes.cardTitle}>Voucher </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Create />
                                                    Create Journal Voucher
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/searchvoucher"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <Search />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Search  </p>
                                                <h3 className={classes.cardTitle}>Voucher </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Search />
                                                    Search Journal Voucher
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/bankpayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <AccountBalance />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Bank  </p>
                                                <h3 className={classes.cardTitle}>Payment </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <AccountBalance />
                                                    Bank Payment
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/bankreceipt"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <Receipt />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Bank  </p>
                                                <h3 className={classes.cardTitle}>Receipt </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Receipt />
                                                    Create Bank Receipt
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/cashpayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Money />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Cash  </p>
                                                <h3 className={classes.cardTitle}>Payment </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Money />
                                                    Create Cash Payment
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/cashreceipt"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Receipt />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Cash  </p>
                                                <h3 className={classes.cardTitle}>Receipt </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Receipt />

                                                    Create Cash Receipt
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/searchreceipt"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <Pageview />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Search </p>
                                                <h3 className={classes.cardTitle}>Receipt </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Pageview />
                                                    Search Receipt
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/challanmanagement"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <Note />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Challan  </p>
                                                <h3 className={classes.cardTitle}>Management </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Note />

                                                    Challan Data Manage
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/accountsmanagement"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Receipt />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Accounts  </p>
                                                <h3 className={classes.cardTitle}>Mangement </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Receipt />

                                                    Accounts Mangement
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/feeinstallment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <DataUsage />
                                                </CardIcon>
                                                <p className={classes.cardCategory}> Fee</p>
                                                <h3 className={classes.cardTitle}>Installment </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <DataUsage />
                                                    Fee Installment
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                {/*<GridItem xs={12} sm={6} md={6} lg={3} >*/}
                                {/*    <Link from={"/"} to={"/app/createsinglechallan"} style={{ textDecoration: 'none' }}>*/}
                                {/*        <Card>*/}
                                {/*            <CardHeader color="danger" stats icon>*/}
                                {/*                <CardIcon color="danger">*/}
                                {/*                    <AccountBalanceWallet />*/}
                                {/*                </CardIcon>*/}
                                {/*                <p className={classes.cardCategory}> Create Single</p>*/}
                                {/*                <h3 className={classes.cardTitle}>Challan </h3>*/}
                                {/*            </CardHeader>*/}
                                {/*            <CardFooter stats>*/}
                                {/*                <div className={classes.stats}>*/}
                                {/*                    <AccountBalanceWallet />*/}
                                {/*                    Create Single Challan*/}
                                {/*                </div>*/}
                                {/*            </CardFooter>*/}
                                {/*        </Card>*/}
                                {/*    </Link>*/}

                                {/*</GridItem>*/}
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/taxcalculator"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Exposure />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Tax </p>
                                                <h3 className={classes.cardTitle}>Calculator </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Exposure />
                                                    Tax Calculator
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/SettlementStructureEditing"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <EditAttributes />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Settlement Structure </p>
                                                <h3 className={classes.cardTitle}>Editing </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <EditAttributes />
                                                    Settlement Structure Editing
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/AuditedTrialBalance"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Equalizer />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Audited </p>
                                                <h3 className={classes.cardTitle}>Trail Balance </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Equalizer />
                                                    Audited Trail Balance
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><AccountBalanceWalletOutlined style={{ marginBottom: "-5px" }} /> Accounts Administration</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/PrimaryDataAccounts"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <AccountTree />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Primary </p>
                                                <h3 className={classes.cardTitle}>Data </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <AccountTree />
                                                    Primary Data Accounts
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/ledgerrights"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <AccountBox />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Ledger </p>
                                                <h3 className={classes.cardTitle}>Rights </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <AccountBox />
                                                    Ledger Accounts Rights
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><AccountBalanceWalletOutlined style={{ marginBottom: "-5px" }} />Fee Creation Report</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Challanmonthlybalance"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AccountBalance style={{ color: 'Green', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Challan Monthly Balance</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Missingchallan"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Report style={{ color: 'Spring Green', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Missing Challan</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feechallandetail"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Assessment style={{ color: 'Turquoise', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Fee Challan Detail</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Admissionvouchernotpaid"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AccountBalanceWallet style={{ color: 'DeepPink', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Admission Voucher Not Paid </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Monthlyfeechallan"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AccountBalanceWallet style={{ color: 'DeepPink', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Monthly Fee Challan </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Latefeefine"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <MoneyOff style={{ color: 'Teal', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Late Fee Fine </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Studentpacakagesdiscount"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AttachMoney style={{ color: 'green', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Student Pacakages Discount </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/studentfeestructure"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Create style={{ color: 'Tan	', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Student Fee structure </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feeadjustmentlog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Adjust style={{ color: 'Magenta	', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Fee Adjustment Log </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Finalsettlementunpassed"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <MonetizationOn style={{ color: 'Gray	', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Final Settlement Unpassed </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feepayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <LocalAtm style={{ color: 'Lime	', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Fee Payment </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><AccountBalanceWalletOutlined style={{ marginBottom: "-5px" }} />Fee Collection Report</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feecollection"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Money style={{ color: 'OrangeRed', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Fee Collection </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feecollectionnew"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Queue style={{ color: 'BlueViolet', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Fee Collection(New) </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feedefaulter"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Block style={{ color: 'Dark Magenta', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Fee Defaulter </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feedefaultersumary"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Notes style={{ color: 'Purple', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Fee Defaulter Summary </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Advancefeepayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Payment style={{ color: 'MediumVioletRed', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Advance Fee Payment </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Monthlyfeepayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Euro style={{ color: 'OliveDrab', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center> Monthly Fee Payment </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Dailybankpayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AccountBalance style={{ color: 'mediumblue', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Daily Bank Payment</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Securityfeepayment"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Security style={{ color: 'Teal', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Security Fee Payment</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Allstudentpayementhistory"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <History style={{ color: 'DodgerBlue', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>All Student Payement History</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Discountapproval"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <DiscFull style={{ color: 'Navy', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Discount Approval</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><AccountBalanceWalletOutlined style={{ marginBottom: "-5px" }} />Other Report</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Employeededuction"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <ThumbDown style={{ color: 'violetred', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Employee Deduction</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Ledger"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Note style={{ color: 'SaddleBrown', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Ledger</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Trialbalance"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AddToQueue style={{ color: 'DarkViolet', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Trial Balance</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Otherfeeheads"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <DevicesOther style={{ color: 'lightcoral', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Other Fee Heads</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Readmission"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Refresh style={{ color: 'indianred4', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Readmission</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Finalsettlementedit"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Edit style={{ color: 'maroon', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Final Settlement Edit</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Revenue"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <TrendingUp style={{ color: 'mediumpurple', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Revenue</center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Transactionbook"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <TransferWithinAStation style={{ color: 'violetred', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Transaction Book </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Securityfee"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Https style={{ color: 'magenta', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Security Fee </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Ledgersummary"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <FindInPage style={{ color: 'darkorchid', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Ledger Summary </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Feebilled"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Receipt style={{ color: 'midnightblue', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Fee Billed</center></h3>
                                            <p className={classes.cardCategory}><center> Report </center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Finalsettlemetremoval"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <RemoveCircle style={{ color: 'orangered', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Final Settlemet Removal </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/FeeChallanDetailReport"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Computer style={{ color: 'ForestGreen', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Bank Reconciliation Statement </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Expense"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <CreditCard style={{ color: 'Blue', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}> <center>Expense </center></h3>
                                            <p className={classes.cardCategory}><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
}
Accounts.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(Accounts);
