//Import from Globel Component";
//import {
//    CardBody
//} from '.../../components/Common/ImportAll';
//import React, {useState } from "react";
//import { formStyle, muiWithToolbar, tbleWithPrint } from "assets/jss/customStyle/allCustomStyle";
//import withStyles from "@material-ui/core/styles/withStyles";
//import Checkbox from "@material-ui/core/Checkbox";
//import InputLabel from "@material-ui/core/InputLabel";
//import ListItemIcon from "@material-ui/core/ListItemIcon";
//import ListItemText from "@material-ui/core/ListItemText";
//import MenuItem from "@material-ui/core/MenuItem";
//import FormControl from "@material-ui/core/FormControl";
//import Select from "@material-ui/core/Select";
//import { MenuProps, useStyles, options } from "components/Card/utils.jsx";

//new try

import { withStyles, formStyle, GridTextbox, Autocomplete, TextField} from '.../../components/Common/ImportAll';
import * as React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;
//const MenuProps = {
//    PaperProps: {
//        style: {
//            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//            width: 250,
//        },
//    },
//};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];
/*const [personName, setPersonName] = React.useState([]);*/
var Base_URL = localStorage.BaseURL;
class EmployeeLetters extends React.Component {
    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            personName: [],
            AllDedDD: [{ allDedId: -1, allDedName: "Select" }],
            allDedId: "",
            allDedIdIndex: 0,
            allDedIdState: "",
        }
    }
    handleChange = (event) => {
        debugger;
        //const {
        //    target: { value },
        //} = event;
        let person = event.target.value;
        if (typeof person === 'string') {
            var p = person.split(',');
            this.setState({
                personName: p
            })
        }
        else {
            this.setState({
                personName: event.target.value
            })
        }
        //setPersonName(
        //    // On autofill we get a stringified value.
        //    typeof value === 'string' ? value.split(',') : value,
        //);
        /* };*/
}
    componentDidMount() {
        this.LoadAllowanceDeductionDD();
    }
    async LoadAllowanceDeductionDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/AllowanceDeductionDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AllDedDD: data.allowanceDeductionDDList
                });
                if (data.allowanceDeductionDDList.length > 0) {
                    this.setState({
                        allDedId: data.allowanceDeductionDDList[0].allDedId,
                        allDedIdState: "success"
                    });
                }
                else {
                    this.setState({
                        allDedId: 0,
                        allDedIdIndex: -1,
                        allDedIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    render() {
        const { classes } = this.props;
        const { personName } = this.state;
//        const [personName, setPersonName] = React.useState([]);
//const handleChange = (event) => {
//    const {
//        target: { value },
//    } = event;
//    setPersonName(
//        // On autofill we get a stringified value.
//        typeof value === 'string' ? value.split(',') : value,
//    );
//};
        return (
            <div>
                {/*<FormControl sx={{ m: 1, width: 300 }}>*/}
                {/*    <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelId="demo-multiple-checkbox-label"*/}
                {/*        id="demo-multiple-checkbox"*/}
                {/*        multiple*/}
                {/*        value={personName}*/}
                {/*        onChange={this.handleChange}*/}
                {/*        //input={<EditOutlined label="Tag" />}*/}
                {/*        renderValue={(selected) => selected.join(', ')}*/}
                {/*        MenuProps={MenuProps}*/}
                {/*    >*/}
                {/*        {names.map((name) => (*/}
                {/*            <MenuItem key={this.state.allDedId} value={this.state.allDedName}>*/}
                {/*                <Checkbox checked={personName.indexOf(allDedName) > -1} />*/}
                {/*                <ListItemText primary={name} />*/}
                {/*            </MenuItem>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}

                <GridTextbox xs={12} sm={12} md={4}>
                    <Autocomplete
                        size="small"
                        options={this.state.AllDedDD}
                        getOptionLabel={(option) => option.allDedName}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={this.state.AllDedDD[this.state.allDedIdIndex]}
                        disableClearable={true}
                        onChange={(event, value) => this.handleChange(event, "allDedId", "allDedId", value)}
                        renderValue={(selected) => selected.join(', ')}
                        renderInput={(params) => <TextField {...params} label="Allowance & Deduction " variant="outlined"
                        success={this.state.allDedIdState === "success"}
                        error={this.state.allDedIdState === "error"} />}
                    />
                    {this.state.AllDedDD.map((this.state.allDedName) (
                        <MenuItem key={this.state.allDedId} value={this.state.allDedName}>
                        <Checkbox checked={personName.indexOf(this.state.allDedIdIndex) > -1} />
                            <ListItemText primary={this.state.AllDedDD} />
                        </MenuItem>
                    ))}
                </GridTextbox>
            </div>
        );
    }

}
                              

  export default withStyles(formStyle)(EmployeeLetters);