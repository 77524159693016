
//Import from Globel Component";
import {
    React, Link, SweetAlert, ReactToggle, withStyles, Typography, TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox,
    Card, CardHeader, CardIcon, CardBody, MUIDataTable, Button, formStyle, muiWithToolbar, tbleWithPrint, NavigateNextIcon, HomeIcon, EditOutlined,
    FileCopy , SearchIcon, AlternateEmailIcon, TextsmsIcon, AllDropDowns
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class StudentApplication extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchCheck: true,
            masksmsId: -1,
            paid: true,
            studentApplicationArray: [],
            registrationNo: '',
            registrationNoState: '',
            name: '',
            nameState: '',
            fatherName: '',
            fatherNameState: '',
            registrationDate: '',
            registrationDateState: '',
            schoolJoiningDate: '',
            schoolJoiningDateState: '',
            sessionName: '',
            sessionNameState: '',
            sectorName: '',
            sectorNameState: '',
            requestedClassId: '',
            alert: null,
            show: false,
            checked: false, saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            //  #region Dropdown
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0,
            sessionId: -1,
            sessionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: -1,
            campusIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classIdIndex: 0,
            classId: -1,
            classIdState: '',
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionIdIndex: 0,
            sectionId: -1,
            sectionIdState: '',
            educationLevelDD: [{ educationLevelId: -1, levelName: 'Select' }],
            educationLevelIdIndex: 0,
            educationLevelId: -1,
            educationLevelIdState: '',
            show: false, generalError: true, ShowSession: true, ShowCampus: true, ShowLevel: true, ShowClass: true, ShowSection: true,
            IsCampusAll: true, IsLevelAll: true, IsClassAll: true, IsSectionAll: true,
            //#endregion
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
       // this.validateData = this.validateData.bind(this);
       // this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.loadDropDown();
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    handleChange(event, value) {
        debugger;
        const stateName = event.target.id.split("-")[0];
        this.setState({
            [stateName]: value[stateName],
            [stateName + "State"]: "success",
            generalError: false
        })
    }
    LoadData = () => {
        debugger;
        fetch(Base_URL + "/api/StudentApplication/LoadStudentApplicationData?Paid=" + this.state.paid + "&Session=" + this.state.sessionId + "&Campus=" + this.state.campusId + "&Level=" + this.state.educationLevelId + "&Class=" + this.state.classId + "&Section=" + this.state.sectionId + "&NameFatherNic=" + this.state.name + "&RegistrationNo=" + this.state.registrationNo).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({ studentApplicationArray: data.allStudentApplications });
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    loadDropDown = () => {
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sessionDD: data.sessionsDDList, sessionId: data.sessionsDDList[0]['sessionId'] });
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList = this.state.campusDD;
                allList = allList.concat(data.campusDDList);
                this.setState({ campusDD: allList, campusId: allList[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    //ClearTextfieldStates = () => {
    //    this.setState({
    //        masksmsId: -1, mask: '', password: '', paid: false, userId: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
    //        url: '',
    //        sectorIdState: '', sectorId: 0, sectorIdIndex: -1,

    //        sessionIdState: '', sessionId: 0, sessionIdIndex: -1,

    //        maskState: '',
    //        passwordState: '',
    //        userIdState: '',
    //        urlState: '',
    //    });
    //    this.hideAlert();
    //    this.LoadData();
    //}
    //validateData = () => {
    //    if (this.state.sessionId === 0) {
    //        this.setState({ sessionIdState: "error" });
    //        return;
    //    }
    //    else if (this.state.mask === "") {
    //        this.setState({ maskState: "error" });
    //        return;
    //    }
    //    else if (this.state.password === '') {
    //        this.setState({ passwordState: "error" });
    //        return;
    //    }
    //    else if (this.state.userId === '') {
    //        this.setState({ userIdState: "error" });
    //        return;
    //    }
    //    else if (!this.verifyUrl(this.state.url)) {
    //        this.setState({ urlState: "error" });
    //        return;
    //    }

    //    else {
    //        this.ConfirmMessage();
    //    }
    //}
 
    //setDDValue = (event, value) => {
    //    /*        debugger;*/
    //    const stateName = event.target.id.split("-")[0];
    //    const dropdownName = stateName.slice(0, -2) + "DD";
    //    var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
    //    this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
    //    if (dropdownName === 'sessionDD') {
    //        this.state.sessionId = value['sessionId'];
    //        this.LoadSectors();
    //    }
    //    else if (dropdownName === 'campusDD') {
    //        this.state.campusId = value['campusId'];
    //        this.LoadSubSectors();
    //    }
    //    else if (dropdownName == 'levelDD') {
    //        this.state.levelId = value['levelId'];
    //        this.LoadClasses();
    //    }
    //    else if (dropdownName == 'classDD') {
    //        this.state.classId = value['classId'];
    //        this.LoadSections();
    //    }
    //    else if (dropdownName == 'sectionDD') {
    //        this.state.sectionId = value['sectionId'];
    //    }
    //}
    //hideAlert() {
    //    this.setState({
    //        alert: null
    //    });
    //}
    //textChange = (event) => {
    //    this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    //}
   
    //LoadTextbox(masksmsId) {
    //    let obj = this.state.studentApplicationArray.find(o => o.masksmsId === masksmsId);
    //    this.setState({ mask: obj.mask, masksmsId: obj.masksmsId, password: obj.password, userId: obj.userId, url: obj.url, sectorId: obj.sectorId, paid: obj.paid, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    //    this.setState({ sectorIdIndex: this.state.campusDD.findIndex(x => x.sectorId === obj.sectorId) });
    //}
    //#region Alert Messages
    //ConfirmMessage() {
    //    this.setState({
    //        alert: (
    //            <SweetAlert
    //                warning
    //                style={{ display: "block", marginTop: "-200px" }}
    //                title="Are you sure?"
    //                onConfirm={() => this.SaveUpdateSMSInquiry()}
    //                onCancel={() => this.hideAlert()}
    //                confirmBtnCssClass={
    //                    this.props.classes.button + " " + this.props.classes.success
    //                }
    //                cancelBtnCssClass={
    //                    this.props.classes.button + " " + this.props.classes.danger
    //                }
    //                confirmBtnText="Yes"
    //                cancelBtnText="Cancel"
    //                showCancel
    //            >
    //                Do you want to save SMS Inquiry detail!
    //            </SweetAlert>
    //        )
    //    });
    //}
    //SuccessMessage(status, msg) {
    //    if (status === 'Success') {
    //        this.setState({
    //            alert: (
    //                <SweetAlert
    //                    success
    //                    style={{ display: "block", marginTop: "-200px" }}
    //                    title={status}
    //                    onConfirm={() => this.ClearTextfieldStates()}
    //                    onCancel={() => this.hideAlert()}
    //                    confirmBtnCssClass={
    //                        this.props.classes.button + " " + this.props.classes.success
    //                    }
    //                >{msg}</SweetAlert>
    //            )
    //        });
    //    }
    //    else {
    //        this.setState({
    //            alert: (
    //                <SweetAlert
    //                    warning
    //                    style={{ display: "block", marginTop: "-200px" }}
    //                    title={status}
    //                    onConfirm={() => this.hideAlert()}
    //                    onCancel={() => this.hideAlert()}
    //                    confirmBtnCssClass={
    //                        this.props.classes.button + " " + this.props.classes.success
    //                    }
    //                >{msg}</SweetAlert>
    //            ), saveUpdateBtnDisabled: false,
    //        });

    //    }
    //}
    //#endregion 

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { studentApplicationArray, paid, registrationNoState, registrationNo, name, nameState } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <FileCopy />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Student Application</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdmanagementhome" > Student Management</Link>
                            <Typography color="textPrimary">Student Application</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            {this.state.alert}
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardHeader>

                                                    <h4 className={classes.cardIconTitle}>Student Application</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <form>
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={12} lg={12}>
                                                                <AllDropDowns data={this.state} xsm={12} smm={6} mdm={3} lgm={2} handleStateChange={this.handleChange} />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={nameState === "success"}
                                                                    error={nameState === "error"}
                                                                    size="small"
                                                                    label="Name/Father NIC"
                                                                    id="name"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={name}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={registrationNoState === "success"}
                                                                    error={registrationNoState === "error"}
                                                                    size="small"
                                                                    label="BTS No."
                                                                    id="registrationNo"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={registrationNo}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={2}>
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "paid")}
                                                                        checked={paid}
                                                                        onColor="#3b5998"
                                                                        borderRadius={4}
                                                                        handleDiameter={22}
                                                                        className={classes.toggleStyle}
                                                                        uncheckedIcon={<div
                                                                            className={classes.toggleOff}>No</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                        } />

                                                                    <span> Challan Created</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={2}>
                                                                <Button color="facebook" onClick={this.LoadData}><SearchIcon />Search</Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                    </form>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Student Application"}
                                                    data={studentApplicationArray}
                                                    columns={[{
                                                        name: "registrationNo",
                                                        label: "Reg No.",
                                                    },
                                                    {
                                                        name: "name",
                                                        label: "Name",
                                                    },
                                                    {
                                                        name: "registrationDate",
                                                        label: "Reg. Date",
                                                    },
                                                    {
                                                        name: "schoolJoiningDate",
                                                        label: "Joining Date",
                                                    },
                                                    {
                                                        name: "sessionName",
                                                        label: "Session",
                                                    },
                                                    {
                                                        name: "campusName",
                                                        label: "Sector",
                                                    }, {
                                                        name: "levelName",
                                                        label: "SubSector",
                                                    }, {
                                                        name: "className",
                                                        label: "Class",
                                                    }, {
                                                        name: "sectionName",
                                                        label: "Section",
                                                    },
                                                    {
                                                        name: "paid",
                                                        label: "IsPaid",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                            }
                                                        }
                                                    },

                                                    {
                                                        name: "masksmsId",
                                                        label: "Edit",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<Link to="/app/studentProfileEdit"><EditOutlined /></Link>);
                                                            }
                                                        }
                                                    }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                            <div style={{ marginTop: 10, marginLeft: 800 }} >
                                                <Link to="/app/sendEmail"> <Button color="facebook" ><AlternateEmailIcon />Email</Button></Link>
                                                <Button color="facebook" >Application Report</Button>
                                                <Button color="facebook" ><TextsmsIcon />SMS</Button>
                                            </div>
                                        </GridForm>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(StudentApplication);
