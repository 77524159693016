import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import history from '../../context/History';
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Person from "@material-ui/icons/Person";
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

class HeaderLinks extends React.Component {
    state = {
        openNotification: false,
        openSignOut: false
    };
    handleClick = () => {
        this.setState({ openNotification: !this.state.openNotification });
    };
    handleClickSignOut = () => {
        this.setState({ openSignOut: !this.state.openNotification });
    };
    handleClose = () => {
        this.setState({ openNotification: false });
        this.setState({ openSignOut: false });
    };
    handleSignOut = () => {
        //debugger;
        localStorage.removeItem("RightSideBar");
        localStorage.removeItem("BaseURL");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("error");
        for (var i = 1; i <= localStorage.length; i++) {
            var key = localStorage.key(i);
            if (key !== 'UserName' && key !== 'Password' && key !== 'Context') {
                localStorage.removeItem(key);
            }
            else {
                continue;
            }
        }
        history.entries = [];
        history.index = -1;
        history.replace('/loginpage');
        //history.push('/auth/login-page');
    };
    render() {
        const { classes, rtlActive } = this.props;
        const { openSignOut } = this.state;
        var ConType = localStorage.getItem('ConnectionType');
        const dropdownItem = classNames(
            classes.dropdownItem,
            classes.facebookHover,
            { [classes.dropdownItemRTL]: rtlActive }
        );
        const wrapper = classNames({
            [classes.wrapperRTL]: rtlActive
        });
        const managerClasses = classNames({
            [classes.managerClasses]: true
        });
        return (
            <div className={wrapper}>
                <div className={managerClasses}>
                    <h4 className={managerClasses}>{ConType}</h4>
                </div>
                
                <div className={managerClasses}>
                    <Button color="transparent" justIcon aria-label="Person"
                        aria-owns={openSignOut ? "person-list" : null}
                        aria-haspopup="true"
                        onClick={this.handleClickSignOut}
                        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                        muiClasses={{ label: rtlActive ? classes.labelRTL : "" }}
                        buttonRef={node => { this.anchorEl2 = node; }}
                    >
                        <Person className={classes.headerLinksSvg + " " + (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)} />
                        <Hidden mdUp implementation="css">
                            <span onClick={this.handleClickSignOut} className={classes.linkText}>
                                {rtlActive ? "إعلام" : "Person"}
                            </span>
                        </Hidden>
                    </Button>
                    <Popper open={openSignOut} anchorEl={this.anchorEl2} transition disablePortal placement="bottom"
                        className={classNames({
                            [classes.popperClose]: !openSignOut,
                            [classes.pooperResponsive]: true,
                            [classes.pooperNav]: true
                        })} >
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps} id="person-list" style={{ transformOrigin: "0 0 0" }} >
                                <Paper className={classes.dropdown}>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList role="menu">
                                            <MenuItem onClick={this.handleSignOut} className={dropdownItem} >
                                                {rtlActive
                                                    ? "إجلاء أوزار الأسيوي حين بل, كما"
                                                    : "SignOut"}
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </div>
        );
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
