import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    axios, SearchSharp, Autocomplete
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class StdAgeLimit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Datatable Columns
            columns: [{ name: "className", label: "Class" }, { name: "minimumAge", label: "Minimum Age" }, { name: "maximumAge", label: "Maximum Age" },
            {
                name: "active", label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            },
            {
                name: "id",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false, searchCheck: true,
            ageLimitList: [], classDD: [{ classId: -1, className: "Select" }], ageLimitId: -1, classIndex: 0, classId: -1, classIdState: '', minimumAge: '', minimumAgeState: '', maximumAge: '', maximumAgeState: '', active: true,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#region Button,Validaition
    validateData = () => {
        if (this.state.classId === -1) {
            this.setState({ classIdState: "error" });
            return;
        }
        else if (this.state.minimumAge === "") {
            this.setState({ minimumAgeState: "error" });
            return;
        }
        else if (this.state.maximumAge === "") {
            this.setState({ maximumAgeState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }

    SaveUpdateAgeLimit = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const formData = new FormData()
        formData.append("ID", this.state.ageLimitId)
        formData.append("ClassId", this.state.classId)
        formData.append("MinimumAge", this.state.minimumAge)
        formData.append("MaximumAge", this.state.maximumAge)
        formData.append("Active", this.state.active)
        axios.post(Base_URL + "/StudentPrimaryData/SaveUpdateAgeLimit", formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    LoadTextbox = (Id) => {
        let obj = this.state.ageLimitList.find(o => o.id === Id);
        this.setState({ classId: obj.classId, active: obj.active, ageLimitId: obj.id, maximumAge: obj.maximumAge, minimumAge: obj.minimumAge, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ classIndex: this.state.classDD.findIndex(x => x.classId === obj.classId) });
    }
    ClearTextfieldStates = () => {
        this.setState({ ageLimitId: -1, classIndex: 0, classId: -1, classIdState: '', minimumAge: '', minimumAgeState: '', maximumAge: '', maximumAgeState: '', active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', });
        this.hideAlert();
        this.LoadData();
    }
    //#endregion 

    //#region Backend Data Fetching and Saving
    componentDidMount() {
        fetch(Base_URL + "/api/DropDowns/ClassDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ classDD: data });
            }).catch(error => {
                console.log(error);
            });
    }

    LoadData = () => {
        fetch(Base_URL + "/StudentPrimaryData/LoadAgeLimit?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ ageLimitList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateAgeLimit()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    closeOnClickOutside={false}
                    showCancel
                >
                    Do you want to save Profession detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { ageLimitList, classDD, columns, classIndex, classIdState, minimumAge, minimumAgeState, maximumAge, maximumAgeState, active, searchCheck, clearTextBtn } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Student Age Limits</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="classId"
                                                        size="small"
                                                        options={classDD}
                                                        getOptionLabel={(option) => option.className}
                                                        value={classDD[classIndex]}
                                                        onChange={(event, value) => { this.setState({ classId: value.classId, classIdState: "success", classIndex: classDD.findIndex(x => x.classId === value.classId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={classIdState === "success"}
                                                            error={classIdState === "error"} label="Class" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={minimumAgeState === "success"}
                                                        error={minimumAgeState === "error"}
                                                        size="small"
                                                        label="Minimum Age"
                                                        id="minimumAge"
                                                        variant="outlined"
                                                        type="number"
                                                        fullwidth="true"
                                                        value={minimumAge}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={maximumAgeState === "success"}
                                                        error={maximumAgeState === "error"}
                                                        size="small"
                                                        label="Maximum Age"
                                                        id="maximumAge"
                                                        type="number"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={maximumAge}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Student Age Limits"}
                                        data={ageLimitList}
                                        columns={columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdAgeLimit);
