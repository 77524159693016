import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactToggle from "react-switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { GroupAdd as SupplierIcon, EditOutlined, SaveOutlined, AddCircle as AddIcon, Home as HomeIcon, NavigateNext as NavigateNextIcon} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { MuiThemeProvider } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {formStyle, muiWithToolbar,tbleWithPrint, StyledBreadcrumb} from "assets/jss/customStyle/allCustomStyle";
var Base_URL = localStorage.BaseURL;
class ManageSuppliers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            suppliersdata: [],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Name",
            }, {
                name: "city",
                label: "City",
            }, {
                name: "country",
                label: "Country",
            },
            {
                name: "department",
                label: "Department",
            }, {
                name: "accountId",
                label: "Account ID",
            }, {
                name: "accountTitle",
                label: "Account Title",
            }, {
                name: "active",
                label: "Active",
            }, {
                name: "supplierId",
                label: "Edit",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<Button color="facebook" onClick={() => this.LoadTextbox(value)}><EditOutlined /> Edit </Button>);
                    }
                }
            }],
            //#endregion
          

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false,
            departmentDD: [{ departMentCode: -1, departmentName: "Select" }], countryDD: [{ countryCode: -1, countryName: "Select" }], cityDD: [{ cityCode: -1, cityName: "Select" }],
            supplierEmail: "", supplierEmailState: "", supplierName: "", supplierNameState: "", supplierCNIC: "", supplierCNICState: "", supplierId: -1, accountId:-1,
            address: '', phoneNo: '', mobileNo: '', mobileNoState: '', departMentCode: 0, departmentIndex: 0, taxStatus: 'Filer', cityCode: -1, cityIndex: 0,
            countryCode: -1, countryIndex: 0, cityCodeState: '', countryCodeState: '', ntnNo: '', isVendor: false,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    
    LoadTextbox = (supplierId) => {
        let obj = this.state.suppliersdata.find(o => o.supplierId === supplierId);
        this.setState({ departMentCode: obj.departmentId, cityCode: obj.cityCode, countryCode: obj.countryCode, supplierName: obj.name, supplierId: obj.supplierId, accountId: obj.accountId, address: obj.address, phoneNo: obj.phoneNo, mobileNo: obj.mobileNo, supplierCNIC: obj.supplierCNIC, supplierEmail: obj.email, ntnNo: obj.ntn, taxStatus: obj.taxStatus, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (obj.active === "Yes") {
            this.setState({ isVendor: true })
        }
        else {
            this.setState({ isVendor: false })
        }
        this.setState({ departmentIndex: this.state.departmentDD.findIndex(x => x.departMentCode === obj.departmentId) });
        this.setState({ cityIndex: this.state.cityDD.findIndex(x => x.cityCode === obj.cityCode) });
        this.setState({ countryIndex: this.state.countryDD.findIndex(x => x.countryCode === obj.countryCode) });
    }
    SaveSupplier = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true, clearTextBtn: 'none' });
        axios.post(Base_URL + "/Suppliers/AddUpdateSupplier?SupplierId=" + this.state.supplierId + "&AccountId=" + this.state.accountId + "&Name=" + this.state.supplierName + "&NIC=" + this.state.supplierCNIC + "&Email=" + this.state.supplierEmail + "&Address=" + this.state.address
            + "&PhoneNo=" + this.state.phoneNo + "&MobileNo=" + this.state.mobileNo + "&CityCode=" + this.state.cityCode + "&CountryCode=" + this.state.countryCode + "&NTN=" + this.state.ntnNo + "&IsVendor=" + this.state.isVendor
            + "&TaxStatus=" + this.state.taxStatus + "&DepartMentCode=" + this.state.departMentCode)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    ClearTextfieldStates = () => {
        this.setState({
            supplierId: -1, accountId:-1, supplierName: "", supplierNameState: "", supplierCNIC: "", supplierCNICState: "", supplierEmail: "", supplierEmailState: "", address: '', phoneNo: '', mobileNo: '', mobileNoState: '',
            departMentCode: -1, departMentCodeState: '', taxStatus: 'Filer', cityCode: -1, countryCode: -1, cityCodeState: '', countryCodeState: '', ntnNo: '', isVendor: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
    }

    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.supplierName === "") {
            this.setState({ supplierNameState: "error" });
            return;
        }
        else if (this.state.departMentCode < 1) {
            this.setState({ departMentCodeState: "error" });
            return;
        }
        else if (this.state.cityCode < 1) {
            this.setState({ cityCodeState: "error" });
            return;
        }
        else if (this.state.countryCode < 1) {
            this.setState({ countryCodeState: "error" });
            return;
        }
        else if ((this.state.supplierCNIC !== "") && (this.verifyCNICNo() === false)) {
            this.setState({ supplierCNICState: "error" });
            return;
        }
        else if ((this.state.supplierEmail !== "") && (this.verifyEmail() === false)) {
            this.setState({ supplierEmailState: "error" });
            return;
        }
        else if ((this.state.mobileNo !== "") && (this.verifyMobileNo() === false)) {
            this.setState({ mobileNoState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    // function that returns true if value is email, false otherwise
    verifyEmail() {
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(this.state.supplierEmail)) {
            return true;
        }
        return false;
    }
    verifyMobileNo() {
        var MobNoRex = /^[3]{1}[0-9]{9}$/i
        if (MobNoRex.test(this.state.mobileNo)) {
            return true;
        }
        return false;
    }
    verifyCNICNo() {
        var CNICRex = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/i
        if (CNICRex.test(this.state.supplierCNIC)) {
            return true;
        }
        return false;
    }

    handleToggleChange(isVendor) {
        this.setState({ isVendor });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    //#endregion 

    //#region Backend Data Fetching and Saving
    LoadSupplier = () => {
        fetch(Base_URL + "/Suppliers/loadSuppliers").then(response => { return response.json(); })
            .then(data => {
                let ldata = data.map((i) => { return { supplierId: i.supplierId, name: i.name, city: i.cityName, cityCode: i.cityCode, country: i.countryName, countryCode: i.countryCode, department: i.departmentName, departmentId: i.departmentId, active: i.activeStatus, address: i.address, phoneNo: i.phoneNo, mobileNo: i.mobileNo, supplierCNIC: i.nic, email: i.email, ntn: i.ntn, taxStatus: i.taxStatus, accountId: i.accountId, accountTitle: i.accountTitle, isVendor: i.isVendor } });
                this.setState({ suppliersdata: ldata });
            }).catch(error => {
                console.log(error);
            });
    }
    componentDidMount() {
        fetch(Base_URL + "/Suppliers/loadDepartmentDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ departmentDD: data });
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/Suppliers/LoadCountry").then(response => { return response.json(); })
            .then(data => {
                this.setState({ countryDD: data, countryIndex: 8 });
            }).catch(error => {
                console.log(error);
            });
        this.LoadSupplier();
        this.LoadCity(null);
    }
    componentDidUpdate() {
        this.LoadSupplier();
    }
    LoadCity = (countryCode) => {
        let cID = -1;
        if (countryCode !== null) {
            cID = countryCode;
        }
        fetch(Base_URL + "/Suppliers/LoadCity?countryCode=" + cID).then(response => { return response.json(); })
            .then(data => {
                this.setState({ cityDD: data, cityIndex: 2 });
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveSupplier()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save supplier detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if ((status === 'Error') || (status === 'Duplicate'))  {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block'
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <SupplierIcon />
                    </CardIcon>
                    <h4 className={classes.formHeading}>Manage Suppliers</h4>
                </CardHeader>
                {this.state.alert}
                <div className={classes.formDiv}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/inventory" > Inventory</Link>
                        <Typography color="textPrimary">Manage Suppliers </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="success" icon>
                                        <h4 className={classes.formHeading}>Supplier Detail</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.supplierNameState === "success"}
                                                        error={this.state.supplierNameState === "error"}
                                                        size="small"
                                                        label="Supplier Name"
                                                        id="supplierName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.supplierName}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.supplierCNICState === "success"}
                                                        error={this.state.supplierCNICState === "error"}
                                                        size="small"
                                                        label="CNIC#"
                                                        id="supplierCNIC"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.supplierCNIC}
                                                        onChange={this.textChange}
                                                    />

                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Phone No."
                                                        id="phoneNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        type={"number"}
                                                        value={this.state.phoneNo}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.supplierEmailState === "success"}
                                                        error={this.state.supplierEmailState === "error"}
                                                        size="small"
                                                        label="Email"
                                                        id="supplierEmail"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.supplierEmail}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.mobileNoState === "success"}
                                                        error={this.state.mobileNoState === "error"}
                                                        size="small"
                                                        label="Mobile#"
                                                        id="mobileNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.mobileNo}
                                                        onChange={this.textChange} type="number"
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="departMentCode"
                                                        size="small"
                                                        options={this.state.departmentDD}
                                                        getOptionLabel={(option) => option.departmentName}
                                                        value={this.state.departmentDD[this.state.departmentIndex]}
                                                        onChange={(event, value) => { this.setState({ departMentCode: value.departMentCode, departMentCodeState: "success", departmentIndex: this.state.departmentDD.findIndex(x => x.departMentCode === value.departMentCode) }) }}
                                                        renderInput={(params) => <TextField {...params} success={this.state.departMentCodeState === "success"}
                                                            error={this.state.departMentCodeState === "error"} label="Department" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="countryCode"
                                                        size="small"
                                                        options={this.state.countryDD}
                                                        getOptionLabel={(option) => option.countryName}
                                                        value={this.state.countryDD[this.state.countryIndex]}
                                                        onChange={(event, value) => { this.setState({ countryCode: value.countryCode, countryIndex: this.state.countryDD.findIndex(x => x.countryCode === value.countryCode) }); /*this.LoadCity(value.countryCode)*/ }}
                                                        renderInput={(params) => <TextField {...params} success={this.state.countryCodeState === "success"}
                                                            error={this.state.countryCodeState === "error"} label="Country" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="cityCode"
                                                        size="small"
                                                        options={this.state.cityDD}
                                                        getOptionLabel={(option) => option.cityName}
                                                        value={this.state.cityDD[this.state.cityIndex]}
                                                        onChange={(event, value) => { this.setState({ cityCode: value.cityCode, cityIndex: this.state.cityDD.findIndex(x => x.cityCode === value.cityCode) }) }}
                                                        renderInput={(params) => <TextField {...params} success={this.state.cityCodeState === "success"}
                                                            error={this.state.cityCodeState === "error"} label="City" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="NTN"
                                                        id="ntnNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.ntnNo}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                                                        <InputLabel id="taxStatuslabel">Tax Status</InputLabel>
                                                        <Select native labelId="taxStatuslabel" id="taxStatus" label="Tax Status" onChange={this.textChange} value={this.state.taxStatus} isSearchable={true} >
                                                            <option value={"Filer"}>Filer</option>
                                                            <option value={"Non-Filer"}>Non-Filer</option>
                                                        </Select>
                                                    </FormControl>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={4}>
                                                    <TextField
                                                        size="small" label="Address" id="address" variant="outlined" multiline rowsMax={1} fullWidth
                                                        onChange={this.textChange} value={this.state.address}
                                                    />
                                                </GridTextbox>
                                            </GridContainer>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={this.handleToggleChange} checked={this.state.isVendor} onColor="#3b5998" width={60}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                  <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Suppliers List"}
                                        data={this.state.suppliersdata}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </Card>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(ManageSuppliers);
