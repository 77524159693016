import {
    React, GridContainer, Card, CardHeader, CardBody, Box, Typography, PropTypes, CardIcon, Link, Tab, Breadcrumbs,
    NavigateNextIcon, Tabs, HomeIcon, makeStyles, StdIcon
} from '../../components/Common/ImportAll'
// Import Employee Views
import StdSessions from "./StdPrimaryDataTabs/StdSessions.jsx";
import StdClasses from "./StdPrimaryDataTabs/StdClasses.jsx";
import StdSections from "./StdPrimaryDataTabs/StdSections.jsx";
import StdClubs from "./StdPrimaryDataTabs/StdClubs.jsx";
import ParentProfessions from "./StdPrimaryDataTabs/ParentProfessions.jsx";
import StdAgeLimit from "./StdPrimaryDataTabs/StdAgeLimit.jsx";
import StdLevels from "./StdPrimaryDataTabs/StdLevels.jsx";
import StudentLeaveReason from "./StdPrimaryDataTabs/StudentLeaveReason.jsx";
import AdmissionFreezeReason from "./StdPrimaryDataTabs/AdmissionFreezeReason.jsx";
import StdTestAssessment from "./StdPrimaryDataTabs/StdTestAssessment.jsx";
import Groups from "./StdPrimaryDataTabs/Groups.jsx";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index}
            id={'scrollable-auto-tabpanel-${index}'}
            aria-labelledby={'scrollable-auto-tab-${index}'} {...other} >

            {value === index && (<Box p={3}> <Typography>{children}</Typography> </Box>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: 'scrollable-auto-tab-${index}',
        'aria-controls': 'scrollable-auto-tabpanel-${index}',
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonAuto() {
    const classes = useStyles();
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="info" icon>
                    <CardIcon color="success">
                        <StdIcon />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Student Primary Data<small> Management</small></h4>
                    <hr style={{ marginBottom: "8px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link  to="/app/stdmanagement" >Student Management</Link>
                        <Typography color="textPrimary">Primary Data</Typography>
                    </Breadcrumbs>

                    <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <CardBody>
                        <GridContainer>
                            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="on" style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary" textColor="primary" wrapped >

                                <Tab label="Sessions" {...a11yProps(0)} />
                                <Tab label="Classes" {...a11yProps(1)} />
                                <Tab label="Sections" {...a11yProps(2)} />
                                <Tab label="Groups" {...a11yProps(3)} />
                                <Tab label="Clubs" {...a11yProps(4)} />
                                <Tab label="Parent Professions" {...a11yProps(5)} />
                                <Tab label="Age Limits" {...a11yProps(6)} />
                                <Tab label="Levels" {...a11yProps(7)} />
                                <Tab label="Student Leave Reason" {...a11yProps(8)} />
                                <Tab label="Admission Freeze Reason" {...a11yProps(9)} />
                                <Tab label="Test Assessment" {...a11yProps(10)} />
                            </Tabs>
                            {selectedTab === 0 && <StdSessions />}
                            {selectedTab === 1 && <StdClasses />}
                            {selectedTab === 2 && <StdSections />}
                            {selectedTab === 3 && <Groups />}
                            {selectedTab === 4 && <StdClubs />}
                            {selectedTab === 5 && <ParentProfessions />}
                            {selectedTab === 6 && <StdAgeLimit />}
                            {selectedTab === 7 && <StdLevels />}
                            {selectedTab === 8 && <StudentLeaveReason />}
                            {selectedTab === 9 && <AdmissionFreezeReason />}
                            {selectedTab === 10 && <StdTestAssessment />}
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}