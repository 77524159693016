import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp, axios,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar,
    tbleWithPrint, Autocomplete, Moment
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class Sectors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectorId: 0,
            sectorName: "",
            sectorNameState: "",
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            searchCountryId: 0,
            searchCountryIndex:0,
            searchCityId: 0,
            searchCityIndex: 0,
            searchTownId: 0,
            searchTownIndex: 0,
            saveCountryId: 0,
            saveCountryIdState: "",
            saveCountryIndex: 0,
            saveCityId: 0,
            saveCityIdState: "",
            saveCityIndex: 0,
            saveTownId: 0,
            saveTownIdState: "",
            saveTownIndex: 0,
            countryDD: [{ countryId: 0, countryName: "Select" }],
            loadCountryDD: [{ countryId: -1, countryName: "All" }],
            cityDD: [{ cityId: 0, cityName: "Select" }],
            loadCitiesDD: [{ cityId: -1, cityName: "All" }],
            saveCityDD: [{ cityId: 0, cityName: "Select" }],
            townDD: [{ townId: 0, townName: "Select" }],
            saveTownDD: [{ townId: 0, townName: "Select" }],
            loadTownssDD: [{ townId: -1, townName: "All" }],
            //#region Datatable Columns and Data

            //#region start
            sectorsData: [],
            //#region Datatable Columns
            columns: [{
                name: "countryName",
                label: "Country",
                options: {
                    filter: true,
                }
            }, {
                name: "cityName",
                label: "City",
                options: {
                    filter: true,
                }
            }, {
                name: "townName",
                label: "Town",
                options: {
                    filter: true,
                }
            }, {
                name: "sectorName",
                label: "Sector Name",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "sectorId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadSectors = this.loadSectors.bind(this);
    }
    async componentDidMount() {
        this.loadsaveCountries();
        this.loadCountries();
    }
    async loadsaveCountries() {
        const response = await (fetch(Base_URL + "/api/Dropdowns/Countries"))
        const data = await response.json();
        this.setState({ countryDD: data.countryDDList });
        this.setState({ saveCountryId: data.countryDDList[0].countryId, saveCountryIdState: "success" });
        this.loadSaveCityDD(data.countryDDList[0].countryId);
    }
    //async loadCityDD(countryId) {
    //    debugger
    //    const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
    //    const data = await response.json();
    //    debugger
    //    this.setState({ cityDD: data });
    //}
    async loadSaveCityDD(countryId) {
        const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
        const data = await response.json();
        this.setState({ saveCityDD: data.citiesDDList });
        if (data.citiesDDList.length > 0) {
            this.setState({
                saveCityIndex: 0,
                saveCityId: data.citiesDDList[0].cityId,
                saveCityIdState: "success"
            });
            this.loadSaveTownDD(data[0].cityId);
        }
        else {
            this.setState({
                cityId: 0,
                saveCityIndex: -1,
                saveCityIdState: ""
            })
        }
    }
    async loadCountries() {
        //debugger;
        fetch(Base_URL + "/api/Dropdowns/Countries").then(response => { return response.json(); })
            .then(data => {
                //debugger;
                let allList = [];
                allList = this.state.loadCountryDD;
                allList = allList.concat(data.countryDDList);
                this.setState({ loadCountryDD: allList, saveCountryId: allList[0]['countryId'] });
                this.loadCityDD(this.state.saveCountryId);
            }).catch(error => {
                console.log(error);
            });
    }
   // #endregion
    async loadCityDD(countryId) {
        //debugger;
        fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId).then(response => { return response.json(); })
            .then(data => {
                //debugger;
                let allList = [];
                allList.push({ cityId: -1, cityName: "All" });
                allList = allList.concat(data.citiesDDList);
                this.setState({ loadCitiesDD: allList, saveCityId: allList[0]['cityId'] });
                this.loadtownsDD(this.state.saveCityId);
            }).catch(error => {
                console.log(error);
            });
    }
    async loadtownsDD(cityId) {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + cityId).then(response => { return response.json(); })
            .then(data => {
                debugger;
                let allList = [];
                allList.push({ townId: -1, townName: "All" });
                allList = allList.concat(data.townDDList);
                this.setState({ loadTownssDD: allList, saveTownId: allList[0]['townId'] });
            }).catch(error => {
                console.log(error);
            });
    }

    async loadEditCityDD(countryId, cityId, townId) {
        debugger;
        const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
        const data = await response.json();
        this.setState({ saveCityDD: data });
        if (this.state.saveCityDD.find(x => x.cityId === cityId) != null) {
            this.setState({
                saveCityIndex: this.state.saveCityDD.findIndex(x => x.cityId === cityId),
                saveCityId: cityId,
                saveCityIdState: "success"
            })
            this.loadEditTownDD(cityId, townId);
        }
        else {
            this.setState({
                saveCityIndex: -1,
                saveTownIndex: -1,
                saveCityId: 0,
                saveTownId: 0,
                saveCityIdState: "error",
                saveTownIdState: "error"
            })
            this.SuccessMessage('Alert', 'Highlighted fields are InActive');
        }
    }
    
    //async loadTownDD(cityId) {
    //    try {
    //        const response = await (fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + cityId))
    //        if (response.ok) {
    //            const data = await response.json();
    //            this.setState({ townDD: data });
    //        }
    //        else {
    //            throw Error(response.statusText)
    //        }
    //    }
    //    catch (error) {
    //        console.error(error);
    //    }
    //}
    async loadSaveTownDD(cityId) {
        const response = await (fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + cityId))
        const data = await response.json();
        this.setState({ saveTownDD: data.townDDList });
        if (data.townDDList.length > 0) {
            this.setState({
                saveTownIndex: 0,
                saveTownId: data.townDDList[0].townId,
                saveTownIdState: "success" 
            });
        }
        else {
            this.setState({ 
                saveTownIndex: -1,
                saveTownId: 0,
                saveTownIdState: "" 
            })
        }
    }
    async loadEditTownDD(cityId, townId) {
        debugger
        const response = await (fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + cityId))
        const data = await response.json();
        this.setState({ saveTownDD: data });
        debugger
        if (this.state.saveTownDD.find(x => x.townId === townId) != null) {
            this.setState({
                saveTownIndex: this.state.saveTownDD.findIndex(x => x.townId === townId),
                saveTownId: townId,
                saveTownIdState: "success"
            })
        }
        else {
            this.setState({
                saveTownIndex: -1,
                saveTownId: 0,
                saveTownIdState: "error"
            })
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    loadSectors = () => {
        fetch(Base_URL + "/api/primaryData/Sectors?CountryId=" + this.state.searchCountryId + "&CityId=" + this.state.searchCityId + "&TownId=" + this.state.searchTownId + "&Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectorsData: data.sectorsList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleChange(value, type) {
        debugger;
        switch (type) {
            case "searchCountryId":
                if (value !== null) {
                    this.setState({
                        searchCountryId: value.countryId,
                        searchCountryIndex: this.state.loadCountryDD.findIndex(x => x.countryId === value.countryId)
                    });
                    this.loadCityDD(value.countryId);
                } else {
                    this.setState({ searchCountryId: 0 });
                }
                break;
            case "saveCountryId":
                if (value !== null) {
                    this.setState({
                        saveCountryId: value.countryId,
                        saveCountryIdState: "success",
                        saveCountryIndex: this.state.countryDD.findIndex(x => x.countryId === value.countryId)
                    });
                    this.loadSaveCityDD(value.countryId);
                } else {
                    this.setState({ saveCountryId: 0, saveCountryIdState: "error" });
                }
                break;
            case "searchCityId":
                if (value !== null) {
                    this.setState({
                        searchCityId: value.cityId,
                        searchCityIndex: this.state.loadCitiesDD.findIndex(x => x.cityId === value.cityId)
                    });
                    this.loadtownsDD(value.cityId);
                } else {
                    this.setState({ searchCityId: 0 });
                }
                break;
            case "saveCityId":
                if (value !== null) {
                    this.setState({
                        saveCityId: value.cityId,
                        saveCityIdState: "success",
                        saveCityIndex: this.state.saveCityDD.findIndex(x => x.cityId === value.cityId)
                    });
                    this.loadSaveTownDD(value.cityId);
                } else {
                    this.setState({ saveCityId: 0, saveCityIdState: "error" });
                }
                break;
            case "searchTownId":
                if (value !== null) {
                    this.setState({
                        searchTownId: value.townId,
                        searchTownIndex: this.state.loadTownssDD.findIndex(x => x.townId === value.townId)
                    });
                } else {
                    this.setState({ searchTownId: 0 });
                }
                break;
            case "saveTownId":
                if (value !== null) {
                    this.setState({
                        saveTownId: value.townId,
                        saveTownIdState: "success",
                        saveTownIndex: this.state.saveTownDD.findIndex(x => x.townId === value.townId)
                    });
                } else {
                    this.setState({ saveTownId: 0, saveTownIndex: "error" });
                }
                break;
            default:
                break;
        }
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        debugger;
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        debugger;
        switch (type) {
            case "sectorName":
                if (this.verifyLength(event.target.value, stateNameEqualTo-1)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        if (this.state.saveCountryIdState === "" || this.state.saveCountryIdState === "error") {
            this.setState({ saveCountryIdState: "error" });
            return;
        } else if (this.state.saveCityIdState === "" || this.state.saveCityIdState === "error") {
            this.setState({ saveCityIdState: "error" });
            return;
        } else if (this.state.saveTownIdState === "" || this.state.saveTownIdState === "error") {
            this.setState({ saveTownIdState: "error" });
            return;
        }
        else if (this.state.sectorNameState === "" || this.state.sectorNameState === "error") {
            this.setState({ sectorNameState: "error" });
            return;
        } else {
            if (this.state.sectorId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + '/api/primaryData/Sectors', {
            sectorId: this.state.sectorId,
            sectorName: this.state.sectorName,
            CountryId: this.state.saveCountryId,
            CityId: this.state.saveCityId,
            TownId: this.state.saveTownId,
            Active: this.state.active
        })
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Sector will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Sector will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                )
            });

        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (sectorId) => {
        debugger
        let obj = this.state.sectorsData.find(o => o.sectorId === sectorId);
        this.setState({ sectorId: obj.sectorId, sectorName: obj.sectorName, sectorNameState: "success",  saveCityId: obj.cityId, saveTownId: obj.townId, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (this.state.loadCountryDD.find(x => x.countryId === obj.countryId) != null)
        {
            this.setState({
                saveCountryId: obj.countryId,
                saveCountryIndex: this.state.countryDD.findIndex(x => x.countryId === obj.countryId),
                saveCountryIdState: "success"
            });
            this.loadEditCityDD(obj.countryId, obj.cityId, obj.townId);
        }
        else
        {
            this.setState({ 
                saveCountryIndex: -1,
                saveCountryId:0,
                saveCountryIdState: "error",
                saveCityIndex: -1,
                saveCityId: 0,
                saveCityIdState: "error",
                saveTownIndex: -1,
                saveTownId: 0,
                saveTownIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted fields are InActive');
        }
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, sectorId: 0, sectorName: "", sectorNameState: "", active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.setState({ saveCountryIndex: 0, saveCountryId: this.state.countryDD[0].countryId });
        this.loadSaveCityDD(this.state.countryDD[0].countryId);
        this.loadSectors();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.loadCountryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.loadCountryDD[this.state.searchCountryIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchCountryId")}
                                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.loadCitiesDD}
                                                    getOptionLabel={(option) => option.cityName}
                                                    value={this.state.loadCitiesDD[this.state.searchCityIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchCityId")}
                                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.loadTownssDD}
                                                    getOptionLabel={(option) => option.townName}
                                                    value={this.state.loadTownssDD[this.state.searchTownIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchTownId")}
                                                    renderInput={(params) => <TextField {...params} label="Town" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadSectors} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Sectors</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.countryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.countryDD[this.state.saveCountryIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveCountryId")}
                                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined"
                                                        success={this.state.saveCountryIdState === "success"}
                                                        error={this.state.saveCountryIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.saveCityDD}
                                                    getOptionLabel={(option) => option.cityName}
                                                    value={this.state.saveCityDD[this.state.saveCityIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveCityId")}
                                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined"
                                                        success={this.state.saveCityIdState === "success"}
                                                        error={this.state.saveCityIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.saveTownDD}
                                                    getOptionLabel={(option) => option.townName}
                                                    value={this.state.saveTownDD[this.state.saveTownIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveTownId")}
                                                    renderInput={(params) => <TextField {...params} label="Town" variant="outlined"
                                                        success={this.state.saveTownIdState === "success"}
                                                        error={this.state.saveTownIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.sectorNameState === "success"}
                                                    error={this.state.sectorNameState === "error"}
                                                    value={this.state.sectorName}
                                                    size="small"
                                                    label="Sector Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "sectorName", "sectorName", 2), type: "text" }}
                                                />
                                            </GridTextbox>

                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Sectors"}
                                        data={this.state.sectorsData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(Sectors);