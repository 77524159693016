//Import from Globel Component";
import {
    React, axios, Moment, ReactToggle, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class EOBI extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            empSSId: 0,
            empEobId: 0,
            eobiId: 0,
            SocialSecurityList: [],
            EOBIList: [],
            enrollmentDateState: "",
            enrollmentDate: new Date(),
            cardStatusId: "",
            cardStatusIdIndex: 0,
            cardStatusIdState: "",
            cardNo: "",
            cardNoState: "",
            isActive: "",
            ssEnrollmentDateState: "",
            ssEnrollmentDate: new Date(),
            expiryDate: new Date(),
            expiryDateState: "",
            sscardStatusId: "",
            sscardStatusIdIndex: 0,
            sscardStatusIdState: "",
            sscardNo: "",
            sscardNoState: "",
            CardStatusDD: [{ cardStatusId: -1, cardStatus: "Select" }],
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextEobiBtn: 'none',
            saveUpdateEobiBtnText: 'Save',
            saveUpdateEobiBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadSocialSecurityData();
        this.LoadEOBIData();
        this.LoadCardStatusDD();
    }
    async LoadCardStatusDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/CardStatusDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    CardStatusDD: data.cardStatusDDList
                });
                if (data.cardStatusDDList.length > 0) {
                    debugger;
                    this.setState({
                        cardStatusId: data.cardStatusDDList[0].cardStatusId,
                        cardStatusIdIndex: 0,
                        cardStatusIdState: "success",
                        sscardStatusId: data.cardStatusDDList[0].cardStatusId,
                        sscardStatusIdIndex: 0,
                        sscardStatusIdState: "success",
                    });
                }
                else {
                    this.setState({
                        cardStatusId: 0,
                        cardStatusIdIndex: -1,
                        cardStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadEOBIData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadEOBIData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    EOBIList: data.eobiDataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadSocialSecurityData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadSocialSecurityData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    SocialSecurityList: data.socialSecurityDataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    SaveEOBIData = () => {
        debugger;
        if ((this.state.enrollmentDate === "") || (this.state.enrollmentDateState === "error")) {
            this.setState({ enrollmentDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.cardStatusId === "") || (this.state.cardStatusId === 0) || (this.state.cardStatusIdState === "error")) {
            this.setState({ cardStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.cardNo === "") || (this.state.cardNoState === "error")) {
            this.setState({ cardNoState: "error", generalError: true });
            return;
        }

        let EOBIInfo = {
            EmpEobId: this.state.empEobId,
            EOBIId: this.state.eobiId,
            EmployeeId: this.state.employeeId,
            CardNo: this.state.cardNo,
            EnrollmentDate: Moment(this.state.enrollmentDate).format('YYYY-MM-DD'),
            CardStatusId: parseInt(this.state.cardStatusId),
            Status: this.state.isActive,
        };
        console.log(EOBIInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeEOBI', EOBIInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveSocialSecurityData = () => {
        debugger;
        if ((this.state.ssEnrollmentDate === "") || (this.state.ssEnrollmentDateState === "error")) {
            this.setState({ ssEnrollmentDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.expiryDate === "") || (this.state.expiryDateState === "error")) {
            this.setState({ expiryDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.sscardStatusId === "") || (this.state.sscardStatusId === 0) || (this.state.sscardStatusIdState === "error")) {
            this.setState({ sscardStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.sscardNo === "") || (this.state.sscardNoState === "error")) {
            this.setState({ sscardNoState: "error", generalError: true });
            return;
        }

        let SocialSecurityInfo = {
            EmployeeId: this.state.employeeId,
            EmpSSId: parseInt(this.state.empSSId),
            CardNo: this.state.sscardNo,
            EnrollmentDate: Moment(this.state.ssEnrollmentDate).format('YYYY-MM-DD'),
            ExpiryDate: Moment(this.state.expiryDate).format('YYYY-MM-DD'),
            CardStatusId: parseInt(this.state.sscardStatusId),

        };
        console.log(SocialSecurityInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveSocialSecurity', SocialSecurityInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.SocialSecurityList.find(o => o.empSSId === id);
        this.setState({
            empSSId: obj.empSSId,
            ssEnrollmentDate: obj.enrollmentDate,
            expiryDate: obj.expiryDate,
            sscardNo: obj.cardNo,
            saveUpdateEobiBtnDisabled: false,
            saveUpdateBtnText: 'Update',
            clearTextBtn: 'block'
        });
        if (this.state.CardStatusDD.find(x => x.cardStatusId === obj.cardStatusId) != null) {
            this.setState({
                sscardStatusId: obj.cardStatusId,
                sscardStatusIdIndex: this.state.CardStatusDD.findIndex(x => x.cardStatusId === obj.cardStatusId),
                sscardStatusIdState: "success"
            });
        }
        else {
            this.setState({
                sscardStatusId: -1,
                sscardStatusIdIndex: 0,
                sscardStatusIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    LoadEobiTextbox = (id) => {
        debugger
        let obj = this.state.EOBIList.find(o => o.empEobId === id);
        this.setState({
            empEobId: obj.empEobId,
            employeeId: obj.employeeId,
            eobiId: obj.eobiId,
            enrollmentDate: obj.enrollmentDate,
            cardNo: obj.cardNo,
            cardStatusId: obj.cardStatusId,
            isActive: obj.active,
            saveUpdateBtnDisabled: false,
            saveUpdateEobiBtnText: 'Update',
            clearTextEobiBtn: 'block',
        });
        if (this.state.CardStatusDD.find(x => x.cardStatusId === obj.cardStatusId) != null) {
            this.setState({
                cardStatusId: obj.cardStatusId,
                cardStatusIdIndex: this.state.CardStatusDD.findIndex(x => x.cardStatusId === obj.cardStatusId),
                cardStatusIdState: "success"
            });
        }
        else {
            this.setState({
                cardStatusId: -1,
                cardStatusIdIndex: 0,
                cardStatusIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert();
    }
    hideAlert() {
        this.setState({
            alert: null,
            empSSId: 0,
            empEobId: 0,
            eOBIId: 0,
            enrollmentDate: new Date(),
            ssEnrollmentDate: new Date(),
            expiryDate: new Date(),
            isActive: true,
            cardNo: "",
            sscardNo: "",
            saveUpdateBtnText: 'Save',
            clearTextBtn: 'none',
            saveUpdateEobiBtnText: 'Save',
            clearTextEobiBtn: 'none',
        });
        this.LoadSocialSecurityData();
        this.LoadEOBIData();
        this.LoadCardStatusDD();
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "enrollmentDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        enrollmentDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "cardStatusId":
                debugger
                if (this.state.CardStatusDD.find(x => x.cardStatusId === value.cardStatusId)) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.CardStatusDD.findIndex(x => x[stateName] === value[stateName]),
                        cardStatusId: value.cardStatusId,
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.CardStatusDD.findIndex(x => x[stateName] === value[stateName]),
                        cardStatusId: value.cardStatusId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "cardNo":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        cardNo: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        cardNo: event.target.value,
                    })
                }
                break;
            case "ssenrollmentDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        ssEnrollmentDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "expiryDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        expiryDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "sscardStatusId":
                debugger
                if (this.state.CardStatusDD.find(x => x.cardStatusId === value.cardStatusId)) {
                    this.setState({
                        sscardStatusIdIndex: this.state.CardStatusDD.findIndex(x => x.cardStatusId === value.cardStatusId),
                        sscardStatusId: value.cardStatusId,
                        sscardStatusIdState: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        sscardStatusIdIndex: this.state.CardStatusDD.findIndex(x => x.cardStatusId === value.cardStatusId),
                        sscardStatusId: value.cardStatusId,
                        sscardStatusIdState: "success"
                    });

                }
                break;
            case "sscardNo":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        sscardNo: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        sscardNo: event.target.value,
                    })
                }
                break;
            case "checkbox":
                this.setState({ [stateName]: event });
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { SocialSecurityList, EOBIList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>EOBI History </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.enrollmentDateState === "success"}
                                            error={this.state.enrollmentDateState === "error"}
                                            size="small"
                                            label="Enrollment Date"
                                            id="enrollmentDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.enrollmentDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "enrollmentDate", "enrollmentDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4} >
                                        <Autocomplete
                                            size="small"
                                            options={this.state.CardStatusDD}
                                            getOptionLabel={(option) => option.cardStatus}
                                            id="cardStatusId"
                                            value={this.state.CardStatusDD[this.state.cardStatusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "cardStatusId", "cardStatusId", value)}
                                            renderInput={(params) => <TextField {...params} label="Card Status" variant="outlined"
                                                success={this.state.cardStatusIdState === "success"}
                                                error={this.state.cardStatusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.cardNoState === "success"}
                                            error={this.state.cardNoState === "error"}
                                            value={this.state.cardNo}
                                            size="small"
                                            label="Card No"
                                            id="cardNo"
                                            variant="outlined"
                                            fullWidth
                                            // required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "cardNo", "cardNo", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "isActive", "checkbox")}
                                                checked={this.state.isActive}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Active </span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveEOBIData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateEobiBtnText}  </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextEobiBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"EOBI Details"}
                                    data={EOBIList}
                                    columns={[
                                        {
                                            name: "empEobId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadEobiTextbox(value) }} />);
                                                }
                                            }
                                        },
                                        {
                                            name: "active", label: "Active",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (value) === true ? 'Yes' : (value) === false ? 'No' : "";
                                                }
                                            }
                                        },
                                        { name: "cardNo", label: "Card No" },
                                        { name: "cardStatus", label: "Card Status" },
                                        { name: "enrollmentDate", label: "Enrollment Date" },
                                        { name: "basicSalary", label: "Basic Salary" },
                                        { name: "perAge", label: "Per Page" },
                                        { name: "fromDate", label: "From Date" },
                                        { name: "toDate", label: "To Date" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Social seurty History </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.ssEnrollmentDateState === "success"}
                                            error={this.state.ssEnrollmentDateState === "error"}
                                            size="small"
                                            label="S.S Enrollment Date"
                                            id="ssenrollmentDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.ssEnrollmentDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "ssenrollmentDate", "ssenrollmentDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.expiryDateState === "success"}
                                            error={this.state.expiryDateState === "error"}
                                            size="small"
                                            label="Expiry Date"
                                            id="expiryDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.expiryDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "expiryDate", "expiryDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4} >
                                        <Autocomplete
                                            size="small"
                                            options={this.state.CardStatusDD}
                                            getOptionLabel={(option) => option.cardStatus}
                                            id="sscardStatusId"
                                            value={this.state.CardStatusDD[this.state.sscardStatusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "sscardStatusId", "sscardStatusId", value)}
                                            renderInput={(params) => <TextField {...params} label="S.S Card Status" variant="outlined"
                                                success={this.state.sscardStatusIdState === "success"}
                                                error={this.state.sscardStatusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.sscardNoState === "success"}
                                            error={this.state.sscardNoState === "error"}
                                            value={this.state.sscardNo}
                                            size="small"
                                            label="S.S Card No"
                                            id="sscardNo"
                                            variant="outlined"
                                            fullWidth
                                            // required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "sscardNo", "sscardNo", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveSocialSecurityData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Social Security Details"}
                                    data={SocialSecurityList}
                                    columns={[
                                        {
                                            name: "empSSId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "cardNo", label: "Card No" },
                                        { name: "cardStatus", label: "Card Status" },
                                        { name: "enrollmentDate", label: "Enrollment Date" },
                                        { name: "expiryDate", label: "Expiry Date" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}
export default withStyles(formStyle)(EOBI);