//Import from Globel Component";
import {
    React, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, TextField, Breadcrumbs,
    MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable, Button, formStyle, muiWithToolbar, InputMask,
    tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, validateCheckSetVal, AllDropDowns, Autocomplete
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}
class StdNewRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parentId: -1,
            siblingsList: [],
            studentDOBState: '',
            studentDOB: new Date(),
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionId: -1,
            sessionIdIndex: 0,
            sessionIdState: '',
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionId: -1,
            sectionIdIndex: 0,
            sectionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: 0,
            campusIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classId: -1,
            classIdState: '',
            classIdIndex: 0,
            studentAge: '',
            fatherName: "",
            fatherNameState: "",
            fatherNICState: "",
            fatherNIC: "",
            fatherMobileNo: "",
            fatherMobileNoState: "",
            fatherEmail: "",
            fatherEmailState: "",
            motherName: "",
            motherNameState: "",
            motherNICState: "",
            motherNIC: "",
            motherMobileNo: "",
            motherMobileNoState: "",
            motherEmail: "",
            motherEmailState: "",
            registrationDate: new Date(),
            registrationDateState: '',
            studentName: "", studentNameState: "",
            overSeas: false,
            gender: true,
            genderDD: [{ genderId: -1, name: "Select" }],
            genderId: 1,
            genderIdIndex: 0,
            genderIdState: "",
            saveUpdateBtnDisabled: false,
            showSiblingsTable: false,
            alert: null, show: false, generalError: false, IsAll: true, formName: "New Registration",
            ShowSession: true, ShowCampus: true, ShowLevel: false, ShowClass: true, ShowSection: true,
        }
        this.validateData = this.validateData.bind(this);
        this.SaveNewRegistration = this.SaveNewRegistration.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
    };
    handleToggleChange = (event, checkName) => {
        this.setState({
            [checkName]: event,
            fatherNIC: "",
            motherNIC: "",
        });
    }
    componentDidMount() {
        this.loadGenderDD()
    }
    async loadGenderDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/GenderDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    genderDD: data.genderDDList
                });
                if (data.genderDDList.length > 0) {
                    debugger
                    this.setState({
                        genderId: data.genderDDList[0].genderId,
                        genderIdIndex: 0,
                        genderIdState: "success"
                    });
                }
                else {
                    this.setState({
                        genderId: -1,
                        genderIdIndex: -1,
                        genderIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    change(stateName, value) {
        this.setState({
            stateName: value[stateName],
            [stateName + "Index"]: this.state.genderDD.findIndex(x => x[stateName] === value[stateName]),
            genderId: value.genderId,
            [stateName + "State"]: "success"
        })
    }
    async CheckAndSetStates(event, checkName) {
        switch (checkName) {
            case "fatherNIC":
                debugger;
                this.state.fatherNIC = event.target.value
                checkName = "checkNIC"
                break;
            case "motherNIC":
                debugger;
                this.state.motherNIC = event.target.value
                checkName = "checkNIC"
                break;
            case "fathermobileNumber":
                debugger;
                this.state.fatherMobileNo = event.target.value
                checkName = "mobileNumber"
                break;
            case "mothermobileNumber":
                debugger;
                this.state.motherMobileNo = event.target.value
                checkName = "mobileNumber"
                break;
        }
        var rtnValue = await validateCheckSetVal(event, checkName, this.state);
        debugger
        for (var x = 0; x < rtnValue.length; x++) {
            debugger
            var statename = rtnValue[x].StateName;
            var statevalue = rtnValue[x].StateValue;
            this.setState({ [statename]: statevalue });
        }
    }
    setValue(event) {
        debugger;
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, generalError: false });
    }
    handleChange(event, value) {
        debugger;
        const stateName = event.target.id.split("-")[0];
        this.setState({
            [stateName]: value[stateName],
            [stateName + "State"]: "success",
            generalError: false
        })
    }

    validateData = (event) => {
        debugger;
        var studentDOB = Moment(this.state.studentDOB).format('YYYY-MM-DD');
        var registrationDate = Moment(this.state.registrationDate).format('YYYY-MM-DD')
        if ((this.state.fatherNIC === "") || (this.state.fatherNICState === "error")) {
            this.setState({ fatherNICState: "error", generalError: true });
            return;
        }
        else if ((this.state.motherNIC === "") || (this.state.motherNICState === "error")) {
            this.setState({ motherNICState: "error", generalError: true });
            return;
        }
        else if (this.state.fatherName === "") {
            this.setState({ fatherNameState: "error", generalError: true });
            return;
        }
        else if (this.state.motherName === "") {
            this.setState({ motherNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.fatherMobileNo === "") || (this.state.fatherMobileNoState === "error")) {
            this.setState({ fatherMobileNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.motherMobileNo === "") || (this.state.motherMobileNoState === "error")) {
            this.setState({ motherMobileNoState: "error", generalError: true });
            return;
        }
        else if (this.state.studentName === "") {
            this.setState({ studentNameState: "error", generalError: true });
            return;
        }
        else if (this.state.sessionId < 1) {
            this.setState({ sessionIdState: "error", generalError: true });
            return;
        }
        else if (this.state.campusId < 1) {
            this.setState({ campusIdState: "error", generalError: true });
            return;
        }
        else if (this.state.classId < 1) {
            this.setState({ classIdState: "error", generalError: true });
            return;
        }
        else if (this.state.classId === 44 || this.state.classId === 46 || this.state.classId === 47 || this.state.classId === 51 || this.state.classId === 52) {
            if (this.state.sectionId < 1) {
                this.setState({ sectionIdState: "error", generalError: true });
                return;
            }
        }

        else if (studentDOB === registrationDate) {
            this.setState({ studentDOBState: "error", generalError: true });
            return;
        }
        else if (this.state.studentDOBState === 'error') {
            debugger;
            this.AlertMessage('DOB Error', 'Please enter correct value in DOB')
            return;
        }
        else if (registrationDate > Moment(new Date()).format('YYYY-MM-DD')) {
            this.setState({ registrationDateState: "error" });
            this.AlertMessage('Error', 'Future registration Date is not allowed.')
            return;
        }
        else if (this.state.generalError === true) {
            this.AlertMessage('Error', 'Please enter the value in required fields.')
            return;
        }
        else {
            this.ConfirmMessage("SaveNewRegistration", "Do you want to Register new Student!");
        }
    }
    SaveNewRegistration = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        var studentDOB = Moment(this.state.studentDOB).format('YYYY-MM-DD');
        var registrationDate = Moment(this.state.registrationDate).format('YYYY-MM-DD');
        let RegData = {
            parentId: this.state.parentId,
            fatherNIC: this.state.fatherNIC,
            fatherName: this.state.fatherName,
            fatherEmail: this.state.fatherEmail,
            fatherMobileNo: this.state.fatherMobileNo,
            motherNIC: this.state.motherNIC,
            motherName: this.state.motherName,
            motherEmail: this.state.motherEmail,
            motherMobileNo: this.state.motherMobileNo,
            studentName: this.state.studentName,
            registrationDate: registrationDate,
            studentDOB: studentDOB,
            gender: this.state.genderId,
            sessionId: this.state.sessionId,
            campusId: this.state.campusId,
            classId: this.state.classId,
            sectionId: this.state.sectionId,
            overSeas: this.state.overSeas
        }
        debugger
        axios.post(Base_URL + "/api/StudentRegistration/NewRegistationSave", RegData)
            .then(json => {
                debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.vmResponse.status, json.data.vmResponse.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })
    }
    LoadSiblingParentData = (fatherNic) => {
        if (/*(event.keyCode === 13) &&*/ (fatherNic !== '') && (this.state.fatherNICState !== 'error')) {
            fetch(Base_URL + "/api/StudentRegistration/LoadSiblingsDetails?FatherNIC=" + fatherNic).then(response => { return response.json() })
                .then(data => {
                    if (data.siblingsData != 0) {
                        debugger;
                        var pdata = null;
                        pdata = JSON.parse(data.parentData);
                        this.setState({
                            siblingsList: data.siblingsData,
                            showSiblingsTable: true,
                            parentId: pdata[0].ParentId,
                            fatherName: pdata[0].FatherName,
                            fatherMobileNo: pdata[0].FatherMobileNo,
                            fatherEmail: pdata[0].FatherEmail,
                            motherName: pdata[0].MotherName,
                            motherNIC: pdata[0].MotherNIC,
                            motherEmail: pdata[0].MotherEmail,
                            motherMobileNo: pdata[0].MotherMobileNo,
                        });
                    }
                    else {
                        this.setState({
                            parentId: -1,
                            siblingsList: [],
                            showSiblingsTable: false,
                            fatherName: "",
                            fatherNameState: "",
                            fatherMobileNo: "",
                            fatherMobileNoState: "",
                            fatherEmail: "",
                            fatherEmailState: "",
                            motherName: "",
                            motherNameState: "",
                            motherNICState: "",
                            motherNIC: "",
                            motherMobileNo: "",
                            motherMobileNoState: "",
                            motherEmail: "",
                            motherEmailState: "",
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
        }

    }

    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveNewRegistration()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        debugger;
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        this.ClearTextfieldStates();
    }
    //#endregion

    ClearTextfieldStates = () => {
        window.location.reload(false);
        this.setState({
            parentId: -1,
            siblingsList: [],
            studentDOBState: '',
            studentDOB: new Date(),
            studentAge: '',
            sectionId: -1, sectionIdIndex: 0, sectionIdState: '',
            sessionId: -1, sessionIdIndex: 0, sessionIdState: '',
            campusIdIndex: 0, campusId: 0, campusIdState: '',
            classId: -1, classIdState: '', classIdIndex: 0,
            fatherName: "",
            fatherNameState: "",
            fatherNICState: "",
            fatherNIC: "",
            fatherMobileNo: "",
            fatherMobileNoState: "",
            fatherEmail: "",
            fatherEmailState: "",
            motherName: "",
            motherNameState: "",
            motherNICState: "",
            motherNIC: "",
            motherMobileNo: "",
            motherMobileNoState: "",
            motherEmail: "",
            motherEmailState: "",
            registrationDate: new Date(),
            registrationDateState: '',
            studentName: "", studentNameState: "",
            overSeas: false,
            gender: true,
            saveUpdateBtnDisabled: false,
            showSiblingsTable: false,
            generalError: false,
        })
        this.hideAlert()
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { overSeas, registrationDateState, studentDOBState, } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <RegIcon />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Student New Registration</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }} >
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdManagementHome" >Student Management</Link>
                            <Typography color="textPrimary">New Registration</Typography>
                        </Breadcrumbs>
                        <hr />
                        <GridContainer>
                            {this.state.alert}
                            <div className={classes.mainDiv}>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={6} md={6}>
                                            <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                                <CardHeader color="info" icon>
                                                    <CardIcon color="success">
                                                        <ParentIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Parent Information</h4>
                                                    <hr />
                                                </CardHeader>
                                                <CardBody onClick={() => { this.LoadSiblingParentData(this.state.fatherNIC) }}>
                                                    <GridContainer>
                                                        {this.state.overSeas === false ?
                                                            <GridTextbox xs={12} sm={12} md={6}>
                                                                <InputMask
                                                                    {...this.props}
                                                                    mask="99999-9999999-9"
                                                                    maskChar=""
                                                                    value={this.state.fatherNIC}
                                                                >
                                                                    {() => <TextField
                                                                        success={this.state.fatherNICState === "success"}
                                                                        error={this.state.fatherNICState === "error"}
                                                                        size="small"
                                                                        label="Father CNIC"
                                                                        id="fatherNIC"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required={true}
                                                                        autoComplete="off"
                                                                        placeholder="00000-0000000-0"
                                                                        inputProps={{
                                                                            onChange: event =>
                                                                                this.CheckAndSetStates(event, "fatherNIC"),
                                                                            maxLength: 15,
                                                                        }}
                                                                    />
                                                                    }
                                                                </InputMask>
                                                            </GridTextbox> :
                                                            <GridTextbox xs={12} sm={12} md={6}>
                                                                <TextField
                                                                    success={this.state.fatherNICState === "success"}
                                                                    error={this.state.fatherNICState === "error"}
                                                                    value={this.state.fatherNIC}
                                                                    size="small"
                                                                    label="Father CNIC"
                                                                    id="fatherNIC"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required={true}
                                                                    autoComplete="off"
                                                                    inputProps={{
                                                                        onChange: event =>
                                                                            this.CheckAndSetStates(event, "fatherNIC"),
                                                                        maxLength: 15,
                                                                    }}
                                                                />
                                                            </GridTextbox>
                                                        }
                                                        {this.state.overSeas === false ?
                                                            <GridTextbox xs={12} sm={12} md={6}>
                                                                <InputMask
                                                                    {...this.props}
                                                                    mask="99999-9999999-9"
                                                                    maskChar=""
                                                                    value={this.state.motherNIC}
                                                                >
                                                                    {() => <TextField
                                                                        success={this.state.motherNICState === "success"}
                                                                        error={this.state.motherNICState === "error"}
                                                                        size="small"
                                                                        label="Mother CNIC"
                                                                        id="motherNIC"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required={true}
                                                                        autoComplete="off"
                                                                        placeholder="00000-0000000-0"
                                                                        inputProps={{
                                                                            onChange: event =>
                                                                                this.CheckAndSetStates(event, "motherNIC"),
                                                                            maxLength: 15,
                                                                        }}
                                                                    />
                                                                    }
                                                                </InputMask>
                                                            </GridTextbox> :
                                                            <GridTextbox xs={12} sm={12} md={6}>
                                                                <TextField
                                                                    success={this.state.motherNICState === "success"}
                                                                    error={this.state.motherNICState === "error"}
                                                                    value={this.state.motherNIC}
                                                                    size="small"
                                                                    label="Mother CNIC"
                                                                    id="motherNIC"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required={true}
                                                                    autoComplete="off"

                                                                    inputProps={{
                                                                        onChange: event =>
                                                                            this.CheckAndSetStates(event, "motherNIC"),
                                                                        maxLength: 15,
                                                                    }}
                                                                />
                                                            </GridTextbox>
                                                        }
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <TextField
                                                                success={this.state.fatherNameState === "success"}
                                                                error={this.state.fatherNameState === "error"}
                                                                id="fatherName"
                                                                size="small"
                                                                label="Father Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.fatherName}
                                                                onChange={(event, value) => { this.setValue(event) }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <TextField
                                                                success={this.state.motherNameState === "success"}
                                                                error={this.state.motherNameState === "error"}
                                                                id="motherName"
                                                                size="small"
                                                                label="Mother Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.motherName}
                                                                onChange={(event, value) => { this.setValue(event) }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <InputMask
                                                                {...this.props}
                                                                mask="\3999999999"
                                                                maskChar=""
                                                                value={this.state.fatherMobileNo}
                                                            >
                                                                {() => <TextField
                                                                    success={this.state.fatherMobileNoState === "success"}
                                                                    error={this.state.fatherMobileNoState === "error"}
                                                                    size="small"
                                                                    label="Father Mobile No"
                                                                    id="fatherMobileNo"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required={true}
                                                                    autoComplete="off"
                                                                    placeholder="3001234567"
                                                                    inputProps={{
                                                                        onChange: event =>
                                                                            this.CheckAndSetStates(event, "fathermobileNumber"),
                                                                        maxLength: 10,
                                                                    }}
                                                                // onInput={(e) => {
                                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                                // }}
                                                                />
                                                                }
                                                            </InputMask>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <InputMask
                                                                {...this.props}
                                                                mask="\3999999999"
                                                                maskChar=""
                                                                value={this.state.motherMobileNo}
                                                            >
                                                                {() => <TextField
                                                                    success={this.state.motherMobileNoState === "success"}
                                                                    error={this.state.motherMobileNoState === "error"}
                                                                    size="small"
                                                                    label="Mother Mobile No"
                                                                    id="motherMobileNo"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required={true}
                                                                    autoComplete="off"
                                                                    placeholder="3001234567"
                                                                    inputProps={{
                                                                        onChange: event =>
                                                                            this.CheckAndSetStates(event, "mothermobileNumber"),
                                                                        maxLength: 10,
                                                                    }}
                                                                // onInput={(e) => {
                                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                                // }}
                                                                />
                                                                }
                                                            </InputMask>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <TextField
                                                                success={this.state.fatherEmailState === "success"}
                                                                error={this.state.fatherEmailState === "error"}
                                                                size="small"
                                                                label="Father Email"
                                                                placeholder="someone@mail.com"
                                                                id="fatherEmail"
                                                                variant="outlined"
                                                                value={this.state.fatherEmail}
                                                                fullWidth
                                                                inputProps={{ onChange: event => { this.CheckAndSetStates(event, "email") } }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <TextField
                                                                success={this.state.motherEmailState === "success"}
                                                                error={this.state.motherEmailState === "error"}
                                                                id="motherEmail"
                                                                size="small"
                                                                label="Mother Email"
                                                                variant="outlined"
                                                                fullWidth
                                                                placeholder={"abcd@gmail.com"}
                                                                value={this.state.motherEmail}
                                                                inputProps={{ onChange: event => { this.CheckAndSetStates(event, "email") } }}
                                                            />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={6} md={6}>
                                            <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                                <CardHeader color="info" icon>
                                                    <CardIcon color="info">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Student Registration Detail</h4>
                                                    <hr />
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={8}>
                                                            <TextField
                                                                success={this.state.studentNameState === "success"}
                                                                error={this.state.studentNameState === "error"}
                                                                id="studentName"
                                                                size="small"
                                                                label="Student Name(as Form B)"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.studentName}
                                                                onChange={(event, value) => { this.setValue(event) }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <Autocomplete
                                                                size="small"
                                                                options={this.state.genderDD}
                                                                getOptionLabel={(option) => option.name}
                                                                value={this.state.genderDD[this.state.genderIdIndex]}
                                                                id="genderId"
                                                                disableClearable={true}
                                                                disabled={this.state.genderDisabled}
                                                                onChange={(event, value) => this.change("genderId", value)}
                                                                renderInput={(params) => <TextField {...params} label="Gender *" variant="outlined"
                                                                    success={this.state.genderState === "success"}
                                                                    error={this.state.genderState === "error"} />}
                                                            />
                                                        </GridTextbox>
                                                        {/*<GridTextbox xs={12} sm={12} md={6}>*/}
                                                        {/*    <TextField success={registrationDateState === 'success'}*/}
                                                        {/*        error={registrationDateState === 'error'}*/}
                                                        {/*        id="registrationDate"*/}
                                                        {/*        size="small"*/}
                                                        {/*        label="Registration Date"*/}
                                                        {/*        variant="outlined"*/}
                                                        {/*        fullWidth*/}
                                                        {/*        type="date"*/}
                                                        {/*        value={Moment(this.state.registrationDate).format('YYYY-MM-DD')}*/}
                                                        {/*        onChange={(event, value) => { this.setValue(event) }}*/}
                                                        {/*    />*/}
                                                        {/*</GridTextbox>*/}
                                                        <GridTextbox xs={12} sm={12} md={6}>
                                                            <TextField success={studentDOBState === 'success'}
                                                                error={studentDOBState === "error"}
                                                                id="studentDOB"
                                                                size="small"
                                                                label={"DOB(as Form B): " + this.state.studentAge}
                                                                variant="outlined"
                                                                fullWidth
                                                                type="date"
                                                                value={Moment(this.state.studentDOB).format('YYYY-MM-DD')}
                                                                onChange={(event, value) => { this.CheckAndSetStates(event, "studentDOB") }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <AllDropDowns data={this.state} xsm={12} smm={6} mdm={3} lgm={3} handleStateChange={this.handleChange} />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                            <label htmlFor="small-radius-switch">
                                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "overSeas")} value={overSeas}
                                                                    checked={overSeas}
                                                                    onColor="#3b5998"
                                                                    className={classes.toggleStyle}
                                                                    borderRadius={4}
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                    } />
                                                                <span> Overseas</span>
                                                            </label>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridTextbox xs={12} sm={12} md={1} style={{ marginLeft: "45%" }}>
                                            <Button color="facebook" onClick={(event) => { this.validateData(event) }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Create </Button>
                                        </GridTextbox>
                                        {this.state.showSiblingsTable === true ? <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Siblings Data"}
                                                    data={this.state.siblingsList}
                                                    columns={[
                                                        {
                                                            name: "registrationNo",
                                                            label: "RegistrationNo"
                                                        },
                                                        {
                                                            name: "studentName",
                                                            label: "Student Name"
                                                        },
                                                        {
                                                            name: "className",
                                                            label: "Class"
                                                        },
                                                        {
                                                            name: "sectionName",
                                                            label: "Section"
                                                        },
                                                        {
                                                            name: "fatherNIC",
                                                            label: "Father NIC"
                                                        },
                                                        {
                                                            name: "active",
                                                            label: "Active/Inactive",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (value) === true ? "Active" : (value) === false ? "Inactive" : "";
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: "blackListed",
                                                            label: "Black Listed"
                                                        },
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm> : ""}
                                    </GridContainer>
                                </CardBody>
                            </div>
                        </GridContainer>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdNewRegistration);
