
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, FindInPage, Breadcrumbs, PermContactCalendar, PersonAddOutlined, Autocomplete, FormControl, InputLabel, Select, LocalLibrary,
    Tabs, NavigateNext, NavigateNextIcon, Link, Home, HomeIcon, Typography, GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader,
    CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment
} from '../../../components/Common/ImportAll'

const statusTypeDD = [
    { statusTypeId: 1, value: ' Active' },
    { statusTypeId: 0, value: 'In Active' },
    { statusTypeId: -1, value: 'Both' },
]
var Base_URL = localStorage.BaseURL;

class Employeeissuereturnbook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionId: '',
            ManagementDataList: [],
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: -1,
            campusIdState: "",
            hrLevelDD: [{ hrLevelId: -1, hrLevelName: 'All' }],
            hrLevelIdIndex: 0,
            hrLevelId: -1,
            hrLevelIdState: "",
            departmentDD: [{ departmentId: -1, departmentName: 'All' }],
            departmentIdIndex: 0,
            departmentId: -1,
            departmentIdState: "",
            designationDD: [{ designationId: -1, designationName: 'All' }],
            designationIdIndex: 0,
            designationId: -1,
            designationIdState: "",
            statusTypeDD: [{ statusTypeId: 0, name: "Select" }],
            statusTypeId: 1,
            statusTypeIdIndex: 0,
            statusTypeIdState: "success",
            checkedA: true,
            checkedB: false,
            simpleSelect: "",
            firstname: "",
            firstnameState: "",
            lastname: "",
            lastnameState: "",
            email: "",
            emailState: "",
            empName: "",
            empNameState: "",
            name: "NULL",
        };
    }
    async componentDidMount() {
        debugger;
        this.LoadCampuses();
    }
    async LoadCampuses() {
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ campusId: -1, campusName: 'All' });
                allList = allList.concat(data);
                this.setState({
                    campusDD: allList,
                    campusId: allList[0]['campusId']
                });
                this.state.campusId = allList[0]['campusId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSubLevel() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/HRLevel")
            .then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ hrLevelId: -1, hrLevelName: 'All' });
                allList = allList.concat(data);
                this.setState({
                    hrLevelDD: allList,
                    hrLevelId: allList[0]['hrLevelId'],
                    hrLevelIdIndex: 0
                });
                this.setState.hrLevelId = allList[0]['hrLevelId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadDepartment() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/DepartmentDD?hrLevelId=" + this.state.hrLevelId + "")
            .then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ departmentId: -1, departmentName: 'All' });
                allList = allList.concat(data);
                this.setState({
                    departmentDD: allList,
                    departmentId: allList[0]['departmentId']
                });
                this.setState.departmentId = allList[0]['departmentId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadDesignation() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/DesignationDD?DepartmentId=" + this.state.departmentId + "")
            .then(response => { return response.json(); })
            .then(data => {
                this.setState({
                    designationDD: data,
                    designationId: data[0]['designationId']
                });
                this.setState.designationId = data[0]['designationId']
            }).catch(error => {
                console.log(error);
            });
    }
    searchBtn = () => {
        debugger;
        fetch(Base_URL + "/api/EmployeeApplication/LoadEmployeeManagementData?CampusId=" + this.state.campusId + "&LevelId=" + this.state.hrLevelId + " &DepartmentId=" + this.state.departmentId + " &DesignationId=" + this.state.designationId + "&Name=" + this.state.name + "&Status=" + this.state.statusTypeId + "")
            .then(response => {
                return response.json();
                debugger;
            })
            .then(data => {
                this.setState({
                    ManagementDataList: data
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    setDDValue = (event, value) => {
        debugger
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
        if (dropdownName === 'campusDD') {
            this.state.campusId = value['campusId'];
            this.LoadSubLevel();
        }
        else if (dropdownName == 'hrLevelDD') {
            this.state.hrLevelId = value['hrLevelId'];
            this.LoadDepartment();
        }
        else if (dropdownName == 'departmentDD') {
            this.state.departmentId = value['departmentId'];
            this.LoadDesignation();
        }
        else if (dropdownName == 'designationDD') {
            this.state.designationId = value['designationId'];
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        switch (type) {
            case "fromDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "toDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        toDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "statusTypeId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: statusTypeDD.findIndex(x => x[stateName] === value[stateName]),
                    statusTypeId: value.statusTypeId,
                    [stateName + "State"]: "success"
                })
                break;
            case "empName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        [stateName + "State"]: "success",
                        empName: event.target.value,
                        name: event.target.value,
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        empName: event.target.value,
                        name: event.target.value
                    });

                }
                break;
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { ManagementDataList } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <GridContainer>
                    <CardHeader>
                        <h4 className={classes.cardIconTitle}> Employee Issue Return Book</h4>
                    </CardHeader>
                    <div className={classes.mainDiv}>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                    <GridContainer>
                                       <GridForm xs={12} sm={12} md={12}>
                                          <Card style={{ marginTop: "0px" }}>
                                             <CardBody>
                                                <GridContainer>
                                                   <GridTextbox xs={12} sm={12} md={2}>
                                                     <Autocomplete
                                                        disableClearable
                                                        id="campusId"
                                                        size="small"
                                                        options={this.state.campusDD}
                                                        getOptionLabel={(option) => option.campusName}
                                                        value={this.state.campusDD[this.state.campusIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.campusIdState === "success"}
                                                            error={this.state.campusIdState === "error"}
                                                            label="Sector" variant="outlined" />}
                                                       />
                                                     </GridTextbox>
                                                   <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="hrLevelId"
                                                        size="small"
                                                        options={this.state.hrLevelDD}
                                                        getOptionLabel={(option) => option.hrLevelName}
                                                        value={this.state.hrLevelDD[this.state.hrLevelIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.hrLevelIdState === "success"}
                                                            error={this.state.hrLevelIdState === "error"}
                                                            label="Sub Sector" variant="outlined" />}
                                                    />
                                                   </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                      <Autocomplete
                                                        disableClearable
                                                        id="departmentId"
                                                        size="small"
                                                        options={this.state.departmentDD}
                                                        getOptionLabel={(option) => option.departmentName}
                                                        value={this.state.departmentDD[this.state.departmentIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.departmentIdState === "success"}
                                                            error={this.state.departmentIdState === "error"}
                                                            label="Departments" variant="outlined" />}
                                                        />
                                                     </GridTextbox>
                                                      <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="designationId"
                                                        size="small"
                                                        options={this.state.designationDD}
                                                        getOptionLabel={(option) => option.designationName}
                                                        value={this.state.designationDD[this.state.designationIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.designationIdState === "success"}
                                                            error={this.state.designationIdState === "error"}
                                                            label="Designations" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.empNameState === "success"}
                                                        error={this.state.empNameState === "error"}
                                                        value={this.state.empName}
                                                        size="small"
                                                        label="Emp. Id /Name"
                                                        id="empName"
                                                        variant="outlined"
                                                        fullWidth
                                                        // required={true}
                                                        inputProps={{
                                                            onChange: (event, value) =>
                                                                this.change(event, "empName", "empName", value, 3),
                                                            type: "text",
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Button color="facebook" onClick={this.searchBtn}><SearchSharp />Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                     <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Increment Details"}
                                            data={ManagementDataList}
                                            columns={[
                                                {
                                                    name: "employeeId",
                                                    label: "Edit",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (<Link to={{ pathname: '/app/EditEmployee', empIdinfo: value }}><EditOutlined /></Link>)
                                                            // return (<EditOutlined onClick={() => { this.LoadTextboxw(value) }} />);
                                                        }
                                                    }
                                                },
                                                { name: "regNo", label: "EmployeeId" },
                                                { name: "name", label: "Name" },
                                                { name: "campusName", label: "Sector" },
                                                { name: "hrLevelName", label: "SubSector" },
                                                { name: "departmentName", label: "Department" },
                                                { name: "designationName", label: "Designation" },
                                                
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </GridContainer>
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(Employeeissuereturnbook);

