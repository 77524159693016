import {
    React, SweetAlert, Autocomplete, withStyles, TextField, MuiThemeProvider,  EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment, axios
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class FuelRates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFuelTypeId: 0,
            searchFuelTypeIdIndex: 0,
            fuelRateId: 0,
            saveFuelTypeId: "",
            saveFuelTypeIdState: "",
            fuelRate: 0,
            fuelRateState: "",
            startDate: new Date(),
            startDateState: "",
            endDate: new Date(),
            endDateState:"",
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            saveFuelTypeIdIndex:0,
            fuelTypeDD: [{ fuelTypeId: 0, fuelType: "Select" }],
            fuelTypeDropDown: [{ fuelTypeId: -1, fuelType: "All" }],
            //#region Datatable Columns and Data
            fuelRatesData: [],
            //#region Datatable Columns
            columns: [{
                name: "fuelType",
                label: "Fuel Type",
                options: {
                    filter: true,
                }
            },{
                name: "fuelRate",
                label: "Fuel Rate",
                options: {
                    filter: true,
                }
            }, {
                name: "startDate",
                label: "Start Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY'));
                    }
                }
            }, {
                name: "endDate",
                label: "End Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY'));
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "fuelRateId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.isValidated = this.isValidated.bind(this);
        this.loadFuelRates = this.loadFuelRates.bind(this);
        this.ClearTextfieldStates = this.ClearTextfieldStates.bind(this);
    }

    componentDidMount() {
        this.fuelRatesDD();
        this.LoadFuelRates();
    }
    async fuelRatesDD() {
        fetch(Base_URL + "/api/HR/FuelTypes").then(response => { return response.json(); })
            .then(data => {
                this.setState({ fuelTypeDD: data.fuelTypesList });
                this.setState({ saveFuelTypeId: data.fuelTypesList[0].fuelTypeId, saveFuelTypeIdState: "success" });
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadFuelRates() {
        fetch(Base_URL + "/api/HR/FuelTypes").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ fuelTypeId: -1, fuelType: "All"  });
                allList = allList.concat(data.fuelTypesList);
                this.setState({ fuelTypeDropDown: allList, searchFuelTypeId: allList[0]['fuelTypeId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    loadFuelRates = () => {
        const requestOptions = {
            method: 'GET'
        };
        fetch(Base_URL + "/api/primaryDataHR/FuelRates?FuelTypeId=" + this.state.searchFuelTypeId, requestOptions).then(response => { return response.json(); })
            .then(data => {
                this.setState({ fuelRatesData: data.fuelRatesList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleChange(value, type) {
        switch (type) {
            case "searchFuelTypeId":
                if (value !== null) {
                    this.setState({
                        searchFuelTypeId: value.fuelTypeId,
                        searchFuelTypeIdIndex: this.state.fuelTypeDropDown.findIndex(x => x.fuelTypeId === value.fuelTypeId),
                    });
                } else {
                    this.setState({
                        searchFuelTypeId: 0,
                        searchFuelTypeIdIndex: -1,
                    });
                }
                break;
            case "saveFuelTypeId":
                if (value !== null) {
                    this.setState({ saveFuelTypeId: value.fuelTypeId, saveFuelTypeIdState: "success", 
                    saveFuelTypeIdIndex: this.state.fuelTypeDD.findIndex(x => x.fuelTypeId === value.fuelTypeId) });
                } else {
                    this.setState({ saveFuelTypeId: 0, saveFuelTypeIdState: "error" });
                }
                break;
            default:
                break;
        }
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "fuelRate":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
                case "startDate":
                    this.setState({ stateName: event.target.value });
                    break;
                    case "endDate":
                    this.setState({ stateName: event.target.value });
                    break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        if (this.state.fuelRateState === "error" || this.state.fuelRateState === "") {
            this.setState({ fuelRateState: "error" })
            return;
        }
        else if (this.state.saveFuelTypeIdState !== "success" || this.state.saveFuelTypeId === 0) {
            this.setState({ saveFuelTypeIdState: "error" })
            return;
        } else if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
            this.setState({ startDateState: "error", endDateState: "error" })
            return;
        }
        else {
            this.setState({ startDateState: "success", endDateState: "success" })
            if(this.state.fuelRateId === 0){
                this.confirmMessageSave();
            }
            else
            {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ FuelRateId: this.state.fuelRateId, fuelRate: +this.state.fuelRate, FuelTypeId: this.state.saveFuelTypeId, StartDate: this.state.startDate, EndDate: this.state.endDate })
        };
        fetch(Base_URL + "/api/primaryDataHR/FuelRates", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Fuel Rate will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Fuel Rate will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside = {false}
                    >{message}</SweetAlert>
                )
            });
            
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside = {false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (fuelRateId) => {
        let obj = this.state.fuelRatesData.find(o => o.fuelRateId === fuelRateId);
        this.setState({ fuelRateId: obj.fuelRateId, saveFuelTypeId: obj.fuelTypeId, saveFuelTypeIdState: "success", fuelRate: obj.fuelRate, fuelRateState: "success", startDate: new Date(obj.startDate), endDate: new Date(obj.endDate), saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        this.setState({ saveFuelTypeIdIndex: this.state.fuelTypeDD.findIndex(x => x.fuelTypeId === obj.fuelTypeId) });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, fuelRateId: 0, saveFuelTypeIdState: "success", fuelRate: 0, fuelRateState: "", startDate: new Date(), endDate: new Date(), saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.setState({ saveFuelTypeIdIndex: 0, saveFuelTypeId: this.state.fuelTypeDD[0].fuelTypeId });
        this.loadFuelRates();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12} lg={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Fuel Rates</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.fuelTypeDropDown}
                                                    getOptionLabel={(option) => option.fuelType}
                                                    value={this.state.fuelTypeDropDown[this.state.searchFuelTypeIdIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchFuelTypeId")}
                                                    renderInput={(params) => <TextField {...params} label="Fuel Type" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadFuelRates} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Fuel Rates</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                        <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.fuelTypeDD}
                                                    getOptionLabel={(option) => option.fuelType}
                                                    value={this.state.fuelTypeDD[this.state.saveFuelTypeIdIndex]}
                                                    disableClearable = {true}
                                                    onChange={(event, value) => this.handleChange(value, "saveFuelTypeId")}
                                                    renderInput={(params) => <TextField {...params} label="Fuel Type" variant="outlined"
                                                        success={this.state.saveFuelTypeIdState === "success"}
                                                        error={this.state.saveFuelTypeIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.fuelRateState === "success"}
                                                    error={this.state.fuelRateState === "error"}
                                                    value={this.state.fuelRate}
                                                    size="small"
                                                    label="Fuel Rate"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type= "number"
                                                    inputProps={{ onChange: event => this.change(event, "fuelRate", "fuelRate", 2) }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.startDateState === "success"}
                                                        error={this.state.startDateState === "error"}
                                                        size="small"
                                                        label="Start Date"
                                                        id="startDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.startDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "startDate", "startDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.endDateState === "success"}
                                                        error={this.state.endDateState === "error"}
                                                        size="small"
                                                        label="End Date"
                                                        id="endDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.endDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "endDate", "endDate") }}
                                                    />
                                                </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Fuel Rates"}
                                        data={this.state.fuelRatesData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(FuelRates);