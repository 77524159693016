
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, SearchSharp,
    AddIcon, EditOutlined, Edit
}
    from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class ProjectDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            active: true,
            searchExceptionList: [],
        };
        //#endregion 
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //Creat Functions by Azeem
    Searchexceptions = () => {
        debugger;
        //fetch(Base_URL + "api/Projectexceptions/Searchexceptions").then(response => { return response.json(); })
        //    .then(data => {
        //        debugger;
        //        this.setState({ searchExceptionList: data });
        //    }).catch(error => {
        //        console.log(error);
        //    });
        fetch(Base_URL + "api/ProjectExceptions/SearchExceptions").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.ExceptionToken === null || data.exception.DisplayMessage === null) {
                    this.setState({ searchExceptionList: data.searchexceptions });
                }
                else {
                }

            }).catch(error => {
                console.log(error);
            });
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }

    //#region Form Body and Return

    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (

                < Button color={prop.color}
                    className={classes.actionButton}
                    key={key}>

                    < prop.icon className={classes.icon} />

                </ Button >
            );
        });

        return (
            <GridContainer>
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12}
                                sm={12}
                                md={12}>
                                <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <CardBody>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Project Directory Exceptions Detail</h4>
                                        </CardHeader>
                                        <hr />
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                <TextField
                                                    size="small"
                                                    label="From"
                                                    id="endDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={Moment(endDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    onChange={this.setDateValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    size="small"
                                                    label="To"
                                                    id="endDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={Moment(endDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    onChange={this.setDateValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12}
                                                sm={12}
                                                lg={1}>
                                                <Button color="facebook" onclick={this.Searchexceptions()}><SearchSharp />Search</Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12}
                                sm={12}
                                md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Project Directory Exceptions Detail"}
                                        columns={
                                            [
                                                { label: "Directory ID" }, { label: "Module" },
                                                { label: "Controller" }, { label: "Method/Function Name" },
                                                { label: "Form Name" }, { label: "Form Type Name" },
                                                { label: "Error Message" }, { label: "File Name" },
                                                { label: "Line Numer" }, { label: "Modifier" },
                                                { label: "Modified date" }
                                            ]}
                                        options={tbleWithPrint} />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(ProjectDirectory);