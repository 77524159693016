//Import from Globel Component";
import {
    React, axios, Moment, InputMask, ReactToggle, SweetAlert, SaveOutlined, formStyle, withStyles, Autocomplete, TextField, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, Button
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;
class OtherInfo extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            NextOfKinList: [],
            AccommodationList: [],
            SpecialEmployeeList: [],
            DrivingLicenseList: [],
            CitizenshipList: [],
            EmergencyContactList: [],
            //form Ids
            kinId: 0,
            acdId: 0,
            emgId: 0,
            spcId: 0,
            dlId: 0,
            csId: 0,

            //first form
            citizenId: "",
            citizenIdIndex: 0,
            citizenIdState: "",
            entryDate: new Date(),
            entryDateState: "",
            visaTypeId: "",
            visaTypeIdIndex: 0,
            visaTypeIdState: "",
            visaExpiryDate: new Date(),
            visaExpiryDateState: "",
            entryDateDisabled: true,
            visaDateDisabled: true,
            visatypeDisabled: true,
            citizenshipcolor: 'facebook',
            saveUpdateCitizenshipBtnText: 'Save',

            //2nnd from
            drivingLicense: "",
            drivingLicenseState: "",
            licenseExpiryDate: new Date(),
            licenseExpiryDateState: "",
            saveUpdateDrivingBtnDisabled: 'Save',
            drivingcolor: 'facebook',

            //3rd form
            emergencyName: "",
            emergencyNameState: "",
            relationId: "",
            relationIdIndex: 0,
            relationIdState: "",
            address: "",
            addressState: "",
            email: "",
            emailState: "",
            contactPrimary: "",
            contactPrimaryState: "",
            contactAlternate: "",
            contactAlternateState: "",
            saveUpdateEmergencyBtnText: 'Save',
            emergencycolor: 'facebook',

            //4th form
            waiveoffReason: "",
            waiveoffReasonState: "",
            attendanceReason: "",
            attendanceReasonState: "",
            lateArrival: false,
            attendanceOptional: false,
            saveUpdateSpecialBtnText: 'Save',
            specialcolor: 'facebook',

            //5th form 
            kinName: "",
            kinNameState: "",
            kinPhone: "",
            kinPhoneState: "",
            kinEmployer: "",
            kinEmployerState: "",
            kinRelation: "",
            kinRelationIndex: 0,
            kinRelationState: "",
            kinProfession: "",
            kinProfessionState: "",
            kincolor: 'facebook',
            saveUpdatekinBtnText: 'Save',

            //6th form
            accommodationId: "",
            accommodationIdIndex: 0,
            accommodationIdState: "",
            isAccommodation: false,
            saveUpdateAccBtnText: 'Save',
            Acccolor: 'facebook',


            CitizenDD: [{ citizenId: -1, citizenName: "Select" }],
            VisaTypeDD: [{ visaTypeId: -1, visaTypeName: "Select" }],
            RelationDD: [{ relationId: -1, relationName: "Select" }],
            AccommodationDD: [{ accommodationId: -1, accommodationName: "Select" }],
            alert: null,
            show: false,
            generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.RelationDD();
        this.CitizenDD();
        this.VisaTypeDD();
        this.AccommodationDD();

        this.LoadNextOfKinData();
        this.LoadAccommodationData();
        this.LoadSpecialEmployeeData();
        this.LoadEmergencyContactData();
        this.LoadDriverLicenseData();
        this.LoadCitizenshipData();
    }
    async RelationDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/RelationDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    RelationDD: data.kinRelationDDList
                });
                if (data.kinRelationDDList.length > 0) {
                    this.setState({
                        relationId: data.kinRelationDDList[0].relationId,
                        relationIdState: "success",
                        kinRelation: data.kinRelationDDList[0].relationId,
                        kinRelationState: "success"
                    });
                }
                else {
                    this.setState({
                        relationId: 0,
                        relationIdIndex: -1,
                        relationIdtate: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async CitizenDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/CitizenDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    CitizenDD: data.citizenDDList
                });
                if (data.citizenDDList.length > 0) {
                    this.setState({
                        citizenId: data.citizenDDList[0].citizenId,
                        citizenIdIndex: 0,
                        citizenIdState: "success"
                    });
                }
                else {
                    this.setState({
                        citizenId: 0,
                        citizenIdIndex: -1,
                        citizenIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async VisaTypeDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/VisaTypeDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    VisaTypeDD: data.visaTypeDDList
                });
                if (data.visaTypeDDList.length > 0) {
                    this.setState({
                        visaTypeId: data.visaTypeDDList[0].visaTypeId,
                        visaTypeIdIndex: 0,
                        visaTypeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        visaTypeId: 0,
                        visaTypeIdIndex: -1,
                        visaTypeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async AccommodationDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/AccommodationDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AccommodationDD: data.accommodationDDList
                });
                if (data.accommodationDDList.length > 0) {
                    this.setState({
                        accommodationId: data.accommodationDDList[0].accommodationId,
                        accommodationIdIndex: 0,
                        accommodationIdState: "success"
                    });
                }
                else {
                    this.setState({
                        accommodationId: 0,
                        accommodationIdIndex: -1,
                        accommodationIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }



    //SaveCitizenshipData
    SaveNextofKinData = () => {
        debugger;
        if ((this.state.kinNameState === "") || (this.state.kinNameState === "error")) {
            this.setState({ kinNameState: "error", generalError: true });
            return;
        }
        else if (this.state.kinPhone === "" || this.state.kinPhone === 0 || this.state.kinPhoneState === "error") {
            this.setState({ kinPhoneState: "error", generalError: true });
            return;
        }
        else if (this.state.kinEmployer === "" || this.state.kinEmployerState === "error") {
            this.setState({ kinEmployerState: "error", generalError: true });
            return;
        }
        else if (this.state.kinRelation === "" || this.state.kinRelation === 0 || this.state.kinRelationState === "error") {
            this.setState({ kinRelationState: "error", generalError: true });
            return;
        }
        else if (this.state.kinProfession === "" || this.state.kinProfessionState === "error") {
            this.setState({ kinProfessionState: "error", generalError: true });
            return;
        }
        let KinInfo = {
            NextOfKinId: this.state.kinId,
            EmployeeId: this.state.employeeId,
            NextOfKinName: this.state.kinName,
            NextOfKinPhone: this.state.kinPhone,
            NextOfKinEmployer: this.state.kinEmployer,
            NextOfKinRelation: this.state.kinRelation,
            NextOfKinProfession: this.state.kinProfession
        };
        console.log(KinInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveNextofKin', KinInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveAccommodationData = () => {
        debugger;
        if ((this.state.accommodationId === "") || (this.state.accommodationId === 0) || (this.state.accommodationIdState === "error")) {
            this.setState({ accommodationIdState: "error", generalError: true });
            return;
        }
        else if (this.state.isAccommodation === "" || this.state.isAccommodation === null) {
            return;
        }
        let AccommodationInfo = {
            EmployeeAccId: this.state.acdId,
            EmployeeId: this.state.employeeId,
            AccommodationId: this.state.accommodationId,
            Active: this.state.isAccommodation,
        };
        console.log(AccommodationInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveAccommodation', AccommodationInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveEmergencyContactData = () => {
        debugger;
        if ((this.state.emergencyName === "") || (this.state.emergencyNameState === "error")) {
            this.setState({ emergencyNameState: "error", generalError: true });
            return;
        }
        else if (this.state.relationId === "" || this.state.relationId === 0 || this.state.relationIdState === "error") {
            this.setState({ relationIdState: "error", generalError: true });
            return;
        }
        else if (this.state.address === "" || this.state.addressState === "error") {
            this.setState({ addressState: "error", generalError: true });
            return;
        }
        else if (this.state.email === "" || this.state.emailState === "error") {
            this.setState({ emailState: "error", generalError: true });
            return;
        }
        else if (this.state.contactPrimary === "" || this.state.contactPrimaryState === "error") {
            this.setState({ contactPrimaryState: "error", generalError: true });
            return;
        }
        let EmergencyContactInfo = {
            EmergencyContactId: this.state.emgId,
            EmployeeId: this.state.employeeId,
            Name: this.state.emergencyName,
            RelationId: this.state.relationId,
            Address: this.state.address,
            Email: this.state.email,
            ContactPrimary: this.state.contactPrimary,
            ContactAlternate: this.state.contactAlternate,
        };
        console.log(EmergencyContactInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmergencyContact', EmergencyContactInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveSpecialEmployeeData = () => {
        debugger;
        if ((this.state.waiveoffReason === "") || (this.state.waiveoffReasonState === "error")) {
            this.setState({ waiveoffReasonState: "error", generalError: true });
            return;
        }
        else if (this.state.attendanceReason === "" || this.state.attendanceReasonState === "error") {
            this.setState({ attendanceReasonState: "error", generalError: true });
            return;
        }
        else if (this.state.lateArrival === "" || this.state.lateArrival === null) {
            return;
        }
        else if (this.state.attendanceOptional === "" || this.state.attendanceOptional === null) {
            return;
        }
        let SpecialEmployeeInfo = {
            SpecialEmployeeId: this.state.spcId,
            EmployeeId: this.state.employeeId,
            LateWaiveoff: this.state.lateArrival,
            ReasonWaiveoff: this.state.waiveoffReason,
            Attendance: this.state.attendanceOptional,
            ReasonAttendance: this.state.attendanceReason,
        };
        console.log(SpecialEmployeeInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveSpecialEmployee', SpecialEmployeeInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveDrivingLicenseData = () => {
        debugger;
        if ((this.state.drivingLicense === "") || (this.state.drivingLicense === 0) || (this.state.drivingLicenseState === "error")) {
            this.setState({ drivingLicenseState: "error", generalError: true });
            return;
        }
        else if (this.state.licenseExpiryDate === "" || this.state.licenseExpiryDate === null || (this.state.licenseExpiryDateState === "error")) {
            this.setState({ licenseExpiryDateState: "error", generalError: true });
            return;
        }
        let DrivingLicenseInfo = {
            DriverLicenseId: this.state.dlId,
            EmployeeId: this.state.employeeId,
            DriverLicenseNumber: this.state.drivingLicense,
            LicenseExpiryDate: this.state.licenseExpiryDate,
        };
        console.log(DrivingLicenseInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveDrivingLicense', DrivingLicenseInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveCitizenshipData = () => {
        debugger;
        var findCizenName = this.state.CitizenDD.find(x => x.citizenId === this.state.citizenId).citizenName;
        if ((this.state.citizenId === "") || (this.state.citizenId === 0) || (this.state.citizenIdState === "error")) {
            this.setState({ citizenIdState: "error", generalError: true });
            return;
        }

        else if (findCizenName === "Overseas" || findCizenName === "overseas") {
            if (this.state.visaTypeId === "" || this.state.visaTypeId === -1 || this.state.visaTypeId === 0 || this.state.visaTypeIdState === "error") {
                this.setState({ visaTypeIdState: "error", generalError: true });
                return;
            }
            if (this.state.entryDate === "" || this.state.entryDate === null || this.state.entryDateState === "error") {
                this.setState({ entryDateState: "error", generalError: true });
                return;
            }
            if (this.state.visaExpiryDate === "" || this.state.visaExpiryDate === null || this.state.visaExpiryDateState === "error") {
                this.setState({ visaExpiryDateState: "error", generalError: true });
                return;
            }

        }
        else {
            this.state.visaTypeId = null;
            this.state.entryDate = null;
            this.state.visaExpiryDate = null;
        }

        let CitizenshipInfo = {
            CitizenshipId: this.state.csId,
            EmployeeId: this.state.employeeId,
            CitizenId: this.state.citizenId,
            VisaTypeId: this.state.visaTypeId,
            EntryDate: this.state.entryDate,
            VisaExpiry: this.state.visaExpiryDate,
        };
        console.log(CitizenshipInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveCitizenship', CitizenshipInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }


    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert();
    }
    hideAlert() {
        this.setState({
            alert: null,
            kinId: 0,
            acdId: 0,
            emgId: 0,
            spcId: 0,
            dlId: 0,
            csId: 0,
            entryDate: new Date(),
            visaExpiryDate: new Date(),
        });
        this.LoadNextOfKinData();
        this.LoadAccommodationData();
        this.LoadSpecialEmployeeData();
        this.LoadEmergencyContactData();
        this.LoadDriverLicenseData();
        this.LoadCitizenshipData();
    }
    //Load Data for update 
    async LoadNextOfKinData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadNextOfKinData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    NextOfKinList: data.nextOfkindataList
                });
                if (data.nextOfkindataList.length > 0) {
                    debugger;
                    this.setState({
                        kinName: data.nextOfkindataList[0].nextOfKinName,
                        kinPhone: data.nextOfkindataList[0].nextOfKinPhone,
                        kinEmployer: data.nextOfkindataList[0].nextOfKinEmployer,
                        kinProfession: data.nextOfkindataList[0].nextOfKinProfession,
                        kinRelation: data.nextOfkindataList[0].nextOfKinRelation,
                        kinId: data.nextOfkindataList[0].nextOfKinId,
                        kinRelationIndex: this.state.RelationDD.findIndex(x => x.relationId === data.nextOfkindataList[0].nextOfKinRelation),
                        saveUpdatekinBtnText: 'Update',
                        kincolor: "success",
                        kinNameState: "success",
                        kinPhoneState: "success",
                        kinEmployerState: "success",
                        kinRelationState: "success",
                        kinProfessionState: "success",
                    });
                }
                else {
                    throw Error(response.statusText);
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadAccommodationData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadAccommodationData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AccommodationList: data.accommodationdataList
                });
                if (data.accommodationdataList.length > 0) {
                    debugger;
                    this.setState({
                        acdId: data.accommodationdataList[0].employeeAccId,
                        accommodationId: data.accommodationdataList[0].accommodationId,
                        isAccommodation: data.accommodationdataList[0].active,
                        accommodationIdIndex: this.state.AccommodationDD.findIndex(x => x.accommodationId === data.accommodationdataList[0].accommodationId),
                        saveUpdateAccBtnText: 'Update',
                        Acccolor: "success",
                        accommodationIdState: "success",
                    });
                }
                else {
                    throw Error(response.statusText)
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadEmergencyContactData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadEmergencyContactData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    EmergencyContactList: data.emergencyContactdataList
                });
                if (data.emergencyContactdataList.length > 0) {
                    debugger;
                    this.setState({
                        emgId: data.emergencyContactdataList[0].emergencyContactId,
                        emergencyName: data.emergencyContactdataList[0].name,
                        address: data.emergencyContactdataList[0].address,
                        email: data.emergencyContactdataList[0].email,
                        contactPrimary: data.emergencyContactdataList[0].contactPrimary,
                        contactAlternate: data.emergencyContactdataList[0].contactAlternate,
                        relationId: data.emergencyContactdataList[0].relationId,
                        relationIdIndex: this.state.RelationDD.findIndex(x => x.relationId === data.emergencyContactdataList[0].relationId),
                        saveUpdateEmergencyBtnText: 'Update',
                        emergencycolor: "success",
                        emergencyNameState: "success",
                        relationIdState: "success",
                        addressState: "success",
                        emailState: "success",
                        contactPrimaryState: "success",
                    });
                }
                else {
                    throw Error(response.statusText)
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadSpecialEmployeeData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadSpecialEmployeeData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    SpecialEmployeeList: data.specialEmployeedataList
                });
                if (data.specialEmployeedataList.length > 0) {
                    debugger;
                    this.setState({
                        spcId: data.specialEmployeedataList[0].specialEmployeeId,
                        lateArrival: data.specialEmployeedataList[0].lateWaiveoff,
                        waiveoffReason: data.specialEmployeedataList[0].reasonWaiveoff,
                        attendanceOptional: data.specialEmployeedataList[0].attendance,
                        attendanceReason: data.specialEmployeedataList[0].reasonAttendance,
                        saveUpdateSpecialBtnText: 'Update',
                        specialcolor: "success",
                        waiveoffReasonState: "success",
                        attendanceReasonState: "success",
                    });
                }
                else {
                    throw Error(response.statusText)
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadDriverLicenseData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadDriverLicenseData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    DrivingLicenseList: data.drivingLicensedataList
                });
                if (data.drivingLicensedataList.length > 0) {
                    debugger;
                    this.setState({
                        dlId: data.drivingLicensedataList[0].driverLicenseId,
                        drivingLicense: data.drivingLicensedataList[0].driverLicenseNumber,
                        licenseExpiryDate: data.drivingLicensedataList[0].licenseExpiryDate,
                        saveUpdateDrivingBtnDisabled: 'Update',
                        drivingcolor: "success",
                        drivingLicenseState: "success",
                        licenseExpiryDateState: "success",
                    });
                }
                else {
                    throw Error(response.statusText)
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadCitizenshipData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadCitizenshipData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    CitizenshipList: data.citizenshipdataList
                });
                if (data.citizenshipdataList.length > 0) {
                    debugger;
                    this.setState({
                        csId: data.citizenshipdataList[0].citizenshipId,
                        citizenId: data.citizenshipdataList[0].citizenId,
                        citizenIdIndex: this.state.CitizenDD.findIndex(x => x.citizenId === data.citizenshipdataList[0].citizenId),
                        saveUpdateCitizenshipBtnText: 'Update',
                        citizenshipcolor: "success",
                        citizenIdState: "success",
                    });
                    var CizenName = this.state.CitizenDD.find(x => x.citizenId === this.state.citizenId).citizenName;
                    if (CizenName === "Overseas" || CizenName === "overseas") {
                        this.setState({
                            visaTypeId: data.citizenshipdataList.citizenshipdataList[0].visaTypeId,
                            visaTypeIdIndex: this.state.VisaTypeDD.findIndex(x => x.visaTypeId === data.citizenshipdataList[0].visaTypeId),
                            entryDate: data.citizenshipdataList[0].EntryDate,
                            visaExpiryDate: data.citizenshipdataList[0].visaExpiry,
                            entryDateDisabled: false,
                            visaDateDisabled: false,
                            visatypeDisabled: false,
                            visaTypeIdState: "success",
                            entryDateState: "success",
                            visaExpiryDateState: "success",
                        });
                    }
                    else {
                        this.setState({
                            entryDateDisabled: true,
                            visaDateDisabled: true,
                            visatypeDisabled: true,
                            entryDate: null,
                            visaExpiryDate: null,
                            visaTypeId: 0,
                            visaTypeIdIndex: -1,
                        });
                    }
                }
                else {
                    throw Error(response.statusText)
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }





    verifyEmail(value) {
        debugger
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    verifyMobile(value) {
        debugger
        var mobileRex = /^([3]{1})([0-9]{9})$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "citizenId":
                debugger
                if (this.state.CitizenDD.find(x => x.citizenId === value.citizenId)) {
                    this.state.citizenId = value.citizenId;
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.CitizenDD.findIndex(x => x[stateName] === value[stateName]),
                        [stateName + "State"]: "success"
                    });
                    var CizenName = this.state.CitizenDD.find(x => x.citizenId === this.state.citizenId).citizenName;
                    if (CizenName === "Overseas" || CizenName === "overseas") {
                        this.setState({
                            entryDateDisabled: false,
                            visaDateDisabled: false,
                            visatypeDisabled: false,
                            entryDate: new Date(),
                            visaExpiryDate: new Date(),
                        });
                        this.VisaTypeDD();
                    }
                    else {
                        this.setState({
                            entryDateDisabled: true,
                            visaDateDisabled: true,
                            visatypeDisabled: true,
                            entryDate: null,
                            visaExpiryDate: null,
                            visaTypeId: 0,
                            visaTypeIdIndex: -1,
                        });
                    }

                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.CitizenDD.findIndex(x => x[stateName] === value[stateName]),
                        citizenId: value.citizenId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "visaTypeId":
                debugger
                if (this.state.VisaTypeDD.find(x => x.visaTypeId === value.visaTypeId)) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.VisaTypeDD.findIndex(x => x[stateName] === value[stateName]),
                        visaTypeId: value.visaTypeId,
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.VisaTypeDD.findIndex(x => x[stateName] === value[stateName]),
                        visaTypeId: value.visaTypeId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "entryDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        entryDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "visaExpiryDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        visaExpiryDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;

            case "drivingLicense":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        drivingLicense: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        drivingLicense: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;
            case "licenseExpiryDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        licenseExpiryDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;


            case "emergencyName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        emergencyName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        emergencyName: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;
            case "address":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        address: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        address: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        email: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        email: event.target.value,
                    });
                }
                break;
            case "contactPrimary":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        contactPrimary: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        contactPrimary: event.target.value,
                    });
                }
                break;
            case "contactAlternate":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        contactAlternate: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        contactAlternate: event.target.value,
                    });
                }
                break;
            case "relationId":
                debugger
                if (this.state.RelationDD.find(x => x.relationId === value.relationId)) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.RelationDD.findIndex(x => x[stateName] === value[stateName]),
                        relationId: value.relationId,
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.RelationDD.findIndex(x => x[stateName] === value[stateName]),
                        relationId: value.cardStatusId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "waiveoffReason":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        waiveoffReason: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        waiveoffReason: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;
            case "attendanceReason":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        attendanceReason: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        attendanceReason: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;

            case "accommodationId":
                debugger
                if (this.state.AccommodationDD.find(x => x.accommodationId === value.accommodationId)) {
                    this.setState({
                        accommodationIdIndex: this.state.AccommodationDD.findIndex(x => x.accommodationId === value.accommodationId),
                        accommodationId: value.accommodationId,
                        accommodationIdState: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        sscardStatusIdIndex: this.state.AccommodationDD.findIndex(x => x.accommodationId === value.accommodationId),
                        accommodationId: value.cardStatusId,
                        sscardStatusIdState: "success"
                    });

                }
                break;


            case "kinName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        kinName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        kinName: event.target.value,
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "kinPhone":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        kinPhone: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        kinPhone: event.target.value,
                        [stateName + "State"]: "error",
                    });
                }
                break;
            case "kinEmployer":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        kinEmployer: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        kinEmployer: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;
            case "kinRelation":
                debugger
                if (this.state.RelationDD.find(x => x.relationId === value.relationId)) {
                    this.setState({
                        stateName: value[stateName],
                        relationIdIndex: this.state.RelationDD.findIndex(x => x.relationId === value.relationId),
                        kinRelation: value.relationId,
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        relationIdIndex: this.state.RelationDD.findIndex(x => x.relationId === value.relationId),
                        kinRelation: value.relationId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "kinProfession":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        kinProfession: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        kinProfession: event.target.value,
                        [stateName + "State"]: "error",
                    })
                }
                break;

            case "checkbox":
                this.setState({ [stateName]: event });
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Emergency Contact </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.emergencyNameState === "success"}
                                            error={this.state.emergencyNameState === "error"}
                                            value={this.state.emergencyName}
                                            size="small"
                                            label="Name"
                                            id="emergencyName"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "emergencyName", "emergencyName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.RelationDD}
                                            getOptionLabel={(option) => option.relationName}
                                            id="relationId"
                                            value={this.state.RelationDD[this.state.relationIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "relationId", "relationId", value)}
                                            renderInput={(params) => <TextField {...params} label="Relation" variant="outlined"
                                                success={this.state.relationIdState === "success"}
                                                error={this.state.relationIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.addressState === "success"}
                                            error={this.state.addressState === "error"}
                                            value={this.state.address}
                                            size="small"
                                            label="Address"
                                            id="address"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "address", "address", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.emailState === "success"}
                                            error={this.state.emailState === "error"}
                                            value={this.state.email}
                                            size="small"
                                            label="Email"
                                            id="email"
                                            placeholder="someone@mail.com"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event) =>
                                                    this.change(event, "email", "email")
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <InputMask
                                            {...this.props}
                                            mask="\3999999999"
                                            maskChar=""
                                            value={this.state.contactPrimary}
                                        >
                                            {() => <TextField
                                                success={this.state.contactPrimaryState === "success"}
                                                error={this.state.contactPrimaryState === "error"}
                                                size="small"
                                                label="Contact(Primary No)"
                                                id="contactPrimary"
                                                variant="outlined"
                                                fullWidth
                                                required={true}
                                                autoComplete="off"
                                                placeholder="3001234567"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.change(event, "contactPrimary", "contactPrimary"),
                                                    maxLength: 10,
                                                }}
                                            />
                                            }
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <InputMask
                                            {...this.props}
                                            mask="\3999999999"
                                            maskChar=""
                                            value={this.state.contactAlternate}
                                        >
                                            {() => <TextField
                                                success={this.state.contactAlternateState === "success"}
                                                error={this.state.contactAlternateState === "error"}
                                                size="small"
                                                label="Contact(Alternate)"
                                                id="contactAlternate"
                                                variant="outlined"
                                                fullWidth
                                                required={true}
                                                autoComplete="off"
                                                placeholder="3001234567"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.change(event, "contactAlternate", "contactAlternate"),
                                                    maxLength: 10,
                                                }}
                                            />
                                            }
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Button color={this.state.emergencycolor} onClick={() => { this.SaveEmergencyContactData() }}><SaveOutlined /> {this.state.saveUpdateEmergencyBtnText} </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Special Employee</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "lateArrival", "checkbox")}
                                                checked={this.state.lateArrival}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Late Arrival Waive off </span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={7}>
                                        <TextField
                                            success={this.state.attendanceReasonState === "success"}
                                            error={this.state.attendanceReasonState === "error"}
                                            value={this.state.attendanceReason}
                                            size="small"
                                            label="Reason for Attendance"
                                            id="attendanceReason"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "attendanceReason", "attendanceReason", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "attendanceOptional", "checkbox")}
                                                checked={this.state.attendanceOptional}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Attendance Optional </span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={7}>
                                        <TextField
                                            success={this.state.waiveoffReasonState === "success"}
                                            error={this.state.waiveoffReasonState === "error"}
                                            value={this.state.waiveoffReason}
                                            size="small"
                                            label="Reason for Waive off"
                                            id="waiveoffReason"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "waiveoffReason", "waiveoffReason", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <Button color={this.state.specialcolor} onClick={() => { this.SaveSpecialEmployeeData() }}><SaveOutlined /> {this.state.saveUpdateSpecialBtnText} </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Citizenship </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.CitizenDD}
                                            getOptionLabel={(option) => option.citizenName}
                                            id="citizenId"
                                            value={this.state.CitizenDD[this.state.citizenIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "citizenId", "citizenId", value)}
                                            renderInput={(params) => <TextField {...params} label="Citizenship " variant="outlined"
                                                success={this.state.citizenIdState === "success"}
                                                error={this.state.citizenIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.entryDateState === "success"}
                                            error={this.state.entryDateState === "error"}
                                            size="small"
                                            label="Entry Date in PK"
                                            id="entryDate"
                                            variant="outlined"
                                            fullWidth
                                            disabled={this.state.entryDateDisabled}
                                            value={Moment(this.state.entryDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "entryDate", "entryDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.VisaTypeDD}
                                            getOptionLabel={(option) => option.visaTypeName}
                                            id="visaTypeId"
                                            value={this.state.VisaTypeDD[this.state.visaTypeIdIndex]}
                                            disabled={this.state.visatypeDisabled}
                                            onChange={(event, value) => this.change(event, "visaTypeId", "visaTypeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Visa Type " variant="outlined"
                                                success={this.state.visaTypeIdState === "success"}
                                                error={this.state.visaTypeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.visaExpiryDateState === "success"}
                                            error={this.state.visaExpiryDateState === "error"}
                                            size="small"
                                            label="Visa Expiry Date"
                                            id="visaExpiryDate"
                                            variant="outlined"
                                            fullWidth
                                            disabled={this.state.visaDateDisabled}
                                            value={Moment(this.state.visaExpiryDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "visaExpiryDate", "visaExpiryDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Button color={this.state.citizenshipcolor} onClick={() => { this.SaveCitizenshipData() }}><SaveOutlined /> {this.state.saveUpdateCitizenshipBtnText} </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Next of Kin</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.kinNameState === "success"}
                                            error={this.state.kinNameState === "error"}
                                            value={this.state.kinName}
                                            size="small"
                                            label="Name"
                                            id="kinName"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "kinName", "kinName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <InputMask
                                            {...this.props}
                                            mask="\3999999999"
                                            maskChar=""
                                            value={this.state.kinPhone}
                                        >
                                            {() => <TextField
                                                success={this.state.kinPhoneState === "success"}
                                                error={this.state.kinPhoneState === "error"}
                                                size="small"
                                                label="Phone"
                                                id="kinPhone"
                                                variant="outlined"
                                                fullWidth
                                                required={true}
                                                autoComplete="off"
                                                placeholder="3001234567"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.change(event, "kinPhone", "kinPhone"),
                                                    maxLength: 10,
                                                }}
                                            />
                                            }
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.kinEmployerState === "success"}
                                            error={this.state.kinEmployerState === "error"}
                                            value={this.state.kinEmployer}
                                            size="small"
                                            label="Employer"
                                            id="kinEmployer"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "kinEmployer", "kinEmployer", value, 2),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.RelationDD}
                                            getOptionLabel={(option) => option.relationName}
                                            id="kinRelation"
                                            value={this.state.RelationDD[this.state.kinRelationIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "kinRelation", "kinRelation", value)}
                                            renderInput={(params) => <TextField {...params} label="Relation" variant="outlined"
                                                success={this.state.kinRelationState === "success"}
                                                error={this.state.kinRelationState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.kinProfessionState === "success"}
                                            error={this.state.kinProfessionState === "error"}
                                            value={this.state.kinProfession}
                                            size="small"
                                            label="Profession"
                                            id="kinProfession"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "kinProfession", "kinProfession", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Button color={this.state.kincolor} onClick={() => { this.SaveNextofKinData() }}><SaveOutlined /> {this.state.saveUpdatekinBtnText} </Button>
                                    </GridTextbox>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Accommodation </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.AccommodationDD}
                                            getOptionLabel={(option) => option.accommodationName}
                                            id="accommodationId"
                                            value={this.state.AccommodationDD[this.state.accommodationIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "accommodationId", "accommodationId", value)}
                                            renderInput={(params) => <TextField {...params} label="Accommodation" variant="outlined"
                                                success={this.state.accommodationIdState === "success"}
                                                error={this.state.accommodationIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "isAccommodation", "checkbox")}
                                                checked={this.state.isAccommodation}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Accommodation </span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Button color={this.state.Acccolor} onClick={() => { this.SaveAccommodationData() }}><SaveOutlined /> {this.state.saveUpdateAccBtnText} </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Driving License</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.drivingLicenseState === "success"}
                                            error={this.state.drivingLicenseState === "error"}
                                            value={this.state.drivingLicense}
                                            size="small"
                                            label="Driver License No."
                                            id="drivingLicense"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "drivingLicense", "drivingLicense", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.licenseExpiryDateState === "success"}
                                            error={this.state.licenseExpiryDateState === "error"}
                                            size="small"
                                            label="License Expiry Date"
                                            id="licenseExpiryDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.licenseExpiryDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "licenseExpiryDate", "licenseExpiryDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Button color={this.state.drivingcolor} onClick={() => { this.SaveDrivingLicenseData() }}><SaveOutlined /> {this.state.saveUpdateDrivingBtnDisabled} </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(OtherInfo);