import React from "react";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import ReactToggle from "react-switch";
import axios from 'axios';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
// @material-ui/core components
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from "@material-ui/core/Grid";
// @material-ui/icons
import { RecentActorsIcon, NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import ViewArrayIcon from '@material-ui/icons/ViewArray';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PropTypes from "prop-types";
import CardIcon from "components/Card/CardIcon.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";
import Moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import Checkbox from '@material-ui/core/Checkbox';
import Cities from "../../SetupManagement/PrimaryDataTabs/Cities";
import UserGroups from "../../ItManagement/UserGroups";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { muiWithToolbar, tbleWithPrint } from "assets/jss/customStyle/allCustomStyle";
//Import from Globel Component";
import {
    validateCheckSetVal
} from '../../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;
class PickupPerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Dropdown
            relationDD: [{ relationId: -1, relationName: 'Select' }],
            relationIdIndex: 0,
            relationId: -1,
            relationIdState: '',
            relationTwoDD: [{ relationTwoId: -1, relationTwoName: 'Select' }],
            relationTwoIdIndex: -1,
            relationTwoId: 0,
            relationTwoIdState: '',
            relationThreeDD: [{ relationThreeId: -1, relationThreeName: 'Select' }],
            relationThreeIdIndex: 0,
            relationThreeId: -1,
            relationThreeIdState: '',
            relationFourDD: [{ relationFourId: -1, relationFourName: 'Select' }],
            relationFourIdIndex: 0,
            relationFourId: -1,
            relationFourIdState: '',
            //#endregion
            nicFirst: "",
            nicFirstState: "",
            nameOne: "",
            nameOneState: "",
            nicTwo: "",
            nicTwoState: "",
            nameTwo: "",
            nameTwoState: "",
            nicThree: "",
            nicThreeState: "",
            nameThree: "",
            nameThreeState: "",
            nicFour: "",
            nicFourState: "",
            nameFour: "",
            nameFourState: "",


        };
    }

    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });

    }
    textChange = (event) => {
        debugger;
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    async CheckAndSetStates(event, checkName) {
        debugger
        switch (checkName) {
            case "nicFirst":
                debugger;
                this.state.nicFirst = event.target.value
                checkName = "checkNIC"
                break;
            case "nicTwo":
                debugger;
                this.state.nicTwo = event.target.value
                checkName = "checkNIC"
                break;
            case "nicThree":
                debugger;
                this.state.nicTwo = event.target.value
                checkName = "checkNIC"
                break;
            case "nicFour":
                debugger;
                this.state.nicTwo = event.target.value
                checkName = "checkNIC"
                break;
        }
        var rtnValue = await validateCheckSetVal(event, checkName, this.state);
        debugger
        for (var x = 0; x < rtnValue.length; x++) {
            debugger
            var statename = rtnValue[x].StateName;
            var statevalue = rtnValue[x].StateValue;
            this.setState({ [statename]: statevalue });
        }
    }
    //#region componentDidMount
    async componentDidMount() {
        this.RelationShipOne();
        this.RelationShipTwo();
        this.RelationShipThree();
        this.RelationShipFour();
    }
    RelationShipOne() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/GuardianRealation").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ relationId: -1, relationName: 'Select' }]
                    arr = arr.concat(data.relationlist)
                    if (this.state.relationId !== 0 && this.state.relationId !== null && this.state.relationId !== -1) {
                        this.setState({ relationDD: arr });
                        var GuardianIndex = this.state.relationDD.findIndex(o => o.relationId === this.state.relationId);
                        this.setState({
                            relationIdIndex: GuardianIndex,
                            relationIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ relationDD: arr, relationId: arr[0]['relationId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    RelationShipTwo() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/GuardianRealationTwo").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ relationTwoId: -1, relationTwoName: 'Select' }]
                    arr = arr.concat(data.relationlist)
                    if (this.state.relationTwoId !== 0 && this.state.relationTwoId !== null && this.state.relationTwoId !== -1) {
                        this.setState({ relationDD: arr });
                        var GuardianIndex = this.state.relationTwoDD.findIndex(o => o.relationTwoId === this.state.relationTwoId);
                        this.setState({
                            relationTwoIdIndex: GuardianIndex,
                            relationTwoIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ relationTwoDD: arr, relationTwoId: arr[0]['relationTwoId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    RelationShipThree() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/GuardianRealationThree").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ relationThreeId: -1, relationThreeName: 'Select' }]
                    arr = arr.concat(data.relationlist)
                    if (this.state.relationThreeId !== 0 && this.state.relationThreeId !== null && this.state.relationThreeId !== -1) {
                        this.setState({ relationThreeDD: arr });
                        var GuardianIndex = this.state.relationThreeDD.findIndex(o => o.relationThreeId === this.state.relationThreeId);
                        this.setState({
                            relationThreeIdIndex: GuardianIndex,
                            relationThreeIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ relationThreeDD: arr, relationThreeId: arr[0]['relationThreeId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    RelationShipFour() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/GuardianRealationFour").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ relationFourId: -1, relationFourName: 'Select' }]
                    arr = arr.concat(data.relationlist)
                    if (this.state.relationFourId !== 0 && this.state.relationFourId !== null && this.state.relationFourId !== -1) {
                        this.setState({ relationFourDD: arr });
                        var GuardianIndex = this.state.relationFourDD.findIndex(o => o.relationFourId === this.state.relationFourId);
                        this.setState({
                            relationFourIdIndex: GuardianIndex,
                            relationFourIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ relationFourDD: arr, relationFourId: arr[0]['relationFourId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    render() {
        const { classes } = this.props;
        const { relationDD, relationIdIndex, relationIdState, relationTwoDD, relationTwoIdIndex, relationTwoIdState, relationThreeDD, relationThreeIdIndex, relationThreeIdState,
            relationFourDD, relationFourIdIndex, relationFourIdState
        } = this.state;
        return (
            <GridContainer>
                <div style={{ paddingBottom: "5px", paddingRight: "5px", paddingLeft: "5px" }}
                    className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer >
                            <GridForm xs={12} sm={12} md={6}>
                                {this.state.alert}
                                <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Pickup Person</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                                            <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="relationId"
                                                            size="small"
                                                            options={relationDD}
                                                            getOptionLabel={(option) => option.relationName}
                                                            value={relationDD[relationIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                                            renderInput={(params) => <TextField {...params} success={relationIdState === "success"}
                                                                error={relationIdState === "error"} label="RelationShip" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Button color="facebook" onClick={(event) => { this.validateData(event) }}
                                                            disabled={this.state.saveUpdateBtnDisabled}>{/*<SaveOutlined />*/} Update </Button>
                                                    </GridTextbox>
                                            </GridContainer>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={6}>
                                {this.state.alert}
                                <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Pickup Person</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={3}>
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="relationId"
                                                            size="small"
                                                            options={relationDD}
                                                            getOptionLabel={(option) => option.relationName}
                                                            value={relationDD[relationIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                                            renderInput={(params) => <TextField {...params} success={relationIdState === "success"}
                                                                error={relationIdState === "error"} label="RelationShip" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <TextField
                                                            success={this.state.nameOneState === "success"}
                                                            error={this.state.nameOneState === "error"}
                                                            size="small"
                                                            label={"Name"}
                                                            id="nameOne"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.nameOne}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <InputMask
                                                            {...this.props}
                                                            mask="99999-9999999-9"
                                                            maskChar=""
                                                        >
                                                            {() => <TextField
                                                                success={this.state.nicFirstState === "success"}
                                                                error={this.state.nicFirstState === "error"}
                                                                size="small"
                                                                label={"NIC"}
                                                                variant="outlined"
                                                                fullWidth
                                                                //value={this.state.nicFirst}
                                                                id="nicFirst"
                                                                placeholder="00000-0000000-0"
                                                                required={true}
                                                                autoComplete="off"
                                                                inputProps={{
                                                                    onChange: event =>
                                                                        this.CheckAndSetStates(event, "nicFirst"),
                                                                    maxLength: 15,
                                                                }}
                                                            />}
                                                        </InputMask>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Button color="facebook" onClick={(event) => { this.validateData(event) }}
                                                            disabled={this.state.saveUpdateBtnDisabled}>{/*<SaveOutlined />*/} Update </Button>
                                                    </GridTextbox>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4} style={{ marginLeft: "40%" }}>
                                                    <PictureUpload />
                                                    <div style={{ textAlign: "center" }}>
                                                        <Button color="success" ><GetAppIcon />Download</Button>
                                                        <Button color="danger"><DeleteIcon />Remove</Button>
                                                    </div>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}
PickupPerson.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(formStyle)(PickupPerson);