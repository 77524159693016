import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp, axios,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar,
    tbleWithPrint, Autocomplete,Moment
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;
class Cities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cityId: 0,
            cityName: "",
            cityNameState: "",
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            searchCountryId: 0,
            searchCountryIndex:0,
            saveCountryId:0,
            saveCountryIdState:"",
            saveCountryIndex:0,
            countryDD: [{ countryId: -1, countryName: "All" }],
            getCountryDD: [{ gCountryId: -1, gCountryName: "All" }],
            searchCountryId:0,
            saveCountryId:0,
            saveCountryIdState:"",
            saveCountryIndex:0,
            //#region Datatable Columns and Data
            citiesData: [],
            //#region Datatable Columns
            columns: [{
                name: "countryName",
                label: "Country Name",
                options: {
                    filter: true,
                }
            },{
                name: "cityName",
                label: "City Name",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "cityId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadCities = this.loadCities.bind(this);
    }
    async componentDidMount() {
        this.loadCountries();
        this.GetCountries();
    }
    async loadCountries() {
        fetch(Base_URL + "/api/Dropdowns/Countries").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList = this.state.countryDD;
                allList = allList.concat(data.countryDDList);
                this.setState({ countryDD: allList, searchCountryId: allList[0]['countryId'] });
            }).catch(error => {
                console.log(error);
            });
    }
   async GetCountries() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/Countries").then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ getCountryDD: data.countryDDList });
                this.setState({ saveCountryId: data.countryDDList[0].countryId, saveCountryIdState: "success" });
            }).catch(error => {
                console.log(error);
            });
    }
    loadCities = () => {
        debugger;
        fetch(Base_URL + "/api/primaryData/Cities?CountryId=" + this.state.searchCountryId + "&Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ citiesData: data.citiesList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleChange(value, type) {
        debugger;
        switch (type) {
            case "searchCountryId":
                if (value !== null) {
                    this.setState({
                        searchCountryId: value.countryId,
                        searchCountryIndex: this.state.countryDD.findIndex(x => x.countryId === value.countryId)
                    });
                } else {
                    this.setState({ searchCountryId: 0 });
                }
                break;
            case "saveCountryId":
                if (value !== null) {
                    this.setState({
                        saveCountryId: value.countryId, 
                        saveCountryIdState: "success",
                        saveCountryIndex: this.state.getCountryDD.findIndex(x => x.countryId === value.countryId) });
                } else {
                    this.setState({ saveCountryId: 0, saveCountryIdState: "error" });
                }
                break;
            default:
                break;
        }
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "cityName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        if (this.state.cityNameState === "" || this.state.cityNameState === "error") {
            this.setState({ cityNameState: "error" });
            return;
        }else if(this.state.saveCountryIdState === "" || this.state.saveCountryIdState ==="error"){
            this.setState({ saveCountryIdState: "error" });
            return;
        } else {
            if (this.state.cityId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL +'/api/primaryData/Cities', {
            CityId: this.state.cityId,
            CityName: this.state.cityName,
            CountryId:this.state.saveCountryId,
            Active: this.state.active
          })
          .then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new City will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    City will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                )
            });
            
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (cityId) => {
        debugger
        let obj = this.state.citiesData.find(o => o.cityId === cityId);
        this.setState({ cityId: obj.cityId,
            cityName: obj.cityName, 
            cityNameState: "success", 
            //saveCountryId: obj.countryId, 
            active: obj.active, 
            saveUpdateBtnDisabled: false, 
            saveUpdateBtnText: 'Update', 
            clearTextBtn: 'block' });
            if(this.state.countryDD.find(x => x.countryId === obj.countryId) != null)
            {
                this.setState({ 
                    saveCountryId: obj.countryId,
                    saveCountryIdState:"success",
                    saveCountryIndex: this.state.getCountryDD.findIndex(x => x.countryId === obj.countryId)
                });
            }
            else
            {
                this.setState({ 
                    saveCountryIdState:"error" ,
                    saveCountryIndex: -1, 
                    saveCountryId: 0 });
                this.SuccessMessage('Alert','Entered Country is InActive');
            }
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null,
            cityId: 0, 
            cityName: "", 
            cityNameState: "", 
            active: false, 
            saveUpdateBtnText: 'Save', 
            saveUpdateBtnDisabled: false, 
            clearTextBtn: 'none',
            saveCountryIndex: 0, 
            saveCountryId: this.state.countryDD[0].countryId
        });
        if(this.state.countryDD.length > 0)
        {
            this.setState({
                saveCountryIdState:"success" 
            })
        }
        else
        {
            this.setState({
                saveCountryIdState:"" 
            })
        }
        this.loadCities();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.countryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.countryDD[this.state.searchCountryIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchCountryId")}
                                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadCities} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add City</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.getCountryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.getCountryDD[this.state.saveCountryIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveCountryId")}
                                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined"
                                                        success={this.state.saveCountryIdState === "success"}
                                                        error={this.state.saveCountryIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.cityNameState === "success"}
                                                    error={this.state.cityNameState === "error"}
                                                    value={this.state.cityName}
                                                    size="small"
                                                    label="City Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "cityName", "cityName", 2), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Cities"}
                                        data={this.state.citiesData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(Cities);