
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon,
    AddIcon, EditOutlined, Edit, SearchSharp
}
    from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class Exceptionmessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states

            ExceptionsMessagesList: [],
            messageId: 0,
            messages: "",
            messagesState: "",
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,

        };
        //#endregion 
    }
    async componentDidMount() {
        debugger
       this.LoadExceptionMessages();
    }
    async LoadExceptionMessages() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Excepetions/LoadExceptionMessages"));
            if (response.ok) {
                const data = await response.json();
                debugger;
                this.setState({
                    ExceptionsMessagesList: data.exceptionMessagesList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "messages":
                if (this.verifyAlphabets(event.target.value)) {
                    if (event.target.value != "") {
                        this.setState({
                            stateName: stateName,
                            messages: event.target.value,
                            [stateName + "State"]: "success",
                        })
                    }
                    else {
                        this.setState({
                            messages: event.target.value,
                            [stateName + "State"]: "error"
                        });
                    }
                }
                else {
                    this.setState({
                        messages: event.target.value,
                        [stateName + "State"]: "error"
                    });
                    this.state.messagesState = 'error';
                }
                break;
        }
    }
    verifyAlphabets(value) {
        debugger
        var AlphabetsRex = /^[a-zA-Z ]*$/;
        if (AlphabetsRex.test(value)) {
            return true;
        }
        return false;
    }
    saveExceptionsMessages = () => {
        debugger;
        if ((this.state.messages === null) || (this.state.messages === '') || (this.state.messagesState === "error")) {
            this.setState({ messagesState: "error", generalError: true });
            return;
        }
        let MessageInfo = {
            MessageId: this.state.messageId,
            Message: this.state.messages,
        };
        console.log(MessageInfo);
        axios.post(Base_URL + '/api/Excepetions/saveExceptionsMessages', MessageInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox = (id) => {
        debugger;
        let obj = this.state.ExceptionsMessagesList.find(o => o.messageId === id);
        this.setState({ messageId: obj.messageId, messages: obj.message, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (this.state.ExceptionsMessagesList.find(x => x.messageId === obj.messageId) != null) {
            this.setState({
                messageId: obj.messageId,
                messages: obj.message,
                messagesState: "success"
            });
        }
        else {
            this.setState({
                messageId: 0,
                messages:"",
                messagesState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    AlertMessage(status, message) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ),
                saveUpdateincBtnDisabled: false,
                saveUpdateAlloBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            messageId: 0,
            messages: '',
            alert: null,
            saveUpdateBtnText: 'save',
            clearTextBtn: 'none',
            clearTextBtn: 'none',
        });
        this.LoadExceptionMessages();
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { ExceptionsMessagesList } = this.state;
        return (
            <GridContainer>
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                {this.state.alert}
                                <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <CardBody>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Exception Messages</h4>
                                        </CardHeader>
                                        <hr />
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={3}>
                                                <TextField
                                                    success={this.state.messagesState === "success"}
                                                    error={this.state.messagesState === "error"}
                                                    value={this.state.messages}
                                                    size="small"
                                                    label="Message"
                                                    id="messages"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "messages", "messages", value, 3),
                                                        type: "text",
                                                        maxLength: 100
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Button color="facebook" onClick={() => { this.saveExceptionsMessages() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12}
                                sm={12}
                                md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Exception Message Detail"}
                                        data={ExceptionsMessagesList}
                                        columns={
                                            [
                                                { name:"message",label: "Message" },
                                                { name: "modifier", label: "Modifier" },
                                                { name: "modifiedDate", label: "ModifiedDate" },
                                                {
                                                    name: "messageId",
                                                    label: "Edit",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                        }
                                                    }
                                                },

                                            ]}
                                        options={tbleWithPrint} />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(Exceptionmessages);