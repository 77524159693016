import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    axios, SearchSharp
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class StdSections extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //#region Datatable Columns
            columns: [{
                name: "sectionName",
                label: "Section Name",

            }, {
                name: "nickName",
                label: "Nick Name",
            },
            {
                name: "active",
                label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            },
            {
                name: "sectionId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            searchCheck: true,
            sectionList: [],
            sectionId: -1,
            sectionName: '',
            sectionNameState: '',
            nickName: '',
            nickNameState: '',
            active: true,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };

    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.sectionName === "") {
            this.setState({ sectionNameState: "error" });
            return;
        }
        else if (this.state.nickName === "") {
            this.setState({ nickNameState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }

    SaveUpdateSection = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/StudentPrimaryData/SaveUpdateSection?SectionId=" + this.state.sectionId + "&SectionName=" + this.state.sectionName + "&NickName=" + this.state.nickName + "&Active=" + this.state.active)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox(sectionId) {
        let obj = this.state.sectionList.find(o => o.sectionId === sectionId);
        this.setState({ sectionName: obj.sectionName, nickName: obj.nickName, active: obj.active, sectionId: obj.sectionId, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }
    ClearTextfieldStates = () => {
        this.setState({ sectionId: -1, sectionName: '', sectionNameState: '', nickName: '', nickNameState: '', active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', });
        this.hideAlert();
        this.LoadData();
    }

    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }

    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    //#endregion 

    //#region Backend Data Fetching and Saving

    LoadData = () => {
        fetch(Base_URL + "/StudentPrimaryData/LoadSections?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectionList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateSection()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    closeOnClickOutside={false}
                    showCancel
                >
                    Do you want to save Section detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { sectionList, columns, sectionName, sectionNameState, nickName, nickNameState, active, searchCheck, clearTextBtn } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Section</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={sectionNameState === "success"}
                                                        error={sectionNameState === "error"}
                                                        size="small"
                                                        label="Section Name"
                                                        id="sectionName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={sectionName}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={nickNameState === "success"}
                                                        error={nickNameState === "error"}
                                                        size="small"
                                                        label="Nick Name"
                                                        id="nickName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={nickName}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Section List"}
                                        data={sectionList}
                                        columns={columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdSections);
