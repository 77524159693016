
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, AllDropDowns
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
const statusDD = [
    { statusName: 'Both', statusId: -1 },
    { statusName: 'Active', statusId: 1 },
    { statusName: 'Inactive', statusId: 0 },
  
]
const pictureDD = [
    { pictureName: 'All', pictureId: '-1' },
    { pictureName: 'Yes', pictureId: '1' },
    { pictureName: 'No', pictureId: '0' },
]
var Base_URL = localStorage.BaseURL;

class Studentmanagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            studentApplicationArray: [],
            name: '',
            nameState: '',
            registrationNo: "",
            registrationNoState: "",
            active: true,
            paid:true,
            //  #region Dropdown
            pictureDD: [{ pictureName: 'Select', pictureId: '-1' }],
            pictureId: -1,
            pictureIdState: "",
            pictureIdIndex:0,
            motId: -1,
            motDD: [{ motId: -1, motStatus: 'Select' }],
            motIdIndex:0,
            motIdState:"",
            statusDD: [{ statusId: -1, statusName: 'Select' }],
            statusIdIndex: 0,
            statusId: -1,
            statusIdState: "",
            cardStatusId: -1,
            cardStatusDD: [{ cardStatusId: -1, cardStatus: "Select" }],
            cardStatusIdState: "",
            cardStatusIdIndex:0,
            sectionDD: [{ sectionId: -1, sectionName: 'All' }],
            sectionId: -1,
            sectionIdState: '',
            sectionIdIndex: 0,
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0,
            sessionId: -1,
            sessionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'All' }],
            campusId: -1,
            campusIdState: '',
            campusIdIndex: 0,
            classDD: [{ classId: -1, className: 'All' }],
            classId: -1,
            classIdState: '',
            classIdIndex: 0,
            educationLevelDD: [{ educationLevelId: -1, levelName: 'Select' }],
            educationLevelId: -1,
            educationLevelIdState: '',
            educationLevelIdIndex: 0,
            startDate: new Date(), startDateState: '', endDate: new Date(), regStatus: 1,
            alert: null, show: false, generalError: true, ShowSession: true, ShowCampus: true, ShowLevel: false, ShowClass: true, ShowSection: true,
            IsCampusAll: true, IsLevelAll: true, IsClassAll: true, IsSectionAll: true, ShowLevel: true, isLevelAll: true
            //#endregion
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.handleChange = this.handleChange.bind(this);
    };
    //#region Dropdowns
    componentDidMount() {
        this.LoadCardStatus();
        this.LoadMot();
    }
    LoadCardStatus() {
        fetch(Base_URL + "/api/StudentManagement/CardStatus").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ cardStatusId: -1, cardStatus: "All" }];
                    arr = arr.concat(data.studentCardStatus);
                    this.setState({ cardStatusDD: arr, cardStatusId: arr[0]['cardStatusId'] });
                    }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadMot() {
        debugger;
        fetch(Base_URL + "/api/StudentManagement/MotStatus").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ motId: -1, motStatus: 'All' }];
                    arr = arr.concat(data.motStatus);
                    this.setState({ motDD: arr, motId: arr[0]['motId'] });
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    handleChange(event, value) {
        debugger;
        const stateName = event.target.id.split("-")[0];
        this.setState({
            [stateName]: value[stateName],
            [stateName + "State"]: "success",
            generalError: false
        })
    }
    change(event, stateName, type, value) {
        debugger;
        switch (type) {
            case "statusId":
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: statusDD.findIndex(x =>
                        x[stateName] === value[stateName]),
                    statusId: value.statusId,
                    [stateName + "State"]: "success"
                })
                break;
            case "pictureId":
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: pictureDD.findIndex(x =>
                        x[stateName] === value[stateName]),
                    pictureId: value.pictureId,
                    [stateName + "State"]: "success"
                })
                break;
        }
    }
//#endregion
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }

    validateData = () => {
        if (this.state.sessionId === 0) {
            this.setState({ sessionIdState: "error" });
            return;
        }
        else if (this.state.mask === "") {
            this.setState({ maskState: "error" });
            return;
        }
        else if (this.state.password === '') {
            this.setState({ passwordState: "error" });
            return;
        }
        else if (this.state.userId === '') {
            this.setState({ userIdState: "error" });
            return;
        }
        else if (!this.verifyUrl(this.state.url)) {
            this.setState({ urlState: "error" });
            return;
        }

        else {
            this.ConfirmMessage();
        }
    }
    LoadData = () => {
        debugger;
        fetch(Base_URL + "/api/StudentApplication/LoadStudentApplicationData?Paid=" + this.state.paid + "&SessionName=" + this.state.sessionId + "&SectorName=" + this.state.sectorId + "&SubSectors=" + this.state.levelId + "&ClassName=" + this.state.classId + "&SectionName=" + this.state.sectionId + "&NameFatherNic=" + this.state.name + "&RegistrationNo=" + this.state.registrationNo).then(response => { return response.json(); })
            .then(data => {
                this.setState({ studentApplicationArray: data });
            }).catch(error => {
                console.log(error);
            });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
   
   
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateSMSInquiry()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save SMS Inquiry detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { cardStatusDD, cardStatusIdState, cardStatusIdIndex, active, registrationNoState, registrationNo, name, nameState, paid, studentApplicationArray} = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <FileCopy />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Student Management</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdmanagementhome" > Student Management</Link>
                            <Typography color="textPrimary">Student Management</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            {this.state.alert}
                            <GridContainer>
                               <div className={classes.mainDiv}>
                                  <GridContainer>
                                     <GridForm xs={12} sm={12} md={12}>
                                        <Card style={{ marginTop: "0px" }}>
                                            <CardHeader>
                                                    <h4 className={classes.cardIconTitle}>Student Management</h4>
                                                </CardHeader>
                                           <CardBody>
                                               <form>
                                                  <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={12} lg={12}>
                                                                <AllDropDowns data={this.state} xsm={12} smm={6} mdm={3} lgm={3} handleStateChange={this.handleChange} />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} md={2}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    size="small"
                                                                    options={statusDD}
                                                                    getOptionLabel={(option) => option.statusName}
                                                                    value={statusDD[this.state.statusIdIndex]}
                                                                    id="statusId"
                                                                    onChange={(event, value) => this.change(event, "statusId", "statusId", value)}
                                                                    renderInput={(params) => <TextField {...params} label="Status" variant="outlined"
                                                                        success={this.state.statusState === "success"}
                                                                        error={this.state.statusState === "error"} />}
                                                                />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} md={3}>
                                                                <TextField
                                                                    success={nameState === "success"}
                                                                    error={nameState === "error"}
                                                                    size="small"
                                                                    label="Name/Parents/NIC/Mobile"
                                                                    id="name"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={name}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={registrationNoState === "success"}
                                                                    error={registrationNoState === "error"}
                                                                    size="small"
                                                                    label="BTS No."
                                                                    id="registrationNo"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={registrationNo}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} lg={2}>
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" borderRadius={4}
                                                                        handleDiameter={22} className={classes.toggleStyle}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                        } />
                                                                    <span> Active in Class</span>
                                                                </label>
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} md={1}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    id="cardStatusId"
                                                                    size="small"
                                                                    options={cardStatusDD}
                                                                    getOptionLabel={(option) => option.cardStatus}
                                                                    value={cardStatusDD[cardStatusIdIndex]}
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} success={cardStatusIdState === "success"}
                                                                        error={cardStatusIdState === "error"} label="Card Status" variant="outlined" />}
                                                                />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} md={2}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    size="small"
                                                                    options={this.state.motDD}
                                                                    getOptionLabel={(option) => option.motStatus}
                                                                    value={this.state.motDD[this.state.motIdIndex]}
                                                                    id="motId"
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} label="MOT" variant="outlined"
                                                                        success={this.state.motIdState === "success"}
                                                                        error={this.state.motIdState === "error"} />}
                                                                />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} md={2}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    size="small"
                                                                    options={pictureDD}
                                                                    getOptionLabel={(option) => option.pictureName}
                                                                    value={pictureDD[this.state.pictureIdIndex]}
                                                                    id="pictureId"
                                                                    onChange={(event, value) => this.change(event, "pictureId", "pictureId", value)}
                                                                    renderInput={(params) => <TextField {...params} label="Pictures" variant="outlined"
                                                                        success={this.state.pictureIdState === "success"}
                                                                        error={this.state.pictureIdState === "error"} />}
                                                                />
                                                            </GridTextbox>
                                                     <GridTextbox xs={12} sm={12} lg={2}>
                                                                <Button color="facebook" onClick={this.LoadData}><SearchIcon />Search</Button>
                                                            </GridTextbox>
                                                  </GridContainer>
                                               </form>
                                           </CardBody>
                                        </Card>
                                     </GridForm>
                                     <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Student Detail List"}
                                                    data={studentApplicationArray}
                                                    columns={[{
                                                        name: "registrationNo",
                                                        label: "Reg No.",
                                                    },
                                                    {
                                                        name: "sessionName",
                                                        label: "Session",
                                                    },
                                                    {
                                                        name: "sectorName",
                                                        label: "Sector",
                                                    }, {
                                                        name: "className",
                                                        label: "Class",
                                                    }, {
                                                        name: "sectionName",
                                                        label: "Section",
                                                        }, {
                                                            name: "name",
                                                            label: "Name",
                                                        }, {
                                                        label: "Payment",
                                                       
                                                        options: {
                                                            name: "masksmsId",
                                                            customBodyRender: (value) => {
                                                                return (
                                                                    <Button color="facebook">
                                                                        Payment
                                                                    </Button>
                                                                )
                                                            }
                                                        }
                                                        }, {
                                                        name: "  Fee Charged",
                                                        options: {
                                                            name: "masksmsId",
                                                            customBodyRender: (value) => {
                                                                return (
                                                                    <Button color="facebook">
                                                                        Fee Charged
                                                                    </Button>
                                                                )
                                                            }
                                                        }
                                                        }, {
                                                            name: "name",
                                                            label: "Transport Mode",
                                                        }, {
                                                            name: "name",
                                                            label: "Photo",
                                                        }, {
                                                            name: "name",
                                                            label: "ID Card Status",
                                                        }, {
                                                            name: "name",
                                                            label: "Pending",
                                                        },
                                                        {
                                                            name: "masksmsId",
                                                            label: "Edit",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (<Link to="/app/studentProfileEdit"><EditOutlined /></Link>);
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                     <GridForm xs={12} sm={12} md={12}>
                                            <Card>
                                                <CardHeader></CardHeader>
                                                <CardBody>
                                                    <form>
                                                    <GridContainer>
                                                    <GridTextbox xs={12} sm={12} lg={2}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "paid")} checked={paid} onColor="#3b5998" borderRadius={4}
                                                                handleDiameter={22} className={classes.toggleStyle}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Is Defaulter</span>
                                                        </label>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={2}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "paid")} checked={paid} onColor="#3b5998" borderRadius={4}
                                                                handleDiameter={22} className={classes.toggleStyle}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Can Log In</span>
                                                        </label>
                                                            </GridTextbox>
                                                            <div style={{ padding:10 }}>
                                                                <Button color="facebook" >Update Defaulter</Button>
                                                                </div>
                                                    </GridContainer>
                                                    </form>
                                                    </CardBody>
                                            </Card>
                                            <div style={{ textAlign: "center" }}>
                                                <Button variant="contained" component="label"                                               >
                                                    Upload File
                                                                    <input
                                                        type="file"
                                                        hidden
                                                    />
                                                </Button>
                                                <Button color="facebook" >Import</Button>
                                                <Button color="facebook" >List</Button>
                                                <Button color="facebook" >Id Card</Button>
                                                <Button color="facebook" >Customize</Button>
                                                <Button color="facebook" ><TextsmsIcon />SMS</Button>
                                                <Link to="/app/sendEmail"> <Button color="facebook" ><AlternateEmailIcon />Email</Button></Link>
                                                
                                            </div>
                                        </GridForm>
                                  </GridContainer>
                               </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(Studentmanagement);
