import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactToggle from "react-switch";
import { InputLabel, FormControl, Select } from '@material-ui/core';
import SweetAlert from "react-bootstrap-sweetalert";
import { ContactMailOutlined as ParentIcon, SaveOutlined } from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from 'sweetalert';
var Base_URL = localStorage.BaseURL;
class TestAssessment extends React.Component {
    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        this.state = {
            levelId: editRegData["levelId"],
            regNo: parseInt(editRegData["registrationId"]),
            testCleared: editRegData["isTestCleared"],
            saveUpdateBtnDisabled: true,
            assessmentSubjectAll: [],
            assessmentSubject: [],
            resultIdArray: [],
            //loadStudentTestAssessment: [],
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0, sessionId: -1, sessionIdState: '',
            allselectedvalue: [{ DDName: "NULL", DDValue: "Select" }],
            //arrDDIdIndex: [],
           // dropDownStorinArray: [{ dDName: "Name", dDId: 0, ddIdIndex: 0, titleOptionsDD: [{ dDId: -1, options: "Select" }] }],
           // IndexArray: 0,
            TitleOptionsId: 0,
            //resultIdArray: [],
            Dropdowndata: [{ "TitleName": "TitleName", "Title": "Title", "TitleOptionsDD": [{ 'TitleOptionsId': -1, 'Title': "Select" }], TitleIdIndex: 0, dDId: 0, resultId: 0 }]

        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.LoadRegData = this.LoadRegData.bind(this);
        this.setValue = this.setValue.bind(this);
        this.componentDidMount();
        this.setDDValue = this.setDDValue.bind(this);
    };
    async componentDidMount() {
        debugger;
        await fetch(Base_URL + '/api/StudentRegistration/AssessmentSubject?LevelId=' + this.state.levelId + '&StudentId=' + this.state.regNo).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({ assessmentSubjectAll: data.assesmentsSubjects, assessmentSubject: data.subjectList, resultIdArray: data.ids });
                }
                else {
                    this.AlertMessage("Error", data.exception.displayMessage);
                }
            }).catch(error => {
                console.log(error);
            });
        debugger;
        //await fetch(Base_URL + '/api/StudentRegistration/LoadTestAssessment?RegId=' + this.state.regNo).then(response => { return response.json(); })
        //    .then(data => {
        //        debugger;
        //        this.setState({ loadStudentTestAssessment: data });
        //    }).catch(error => {
        //        console.log(error);
        //    });
    }
    //#region DropDowns
    setDDValue = (event, value) => {
        debugger;
        this.state.saveUpdateBtnDisabled = false;
        debugger;
        const stateName = event.target.id.split("-")[0];
        var Resultobj = this.state.assessmentSubjectAll.find(x => x.assementResultTypeId === value.TitleOptionsId && x.assessmentSubjectName === stateName)
        var ResultId = Resultobj.resultId;
        var dupArray = [];
        var dDId = value.TitleOptionsId;
        dupArray = this.state.Dropdowndata;
        let ddObj = this.state.Dropdowndata.find(x => x.TitleName === stateName);
        let ddObjIndex = this.state.Dropdowndata.findIndex(x => x.TitleName === stateName);
        var newIndex = ddObj.TitleOptionsDD.findIndex(x => x.Title === value.Title);
        dupArray[ddObjIndex].TitleIdIndex = newIndex;
        dupArray[ddObjIndex].dDId = dDId;
        dupArray[ddObjIndex].resultId = ResultId;
        this.setState({ Dropdowndata: dupArray });
    }
    
    //#endregion
    setValue = (event) => {
        debugger;
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, saveUpdateBtnDisabled: false },);
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //ValidateData = () => {
    //}
    SaveUpdateData = () => {
        debugger;
        this.hideAlert();
        let ResultIdArray = "";
        for (var i = 1; i < this.state.Dropdowndata.length; i++) {
            if (i == this.state.Dropdowndata.length - 1) {
                ResultIdArray += this.state.Dropdowndata[i].resultId;
            }
            else {
                ResultIdArray += this.state.Dropdowndata[i].resultId + ",";
            }
        }
        var url = Base_URL + "/api/StudentRegistration/SaveTestAssesment?RegId=" + this.state.regNo + "&ResultTypeId=" + ResultIdArray + "&IsTestCleared=" + this.state.testCleared;
        let req = new XMLHttpRequest();
            req.open('Post', url, true)
            req.responseType = 'json';
            var ty = req.getResponseHeader('content-type');
            req.setRequestHeader('content-type', ty);
            req.onload = function () {
                if (req.readyState == 4 && req.status == 200) {
                    let responseObj = req.response;
                        if (responseObj.exception.exceptionToken == '' || responseObj.exception.exceptionToken == null) {
                           // this.AlertMessage(responseObj.vmResponse.status, responseObj.vmResponse.message);
                            swal({
                                title: responseObj.vmResponse.status,
                                text: responseObj.vmResponse.message,
                                icon: "success",
                                button: "OK"
                            });
                        }
                        else {
                            var errorMessage = responseObj.exception.exceptionToken + ":<br \>" + responseObj.exception.displayMessage;
                            swal({
                                title: "Login Status Alert!",
                                text: errorMessage,
                                icon: "error",
                                button: "OK"
                            });
                        }
                }
            };
            req.send(null);
            req.onerror = function () {
            };
            //#endregion
    }
    //#region this Function is Called after Updating data
    LoadRegData() {
        this.hideAlert();
        this.props.LoadRegData(); 
    }
  
    //#endregion
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateData()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    "Do You Want Save Test Assessment"
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.LoadRegData()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion
    //#region Form Body and Return
    render() {
        debugger;
        const { classes } = this.props;
        const { testCleared, assessmentSubject, assessmentSubjectAll, resultIdArray} = this.state;
        const AssessmentSubject = ((props) => {
            if (assessmentSubject !== null && assessmentSubject.length !== 0) {
                debugger;
                var TitleName = props.Subjects;
                var Title = TitleName.charAt(0).toLowerCase() + TitleName.slice(1);
                var key = props.data.key;
                var TitleOptionsDD = [{ 'TitleOptionsId': -1, 'Title': "Select" }];
                var Id = assessmentSubject[props.data.key].assessmentSubjectId;
                let obj = this.state.assessmentSubjectAll.filter(user => {
                    return user.assessmentSubjectId === Id;
                });
                var opt1 = [];
                for (let i = 0; i < obj.length; i++) {
                    opt1 = { 'TitleOptionsId': obj[i].assementResultTypeId, 'Title': obj[i].assessmentResultName };
                    TitleOptionsDD.push(opt1);
                }
                props.data.key = props.data.key + 1;
                var TitleIdIndex = 0;
                var ArrayForDropdown = [];
                const found = this.state.Dropdowndata.some(el => el.TitleName === TitleName);
                if (!found) {
                    if (this.state.resultIdArray ===null) {
                        ArrayForDropdown = { "TitleName": TitleName, "Title": Title, "TitleOptionsDD": TitleOptionsDD, TitleIdIndex: 0, dDId: 0, resultId: 0 };
                      }
                 else {
                        var resultId = resultIdArray[key];
                        var objArray = assessmentSubjectAll.find(x => x.resultId === resultId );
                        var index = TitleOptionsDD.findIndex(x => x.Title === objArray.assessmentResultName);
                        ArrayForDropdown = { "TitleName": TitleName, "Title": Title, "TitleOptionsDD": TitleOptionsDD, TitleIdIndex: index, dDId: objArray.assementResultTypeId, resultId: objArray.resultId };
                    }
                    this.state.Dropdowndata.push(ArrayForDropdown);
                }
                return (
                    <GridTextbox xs={12} sm={12} md={12}>
                        <Autocomplete
                            disableClearable
                            id={this.state.Dropdowndata[props.data.key].TitleName}
                            size="small"
                            options={this.state.Dropdowndata[props.data.key].TitleOptionsDD}
                            getOptionLabel={(option) => option.Title}
                            value={this.state.Dropdowndata[props.data.key].TitleOptionsDD[this.state.Dropdowndata[props.data.key].TitleIdIndex]}
                            onChange={(event, value) => { this.setDDValue(event, value) }}
                            renderInput={(params) => <TextField {...params} label={this.state.Dropdowndata[props.data.key].TitleName} variant="outlined" />}
                        />
                    </GridTextbox>
                );
            }
            else {
                return (<div></div>);
            }

        });
        let rows = [];
        for (let i = 0; i < assessmentSubject.length; i++) {
            rows.push(<AssessmentSubject data={{ key: i, subjectId: assessmentSubject[i].assessmentSubjectId }} Subjects={assessmentSubject[i].assessmentSubjectName} />);
        }
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody> <GridContainer style={{ placeContent: "center" }}>
                        <GridForm xs={12} sm={12} md={3}>
                            <Card>
                                <CardHeader color="info" icon>
                                    <CardIcon color="success">
                                        <ParentIcon />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>Test Assessment</h4>
                                    <hr />
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        {rows}
                                        <GridTextbox xs={12} sm={12} md={5} style={{ textAlign: "center" }}>
                                            <label htmlFor="small-radius-switch">
                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "testCleared")} value={testCleared}
                                                    checked={testCleared}
                                                    onColor="#3b5998"
                                                    className={classes.toggleStyle}
                                                    borderRadius={4}
                                                    handleDiameter={22}
                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                    } />
                                                <span> Test Cleared</span>
                                            </label>
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={2} lg={2}>
                                            <Button color="facebook" onClick={() => { this.ConfirmMessage() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Save </Button>
                                        </GridTextbox>
                                        {/*<GridTextbox xs={12} sm={12} md={5} lg={5}>*/}
                                        {/*    <Button color="facebook" disabled={this.state.saveUpdateBtnDisabled}> Test Assessment </Button>*/}
                                        {/*</GridTextbox>*/}
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridForm>
                    </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(TestAssessment);
