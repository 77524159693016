
//#region Import Library viewTabs
import Studentissuereturnbook from "./LibraryTabs/Studentissuereturnbook";
import Employeeissuereturnbook from "./LibraryTabs/Employeeissuereturnbook";
//#endregion

//Import from Globel Component";
  import {
           React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
           AddIcon, FindInPage, Breadcrumbs, PermContactCalendar, LocalLibrary, Tabs, PropTypes, Tab, NavigateNextIcon, Link,
           HomeIcon, Typography, GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable,
           formStyle, muiWithToolbar, tbleWithPrint, Moment
         } from '../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

function TabContainer(props) {
    debugger;
    return (
        <Typography component="div" style={{ width: "100%", alignContent: "center" }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});
class IssueReturn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
        };

    };
    handleChange = (event, value) => {
        this.setState({ selectedTab: value });
    };
    //#region Form Body and Return
    render() {
        const { selectedTab} = this.state;
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="danger" icon>
                        <CardIcon color="danger">
                            <LocalLibrary />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}> Issue Return</h4>
                    </CardHeader>
                      <div style={{ padding: "5px 15px" }}>
                        <hr />
                         <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/library" > Library</Link>
                            <Typography color="textPrimary">Issue Return</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                      </div>
                       <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                          <CardBody >
                              <GridContainer>
                                 <div className={classes.mainDiv}>
                                   <Tabs
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    style={{ backgroundColor: "#d4e2f8", width: "100%" }}
                                    indicatorColor="primary"
                                    textColor="primary" wrapped >
                                    <Tab label=" Student" />
                                    <Tab label="Employee" />
                                </Tabs>
                                   {selectedTab === 0 && <TabContainer><Studentissuereturnbook /></TabContainer>}
                                   {selectedTab === 1 && <TabContainer><Employeeissuereturnbook /></TabContainer>}
                                 </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(IssueReturn);

