import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import { FormControl, InputLabel, Checkbox } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TextField from '@material-ui/core/TextField';
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import history from '../../context/History';
import swal from 'sweetalert';
import bglogin from "assets/img/login.jpeg";
var selectedCity = '';
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
            loginValue: "",
            passwordValue: "",
            isAuthenticated: false,
            isError: false,
            keepLoginValue: true,
            registerCheckbox: false,
            registerCheckboxState: "",
            citySelect: '',
            HasCredentials: false,
        };
        this.state.loginValue = localStorage.getItem('UserName');
        var pass = localStorage.getItem('Password');
        if (pass !== 'undefined' && pass !== null) {
            this.state.passwordValue = JSON.parse(localStorage.getItem('Password') || '');
        }

        this.change = this.change.bind(this);
        if (this.state.loginValue !== '' && this.state.passwordValue !== '' && this.state.passwordValue !== null && this.state.loginValue !== null) {
            this.state.HasCredentials = true;
        }
        selectedCity = localStorage.getItem('Context');
        this.state.citySelect = selectedCity;
    }

    getBgImageLogin = () => {
        return bglogin;
    };

    componentDidMount() {
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
        document.body.style.overflow = "unset";
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    handleCity = e => {
        ////debugger;
        this.setState({ [e.target.name]: e.target.value });
        this.state.citySelect = e.target.value;
        selectedCity = e.target.value;
    };
    change(event, id) {
        ////debugger;
        switch (id) {
            case "username":
                if (event.target.value !== '') {
                    this.setState({ ["loginValue"]: event.target.value });
                }
                else {
                    this.setState({ ["loginValue"]: "" });
                }
                this.state.HasCredentials = false;
                break;

            case "password":
                if (event.target.value !== '') {
                    this.setState({ ["passwordValue"]: event.target.value });
                }
                else {
                    this.setState({ ["passwordValue"]: "" });
                }
                this.state.HasCredentials = false;
                break;
            case "chkKeepLogin":
                if (event.target.value !== '') {
                    this.setState({ ["keepLoginValue"]: event.target.value });
                }
                else {
                    this.setState({ ["keepLoginValue"]: true });
                }
                break;

            default:
                break;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <div className={classes.wrapper} ref="wrapper">
                    <div
                        className={classes.fullPage} style={{ backgroundImage: "url(" + this.getBgImageLogin() + ")" }}>
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={6} md={4}>
                                    <form>
                                        <Card login className={classes[this.state.cardAnimaton]}>
                                            <CardHeader
                                                className={`${classes.cardHeader} ${classes.textCenter}`}
                                                color="rose"
                                            >
                                                <h4 className={classes.cardTitle}>Log in</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer style={{ width: "100%", marginTop: "15px", margin: "0px" }}>
                                                    <GridTextbox style={{ width: "100%", marginTop: "15px" }}>
                                                        <TextField
                                                            size="small"
                                                            label="User Name *"
                                                            id="username"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                onChange: event =>
                                                                    this.change(event, "username"),
                                                                type: "text",
                                                                value: this.state.loginValue
                                                            }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox style={{ width: "100%", marginTop: "15px" }}>
                                                        <TextField
                                                            size="small"
                                                            label="Password *"
                                                            id="password"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                onChange: event =>
                                                                    this.change(event, "password"),
                                                                type: "password",
                                                                value: this.state.passwordValue
                                                            }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox style={{ width: "100%", marginTop: "15px" }}>
                                                        <FormControl fullWidth className={classes.selectFormControl} variant="outlined" size="small" >
                                                            <InputLabel id="cityLabel" className={classes.selectLabel} >City</InputLabel>
                                                            <Select native
                                                                labelId="cityLabel" label="City" value={selectedCity} onChange={this.handleCity}>
                                                                <option value="select">--select--</option>
                                                                <option value="Lahore">Lahore</option>
                                                                <option value="Karachi">Karachi</option>
                                                            </Select>
                                                        </FormControl>
                                                    </GridTextbox>
                                                </GridContainer>


                                                <div className={classes.topMarginDiv}>
                                                    <label>
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            onClick={event => this.change(event, "chkKeepLogin")}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot
                                                            }}

                                                        />
                                                        Keep me Login
                                                    </label>
                                                    <Link to={"/forgotpassword"} >
                                                        <label style={{ textDecoration: 'underline', float: 'right' }}> Forgot Passwrod? </label>
                                                    </Link>
                                                </div>

                                            </CardBody>
                                            <CardFooter className={classes.justifyContentCenter}>
                                                <Button color="facebook" block onClick={() => this.loginUser(this.state.loginValue, this.state.passwordValue, this.state.keepLoginValue, this.state.citySelect, this.state.HasCredentials)}>
                                                    Login </Button>
                                            </CardFooter>
                                        </Card>
                                    </form>
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    loginUser(login, password, isKeepLogin, CampCity, hasCred) {
        if (login === null || login === '') {
            swal({
                title: "Login Credentials Alert!",
                text: "Please enter valid Login Id (User Name).",
                icon: "error",
                button: "OK"
            });
        }
        else if (password === null || password === '') {
            swal({
                title: "Login Credentials Alert!",
                text: "Please enter your password.",
                icon: "error",
                button: "OK"
            });
        }
        else if (CampCity === null || CampCity === '') {
            swal({
                title: "Login Credentials Alert!",
                text: "Please select city i.e Lahore/Karachi.",
                icon: "error",
                button: "OK"
            });
        }
        else {
            var Base_URL = window.location.origin;
            if (Base_URL == 'http://localhost:58487') {
                localStorage.setItem('BaseURL', Base_URL);
                Base_URL = Base_URL + '/Login/login?Contaxt=' + CampCity + '&UserName=' + login + '&Password=' + password + '&KeepLogin=' + isKeepLogin + '&HasCredentials=' + hasCred + '';

            }
            else {
                localStorage.setItem('BaseURL', Base_URL + '/btsmis');
                Base_URL = Base_URL + '/btsmis/Login/login?Contaxt=' + CampCity + '&UserName=' + login + '&Password=' + password + '&KeepLogin=' + isKeepLogin + '&HasCredentials=' + hasCred + '';

            }
            fetch(Base_URL).then(response => { return response.json() })
                .then(data => {
                    debugger;
                    if (data !== null) {
                        if ((data.exception.exceptionToken == null || data.exception.exceptionToken == '')
                            && (data.exception.displayMessage == null || data.exception.displayMessage == '') && data.loginModel.employeeId !== null) {

                            if (data.loginModel.loginStatus === 'Success' && (data.loginModel.errorMsg === null || data.loginModel.errorMsg === '')) {
                                if (isKeepLogin == true) {
                                    localStorage.setItem('UserName', login);
                                    localStorage.setItem('Password', JSON.stringify(data.loginModel.encPassword));
                                    localStorage.setItem('Context', CampCity);
                                }
                                localStorage.setItem('isAuthenticated', true);
                                localStorage.setItem('EmployeeName', data.loginModel.name);
                                localStorage.setItem('ProfilePhoto', data.loginModel.profilePhoto);
                                localStorage.setItem('ConnectionType', data.loginModel.connectionType);
                                localStorage.setItem('error', false);
                                history.push('/app/dashboard');
                            }
                        }
                        else {
                            debugger;
                            var msg = JSON.stringify(data.loginModel.errorMsg);
                            swal({
                                title: "Login Status Alert!",
                                text: msg,
                                icon: "error",
                                button: "OK"
                            });
                        }
                    }
                    else {
                        debugger;
                        var errorMessage = JSON.stringify(data.exception.exceptionToken + ":-" + data.exception.displayMessage);
                        swal({
                            title: "Login Status Alert!",
                            text: errorMessage,
                            icon: "error",
                            button: "OK"
                        });
                    }
                })
                .catch(error => {
                    debugger;
                    //console.log(error);                    
                    //var errorMessage = JSON.stringify(error);
                    //swal({
                    //    title: "Login Status Alert!",
                    //    text: errorMessage,
                    //    icon: "error",
                    //    button: "OK"
                    });
                //    if (typeof error.json === "function") {
                //        error.json().then(jsonError => {
                //            swal({
                //                title: "Login Status Alert!",
                //                text: jsonError,
                //                icon: "error",
                //                button: "OK"
                //            });
                //        }).catch(genericError => {
                //            swal({
                //                title: "Login Status Alert!",
                //                text: error.statusText,
                //                icon: "error",
                //                button: "OK"
                //            });
                //        });
                //    } else {
                //        swal({
                //            title: "Login Status Alert!",
                //            text: "Fetal Error",
                //            icon: "error",
                //            button: "OK"
                //        });
                //    }
                //});
            //.catch(function (error) {
            //    debugger;
            //    var msg = error.message + '/n Reason: ' + reason.message;
            //   swal({
            //        title: "Login Status Alert!",
            //       text: msg,
            //        icon: "error",
            //        button: "OK"
            //    });
            //}).catch(function (reason) {
            //    debugger;
            //    var msg = error.message + '/n Reason: ' + reason.message;
            //   swal({
            //        title: "Login Status Alert!",
            //       text: msg,
            //        icon: "error",
            //        button: "OK"
            //    });
            //}).catch(genericError => {
            //    var msg ="Generic error from API";
            //    swal({
            //        title: "Login Status Alert!",
            //        text: msg,
            //        icon: "error",
            //        button: "OK"
            //    });
            //});
            //;

        }
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(loginPageStyle)(LoginPage);
