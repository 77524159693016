//Import from Globel Component";
import {
    React, axios, Moment, ReactToggle, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;

class Scores extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            ScoresList: [],
            employeeId: parseInt(editRegData["EmployeeId"]),
            scoresId: 0,
            cvScores: "",
            cvScoresState: "",
            interviewScores: "",
            interviewScoresState: "",
            writtenTestScores: "",
            writtenTestScoresState: "",
            auditionScores: "",
            auditionScoresState: "",
            totalScores: "",
            totalScoresState: "",
            saveUpdateBtnText: 'save',
            scorescolor: 'facebook',
        }
    }
    async componentDidMount() {
        this.LoadScoresData();
    }
    async LoadScoresData() {
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadScoresData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    ScoresList: data.scoresdataList
                });
                if (data.scoresdataList.length > 0) {
                    debugger;
                    this.setState({
                        scoresId: data.scoresdataList[0].scoresId,
                        cvScores: data.scoresdataList[0].cv,
                        interviewScores: data.scoresdataList[0].interview,
                        writtenTestScores: data.scoresdataList[0].writtenTest,
                        auditionScores: data.scoresdataList[0].audition,
                        totalScores: data.scoresdataList[0].total,
                        saveUpdateBtnText: 'Update',
                        scorescolor: 'success',
                    });
                }
                else {
                    throw Error(response.statusText)
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    verifyNumber(value) {
        var mobileRex = /^[0-9]+$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    SaveScoresData = () => {
        if ((this.state.cvScores === "") || (this.state.cvScores < 0) || (this.state.cvScoresState === "error")) {
            this.setState({ cvScoresState: "error", generalError: true });
            return;
        }
        else if (this.state.interviewScores < 0 || this.state.interviewScores === "" || this.state.interviewScoresState === "error") {
            this.setState({ interviewScoresState: "error", generalError: true });
            return;
        }
        else if (this.state.writtenTestScores < 0 || this.state.writtenTestScores === "" || this.state.writtenTestScoresState === "error") {
            this.setState({ writtenTestScoresState: "error", generalError: true });
            return;
        }
        else if (this.state.auditionScores < 0 || this.state.auditionScores === "" || this.state.auditionScoresState === "error") {
            this.setState({ auditionScoresState: "error", generalError: true });
            return;
        }
        let ScoresInfo = {
            EmployeeId: this.state.employeeId,
            ScoresId: this.state.scoresId,
            CV: parseInt(this.state.cvScores),
            Interview: parseInt(this.state.interviewScores),
            WrittenTest: parseInt(this.state.writtenTestScores),
            Audition: parseInt(this.state.auditionScores),
            Total: parseInt(this.state.totalScores),
        };
        console.log(ScoresInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeScores', ScoresInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ),
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null,
            scoresId: 0,
            cvScores: "",
            interviewScores: "",
            writtenTestScores: "",
            auditionScores: "",
            totalScores: "",
        });
        this.LoadScoresData();
    }
    hideInvalidAlert() {
        this.setState({
            alert: null,
        });
    }
    SetTotalValue() {
        var Cv = 0;
        var cvValue = this.state.cvScores;
        var ConvertcvValue = parseInt(cvValue)
        if (cvValue != "" && cvValue != null && cvValue != undefined) {
            Cv = ConvertcvValue;
        }
        var Interview = 0;
        var InterviewValue = this.state.interviewScores;
        var ConvertedValue = parseInt(InterviewValue)
        if (InterviewValue != "" && InterviewValue != null && InterviewValue != undefined) {
            Interview = ConvertedValue;
        }
        var WrittenTest = 0;
        var WrittenTestValue = this.state.writtenTestScores;
        var ConvertedWrittenValue = parseInt(WrittenTestValue)
        if (WrittenTestValue != "" && WrittenTestValue != null && ConvertedWrittenValue != undefined) {
            WrittenTest = ConvertedWrittenValue;
        }
        var Audition = 0;
        var AuditionValue = this.state.auditionScores;
        var ConvertedAuditionValue = parseInt(AuditionValue)
        if (AuditionValue != "" && AuditionValue != null && AuditionValue != undefined) {
            Audition = ConvertedAuditionValue;
        }
        this.state.totalScores = Cv + Interview + WrittenTest + Audition;
    }
    AlertInvalidValue(msg) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title={"Alert"}
                    onConfirm={() => this.hideInvalidAlert()}
                    onCancel={() => this.hideInvalidAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    closeOnClickOutside={false}
                >{msg}</SweetAlert>
            )
        });

    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "cvScores":
                if (this.verifyNumber(event.target.value)) {
                    var validRange = event.target.value;
                    var cvRange = parseInt(validRange);
                    if (cvRange > stateNameEqualTo) {
                        this.state.cvScores = event.target.value;
                        this.setState({
                            [stateName + "State"]: "error",
                        });

                        this.AlertInvalidValue('Out of range');
                    }
                    else {
                        this.state.cvScores = event.target.value;
                        this.setState({
                            [stateName + "State"]: "success",
                        })
                    }
                }
                else {
                    this.setState({
                        stateName: "",
                        cvScores: "",
                        [stateName + "State"]: "error"
                    })
                }
                this.SetTotalValue();
                break;
            case "interviewScores":
                if (this.verifyNumber(event.target.value)) {
                    var validRange = event.target.value;
                    var interviewRange = parseInt(validRange);
                    if (interviewRange > stateNameEqualTo) {
                        this.state.interviewScores = event.target.value;
                        this.setState({
                            [stateName + "State"]: "error"
                        });
                        this.AlertInvalidValue('Out of range');
                    }
                    else {
                        this.state.interviewScores = event.target.value;
                        this.setState({
                            stateName: stateName,
                            [stateName + "State"]: "success",
                        })
                    }
                }
                else {
                    this.setState({
                        stateName: "",
                        interviewScores: "",
                        [stateName + "State"]: "error"
                    })
                }
                this.SetTotalValue();
                break;
            case "writtenTestScores":
                if (this.verifyNumber(event.target.value)) {
                    var validRange = event.target.value;
                    var writtenTestRange = parseInt(validRange);
                    if (writtenTestRange > stateNameEqualTo) {
                        this.state.writtenTestScores = event.target.value;
                        this.setState({
                            [stateName + "State"]: "error"
                        });
                        this.AlertInvalidValue('Out of range');
                    }
                    else {
                        this.state.writtenTestScores = event.target.value;
                        this.setState({
                            stateName: stateName,
                            [stateName + "State"]: "success",
                        })
                    }
                }
                else {
                    this.setState({
                        stateName: "",
                        writtenTestScores: "",
                        [stateName + "State"]: "error"
                    })
                }
                this.SetTotalValue();
                break;
            case "auditionScores":
                if (this.verifyNumber(event.target.value)) {
                    var validRange = event.target.value;
                    var auditionRange = parseInt(validRange);
                    if (auditionRange > stateNameEqualTo) {
                        this.state.auditionScores = event.target.value;
                        this.setState({
                            [stateName + "State"]: "error"
                        });
                        this.AlertInvalidValue('Out of range');
                    }
                    else {
                        this.state.auditionScores = event.target.value;
                        this.setState({
                            stateName: stateName,
                            [stateName + "State"]: "success",
                        })
                    }
                }
                else {
                    this.setState({
                        stateName: "",
                        auditionScores: "",
                        [stateName + "State"]: "error"
                    })
                }
                this.SetTotalValue();
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { } = this.state;
        return (
            <div style={{ paddingBottom: "10px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Scores</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.cvScoresState === "success"}
                                            error={this.state.cvScoresState === "error"}
                                            value={this.state.cvScores}
                                            size="small"
                                            label="C.V(Out of 10)"
                                            id="cvScores"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "cvScores", "cvScores", value, 10),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.interviewScoresState === "success"}
                                            error={this.state.interviewScoresState === "error"}
                                            value={this.state.interviewScores}
                                            size="small"
                                            label="Interview(Out of 20)"
                                            id="interviewScores"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "interviewScores", "interviewScores", value, 20),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.writtenTestScoresState === "success"}
                                            error={this.state.writtenTestScoresState === "error"}
                                            value={this.state.writtenTestScores}
                                            size="small"
                                            label="Written Test(Out of 30)"
                                            id="writtenTestScores"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "writtenTestScores", "writtenTestScores", value, 30),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.auditionScoresState === "success"}
                                            error={this.state.auditionScoresState === "error"}
                                            value={this.state.auditionScores}
                                            size="small"
                                            label="Audition(Out of 40)"
                                            id="auditionScoresId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "auditionScores", "auditionScores", value, 40),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.totalScoresState === "success"}
                                            error={this.state.totalScoresState === "error"}
                                            value={this.state.totalScores}
                                            size="small"
                                            label="Total(Out of 100)"
                                            id="totalScores"
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "totalScores", "totalScores", value, 100),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color={this.state.scorescolor} onClick={() => { this.SaveScoresData() }}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Employee Scores Detail"}
                                    columns={[
                                        { label: "Test Phases" },
                                        { label: "Total Scores" },
                                        { label: "Obtained Scores" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(Scores);