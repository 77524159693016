
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}
const formTypeDD = [
    { formTypeId: 1, value: ' New' },
    { formTypeId: 0, value: 'Edit' },

]

class CreateSalary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            SalaryDataList: [],
            employeeSalaryList: [],
            employeeIdsList: [],
            monthDate: Moment(new Date()).format('YYYY-MM-DD'),
            issueDate: Moment(new Date()).format('YYYY-MM-DD'),
            transferDate: Moment(new Date()).format('YYYY-MM-DD'),
            formTypeDD: [{ formTypeId: -1, name: "Select" }],
            formTypeId: 1,
            formTypeIdIndex: 0,
            formTypeIdState: "success",
            checked: false,
            remarks: "",
            departmentName: "",
            designationName: "",
            totalamount: 0,
            netSalary: "",
            clearNetSalary: 'none',
            createBtnDisabled: true,
            deleteBtnDisabled: true,
            alert: null, show: false, generalError: false,
        };
        //#endregion 
    }

    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "monthDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        monthDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "issueDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        issueDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "transferDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        transferDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "formTypeId":
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: formTypeDD.findIndex(x => x[stateName] === value[stateName]),
                    formTypeId: value.formTypeId,
                    [stateName + "State"]: "success"
                })
                break;
            case "remarks":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        [stateName + "State"]: "success",
                        remarks: event.target.value,
                    });
                } else {
                    this.setState({
                        // [stateName + "State"]: "errorNew"
                        remarks: event.target.value,
                    });

                }
                break;
        }
    }
    selectValue(value, status) {
        debugger;
        if (status == false) {
            debugger;
            this.setState({
                checked: true,
            });
            this.state.checked = true;
            var findindex = this.state.employeeIdsList.findIndex(o => o.EmployeeId === value);
            this.state.employeeIdsList.splice(findindex, 1);
            console.log(this.state.employeeIdsList);
        }
        else {
            var obj = this.state.SalaryDataList.find(o => o.employeeId === value);
            if (obj != null && obj != "" && obj != undefined) {
                this.state.employeeIdsList.push({
                    EmployeeId: obj.employeeId,
                    PlacementId: obj.placementId,
                    Salary: obj.grossSalary,
                    BankId: obj.bankId,
                });
            }
            console.log(this.state.employeeIdsList);
            debugger;
            this.setState({
                checked: false,
            });
        }

        }
    searchBtn = () => {
        debugger;
        fetch(Base_URL + "/api/CreateSalary/LoadCreateSalary?SalaryMonth=" + this.state.monthDate + "")
            .then(response => {
                return response.json();
            })
            .then(data => {
                debugger;
                this.setState({
                    SalaryDataList: data.createSalaryList,
                    createBtnDisabled: false,
                    deleteBtnDisabled: true,
                });
                        if (this.state.formTypeId === 0) {
                            this.setState({
                                createBtnDisabled: true,
                                deleteBtnDisabled: false,
                                clearNetSalary: 'block'
                            });
                            var totalSalary = 0;
                            for (let i = 0; i < data.createSalaryList.length; ++i) {
                                if (data.createSalaryList[i].grossSalary != 0 && data.createSalaryList[i].grossSalary != null) {
                                    totalSalary = totalSalary + data.createSalaryList[i].grossSalary;
                                }
                            }
                            var NetSalary = this.numberWithCommas(totalSalary)
                            this.state.netSalary = NetSalary;
                            this.setState({
                                netSalary: NetSalary
                            });
                        }
                        else {
                            if (data.createSalaryList.length > 0 && data.createSalaryList.length != null) {
                                this.state.SalaryDataList = data.createSalaryList;
                                for (let i = 0; i < data.createSalaryList.length; ++i) {
                                    if (data.createSalaryList[i].employeeId != 0 && data.createSalaryList[i].employeeId != null) {
                                        this.state.employeeIdsList.push({
                                           EmployeeId: data.createSalaryList[i].employeeId,
                                            PlacementId: data.createSalaryList[i].placementId,
                                            Salary: data.createSalaryList[i].grossSalary,
                                            BankId: data.createSalaryList[i].bankId,
                                        });
                                        //this.state.employeeIdsList = this.state.employeeIdsList.concat(
                                        //    data.createSalaryList[i].employeeId,
                                        //    data.createSalaryList[i].placementId,
                                        //    data.createSalaryList[i].grossSalary
                                        //);
                                    }
                                }
                                this.setState({
                                    netSalary: "",
                                    clearNetSalary: 'none'
                                });
                                console.log(this.state.employeeIdsList);
                                
                            }
                        }
                    })
            .catch(error => {
                console.log(error);
            });
    }
     numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    LoadSalaryData = (employeeId) => {
        debugger;
        var totalAmount = 0;
        this.state.totalamount = 0;
        fetch(Base_URL + "/api/CreateSalary/LoadSalary?EmployeeId=" + employeeId+"")
            .then(response => {
                return response.json();
            })
            .then(data => {
                debugger;
                this.state.name = data.loadSalaryList[0].name;
                this.state.departmentName = data.loadSalaryList[0].departmentName;
                this.state.designationName = data.loadSalaryList[0].designationName;
                this.setState({
                    employeeSalaryList: data.loadSalaryList
                });
                if (data.loadSalaryList.length > 0 && data.loadSalaryList.length != null) {
                    this.state.employeeSalaryList = data.loadSalaryList;
                    for (let i = 0; i < data.loadSalaryList.length; ++i) {
                        if (data.loadSalaryList[i].alloTypeName === "Allowance") {
                            totalAmount = totalAmount + data.loadSalaryList[i].amount ;
                        }
                        else {
                            totalAmount = totalAmount - data.loadSalaryList[i].amount;
                        }
                    }
                    var NetAmount = this.numberWithCommas(totalAmount)
                    this.state.totalamount = NetAmount;
                    this.setState({
                        totalamount: NetAmount
                    });
                   //this.state.totalamount = Netamount; 
                }
            })
            .catch(error => {
                console.log(error);
            });

    }

    SaveSalaryData = () => {
        debugger;
        let SalaryInfo = {
            IssueDate: Moment(this.state.issueDate).format('YYYY-MM-DD'),
            TDate: Moment(this.state.transferDate).format('YYYY-MM-DD'),
            Remarks: this.state.remarks,
            MonthDate: this.state.monthDate,
            listofvalues:this.state.employeeIdsList,
        };
        console.log(SalaryInfo);
        axios.post(Base_URL + '/api/CreateSalary/SaveSalary', SalaryInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    AlertMessage(status, message) {
        debugger
        if (status === 'Success') {
            this.setState({
                alert: (
                    swal(
                        {
                            title: status,
                            text: message,
                            icon: "success",
                            button: "OK",
                        }
                    ))
            });
        }
        else {
            this.setState({
                alert: (
                    swal(
                        {
                            title: status,
                            text: message,
                            icon: "warning",
                            button: "OK",
                        }
                    )
                ),
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            alert: null,
        });

    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    render() {
        const { classes } = this.props;
        const { SalaryDataList, employeeSalaryList } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Edit />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Create & Edit Salary</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Create & Edit Salary</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Create & Edit Salary</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Salary Month"
                                                        id="monthDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.monthDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "monthDate", "monthDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Issue Date"
                                                        id="issueDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.issueDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "issueDate", "issueDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Transfer Date"
                                                        id="transferDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.transferDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "transferDate", "transferDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={formTypeDD}
                                                        getOptionLabel={(option) => option.value}
                                                        id="formTypeId"
                                                        value={formTypeDD[this.state.formTypeIdIndex]}
                                                        disableClearable={true}
                                                        //onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        onChange={(event, value) => this.change(event, "formTypeId", "formTypeId", value)}
                                                        renderInput={(params) => <TextField {...params} label="New / Edit" variant="outlined"
                                                            success={this.state.formTypeIdState === "success"}
                                                            error={this.state.formTypeIdState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        value={this.state.remarks}
                                                        size="small"
                                                        label="Remarks"
                                                        id="remarks"
                                                        variant="outlined"
                                                        fullWidth
                                                        // required={true}
                                                        inputProps={{
                                                            onChange: (event, value) =>
                                                                this.change(event, "remarks", "remarks", value, 3),
                                                            type: "text",
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <Button color="facebook" onClick={() => { this.searchBtn() }}> Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={8}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Create Salary"}
                                            data={SalaryDataList}
                                            columns={[
                                                /*  { name: "employeeId", label: "Ignore" },*/
                                                {
                                                    name: "employeeId", label: "Ignore",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (value) != null ? <input type="checkbox" checked={this.state.checked} onClick={() => { this.selectValue(value, this.state.checked) }} /> : "";
                                                        }
                                                    }
                                                },
                                                { name: "regNo", label: "Emp ID" },
                                                { name: "name", label: "Name" },
                                                { name: "campusName", label: "Campus" },
                                                { name: "departmentName", label: "Department" },
                                                { name: "designationName", label: "Designation" },
                                                { name: "days", label: "Days" },
                                                { name: "grossSalary", label: "Gross Salary" },
                                                {
                                                    name: "employeeId", label: "View",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (value) != null ? <Button color="facebook" onClick={() => { this.LoadSalaryData(value) }}>View</Button> : "";
                                                        }
                                                    }
                                                },
                                              /*  { name: "employeeId", label: "View" },*/

                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                    <GridTextbox xs={12} sm={12} lg={12}>
                                        <Button color="facebook" onClick={() => { this.SaveSalaryData() }} disabled={this.state.createBtnDisabled} > Create salary </Button>
                                        <Button color="facebook" onClick={() => { this.searchBtn() }} disabled={this.state.deleteBtnDisabled}> Delete Salary </Button>
                                    </GridTextbox>
                                    <CardHeader>
                                        <Typography color="textPrimary" style={{ display: '' + this.state.clearNetSalary + '' }}>Net Salary: {this.state.netSalary}</Typography>
                                    </CardHeader>
                                </GridForm>
                                <GridForm xs={12} sm={4} md={4}>
                                    <Card style={{ marginTop: "13px" }}>
                                        <CardHeader>
                                            <Typography color="textPrimary">Name: {this.state.name}</Typography>
                                            <Typography color="textPrimary">Department: {this.state.departmentName}</Typography>
                                            <Typography color="textPrimary">Designation: {this.state.designationName}</Typography>
                                        </CardHeader>
                                        <MuiThemeProvider theme={muiWithToolbar()}>
                                            <MUIDataTable
                                                data={employeeSalaryList}
                                                columns={[
                                                    { name: "allowanceName", label:"Allowance / Deduction" },
                                                    { name:"amount",label: "Amount" },
                                                ]}
                                                options={tbleWithPrint}
                                            />
                                        </MuiThemeProvider>
                                        <CardHeader style={{ marginTop: "2%", marginBottom: "2%" }}>
                                            <Typography color="textPrimary">Net Amount: {this.state.totalamount}</Typography>
                                        </CardHeader>
                                    </Card>

                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion 
}
export default withStyles(extendedFormsStyle)(CreateSalary);
