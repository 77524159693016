import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import { FormControl, InputLabel, Checkbox, Select, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import bglogin from "assets/img/login.jpeg";
import history from '../../context/History';
import swal from 'sweetalert';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
            loginValue: "",
            citySelect: "",
        };
        this.change = this.change.bind(this);
    }
    handleCity = event => {
        this.setState({ [event.target.name]: event.target.value });
    };
    componentDidMount() {
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );
        document.body.style.overflow = "unset"; 
    }
    getBgImageLogin = () => {
        return bglogin;
    };

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }
    change(event, id) {
        //debugger;
        switch (id) {
            case "username":
                if (event.target.value !== '') {
                    this.setState({ ["loginValue"]: event.target.value });
                }
                else {
                    this.setState({ ["loginValue"]: "" });
                }
                this.state.HasCredentials = false;
                break;

            
            default:
                break;
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.wrapper} ref="wrapper">
                    <div
                        className={classes.fullPage} style={{ backgroundImage: "url(" + this.getBgImageLogin() + ")" }}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <form>
                            <Card login className={classes[this.state.cardAnimaton]}>
                                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose" >
                                    <h4 className={classes.cardTitle}>Forgot Password</h4>
                                </CardHeader>
                                <CardBody>
                                    <CustomInput
                                        labelText="User Name *"
                                        id="username"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            onChange: event =>
                                                this.change(event, "username"),
                                            type: "text",
                                            value: this.state.loginValue
                                        }}
                                    />
                                    <FormControl fullWidth className={classes.selectFormControl} >
                                        <InputLabel htmlFor="--select--" className={classes.selectLabel} > Select City </InputLabel>
                                        <Select
                                            MenuProps={{ className: classes.selectMenu }}
                                            classes={{ select: classes.select }}
                                            value={this.state.citySelect}
                                            onChange={this.handleCity}
                                            inputProps={{ name: "citySelect", id: "city-select" }} >
                                            <MenuItem
                                                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                value="Lahore"> Lahore </MenuItem>
                                            <MenuItem
                                                classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
                                                value="Karachi"> Karachi </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button color="facebook" block onClick={() => this.SendToController(this.state.loginValue, this.state.citySelect)}>
                                        Login </Button>
                                </CardBody>
                                <CardFooter className={classes.justifyContentCenter}>
                                                <Link to={"/loginpage"} style={{ textDecoration: 'none' }}>
                                        <label> Go Back to Login </label>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    SendToController(login, CampCity) {
        debugger;
        if (login === null || login === '') {
            swal({
                title: "Invalid User Information Alert!",
                text: "Please enter valid Login Id (User Name).",
                icon: "error",
                button: "OK"
            });
        }
        else if (CampCity === null || CampCity === '') {
            swal({
                title: "Invalid User Information Alert!",
                text: "Please select city i.e Lahore/Karachi.",
                icon: "error",
                button: "OK"
            });
        }
        else {
            let req = new XMLHttpRequest();
            req.timeout = 10000;
            var Base_URL = window.location.origin;
            if (Base_URL == 'http://localhost:58487') {
                Base_URL = Base_URL + '/ForgotPassword/EmployeePasswordGet?Contaxt=' + CampCity + '&UserName=' + login + '';
            }
            else {
                Base_URL = Base_URL + '/btsmis/ForgotPassword/EmployeePasswordGet?Contaxt=' + CampCity + '&UserName=' + login + '';
            }
            req.open('GET', Base_URL, true)
            req.responseType = 'json';
            var ty = req.getResponseHeader('content-type');
            req.setRequestHeader('content-type', ty);
            req.onload = function () {
                debugger;
                if (req.readyState == 4 && req.status == 200) {
                    debugger;
                    let responseObj = req.response;
                    if (responseObj != null) {
                        swal({
                            title: "Forgot Password",
                            text: responseObj.responseMSG,
                            icon: "success",
                            button: "OK"
                        });
                    }
                    else {
                        swal({
                            title: "Forgot Password!",
                            text: responseObj.responseMSG,
                            icon: "error",
                            button: "OK"
                        });
                    }
                }
            };
            req.send(null);
            req.onerror = function () {
                //debugger;
                swal({
                    title: "Forgot Password!",
                    text: req.statusText,
                    icon: "error",
                    button: "OK"
                });
            };
        }

    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(loginPageStyle)(ForgotPassword);
