
//Import from Globel Component";

import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, Equalizer
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class AuditedTrialBalance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            paid: true,
        };
        //#endregion
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    onEditorStateChang = (editorState) => {
        this.setState({
            editorState,
        });
    };
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <Equalizer />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Audited Trial Balance</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/accounts" > Accounts</Link>
                            <Typography color="textPrimary">Audited Trial Balance</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "5px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Audited Trial Balance</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Previous Debit"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Previous Credit"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Current Debit"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Current Credit"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Net Debit"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Net Credit"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={startDateState === "success"}
                                                        error={startDateState === "error"}
                                                        size="small"
                                                        label="Start Date"
                                                        id="startDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(startDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.setDateValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={startDateState === "success"}
                                                        error={startDateState === "error"}
                                                        size="small"
                                                        label="End Date"
                                                        id="startDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(startDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.setDateValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Button color="facebook"><SaveOutlined />Save</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Audited Trial Balance"}
                                            columns={[
                                                { label: "Previous Debit" }, { label: "Previous Credit" },
                                                { label: "Current Debit" }, { label: "Current Credit" },
                                                { label: "Net Debit" }, { label: "Net Credit" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(AuditedTrialBalance);