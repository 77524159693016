
//Import from Globel Component";
import {
    React, axios, SweetAlert, SaveOutlined, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
     EditOutlined, Edit
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class ProjectDirectory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Dropdown
            erpModuleDD: [{ "erpModuleId": null, "erpModuleName": null, "active": null }],
            erpModuleIdIndex: 0,
            erpModuleId:-1,
            erpModuleIdState: '',
            formTypeDD: [{ "formTypeId": null, "formTypeName": null }],
            formTypeIdIndex: 0,
            formTypeId:-1,
            formTypeIdState: '',
            //#endregion
            projectDirectoryId:-1,
            controllerName: "",
            controllerNameState: "",
            formName: "",
            formNameState: "",
            functionName: "",
            functionNameState: "",
            fileName:"",
            fileNameState: "",
            lineNumber:0,
            lineNumberState: "",
            directoryList: [],
            formDisbaled: true,
            controllerDisabled: false, 
            functionDisabled: false,
            lineDisabled:false,
            //#region Other Variables and states
            active: true,
            alert: null,
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            saveUpdateBtnText: 'Save',
            //formTypeDisabled :false,
        };
        //#endregion 
        this.hideAlert = this.hideAlert.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.LoadProjectDirectoryData = this.LoadProjectDirectoryData.bind(this);
        this.ClearTextfieldStates = this.ClearTextfieldStates.bind(this);
    }
    //#region componentDidMount
    componentDidMount() {
        this.LoadErpModules();
        this.LoadFormTypes();
    }
    async LoadErpModules() {
        try {
            const response = await (fetch(Base_URL + "/api/ITManagementCommonDD/GetERPModules"))
            if (response.ok) {
                const data = await response.json();
                if ((data.exception.exceptionToken == null || data.exception.exceptionToken === '')
                    && (data.exception.displayMessage == null || data.exception.displayMessage === '') && data.erpModuleList != null && data.erpModuleList.length > 0) {
                    var arr = [{ "erpModuleId": -1, "erpModuleName": 'Select', "active": null }];
                    arr = arr.concat(data.erpModuleList);
                    this.setState({
                        erpModuleDD: arr
                    });
                }
                else {
                    this.setState({
                        erpModuleDD: [{ "erpModuleId": null, "eRPModuleName": null, "active": null }]
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadFormTypes() {
        try {
            const response = await (fetch(Base_URL + "/api/ITManagementCommonDD/GetFormTypes"))
            if (response.ok) {
                const data = await response.json();
                if ((data.exception.exceptionToken == null || data.exception.exceptionToken === '')
                    && (data.exception.displayMessage == null || data.exception.displayMessage === '') && data.fromTypesList != null && data.fromTypesList.length > 0) {
                    var arr = [{ "formTypeId": -1, "formTypeName": 'Select' }];
                    arr = arr.concat(data.fromTypesList);
                    this.setState({
                        formTypeDD: arr
                    });
                }
                else {
                    this.setState({
                        formTypeDD: [{ "formTypeId": null, "formTypeName": null }]
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    //#endregion
    //#region Functions
    setDDValue = (event, value, state) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
        if (state === 'formTypeIdSelected') {
            if (value[stateName] !== -1) {
                this.setState({
                    formDisbaled: false,
                    controllerDisabled: true,
                    functionDisabled: true,
                    lineDisabled:true,
                    controllerName: "",
                    functionName: "",
                    lineNumber: 0,
                   // formTypeDisabled: true,
                });
            }
            else if (value[stateName] === -1) {
                this.setState({
                    formDisbaled: true,
                    controllerDisabled: false,
                    functionDisabled: false,
                    lineDisabled: false,
                    formName: "",
                   // formTypeDisabled: true,
                });
            }
        }
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    setValue(event) {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, generalError: false });
    }
    validateData = () => {
        debugger;
        if (this.state.erpModuleId < 0) {
            this.setState({ erpModuleIdState: "error" })
        }
        else if (this.state.fileName === "") {
            this.setState({ fileNameState: "error" })
        }
        else if (this.state.formTypeId === -1 &&this.state.lineNumber === 0) {
            this.setState({ lineNumberState: "error" })
        }
        else if (this.state.formTypeId === -1 && this.state.controllerName === "") {
            this.setState({ controllerNameState: "error" })
        }
        else if (this.state.formTypeId === -1 && this.state.functionName === "") {
                this.setState({ functionNameState: "error" })
            }
        else if (this.state.formTypeId !== -1 && this.state.formName === "") {
                this.setState({ formNameState: "error" })
        }
        else {
            this.ConfirmMessage('Save', 'Do You Want To Save!')
        }
    }
    ClearTextfieldStates() {
        debugger;
        this.setState({
            controllerName: "", formName: "", functionName: "", fileName: "", lineNumber: 0, formTypeIdIndex: 0, formTypeId: -1, erpModuleIdIndex: 0, erpModuleId: -1,
            saveUpdateBtnDisabled: false, clearTextBtn: 'none', saveUpdateBtnText: 'Save'
        });
       // this.hideAlert();
    }
    LoadTextbox = (DirectoryId) => {
        debugger;
        let obj = this.state.directoryList.find(o => o.projectDirectoryId === DirectoryId);
        var erpModuleIndex = this.state.erpModuleDD.findIndex(x => x.erpModuleId === obj.erpModuleId);
        if (obj.pdFormTypeId === null) {
            this.setState({
                formDisbaled: true,
                controllerDisabled: false,
                functionDisabled: false,
                lineDisabled: false,
                formName: "",
                controllerName: obj.controllerName,
                functionName: obj.functionName,
                lineNumber: obj.functionLineNo,
                formTypeIdIndex: 0,
                formTypeId: -1,
            })
            
        }
        else {
            var formTypeIndex = this.state.formTypeDD.findIndex(x => x.formTypeId === obj.pdFormTypeId)
            this.setState({
                formDisbaled: false,
                controllerDisabled: true,
                functionDisabled: true,
                lineDisabled: true,
                controllerName: "",
                functionName: "",
                lineNumber: 0,
                formTypeIdIndex: formTypeIndex,
                formTypeId: obj.pdFormTypeId,
                formName: obj.pdFormName,
            })
        }
       
       
        this.setState({
            erpModuleIdIndex: erpModuleIndex, erpModuleId: obj.erpModuleId,  
             fileName: obj.fileName,  projectDirectoryId:obj.projectDirectoryId,
            saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update',
            clearTextBtn: 'block'
        })
    }
    //#endregion
    //#region Save Update Search Data
    SaveProjectDirectory() {
           this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/ITAdministration/ProjectDirectorySave?ERPModuleId=" + this.state.erpModuleId + "&ControllerName=" + this.state.controllerName + "&FormName=" + this.state.formName + "&FormTypeId=" + this.state.formTypeId + "&FunctionName=" + this.state.functionName + "&FileName=" + this.state.fileName + "&FunctionLineNo=" + this.state.lineNumber + "&ProjectDirectoryId=" + this.state.projectDirectoryId)
            .then(json => {
                    debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.response.status, json.data.response.message);
                    this.setState({
                        formDisbaled: true,
                        controllerDisabled: false,
                        functionDisabled: false,
                        lineDisabled: false,
                    })
                    }
                    else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                    }
            })
    }
    LoadProjectDirectoryData  ()  {
        fetch(Base_URL + "/api/ITAdministration/LoadProjectDirectory" ).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({ directoryList: data.projectDirectory })
                }
                else {
                    this.AlertMessage("Error", data.exception.displayMessage);
                }
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveProjectDirectory()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        debugger;
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
        this.ClearTextfieldStates();
        this.LoadProjectDirectoryData();
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        this.ClearTextfieldStates();
    }
    //#endregion
    //#region Form Body and Return
    debugger;
    render() {
        const { erpModuleDD, erpModuleIdIndex, erpModuleIdState, formTypeDD, formTypeIdIndex, formTypeIdState, controllerName, formName, functionName, fileName,
            lineNumber, clearTextBtn, directoryList
        } = this.state;
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <CardBody>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Project Directory Information</h4>
                                        </CardHeader>
                                        <GridTextbox xs={12} sm={12} lg={1}>
                                            <Button color="facebook" onClick={this.LoadProjectDirectoryData}>Load</Button>
                                        </GridTextbox>
                                        <hr />
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="erpModuleId"
                                                    size="small"
                                                    options={erpModuleDD}
                                                    getOptionLabel={(option) => option.erpModuleName}
                                                    value={erpModuleDD[erpModuleIdIndex]}
                                                    onChange={(event, value) => { this.setDDValue(event, value, "erpModuleIdSelected") }}
                                                    renderInput={(params) => <TextField {...params} success={erpModuleIdState === "success"}
                                                        error={erpModuleIdState === "error"} label="ERP Module" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                <TextField
                                                    success={this.state.controllerNameState === "success"}
                                                   error={this.state.controllerNameState === "error"}
                                                   disabled={this.state.controllerDisabled}
                                                    size="small"
                                                    label="Controller Name"
                                                    id="controllerName"
                                                    value={controllerName }
                                                    variant="outlined"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(event, value) => { this.setValue(event) }} />
                                                </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                    success={this.state.formNameState === "success"}
                                                    error={this.state.formNameState === "error"}
                                                    disabled={this.state.formDisbaled}
                                                    size="small"
                                                    label="Form Name"
                                                    id="formName"
                                                    value={formName}
                                                    variant="outlined"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(event, value) => { this.setValue(event) }}/>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                        disableClearable
                                                        //disabled={this.state.formTypeDisabled}
                                                    id="formTypeId"
                                                    size="small"
                                                    options={formTypeDD}
                                                    getOptionLabel={(option) => option.formTypeName}
                                                    value={formTypeDD[formTypeIdIndex]}
                                                    onChange={(event, value) => { this.setDDValue(event, value, "formTypeIdSelected") }}
                                                    renderInput={(params) => <TextField {...params} success={formTypeIdState === "success"}
                                                        error={formTypeIdState === "error"} label="Form Type" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                <TextField
                                                    success={this.state.functionNameState === "success"}
                                                    error={this.state.functionNameState === "error"}
                                                     disabled={this.state.functionDisabled}
                                                    size="small"
                                                    label="Function Name"
                                                    id="functionName"
                                                    value={functionName}
                                                    variant="outlined"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(event, value) => { this.setValue(event) }} />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                <TextField
                                                    success={this.state.fileNameState === "success"}
                                                    error={this.state.fileNameState === "error"}
                                                    size="small"
                                                    label="File Name"
                                                    id="fileName"
                                                    value={fileName}
                                                    variant="outlined"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(event, value) => { this.setValue(event) }} />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                <TextField
                                                    success={this.state.lineNumberState === "success"}
                                                        error={this.state.lineNumberState === "error"}
                                                        disabled={this.state.lineDisabled}
                                                    size="small"
                                                    label="Line Number"
                                                    id="lineNumber"
                                                    value={lineNumber}
                                                    variant="outlined"
                                                    type="text"
                                                    fullWidth
                                                    onChange={(event, value) => { this.setValue(event) }} />
                                            </GridTextbox>

                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                            </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Project Directory Detail"}
                                        data={directoryList}
                                        columns={[
                                            {
                                                label: "Directory ID",
                                                name:"projectDirectoryId"
                                            },
                                            {
                                                label: "ERP Module Name",
                                                name:"erpModuleName"
                                            },
                                            {
                                                label: "Controller Name",
                                                name:"controllerName"
                                            },
                                            {
                                                label: "Form Name",
                                                name:"pdFormName"
                                            },
                                            {
                                                label: "Form Type Name",
                                                name:"pdFormType"
                                            },
                                            {
                                                label: "Function Name",
                                                name:"functionName"
                                            },
                                            {
                                                label: "File Name",
                                                name:"fileName"
                                            },
                                            {
                                                label: "Line Numer",
                                                name:"functionLineNo"
                                            },
                                            {
                                                label: "Edit",
                                                name:"projectDirectoryId",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                    }
                                                }                                            }
                                        ]}
                                        options={tbleWithPrint} />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(ProjectDirectory);