import {
    React, withStyles, Autocomplete, TextField, GridContainer, GridTextbox, formStyle
} from '../ImportAll';
var Base_URL = localStorage.BaseURL;
//const dateVal = {
//    currentDate: new Date().getDate(),
//    currentMonth: new Date().getMonth(),
//    currentYear: new Date().getFullYear(),
//}
class AllDropDowns extends React.Component {
    constructor(props) {
    //#region States
        super(props);
        debugger;
        this.state = {
            
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionId: -1,
            sectionIdIndex: 0,
            sectionIdState: '',
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionId: -1,
            sessionIdIndex: 0,
            sessionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId:0,
            campusIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classId: -1,
            classIdState: '',
            classIdIndex: 0,
            educationLevelDD: [{ educationLevelId: -1, levelName: 'Select' }],
            educationLevelId: -1,
            educationLevelIdState: '',
            educationLevelIdIndex: 0,
            alert: null,
            show: false,
            generalError: false,
            isSessionAll: false,
            isCampusAll: false,
            isLevelAll: false,
            isClassAll: false,
            isSectionAll: false,
            showSession: false,
            showCampus: false,
            showLevel: false,
            showClass: false,
            showSection: false,
            FormName: props.data.formName,
            newSessionId: 0,
            newCampusId: -1,
            neweducationLevelId: 0,
            newclassId: 0,
            newsectionId:0,

        }
        if (props.data.IsSessionAll === true) {
            this.state.isSessionAll = true;
        }
        if (props.data.IsCampusAll === true) {
            this.state.isCampusAll = true;
        }
        if (props.data.IsLevelAll === true) {
            this.state.isLevelAll = true;
        }
        if (props.data.IsClassAll === true) {
            this.state.isClassAll = true;
        }
        if (props.data.IsSectionAll === true) {
            this.state.isSectionAll = true;
        }
        if (props.data.ShowSession === true) {
            this.state.showSession = true;
        }
        if (props.data.ShowCampus === true) {
            this.state.showCampus = true;
        }
        if (props.data.ShowLevel === true) {
            this.state.showLevel = true;
        }
        if (props.data.ShowClass === true) {
            this.state.showClass = true;
        }
        if (props.data.ShowSection === true) {
            this.state.showSection = true;
        }
        if (props.data.sessionId !== "" && props.data.sessionId !== null || props.data.sessionId !== -1) {
            this.state.newSessionId = props.data.sessionId
        }
        if (props.data.campusId !== "" || props.data.campusId !== null || props.data.campusId !== -1) {
            debugger;
            this.state.newCampusId = props.data.campusId;
        }
        if (props.data.educationLevelId !== "" || props.data.educationLevelId !== null || props.data.educationLevelId !== -1) {
            
            this.state.neweducationLevelId = props.data.educationLevelId;
        }
        if (props.data.classId !== "" || props.data.classId !== null || props.data.classId !== -1) {
            this.state.newclassId = props.data.classId;
        }
        if (props.data.sectionId !== "" || props.data.sectionId !== null || props.data.sectionId !== -1) {
            this.state.newsectionId = props.data.sectionId;
        }
       
      //#endregion
        this.setValue = this.setValue.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.validateData = this.validateData.bind(this);
        this.loadDropDown();
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, generalError: false });
    }
    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: newIndex,
            [stateName + "State"]: "success",
            generalError: false
        })
        this.props.handleStateChange(event, value);
    }
    //#region Dropdowns
    loadDropDown = () => {
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                var sessionArr = [];
                if (this.state.isSessionAll === true) {
                    sessionArr = [{ sessionId: -1, sessionName: 'All' }];
                    sessionArr = sessionArr.concat(data.sessionsDDList);
                }
                else {
                    //sessionArr = [{ sessionId: -1, sessionName: 'Select' }];
                   // sessionArr = sessionArr.concat(data.sessionsDDList);
                    sessionArr = data.sessionsDDList;
                }
                this.setState({ sessionDD: sessionArr, sessionId: sessionArr[0]['sessionId'] });
                if (this.state.newSessionId !== "" && this.state.newSessionId !== null && this.state.newSessionId !== -1 && typeof this.state.newSessionId !== 'undefined') {
               
                    var newindex = this.state.sessionDD.findIndex(x => x.sessionId == this.state.newSessionId);
                    this.setState({
                        sessionId: this.state.newSessionId,
                        sessionIdState: "success",
                        sessionIdIndex: newindex
                    });
                }
                this.fillcampuses(sessionArr[0]['sessionId']);
            }).catch(error => {
                console.log(error);
            });
        
    }
    fillcampuses = (sessionId) => {
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                debugger;
                var campusesArr = [];
                if (this.state.isCampusAll === true) {
                    //campusesArr = [{ campusId: -1, campusName: 'All' }];
                    //campusesArr = campusesArr.concat(data.campusDDList);

                    campusesArr = [{ campusId: -1, campusName: 'All' }];
                    campusesArr = campusesArr.concat(data.campusDDList);
                }
                else {
                    debugger;
                    campusesArr = [{ campusId: -1, campusName: 'Select' }];
                    campusesArr = campusesArr.concat(data.campusDDList);
                }
                this.setState({ campusDD: campusesArr, campusId: campusesArr[0]['campusId'] });
                if (this.state.newCampusId !== "" && this.state.newCampusId !== null && this.state.newCampusId !== -1 && typeof this.state.newCampusId !== 'undefined') {
                  
                    var newindex = this.state.campusDD.findIndex(x => x.campusId == this.state.newCampusId);
                    this.setState({
                        campusId: this.state.newCampusId,
                        campusIdState: "success",
                        campusIdIndex: newindex
                    });
                }
                this.filllevels(campusesArr[0]['campusId']);
               
              
            }).catch(error => {
                console.log(error);
            });
    }
    filllevels = (CampusID) => {
        fetch(Base_URL + "/api/Dropdowns/AcdamicLevelDD").then(response => { return response.json(); })
            .then(data => {
                debugger;
                var levelArr = [];
                if (this.state.isLevelAll === true) {
                    levelArr = [{ educationLevelId: -1, levelName: 'All' }];
                    levelArr = levelArr.concat(data.acdamicLevelDDList);
                }
                else {
                    debugger;
                    levelArr = [{ educationLevelId: -1, levelName: 'Select' }];
                    levelArr = levelArr.concat(data.acdamicLevelDDList);
                }
                this.setState({ educationLevelDD: levelArr, educationLevelId: levelArr[0]['educationLevelId'] });
                if (this.state.neweducationLevelId !== "" && this.state.neweducationLevelId !== null && this.state.neweducationLevelId !== -1 && typeof this.state.neweducationLevelId !== "undefined") {
                    debugger;
                    var newindex = this.state.educationLevelDD.findIndex(x => x.educationLevelId == this.state.neweducationLevelId);
                    this.setState({
                        educationLevelId: this.state.neweducationLevelId,
                        educationLevelIdState: "success",
                        educationLevelIdIndex: newindex
                    });
                    this.fillClasses(this.state.neweducationLevelId);
                }
                else {
                this.fillClasses(levelArr[0]['educationLevelId']);
                }
               
            }).catch(error => {
                console.log(error);
            });
    }
    fillClasses = (levelid) => {

        debugger;
        fetch(Base_URL + "/api/Dropdowns/ClassDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.campusId + "&LevelId=" + levelid).then(response => { return response.json(); })
            .then(data => {
                debugger;
                var classesArr = [];
                if (this.state.isClassAll === true) {
                    classesArr = [{ classId: -1, className: 'All' }];
                    classesArr = classesArr.concat(data.classDDList);
                }
                else {
                    debugger;
                    classesArr = [{ classId: -1, className: 'Select' }];
                    classesArr = classesArr.concat(data.classDDList);
                }
                this.setState({ classDD: classesArr, classId: classesArr[0]['classId'] });


                if (this.state.newclassId !== "" && this.state.newclassId !== null && this.state.newclassId !== -1 && typeof this.state.newclassId !== 'undefined') {
                    debugger;
                    var newindex = this.state.classDD.findIndex(x => x.classId == this.state.newclassId);
                    this.setState({
                        classId: this.state.newclassId,
                        classIdState: "success",
                        classIdIndex: newindex
                    });
                }
                    this.fillSections(classesArr[0]['classId']);
              


               
            }).catch(error => {
                console.log(error);
            });
    }
    fillSections = (ClassId) => {
       debugger;
        fetch(Base_URL + "/api/Dropdowns/SectionDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.campusId + "&ClassId=" + ClassId + "&LevelId=" + this.state.educationLevelId).then(response => { return response.json(); })
            .then(data => {
               debugger;
                var sectionsArr = [];
                if (this.state.isSectionAll === true) {
                    sectionsArr = [{ sectionId: -1, sectionName: 'All' }];
                    sectionsArr = sectionsArr.concat(data.sectionsDDList);
                }
                else {
                    sectionsArr = data.sectionsDDList;
                }
                this.setState({ sectionDD: sectionsArr, sectionId: sectionsArr[0]['sectionId'] });
                //if (this.state.newsectionId !== "" || this.state.newsectionId !== null || this.state.newsectionId !== -1) {
                //    debugger;
                //    var newindex = this.state.sectionDD.findIndex(x => x.sectionId == this.state.newsectionId);
                //    this.setState({
                //        sectionId: this.state.newsectionId,
                //        sectionIdState: "success",
                //        sectionIdIndex: newindex
                //    });
                /*}*/
               
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    resetClassSec = () => {
        this.setState({
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionId: -1, sectionIdIndex: 0, sectionIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classId: -1, classIdState: '', classIdIndex: 0,
        });
    }
    changeValue = (event, type) => {

    }
    validateData = () => {
        //debugger;
        if (this.state.sessionId < 1) {
            this.setState({ sessionIdState: "error", generalError: true });
            return;
        }
        else if (this.state.campusId < 1) {
            this.setState({ campusIdState: "error", generalError: true });
            return;
        } else if (this.state.educationLevelId < 1) {
            this.setState({ educationLevelIdState: "error", generalError: true });
            return;
        }
        else if (this.state.classId < 1) {
            this.setState({ classIdState: "error", generalError: true });
            return;
        }
        else if (this.state.sectionId < 1) {
            this.setState({ sectionIdState: "error", generalError: true });
            return;
        }
        else {
            //this.ConfirmMessage("SaveNewRegistration", "Do you want to Register new Student!");
        }
    }
    ClearTextfieldStates = () => {
        this.setState({
            sectionId: -1, sectionIdIndex: 0, sectionIdState: '',
            sessionId: -1, sessionIdIndex: 0, sessionIdState: '',
            campusIdIndex: 0, campusId: 0, campusIdState: '',
            educationLevelId:-1, educationLevelIdIndex: 0, educationLevelIdState:'',
            classId: -1, classIdState: '', classIdIndex: 0,
        })
        this.hideAlert()
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { sessionDD, sessionId, sessionIdState, sessionIdIndex, campusDD, campusIdState, campusIdIndex, classDD, classIdState, classIdIndex, sectionDD,
            sectionIdState, sectionIdIndex, educationLevelDD, educationLevelIdState, educationLevelIdIndex } = this.state;
        const Sessions = (() => {
            if (this.state.showSession === true) {
                return (
                    <GridTextbox  xs={this.props.xsm} sm={this.props.smm} md={this.props.mdm} lg={this.props.lgm}>
                        <Autocomplete
                            disableClearable
                            id="sessionId"
                            size="small"
                            options={sessionDD}
                            getOptionLabel={(option) => option.sessionName}
                            value={sessionDD[sessionIdIndex]}
                            onChange={(event, value) => { this.setDDValue(event, value); this.resetClassSec(); this.fillcampuses(value.sessionId); }}
                            renderInput={(params) => <TextField {...params} success={sessionIdState === "success"}
                                error={sessionIdState === "error"} label="Session" variant="outlined" />}
                        />
                    </GridTextbox>
                );
            }
            else {
                return (
                    <div></div>
                );
            }
        });
        const Campus = (() => {
            if (this.state.showCampus === true) {
                return (
                    <GridTextbox  xs={this.props.xsm} sm={this.props.smm} md={this.props.mdm} lg={this.props.lgm}>
                        <Autocomplete
                            disableClearable
                            id="campusId"
                            size="small"
                            options={campusDD}
                            getOptionLabel={(option) => option.campusName}
                            value={campusDD[campusIdIndex]}
                            onChange={(event, value) => { this.setDDValue(event, value); this.filllevels(value.campusId) }}
                            renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                error={campusIdState === "error"} label="Campus" variant="outlined" />}
                        />
                    </GridTextbox>
                );
            }
            else {
                return (
                    <div></div>
                );
            }
        })
        const Level = (() => {
            if (this.state.showLevel === true) {
                return (
                    <GridTextbox  xs={this.props.xsm} sm={this.props.smm} md={this.props.mdm} lg={this.props.lgm}>
                        <Autocomplete
                            disableClearable
                            id="educationLevelId"
                            size="small"
                            options={educationLevelDD}
                            getOptionLabel={(option) => option.levelName}
                            value={educationLevelDD[educationLevelIdIndex]}
                            onChange={(event, value) => { this.setDDValue(event, value); this.fillClasses(value.educationLevelId) }}
                            renderInput={(params) => <TextField {...params} success={educationLevelIdState === "success"}
                                error={educationLevelIdState === "error"} label="Level Name" variant="outlined" />}
                        />
                    </GridTextbox>
                );
            }
            else {
                return (
                    <div></div>
                );
            }
        })
        const Class = (() => {
            if (this.state.showClass === true) {
                return (
                    <GridTextbox xs={this.props.xsm} sm={this.props.smm} md={this.props.mdm} lg={this.props.lgm}>
                        <Autocomplete
                            disableClearable
                            id="classId"
                            size="small"
                            options={classDD}
                            getOptionLabel={(option) => option.className}
                            value={classDD[classIdIndex]}
                            onChange={(event, value) => { this.setDDValue(event, value); this.fillSections(value.classId)/*; this.validateStudentAge(value.classId)*/ }}
                            renderInput={(params) => <TextField {...params} success={classIdState === "success"}
                                error={classIdState === "error"} label="Class" variant="outlined" />}
                        />
                    </GridTextbox>
                );
            }
            else {
                return (
                    <div></div>
                );
            }
        })
        const Sections = (() => {
            //debugger;
            if (this.state.FormName !== null && this.state.FormName === "New Registration" && this.state.showSection === true) {
                if (this.state.classId === 44 || this.state.classId === 46 || this.state.classId === 47 || this.state.classId === 51 || this.state.classId === 52) {
                    return (
                        <GridTextbox  xs={this.props.xsm} sm={this.props.smm} md={this.props.mdm} lg={this.props.lgm}>
                            <Autocomplete
                                disableClearable
                                id="sectionId"
                                size="small"
                                options={sectionDD}
                                getOptionLabel={(option) => option.sectionName}
                                value={sectionDD[sectionIdIndex]}
                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                renderInput={(params) => <TextField {...params} success={sectionIdState === "success"}
                                    error={sectionIdState === "error"} label="Section" variant="outlined" />}
                            />
                        </GridTextbox>
                    );
                }
                else {
                    return (
                        <div></div>
                    );
                }
            }
            else {
                return (
                    <GridTextbox xs={this.props.xsm} sm={this.props.smm} md={this.props.mdm} lg={this.props.lgm}>
                        <Autocomplete
                            disableClearable
                            id="sectionId"
                            size="small"
                            options={sectionDD}
                            getOptionLabel={(option) => option.sectionName}
                            value={sectionDD[sectionIdIndex]}
                            onChange={(event, value) => { this.setDDValue(event, value) }}
                            renderInput={(params) => <TextField {...params} success={sectionIdState === "success"}
                                error={sectionIdState === "error"} label="Section" variant="outlined" />}
                        />
                    </GridTextbox>
                );
            }
        });
        return (
            <div>
                <div style={{ paddingTop: "-5px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <GridContainer>
                        {this.state.alert}
                        <Sessions/>
                        <Campus/>
                        <Level/>
                        <Class/>
                        <Sections/>
                    </GridContainer>
                </div>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(AllDropDowns);
