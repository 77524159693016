//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';
const jobTypeDD = [
    { jobTypeId: 'PartTime', value: 'PartTime' },
    { jobTypeId: 'FullTime', value: 'FullTime' },
]
var Base_URL = localStorage.BaseURL;
class EductionOrExperience extends React.Component {

    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            eductionList: [],
            experienceList: [],
            educationId: 0,
            experienceId: 0,
            boardName: "",
            boardNameState: "",
            years: "",
            yearsState: "",
            educationLevelId: "",
            educationLevelIdIndex: 0,
            educationLevelIdState: "",
            jobTypeId: "PartTime",
            jobTypeIdIndex: 0,
            jobTypeIdState: "",
            degreeId: "",
            degreeIdIndex: 0,
            degreeIdState: "",
            organizationName: "",
            organizationNameState: "",
            designationName: "",
            designationNameState: "",
            fromDate: new Date(),
            fromDateState: "",
            toDate: new Date(),
            toDateState: "",
            saveEduUpdateBtnText: 'Save',
            saveEduUpdateBtnDisabled: false,
            saveExpUpdateBtnText: 'Save',
            saveExpUpdateBtnDisabled: false,
            clearEduTextBtn: 'none',
            clearExpTextBtn: 'none',
            EducationLevelDD: [{ educationLevelId: -1, levelName: "Select" }],
            DegreeDD: [{ degreeId: -1, degreeName: "Select" }],
            jobTypeDD: [{ jobTypeId: "PartTime", name: "Select" }],
            alert: null, show: false, generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadEducationLevelDD();
        this.LoadDegreeDD();
        this.LoadExperienceData();
        this.LoadEductionData()

    }
    async LoadEducationLevelDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/EducationLevelDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    EducationLevelDD: data.educationLevelDDList
                });
                if (data.educationLevelDDList.length > 0) {
                    this.setState({
                        educationLevelId: data.educationLevelDDList[0].educationLevelId,
                        educationLevelIdState: "success"
                    });
                }
                else {
                    this.setState({
                        educationLevelId: 0,
                        educationLevelIdIndex: -1,
                        educationLevelIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadDegreeDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/DegreeDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    DegreeDD: data.educationDegreeDDList
                });
                if (data.educationDegreeDDList.length > 0) {
                    this.setState({
                        degreeId: data.educationDegreeDDList[0].degreeId,
                        degreeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        degreeId: 0,
                        degreeIdIndex: -1,
                        degreeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadEductionData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadEductionData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    eductionList: data.eductiondataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadExperienceData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadExperienceData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                // Moment(data[0].dateFrom).format('YYYY-MM-DD');
                this.setState({
                    experienceList: data.experiencedataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    setDDValue = (event, value) => {
        debugger;
        let stateName = event.target.id.split("-")[0];
        let dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    SaveEductionData = () => {
        debugger;
        if ((this.state.educationLevelId === 0) || (this.state.educationLevelIdState === "error")) {
            this.setState({ educationLevelIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.degreeId === 0) || (this.state.degreeIdState === "error")) {
            this.setState({ degreeIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.boardName === "") || (this.state.boardNameState === "error")) {
            this.setState({ boardNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.years === "") || (this.state.yearsState === "error")) {
            this.setState({ yearsState: "error", generalError: true });
            return;
        }
        let EducationInfo = {
            Id: this.state.educationId,
            EmployeeId: this.state.employeeId,
            QualificationLevel: this.state.educationLevelId,
            DegreeLevel: this.state.degreeId,
            BoardUniversity: this.state.boardName,
            PassingYear: this.state.years,
        };
        console.log(EducationInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeEducation', EducationInfo)
            .then(json => {
                debugger;
                this.AlertMessage(json.data.status, json.data.message)
            })


    }
    SaveWorkExperienceData = () => {
        debugger;
        if ((this.state.jobTypeId === "") || (this.state.jobTypeIdState === "error")) {
            this.setState({ jobTypeIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.organizationName === "") || (this.state.organizationNameState === "error")) {
            this.setState({ organizationNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.designationName === "") || (this.state.designationNameState === "error")) {
            this.setState({ designationNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.fromDate === "") || (this.state.fromDateState === "error")) {
            this.setState({ fromDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.toDate === "") || (this.state.toDateState === "error")) {
            this.setState({ toDateState: "error", generalError: true });
            return;
        }
        let WorkInfo = {
            Id: this.state.experienceId,
            EmployeeId: this.state.employeeId,
            FullPartTime: this.state.jobTypeId,
            Organization: this.state.organizationName,
            Designation: this.state.designationName,
            DateFrom: Moment(this.state.fromDate).format('YYYY-MM-DD'),
            DateTo: Moment(this.state.toDate).format('YYYY-MM-DD'),
        };
        console.log(WorkInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeExperience', WorkInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }

    AlertMessage(status, message) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            educationId: 0,
            experienceId: 0,
            alert: null,
            educationLevelId: 1,
            educationLevelIdIndex: 0,
            degreeId: 1,
            degreeIdIndex: 0,
            boardName: "",
            years: "",
            jobTypeId: "PartTime",
            jobTypeIdIndex: 0,
            organizationName: "",
            designationName: "",
            fromDate: new Date(),
            toDate: new Date(),
            saveEduUpdateBtnText: 'Save',
            saveEduUpdateBtnDisabled: false,
            saveExpUpdateBtnText: 'Save',
            saveExpUpdateBtnDisabled: false,
            clearEduTextBtn: 'none',
            clearExpTextBtn: 'none',

        });
        this.LoadEductionData();
        this.LoadExperienceData();
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    verifyNumber(value) {
        debugger
        var mobileRex = /^[0-9]+$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.eductionList.find(o => o.id === id);
        this.setState({ educationId: id, educationLevelId: obj.educationLevelId, degreeId: obj.degreeId, boardName: obj.boardUniversity, years: obj.passingYear, saveEduUpdateBtnDisabled: false, saveEduUpdateBtnText: 'Update', clearEduTextBtn: 'block' });
        if (this.state.EducationLevelDD.find(x => x.educationLevelId === obj.educationLevelId) != null) {
            debugger;
            this.setState({
                educationLevelId: obj.educationLevelId,
                educationLevelIdIndex: this.state.EducationLevelDD.findIndex(x => x.educationLevelId === obj.educationLevelId),
                educationLevelIdState: "success"
            });
        }
        else {
            this.setState({
                educationLevelId: -1,
                educationLevelIdIndex: 0,
                educationLevelIdState: "error"
            });
        }
        if (this.state.DegreeDD.find(x => x.degreeId === obj.degreeId) != null) {
            debugger;
            this.setState({
                degreeId: obj.degreeId,
                degreeIdIndex: this.state.DegreeDD.findIndex(x => x.degreeId === obj.degreeId),
                degreeIdState: "success"
            });
        }
        else {
            this.setState({
                degreeId: -1,
                degreeIdIndex: 0,
                degreeIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    LoadExperienceField = (id) => {
        debugger
        let obj = this.state.experienceList.find(o => o.id === id);
        this.setState({ experienceId: id, jobTypeId: obj.jobTypeId, organizationName: obj.organization, designationName: obj.designation, fromDate: obj.dateFrom, toDate: obj.dateTo, saveExpUpdateBtnDisabled: false, saveExpUpdateBtnText: 'Update', clearExpTextBtn: 'block' });
        if (this.state.jobTypeDD.find(x => x.jobTypeId === obj.fullPartTime)) {
            debugger;
            this.setState({
                jobTypeId: obj.fullPartTime,
                jobTypeIdIndex: 0,
                jobTypeIdState: "success"
            });
        }
        else {
            this.setState({
                jobTypeId: obj.fullPartTime,
                jobTypeIdIndex: 1,
                jobTypeIdState: "success"
            });
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "educationLevelId":
                debugger
                if (this.state.eductionList.find(x => x.educationLevelId === value.educationLevelId)) {

                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.EducationLevelDD.findIndex(x => x[stateName] === value[stateName]),
                        educationLevelId: value.educationLevelId,
                        [stateName + "State"]: "success"
                    })
                }
                break;
            case "degreeId":
                debugger
                if (this.state.eductionList.find(x => x.degreeId === value.degreeId)) {

                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.DegreeDD.findIndex(x => x[stateName] === value[stateName]),
                        degreeId: value.degreeId,
                        [stateName + "State"]: "success"
                    })
                }
                break;
            case "boardName":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        boardName: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "error",
                    });
                }
                break;
            case "years":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        years: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else if (event.target.value.length < stateNameEqualTo && this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        years: event.target.value,
                        [stateName + "State"]: "error"
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        years: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "jobTypeId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: jobTypeDD.findIndex(x => x[stateName] === value[stateName]),
                    jobTypeId: value.jobTypeId,
                    [stateName + "State"]: "success"
                })
                break;
            case "organizationName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        [stateName + "State"]: "success",
                        organizationName: event.target.value,
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        organizationName: event.target.value
                    });

                }
                break;
            case "designationName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        designationName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        designationName: event.target.value,
                    })
                }
                break;
            case "fromDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "toDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        toDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { eductionList, experienceList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Education Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.EducationLevelDD}
                                            getOptionLabel={(option) => option.levelName}
                                            id="educationLevelId"
                                            value={this.state.EducationLevelDD[this.state.educationLevelIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "educationLevelId", "educationLevelId", value)}
                                            renderInput={(params) => <TextField {...params} label="Level *" variant="outlined"
                                                success={this.state.educationLevelIdState === "success"}
                                                error={this.state.educationLevelIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.DegreeDD}
                                            getOptionLabel={(option) => option.degreeName}
                                            id="degreeId"
                                            required={true}
                                            value={this.state.DegreeDD[this.state.degreeIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "degreeId", "degreeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Degree Level *" variant="outlined"
                                                success={this.state.degreeIdState === "success"}
                                                error={this.state.degreeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.boardNameState === "success"}
                                            error={this.state.boardNameState === "error"}
                                            value={this.state.boardName}
                                            size="small"
                                            label="Institute/Board/University"
                                            id="boardName"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "boardName", "boardName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.yearsState === "success"}
                                            error={this.state.yearsState === "error"}
                                            value={this.state.years}
                                            size="small"
                                            label="Year"
                                            id="years"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "years", "years", value, 3),
                                                type: "text",
                                                maxLength: 4
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveEductionData() }} disabled={this.state.saveEduUpdateBtnDisabled}><SaveOutlined /> {this.state.saveEduUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearEduTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Eduction Details"}
                                    data={eductionList}
                                    columns={[
                                        {
                                            name: "id",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "boardUniversity", label: "BoardUniversity" },
                                        { name: "passingYear", label: "PassingYear" },
                                        { name: "qualificationLevel", label: "QualificationLevel" },
                                        { name: "degreeLevel", label: "DegreeLevel" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Work Experience</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={jobTypeDD}
                                            getOptionLabel={(option) => option.value}
                                            id="jobTypeId"
                                            value={jobTypeDD[this.state.jobTypeIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "jobTypeId", "jobTypeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Job Type" variant="outlined"
                                                success={this.state.jobTypeIdState === "success"}
                                                error={this.state.jobTypeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.organizationNameState === "success"}
                                            error={this.state.organizationNameState === "error"}
                                            value={this.state.organizationName}
                                            size="small"
                                            label="Organization"
                                            id="organizationName"
                                            variant="outlined"
                                            fullWidth
                                            // required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "organizationName", "organizationName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.designationNameState === "success"}
                                            error={this.state.designationNameState === "error"}
                                            value={this.state.designationName}
                                            size="small"
                                            label="Designation"
                                            id="designationName"
                                            variant="outlined"
                                            fullWidth
                                            // required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "designationName", "designationName", value, 5),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.fromDateState === "success"}
                                            error={this.state.fromDateState === "error"}
                                            size="small"
                                            label="From Date"
                                            id="fromDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.toDateState === "success"}
                                            error={this.state.toDateState === "error"}
                                            size="small"
                                            label="To Date"
                                            id="toDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.toDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "toDate", "toDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveWorkExperienceData() }} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveExpUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearExpTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Experience Details"}
                                    data={experienceList}
                                    columns={[
                                        {
                                            name: "id",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadExperienceField(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "organization", label: "Organization" },
                                        { name: "designation", label: "Designation" },
                                        { name: "dateFrom", label: "DateFrom" },
                                        { name: "dateTo", label: "DateTo" },
                                        { name: "fullPartTime", label: "JobType" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>

            </div>
        );
    }
}

export default withStyles(formStyle)(EductionOrExperience);




