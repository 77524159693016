import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import LMSICON from "assets/img/LMS.png";
import SMISICON from "assets/img/SMIS.png";
import VISION from "assets/img/vision.png";
import MISSION from "assets/img/mission.png";
import HEADER from "assets/img/header.png";
import { Link } from "react-router-dom";
import badgeStyle from "../../assets/jss/material-dashboard-pro-react/components/badgeStyle";

var BaseUrl = '';

class RoutePage extends React.Component {
    constructor(props) {
        //debugger;
        super(props);
        this.state = {
            cardAnimaton: "cardHidden",
        };
    }
    componentDidMount() {
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: "" });
            }.bind(this),
            700
        );

        BaseUrl = localStorage.getItem('BaseURL');
        if (BaseUrl !== 'http://localhost:58487' && BaseUrl !== null && BaseUrl !== '') {
            BaseUrl = BaseUrl;
        }
        else {
            BaseUrl = window.location.origin;
        }
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    render() {
        //debugger;
        const { classes } = this.props;
        return (
            <div className={classes.container} xs={12} sm={12} md={12} lg={12}
                style={{ marginLeft: "0", marginRight: "0", paddingLeft: "0", paddingRight: "0", width: "100%" }} >
                <img src={HEADER} alt="..." style={{ height: "auto", width: "inherit" }} />
                <GridContainer justify="center" style={{ marginLeft: "5px", marginRight: "5px", width: "100%", marginTop: "7%" }}>
                    <GridItem xs={6} sm={6} md={3} lg={3} style={{ padding: "30px" }}>
                        <div xs={12} sm={12} md={12} lg={12}>
                            <img src={VISION} alt="..." style={{ height: "100%", width: "100%" }} />
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3} style={{ padding: "30px" }}>
                        <div xs={12} sm={12} md={12} lg={12}>
                            <a href="http://application.btsc.edu.pk/portal" target="_blank">
                                <img src={LMSICON} alt="..." style={{ height: "100%", width: "100%" }} />
                            </a>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3} style={{ padding: "30px" }}>
                        <div xs={12} sm={12} md={12} lg={12}>
                            {/*<a href={BaseUrl+'/auth/login-page'} target="_blank">
                                <img src={SMISICON} alt="..." style={{ height: "100%", width: "100%" }} />
                            </a>*/}
                            <Link to={'/loginpage'}>
                                <img src={SMISICON} alt="..." style={{ height: "100%", width: "100%" }} />
                            </Link>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3} style={{ padding: "30px" }}>
                        <div xs={12} sm={12} md={12} lg={12}>
                            <img src={MISSION} alt="..." style={{ height: "100%", width: "100%" }} />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

RoutePage.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(loginPageStyle)(RoutePage);
