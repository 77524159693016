
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class FiscalYear extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            Changeable: false,
            Editable: false,
            Active: true,
        };
        //#endregion 
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div style={{ paddingBottom: "7px", paddingRight: "13px", paddingLeft: "13px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                            <CardHeader>
                                <h4 className={classes.cardIconTitle}>Search Fiscal Year</h4>
                                <hr />
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            id="Department"
                                            size="small"
                                            renderInput={(params) => <TextField {...params} label="Fee Head Type" variant="outlined" />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="facebook"> <SearchSharp />Search</Button>
                                    </GridTextbox>
                                </GridContainer>
                                <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                <h4 className={classes.cardIconTitle}>Fiscal Year Name</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            size="small"
                                            label="Name"
                                            id="EmpIdName"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={startDateState === "success"}
                                            error={startDateState === "error"}
                                            size="small"
                                            label="From Date"
                                            id="startDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(startDate).format('YYYY-MM-DD')}
                                            type="date"
                                            onChange={this.setDateValue}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={startDateState === "success"}
                                            error={startDateState === "error"}
                                            size="small"
                                            label="To Date"
                                            id="startDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(startDate).format('YYYY-MM-DD')}
                                            type="date"
                                            onChange={this.setDateValue}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <Button color="facebook"><SaveOutlined />Save</Button>
                                        <Button color="facebook">Update</Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={12} md={12}>
                        <MuiThemeProvider theme={muiWithToolbar()}>
                            <MUIDataTable
                                title={"Fiscal Year Detail"}
                                columns={[
                                    { label: "Fiscal Year Name" }, { label: "Start Date" },
                                    { label: "End Date" }, { label: "Modifier" },
                                    { label: "Modified Date" }, { label: "Edit" },
                                ]}
                                options={tbleWithPrint}
                            />
                        </MuiThemeProvider>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(FiscalYear);