
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Sms,
    AddIcon, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class LMSLimitations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lmslimitations: [],
            name: "title",
            label: "Title",
            title: '',
            days: '',
            lmsId: -1,
            titleState: '',
            daysState: '',
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            SearchCheck: true,


        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };
    ClearTextfieldStates = () => {
        this.setState({
            lmsId: -1, title: '', days: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        if (this.state.title === "") {
            this.setState({ titleState: "error" });
            return;
        }
        else if (this.state.days === '') {
            this.setState({ daysState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkTitle) => {
        this.setState({ [checkTitle]: event });
    }
    LoadData = () => {
        debugger;
        fetch(Base_URL + "/api/LMSLimitations/LoadLMSLimitations").then(response => { return response.json(); })
            .then(data => {
                this.setState({ lmslimitations: data });
            }).catch(error => {
                console.log(error);
            });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }
    SaveUpdateLMSLimitations = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/LMSLimitations/SaveUpdateLMSLimitations?Id=" + this.state.lmsId + "&Title=" + this.state.title + "&Days=" + this.state.days)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(lmsId) {

        let obj = this.state.lmslimitations.find(o => o.id === lmsId);
        this.setState({ title: obj.title, lmsId: obj.id, days: obj.days, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateLMSLimitations()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save LMS detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { lmslimitations, title, days, titleState, daysState } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="info" icon>
                        <CardIcon color="success">
                            <AllInbox />
                        </CardIcon>
                        <h4 style={{ color: "black" }}> Basic LMS Limitation<small> Management</small></h4>
                    </CardHeader>
                    {this.state.alert}
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "3px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">LMS Limitations</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "5px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "0px", paddingLeft: "0px" }}>
                        <CardBody>
                            <GridContainer>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "1px", marginBottom: "10px" }}>
                                                <CardHeader>
                                                    <GridContainer>
                                                        <GridTextbox>
                                                            <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                    <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                                    <h4 className={classes.cardIconTitle}>Add LMS Limitations</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <form>
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={titleState === "success"}
                                                                    error={titleState === "error"}
                                                                    size="small"
                                                                    label="Title"
                                                                    id="title"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={title}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={daysState === "success"}
                                                                    error={daysState === "error"}
                                                                    type={"number"}
                                                                    size="small"
                                                                    label="Days"
                                                                    id="days"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={days}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                    </form>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"LMS Limitations"}
                                                    data={lmslimitations}
                                                    columns={[{
                                                        name: "title",
                                                        label: "Title",
                                                    },
                                                    {
                                                        name: "days",
                                                        label: "Days",
                                                    },

                                                    {
                                                        name: "id",
                                                        label: "Edit",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                            }
                                                        }
                                                    }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>

                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(LMSLimitations);
