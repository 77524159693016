import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// @material-ui/core components
import {
    Home as HomeIcon, NavigateNext as NavigateNextIcon, Replay,
    SchoolOutlined, Details, DateRange, Search, GraphicEq, ChatBubbleOutline, Report,
    FindInPage, Equalizer, NoteOutlined, DescriptionOutlined, StarOutline, EventAvailable,
    CalendarTodayOutlined, AssessmentOutlined, Help, EventAvailableOutlined
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";

// components
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";

class Academic extends React.Component {
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;

        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="warning" icon>
                    <CardIcon color="warning">
                        <SchoolOutlined />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Academic </h4>
                </CardHeader>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Typography color="textPrimary">Academic </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/syllabus"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <Details />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Syllabus</p>
                                 
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Details />
                                                Syllabus
                                    
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/timetable"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <DateRange />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Time</p>
                                            <h3 className={classes.cardTitle}>
                                                Table
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <DateRange />
                                    Time Table
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/subtitletimetable"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <DateRange />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Subtitle</p>
                                            <h3 className={classes.cardTitle}>
                                               Time Table
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <DateRange />
                                     Subtitle Time Table
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/searchteacher"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <Search />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Search</p>
                                            <h3 className={classes.cardTitle}>
                                                Teacher
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Search />
                                    Search Teacher
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/datesheet"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <Replay />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Date</p>
                                            <h3 className={classes.cardTitle}>
                                                Sheet
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Replay />
                                    Date Sheet
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/newresult"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <GraphicEq />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>New</p>
                                            <h3 className={classes.cardTitle}>
                                                Result
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <GraphicEq />
                                  New Result
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/studentremarks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <ChatBubbleOutline />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Student</p>
                                            <h3 className={classes.cardTitle}>
                                                Remarks
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <ChatBubbleOutline />
                                  Student Remarks
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/allresultreport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Report />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>All Result</p>
                                            <h3 className={classes.cardTitle}>
                                                Report
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Report />
                                 All Result Report
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/searchresult"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <FindInPage />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Search</p>
                                            <h3 className={classes.cardTitle}>
                                                Result
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <FindInPage />
                                  Search Result
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/preschoolresult"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <Equalizer />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Pre School</p>
                                            <h3 className={classes.cardTitle}>
                                                Result
                                    </h3>

                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Equalizer />
                                 Pre School Result
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/preschoolresult"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <NoteOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Pre School</p>
                                            <h3 className={classes.cardTitle}>
                                                Remarks
                                    </h3>

                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <NoteOutlined />
                                 Pre School Remarks
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/preschoolreoport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <DescriptionOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Pre School</p>
                                            <h3 className={classes.cardTitle}>
                                                Report
                                    </h3>

                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <DescriptionOutlined />
                                  Pre School Report
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                            <hr />
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><SchoolOutlined style={{ marginBottom: "-5px" }} /> Academic Administration</Typography>
                            </Breadcrumbs>
                            <hr />
                            <CardBody>
                                <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/primarydata"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <StarOutline />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Primary </p>
                                            <h3 className={classes.cardTitle}>
                                                Data
                                    </h3>


                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <StarOutline />
                                  Primary Data  
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/evententry"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <EventAvailable />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Event </p>
                                            <h3 className={classes.cardTitle}>
                                                Entry
                                    </h3>


                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <EventAvailable />
                                  Event Entry 
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><AssessmentOutlined style={{ marginBottom: "-5px" }} /> Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/Teachertimetablesummary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><CalendarTodayOutlined style={{ color: 'Green', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Teacher TimeTable Summary</center> </h3>
                                        <p className={classes.cardCategory}><center>Report</center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/Teachermissing"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><Help style={{ color: 'Teal', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Teacher Missing</center> </h3>
                                        <p className={classes.cardCategory}><center>Substitutions</center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/Teachertimetableclasssection"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><AssessmentOutlined style={{ color: 'Blue', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Teacher Time Table Class Section </center> </h3>
                                        <p className={classes.cardCategory}><center>Wise Summary</center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/Timetable"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><EventAvailableOutlined style={{ color: 'Navy', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center> Time Table  </center> </h3>
                                        <p className={classes.cardCategory}><center> Summary</center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>

                </div>
            </Card >
        );
    }
}

Academic.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Academic);
