import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class EOBI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eOBIId: 0,
            basicSalary: 0,
            basicSalaryState: "",
            perAge: 0,
            perAgeState: "",
            fromDate: new Date(),
            fromDateState: "",
            toDate: new Date(),
            toDateState: "",
            saveActive: true,
            searchActive: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            //#region Datatable Columns and Data
            eOBIData: [],
            //#region Datatable Columns
            columns: [{
                name: "basicSalary",
                label: "Basic Salary",
                options: {
                    filter: true,
                }
            }, {
                name: "perAge",
                label: "Per Age",
                options: {
                    filter: true,
                }
            }, {
                name: "fromDate",
                label: "From Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY'));
                    }
                }
            }, {
                name: "toDate",
                label: "To Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY'));
                    }
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "eobiId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadEOBI = this.loadEOBI.bind(this);
        this.ClearTextfieldStates = this.ClearTextfieldStates.bind(this);

    }
    loadEOBI = () => {
        debugger;
        const requestOptions = {
            method: 'GET'
        };
        fetch(Base_URL + "/api/primaryDataHR/EOBI?Active=" + this.state.searchActive, requestOptions).then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({
                    eOBIData: data.eobiList,
                });
            }).catch(error => {
                console.log(error);
            });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    change(event, stateName, type) {
        switch (type) {
            case "basicSalary":
                if (event.target.value >= 0) {
                    this.setState({
                        [stateName + "State"]: "success"
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "perAge":
                if (event.target.value > 0) {
                    this.setState({
                        [stateName + "State"]: "success"
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "fromDate":
                this.setState({
                    stateName: event.target.value
                });
                break;
            case "toDate":
                this.setState({
                    stateName: event.target.value
                });
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        if (this.state.basicSalaryState === "error" || this.state.basicSalaryState === "" || this.state.basicSalary <= 0) {
            this.setState({
                basicSalaryState: "error"
            })
            return;
        } else if (this.state.perAgeState === "error" || this.state.perAgeState === "" || this.state.perAge <= 0) {
            this.setState({
                perAgeState: "error"
            })
            return;
        } else if (new Date(this.state.fromDate) > new Date(this.state.toDate)) {
            this.setState({
                fromDateState: "error",
                toDateState: "error"
            })
            return;
        } else {
            this.setState({
                fromDateState: "success",
                toDateState: "success"
            })
            if (this.state.eOBIId === 0) {
                this.confirmMessageSave();
            } else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                EOBIId: this.state.eOBIId,
                BasicSalary: +this.state.basicSalary,
                PerAge: +this.state.perAge,
                FromDate: this.state.fromDate,
                ToDate: this.state.toDate,
                Active: this.state.saveActive
            })
        };
        fetch(Base_URL + "/api/primaryDataHR/EOBI", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new EOBI will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    EOBI will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });

        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (eobiId) => {
        let obj = this.state.eOBIData.find(o => o.eobiId === eobiId);
        this.setState({ eOBIId: obj.eobiId, basicSalary: obj.basicSalary, basicSalaryState: "success", perAge: obj.perAge, perAgeState: "success", fromDate: obj.fromDate, toDate: obj.toDate, saveActive: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, eOBIId: 0, basicSalaryState: "", basicSalary: 0, perAge: 0, perAgeState: "", fromDate: new Date(), toDate: new Date(), saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.loadEOBI();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12} lg={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load EOBI Detail</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadEOBI} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add EOBI</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.basicSalaryState === "success"}
                                                    error={this.state.basicSalaryState === "error"}
                                                    value={this.state.basicSalary}
                                                    size="small"
                                                    label="Basic Salary"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    inputProps={{ onChange: event => this.change(event, "basicSalary", "basicSalary") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.perAgeState === "success"}
                                                    error={this.state.perAgeState === "error"}
                                                    value={this.state.perAge}
                                                    size="small"
                                                    label="Per Age"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="number"
                                                    inputProps={{ onChange: event => this.change(event, "perAge", "perAge") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    success={this.state.fromDateState === "success"}
                                                    error={this.state.fromDateState === "error"}
                                                    size="small"
                                                    label="From Date"
                                                    id="fromDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    success={this.state.toDateState === "success"}
                                                    error={this.state.toDateState === "error"}
                                                    size="small"
                                                    label="To Date"
                                                    id="toDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={Moment(this.state.toDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "toDate", "toDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "saveActive")}
                                                        checked={this.state.saveActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"EOBI"}
                                        data={this.state.eOBIData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(EOBI);