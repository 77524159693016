
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
    SearchSharp, AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
    GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
    muiWithToolbar, tbleWithPrint, Moment, ErrorOutline, DateRange, AlternateEmail, Autocomplete, FormControl,
    InputLabel, Select, FormControlLabel, Switchb, Commute, GridItem, regularFormsStyle, NavigateNext, Home, extendedFormsStyle
} from '../../components/Common/ImportAll'

const dateVal = {
    currentDate: new Date().toISOString().substring(0, 10)
};
var Base_URL = localStorage.BaseURL;
class StudentTransport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zoneDD: [{ zoneId: -1, zoneName: "Select" }],
            blockDD: [{ blockId: -1, blockName: "Select" }],
            busDD: [{ busId: -1, busName: "Select" }],
            reasonDD: [],
            routeDD: [{ routeId: -1, routeName: "Select" }],
            townDD: [{ townId: -1, townName: "Select" }],
            studentTransportList: [],
            zoneSelected: 0,
            checked: [24, 22],
            selectedValue: null,
            selectedEnabled: "b",
            studentRegNo: "",
            studentRegNoState: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
        this.searchBtnClick = this.searchBtnClick.bind(this);
    }

    //#region State change and Validation Funtions
    searchBtnClick() {
        if (this.state.studentRegNoState === "") {
            this.setState({ studentRegNoState: "error" });
        }
        else {
            this.loadTransportTable(this.state.studentRegNo);
        }
        if (this.state.supplierNameState === "") {
            this.setState({ supplierNameState: "error" });
        }
        if (this.state.supplierCNICState === "") {
            this.setState({ supplierCNICState: "error" });
        }
    }
    // function that returns true if value is btsno, false otherwise
    verifyBTSNo(value) {
        var btsRex = /^(BTS-|bts-)?\d{2}-\d{4}$/;
        if (btsRex.test(value)) {
            this.setState({ studentRegNo: value });
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo, maxValue) {
        switch (type) {
            case "btsno":
                if (this.verifyBTSNo(event.target.value)) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleChangeEnabled(event) {
        this.setState({ selectedEnabled: event.target.value });
    }
    handleToggle(value) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked
        });
    }

    //#endregion

    //#region Dropdowns
    componentDidMount() {

        fetch(Base_URL + "/Transport/LoadZone").then(response => { return response.json(); })
            .then(data => {
                this.setState({ zoneDD: data });
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/Transport/LoadDenyReson").then(response => { return response.json(); })
            .then(data => {
                this.setState({ reasonDD: data });
            }).catch(error => {
                console.log(error);
            });
        this.loadTownsDD(-1);
        this.loadBlockDD(-1);
        this.loadBusDD(-1);
        this.loadRoutesDD(null, null);
    }
    LoadotherDD = (event, value) => {
        if (value !== null) {
            this.loadTownsDD(value.routeId);
            this.loadBlockDD(value.routeId);
            this.loadBusDD(value.routeId);
        }
        else {
            this.loadTownsDD(-1);
            this.loadBlockDD(-1);
            this.loadBusDD(-1);
            this.loadRoutesDD(null, null);
        }
    }
    loadRoutesDD = (event, value) => {
        let zID = -1;
        if (value !== null) {
            zID = value.zoneId;
        }
        fetch(Base_URL + '/Transport/loadRoutesDD?zoneId=' + zID).then(response => { return response.json(); })
            .then(data => {
                this.setState({ routeDD: data });
            }).catch(error => {
                console.log(error);
            });
    }
    loadTownsDD = (routeId) => {
        fetch(Base_URL + '/Transport/loadTownsDD?routeId=' + routeId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ townDD: data });
            }).catch(error => {
                console.log(error);
            });
    }
    loadBlockDD = (routeId) => {
        fetch(Base_URL + '/Transport/loadBlockDD?routeId=' + routeId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ blockDD: data });
            }).catch(error => {
                console.log(error);
            });
    }
    loadBusDD = (routeId) => {
        fetch('/Transport/loadBusDD?routeId=' + routeId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ busDD: data });
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion

    //#region Load Table Data
    loadTransportTable = () => {
        fetch('/Transport/loadTransportTable?registrationNo=' + this.state.studentRegNo).then(response => { return response.json(); })
            .then(data => {
                this.setState({ studentTransportList: data.stdTransList });
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion

    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });

        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Commute />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Transport Management</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb"
                            style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/Transport" > Transport</Link>
                            <Typography color="textPrimary">Transport Management</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "0px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={4}>
                                    <Card style={{ marginTop: "5px", marginBottom: "3px" }}>
                                        <CardHeader color="success" icon>
                                            <h4 className={classes.cardIconTitle}>Search Student Transport</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={8}>
                                                    <TextField
                                                        success={this.state.studentRegNoState === "success"}
                                                        error={this.state.studentRegNoState === "error"}
                                                        size="small"
                                                        label="Registration No."
                                                        id="studentRegNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "studentRegNo", "btsno"), type: "btsno" }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1}>
                                                    <Button color="facebook" onClick={this.searchBtnClick} > Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                    <Card tyle={{ marginTop: "0px", marginBottom: "0px" }}>
                                        <CardHeader color="success" icon>
                                            <h4 className={classes.cardIconTitle}>Transport Denied Reason</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <form>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={8}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.reasonDD}
                                                            getOptionLabel={(option) => option.reason}
                                                            renderInput={(params) => <TextField {...params} label="Denied Reason" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Button color="facebook" onClick={this.searchBtnClick} > Denied </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={8}>
                                    <Card style={{ marginTop: "5px", marginBottom: "10px" }}>
                                        <CardHeader color="success" icon>
                                            <h4 className={classes.cardIconTitle}>Assign Student Transport</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <form>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <TextField
                                                            success={this.state.studentRegNoState === "success"}
                                                            error={this.state.studentRegNoState === "error"}
                                                            size="small"
                                                            label="Application No"
                                                            id="concessiontxt"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "studentRegNo", "number"), type: "text" }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.zoneDD}
                                                            getOptionLabel={(option) => option.zoneName}
                                                            getOptionValue={(option) => option.zoneId}
                                                            value={this.state.zoneDD[0]}
                                                            onChange={this.loadRoutesDD}
                                                            renderInput={(params) => <TextField {...params} label="Zone" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.routeDD}
                                                            getOptionLabel={(option) => option.routeName}
                                                            defaultvalue={this.state.routeDD[0]}
                                                            onChange={this.LoadotherDD}
                                                            renderInput={(params) => <TextField {...params} label="Route" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.townDD}
                                                            getOptionLabel={(option) => option.townName}
                                                            defaultvalue={this.state.townDD[0]}
                                                            onChange={this.handleChange}
                                                            renderInput={(params) => <TextField {...params} label="Town" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.blockDD}
                                                            getOptionLabel={(option) => option.blockName}
                                                            getOptionValue={(option) => option.blockId}
                                                            defaultvalue={this.state.blockDD[0]}
                                                            renderInput={(params) => <TextField {...params} label="Block" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.busDD}
                                                            getOptionLabel={(option) => option.busName}
                                                            defaultvalue={this.state.busDD[0]}
                                                            renderInput={(params) => <TextField {...params} label="Pick Bus" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Autocomplete
                                                            id="deniedReasonDD"
                                                            size="small"
                                                            options={this.state.busDD}
                                                            getOptionLabel={(option) => option.busName}
                                                            defaultvalue={this.state.busDD[0]}
                                                            renderInput={(params) => <TextField {...params} label="Drop Bus" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <TextField
                                                            success={this.state.studentRegNoState === "success"}
                                                            error={this.state.studentRegNoState === "error"}
                                                            size="small"
                                                            label="Discount"
                                                            id="concessiontxt"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "studentRegNo", "number"), type: "text" }}
                                                        />
                                                    </GridTextbox>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <TextField
                                                            size="small"
                                                            label="From Date"
                                                            id="fromDate"
                                                            variant="outlined"
                                                            defaultValue={dateVal.currentDate}
                                                            type="date"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "studentRegNo", "number") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <TextField
                                                            size="small"
                                                            label="To Date"
                                                            id="toDate"
                                                            variant="outlined"
                                                            type="date"
                                                            defaultValue={dateVal.currentDate}
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "studentRegNo", "number") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                                                            <InputLabel id="modelabel">Mode</InputLabel>
                                                            <Select native labelId="modelabel" id="transportMode" label="Mode" defaultValue="2">
                                                                <option value={2}>Both</option>
                                                                <option value={0}>Pick</option>
                                                                <option value={1}>Drop</option>
                                                            </Select>
                                                        </FormControl>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                                                            <InputLabel id="packagelabel">Package</InputLabel>
                                                            <Select native labelId="packagelabel" id="TransportPackage" label="Package" defaultValue="12M">
                                                                <option value={"12M"}>12 Months</option>
                                                                <option value={"10M"}>10 Months</option>
                                                            </Select>
                                                        </FormControl>
                                                    </GridTextbox>
                                                </GridContainer>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <TextField
                                                            success={this.state.studentRegNoState === "success"}
                                                            error={this.state.studentRegNoState === "error"}
                                                            size="small"
                                                            label="Student Address"
                                                            id="concessiontxt"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "studentRegNo", "number"), type: "text" }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={2} md={2} style={{ marginLeft: "1%" }}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")}
                                                                onColor="#3b5998"
                                                                className={classes.toggleStyle}
                                                                borderRadius={4}
                                                                handleDiameter={22}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Active</span>
                                                        </label>
                                                    </GridTextbox>
                                                    {/*<div className={classes.block}>*/}
                                                    {/*    <FormControlLabel*/}
                                                    {/*        control={*/}
                                                    {/*            <Switchb*/}
                                                    {/*                checked={this.state.checkedA}*/}
                                                    {/*                onChange={this.handleChange("checkedA")}*/}
                                                    {/*                value="checkedA"*/}
                                                    {/*                classes={{*/}
                                                    {/*                    switchBase: classes.switchBase,*/}
                                                    {/*                    checked: classes.switchChecked,*/}
                                                    {/*                    icon: classes.switchIcon,*/}
                                                    {/*                    iconChecked: classes.switchIconChecked,*/}
                                                    {/*                    bar: classes.switchBar*/}
                                                    {/*                }}*/}
                                                    {/*            />*/}
                                                    {/*        }*/}
                                                    {/*        classes={{*/}
                                                    {/*            label: classes.label*/}
                                                    {/*        }}*/}
                                                    {/*        label="Status"*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}
                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                        <Button color="facebook" onClick={this.searchBtnClick}><SaveOutlined />Save</Button>
                                                        <Button color="facebook" onClick={this.searchBtnClick}> <SaveOutlined />Update</Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Student Transport Detail"}
                                            columns={[
                                                { label: "App no" }, { label: "Zone" }, { label: "Route" },
                                                { label: "Town" }, { label: "Block" },
                                                { label: "Pick Bus" }, { label: "Drop Bus" },
                                                { label: "Discount" }, { label: "From Date" },
                                                { label: "To Date" }, { label: "Mode" },
                                                { label: "Package" }, { label: "Enable" },
                                                { label: "Update" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );

    }
}

export default withStyles(formStyle)(StudentTransport);
