
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon,
    AddIcon, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class UserGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usergrouparray: [],
            name: "title",
            label: "Title",
            userGroupName: '',
            lmsId: -1,
            userGroupNameState: '',
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            searchCheck: true,
            dbStatus: false,

        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };
    ClearTextfieldStates = () => {
        this.setState({
            lmsId: -1, dbStatus: false, userGroupName: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        if (this.state.userGroupName === "") {
            this.setState({ userGroupNameState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkTitle) => {
        this.setState({ [checkTitle]: event });
    }
    LoadData = () => {
        debugger;
        fetch(Base_URL + "/api/UserGroups/UserGroupList?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ usergrouparray: data });
            }).catch(error => {
                console.log(error);
            });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    AddUserGroupName = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        if (this.state.saveUpdateBtnText === 'Update') {
            axios.post(Base_URL + "/api/UserGroups/UpdateUserGroupName?Id=" + this.state.lmsId + "&UserGroupName=" + this.state.userGroupName + "&DbStatus=" + this.state.dbStatus)
                .then(json => {
                    this.SuccessMessage(json.data.status, json.data.message)
                });
        }
        else {
            axios.post(Base_URL + "/api/UserGroups/AddUserGroupName?UserGroupName=" + this.state.userGroupName + "&DbStatus=" + this.state.dbStatus)
                .then(json => {
                    this.SuccessMessage(json.data.status, json.data.message)
                });
        }

    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(lmsId) {
        debugger;
        let obj = this.state.usergrouparray.find(o => o.id === lmsId);
        this.setState({ userGroupName: obj.userGroupName, lmsId: obj.id, dbStatus: obj.dbStatus, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.AddUserGroupName()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save User Group!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { usergrouparray, userGroupName, userGroupNameState, searchCheck, dbStatus } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="info" icon>
                        <CardIcon color="success">
                            <AllInbox />
                        </CardIcon>
                        <h4 style={{ color: "black" }}> User Group Management</h4>
                        {/*<hr style={{ marginTop: "10px", marginBottom: "3px" }} />*/}
                    </CardHeader>
                    {this.state.alert}
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "0px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">User Groups</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "5px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")}
                                                            checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox>
                                                    <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                            <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                            <h4 className={classes.cardIconTitle}>Add User Group</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <form>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            success={userGroupNameState === "success"}
                                                            error={userGroupNameState === "error"}
                                                            size="small"
                                                            label="User Group"
                                                            id="userGroupName"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={userGroupName}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "dbStatus")}
                                                                checked={dbStatus} onColor="#3b5998" borderRadius={4}
                                                                handleDiameter={22} className={classes.toggleStyle}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Active</span>
                                                        </label>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1}>
                                                        <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1}>
                                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"User Group"}
                                            data={usergrouparray}
                                            columns={[
                                                {
                                                    name: "userGroupName",
                                                    label: "User Group",
                                                },
                                                {
                                                    name: "dbStatus",
                                                    label: "Active",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                        }
                                                    }
                                                },
                                                {
                                                    name: "id",
                                                    label: "Edit",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                        }
                                                    }
                                                }
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(UserGroups);
