
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
    SearchSharp, AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
    GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
    muiWithToolbar, tbleWithPrint, Moment, MenuBook, GridItem, PictureUpload, Photo, StdIcon, GetAppIcon, DeleteIcon
} from '../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class NewBook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    };

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <MenuBook />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>New Book</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "0px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/library" > Library</Link>
                            <Typography color="textPrimary">New Book</Typography>
                        </Breadcrumbs>
                        <hr />
                        <GridContainer>
                            <div className={classes.mainDiv}>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                                <CardHeader color="info" icon>
                                                    <CardIcon color="success">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Add New Book</h4>
                                                    <hr />
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer style={{ marginleft: "1px" }}>
                                                        <GridItem xs={12} sm={12} md={8}>
                                                            <GridContainer xs={12} sm={12} md={12}>
                                                                <GridContainer style={{ marginLeft: 20 }}>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Campus"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Level"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                                    </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Book Type"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Book Title"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Rack/Grade"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Description"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Suplier"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Publisher"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="ISBN"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Pages"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Barcode"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Purchase Invoice"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                                </GridContainer>
                                                            </GridContainer>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={4}>
                                                            <PictureUpload />
                                                            <div style={{ textAlign: "center" }}>
                                                                <Button color="success" ><GetAppIcon />Download</Button>
                                                                <Button color="danger"><DeleteIcon />Remove</Button>
                                                            </div>
                                                            <hr />
                                                        </GridItem>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridForm xs={12} sm={6} md={6}>
                                            <Card style={{ marginTop: "13px" }}>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Quantity"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Entry Date"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Unit Price"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Currency"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox justify="flex-end" xs={12} sm={4} lg={4} >
                                                            <Button color="facebook">Add</Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        columns={[
                                                            { label: "Quantity" },
                                                            { label: "Entry Date" },
                                                            { label: "Unit Price" },
                                                            { label: "Currency" },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={3} md={3}>
                                            <Card style={{ marginTop: "13px" }}>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Author"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox justify="flex-end" xs={12} sm={4} lg={4} >
                                                            <Button color="facebook">Add</Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        columns={[
                                                            { label: "Authors" },
                                                            { label: "Remove" },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={3} md={3}>
                                            <Card style={{ marginTop: "13px" }}>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Category"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox justify="flex-end" xs={12} sm={4} lg={4} >
                                                            <Button color="facebook">Add</Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        columns={[
                                                            { label: "Category" },
                                                            { label: "Remove" },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </Card>
                                           
                                        </GridForm>
                                        <GridTextbox xs={12} sm={12} md={4} style={{ marginLeft: "50%" }}>
                                            <Button color="facebook"><SaveOutlined />Save </Button>
                                        </GridTextbox>
                                    </GridContainer>
                                </CardBody>
                            </div>
                        </GridContainer>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(NewBook);

