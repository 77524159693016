
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class PostSalary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            SalaryDataList: [],
            employeeSalaryList: [],
            employeeIdsList: [],
            monthDate: Moment(new Date()).format('YYYY-MM-DD'),
            issueDate: Moment(new Date()).format('YYYY-MM-DD'),
            departmentName: "",
            designationName: "",
            totalamount: 0,
            formTypeId:0,
            netSalary: "",
            clearNetSalary: 'none',
            createBtnDisabled: true,
            deleteBtnDisabled: true,
            generalError: false,
            alert: null,
            show: true,
        };
        //#endregion 
    }
    postSalary = () => {
        this.AlertMessage('Success', 'Testing Message( Data Save Successfully......!!!)')
    }
    AlertMessage(status, message) {
        debugger
        if (status === 'Success') {
            this.setState({
                alert: (
                    swal(
                        {
                            title: status ,
                            text: message,
                    icon: "success",
                    button: "OK",
                        }
                    ))
                    //<SweetAlert
                    //    success
                    //    style={{ display: "block", marginTop: "-200px" }}
                    //    title={status}
                    //    onConfirm={() => this.ClearTextfieldStates()}
                    //    //onConfirm={() => this.hideAlert()}
                    //    onCancel={() => this.hideAlert()}
                    //    confirmBtnCssClass={
                    //        this.props.classes.button + " " + this.props.classes.success
                    //    }
                    //    closeOnClickOutside={false}
                    //>{message}</SweetAlert>
            });
        }
        else {
            this.setState({
                alert: (
                    swal(
                        {
                            title: status,
                            text: message,
                            icon: "warning",
                            button: "OK",
                        }
                    )
                    //<SweetAlert
                    //    warning
                    //    style={{ display: "block", marginTop: "-200px" }}
                    //    title={status}
                    //    onConfirm={() => this.hideAlert()}
                    //    onCancel={() => this.hideAlert()}
                    //    confirmBtnCssClass={
                    //        this.props.classes.button + " " + this.props.classes.success
                    //    }
                    //    closeOnClickOutside={false}
                    //>{message}</SweetAlert>
                ),
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            alert: null,
        });

    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    searchBtn = () => {
        debugger;
        fetch(Base_URL + "/api/PostSalary/LoadPostSalary?SalaryMonth=" + this.state.monthDate + "")
            .then(response => {
                return response.json();
            })
            .then(data => {
                debugger;
                //if (data.postSalaryList == null || data.postSalaryList=='NULL') {
                //    this.state.SalaryDataList = [];
                //}
                this.setState({
                    SalaryDataList: data.postSalaryList,
                    createBtnDisabled: false,
                    deleteBtnDisabled: true,
                });
                if (this.state.formTypeId === 0) {
                    this.setState({
                        createBtnDisabled: false,
                        deleteBtnDisabled: false,
                        clearNetSalary: 'block'
                    });
                    var totalSalary = 0
                    for (let i = 0; i < data.postSalaryList.length; ++i) {
                        if (data.postSalaryList[i].grossSalary != 0 && data.postSalaryList[i].grossSalary != null) {
                            totalSalary = totalSalary + data.postSalaryList[i].grossSalary;
                        }
                    }
                    var NetSalary = this.numberWithCommas(totalSalary)
                    this.state.netSalary = NetSalary;
                    this.setState({
                        netSalary: NetSalary
                    });
                }
                else {
                    if (data.postSalaryList.length > 0 && data.postSalaryList.length != null) {
                        this.state.SalaryDataList = data.createSalaryList;
                        for (let i = 0; i < data.postSalaryList.length; ++i) {
                            if (data.postSalaryList[i].employeeId != 0 && data.postSalaryList[i].employeeId != null) {
                                this.state.employeeIdsList.push({
                                    EmployeeId: data.postSalaryList[i].employeeId,
                                    PlacementId: data.postSalaryList[i].placementId,
                                    Salary: data.postSalaryList[i].grossSalary,
                                });
                                //this.state.employeeIdsList = this.state.employeeIdsList.concat(
                                //    data.createSalaryList[i].employeeId,
                                //    data.createSalaryList[i].placementId,
                                //    data.createSalaryList[i].grossSalary
                                //);
                            }
                        }
                        this.setState({
                            netSalary: "",
                            clearNetSalary: 'none'
                        });
                        console.log(this.state.employeeIdsList);

                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    LoadSalaryData = (employeeId) => {
        debugger;
        var totalAmount = 0;
        this.state.totalamount = 0;
        fetch(Base_URL + "/api/CreateSalary/LoadSalary?EmployeeId=" + employeeId + "")
            .then(response => {
                return response.json();
            })
            .then(data => {
                debugger;
                this.state.name = data.loadSalaryList[0].name;
                this.state.departmentName = data.loadSalaryList[0].departmentName;
                this.state.designationName = data.loadSalaryList[0].designationName;
                this.setState({
                    employeeSalaryList: data.loadSalaryList
                });
                if (data.loadSalaryList.length > 0 && data.loadSalaryList.length != null) {
                    this.state.employeeSalaryList = data.loadSalaryList;
                    for (let i = 0; i < data.loadSalaryList.length; ++i) {
                        if (data.loadSalaryList[i].alloTypeName === "Allowance") {
                            totalAmount = totalAmount + data.loadSalaryList[i].amount;
                        }
                        else {
                            totalAmount = totalAmount - data.loadSalaryList[i].amount;
                        }
                    }
                    var NetAmount = this.numberWithCommas(totalAmount)
                    this.state.totalamount = NetAmount;
                    this.setState({
                        totalamount: NetAmount
                    });
                    //this.state.totalamount = Netamount; 
                }
            })
            .catch(error => {
                console.log(error);
            });

    }
    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "monthDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        monthDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "issueDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        issueDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { SalaryDataList, employeeSalaryList } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Edit />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Post Salary</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Post Salary</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Post Salary</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Salary Month"
                                                        id="monthDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.monthDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "monthDate", "monthDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Issue Date"
                                                        id="issueDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.issueDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "issueDate", "issueDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1} style={{ marginleft: "5px" }}>
                                                    <Button color="facebook" onClick={() => { this.searchBtn() }}> Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={8}>
                                    <Card>
                                        <MuiThemeProvider theme={muiWithToolbar()}>
                                            <MUIDataTable
                                                data={SalaryDataList}
                                                columns={[                                                    
                                                    { name: "regNo", label: "Emp ID" },
                                                    { name: "name", label: "Name" },
                                                    { name: "campusName", label: "Campus" },
                                                    { name: "departmentName", label: "Department" },
                                                    { name: "designationName", label: "Designation" },
                                                    { name: "days", label: "Days" },
                                                    { name: "grossSalary", label: "Gross Salary" },
                                                    {
                                                        name: "employeeId", label: "View",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (value) != null ? <Button color="facebook" onClick={() => { this.LoadSalaryData(value) }}>View</Button> : "";
                                                            }
                                                        }
                                                    },
                                                    /*  { name: "employeeId", label: "View" },*/

                                                ]}
                                                options={tbleWithPrint}
                                            />
                                        </MuiThemeProvider>
                                    </Card >
                                    <GridTextbox xs={12} sm={12} lg={12}>
                                        <Button color="facebook" onClick={() => { this.postSalary() }} disabled={this.state.createBtnDisabled} > Post Salaries </Button>
                                        <Button color="facebook" onClick={() => { this.searchBtn() }} disabled={this.state.deleteBtnDisabled}> Print Voucher</Button>
                                    </GridTextbox>
                                    <CardHeader style={{ marginRight: "59px" }}>
                                        <Typography color="textPrimary" style={{ display: '' + this.state.clearNetSalary + '' }}>Net Salary: {this.state.netSalary}</Typography>
                                    </CardHeader>
                                </GridForm>
                                <GridForm xs={12} sm={4} md={4}>
                                    <Card style={{ marginTop: "13px" }}>
                                        <CardHeader>
                                            <Typography color="textPrimary">Name:{this.state.name}</Typography>
                                            <Typography color="textPrimary">Department:{this.state.departmentName}</Typography>
                                            <Typography color="textPrimary">Designation:{this.state.designationName}</Typography>
                                            {/*<Typography color="textPrimary"><small>Designation:</small></Typography>*/}
                                        </CardHeader>
                                        <MuiThemeProvider theme={muiWithToolbar()}>
                                            <MUIDataTable
                                                data={employeeSalaryList}
                                                columns={[
                                                    { name: "allowanceName", label: "Allowance / Deduction" },
                                                    { name: "amount", label: "Amount" },
                                                ]}
                                                options={tbleWithPrint}
                                            />
                                        </MuiThemeProvider>
                                        <CardHeader style={{ marginTop: "2%", marginBottom: "2%" }}>
                                            <Typography color="textPrimary">Net Amount:{this.state.totalamount}</Typography>
                                        </CardHeader>
                                    </Card>

                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion 
}
export default withStyles(extendedFormsStyle)(PostSalary);
