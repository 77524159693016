import {
    React, GridContainer, Card, CardHeader, CardBody, Box, Typography, PropTypes, CardIcon, Link, Tab, Breadcrumbs,
    NavigateNextIcon, Tabs, HomeIcon, PermDataSettingOutlined, Note
} from '../../components/Common/ImportAll'
//#region Import Employee Views
import CreateEditSingleChallan from './ChallanManagementTabs/CreateEditSingleChallan';
import UnpaidChallan from './ChallanManagementTabs/UnpaidChallan';
import RemoveStudentChallan from './ChallanManagementTabs/RemoveStudentChallan';
import ChangePaymentBank from './ChallanManagementTabs/ChangePaymentBank';
import ChangePaymentDate from './ChallanManagementTabs/ChangePaymentDate';
import ChangeDueDate from './ChallanManagementTabs/ChangeDueDate';
////#endregion
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ScrollableTabsButtonAuto() {
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <Note />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Fee Challan Management</h4>
                    <hr style={{ marginTop: "15px", marginBottom: "4px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/accounts" > Accounts</Link>
                        <Typography color="textPrimary">Challan Management</Typography>
                    </Breadcrumbs>
                    <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                    <CardBody>
                        <GridContainer>
                            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable"
                                scrollButtons="on" style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary"
                                textColor="primary" wrapped >
                                <Tab label="Create Edit Single Challan" {...a11yProps(0)} />
                                <Tab label="Unpaid Challan" {...a11yProps(1)} />
                                <Tab label="Remove Student Challan" {...a11yProps(2)} />
                                <Tab label="Change Payment Bank" {...a11yProps(3)} />
                                <Tab label="Change Payment Date" {...a11yProps(4)} />
                                <Tab label="Change Due Date" {...a11yProps(5)} />
                            </Tabs>
                            {selectedTab === 0 && <CreateEditSingleChallan />}
                            {selectedTab === 1 && <UnpaidChallan />}
                            {selectedTab === 2 && <RemoveStudentChallan />}
                            {selectedTab === 3 && <ChangePaymentBank />}
                            {selectedTab === 4 && <ChangePaymentDate />}
                            {selectedTab === 5 && <ChangeDueDate />}
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}
