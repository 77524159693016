
// Import Transport tabs Views
import TransportMail from "./PrimaryDataTabs/TransportMail.jsx";
import TransportReasons from "./PrimaryDataTabs/TransportReasons.jsx";
import ZoneFeeStructure from "./PrimaryDataTabs/ZoneFeeStructure.jsx";

//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
    SearchSharp, PropTypes, Box, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
    GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
    muiWithToolbar, tbleWithPrint, Tabs, ErrorOutline, DateRange, AlternateEmail, Autocomplete, FormControl,
    makeStyles, Select, FormControlLabel, Switchb, StdIcon, GridItem, regularFormsStyle, Tab, Home, extendedFormsStyle,
    axios, SearchIcon
} from '../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index}
            id={'scrollable-auto-tabpanel-${index}'}
            aria-labelledby={'scrollable-auto-tab-${index}'} {...other} >

            {value === index && (<Box p={3}> <Typography>{children}</Typography> </Box>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: 'scrollable-auto-tab-${index}',
        'aria-controls': 'scrollable-auto-tabpanel-${index}',
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonAuto() {
    const classes = useStyles();
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="info" icon>
                    <CardIcon color="success">
                        <StdIcon />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Transport Primary Data Management</h4>
                    <hr style={{ marginTop: "15px", marginBottom: "4px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/transport" >Transport</Link>
                        <Typography color="textPrimary">Primary Data</Typography>
                    </Breadcrumbs>
                    <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                    <CardBody>
                        <GridContainer>
                            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="on"
                                style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary" textColor="primary" wrapped >
                                <Tab label="Transport Mail" {...a11yProps(0)} />
                                <Tab label="Transport Reasons" {...a11yProps(1)} />
                                <Tab label="Zone Fee Structure" {...a11yProps(2)} />
                            </Tabs>
                            {selectedTab === 0 && <TransportMail />}
                            {selectedTab === 1 && <TransportReasons />}
                            {selectedTab === 2 && <ZoneFeeStructure />}
                           
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}