
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Sms,
    AddIcon, EditOutlined, PermDataSettingOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class ManageForms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            manageformlist: [],
            name: "title",
            label: "Title",
            formId: -1,
            formIdState: '',
            formName: '',
            formNameState: '',
            sectionName: '',
            sectionNameState: '',
            typeName: '',
            typeNameState: '',
            lmsId: -1,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            SearchCheck: true,

            sectionId: 0,
            sectionIdIndex: 0,
            sectionIdState: '',
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],

            typeId: 0,
            typeIdIndex: 0,
            typeIdState: '',
            typeDD: [{ typeId: -1, typeName: 'Select' }],
        };

        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };
    async componentDidMount() {
        this.loadDepartment();
        this.loadForms();
    }
    // DropDownRegion
    async loadForms() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/LoadFormTypes").then(response => { return response.json(); })
            .then(data => {
                this.setState({ typeDD: data, typeId: data[0]['typeId'] });
            }).catch(error => {
                console.log(error);
            });
    }

    async loadDepartment() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/LoadDepartments").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectionDD: data, sectionId: data[0]['sectionId'] });
            }).catch(error => {
                console.log(error);
            });
    }

    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" })
    }

    ClearTextfieldStates = () => {
        this.setState({
            lmsId: -1, formName: '', formNameState: '',
            sectionId: 0, sectionIdIndex: -1, sectionIdState: '',
            typeId: 0, typeIdIndex: -1, typeIdState: '',
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        if (this.state.formName === "") {
            this.setState({ formNameState: "error" });
            return;
        }
        else if (this.state.sectionId === '') {
            this.setState({ sectionIdState: "error" });
            return;
        }
        else if (this.state.typeId === '') {
            this.setState({ typeIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    LoadData = () => {
        fetch(Base_URL + "/api/ManageForm/LoadFormsManagentList").then(response => { return response.json(); })
            .then(data => {
                this.setState({ manageformlist: data });
            }).catch(error => {
                console.log(error);
            });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    SaveUpdateLMSLimitations = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/LMSLimitations/SaveUpdateLMSLimitations?Id=" + this.state.lmsId + "&Title=" + this.state.formName + "&Days=" + this.state.days)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    //LoadTextbox(lmsId) {
    //    debugger;
    //    let obj = this.state.manageformlist.find(o => o.formId === lmsId);
    //    this.setState({ formName: obj.formName, lmsId: obj.formId, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    //    this.setState({ sectionIdIndex: this.state.sectionDD.findIndex(x => x.sectionId === obj.sectionId) });
    //    this.setState({ typeIdIndex: this.state.typeDD.findIndex(x => x.typeId === obj.typeId) });
    //}
    LoadTextbox(formId) {
        debugger;
        let obj = this.state.manageformlist.find(o => o.formId === formId);
        this.setState({ formName: obj.formName, formId: obj.formId, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ sectionIdIndex: this.state.sectionDD.findIndex(x => x.sectionId === obj.sectionId) });
        this.setState({ typeIdIndex: this.state.typeDD.findIndex(x => x.typeId === obj.typeId) });
    }

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateLMSLimitations()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { manageformlist, formId, formIdState, formName, formNameState, sectionName, sectionNameState, typeName, typeNameState, sectionId, sectionIdIndex, sectionIdState, sectionDD, typeId, typeIdIndex, typeIdState, typeDD } = this.state;
        return (
            //<div>
            //    <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
            //        <CardHeader color="success" icon>
            //            <CardIcon color="success">
            //                <PermDataSettingOutlined />
            //            </CardIcon>
            //            <h4 className={classes.cardIconTitle}>Manage Forms</h4>
            //        </CardHeader>
            //        {this.state.alert}
            //        <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
            //            <hr />
            //            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
            //                <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
            //                <Link to="/app/itmanagement" > IT Management</Link>
            //                <Typography color="textPrimary">Manage Forms</Typography>
            //            </Breadcrumbs>
            //            <hr />
            /*<CardBody>*/
            <GridContainer>
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add LMS Limitations</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={formNameState === "success"}
                                                        error={formNameState === "error"}
                                                        size="small"
                                                        label="Form Name"
                                                        id="formName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={formName}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="sectionId"
                                                        size="small"
                                                        options={sectionDD}
                                                        getOptionLabel={(option) => option.sectionName}
                                                        value={sectionDD[sectionIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params} success={sectionIdState === "success"}
                                                            error={sectionIdState === "error"} label="Department" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="typeId"
                                                        size="small"
                                                        options={typeDD}
                                                        getOptionLabel={(option) => option.typeName}
                                                        value={typeDD[typeIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params} success={typeIdState === "success"}
                                                            error={typeIdState === "error"} label="Type" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Forms Management"}
                                        data={manageformlist}
                                        columns={[{
                                            name: "formId",
                                            label: "Form ID",
                                        },
                                        {
                                            name: "formName",
                                            label: "Form Name",
                                        },
                                        {
                                            name: "sectionName",
                                            label: "Department",
                                        },
                                        {
                                            name: "typeName",
                                            label: "Type",
                                        },

                                        {
                                            name: "id",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        }
                                        ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
            /*</CardBody>*/
            //        </div>
            //    </Card>
            //</div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(ManageForms);
