import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from '@material-ui/core';
import {
    NavigateNext as NavigateNextIcon, Home as HomeIcon, MenuBook, Help, Bookmarks, FindInPage, PermContactCalendar, LocalLibrary,
    Settings, FormatAlignJustify, ErrorOutline, DateRange, AlternateEmail, Textsms, LibraryBooks, NoteOutlined,
    PermIdentity, Publish, Category, BookRounded, EmailOutlined, Sms, NotificationImportant
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { events } from "variables/general.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

class Library extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: events
        };
    }
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Bookmarks />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Library</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Typography color="textPrimary">Library</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>   
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/newbook"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <MenuBook />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>New </p>
                                                <h3 className={classes.cardTitle}> Book </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <MenuBook />
                                                   Define Book
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/searchbook"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <FindInPage />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Search </p>
                                                <h3 className={classes.cardTitle}>Book</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <FindInPage />
                                                    Search Book
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/managebook"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <PermContactCalendar />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Manage</p>
                                                <h3 className={classes.cardTitle}>Book </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <PermContactCalendar />
                                                Manage Book
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/issuereturn"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <LocalLibrary />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Issue/ </p>
                                                <h3 className={classes.cardTitle}> Return </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <LocalLibrary />
                                                   Issue/Return Books
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/issuedbooks"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Settings />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Issued</p>
                                                <h3 className={classes.cardTitle}>Books </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Settings />
                                                 Issued Books
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageracks"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <FormatAlignJustify />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Manage  </p>
                                                <h3 className={classes.cardTitle}>Racks </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <FormatAlignJustify />
                                          Manage Racks
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/managestatus"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <ErrorOutline />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Manage  </p>
                                                <h3 className={classes.cardTitle}>Status </h3>

                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <ErrorOutline />
                                          Manage Status
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageProducttype"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Help />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Manage Product </p>
                                                <h3 className={classes.cardTitle}>Type </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Help />
                                           Manage Product Type 
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageduedays"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <DateRange />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Manage  </p>
                                                <h3 className={classes.cardTitle}>Due Days </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <DateRange />
                                           Manage Due Days
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/overdueemail"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <AlternateEmail />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Overdue</p>
                                                <h3 className={classes.cardTitle}>Email </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <AlternateEmail />
                                           Overdue Email
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                              <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/overduesms"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Textsms />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Overdue SMS</p>
                                               
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Textsms />
                                           Overdue SMS
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                                    </GridItem>
                                    </GridContainer>
                         </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><BookRounded style={{ marginBottom: "-5px" }} />  Book Authors & Publishers</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <PermIdentity />
                                                </CardIcon>
                                                <p className={classes.cardCategory}> Manage</p>
                                                <h3 className={classes.cardTitle}>Authors</h3>

                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <PermIdentity />
                                           Manage Authors
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/managepublishers"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <Publish />
                                                </CardIcon>
                                                <p className={classes.cardCategory}> Manage</p>
                                                <h3 className={classes.cardTitle}>Publishers</h3>

                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Publish />
                                           Manage Publishers
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/managecategories"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Category />
                                                </CardIcon>
                                                <p className={classes.cardCategory}> Manage</p>
                                                <h3 className={classes.cardTitle}>Categories</h3>

                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Category />
                                           Manage Categories
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><BookRounded style={{ marginBottom: "-5px" }} />Reports</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><MenuBook style={{ color: 'Crimson	', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Issued Book Detail</center> </h3>
                                            <p className={classes.cardCategory}> <center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><Bookmarks style={{ color: 'Red', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Books Catalog</center> </h3>
                                            <p className={classes.cardCategory}> <center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><LibraryBooks style={{ color: 'Magenta', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Library Books Quantity</center> </h3>
                                            <p className={classes.cardCategory}> <center>Details</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><NoteOutlined style={{ color: 'Brown', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Top 10 </center> </h3>
                                            <p className={classes.cardCategory}> <center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><EmailOutlined style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>OverDue Email Log </center> </h3>
                                            <p className={classes.cardCategory}> <center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><Sms style={{ color: 'Orange', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>OverDue SMS Log </center> </h3>
                                            <p className={classes.cardCategory}> <center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/manageauthors"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center><NotificationImportant style={{ color: 'Olive', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Issue Book Fine </center> </h3>
                                            <p className={classes.cardCategory}> <center>Detail</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
}
Library.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(Library);
