import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import IdleTimer from 'react-idle-timer';
import { Switch, Route, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import withStyles from "@material-ui/core/styles/withStyles";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import { dashRoutes as routes, pRoutes} from "routes";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";
import history from '../context/History';

var ps;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            miniActive: true,
            image: image,
            color: "white",
            bgColor: "facebook",
            hasImage: true,
            fixedClasses: "dropdown",
            timeout: 1000 * 540 * 1
        };
        this.idleTimer = null
        this.resizeFunction = this.resizeFunction.bind(this);
        this.onIdle = this.onIdle.bind(this)
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.mainPanel, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = "hidden";
        }
        window.addEventListener("resize", this.resizeFunction);
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        window.removeEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    handleImageClick = image => {
        this.setState({ image: image });
    };
    handleColorClick = color => {
        this.setState({ color: color });
    };
    handleBgColorClick = bgColor => {
        this.setState({ bgColor: bgColor });
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({ fixedClasses: "dropdown show" });
        } else {
            this.setState({ fixedClasses: "dropdown" });
        }
    };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    getRoute() {
        //debugger;
        const isAuthentic = localStorage.getItem('isAuthenticated');
        if (this.props.location.pathname !== null && this.props.location.pathname !== '') {
            return this.props.location.pathname !== "/full-screen-maps";
        }
        else {
            return window.location.href
        }
    }
    getActiveRoute = routes => {
        if (window.innerWidth >= 960) {
            var ContextDB = '';
            if (localStorage.getItem('Context') === 'Lahore') {
                ContextDB = 'LAHORE';
            }
            if (localStorage.getItem('Context') === 'Karachi') {
                ContextDB = 'KARACHI';
            }
            return "BAHRIA TOWN SCHOOL MANAGEMENT INFORMATION SYSTEM " + ContextDB;
        }
        else {
            if (localStorage.getItem('Context') === 'Lahore') {
                return "BTSMIS-LHR";
            }
            if (localStorage.getItem('Context') === 'Karachi') {
                return "BTSMIS-KHI";
            }

        }
    };

    getRoutes = routes => {
        let allRoutes = [];
        allRoutes = routes.map((i) => { return { path: i.path, layout: i.layout, component: i.component } });
        allRoutes = allRoutes.concat(pRoutes);
        const isAuthentic = localStorage.getItem('isAuthenticated');
        return allRoutes.map((prop, key) => {
            if (isAuthentic) {
                if (prop.collapse) {
                    return this.getRoutes(prop.views);
                }
                else {
                    return (
                        <Route path={prop.layout + prop.path} component={prop.component} key={key} />
                    );
                }
            }
            else {
                return <Redirect to='/loginpage' />;
            }
        });
    };
    sidebarMinimize() {
        this.setState({ miniActive: !this.state.miniActive });
    }
    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
            this.setState({ miniActive: true });
        }
        else {
            this.setState({ mobileOpen: false });
            this.setState({ miniActive: false });
        }
    }
    onIdle() {
        this.idleTimer.reset();
        localStorage.setItem('isAuthenticated', false);
        localStorage.removeItem('EmployeeName');
        localStorage.removeItem('ProfilePhoto');
        this.props.history.push('/loginpage')

    }
    render() {
        const { classes, ...rest } = this.props;
        const mainPanel =
            classes.mainPanel +
            " " +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                    navigator.platform.indexOf("Win") > -1
            });
        return (

            <div className={classes.wrapper}>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    timeout={this.state.timeout} />
                <Sidebar
                    routes={routes}
                    logoText={"BTSMIS"}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    bgColor={this.state.bgColor}
                    miniActive={this.state.miniActive}
                    {...rest}
                />
                <div className={mainPanel} ref="mainPanel">
                    <AdminNavbar
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                        brandText={this.getActiveRoute(routes)}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                    {this.getRoute() ? (
                        <div className={classes.content}>
                            <div className={classes.container}>
                                <Switch>{this.getRoutes(routes)}</Switch>
                            </div>
                        </div>
                    ) : (
                            <div className={classes.map}>
                                <Switch>{this.getRoutes(routes)}</Switch>
                            </div>
                        )}
                    {this.getRoute() ? <Footer fluid /> : null}
                    <FixedPlugin
                        handleImageClick={this.handleImageClick}
                        handleColorClick={this.handleColorClick}
                        handleBgColorClick={this.handleBgColorClick}
                        handleHasImage={this.handleHasImage}
                        color={this.state["color"]}
                        bgColor={this.state["bgColor"]}
                        bgImage={this.state["image"]}
                        handleFixedClick={this.handleFixedClick}
                        fixedClasses={this.state.fixedClasses}
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={this.state.miniActive}
                    />
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(appStyle)(Dashboard);
