//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}

class ImportSalaryDaysHeads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            ImportSalaryDayHeadList: [],
            monthDate: Moment(new Date()).format('YYYY-MM-DD'),
            fromDate: new Date().setFullYear(dateVal.currentYear, dateVal.currentMonth - 1, dateVal.currentDate =26),
            toDate: new Date().setFullYear(dateVal.currentYear, dateVal.currentMonth, dateVal.currentDate=25),
            empIdName: "",
            fromDateDisabled: true,
            toDateDisabled: true,
            ok:3450,
        };
         //#endregion 
    }
   
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    searchBtn = () => {
        debugger;
        fetch(Base_URL + "/api/ImportSalaryDayHead/LoadImportSalaryDayHead?SalaryMonth=" + this.state.monthDate + "&Name=" + this.state.empIdName +"")
            .then(response => {
                return response.json();
            })
            .then(data => {
                debugger;
                this.setState({
                    ImportSalaryDayHeadList: data.salaryDayHeadsList
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    datesCalculate(dateValue) {
        debugger;
        const str = dateValue;
        const date = new Date(str);
        console.log(date.toString());
        console.log(date.getDate()); // 11
        console.log(date.getMonth()); // 5
        console.log(date.getFullYear()); // 2020

        var year = date.getFullYear();
        var month = date.getMonth();
        var selectdate = date.getDate();
        this.setState({
            fromDate: date.setFullYear(year, month - 1, selectdate = 26),
            toDate: date.setFullYear(year, month, selectdate = 25),
        });
        //var from = date.setFullYear(date.getDate(), date.getMonth() - 1, date.getDate() = 26);
       // console.log(from);
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "monthDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        monthDate: event.target.value,
                        stateName: event.target.value
                    });
                    this.state.monthDate = event.target.value;
                    this.datesCalculate(this.state.monthDate);
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "empIdName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        [stateName + "State"]: "success",
                        empIdName: event.target.value,
                    });
                } else {
                    this.setState({
                        empIdName: event.target.value,
                    });

                }
                break;
            case "fromDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "toDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        toDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
     //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { ImportSalaryDayHeadList } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <PersonAddOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Import Salary Days & Heads</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Import Salary</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Import Salary Days & Heads</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Salary Month"
                                                        id="monthDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.monthDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "monthDate", "monthDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        value={this.state.empIdName}
                                                        size="small"
                                                        label="Emp. Id"
                                                        id="empIdName"
                                                        variant="outlined"
                                                        fullWidth
                                                        // required={true}
                                                        inputProps={{
                                                            onChange: (event, value) =>
                                                                this.change(event, "empIdName", "empIdName", value, 3),
                                                            type: "text",
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="From"
                                                        id="fromDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                                        disabled={this.state.fromDateDisabled}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="To"
                                                        id="toDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.toDate).format('YYYY-MM-DD')}
                                                        disabled={this.state.toDateDisabled}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "toDate", "toDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <Button color="facebook" onClick={() => { this.searchBtn() }}> Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Employees Salary Days & Heads"}
                                            data={ImportSalaryDayHeadList}
                                            columns={[
                                                { name: "regNo",label: "Emp. ID" },
                                                { name: "name",label: "Name" },
                                                { name: "departmentName",label: "Department" },
                                                { name: "designationName", label: "Designation" },
                                                { name: "eobi", label: "EOBI" },
                                                { name: "days",label: "Days" },
                                                { name: "iTax", label: "ITax" },
                                                { name: "mobile", label: "Mobile" },
                                                //{
                                                //    name: "pArrear", label: "P_Arrear",
                                                //    options: {
                                                //        customBodyRender: (value) => {
                                                //            return (value) === 0 ? this.state.ok : value;
                                                //        }
                                                //    }
                                                //},
                                                { name: "pArrear",label: "P_Arrear" },
                                                { name: "advance", label: "Advance" },
                                                { name: "sld", label: "SLD" },
                                                { name: "fuel",label: "Fuel" },
                                                { name: "monthlyADV", label: "MonthlyADV" },
                                                { name: "prvArear",label: "PrvArear" },
                                                { name: "mobileDeduction", label: "MobileDeduction" },
                                                { name: "idp", label: "IDP" },
                                                { name: "subCoordinationAllowance", label: "SubCoordinationAllowance" },
                                                { name: "lateArrivalDeductions", label: "LateArrivalDeductions" },
                                                { name: "healthInsurance",label: "HealthInsurance" },
                                                { name: "mobileBillWarid",label: "MobileBillWarid" },
                                                { name: "miscPayment", label: "MiscPayment" },
                                                { name: "btlMess",label: "BTLMess" },

                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} lg={12} style={{ marginLeft: "40%" }}>
                                        <Button color="facebook">Import</Button>
                                        <Button color="facebook">Save</Button>
                                        <Button color="facebook">Export</Button>
                                    </GridTextbox>
                                </GridContainer>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
     //#endregion 
}
export default withStyles(extendedFormsStyle)(ImportSalaryDaysHeads);