//Import from Globel Component";
import {
    React, axios, Moment, ReactToggle, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;
class MobileAllowance extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            mobileID: 0,
            MobileAllowanceList: [],
            amount: "",
            amountIdState: "",
            fromDate: new Date(),
            fromDateState: "",
            status: true,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            generalError: false, alert: null,
        }
    }
    async componentDidMount() {
        debugger
        this.LoadMobileAllowanceData();
    }
    async LoadMobileAllowanceData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadMobileAllowanceData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    MobileAllowanceList: data.mobileAllowancedataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    SaveMobileAllowanceData = () => {
        debugger;
        if ((this.state.amount <= 0) || (this.state.amountIdState === "error")) {
            this.setState({ amountIdState: "error", generalError: true });
            return;
        }
        else if (this.state.fromDate === "" || this.state.fromDateState === "error") {
            this.setState({ fromDateState: "error", generalError: true });
            return;
        }
        let MobileAllowanceInfo = {
            ID: this.state.mobileID,
            EmployeeId: this.state.employeeId,
            Amount: parseInt(this.state.amount),
            StartDate: Moment(this.state.fromDate).format('YYYY-MM-DD'),
            Status: this.state.status,
        };
        console.log(MobileAllowanceInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeMobileAllowance', MobileAllowanceInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ),
                saveUpdateBtnDisabled: false,
                saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            alert: null,
            mobileID: 0,
            amount: "",
            amountIdState: "",
            fromDate: new Date(),
            saveUpdateBtnText: 'save',
            clearTextBtn: 'none',

        });
        this.LoadMobileAllowanceData();

    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.MobileAllowanceList.find(o => o.id === id);
        if (obj != null) {
            this.setState({
                mobileID: obj.id, amount: obj.amount, fromDate: obj.startDate, status: obj.status, fromDateState: "success", amountIdState: "success", saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block'
            });
        }
        else {
            this.setState({
                amount: "",
                amountIdState: "",
            });
            // this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    verifyNumber(value) {
        debugger
        var mobileRex = /^[0-9]+$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "amountId":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        amount: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        amount: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "fromDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "checkbox":
                this.setState({ [stateName]: event });
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { MobileAllowanceList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Mobile Allowance</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.amountIdState === "success"}
                                            error={this.state.amountIdState === "error"}
                                            value={this.state.amount}
                                            size="small"
                                            label="Amount"
                                            id="amountId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "amountId", "amountId", value, 10),
                                                type: "text",
                                                maxLength: 1000000
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.fromDateState === "success"}
                                            error={this.state.fromDateState === "error"}
                                            size="small"
                                            label="Effect From Date"
                                            id="fromDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "status", "checkbox")}
                                                checked={this.state.status}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span>Active</span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <Button color="facebook" onClick={() => { this.SaveMobileAllowanceData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                    <GridItem xs={12}>

                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Allowance/Deduction"}
                                    data={MobileAllowanceList}
                                    columns={[
                                        { name: "amount", label: "Amount" },
                                        { name: "startDate", label: "Effect From Date" },
                                        {
                                            name: "status",
                                            label: "Active",
                                            options: {
                                                filter: true,
                                                customBodyRender: (value) => {
                                                    return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                }
                                            }
                                        },
                                        {
                                            name: "id",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        }
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(MobileAllowance);