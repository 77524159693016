
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon,
    AddIcon, EditOutlined, Edit
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class SMSsending extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            active: true,
        };
        //#endregion 
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <GridContainer>
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <CardBody>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>SMS Sending Detail</h4>
                                        </CardHeader>
                                        <hr />
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "1%" }}>
                                                <Button color="facebook"><SearchIcon />Refresh</Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Project Directory Detail"}
                                        columns={[
                                            { label: "Employee/Student ID" }, { label: "Mobile" },
                                            { label: "Message" }, { label: "Mask Name" },
                                            { label: "Status" }, { label: "Error Message" },
                                            { label: "Entry Date" }
                                        ]}
                                        options={tbleWithPrint} />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(SMSsending);