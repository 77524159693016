//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, AddIcon, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, Extension
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class AllowanceDeduction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            name: "",
            nameState:"",
            alloTypeId: "",
            alloTypeIdIndex: 0,
            alloTypeIdState: "",
            amountTypeId: "",
            amountTypeIdIndex: 0,
            amountTypeIdState: "",
            accountId: "",
            accountIdIndex: 0,
            accountIdState: "",
            saveAllowUpdateBtnText: 'Save',
            saveAllowUpdateBtnDisabled: false,
            clearAllowTextBtn: 'none',
            AlloTypeDD: [{ alloTypeId: -1, alloTypeName: "Select" }],
            AccountDD: [{ accountId: -1, title: "Select" }],
            AmountTypeDD: [{ amountTypeId: -1, amountTypeName: "Select" }],
        }
        //#endregion 
    };
    async componentDidMount() {
        debugger
        this.LoadAlloTypeDD();
        this.LoadAccountDD();
        this.LoadAmountTypeDD();
    }
    async LoadAlloTypeDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/HR/AlloTypeDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AlloTypeDD: data.alloTypeList
                });
                if (data.alloTypeList.length > 0) {
                    this.setState({
                        alloTypeId: data.alloTypeList[0].alloTypeId,
                        alloTypeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        alloTypeId: 0,
                        alloTypeIdIndex: -1,
                        alloTypeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadAccountDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/HR/AccountDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AccountDD: data.accountList
                });
                if (data.accountList.length > 0) {
                    this.setState({
                        accountId: data.accountList[0].accountId,
                        accountIdState: "success"
                    });
                }
                else {
                    this.setState({
                        accountId: 0,
                        accountIdIndex: -1,
                        accountIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadAmountTypeDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/HR/AmountTypeDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AmountTypeDD: data.amountTypList
                });
                if (data.amountTypList.length > 0) {
                    this.setState({
                        amountTypeId: data.amountTypList[0].amountTypeId,
                        amountTypeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        amountTypeId: 0,
                        amountTypeIdIndex: -1,
                        amountTypeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "alloTypeId":
                debugger
                // if (this.state.eductionList.find(x => x.educationLevelId === value.educationLevelId)) {

                // }
                //  else {
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.AlloTypeDD.findIndex(x => x[stateName] === value[stateName]),
                    alloTypeId: value.alloTypeId,
                    [stateName + "State"]: "success"
                })
                //  }
                break;
            case "accountId":
                debugger
                // if (this.state.eductionList.find(x => x.educationLevelId === value.educationLevelId)) {

                // }
                //  else {
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.AccountDD.findIndex(x => x[stateName] === value[stateName]),
                    accountId: value.accountId,
                    [stateName + "State"]: "success"
                })
                //  }
                break;
            case "amountTypeId":
                debugger
                // if (this.state.eductionList.find(x => x.educationLevelId === value.educationLevelId)) {

                // }
                //  else {
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.AmountTypeDD.findIndex(x => x[stateName] === value[stateName]),
                    amountTypeId: value.amountTypeId,
                    [stateName + "State"]: "success"
                })
                //  }
                break;
            case "name":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        name: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "error",
                    });
                }
                break;
        }
    }
  //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Extension />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Allowance & Deduction Details</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Allowance/Deduction</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingTop: "0px",paddingBottom: "15px", paddingRight: "5px", paddingLeft: "5px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Add Allowance & Deduction</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.AlloTypeDD}
                                                        getOptionLabel={(option) => option.alloTypeName}
                                                        id="alloTypeId"
                                                        value={this.state.AlloTypeDD[this.state.alloTypeIdIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => this.change(event, "alloTypeId", "alloTypeId", value)}
                                                        renderInput={(params) => <TextField {...params} label="Type *" variant="outlined"
                                                            success={this.state.alloTypeIdState === "success"}
                                                            error={this.state.alloTypeIdState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <TextField
                                                        success={this.state.nameState === "success"}
                                                        error={this.state.nameState === "error"}
                                                        value={this.state.name}
                                                            size="small"
                                                            label="Allowance/ Deduction Name"
                                                            id="name"
                                                            variant="outlined"
                                                            fullWidth
                                                            required={true}
                                                            inputProps={{
                                                                onChange: (event, value) =>
                                                                    this.change(event, "name", "name", value, 3),
                                                                type: "text",
                                                                maxLength: 100
                                                            }}
                                                        />
                                                    </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.AccountDD}
                                                        getOptionLabel={(option) => option.title}
                                                        id="accountId"
                                                        value={this.state.AccountDD[this.state.accountIdIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => this.change(event, "accountId", "accountId", value)}
                                                        renderInput={(params) => <TextField {...params} label="Account Title *" variant="outlined"
                                                            success={this.state.accountIdState === "success"}
                                                            error={this.state.accountIdState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.AmountTypeDD}
                                                        getOptionLabel={(option) => option.amountTypeName}
                                                        id="amountTypeId"
                                                        value={this.state.AmountTypeDD[this.state.amountTypeIdIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => this.change(event, "amountTypeId", "amountTypeId", value)}
                                                        renderInput={(params) => <TextField {...params} label="Credit/Debit *" variant="outlined"
                                                            success={this.state.amountTypeIdState === "success"}
                                                            error={this.state.amountTypeIdState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Button color="facebook" onClick={() => { this.SaveEductionData() }} disabled={this.state.saveAllowUpdateBtnDisabled}><SaveOutlined /> {this.state.saveAllowUpdateBtnText} </Button>
                                                </GridTextbox>
                                                <GridTextbox>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearAllowTextBtn + '' }}> <AddIcon /> Add New </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            columns={[
                                                { label: "Allowance/Deduction Name" }, { label: "Account Title" },
                                                { label: "Credit/Debit" }, { label: "Type" },
                                                { label: "Edit" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion 
}
export default withStyles(extendedFormsStyle)(AllowanceDeduction);
