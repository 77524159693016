
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll, DraftsOutlined
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class Systemgeneratedemails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            checkedA: true,
            checkedB: false,
            simpleSelect: "",
            firstname: "",
            firstnameState: "",
            lastname: "",
            lastnameState: "",
            email: "",
            emailState: ""
        };
        //#endregion
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <DraftsOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>System Generated Emails</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">System Generated Emails</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "5px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Search Emails</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Designation"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Module Area" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="From Address" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Designation"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Designation" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook"><SearchSharp />Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Email Details"}
                                            columns={[
                                                { label: "Campus" }, { label: "Module Area" }, { label: "User Mail" },
                                                { label: "Controller/Method" }, { label: "Subject" },
                                                { label: "Email Text" }, { label: "From" },
                                                { label: "To" }, { label: "CC" },
                                                { label: "Modifier" }, { label: "Modifier Date" }, { label: "Update" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(extendedFormsStyle)(Systemgeneratedemails);