

//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class SalaryPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            SalaryPaymentDataList: [],
            creditToList:[],
            date: Moment(new Date()).format('YYYY-MM-DD'),
            paymentDate: Moment(new Date()).format('YYYY-MM-DD'),
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusId: -1,
            campusIdIndex: 0,
            campusIdState: "",
            hrLevelDD: [{ hrLevelId: -1, hrLevelName: 'All' }],
            hrLevelIdIndex: 0,
            hrLevelId: -1,
            hrLevelIdState: "",
            departmentDD: [{ departmentId: -1, departmentName: 'All' }],
            departmentIdIndex: 0,
            departmentId: -1,
            departmentIdState: "",
            designationDD: [{ designationId: -1, designationName: 'All' }],
            designationIdIndex: 0,
            designationId: -1,
            designationIdState: "",
            BankAccDD: [{ bankAccId: -1, title: "Select" }],
            bankAccId: -1,
            bankAccIdIndex: 0,
            bankAccIdState: "",
            AccSubHeadDD: [{ accSubHeadId: -1, accSubHeadName: "Select" }],
            accSubHeadId: "",
            accSubHeadIdIndex: 0,
            accSubHeadIdState: "",
            creditToDD: [{ bankAccId: -1, title: "Select" }],
            creditTo: -1,
            creditToIndex: 0,
            creditToState:"",
            particulars: "",
            cheque: "",
            paymentForm: 'none',

        };
            //#endregion 
    }
    async componentDidMount() {
        this.LoadCampuses();
        this.LoadBankAccDD();
        this.LoadAccSubHeadDD();
        this.LoadChequeNo();
    }
    async LoadCampuses() {
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ campusId: -1, campusName: 'All' });
                allList = allList.concat(data.campusDDList);
                this.setState({
                    campusDD: allList,
                    campusId: allList[0]['campusId']
                });
                this.state.campusId = allList[0]['campusId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSubLevel() {
        fetch(Base_URL + "/api/Dropdowns/HRLevel")
            .then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ hrLevelId: -1, hrLevelName: 'All' });
                allList = allList.concat(data.hrLevelDDList);
                this.setState({
                    hrLevelDD: allList,
                    hrLevelId: allList[0]['hrLevelId'],
                    hrLevelIdIndex: 0
                });
                this.setState.hrLevelId = allList[0]['hrLevelId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadDepartment() {
        fetch(Base_URL + "/api/Dropdowns/DepartmentDD?hrLevelId=" + this.state.hrLevelId + "")
            .then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ departmentId: -1, departmentName: 'All' });
                allList = allList.concat(data.departmentDDList);
                this.setState({
                    departmentDD: allList,
                    departmentId: allList[0]['departmentId']
                });
                this.setState.departmentId = allList[0]['departmentId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadDesignation() {
        fetch(Base_URL + "/api/Dropdowns/DesignationDD?DepartmentId=" + this.state.departmentId + "")
            .then(response => { return response.json(); })
            .then(data => {
                this.setState({
                    designationDD: data.designationDDList,
                    designationId: data.designationDDList[0]['designationId']
                });
                this.setState.designationId = data.designationDDList[0]['designationId']
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadBankAccDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/BankAccDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ bankAccId: -1, title: "All" });
                allList = allList.concat(data.bankAccDDList);
                this.setState({
                    BankAccDD: allList,
                    bankAccId: allList[0]['bankAccId'],
                });
                this.state.bankAccId = allList[0]['bankAccId'];
                let allList2 = [];
                allList2 = allList2.concat(allList);
                allList2.splice(0, 1);
                this.state.creditToDD = allList2;
                if (allList2.length > 0) {
                    this.setState({
                        creditTo: this.state.creditToDD[0]['bankAccId'],
                        creditToIndex: this.state.creditToDD.findIndex(x => x['bankAccId'] === allList2[0].bankAccId),
                        creditToState: "success"
                    });
                }
                else {
                    this.setState({
                        bankAccId: 0,
                        bankAccIdIndex: -1,
                        bankAccIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadAccSubHeadDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/AccSubHeadDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AccSubHeadDD: data.accSubHeadDDList,
                    accSubHeadId: data.accSubHeadDDList[0].accSubHeadId,
                });
                if (data.accSubHeadDDList.length > 0) {
                    this.setState({
                        accSubHeadId: data.accSubHeadDDList[0].accSubHeadId,
                        accSubHeadIdState: "success",
                    });
                }
                else {
                    this.setState({
                        accSubHeadId: 0,
                        accSubHeadIdIndex: -1,
                        accSubHeadIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadChequeNo() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/SalaryPayment/LoadChequeNo"))
            if (response.ok) {
                const data = await response.json();
                if (data.chequeNo.length > 0) {
                    this.setState({
                        cheque: data.chequeNo,
                    });
                }
                else {
                    this.setState({
                        cheque: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
        if (dropdownName === 'campusDD') {
            this.state.campusId = value['campusId'];
            this.LoadSubLevel();
        }
        else if (dropdownName == 'hrLevelDD') {
            this.state.hrLevelId = value['hrLevelId'];
            this.LoadDepartment();
        }
        else if (dropdownName == 'departmentDD') {
            this.state.departmentId = value['departmentId'];
            this.LoadDesignation();
        }
        else if (dropdownName == 'designationDD') {
            this.state.designationId = value['designationId'];
        }
    }
    handleToggleChange = (event, checkName) => {
        debugger;
        this.setState({
            [checkName]: event
        });
        if (event === true) {
            this.setState({
                paymentForm: 'block'
            });
        }
        else {
            this.setState({
                paymentForm: 'none'
            });
        }
    }
    searchBtn = () => {
        debugger;
        fetch(Base_URL + "/api/SalaryPayment/LoadSalaryPayment")
            .then(response => {
                return response.json();
            })
            .then(data => {
                debugger;
                this.setState({
                    SalaryPaymentDataList: data.salaryPaymentList,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "bankAccId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.BankAccDD.findIndex(x => x[stateName] === value[stateName]),
                    bankAccId: value.bankAccId,
                    [stateName + "State"]: "success"
                })
                break;
            case "creditTo":
                debugger
                this.setState({
                    creditTo: value.bankAccId,
                    creditToIndex: this.state.creditToDD.findIndex(x => x['bankAccId'] === value.bankAccId),
                    creditTo: value.bankAccId,
                    creditToState: "success"
                })
                break;
            case "particulars":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        [stateName + "State"]: "success",
                        remarks: event.target.value,
                    });
                } else {
                    this.setState({
                        // [stateName + "State"]: "error",
                        remarks: event.target.value,
                    });

                }
                break;
            case "accSubHeadId":
                debugger
                if (this.state.AccSubHeadDD.find(x => x.accSubHeadId === value.accSubHeadId)) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.AccSubHeadDD.findIndex(x => x.accSubHeadId === value.accSubHeadId),
                        [stateName + "State"]: "success"
                    });
                }
                else {
                    this.setState({
                       // stateName: value[stateName]
                         // [stateName + "State"]: "error"
                        [stateName + "State"]: "success"
                    });

                }
                break;
        }
    }
                
 //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { SalaryPaymentDataList } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <PersonAddOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Salary Payment</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Salary Payment</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Salary Payment</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Date"
                                                        id="date"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.date).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "date", "date") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="campusId"
                                                        size="small"
                                                        options={this.state.campusDD}
                                                        getOptionLabel={(option) => option.campusName}
                                                        value={this.state.campusDD[this.state.campusIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.campusIdState === "success"}
                                                            error={this.state.campusIdState === "error"}
                                                            label="Sector" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="hrLevelId"
                                                        size="small"
                                                        options={this.state.hrLevelDD}
                                                        getOptionLabel={(option) => option.hrLevelName}
                                                        value={this.state.hrLevelDD[this.state.hrLevelIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.hrLevelIdState === "success"}
                                                            error={this.state.hrLevelIdState === "error"}
                                                            label="Sub Sector" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="departmentId"
                                                        size="small"
                                                        options={this.state.departmentDD}
                                                        getOptionLabel={(option) => option.departmentName}
                                                        value={this.state.departmentDD[this.state.departmentIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.departmentIdState === "success"}
                                                            error={this.state.departmentIdState === "error"}
                                                            label="Departments" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="designationId"
                                                        size="small"
                                                        options={this.state.designationDD}
                                                        getOptionLabel={(option) => option.designationName}
                                                        value={this.state.designationDD[this.state.designationIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.designationIdState === "success"}
                                                            error={this.state.designationIdState === "error"}
                                                            label="Designations" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.BankAccDD}
                                                        getOptionLabel={(option) => option.title}
                                                        id="bankAccId"
                                                        value={this.state.BankAccDD[this.state.bankAccIdIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => this.change(event, "bankAccId", "bankAccId", value)}
                                                        renderInput={(params) => <TextField {...params} label="Search By Bank/Cash/All" variant="outlined"
                                                            success={this.state.bankAccIdState === "success"}
                                                            error={this.state.bankAccIdState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="hrLevelId"
                                                        size="small"
                                                        options={this.state.hrLevelDD}
                                                        getOptionLabel={(option) => option.hrLevelName}
                                                        value={this.state.hrLevelDD[this.state.hrLevelIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.hrLevelIdState === "success"}
                                                            error={this.state.hrLevelIdState === "error"}
                                                            label="Payment Head" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                {/*<GridTextbox xs={12} sm={12} md={3}>*/}
                                                {/*    <Autocomplete*/}
                                                {/*        id="Status"*/}
                                                {/*        size="small"*/}
                                                {/*        getOptionLabel={(option) => option.title}*/}
                                                {/*        renderInput={(params) => <TextField {...params} label="Payment Head" variant="outlined" />}*/}
                                                {/*    />*/}
                                                {/*</GridTextbox>*/}
                                                <GridTextbox xs={12} sm={12} md={1} color="facebook" style={{ marginLeft: "1%" }}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "paid")}
                                                            checked={this.state.paid}
                                                            onColor="#3b5998"
                                                            color="facebook"
                                                            className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                        />
                                                        <span>For Payment</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1}>
                                                    <Button color="facebook" onClick={() => { this.searchBtn() }}> Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                                <hr />
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.paymentForm + '' }}>
                                                    <TextField
                                                        size="small"
                                                        label="Payment Date"
                                                        id="paymentDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.paymentDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "paymentDate", "paymentDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3} style={{ display: '' + this.state.paymentForm + '' }}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.creditToDD}
                                                        getOptionLabel={(option) => option.title}
                                                        id="creditTo"
                                                        value={this.state.creditToDD[this.state.creditToIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => this.change(event, "creditTo", "creditTo", value)}
                                                        renderInput={(params) => <TextField {...params} label="Credit To" variant="outlined"
                                                            success={this.state.creditToState === "success"}
                                                            error={this.state.creditToState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                {/*<GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.paymentForm + '' }}>*/}
                                                {/*    <Autocomplete*/}
                                                {/*        disableClearable*/}
                                                {/*        id="hrLevelId"*/}
                                                {/*        size="small"*/}
                                                {/*        options={this.state.AccSubHeadDD}*/}
                                                {/*        getOptionLabel={(option) => option.accSubHeadName}*/}
                                                {/*        value={this.state.AccSubHeadDD[this.state.accSubHeadIdIndex]}*/}
                                                {/*        onChange={(event, value) => { this.setDDValue(event, value) }}*/}
                                                {/*        renderInput={(params) => <TextField {...params}*/}
                                                {/*            success={this.state.accSubHeadIdState === "success"}*/}
                                                {/*            error={this.state.accSubHeadIdState === "error"}*/}
                                                {/*            label="Payment Head" variant="outlined" />}*/}
                                                {/*    />*/}
                                                {/*</GridTextbox>*/}
                                                <GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.paymentForm + '' }}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.AccSubHeadDD}
                                                        getOptionLabel={(option) => option.accSubHeadName}
                                                        id="accSubHeadId"
                                                        value={this.state.AccSubHeadDD[this.state.accSubHeadIdIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => this.change(event, "accSubHeadId", "accSubHeadId", value)}
                                                        renderInput={(params) => <TextField {...params} label="Payment Head" variant="outlined"
                                                            success={this.state.kinRelationState === "success"}
                                                            error={this.state.kinRelationState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.paymentForm + '' }}>
                                                    <TextField
                                                        value={this.state.cheque}
                                                        size="small"
                                                        label="Cheque #:"
                                                        id="cheque"
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={true}
                                                        // required={true}
                                                        inputProps={{
                                                            onChange: (event, value) =>
                                                                this.change(event, "cheque", "cheque", value, 3),
                                                            type: "text",
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.paymentForm + '' }}>
                                                    <TextField
                                                        value={this.state.particulars}
                                                        size="small"
                                                        label="Particulars"
                                                        id="particulars"
                                                        variant="outlined"
                                                        fullWidth
                                                        // required={true}
                                                        inputProps={{
                                                            onChange: (event, value) =>
                                                                this.change(event, "particulars", "particulars", value, 3),
                                                            type: "text",
                                                            maxLength: 100
                                                        }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3} style={{ display: '' + this.state.paymentForm + '' }}>
                                                    <Button color="facebook">Pay salaries of Selected Employees </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Employees Detail List "}
                                            data={SalaryPaymentDataList }
                                            columns={[
                                                {
                                                    name: "employeeId", label: "Select",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (value) != null ? <input type="checkbox" checked={this.state.checked} onClick={() => { this.selectValue(value, this.state.checked) }} /> : "";
                                                        }
                                                    }
                                                },
                                                { name: "regNo", label: "Emp ID" },
                                                { name: "name", label: "Name" },
                                                { name: "fatherName", label: "Father Name" },
                                                { name: "departmentName", label: "Department" },
                                                { name: "designationName", label: "Designation" },
                                                { name: "bankAcc", label: "Bank ACC" },
                                                { name: "salary", label: "Salary" },
                                                {name: "bankType", label: "Bank/Cash" },
                                                {name: "cnic", label: "CNIC" },

                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                                    
                                            <GridTextbox xs={12} sm={6} md={6}>
                                            <h4><small>Selected Employees: 0 </small> </h4>
                                            <h4><small>Salary of Selected Employees: 1</small></h4>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} lg={6} style={{ marginLeft: "30%" }}>
                                                    <Button color="facebook">Export</Button>
                                                    <Button color="facebook">Sheet</Button>
                                                    <Button color="facebook">Audit Salary Sheet</Button>
                                                    <Button color="facebook">Salary Slip</Button>
                                                    <Button color="facebook">Salary Transfer Letter</Button>
                                                </GridTextbox>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
      //#endregion
}
export default withStyles(formStyle)(SalaryPayment);
