//Import from Globel Component";
import {
    React, Moment, Link, withStyles, Typography, InputLabel, FormControl, Select, TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm,
    GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable, Button, formStyle, muiWithToolbar, tbleWithPrint, NavigateNextIcon, HomeIcon,
    PeopleAlt, SearchSharp, EditOutlined, AllDropDowns, SweetAlert, history
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class AllRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regDataList: [],
            nameorfatherNIC: '',
            studentRegNo: '',
            sectionDD: [{ sectionId: -1, sectionName: 'All' }],
            sectionId: -1,
            sectionIdState: '',
            sectionIdIndex: 0,
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0,
            sessionId: -1,
            sessionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'All' }],
            campusId: -1,
            campusIdState: '',
            campusIdIndex: 0,
            classDD: [{ classId: -1, className: 'All' }],
            classId: -1,
            classIdState: '',
            classIdIndex: 0,
            startDate: new Date(), startDateState: '', endDate: new Date(), regStatus: 1,
            alert: null, show: false, generalError: true, ShowSession: true, ShowCampus: true, ShowLevel: false, ShowClass: true, ShowSection: true,
           IsCampusAll: true, IsLevelAll: true, IsClassAll: true, IsSectionAll: true
        };
        //this.setValue = this.setValue.bind(this);
        this.setDateValue = this.setDateValue.bind(this);
        this.loadDropDown();
        this.handleChange = this.handleChange.bind(this);

        
    };
    handleChange(event, value) {
        debugger;
        const stateName = event.target.id.split("-")[0];
        this.setState({
            [stateName]: value[stateName],
            [stateName + "State"]: "success",
            generalError: false
        })
    }
    searchData = () => {
        var startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
        var endDate = Moment(this.state.endDate).format('YYYY-MM-DD');
        var nameorfatherNIC = -1;
        var studentRegNo = -1;
        if (this.state.nameorfatherNIC !== '') {
            nameorfatherNIC = this.state.nameorfatherNIC;
        }
        if (this.state.studentRegNo !== '') {
            studentRegNo = this.state.studentRegNo;
        }
        fetch(Base_URL + "/api/StudentRegistration/SearchAllRegistration?regStatus=" + this.state.regStatus + "&sessionId=" + this.state.sessionId + "&campusId=" + this.state.campusId
            + "&classId=" + this.state.classId + "&sectionId=" + this.state.sectionId + "&nameorfatherNIC=" + nameorfatherNIC + "&studentRegNo=" + studentRegNo
            + "&startDate=" + startDate + "&endDate=" + endDate
        ).then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({ regDataList: data.allRegistrations });
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }
            }).catch(error => {
                console.log(error);
            });
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    loadDropDown = () => {
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sessionDD: data.sessionsDDList, sessionId: data.sessionsDDList[0]['sessionId'] });
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList = this.state.campusDD;
                allList = allList.concat(data.campusDDList);
                this.setState({ campusDD: allList, campusId: allList[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    StudentConfirmation = (SelectedData) => {
        debugger;
        var Regno = SelectedData[0];
        fetch(Base_URL + "/api/StudentRegistration/ConfirmStudentRegistration?RegistrationNo=" + Regno + "").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.exceptionToken === null || data.exception.exceptionToken === '' || data.exception.DisplayMessage === null ||
                    data.exception.DisplayMessage === '') {
                    if (data.confirmValidation.isAlreadyConfirmed === true) {
                        this.AlertMessage('Failed', 'Student has already been confirmed.')
                    }
                    else if (data.confirmValidation.isRegistrationPaid === false) {
                        this.AlertMessage('Failed', 'Student did not paid registartion fee.')
                    }
                    else if (data.confirmValidation.isTestDateTime === false && data.confirmValidation.isTestCleared === false) {
                        this.AlertMessage('Failed', 'Test assessment not been scheduled yet.')
                    }
                    else if (data.confirmValidation.isTestCleared === false) {
                        this.AlertMessage('Failed', 'Student did not cleared test assessment.')
                    }
                    else if (data.confirmValidation.isInterviewed === false) {
                        this.AlertMessage('Failed', 'Student interview not been scheduled yet.')
                    }
                    else if (data.confirmValidation.isAlreadyConfirmed === false && data.confirmValidation.isRegistrationPaid === true
                        && data.confirmValidation.isTestDateTime === true && data.confirmValidation.isTestCleared === true
                        && data.confirmValidation.isInterviewed === true) {
                        this.props.history.push({
                            pathname: '/app/studentprofileedit',
                            state: { RegistrationNo: Regno, IsConfirmation: true }
                        });
                    }
                }
            }).catch(error => {
                console.log(error);
            });
    }
    ConfirmMessage(funName, msg) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this[funName]()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }


    //setValue = (event) => {
    //    this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    //}
   
    //setDDValue = (event, value) => {
    //    const stateName = event.target.id.split("-")[0];
    //    const dropdownName = stateName.slice(0, -2) + "DD";
    //    var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
    //    this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex })
    //}
    
    
    //componentDidMount() {
    //    this.fillClasses();
    //    this.fillSections();
    //}
    //fillClasses = (CampusId) => {
    //    fetch(Base_URL + "/api/Dropdowns/ClassDD?SessionId=" + this.state.sessionId + "&CampusId=" + CampusId).then(response => { return response.json(); })
    //        .then(data => {
    //            this.setState({ classDD: [] })
    //            let allList = [{ classId: -1, className: 'All' }].concat(data.classDDList);
    //            this.setState({
    //                classDD: allList, classId: allList[0]['classId'], classIdIndex: 0,
    //            });
    //            this.fillSections(allList[0]['classId']);
    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    //fillSections = (ClassId) => {
    //    fetch(Base_URL + "/api/Dropdowns/SectionDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.campusId + "&ClassId=" + ClassId + "&LevelId=-1").then(response => { return response.json(); })
    //        .then(data => {
    //            this.setState({ sectionDD: [] })
    //            let allList = [{ sectionId: -1, sectionName: 'All' }].concat(data.sectionsDDList);
    //            this.setState({ sectionDD: allList, sectionId: allList[0]['sectionId'], sectionIdIndex: 0 });

    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    //resetClassSec = () => {
    //    this.setState({
    //        sectionDD: [{ sectionId: -1, sectionName: 'All' }],
    //        sectionId: -1, sectionIdIndex: 0,
    //        classDD: [{ classId: -1, className: 'All' }],
    //        classId: -1, classIdIndex: 0,
    //    });
    //}
 
    //LoadTextbox = () => {

    //}
 
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { studentRegNo, nameorfatherNIC, regStatus, startDate, startDateState, endDate } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <PeopleAlt />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>All Student Registration</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdmanagementHome" > Student Management</Link>
                            <Typography color="textPrimary">All Student Registration</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                {this.state.alert}
                                <div className={classes.mainDiv}>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardHeader>
                                                    <h4 className={classes.cardIconTitle}>All Student Registration</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={8} lg={8}>
                                                            <AllDropDowns data={this.state} xsm={12} smm={6} mdm={3} lgm={3} handleStateChange={this.handleChange} />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={startDateState === "success"}
                                                                error={startDateState === "error"}
                                                                size="small"
                                                                label="Start Date"
                                                                id="startDate"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={Moment(startDate).format('YYYY-MM-DD')}
                                                                type="date"
                                                                onChange={this.setDateValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                size="small"
                                                                label="End Date"
                                                                id="endDate"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={Moment(endDate).format('YYYY-MM-DD')}
                                                                type="date"
                                                                onChange={this.setDateValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                id="nameorfatherNIC"
                                                                size="small"
                                                                label="Name / Father CNIC"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={nameorfatherNIC}
                                                                onChange={this.setValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                size="small"
                                                                label="BTSC No."
                                                                id="studentRegNo"
                                                                variant="outlined"
                                                                value={studentRegNo}
                                                                fullWidth
                                                                onChange={this.setValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                                                                <InputLabel id="regStatuslabel">Status</InputLabel>
                                                                <Select native labelId="regStatuslabel" id="regStatus" label="Status" onChange={this.setValue} value={regStatus}>
                                                                    <option value={1}>Active</option>
                                                                    <option value={0}>InActive</option>
                                                                    <option value={2}>Both</option>
                                                                </Select>
                                                            </FormControl>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={2}>
                                                            <Button color="facebook" onClick={() => { this.searchData() }}><SearchSharp /> Search </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Student Registration List"}
                                                    data={this.state.regDataList}
                                                    columns={[
                                                        { name: "registrationNo", label: "BTS No" },
                                                        { name: "name", label: "Name" },
                                                        { name: "sessionName", label: "Session" },
                                                        { name: "campusName", label: "Campus" },
                                                        { name: "className", label: "Class" },
                                                        { name: "sectionName", label: "Section" },
                                                        {
                                                            name: "registrationDate", label: "Reg.Date",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (Moment(value).format('DD-MMM-YYYY'));
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: "status", label: "Reg.Status",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (value) === true ? "Active" : (value) === false ? "InActive" : "";
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: "paid", label: "Paid",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: "studentId",
                                                            label: "Edit",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (<Link to={{ pathname: '/app/stdeditregistration', stdIdinfo: value }}  ><EditOutlined /></Link>);
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: "isConfirmed",
                                                            label: "Confirmation",
                                                            options: {
                                                                customBodyRender: (value, tableMeta) => {
                                                                    const tableData = tableMeta.tableData;
                                                                    const index = tableMeta.rowIndex;
                                                                    const rowData = tableData[index];
                                                                    return (value) === true ? (<Button color="success" block onClick={() => this.StudentConfirmation(rowData)}> Confirmed </Button>)
                                                                        : (value) === false ? (<Button color="danger" block onClick={() => this.StudentConfirmation(rowData)}> Not Confirm </Button>)
                                                                            : (<div></div>);
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(AllRegistration);
