//Import from Globel Component";
import {
    React, axios, InputMask, ReactToggle, PictureUpload, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, Button,
} from '.../../components/Common/ImportAll';

const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}
var Base_URL = localStorage.BaseURL;
class EditHrEmployee extends React.Component {
    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        this.myRef = React.createRef();
        debugger
        this.state = {
            employeeRegNo: editRegData[0].RegNo,
            employeeIdNo: parseInt(editRegData[0].EmployeeId),
            active: editRegData[0].Active,
            employeeRegNoState: "",
            titleId: editRegData[0].TitleId,
            titleIdIndex: 0,
            employeeName: editRegData[0]["Name"],
            employeeNameState: "",
            genderId: parseInt(editRegData[0].GenderId),
            genderIdIndex: 0,
            genderDisabled: true,
            nicValue: editRegData[0].CNIC,
            nic: editRegData[0].CNIC,
            nicBlackListed: "",
            nicState: "",
            nicExpiry: editRegData[0].CNICExpireDate,
            nicExpiryState: "",
            nicExpiryValue: Moment(editRegData[0].CNICExpireDate).format('YYYY-MM-DD'),
            dob: editRegData[0].DOB,
            minDOB: new Date().setFullYear(dateVal.currentYear - 18, dateVal.currentMonth, dateVal.currentDate),
            dobState: "",
            dobValue: Moment(editRegData[0].DOB).format('YYYY-MM-DD'),
            NewDate: new Date(),
            age: this.ageCalculate(new Date().setFullYear(dateVal.currentYear - 18, dateVal.currentMonth, dateVal.currentDate)),
            mobileNo: editRegData[0].MobileNo,
            mobileNoState: "",
            otherMobileNo: editRegData[0]["OtherMobileNo"],
            otherMobileNoState: "",
            landLine: editRegData[0].LandLine,
            landLineState: "",
            emailPersonal: editRegData[0].PersonalEmail,
            emailPersonalState: "",
            applicationStatusId: editRegData[0].ApplicationStatusId,
            applicationStatusIdIndex: 0,
            applicationStatusIdState: "",
            joiningDate: Moment(editRegData[0].JoiningDate).format('YYYY-MM-DD'),
            joiningDateState: "",
            isSupervisor1: editRegData[0].CanBeSupOne === 'True' ? 1 : 0,
            isSupervisor2: editRegData[0].CanBeSupOne === 'True' ? 1 : 0,
            userName: "",
            userNameState: "",
            password: "",
            passwordState: "",
            emailProfessional: "",
            emailProfessionalState: "",
            userGroupId: "",
            userGroupIndex: 0,
            userGroupState: "",
            house: editRegData[0].House,
            houseState: "success",
            street: editRegData[0].Street,
            streetState: "success",
            countryId: parseInt(editRegData[0].CountryId),
            countryIdIndex: 0,
            countryIdState: "success",
            cityId: parseInt(editRegData[0].CityId),
            cityIdIndex: 0,
            cityIdState: "success",
            townId: parseInt(editRegData[0].TownId),
            townIdIndex: 0,
            townIdState: "",
            sectorId: parseInt(editRegData[0].SectorId),
            sectorIdIndex: 0,
            sectorIdState: "",
            blockId: parseInt(editRegData[0].BlockId),
            blockIdIndex: 0,
            blockIdState: "",
            BlockName: "",
            supervisorOneId: parseInt(editRegData[0].SupOneId),
            supervisorTwoId: parseInt(editRegData[0].SupTwoId),
            superOneIdIndex: 0,
            superOneIdState: "",
            superTwoIdIndex: 0,
            superTwoIdState: "",
            currentAddress: editRegData[0].CurrentAddress,
            currentAddressState: "",
            permanentAddress: editRegData[0].PermanentAddress,
            permanentAddressState: "",
            fatherName: editRegData[0].FatherHusbandName,
            fatherNameState: "",
            spouseName: editRegData[0].SpouseName,
            spouseNameState: "",
            spouseNameDisabled: true,
            religionId: parseInt(editRegData[0].ReligionId),
            religionIdIndex: 0,
            religionIdState: "",
            placeOfBirth: editRegData[0].BirthPlace,
            placeOfBirthState: "",
            bloodGroupId: 0,
            bloodGroupIdIndex: 0,
            bloodGroupIdState: "",
            maritalStatusId: editRegData[0].MaritalStatusId,
            maritalStatusIdIndex: 0,
            maritalStatusIdState: "",
            employmentStatusId: editRegData[0].EmploymentStatusId,
            employmentStatusIdIndex: 0,
            employmentStatusIdState: "",
            confirmationDate: Moment(editRegData[0].ConfirmedDate).format('YYYY-MM-DD'),
            confirmationDateState: "",
            justificationId: editRegData[0].justificationId,
            justificationIdIndex: 0,
            justificationIdState: "",
            employeeIdDisabled: true,
            employeeId: parseInt(editRegData[0].ReplaceEmployeeId),
            employeeIdIndex: 0,
            employeeIdState: "",
            nameUrdu: editRegData[0].NameUrdu,
            nameUrduState: "",
            fatherOrSpouseNameUrdu: editRegData[0].FatherNameUrdu,
            fatherOrSpouseNameUrduState: "",
            applicationDate: Moment(editRegData[0].ApplicationDate).format('YYYY-MM-DD'),
            applicationDateState: "",
            appointmentDate: Moment(editRegData[0].AppointmentDate).format('YYYY-MM-DD'),
            appointmentDateState: "",
            //permanentAddress: "",
            //permanentAddressState: "",
            applicationStatusDD: [{ applicationStatusId: -1, applicationStatus: "Select" }],
            maritalStatusDD: [{ maritalStatusId: -1, maritalStatus: "Select" }],
            justificationDD: [{ justificationId: -1, justification: "Select" }],
            employmentStatusDD: [{ employmentStatusId: -1, employmentStatus: "Select" }],
            employeeDD: [{ employeeId: -1, name: "Select" }],
            countryDD: [{ countryId: -1, countryName: "Select" }],
            cityDD: [{ cityId: -1, cityName: "Select" }],
            townDD: [{ townId: -1, townName: "Select" }],
            sectorDD: [{ sectorId: -1, sectorName: "Select" }],
            blockDD: [{ blockId: -1, blockName: "Select" }],
            religionDD: [{ religionId: -1, religionName: "Select" }],
            bloodGroupDD: [{ bloodGroupId: -1, name: "Select" }],
            titleDD: [{ titleId: -1, name: "Select" }],
            genderDD: [{ genderId: -1, name: "Select" }],
            supervisorOneDD: [{ employeeId: -1, name: "Select" }],
            supervisorTwoDD: [{ employeeId: -1, name: "Select" }],
            alert: null, show: false, generalError: false,
        }
        this.validateData = this.validateData.bind(this);
    };
    ageCalculate(dob) {
        var todate = new Date()
        var fromdate = new Date(dob)
        var y = [todate.getFullYear(), fromdate.getFullYear()],
            ydiff = y[0] - y[1],
            m = [todate.getMonth(), fromdate.getMonth()],
            mdiff = m[0] - m[1],
            d = [todate.getDate(), fromdate.getDate()],
            ddiff = d[0] - d[1];
        if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) {
            --ydiff;
        }
        if (mdiff < 0) {
            mdiff += 12;
        }
        if (ddiff < 0) {
            fromdate.setMonth(m[1] + 1, 0)
            ddiff = fromdate.getDate() - d[1] + d[0]
            --mdiff;
            if (mdiff < 0) {
                mdiff += 12;
            }
        }
        return ydiff + " y " + mdiff + " m " + ddiff + " d";
    }
    // function that verifies if a string is CNIC or not
    async VerifyNIC(nic) {
        debugger;
        try {
            const response = await (fetch(Base_URL + "/api/NewEmployee/VerifyNIC?NIC=" + nic));
            if (response.ok) {
                debugger
                const data = await response.json();
                debugger;
                //this.AlertMessage(data.status,data.message)
                this.confirmAutoFill(data.status, data.message);
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    confirmAutoFill(status, message) {
        debugger
        if (status === "Duplicate") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status + "Employee"}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        cancelBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.danger
                        }
                        confirmBtnText="Do you Want to Autofill the Form?"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        Duplicate NIC found.
                    </SweetAlert>
                ),
                nicBlackListed: ""
            });
        }
        else if (status === "Blacklisted") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                ),
                nicState: "error",
                nicBlackListed: "Black listed Employee"
            });
        }
        else {
            this.setState({
                nicBlackListed: ""
            })
        }

    }
    hideAlert() {
        this.setState({
            alert: null,
            employeeRegNo: "",
        });
    }
    //#region Dropdowns
    ShowHide = () => {
        this.setState({
            clearTextBtn: 'none',
        });
    }
    async AutoFillForm() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/NewEmployee/AutoFill?NIC=" + this.state.nic))
            if (response.ok) {
                const data = await response.json();
                this.confirmAutoFill(data.status, data.message)
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async componentDidMount() {
        this.Disabledbtn();
        this.loadCountryDD();
        this.loadApplicationStatusDD();
        this.loadBloodGroupDD();
        this.loadTitleDD();
        this.loadGenderDD();
        this.loadMaritalStatusDD();
        this.loadReligionsDD();
        this.loadSupperOneDD();
        this.loadSupperTwoDD();
        this.loadEmploymentStatusDD();
        this.loadJustificationDD();
        this.SetSpouseValue();
    }
    async Disabledbtn() {
        debugger;
        if (this.state.active === 'False' || this.state.active === 0) {
            this.setState({
                saveUpdateBtnDisabled: true,
            });
        }
        else {
            this.setState({
                saveUpdateBtnDisabled: false,
            });
        }

    }
    async loadApplicationStatusDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/ApplicationStatuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    applicationStatusDD: data.applicationStatuses
                });
                if (data.applicationStatuses.length > 0) {
                    this.setState({
                        applicationStatusIdIndex: 0,
                        applicationStatusId: data.applicationStatuses[0].applicationStatusId,
                        applicationStatusIdState: "success"
                    });
                    this.SetAppStatusDropDown();
                }
                else {
                    this.setState({
                        applicationStatusId: 0,
                        applicationStatusIdIndex: -1,
                        applicationStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadMaritalStatusDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/MaritalStatuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    maritalStatusDD: data.maritalStatuses
                });
                if (data.maritalStatuses.length > 0) {
                    this.setState({
                        maritalStatusId: data.maritalStatuses[0].maritalStatusId,
                        maritalStatusIdState: "success"
                    });
                    this.SetMaritalStatusDropDown();
                }
                else {
                    this.setState({
                        maritalStatusId: 0,
                        maritalStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadReligionsDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/ReligionsDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    religionDD: data.religionsDDList
                });
                if (data.religionsDDList.length > 0) {
                    this.setState({
                        /*                        religionIdIndex: -1,*/
                        religionIdState: "success"
                    });
                    this.SetReligionsDropDown();
                }
                else {
                    this.setState({
                        religionId: 0,
                        religionIdIndex: -1,
                        religionIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadBloodGroupDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/BloodGroupDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    bloodGroupDD: data.bloodGroupDDList
                });
                if (data.bloodGroupDDList.length > 0) {
                    this.setState({
                        bloodGroupId: data.bloodGroupDDList[0].bloodGroupId,
                        /*                        bloodGroupIdIndex: 0,*/
                        bloodGroupIdState: "success"
                    });
                    this.SetBloodGroupDropDown();
                }
                else {
                    this.setState({
                        bloodGroupId: 0,
                        bloodGroupIdIndex: 0,
                        bloodGroupIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadTitleDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/TitleDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    titleDD: data.titleDDList
                });
                if (data.titleDDList.length > 0) {
                    this.setState({
                        titleId: 1,
                        titleIdState: ""
                    });
                    this.SetTitleDropDown();
                }
                else {
                    this.setState({
                        titleId: -1,
                        titleIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadGenderDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/GenderDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    genderDD: data.genderDDList,
                });
                if (data.genderDDList.length > 0) {
                    this.setState({
                        genderId: 1,
                        genderIdState: "success"
                    });
                    this.SetGenderDropDown();
                }
                else {
                    this.setState({
                        genderId: -1,
                        genderIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSupperOneDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/SuppervisorOneDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    supervisorOneDD: data.supervisorDDList
                });
                if (data.supervisorDDList.length > 0) {
                    this.setState({
                        //superOneIdIndex: 0,
                        // supervisorOneId: editRegData[0].supervisorOneId,
                        superOneIdState: "success"
                    });
                    this.SetSupperOneDropDown();
                }
                else {
                    this.setState({
                        superOneId: 0,
                        superOneIdIndex: -1,
                        superOneIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSupperTwoDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/SuppervisorTwoDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    supervisorTwoDD: data.supervisorDDList
                });
                if (data.supervisorDDList.length > 0) {
                    this.setState({
                        // superTwoIdIndex: data[17].employeeId,
                        superTwoIdState: "success"
                    });
                    this.SetSupperTwoDropDown();
                }
                else {
                    this.setState({
                        superTwoId: 0,
                        superTwoIdIndex: -1,
                        superTwoIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadEmploymentStatusDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/EmploymentStatuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    employmentStatusDD: data.employmentStatuses
                });
                if (data.employmentStatuses.length > 0) {
                    this.setState({
                        employmentStatusIdIndex: 0,
                        employmentStatusId: data.employmentStatuses[0].employmentStatusId,
                        employmentStatusIdState: "success"
                    });
                    this.SetEmploymentStatusDropDown();
                }
                else {
                    this.setState({
                        employmentStatusId: 0,
                        employmentStatusIdIndex: -1,
                        employmentStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadJustificationDD() {
        try {
            debugger;
            const response = await (fetch(Base_URL + "/api/HR/HRHiringJustifications"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    justificationDD: data.hRHiringJustifications
                });
                if (data.hRHiringJustifications.length > 0) {
                    this.setState({
                        justificationIdState: "success"
                    });
                    this.SetJustificationDropDown();
                }
                else {
                    this.setState({
                        justificationId: 0,
                        justificationIdIndex: -1,
                        justificationIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadAllEmployeesDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/AllEmployees"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    employeeDD: data.employees,
                });
                if (data.employees.length > 0) {
                    debugger
                    this.setState({
                        employeeIdState: "success"
                    });
                    this.SetAllEmployeesDropDown();

                }
                else {
                    this.setState({
                        employeeId: 0,
                        employeeIdIndex: -1,
                        employeeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadCountryDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Countries"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    countryDD: data.countryDDList
                });
                if (data.countryDDList.length > 0) {
                    this.setState({
                        countryIdState: "success"
                    });
                    this.SetCountryDropDown();
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadCityDD(countryId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    cityDD: data.citiesDDList,
                });
                if (data.citiesDDList.length > 0) {
                    this.setState({
                        cityId: data.citiesDDList[0].cityId,
                        cityIdIndex: 0,
                        cityIdState: ""
                    })
                }
                else {
                    this.setState({
                        cityId: 0,
                        cityIdIndex: -1,
                        cityIdState: ""
                    })
                }
                this.SetCityDropDown();
            }
            else {
                throw Error(response.statusText)
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    async loadTownDD(cityId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + cityId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    townDD: data.townDDList
                });
                if (data.townDDList.length > 0) {
                    this.setState({
                        townId: 0,
                        townIdIndex: 0,
                        townIdState: ""
                    });
                }
                else {
                    this.setState({
                        townId: 0,
                        townIdIndex: -1,
                        townIdState: ""
                    })
                }
                this.SetTownDropDown();
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSectorDD(townId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Sectors?TownId=" + townId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    sectorDD: data.sectorsDDList
                });
                if (data.sectorsDDList.length > 0) {
                    this.setState({
                        sectorId: 0,
                        sectorIdIndex: 0,
                        sectorIdState: ""
                    });
                }
                else {
                    this.setState({
                        sectorId: 0,
                        sectorIdIndex: -1,
                        sectorIdState: ""
                    })
                }
                this.SetSectorDropDown();
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadBlockDD(sectorId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Blocks?sectorId=" + sectorId))
            if (response.ok) {
                debugger;
                const data = await response.json();
                this.setState({
                    blockDD: data.blockDDList
                });
                if (data.blockDDList.length > 0) {
                    this.setState({
                        blockId: 0,
                        blockIdIndex: 0,
                        blockIdState: "success"
                    });
                }
                else {
                    this.setState({
                        blockId: 0,
                        blockIdIndex: -1,
                        blockIdState: "",
                    })
                }
                this.SetBlockDropDown();
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region SetDropdowns
    SetGenderDropDown = () => {
        var genderId = parseInt(this.props.editRegData[0].GenderId);
        let obj = this.state.genderDD.find(o => o.genderId === genderId);
        if (this.state.genderDD.find(x => x.genderId === obj.genderId) != null) {
            this.setState({
                genderId: obj.genderId,
                genderIdIndex: this.state.genderDD.findIndex(x => x.genderId === obj.genderId),
                genderIdState: "success"
            });
        }
        else {
            this.setState({
                genderId: -1,
                genderIdIndex: 0,
                genderIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetTitleDropDown = () => {
        var titleId = parseInt(this.props.editRegData[0].TitleId);
        let obj = this.state.titleDD.find(o => o.titleId === titleId);
        if (this.state.titleDD.find(x => x.titleId === obj.titleId) != null) {
            this.setState({
                titleId: obj.titleId,
                titleIdIndex: this.state.titleDD.findIndex(x => x.titleId === obj.titleId),
                titleIdState: "success"
            });
        }
        else {
            this.setState({
                titleId: -1,
                titleIdIndex: 0,
                titleIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetAppStatusDropDown = () => {
        var applicationStatusId = parseInt(this.props.editRegData[0].ApplicationStatusId);
        let obj = this.state.applicationStatusDD.find(o => o.applicationStatusId === applicationStatusId);
        if (this.state.applicationStatusDD.find(x => x.applicationStatusId === obj.applicationStatusId) != null) {
            this.setState({
                applicationStatusId: obj.applicationStatusId,
                applicationStatusIdIndex: this.state.applicationStatusDD.findIndex(x => x.applicationStatusId === obj.applicationStatusId),
                applicationStatusIdState: "success"
            });
        }
        else {
            this.setState({
                applicationStatusId: -1,
                applicationStatusIdIndex: 0,
                applicationStatusIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetMaritalStatusDropDown = () => {
        var maritalStatusId = parseInt(this.props.editRegData[0].MaritialStatusId);
        let obj = this.state.maritalStatusDD.find(o => o.maritalStatusId === maritalStatusId);
        if (this.state.maritalStatusDD.find(x => x.maritalStatusId === obj.maritalStatusId) != null) {
            this.setState({
                maritalStatusId: obj.maritalStatusId,
                maritalStatusIdIndex: this.state.maritalStatusDD.findIndex(x => x.maritalStatusId === obj.maritalStatusId),
                maritalStatusIdState: "success"
            });
        }
        else {
            this.setState({
                maritalStatusId: -1,
                maritalStatusIdIndex: 0,
                maritalStatusIdState: "error"
            });
        }
    }
    SetBloodGroupDropDown = () => {
        var bloodGroupId = parseInt(this.props.editRegData[0].BloodGroupId);
        let obj = this.state.bloodGroupDD.find(o => o.bloodGroupId === bloodGroupId);
        if (this.state.bloodGroupDD.find(x => x.bloodGroupId === obj.bloodGroupId) != null) {
            this.setState({
                maritalStatusId: obj.bloodGroupId,
                bloodGroupIdIndex: this.state.bloodGroupDD.findIndex(x => x.bloodGroupId === obj.bloodGroupId),
                bloodGroupIdState: "success"
            });
        }
        else {
            this.setState({
                bloodGroupId: -1,
                bloodGroupIdIndex: 0,
                bloodGroupIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetReligionsDropDown = () => {
        var religionId = parseInt(this.props.editRegData[0].ReligionId);
        let obj = this.state.religionDD.find(o => o.religionId === religionId);
        if (this.state.religionDD.find(x => x.religionId === obj.religionId) != null) {
            this.setState({
                religionId: obj.religionId,
                religionIdIndex: this.state.religionDD.findIndex(x => x.religionId === obj.religionId),
                religionIdState: "success"
            });
        }
        else {
            this.setState({
                religionId: -1,
                religionIdIndex: 0,
                religionIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetEmploymentStatusDropDown = () => {
        var employmentStatusId = parseInt(this.props.editRegData[0].EmploymentStatusId);
        let obj = this.state.employmentStatusDD.find(o => o.employmentStatusId === employmentStatusId);
        if (this.state.employmentStatusDD.find(x => x.employmentStatusId === obj.employmentStatusId) != null) {
            this.setState({
                employmentStatusId: obj.employmentStatusId,
                employmentStatusIdIndex: this.state.employmentStatusDD.findIndex(x => x.employmentStatusId === obj.employmentStatusId),
                employmentStatusIdState: "success"
            });
        }
        else {
            this.setState({
                employmentStatusId: -1,
                employmentStatusIdIndex: 0,
                employmentStatusIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetJustificationDropDown = () => {
        var justificationId = parseInt(this.props.editRegData[0].JustificationId);
        var justificatitionName = this.state.justificationDD.find(o => o.justificationId === justificationId).justification;
        let obj = this.state.justificationDD.find(o => o.justificationId === justificationId);
        if (this.state.justificationDD.find(x => x.justificationId === obj.justificationId) != null) {
            this.setState({
                justificationId: obj.justificationId,
                justificationIdIndex: this.state.justificationDD.findIndex(x => x.justificationId === obj.justificationId),
                justificationIdState: "success"
            });
        }
        else {
            this.setState({
                justificationId: -1,
                justificationIdIndex: 0,
                justificationIdState: "error"
            });
        }
        if (justificatitionName != null && justificatitionName != "" && justificatitionName != undefined && justificatitionName === "Replacement") {
            this.loadAllEmployeesDD();
            this.setState({
                employeeIdDisabled: false,
            });
        }
    }
    SetAllEmployeesDropDown = () => {
        debugger
        var employeeId = parseInt(this.props.editRegData[0].ReplaceEmployeeId);
        let obj = this.state.employeeDD.find(o => o.employeeId === employeeId);
        if (this.state.employeeDD.find(x => x.employeeId === obj.employeeId) != null) {
            var IdIndexValue = this.state.employeeDD.findIndex(x => x.employeeId === obj.employeeId);
            this.setState({
                employeeId: obj.employeeId,
                employeeIdIndex: IdIndexValue,
                employeeIdState: "success"
            });
        }
        else {
            debugger
            this.setState({
                employeeId: -1,
                employeeIdIndex: 0,
                employeeIdState: "error"
            });
        }
    }
    SetSupperOneDropDown = () => {
        var superOneId = parseInt(this.props.editRegData[0].SupOneId);
        let obj = this.state.supervisorOneDD.find(o => o.employeeId === superOneId);
        if (this.state.supervisorOneDD.find(x => x.employeeId === obj.employeeId) != null) {
            this.setState({
                supervisorOneId: obj.employeeId,
                superOneIdIndex: this.state.supervisorOneDD.findIndex(x => x.employeeId === obj.employeeId),
                superOneIdState: "success"
            });
        }
        else {
            this.setState({
                supervisorOneId: -1,
                superOneIdIndex: 0,
                superOneIdState: "error"
            });
            //this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    SetSupperTwoDropDown = () => {
        var superTwoId = parseInt(this.props.editRegData[0].SupTwoId);
        if (superTwoId != null && superTwoId != "" && superTwoId != undefined) {
            let obj = this.state.supervisorTwoDD.find(o => o.employeeId === superTwoId);
            if (this.state.supervisorTwoDD.find(x => x.employeeId === obj.employeeId) != null) {
                this.setState({
                    supervisorTwoId: obj.employeeId,
                    superTwoIdIndex: this.state.supervisorTwoDD.findIndex(x => x.employeeId === obj.employeeId),
                    superTwoIdState: "success"
                });
            }
            else {
                this.setState({
                    supervisorTwoId: 0,
                    superTwoIdIndex: -1,
                    superTwoIdState: "error"
                });
                //this.SuccessMessage('Alert', 'Highlighted field is InActive');
            }
        }
        else {
            this.setState({
                supervisorTwoId: 0,
                superTwoIdIndex: -1
            });
        }
    }
    SetCountryDropDown = () => {
        var countryId = parseInt(this.props.editRegData[0].CountryId);
        if (countryId != null && countryId != "" && countryId != undefined) {
            let obj = this.state.countryDD.find(o => o.countryId === countryId);
            if (this.state.countryDD.find(x => x.countryId === obj.countryId) != null) {
                this.setState({
                    countryId: obj.countryId,
                    countryIdIndex: this.state.countryDD.findIndex(x => x.countryId === obj.countryId),
                    countryIdState: "success"
                });
            }
            else {
                this.setState({
                    countryId: 0,
                    countryIdIndex: -1,
                    countryIdState: "error"
                });
            }
            this.loadCityDD(countryId);
        }
        else {
            this.setState({
                countryId: 0,
                countryIdIndex: -1
            });
        }
    }
    SetCityDropDown = () => {
        var cityId = parseInt(this.props.editRegData[0].CityId);
        if (cityId != null && cityId != "" && cityId != undefined) {
            let obj = this.state.cityDD.find(o => o.cityId === cityId);
            if (this.state.cityDD.find(x => x.cityId === obj.cityId) != null) {
                this.setState({
                    cityId: obj.cityId,
                    cityIdIndex: this.state.cityDD.findIndex(x => x.cityId === obj.cityId),
                    cityIdState: "success"
                });
            }
            else {
                this.setState({
                    cityId: 0,
                    cityIdIndex: -1,
                    cityIdState: "error"
                });
            }
            this.loadTownDD(cityId);
        }
        else {
            this.setState({
                cityId: 0,
                cityIdIndex: -1
            });
        }

    }
    SetTownDropDown = () => {
        var townId = parseInt(this.props.editRegData[0].TownId);
        if (townId != null && townId != "" && townId != undefined) {
            let obj = this.state.townDD.find(o => o.townId === townId);
            if (this.state.townDD.find(x => x.townId === obj.townId) != null) {
                this.setState({
                    townId: obj.townId,
                    townIdIndex: this.state.townDD.findIndex(x => x.townId === obj.townId),
                    townIdState: "success"
                });
            }
            else {
                this.setState({
                    townId: 0,
                    townIdIndex: -1,
                    townIdState: "error"
                });
            }
            this.loadSectorDD(townId);
        }
        else {
            this.setState({
                townId: 0,
                townIdIndex: -1
            });
        }

    }
    SetSectorDropDown = () => {
        var sectorId = parseInt(this.props.editRegData[0].SectorId);
        if (sectorId != null && sectorId != "" && sectorId != undefined) {
            let obj = this.state.sectorDD.find(o => o.sectorId === sectorId);
            if (this.state.sectorDD.find(x => x.sectorId === obj.sectorId) != null) {
                this.setState({
                    sectorId: obj.sectorId,
                    sectorIdIndex: this.state.sectorDD.findIndex(x => x.sectorId === obj.sectorId),
                    sectorIdState: "success"
                });
            }
            else {
                this.setState({
                    sectorId: 0,
                    sectorIdIndex: -1,
                    sectorIdState: "error"
                });
            }
            this.loadBlockDD(sectorId);
        }
        else {
            this.setState({
                sectorId: 0,
                sectorIdIndex: -1
            });
        }

    }
    SetBlockDropDown = () => {
        var blockId = parseInt(this.props.editRegData[0].BlockId);
        if (blockId != null && blockId != "" && blockId != undefined) {
            let obj = this.state.blockDD.find(o => o.blockId === blockId);
            if (this.state.blockDD.find(x => x.blockId === obj.blockId) != null) {
                this.setState({
                    blockId: obj.blockId,
                    blockIdIndex: this.state.blockDD.findIndex(x => x.blockId === obj.blockId),
                    blockIdState: "success"
                });
            }
            else {
                this.setState({
                    blockId: 0,
                    blockIdIndex: -1,
                    blockIdState: "error"
                });
            }
        }
        else {
            this.setState({
                blockId: 0,
                blockIdIndex: -1
            });
        }

    }
    SetSpouseValue = () => {
        var spouseName = this.props.editRegData[0].SpouseName;
        if (spouseName != null && spouseName != "" && spouseName != undefined) {
            this.setState({
                spouseNameDisabled: false,
            });
        }
    }
    //#endregion

    //#region Validations
    verifyDate(value) {
        debugger
        var DateRex = /\d\d\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/;
        if (DateRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Email or not
    verifyEmail(value) {
        debugger
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Mobile No. or not
    verifyMobile(value) {
        debugger
        var mobileRex = /^([3]{1})([0-9]{9})$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        debugger
        if (value === length) {
            return true;
        }
        return false;
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true;
        }
        return false;
    }
    // function that setState of Dynamic Dropdown
    setDDValue = (event, value) => {
        debugger;
        let stateName = event.target.id.split("-")[0];
        let dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    setAddress() {
        debugger
        var house = "";
        if (this.state.houseState === "success") {
            house = "House No " + this.state.house;
        }
        var street = "";
        if (this.state.streetState === "success") {
            street = "Street No " + this.state.street;
        }
        var blockId = this.state.blockId;
        var blockName = "";
        if (this.state.blockId != 0 && this.state.blockId != "") {
            blockName = this.state.blockDD.find(x => x.blockId === blockId).blockName;
            blockId = "";
        }
        var sectorId = this.state.sectorId;
        var sectorName = "";
        if (this.state.sectorId != 0 && this.state.sectorId != -1) {
            sectorName = this.state.sectorDD.find(x => x.sectorId === sectorId).sectorName;
        }
        var townId = this.state.townId;
        var townName = "";
        if (this.state.townId != 0 && this.state.townId != -1) {
            townName = this.state.townDD.find(x => x.townId === townId).townName;
        }
        var cityId = this.state.cityId;
        var cityName = "";
        if (this.state.cityIdState === "success") {
            cityName = this.state.cityDD.find(x => x.cityId === cityId).cityName;
        }
        var countryId = this.state.countryId;
        var countryName = "";
        if (this.state.countryIdState === "success") {
            countryName = this.state.countryDD.find(x => x.countryId === countryId).countryName;
        }
        var CompleteAddress = house + " " + street + " " + blockName + " " + sectorName + " " + townName + " " + cityName + " " + countryName
        if (CompleteAddress.trim().length > 10) {
            debugger
            this.setState({
                currentAddress: CompleteAddress.trim(),
                currentAddressState: "success"
            })
        }
        else {
            this.setState({
                currentAddress: CompleteAddress.trim(),
                currentAddressState: ""
            })
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "employeeName":
                if (this.verifyAlphabets(event.target.value)) {
                    if (event.target.value != "") {
                        this.setState({
                            stateName: stateName,
                            employeeName: event.target.value,
                            [stateName + "State"]: "success",
                        })
                    }
                    else {
                        this.setState({
                            employeeName: event.target.value,
                            [stateName + "State"]: "error"
                        });
                    }
                }
                else {
                    this.setState({
                        employeeName: event.target.value,
                        [stateName + "State"]: "error"
                    });
                    this.state.employeeNameState = 'error';
                }
                break;
            case "dob":
                var date = new Date(event.target.value)
                if (new Date(this.state.minDOB) > date) {
                    Moment(event.target.value).format('YYYY-MM-DD');
                    this.setState({
                        age: this.ageCalculate(date),
                        dob: event.target.value,
                        dobValue: event.target.value,
                        dobState: "success"
                    })
                }
                else {
                    this.setState({
                        dobState: "error",
                        dob: date,
                        age: "Age is less then 18 Y"
                    })
                }
                break;
            case "applicationDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        applicationDate: event.target.value,
                        applicationDateState: "success"
                    })
                }
                else {
                    this.setState({
                        applicationDateState: "error",
                    })
                }
                break;
            case "AppointmentDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        appointmentDate: event.target.value,
                        appointmentDateState: "success"
                    })
                }
                else {
                    this.setState({
                        appointmentDateState: "error",
                    })
                }
                break;
            case "confirmationDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        confirmationDate: event.target.value,
                        confirmationDatetate: "success"
                    })
                }
                else {
                    this.setState({
                        confirmationDateState: "error",
                    })
                }
                break;
            case "titleId":
                this.setState({
                    stateName: value["name"],
                    [stateName + "Index"]: this.state.titleDD.findIndex(x => x[stateName] === value[stateName]),
                    [stateName + "State"]: "success"
                })
                if (value["name"] === "Mr.") {
                    this.setState({
                        genderIdIndex: this.state.genderDD.findIndex(x => x.genderId === 1),
                        titleId: value.titleId,
                        genderId: 1,
                        genderDisabled: true
                    });
                } else if (value["name"] === "Mrs." || value["name"] === "Miss.") {
                    this.setState({
                        genderIdIndex: this.state.genderDD.findIndex(x => x.genderId === 2),
                        titleId: value.titleId,
                        genderId: 2,
                        genderDisabled: true
                    });
                } else if (value["name"] === "Dr.") {
                    this.setState({
                        genderIdIndex: 0,
                        titleId: value.titleId,
                        genderId: 1,
                        genderDisabled: false
                    });
                }
                break;
            case "genderId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.genderDD.findIndex(x => x[stateName] === value[stateName]),
                    genderId: value.genderId,
                    [stateName + "State"]: "success"
                })
                break;
            case "nic":

                if (this.verifyLength((event.target.value.length), 15) == true) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nicValue: event.target.value,
                        nic: event.target.value

                    });
                    this.VerifyNIC(event.target.value);
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value,
                        nic: event.target.value
                    });

                }
                break;
            case "nicExpiry":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nicExpiryValue: event.target.value,
                        cnicexp: event.target.value,
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "mobileNo":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        mobileNo: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        mobileNo: event.target.value
                    });

                }
                break;
            case "otherMobileNo":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        otherMobileNo: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        otherMobileNo: event.target.value
                    });
                }
                break;
            case "permanentAddress":
                debugger;
                if (event.target.value != null && event.target.value!="") {
                    this.setState({
                        [stateName + "State"]: "success",
                        permanentAddress: event.target.value,
                    });
                } else {
                    this.setState({
                        [stateName]: event.target.value,
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "landLine":
                this.setState({ [stateName + "State"]: "success", stateName: event.target.value, landLine: event.target.value });
                break;
            case "emailPersonal":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        emailPersonal: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "joiningDate":
                debugger;
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        joiningDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "house":
                debugger;
                if (event.target.value != null && event.target.value != "") {
                    this.state.houseState = "success";
                    this.state.house = event.target.value;
                    this.setAddress();
                }
                else {
                    this.setState({
                        [stateName]: event.target.value,
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "street":
                if (event.target.value != null && event.target.value != "") {
                    this.state.streetState = "success";
                    this.state.street = event.target.value;
                    this.setAddress();
                }
                else {
                    this.setState({
                        [stateName]: event.target.value,
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "countryId":
                if (value !== null) {
                    this.state.countryId = value.countryId;
                    this.state.countryIdState = "success";
                    this.loadCityDD(value.countryId);
                }
                else {
                    this.setState({
                        cityDD: [{
                            cityId: 0,
                            cityName: ""
                        }],
                        cityId: 0,
                        cityIdIndex: -1,
                        cityIdState: ""
                    })
                }
                this.setDDValue(event, value);
                this.setAddress();
                break;
            case "cityId":
                if (value !== null) {
                    this.state.cityId = value.cityId;
                    this.state.cityIdState = "success";
                    this.loadTownDD(value.cityId);
                }
                else {
                    this.setState({
                        townDD: [{ townId: 0, townName: " " }],
                    })
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "townId":
                if (value !== null) {
                    this.state.townId = value.townId;
                    this.state.townIdState = "success";
                    this.loadSectorDD(value.townId);
                }
                else {
                    this.setState({
                        sectorDD: [{ sectorId: 0, sectorName: " " }],
                    })
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "sectorId":
                if (value !== null) {
                    debugger;
                    this.loadBlockDD(value.sectorId);
                }
                else {
                    this.setState({
                        sectorDD: [{ sectorId: 0, blockName: " " }],
                    })
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "blockId":
                if (value !== null) {
                    this.state.blockId = value.blockId;
                    this.state.blockIdState = "success";
                    this.state.blockId = value.blockId;
                }
                else {
                    this.setState({
                        [stateName + "State"]: " "
                    });
                }
                this.setDDValue(event, value);
                this.setAddress();
                break;
            case "fatherName":
                if (event.target.value != null && event.target.value != "") {
                    this.setState({
                        [stateName + "State"]: "success",
                        fatherName: event.target.value,
                    });
                } else {
                    this.setState({
                        fatherName: event.target.value,
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "maritalStatusId":
                if (value.maritalStatus === "Single") {
                    this.setState({
                        spouseNameDisabled: true,
                        maritalStatusId: value.maritalStatusId
                        //spouseName: "",
                        //spouseNameId:""
                    });
                }
                else {
                    this.setState({
                        spouseNameDisabled: false,
                    });
                }
                this.setDDValue(event, value);
                break;
            case "spouseName":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        spouseName: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "PlaceofBirth":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        placeOfBirth: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "bloodGroup":
                debugger;
                if (value.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        bloodGroupId: value.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "applicationStatusId":
                if (value.applicationStatusId != null) {
                    this.setState({
                        applicationStatusId: value.applicationStatusId,
                        applicationStatusIdState: "success"
                    })
                }
                else {
                    this.setState({
                        confirmationDateState: "error",
                    })
                }
                this.setDDValue(event, value);
                break;
            case "supervisorOneId":
                if (value.employeeId != null) {
                    this.setState({
                        supervisorOneId: value.employeeId,
                        superOneIdIndex: this.state.supervisorOneDD.findIndex(x => x.employeeId === value.employeeId),
                        superOneIdState: "success"
                    })
                }
                else {
                    this.setState({
                        superOneIdState: "error",
                    })
                }
                break;
            case "supervisorTwoId":
                if (value.employeeId != null) {
                    this.setState({
                        supervisorTwoId: value.employeeId,
                        superTwoIdIndex: this.state.supervisorTwoDD.findIndex(x => x.employeeId === value.employeeId),
                        superTwoIdState: "success"
                    })
                }
                else {
                    this.setState({
                        superTwoIdState: "error",
                    })
                }
                break;
            case "NameUrdu":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nameUrdu: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "FatherSpouseNameUrdu":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fatherOrSpouseNameUrdu: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "justificationId":
                if (value.justification === "Replacement") {
                    this.setDDValue(event, value);
                    this.setState({
                        employeeIdDisabled: false
                    });
                    this.loadAllEmployeesDD();
                }
                else {
                    debugger
                    this.setState({
                        employeeDD: [{ employeeId: -1, name: "Select" }],
                        employeeIdIndex: -1,
                        employeeId: 0,
                        employeeIdState: "",
                        employeeIdDisabled: true,
                    });
                    this.setDDValue(event, value);
                }

                break;
            case "equalTo":
                if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "checkbox":
                this.setState({ [stateName]: event });
                break;
            // default:
            //     if ([stateName + "State"] === "success") {
            //         this.setState({ stateName: event.target.value })
            //     }
            //     break;
        }
    }

    // Update HrEmployee function
    validateData = () => {
        debugger;
        if ((this.state.employeeRegNo === 0) || (this.state.employeeRegNo === "") || (this.state.employeeRegNoState === "error")) {
            this.setState({ employeeRegNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.titleId === 0) || (this.state.titleIdState === "error")) {
            this.setState({ titleIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.genderId === 0) || (this.state.genderId === -1) || (this.state.genderIdState === "error")) {
            this.setState({ genderIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.employeeName === "") || (this.state.employeeNameState === "error")) {
            this.setState({ employeeNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.dob === "") || (this.state.dobState === "error")) {
            this.setState({ dobState: "error", generalError: true });
            return;
        }
        else if ((this.state.nicValue === null) || (this.state.nicState === "error")) {
            this.setState({ nicState: "error", generalError: true });
            return;
        }
        else if ((this.state.nicExpiry === null) || (this.state.nicExpiryState === "error")) {
            this.setState({ nicExpiryState: "error", generalError: true });
            return;
        }
        else if ((this.state.nicValue === null) || (this.state.nicState === "error")) {
            this.setState({ nicState: "error", generalError: true });
            return;
        }
        else if ((this.state.mobileNo === "") || (this.state.mobileNoState === "error")) {
            this.setState({ mobileNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.otherMobileNo === "") || (this.state.otherMobileNoState === "error")) {
            this.setState({ otherMobileNoState: "error", generalError: true });
            return;
        }
        //Sev 2
        else if ((this.state.house === "") || (this.state.houseState === "error")) {
            this.setState({ houseState: "error", generalError: true });
            return;
        }
        else if ((this.state.street === "") || (this.state.streetState === "error")) {
            this.setState({ streetState: "error", generalError: true });
            return;
        }
        else if ((this.state.countryId === 0) || (this.state.countryId === -1)) {
            this.setState({ countryIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.cityId === 0) || (this.state.cityId === -1)) {
            this.setState({ cityIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.currentAddress === "") || (this.state.currentAddressState === "error")) {
            this.setState({ currentAddressState: "error", generalError: true });
            return;
        }
        else if ((this.state.permanentAddress === "") || (this.state.permanentAddressState === "error")) {
            this.setState({ permanentAddressState: "error", generalError: true });
            return;
        }
        else if ((this.state.permanentAddress === "") || (this.state.permanentAddressState === "error")) {
            this.setState({ permanentAddressState: "error", generalError: true });
        }
        else if ((this.state.applicationStatusId === 0) || (this.state.applicationStatusId === -1)) {
            this.setState({ applicationStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.joiningDate === null) || (this.state.joiningDateState === "error")) {
            this.setState({ joiningDateState: "error", generalError: true });
            return;
        }

        //4th sec 
        else if ((this.state.fatherName === "") || (this.state.fatherName === null) || (this.state.fatherNameState === "error")) {
            this.setState({ fatherNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.maritalStatusId === 0) || (this.state.maritalStatusId === -1)) {
            this.setState({ maritalStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.religionId === 0) || (this.state.religionId === -1)) {
            this.setState({ religionIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.employmentStatusId === 0) || (this.state.employmentStatusId === -1)) {
            this.setState({ employmentStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.confirmationDate === null) || (this.state.confirmationDateState === "error")) {
            this.setState({ confirmationDateState: "error", generalError: true });
            return;
        }

        else if ((this.state.justificationId === 0) || (this.state.justificationId === -1)) {
            this.setState({ justificationIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.applicationDate === null) || (this.state.applicationDateState === "error")) {
            this.setState({ applicationDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.appointmentDate === null) || (this.state.appointmentDateState === "error")) {
            this.setState({ appointmentDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.supervisorOneId === 0) || (this.state.supervisorOneId === -1)) {
            this.setState({ superOneIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.supervisorTwoId === 0) || (this.state.supervisorTwoId === -1)) {
            this.setState({ superTwoIdState: "error", generalError: true });
            return;
        }
        let DateOfbirth = (this.state.dobValue == "") ? new Date() : this.state.dobValue;
        let Cnicexpiryvalue = (this.state.nicExpiryValue == "") ? new Date() : this.state.nicExpiryValue;
        if (this.state.townId === 0 || this.state.townId === -1) {
            this.state.townId = null;
        }
        if (this.state.sectorId === 0 || this.state.sectorId === -1) {
            this.state.sectorId = null;
        }
        if (this.state.BlockId === 0 || this.state.BlockId === -1) {
            this.state.BlockId = null;
        }
        if (this.state.bloodGroupId === 0 || this.state.bloodGroupId === -1) {
            this.state.bloodGroupId = null;
        }
        if (this.state.employeeId === 0 || this.state.employeeId === -1) {
            this.state.employeeId = null;
        }
        if (this.state.isSupervisor1 === 0) {
            this.state.isSupervisor1 = false;
        }
        if (this.state.isSupervisor1 === 1) {
            this.state.isSupervisor1 = true;
        }
        if (this.state.isSupervisor2 === 0) {
            this.state.isSupervisor2 = false;
        }
        if (this.state.isSupervisor2 === 1) {
            this.state.isSupervisor2 = true;
        }
        let emploInfo = {
            RegNo: this.state.employeeRegNo,
            EmployeeIdNo: this.state.employeeIdNo,
            TitleId: this.state.titleId,
            Name: this.state.employeeName,
            GenderId: this.state.genderId,
            DOB: DateOfbirth,
            CNIC: this.state.nicValue,
            CNICExpireDate: Cnicexpiryvalue,
            MobileNo: this.state.mobileNo,
            OtherMobileNo: this.state.otherMobileNo,
            LandLine: this.state.landLine,
            PersonalEmail: this.state.emailPersonal,
            House: this.state.house,
            Street: this.state.street,
            CountryId: this.state.countryId,
            CityId: this.state.cityId,
            TownId: this.state.townId,
            SectorId: this.state.sectorId,
            BlockId: this.state.blockId,
            CurrentAddress: this.state.currentAddress,
            PermanentAddress: this.state.permanentAddress,
            ApplicationStatus: this.state.applicationStatusId,
            JoiningDate: this.state.joiningDate,
            CanBeSupOne: this.state.isSupervisor1,
            CanBeSupTwo: this.state.isSupervisor2,
            FatherHusbandName: this.state.fatherName,
            MaritialStatusId: this.state.maritalStatusId,
            SpouseName: this.state.spouseName,
            BirthPlace: this.state.placeOfBirth,
            BloodGroupId: this.state.bloodGroupId,
            ReligionId: this.state.religionId,
            EmploymentStatusId: this.state.employmentStatusId,
            ConfirmedDate: this.state.confirmationDate,
            JustificationId: this.state.justificationId,
            ReplaceEmployeeId: this.state.employeeId,
            NameUrdu: this.state.nameUrdu,
            FatherNameUrdu: this.state.fatherOrSpouseNameUrdu,
            ApplicationDate: this.state.applicationDate,
            AppointmentDate: this.state.appointmentDate,
            SupOneId: this.state.supervisorOneId,
            SupTwoId: this.state.supervisorTwoId,

        };
        console.log(emploInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/UpdateNewEmployee', emploInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    // function that verifies if it's a string or not 
    verifyAlphabets(value) {
        debugger
        var AlphabetsRex = /^[a-zA-Z ]*$/;
        if (AlphabetsRex.test(value)) {
            return true;
        }
        return false;
    }
    AlertMessage(status, message) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Personal Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                        <TextField
                                            success={this.state.employeeRegNoState === "success"}
                                            error={this.state.employeeRegNoState === "error"}
                                            value={this.state.employeeRegNo}
                                            size="small"
                                            label="Registration No"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            disabled
                                            type="number"
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.titleDD}
                                            getOptionLabel={(option) => option.name}
                                            value={this.state.titleDD[this.state.titleIdIndex]}
                                            id="titleId"
                                            required={true}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "titleId", "titleId", value)}
                                            renderInput={(params) => <TextField {...params} label="Title" variant="outlined"
                                                success={this.state.titleIdState === "success"}
                                                error={this.state.titleIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.employeeNameState === "success"}
                                            error={this.state.employeeNameState === "error"}
                                            value={this.state.employeeName}
                                            size="small"
                                            label="Employee Name"
                                            id="employeeNameId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "employeeName", "employeeName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.genderDD}
                                            getOptionLabel={(option) => option.name}
                                            value={this.state.genderDD[this.state.genderIdIndex]}
                                            id="genderId"
                                            disableClearable={true}
                                            disabled={this.state.genderDisabled}
                                            onChange={(event, value) => this.change(event, "genderId", "genderId", value)}
                                            renderInput={(params) => <TextField {...params} label="Gender" variant="outlined"
                                                success={this.state.genderState === "success"}
                                                error={this.state.genderState === "error"} />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.dobState === "success"}
                                            error={this.state.dobState === "error"}
                                            size="small"
                                            label={"DOB: " + this.state.age}
                                            id="dobId"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.dob).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "dob", "dob") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <InputMask
                                            {...this.props}
                                            mask="99999-9999999-9"
                                            maskChar=""
                                            value={this.state.nic}
                                        >
                                            {() => <TextField
                                                success={this.state.nicState === "success"}
                                                error={this.state.nicState === "error"}
                                                size="small"
                                                label={"NIC"}
                                                helperText={this.state.nicBlackListed}
                                                variant="outlined"
                                                id="nic"
                                                fullWidth
                                                placeholder="00000-0000000-0"
                                                required={true}
                                                autoComplete="off"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.change(event, "nic", "nic"),
                                                    maxLength: 15
                                                }}
                                            />}
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.nicExpiryState === "success"}
                                            error={this.state.nicExpiryState === "error"}
                                            size="small"
                                            label="NIC Expiry"
                                            id="nicExpiry"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.nicExpiry).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "nicExpiry", "nicExpiry") }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <InputMask
                                            {...this.props}
                                            mask="\3999999999"
                                            maskChar=""
                                            value={this.state.mobileNo}
                                        >

                                            {() => <TextField
                                                success={this.state.mobileNoState === "success"}
                                                error={this.state.mobileNoState === "error"}
                                                size="small"
                                                label="Mobile No"
                                                id="mobileNo"
                                                variant="outlined"
                                                //value={this.state.mobileNo}
                                                fullWidth
                                                required={true}
                                                autoComplete="off"
                                                placeholder="3001234567"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.change(event, "mobileNo", "mobileNo"),
                                                    maxLength: 10,
                                                }}
                                            // onInput={(e) => {
                                            //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            // }}
                                            />
                                            }
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <InputMask
                                            {...this.props}
                                            mask="\3999999999"
                                            maskChar=""
                                            value={this.state.otherMobileNo}
                                        >
                                            {() =>
                                                <TextField
                                                    success={this.state.otherMobileNoState === "success"}
                                                    error={this.state.otherMobileNoState === "error"}
                                                    size="small"
                                                    label="Other Mobile No"
                                                    id="otherMobileNo"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    autoComplete="off"
                                                    placeholder="3001234567"
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.change(event, "otherMobileNo", "otherMobileNo"),
                                                        maxLength: 10,
                                                    }}
                                                />
                                            }
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.landLineState === "success"}
                                            error={this.state.landLineState === "error"}
                                            size="small"
                                            label="Land Line"
                                            id="LandLineId"
                                            value={this.state.landLine}
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                onChange: event =>
                                                    this.change(event, "landLine", "landLine"),
                                                type: "number"
                                            }}
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13)
                                            }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                        <TextField
                                            success={this.state.emailPersonalState === "success"}
                                            error={this.state.emailPersonalState === "error"}
                                            size="small"
                                            label="Email(Personal)"
                                            placeholder="someone@mail.com"
                                            id="emailPersonal"
                                            value={this.state.emailPersonal}
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                onChange: event => this.change(event, "emailPersonal", "emailPersonal")
                                            }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Joining  Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <PictureUpload />
                                        <hr style={{ marginBottom: "25px" }} />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.applicationStatusDD}
                                            getOptionLabel={(option) => option.applicationStatus}
                                            id="applicationStatusId"
                                            value={this.state.applicationStatusDD[this.state.applicationStatusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "applicationStatusId", "applicationStatusId", value)}
                                            renderInput={(params) => <TextField {...params} label="Application Status" variant="outlined"
                                                success={this.state.applicationStatusIdState === "success"}
                                                error={this.state.applicationStatusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.joiningDateState === "success"}
                                            error={this.state.joiningDateState === "error"}
                                            size="small"
                                            label="Joining Date"
                                            id="joiningDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer xs={12} sm={12} md={12}>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "isSupervisor1", "checkbox")}
                                                checked={this.state.isSupervisor1}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Supervisor 1</span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "isSupervisor2", "checkbox")}
                                                checked={this.state.isSupervisor2}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Supervisor 2</span>
                                        </label>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Address Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.houseState === "success"}
                                            error={this.state.houseState === "error"}
                                            size="small"
                                            label="House"
                                            id="houseId"
                                            variant="outlined"
                                            value={this.state.house}
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "house", "house", value, 1),
                                                type: "text"
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.streetState === "success"}
                                            error={this.state.streetState === "error"}
                                            size="small"
                                            label="Street"
                                            id="streetId"
                                            value={this.state.street}
                                            variant="outlined"
                                            fullWidth
                                            required="true"
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "street", "street", value, 1),
                                                type: "text"
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="countryId"
                                            options={this.state.countryDD}
                                            getOptionLabel={(option) => option.countryName}
                                            value={this.state.countryDD[this.state.countryIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "countryId", "countryId", value)}
                                            renderInput={(params) => <TextField {...params} label="Country" variant="outlined"
                                                success={this.state.countryIdState === "success"}
                                                error={this.state.countryIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="cityId"
                                            options={this.state.cityDD}
                                            getOptionLabel={(option) => option.cityName}
                                            value={this.state.cityDD[this.state.cityIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "cityId", "cityId", value)}
                                            renderInput={(params) => <TextField {...params} label="City" variant="outlined"
                                                success={this.state.saveCityIdState === "success"}
                                                error={this.state.saveCityIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="townId"
                                            options={this.state.townDD}
                                            getOptionLabel={(option) => option.townName}
                                            value={this.state.townDD[this.state.townIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "townId", "townId", value)}
                                            renderInput={(params) => <TextField {...params} label="Town" variant="outlined"
                                                success={this.state.saveTownIdState === "success"}
                                                error={this.state.saveTownIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="sectorId"
                                            options={this.state.sectorDD}
                                            getOptionLabel={(option) => option.sectorName}
                                            value={this.state.sectorDD[this.state.sectorIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "sectorId", "sectorId", value)}
                                            renderInput={(params) => <TextField {...params} label="Sector" variant="outlined"
                                                success={this.state.saveSectorIdState === "success"}
                                                error={this.state.saveSectorIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="blockId"
                                            options={this.state.blockDD}
                                            getOptionLabel={(option) => option.blockName}
                                            value={this.state.blockDD[this.state.blockIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "blockId", "blockId", value)}
                                            renderInput={(params) => <TextField {...params} label="Block" variant="outlined"
                                                success={this.state.saveblockIdState === "success"}
                                                error={this.state.saveblockIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                        <TextField
                                            success={this.state.currentAddressState === "success"}
                                            error={this.state.currentAddressState === "error"}
                                            size="small"
                                            label="Current Address"
                                            id="currentAddressId"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.currentAddress}
                                            multiline={true}
                                            rows={2}
                                            required="true"
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "currentAddress", "currentAddress", value, 5),
                                                type: "text"
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                        <TextField
                                            success={this.state.permanentAddressState === "success"}
                                            error={this.state.permanentAddressState === "error"}
                                            size="small"
                                            label="Permanent Address"
                                            id="permanentAddress"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.permanentAddress}
                                            multiline={true}
                                            rows={2}
                                            required="true"
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "permanentAddress", "permanentAddress", value, 5),
                                                type: "text"
                                            }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Other Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.fatherNameState === "success"}
                                            error={this.state.fatherNameState === "error"}
                                            size="small"
                                            label="Father Name"
                                            id="fatherNameId"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.fatherName}
                                            required="true"
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "fatherName", "fatherName", value, 3),
                                                type: "text"
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="maritalStatusId"
                                            options={this.state.maritalStatusDD}
                                            getOptionLabel={(option) => option.maritalStatus}
                                            value={this.state.maritalStatusDD[this.state.maritalStatusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "maritalStatusId", "maritalStatusId", value)}
                                            renderInput={(params) => <TextField {...params} label="Marital Status" variant="outlined"
                                                success={this.state.maritalStatusIdState === "success"}
                                                error={this.state.maritalStatusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.spouseNameState === "success"}
                                            error={this.state.spouseNameState === "error"}
                                            size="small"
                                            label="Spouse Name"
                                            id="spouseNameId"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.spouseName}
                                            disabled={this.state.spouseNameDisabled}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "spouseName", "spouseName", value, 3),
                                                type: "text"
                                            }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.studentRegNoState === "success"}
                                            error={this.state.studentRegNoState === "error"}
                                            size="small"
                                            label="Place of Birth"
                                            id="placeOfBirth"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.placeOfBirth}
                                            inputProps={{ onChange: event => this.change(event, "PlaceofBirth", "PlaceofBirth"), type: "text" }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="bloodGroupId"
                                            options={this.state.bloodGroupDD}
                                            getOptionLabel={(option) => option.name}
                                            value={this.state.bloodGroupDD[this.state.bloodGroupIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.setDDValue(event, value)}
                                            renderInput={(params) => <TextField {...params} label="Blood Group" variant="outlined"
                                                success={this.state.bloodGroupIdState === "success"}
                                                error={this.state.bloodGroupIdState === "error"}
                                            />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="religionId"
                                            options={this.state.religionDD}
                                            getOptionLabel={(option) => option.religionName}
                                            value={this.state.religionDD[this.state.religionIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.setDDValue(event, value)}
                                            renderInput={(params) => <TextField {...params} label="Religion" variant="outlined"
                                                success={this.state.religionIdState === "success"}
                                                error={this.state.religionIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="employmentStatusId"
                                            options={this.state.employmentStatusDD}
                                            getOptionLabel={(option) => option.employmentStatus}
                                            value={this.state.employmentStatusDD[this.state.employmentStatusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.setDDValue(event, value)}
                                            renderInput={(params) => <TextField {...params} label="Employment Status" variant="outlined"
                                                success={this.state.employmentStatusIdState === "success"}
                                                error={this.state.employmentStatusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.confirmationDateState === "success"}
                                            error={this.state.confirmationDateState === "error"}
                                            size="small"
                                            label={"confirmationDate"}
                                            id="confirmationDateId"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.confirmationDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "confirmationDate", "confirmationDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="justificationId"
                                            options={this.state.justificationDD}
                                            getOptionLabel={(option) => option.justification}
                                            value={this.state.justificationDD[this.state.justificationIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "justificationId", "justificationId", value)}
                                            renderInput={(params) => <TextField {...params} label="Justification for Hiring" variant="outlined"
                                                success={this.state.justificationIdState === "success"}
                                                error={this.state.justificationIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="employeeId"
                                            options={this.state.employeeDD}
                                            getOptionLabel={(option) => option.name}
                                            value={this.state.employeeDD[this.state.employeeIdIndex]}
                                            disableClearable={true}
                                            disabled={this.state.employeeIdDisabled}
                                            onChange={(event, value) => this.setDDValue(event, value)}
                                            renderInput={(params) => <TextField {...params} label="Employees List" variant="outlined"
                                                success={this.state.employeeIdState === "success"}
                                                error={this.state.employeeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.studentRegNoState === "success"}
                                            error={this.state.studentRegNoState === "error"}
                                            size="small"
                                            label="Name Urdu"
                                            id="NameUrdu"
                                            variant="outlined"
                                            value={this.state.nameUrdu}
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "NameUrdu", "NameUrdu"), type: "text" }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.studentRegNoState === "success"}
                                            error={this.state.studentRegNoState === "error"}
                                            size="small"
                                            label="Father/Spouse Name Urdu"
                                            id="FatherSpouseNameUrdu"
                                            variant="outlined"
                                            value={this.state.fatherOrSpouseNameUrdu}
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "FatherSpouseNameUrdu", "FatherSpouseNameUrdu"), type: "text" }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            size="small"
                                            label="Application Date"
                                            id="ApplicationDate"
                                            variant="outlined"
                                            value={Moment(this.state.applicationDate).format('YYYY-MM-DD')}
                                            // defaultValue={dateVal.currentDate}
                                            type="date"
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "ApplicationDate", "applicationDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            size="small"
                                            label="Appointment Date"
                                            id="AppointmentDate"
                                            variant="outlined"
                                            //defaultValue={dateVal.currentDate}
                                            value={Moment(this.state.appointmentDate).format('YYYY-MM-DD')}
                                            type="date"
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "AppointmentDate", "AppointmentDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            id="supervisorOneId"
                                            options={this.state.supervisorOneDD}
                                            value={this.state.supervisorOneDD[this.state.superOneIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "supervisorOneId", "supervisorOneId", value)}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Supervisor 1" variant="outlined"
                                                success={this.state.superOneIdState === "success"}
                                                error={this.state.superOneIdState === "error"}
                                            />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.supervisorTwoDD}
                                            value={this.state.supervisorTwoDD[this.state.superTwoIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "supervisorTwoId", "supervisorTwoId", value)}
                                            // onChange={(event, value) => this.setDDValue(event, value)}
                                            getOptionLabel={(option) => option.name}
                                            id="supervisorTwoId"
                                            renderInput={(params) => <TextField {...params} label="Supervisor 2" variant="outlined"
                                                success={this.state.superTwoIdState === "success"}
                                                error={this.state.superTwoIdState === "error"}
                                            />}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
                <GridTextbox xs={12} sm={12} md={4} style={{ marginBottom: "0px", marginLeft: "47%" }}>
                    <Button color="success" onClick={() => { this.validateData() }}
                        disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />Update</Button>
                </GridTextbox>
            </div>
        );
    }
}
export default withStyles(formStyle)(EditHrEmployee);