//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class LeaveQuata extends React.Component {

    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            quotaTable: 'none',
            employeeId: parseInt(editRegData["EmployeeId"]),
            hrLeaveQuotList: [],
            leaveQuotaList: [],
            lcId: "",
            lcIdIndex: 0,
            lcIdState: "",
            HrLeaveQuotaDD: [{ lcId: -1, Name: "Select" }],
            DegreeDD: [{ degreeId: -1, degreeName: "Select" }],
            columns: [{
                name: "name",
                label: "Leave Name",
                options: {
                    filter: true,
                }
            },
            {
                name: "casualLeave",
                label: "Casual Leave",
                options: {
                    filter: true,
                }
            }, {
                name: "saturdayLeave",
                label: "Saturday Leave",
                options: {
                    filter: true,
                }
            }, {
                name: "saturday",
                label: "Saturday",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "sunday",
                label: "Sunday",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "compensatory",
                label: "Compensatory",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            },
                //{
                //    name: "lcId",
                //label: "Edit",
                //options: {
                //    customBodyRender: (value) => {
                //        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                //    }
                //}
                //}
            ],
            alert: null, show: false, generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadhrLeaveQuotaDD()
        this.LoadEmployeeLeaveQuota()

    }
    async LoadhrLeaveQuotaDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/HrLeaveQuotaDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    HrLeaveQuotaDD: data.hrLeaveQuotaDDList
                });
                if (data.hrLeaveQuotaDDList.length > 0) {
                    this.setState({
                        lcId: data.hrLeaveQuotaDDList[0].lcId,
                        lcIdIndex: 0,
                        lcIdState: "success"
                    });
                }
                else {
                    this.setState({
                        lcId: 0,
                        lcIdIndex: -1,
                        lcIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    LoadhrLeaveQuota = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/HrLeaveQuata")
            .then(response => { return response.json() })
            .then(data => {
                this.setState({
                    hrLeaveQuotList: data.hrLeaveQuataList
                })
            })
            .catch(error => { console.log(error) });
    }
    async LoadEmployeeLeaveQuota() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadEmployeeLeaveQuota?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    leaveQuotaList: data.leaveQuotadataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    SavehrLeaveQuota = () => {
        debugger;
        if ((this.state.lcId === "") || (this.state.lcId === 0) || (this.state.lcIdState === "error")) {
            this.setState({ lcIdState: "error", generalError: true });
            return;
        }
        let HRLeaveQuotaInfo = {
            EmployeeId: this.state.employeeId,
            LcId: this.state.lcId,
        };
        axios.post(Base_URL + '/api/EditNewEmployee/SavehrLeaveQuota', HRLeaveQuotaInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null,

        });
        this.LoadEmployeeLeaveQuota();
        this.LoadhrLeaveQuotaDD();
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    showTable() {
        debugger;
        this.setState({
            quotaTable: 'block',
        });
        this.LoadhrLeaveQuota();
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "lcId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.HrLeaveQuotaDD.findIndex(x => x[stateName] === value[stateName]),
                    lcId: value.lcId,
                    [stateName + "State"]: "success"
                })
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { leaveQuotaList, hrLeaveQuotList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Leave Quota</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.HrLeaveQuotaDD}
                                            getOptionLabel={(option) => option.name}
                                            id="lcId"
                                            value={this.state.HrLeaveQuotaDD[this.state.lcIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "lcId", "lcId", value)}
                                            renderInput={(params) => <TextField {...params} label=" HR Leave Quota*" variant="outlined"
                                                success={this.state.lcIdState === "success"}
                                                error={this.state.lcIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SavehrLeaveQuota() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Save </Button>
                                        <Button color="success" onClick={() => { this.showTable() }} disabled={this.state.saveUpdateBtnDisabled}> Load Quota </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <GridItem xs={12} style={{ display: '' + this.state.quotaTable + '' }}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Leave Quoata Details"}
                                        data={hrLeaveQuotList}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridItem>
                            <GridItem xs={12} style={{ marginTop: "0px", marginBottom: "0px" }}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Employee Quoata Details"}
                                        data={leaveQuotaList}
                                        columns={
                                            [
                                                //{
                                                //    name: "ElqId",
                                                //    label: "Edit",
                                                //    options: {
                                                //        customBodyRender: (value) => {
                                                //            return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                //        }
                                                //    }
                                                //},
                                                { name: "groupName", label: "Leave Name" },
                                                {
                                                    name: "active", label: "Active",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (value) === true ? 'Yes' : (value) === false ? 'No' : "";
                                                        }
                                                    }
                                                },
                                                {
                                                    name: "casualLeave",
                                                    label: "Casual Leave",
                                                    options: {
                                                        filter: true,
                                                    }
                                                }, {
                                                    name: "saturdayLeave",
                                                    label: "Saturday Leave",
                                                    options: {
                                                        filter: true,
                                                    }
                                                }, {
                                                    name: "saturday",
                                                    label: "Saturday",
                                                    options: {
                                                        filter: true,
                                                        customBodyRender: (value) => {
                                                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                        }
                                                    }
                                                }, {
                                                    name: "sunday",
                                                    label: "Sunday",
                                                    options: {
                                                        filter: true,
                                                        customBodyRender: (value) => {
                                                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                        }
                                                    }
                                                }, {
                                                    name: "compensatory",
                                                    label: "Compensatory",
                                                    options: {
                                                        filter: true,
                                                        customBodyRender: (value) => {
                                                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                        }
                                                    }
                                                },
                                                { name: "modifier", label: "Modifier" },
                                                { name: "modifierDate", label: "ModifiedDate" },
                                            ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridItem>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(LeaveQuata);




