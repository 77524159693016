
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, AddCircle
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class AdvancePayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedA: true,
            checkedB: false,
            simpleSelect: "",
            firstname: "",
            firstnameState: "",
            lastname: "",
            lastnameState: "",
            email: "",
            emailState: ""
        };
        //#endregion 
    }

 //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <AddCircle />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Employee Advances</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Employee Advances</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingTop: "0px", paddingBottom: "15px", paddingRight: "15px", paddingLeft: "5px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Search Employee</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Employee ID/Name"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook"><SearchSharp />Search </Button>
                                                    <Link to={{ pathname: '/app/LoanManagement'  }}><EditOutlined /></Link>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Employee List "}
                                            columns={[
                                                { label: "Advance" }, { label: "Emp ID" },
                                                { label: "Name" }, { label: "Campus" },
                                                { label: "Department" }, { label: "Designation" },
                                                //{
                                                //    name: "employeeId",
                                                //    label: "Edit",
                                                //    options: {
                                                //        customBodyRender: (value) => {
                                                //            return (<Link to={{ pathname: '/app/LoanManagement', empIdinfo: value }}><EditOutlined /></Link>)

                                                //        }
                                                //    }
                                                //},
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                    <CardHeader >
                                        <h4><small>Records Found: 0</small> </h4>
                                    </CardHeader>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
     //#endregion 
}
export default withStyles(extendedFormsStyle)(AdvancePayment);