import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Home as HomeIcon, NavigateNext as NavigateNextIcon, AddCircleOutlineOutlined } from "@material-ui/icons";
//import SweetAlert from "react-bootstrap-sweetalert";
//import ReactToggle from "react-switch";
//import TextField from '@material-ui/core/TextField';
//import axios from 'axios';
//import MUIDataTable from "mui-datatables";
//import { MuiThemeProvider } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.jsx";
//import GridForm from "components/Grid/GridForm.jsx";
//import GridTextbox from "components/Grid/GridTextbox.jsx";
//import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";

class Benefit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            testing: 'Welcome to BTSMIS Employee Benefit  ...',
        };

    };

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <AddCircleOutlineOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}> Employee Benefit </h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary"> Employee Benefit </Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <h4 className={classes.cardIconTitle}>{testing}</h4>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(Benefit);
