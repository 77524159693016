import Chip from '@material-ui/core/Chip';
import withStyles from "@material-ui/core/styles/withStyles";
import { grayColor, cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import { createMuiTheme } from '@material-ui/core/styles';

//#region Form Style
const formStyle = {
    breadcrumStyle: {
        backgroundColor: "#f5f5f5",
        paddingBottom: "5px",
        paddingTop: "5px",
        paddingLeft: "5px"
    },
    mainDiv: {
        paddingBottom: "15px",
        paddingRight: "5px",
        paddingLeft: "5px",
        width:"100% !important",
    },
    formDiv: {
        paddingBottom: "15px",
        paddingRight: "15px",
        paddingLeft: "15px",
        width: "100% !important",
    },
    toggleOff: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 14,
        color: "white",
        paddingRight: 2
    },
    toggleOn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 14,
        color: "white",
        paddingRight: 1
    },
    toggleStyle: {
        width: "60px",
        marginTop: "7px",
    },
    formHeading: {
        color: 'black'
    },
    cardTitle: {
        marginTop: "0",
        marginBottom: "3px",
        color: grayColor[2],
        fontSize: "18px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    center: {
        textAlign: "center"
    },
    right: {
        textAlign: "right"
    },
    left: {
        textAlign: "left"
    },
    MuiBreadcrumbsCenter: {
        margin: "0",
        display: "flex",
        padding: "0",
        flexWrap: "wrap",
        listStyle: "none",
        alignItems: "center",
        placeContent: "center"
    },
    zeromargins: {
        margin: "0",
        display: "flex",
        padding: "0",
        flexWrap: "wrap",
        listStyle: "none"
    },
    ...buttonStyle
};
//#endregion

//#region Datatable Custom Design
const muiNoToolbar = () => createMuiTheme({
    overrides: {
        MUIDataTableHeadCell: {
            fixedHeader: {
                backgroundColor: '#d4e2f8'
            }
        },
        MUIDataTableSelectCell: {
            headerCell: {
                backgroundColor: '#d4e2f8'
            }
        },
        MuiTableCell: {
            root: {
                padding: '4px 8px',
            },
        },
        MUIDataTableToolbar: {
            root: { display: 'none' }
        },
    }
});
const muiWithToolbar = () => createMuiTheme({
    overrides: {
        MUIDataTableHeadCell: {
            fixedHeader: {
                backgroundColor: '#d4e2f8'
            }
        },
        MUIDataTableSelectCell: {
            headerCell: {
                backgroundColor: '#d4e2f8'
            }
        },
        MuiTableCell: {
            root: {
                padding: '4px 8px',
            },
        },
    }
});

const tbleWithPrint = {
    selectableRows: 'none',
    filter: true,
    filterType: 'dropdown',
    responsive: '.',
    rowsPerPageOptions: [5, 10, 100, 1000, 10000],
    enableNestedDataAccess: '.',
    downloadOptions: { filename: 'Exported_data.csv', filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } },
};
const tblWithoutPrint = {
    selectableRows: 'none',
    filter: true,
    responsive: 'scroll',
    enableNestedDataAccess: '.',
    pagination: false,
};
//#endregion

//#region StyledBreadcrumb
const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        height: theme.spacing(3),
        color: "#000",
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[500],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.grey[500],
        },
    },
}))(Chip);

//#endregion

export {
    formStyle,
    muiNoToolbar,
    muiWithToolbar,
    tbleWithPrint,
    tblWithoutPrint,
    StyledBreadcrumb,
}
