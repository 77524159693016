import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GroupAdd as ManageItemIcon, EditOutlined, SaveOutlined, AddCircle as AddIcon, Home as HomeIcon, NavigateNext as NavigateNextIcon, Replay, SearchSharp } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { MuiThemeProvider } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { formStyle, muiWithToolbar, tbleWithPrint, StyledBreadcrumb } from "assets/jss/customStyle/allCustomStyle";
var Base_URL = localStorage.BaseURL;
class IssueInventory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manageItemdata: [],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<Button color="facebook" onClick={() => this.LoadTextbox(value)}><EditOutlined /> Edit </Button>);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false,
            //#endregion
        };
    };

    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.Name === "") {
            this.setState({ NameState: "error" });
            return;
        }
    }
    //#endregion 

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <Replay />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Issue/Return Inventory</h4>
                </CardHeader>
                {this.state.alert}
                <div className={classes.formDiv}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/inventory" > Inventory</Link>
                        <Typography color="textPrimary">Employees <small>Management </small> </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Sector" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Department" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Designation" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Name/Reg/Phone"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "1%" }}>
                                                    <Button color="facebook"><SearchSharp />Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        columns={[
                                            { label: "RegNo" }, { label: "Name" },
                                            { label: "Sector" }, { label: "Department" },
                                            { label: "Designation" }, { label: "Issue" },
                                        ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                                <CardHeader >
                                    <h4><small>Records Found: 0</small> </h4>
                                </CardHeader>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(IssueInventory);

