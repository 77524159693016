
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Sms,
    AddIcon, EditOutlined, GraphicEq
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class AttendanceIPs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchCheck: true,
            attendanceIPId: -1,
            ipAddress: '',
            description: '',
            connectivity: '',
            checkInTime: '',
            checkOutTime: '',
            clearAttendance: '',
            syncAttendance: '',
            active: false,
            attendanceIPsList: [],
            ipAddressState: '',
            descriptionState: '',
            connectivityState: '',
            checkInTimeState: '',
            checkOutTimeState: '',
            clearAttendanceState: '',
            syncAttendanceState: '',
            alert: null,
            show: false,
            checked: false, saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: 0,
            campusIdState: '',
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    ClearTextfieldStates = () => {
        this.setState({
            attendanceIPId: -1, ipAddress: '', connectivity: '', active: false, description: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            checkInTime: '', checkOutTime: '', clearAttendance: '', syncAttendance: '', campusIdState: '', campusId: 0, campusIdIndex: -1,
            ipAddressState: '',
            descriptionState: '',
            connectivityState: '',
            checkInTimeState: '',
            checkOutTimeState: '',
            clearAttendanceState: '',
            syncAttendanceState: '',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        if (this.state.campusId === 0) {
            this.setState({ campusIdState: "error" });
            return;
        }
        else if (this.state.ipAddress === '') {
            this.setState({ ipAddressState: "error" });
            return;
        }
        else if (this.state.description === '') {
            this.setState({ descriptionState: "error" });
            return;
        }
        else if (this.state.checkInTime === '') {
            this.setState({ checkInTimeState: "error" });
            return;
        }
        else if (this.state.checkOutTime === '') {
            this.setState({ checkOutTimeState: "error" });
            return;
        }
        else if (this.state.clearAttendance === '') {
            this.setState({ clearAttendanceState: "error" });
            return;
        }
        else if (this.state.syncAttendance === '') {
            this.setState({ syncAttendanceState: "error" });
            return;
        }

        else {
            this.ConfirmMessage();
        }
    }
    LoadData = () => {
        fetch(Base_URL + "/api/BiometricDeviceIP/LoadAttendanceIPs?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ attendanceIPsList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" })
    }

    componentDidMount() {

        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                this.setState({ campusDD: data, campusId: data[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    SaveBiometricDeviceIP = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/BiometricDeviceIP/InsertBiometricDeviceIP?AttendanceIPId=" + this.state.attendanceIPId + "&IPAddress=" + this.state.ipAddress + "&Description=" + this.state.description + "&CheckInTime=" + this.state.checkInTime + "&CheckOutTime=" + this.state.checkOutTime + "&ClearAttendance=" + this.state.clearAttendance + "&SyncAttendance=" + this.state.syncAttendance + "&CampusId=" + this.state.campusId + "&Active=" + this.state.active)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(attendanceIPId) {
        let obj = this.state.attendanceIPsList.find(o => o.attendanceIPId === attendanceIPId);
        this.setState({ ipAddress: obj.ipAddress, attendanceIPId: obj.attendanceIPId, description: obj.description, checkInTime: obj.checkInTime, checkOutTime: obj.checkOutTime, clearAttendance: obj.clearAttendance, syncAttendance: obj.syncAttendance, campusId: obj.campusId, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ campusIdIndex: this.state.campusDD.findIndex(x => x.campusId === obj.campusId) });
    }

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveBiometricDeviceIP()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save BiometricDeviceIPs detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { attendanceIPsList, description, ipAddress, checkInTime, checkOutTime, active, syncAttendance, clearAttendance, searchCheck, ipAddressState,
            descriptionState, clearAttendanceState, syncAttendanceState, checkOutTimeState, checkInTimeState, campusIdState, campusDD, campusIdIndex } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <GraphicEq />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Biometric Device IPs</h4>
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "3px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">Biometric Device IPs</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "5px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "0px", paddingLeft: "0px" }}>
                        <CardBody>
                            {this.state.alert}
                            <GridContainer>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px", marginBottom: "10px" }}>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")}
                                                                checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                                borderRadius={4}
                                                                handleDiameter={22}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Active</span>
                                                        </label>
                                                    </GridTextbox>
                                                    <GridTextbox>
                                                        <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                                <CardHeader>
                                                    <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                                    <h4 className={classes.cardIconTitle}>Biometric Device IPs</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <form>
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    id="campusId"
                                                                    size="small"
                                                                    options={campusDD}
                                                                    getOptionLabel={(option) => option.campusName}
                                                                    value={campusDD[campusIdIndex]}
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                                                        error={campusIdState === "error"} label="Campus" variant="outlined" />}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={ipAddressState === "success"}
                                                                    error={ipAddressState === "error"}
                                                                    size="small"
                                                                    label="IP Address"
                                                                    id="ipAddress"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={ipAddress}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={1} >
                                                                <TextField
                                                                    success={descriptionState === "success"}
                                                                    error={descriptionState === "error"}
                                                                    size="small"
                                                                    label="Port"
                                                                    id="description"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={description}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <TextField
                                                                    success={descriptionState === "success"}
                                                                    error={descriptionState === "error"}
                                                                    size="small"
                                                                    label="Description"
                                                                    id="description"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={description}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <TextField
                                                                    success={checkInTimeState === "success"}
                                                                    error={checkInTimeState === "error"}
                                                                    size="small"
                                                                    type="time"
                                                                    label="CheckInTime"
                                                                    id="checkInTime"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={checkInTime}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <TextField
                                                                    success={checkOutTimeState === "success"}
                                                                    error={checkOutTimeState === "error"}
                                                                    size="small"
                                                                    type="time"
                                                                    label="CheckOutTime"
                                                                    id="checkOutTime"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={checkOutTime}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <TextField
                                                                    success={clearAttendanceState === "success"}
                                                                    error={clearAttendanceState === "error"}
                                                                    size="small"
                                                                    type="time"
                                                                    label="ClearAttendance"
                                                                    id="clearAttendance"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={clearAttendance}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <TextField
                                                                    success={syncAttendanceState === "success"}
                                                                    error={syncAttendanceState === "error"}
                                                                    size="small"
                                                                    type="time"
                                                                    label="SyncAttendance"
                                                                    id="syncAttendance"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={syncAttendance}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" borderRadius={4}
                                                                        handleDiameter={22} className={classes.toggleStyle}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                        } />
                                                                    <span> Active</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}>Add New</Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                    </form>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"BiometricDeviceIPs"}
                                                    data={attendanceIPsList}
                                                    columns={[{
                                                        name: "campusName",
                                                        label: "Campus",
                                                    },
                                                    {
                                                        name: "ipAddress",
                                                        label: "IP Address",
                                                    },
                                                    {
                                                        name: "description",
                                                        label: "Port",
                                                        },
                                                        {
                                                            name: "description",
                                                            label: "Description",
                                                        },
                                                    {
                                                        name: "checkInTime",
                                                        label: "CheckInTime",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<TextField
                                                                    disabled
                                                                    size="small"
                                                                    type="time"
                                                                    value={value}
                                                                />);
                                                            }
                                                        }
                                                    },

                                                    {
                                                        name: "checkOutTime",
                                                        label: "CheckOutTime",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<TextField
                                                                    disabled
                                                                    size="small"
                                                                    type="time"
                                                                    value={value}
                                                                />);
                                                            }
                                                        }
                                                    },
                                                    {
                                                        name: "clearAttendance",
                                                        label: "Clear Attendance",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<TextField
                                                                    disabled
                                                                    size="small"
                                                                    type="time"
                                                                    value={value}
                                                                />);
                                                            }
                                                        }

                                                    },
                                                    {
                                                        name: "syncAttendance",
                                                        label: "sync Attendance",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<TextField
                                                                    disabled
                                                                    size="small"
                                                                    type="time"
                                                                    value={value}
                                                                />);
                                                            }
                                                        }
                                                    },
                                                    {
                                                        name: "connectivity",
                                                        label: "Connectivity",
                                                    },

                                                    {
                                                        name: "active",
                                                        label: "Active",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                            }
                                                        }
                                                    },
                                                    {
                                                        name: "attendanceIPId",
                                                        label: "Edit",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                            }
                                                        }
                                                    }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(AttendanceIPs);
