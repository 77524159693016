
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class ACRRemarks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            active: true,
        };
        //#endregion 
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <GridContainer>
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>ACR Remarks</h4>
                                        </CardHeader>
                                        <hr />
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                <Autocomplete
                                                    id="Department"
                                                    size="small"
                                                    renderInput={(params) => <TextField {...params}
                                                        label="Select Designation" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "1%" }}>
                                                <Button color="facebook"><SearchSharp />Search</Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        columns={[
                                            { label: "Remarks" }, { label: "Order No" },
                                            { label: "Active" }, { label: "Update" },]}
                                        options={tbleWithPrint} />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(ACRRemarks);