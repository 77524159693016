
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, FindInPage, Breadcrumbs, PermContactCalendar, Autocomplete, FormControl, InputLabel, Select, LocalLibrary, Tabs, NavigateNextIcon, Link, HomeIcon, Typography, GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class Studentissuereturnbook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regDataList: [],
            nameorfatherNIC: '',
            studentRegNo: '',
            sectionDD: [{ sectionId: -1, sectionName: 'All' }],
            sectionId: -1, sectionIdState: '', sectionIdIndex: 0,
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0, sessionId: -1, sessionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'All' }],
            campusId: 0,
            campusIdState: '',
            campusIdIndex: 0,
            classDD: [{ classId: -1, className: 'All' }],
            classId: -1, classIdState: '', classIdIndex: 0,
            startDate: new Date(), startDateState: '', endDate: new Date(), regStatus: 1,
            alert: null, show: false, generalError: true,
        };
        this.setValue = this.setValue.bind(this);
        this.setDDValue = this.setDDValue.bind(this);
        this.setDateValue = this.setDateValue.bind(this);
        this.loadDropDown();
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex })
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    loadDropDown = () => {
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sessionDD: data, sessionId: data[0]['sessionId'] });
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList = this.state.campusDD;
                allList = allList.concat(data);
                this.setState({ campusDD: allList, campusId: allList[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    fillClasses = (CampusId) => {
        fetch(Base_URL + "/api/Dropdowns/ClassDD?SessionId=" + this.state.sessionId + "&CampusId=" + CampusId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ classDD: [] })
                let allList = [{ classId: -1, className: 'All' }].concat(data);
                this.setState({
                    classDD: allList, classId: allList[0]['classId'], classIdIndex: 0,
                });
                this.fillSections(allList[0]['classId']);
            }).catch(error => {
                console.log(error);
            });
    }
    fillSections = (ClassId) => {
        fetch(Base_URL + "/api/Dropdowns/SectionDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.campusId + "&ClassId=" + ClassId + "&LevelId=-1").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectionDD: [] })
                let allList = [{ sectionId: -1, sectionName: 'All' }].concat(data);
                this.setState({ sectionDD: allList, sectionId: allList[0]['sectionId'], sectionIdIndex: 0 });

            }).catch(error => {
                console.log(error);
            });
    }
    resetClassSec = () => {
        this.setState({
            sectionDD: [{ sectionId: -1, sectionName: 'All' }],
            sectionId: -1, sectionIdIndex: 0,
            classDD: [{ classId: -1, className: 'All' }],
            classId: -1, classIdIndex: 0,
        });
    }
    searchData = () => {
        var startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
        var endDate = Moment(this.state.endDate).format('YYYY-MM-DD');
        var nameorfatherNIC = -1;
        var studentRegNo = -1;
        if (this.state.nameorfatherNIC !== '') {
            nameorfatherNIC = this.state.nameorfatherNIC;
        }
        if (this.state.studentRegNo !== '') {
            studentRegNo = this.state.studentRegNo;
        }
        fetch(Base_URL + "/api/StudentRegistration/SearchAllRegistration?regStatus=" + this.state.regStatus + "&sessionId=" + this.state.sessionId + "&campusId=" + this.state.campusId
            + "&classId=" + this.state.classId + "&sectionId=" + this.state.sectionId + "&nameorfatherNIC=" + nameorfatherNIC + "&studentRegNo=" + studentRegNo
            + "&startDate=" + startDate + "&endDate=" + endDate
        ).then(response => { return response.json(); })
            .then(data => {
                this.setState({ regDataList: data });
            }).catch(error => {
                console.log(error);
            });
    }
    LoadTextbox = () => {

    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { sessionDD, campusDD, campusIdIndex, sessionIdIndex, classIdIndex, campusIdState, classDD, classIdState, sectionDD, sectionIdIndex, studentRegNo, nameorfatherNIC, regStatus, startDate, startDateState, endDate } = this.state;
        return (
            <div>
                <GridContainer>
                    <CardHeader>   
                    <h4 className={classes.cardIconTitle}> Student Issue Return Book</h4>
                    </CardHeader>
                    <div className={classes.mainDiv}>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                    {this.state.alert}
                                    <div className={classes.mainDiv}>    
                                    <GridContainer>
                                       <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px"}}>
                                                        <CardBody>
                                                            <GridContainer>
                                                                <GridTextbox xs={12} sm={12} md={2}>
                                                                    <Autocomplete
                                                                        disableClearable
                                                                        id="sessionId"
                                                                        size="small"
                                                                        options={sessionDD}
                                                                        getOptionLabel={(option) => option.sessionName}
                                                                        value={sessionDD[sessionIdIndex]}
                                                                        onChange={(event, value) => { this.setDDValue(event, value); this.resetClassSec() }}
                                                                        renderInput={(params) => <TextField {...params} label="Session" variant="outlined" />}
                                                                    />
                                                                </GridTextbox>
                                                                <GridTextbox xs={12} sm={12} md={2}>
                                                                    <Autocomplete
                                                                        disableClearable
                                                                        id="campusId"
                                                                        size="small"
                                                                        options={campusDD}
                                                                        getOptionLabel={(option) => option.campusName}
                                                                        value={campusDD[campusIdIndex]}
                                                                        onChange={(event, value) => { this.setDDValue(event, value); this.fillClasses(value.campusId) }}
                                                                        renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                                                            error={campusIdState === "error"} label="Campus" variant="outlined" />}
                                                                    />
                                                                </GridTextbox>
                                                                <GridTextbox xs={12} sm={12} md={2}>
                                                                    <Autocomplete
                                                                        disableClearable
                                                                        id="classId"
                                                                        size="small"
                                                                        options={classDD}
                                                                        getOptionLabel={(option) => option.className}
                                                                        value={classDD[classIdIndex]}
                                                                        onChange={(event, value) => { this.setDDValue(event, value); this.fillSections(value.classId); }}
                                                                        renderInput={(params) => <TextField {...params} success={classIdState === "success"}
                                                                            error={classIdState === "error"} label="Class" variant="outlined" />}
                                                                    />
                                                                </GridTextbox>
                                                                <GridTextbox xs={12} sm={12} md={2}>
                                                                    <Autocomplete
                                                                        disableClearable
                                                                        id="sectionId"
                                                                        size="small"
                                                                        options={sectionDD}
                                                                        getOptionLabel={(option) => option.sectionName}
                                                                        value={sectionDD[sectionIdIndex]}
                                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                        renderInput={(params) => <TextField {...params} label="Section" variant="outlined" />}
                                                                    />
                                                                </GridTextbox>
                                                                <GridTextbox xs={12} sm={12} md={2}>
                                                                    <TextField
                                                                        size="small"
                                                                        label="Name /BTSC No."
                                                                        id="studentRegNo"
                                                                        variant="outlined"
                                                                        value={studentRegNo}
                                                                        fullWidth
                                                                        onChange={this.setValue}
                                                                    />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={2}>
                                                                    <Button color="facebook" onClick={() => { this.searchData() }}><SearchSharp /> Search </Button>
                                                                </GridTextbox>
                                                            </GridContainer>

                                                        </CardBody>
                                                    </Card>
                                                </GridForm>
                                       <GridForm xs={12} sm={12} md={12}>
                                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                                        <MUIDataTable
                                                            title={"Student Registration List"}
                                                            data={this.state.regDataList}
                                                            columns={[
                                                                { name: "registrationNo", label: "BTS No" },
                                                                { name: "name", label: "Name" },
                                                                { name: "sessionName", label: "Session" },
                                                                { name: "campusName", label: "Campus" },
                                                                { name: "className", label: "Class" },
                                                                { name: "sectionName", label: "Section" },
                                                                {
                                                                    name: "registrationDate", label: "Reg.Date",
                                                                    options: {
                                                                        customBodyRender: (value) => {
                                                                            return (Moment(value).format('DD-MMM-YYYY'));
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    name: "status", label: "Reg.Status",
                                                                    options: {
                                                                        customBodyRender: (value) => {
                                                                            return (value) === true ? "Active" : (value) === false ? "InActive" : "";
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    name: "paid", label: "Paid",
                                                                    options: {
                                                                        customBodyRender: (value) => {
                                                                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    name: "studentId",
                                                                    label: "Edit",
                                                                    options: {
                                                                        customBodyRender: (value) => {
                                                                            return (<Link to={{ pathname: '/app/stdeditregistration', stdIdinfo: value }}  ><EditOutlined /></Link>);
                                                                        }
                                                                    }
                                                                }
                                                            ]}
                                                            options={tbleWithPrint}
                                                        />
                                                    </MuiThemeProvider>
                                                </GridForm>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </GridContainer>
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(Studentissuereturnbook);

