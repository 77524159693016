
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon
} from '../../components/Common/ImportAll';
const monthDD = [
    { monthId: 6, monthName: '6 Month' },
    { monthId: 7, monthName: '7 Month' },
    { monthId: 8, monthName: '8 Month' },
    { monthId: 9, monthName: '9 Month' },
    { monthId: 10, monthName: '10 Month' },
    { monthId: 11, monthName: '11 Month' },
    { monthId: 12, monthName: '12 Month' },
    { monthId: 13, monthName: '13 Month' },
    { monthId: 14, monthName: '14 Month' },
    { monthId: 15, monthName: '15 Month' },
    { monthId: 16, monthName: '16 Month' },
    { monthId: 17, monthName: '17 Month' },
    { monthId: 18, monthName: '18 Month' },
    { monthId: 19, monthName: '19 Month' },
    { monthId: 20, monthName: '20 Month' },
    { monthId: 21, monthName: '21 Month' },
    { monthId: 22, monthName: '22 Month' },
    { monthId: 23, monthName: '23 Month' },
    { monthId: 24, monthName: '24 Month' },

];
var Base_URL = localStorage.BaseURL;

class UserGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regNo: "",
            regNoState: "",
            studentName: "",
            studentNameState:"",
            className: "",
            classNameState: "",
            section: "",
            sectionState: "",
            campus: "",
            campusState: "",
            noticeDate: new Date(),
            noticeDateState: "",
            freezeDate: new Date(),
            freezeDateState: "",
            unfreezeDate: new Date(),
            unfreezeDateState: "",
            //region Dropdown
            monthDD: [{ monthId: -1, monthName: 'Select' }],
            monthId: -1,
            monthIdIndex: 0,
            monthIdState: "",
            reasonDD: [{ reasonId: -1, reason: "Select" }],
            reasonId: -1,
            reasonIdIndex: 0,
            reasonIdState: "",
            //#region ignored
            usergrouparray: [],
            name: "",
            nameState: '',
            label: "Title",
            userGroupName: '',
            lmsId: -1,
            userGroupNameState: '',
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            registrationDate: new Date(),
            registrationDateState: '',
            studentDOBState: '',
            studentDOB: new Date(),
            //#endregion

        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };
    ClearTextfieldStates = () => {
        this.setState({
            lmsId: -1, dbStatus: false, userGroupName: '', registrationDate: new Date(),
            registrationDateState: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        if (this.state.userGroupName === "") {
            this.setState({ userGroupNameState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkTitle) => {
        this.setState({ [checkTitle]: event });
    }
    LoadData = () => {
        debugger;
        fetch(Base_URL + "/api/UserGroups/UserGroupList?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ usergrouparray: data });
            }).catch(error => {
                console.log(error);
            });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#region function
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    verifyDate(value) {
        debugger
        var DateRex = /\d\d\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/;
        if (DateRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type,) {
        debugger;
        switch (type) {
            case "noticeDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        noticeDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        noticeDate: event.target.value
                    });
                }
                break;
            case "freezeDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        freezeDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        freezeDate: event.target.value
                    });
                }
                break;
            case "unfreezeDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        unfreezeDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        unfreezeDate: event.target.value
                    });
                }
                break;
        }
    }
    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    #endregion
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.AddUserGroupName()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save User Group!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#endregion
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { regNo, regNoState, studentName, studentNameState, className, classNameState, section, sectionState, campus, campusState, noticeDate, noticeDateState,
            freezeDate, freezeDateState, unfreezeDate, unfreezeDateState, monthIdState, monthIdIndex, monthDD, monthName, reasonDD, reasonIdIndex, reasonIdState,
            userGroupName, userGroupNameState } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <QueuePlayNext />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Admission Freeze</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdmanagementhome" > Student Management</Link>
                            <Typography color="textPrimary">Admission Freeze</Typography>
                        </Breadcrumbs>
                        <hr />
                        <GridContainer>
                            <div className={classes.mainDiv}>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                                <CardHeader color="info" icon>
                                                    <CardIcon color="success">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Apply Admission Freeze</h4>
                                                    <hr />
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer style={{ marginleft: "1px" }}>
                                                        <GridItem xs={12} sm={12} md={8}>
                                                            <GridContainer xs={12} sm={12} md={12}>
                                                                <GridContainer style={{ marginLeft: 20 }}>
                                                                    <GridTextbox xs={12} sm={12} md={3}>
                                                                        <TextField
                                                                            success={regNoState === "success"}
                                                                            error={regNoState === "error"}
                                                                            size="small"
                                                                            label="Registration No."
                                                                            id="regNo"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            value={regNo}
                                                                            onChange={this.textChange}
                                                                        />
                                                                    </GridTextbox>
                                                                </GridContainer>
                                                            </GridContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                </CardBody>
                                                <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                                    <CardBody>
                                                        <GridContainer style={{ marginleft: "1px" }}>
                                                            <GridItem xs={12} sm={12} md={8}>
                                                                <GridContainer xs={12} sm={12} md={12}>
                                                                    <GridContainer style={{ marginLeft: 20 }}>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField
                                                                                success={studentNameState === "success"}
                                                                                error={studentNameState === "error"}
                                                                                size="small"
                                                                                label="Student Name"
                                                                                id="studentName"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                value={studentName}
                                                                                onChange={this.textChange}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField
                                                                                success={classNameState === "success"}
                                                                                error={classNameState === "error"}
                                                                                size="small"
                                                                                label="Class"
                                                                                id="className"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                value={className}
                                                                                onChange={this.textChange}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField
                                                                                success={sectionState === "success"}
                                                                                error={sectionState === "error"}
                                                                                size="small"
                                                                                label="Section"
                                                                                id="section"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                value={section}
                                                                                onChange={this.textChange}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField
                                                                                success={campusState === "success"}
                                                                                error={campusState === "error"}
                                                                                size="small"
                                                                                label="Campus"
                                                                                id="campus"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                value={campus}
                                                                                onChange={this.textChange}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField
                                                                                success={userGroupNameState === "success"}
                                                                                error={userGroupNameState === "error"}
                                                                                size="small"
                                                                                label="From Month(dropdown...)"
                                                                                id="userGroupName"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                value={userGroupName}
                                                                                onChange={this.textChange}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                                            <Autocomplete
                                                                                disableClearable
                                                                                size="small"
                                                                                options={monthDD}
                                                                                getOptionLabel={(option) => option.monthName}
                                                                                value={monthDD[monthIdIndex]}
                                                                                id="monthId"
                                                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                                renderInput={(params) => <TextField {...params} label="Upto Month" variant="outlined"
                                                                                    success={monthIdState === "success"}
                                                                                    error={monthIdState === "error"} />}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField success={noticeDateState === 'success'}
                                                                                error={noticeDateState === 'error'}
                                                                                id="noticeDate"
                                                                                size="small"
                                                                                label="Notice Date"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                type="date"
                                                                                value={Moment(noticeDate).format('YYYY-MM-DD')}
                                                                                inputProps={{ onChange: event => this.change(event, "noticeDate", "noticeDate") }}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField success={freezeDateState === 'success'}
                                                                                error={freezeDateState === "error"}
                                                                                id="freezeDate"
                                                                                size="small"
                                                                                label="Freeze Date"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                type="date"
                                                                                value={Moment(freezeDate).format('YYYY-MM-DD')}
                                                                                inputProps={{ onChange: event => this.change(event, "freezeDate", "freezeDate") }}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                                            <Autocomplete
                                                                                disableClearable
                                                                                id="reasonId"
                                                                                size="small"
                                                                                options={reasonDD}
                                                                                getOptionLabel={(option) => option.reason}
                                                                                value={reasonDD[reasonIdIndex]}
                                                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                                renderInput={(params) => <TextField {...params} success={reasonIdState === "success"}
                                                                                    error={reasonIdState === "error"} label="Freeze Reason" variant="outlined" />}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                                            <TextField success={unfreezeDateState === 'success'}
                                                                                error={unfreezeDateState === "error"}
                                                                                id="unfreezeDate"
                                                                                size="small"
                                                                                label="UnFreeze Date"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                type="date"
                                                                                value={Moment(unfreezeDate).format('YYYY-MM-DD')}
                                                                                inputProps={{ onChange: event => this.change(event, "unfreezeDate", "unfreezeDate") }}
                                                                            />
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} lg={2}>
                                                                            <Button color="facebook" onClick={this.LoadData}>UnFreeze</Button>
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} lg={2}>
                                                                            <Button color="facebook" onClick={this.LoadData}>Freeze</Button>
                                                                        </GridTextbox>
                                                                        <GridTextbox xs={12} sm={12} lg={2}>
                                                                            <Button color="facebook" onClick={this.LoadData}>Extend Date</Button>
                                                                        </GridTextbox>
                                                                    </GridContainer>
                                                                </GridContainer>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <PictureUpload />
                                                                <div style={{ textAlign: "center" }}>
                                                                    <Button color="success" ><GetAppIcon />Download</Button>
                                                                    <Button color="danger"><DeleteIcon />Remove</Button>
                                                                </div>
                                                                <hr />
                                                            </GridItem>
                                                        </GridContainer>
                                                    </CardBody>
                                                </Card>
                                            </Card>
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>
                            </div>
                        </GridContainer>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(UserGroups);
