import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined,
     GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    axios, SearchSharp, Autocomplete
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class StdClasses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //#region Datatable Columns
            columns: [
                {
                    name: "levelName",
                    label: "Level"

                },{
                name: "className",
                label: "Class Name",

            }, {
                name: "nickName",
                label: "Nick Name",
            },
            {
                name: "classOrder",
                label: "Class Order",

            }, {
                name: "active",
                label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }

            },
            {
                name: "campusClub",
                label: "Campus Club",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }

            },
            {
                name: "sectionClub",
                label: "Section Club",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }

            },
            {
                name: "classId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false, searchCheck: true,
            classList: [], classId: -1, className: '', classNameState: '', nickName: '', nickNameState: '', campusClub: true, sectionClub: true, active: true,
            levelDD: [{ levelId: -1, levelName: 'Select' }],
            levelId: -1,
            levelIdState: '',
            levelIndex: 0,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    componentDidMount() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/LevelDD").then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ levelDD: data, levelId: data[0]['levelId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.className === "") {
            this.setState({ classNameState: "error" });
            return;
        }
        else if (this.state.nickName === "") {
            this.setState({ nickNameState: "error" });
            return;
        }
        else if (this.state.levelId === 0) {
            this.setState({ levelIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }

    SaveUpdateClass = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/StudentPrimaryData/SaveUpdateClass?ClassId=" + this.state.classId + "&ClassName=" + this.state.className + "&NickName=" + this.state.nickName + "&CampusClub=" + this.state.campusClub + "&SectionClub=" + this.state.sectionClub + "&Active=" + this.state.active + "&LevelId=" + this.state.levelId)
            .then(json => {
                debugger;
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox(classId) {
        debugger;
        let obj = this.state.classList.find(o => o.classId === classId);
        this.setState({ className: obj.className, levelName: obj.levelName, levelId: obj.levelId, nickName: obj.nickName, sectorClub: obj.campusClub, sectionClub: obj.sectionClub, active: obj.active, classId: obj.classId, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ levelIndex: this.state.levelDD.findIndex(x => x.levelId === obj.levelId) });
    }
    ClearTextfieldStates = () => {
        this.setState({ levelId: 0, levelIdState: '', levelIndex: -1, classId: -1, className: '', classNameState: '', nickName: '', nickNameState: '', campusClub: false, sectionClub: false, active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', });
        this.hideAlert();
        this.LoadData();
    }

    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }

    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    //#endregion 

    //#region Backend Data Fetching and Saving

    LoadData = () => {
        debugger;
        fetch(Base_URL + "/StudentPrimaryData/LoadClasses?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ classList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateClass()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    closeOnClickOutside={false}
                    showCancel
                >
                    Do you want to save class detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { classList, columns, className, classNameState, campusClub, sectionClub, nickName, nickNameState, active, searchCheck,
            levelDD, levelIndex, levelIdState } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Class</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="levelId"
                                                        size="small"
                                                        options={levelDD}
                                                        getOptionLabel={(option) => option.levelName}
                                                        value={levelDD[levelIndex]}
                                                        onChange={(event, value) => { this.setState({ levelId: value.levelId, levelIdState: "success", levelIndex: levelDD.findIndex(x => x.levelId === value.levelId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={levelIdState === "success"}
                                                            error={levelIdState === "error"} label="Level Name" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={classNameState === "success"}
                                                        error={classNameState === "error"}
                                                        size="small"
                                                        label="Class Name"
                                                        id="className"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={className}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={nickNameState === "success"}
                                                        error={nickNameState === "error"}
                                                        size="small"
                                                        label="Nick Name"
                                                        id="nickName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={nickName}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "campusClub")} checked={campusClub} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Campus Club</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "sectionClub")} checked={sectionClub} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Section Club</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Class List"}
                                        data={classList}
                                        columns={columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdClasses);
