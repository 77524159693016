import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
//import { Link } from "react-router-dom";
import { InputLabel, FormControl, Select } from '@material-ui/core';
import { ContactMailOutlined as ParentIcon, SaveOutlined, CastForEducation as StdIcon } from "@material-ui/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SweetAlert from "react-bootstrap-sweetalert";
import ReactToggle from "react-switch";
import TextField from '@material-ui/core/TextField';
//import axios from 'axios';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
//import InputMask from 'react-input-mask';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { formStyle} from "assets/jss/customStyle/allCustomStyle";
import Moment from 'moment';
import axios from "axios";
import swal from 'sweetalert';
//Import from Globel Component";
import { AllDropDowns, validateCheckSetVal, InputMask } from '../../../components/Common/ImportAll';


var Base_URL = localStorage.BaseURL;
const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}
class EditRegistration extends React.Component {
    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        this.state = {
            dropdownLoading: true,
           // parentId: editRegData[0]["ParentId"],
            studentDOBState: '',
            studentDOB: editRegData[0]["dob"],
            studentAge: '',
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionId: editRegData[0]["sectionId"],
            sectionIdIndex: 0,
            sectionIdState: '',
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionId: editRegData[0]["sessionId"],
            sessionIdIndex: 0,
            sessionIdState: '',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: editRegData[0]["campusId"],
            campusIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classId: editRegData[0]["classId"],
            classIdState: '',
            classIdIndex: 0,
            fatherName: editRegData[0]["fatherName"],
            fatherNIC: editRegData[0]["fatherNIC"],
            fatherMobileNo: editRegData[0]["fatherMobileNo"],
            fatherEmail: editRegData[0]["fatherEmail"],
            motherName: editRegData[0]["motherName"],
            motherNIC: editRegData[0]["motherNIC"],
            motherMobileNo: editRegData[0]["motherMobileNo"],
            motherEmail: editRegData[0]["motherEmail"],
            registrationDate: editRegData[0]["registrationDate"],
            studentName: editRegData[0]["name"],
            overSeas: (editRegData[0]["overseas"] === "True") ? true : false,
            newgenderId: editRegData[0]["gender"],
            genderDD: [{ genderId: -1, name: "Select" }],
            genderIdIndex: 0,
            genderIdState: "",
            genderId: 0,
            registrationNo: editRegData[0]["registrationNo"],
            registrationNoState:"",
            fatherNameState: "",
            fatherNICState: "",
            fatherMobileNoState: "",
            fatherEmailState: "",
            motherNameState: "",
            motherNICState: "",
            motherMobileNoState: "",
            motherEmailState: "",
            registrationDateState: '',
            studentNameState: "",
            saveUpdateBtnDisabled: false,
            alert: null, show: false, generalError: false,
            registrationId: editRegData[0]["registrationId"],
            ShowSession: true,
            ShowCampus: true,
            ShowClass: true, 
           //ShowSection:false,

        }
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.LoadRegData = this.LoadRegData.bind(this);
         this.handleChange = this.handleChange.bind(this);
    };
    //#region Component
    componentDidMount() {
        this.loadGenderDD()
    }
    async loadGenderDD() {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/GenderDD"))
            if (response.ok) {
                debugger;
                const data = await response.json();
                this.setState({
                    genderDD: data.genderDDList
                });
                if (this.state.newgenderId !== "" && this.state.newgenderId !== null && this.state.newgenderId !== -1 && typeof this.state.newgenderId !== 'undefined') {

                    var newindex = this.state.genderDD.findIndex(x => x.genderId == this.state.newgenderId);
                    this.setState({
                        genderId: this.state.newgenderId,
                        genderIdState: "success",
                        genderIdIndex: newindex
                    });
                }
                else {
                    this.setState({
                        genderId: -1,
                        genderIdIndex: -1,
                        genderIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    //#endregion
    //#region Dropdowns
    handleChange(event, value) {
        const stateName = event.target.id.split("-")[0];
        this.setState({
            [stateName]: value[stateName],
            [stateName + "State"]: "success"
        })
    }
    change(stateName, value) {
        this.setState({
            stateName: value[stateName],
            [stateName + "Index"]: this.state.genderDD.findIndex(x => x[stateName] === value[stateName]),
            genderId: value.genderId,
            [stateName + "State"]: "success"
        })
    }
    //#endregion
    //#region function
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, generalError: false });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({
            [checkName]: event,
            fatherNIC: "",
            motherNIC: "",
        });
    }
    //#endregion
    ageCalculate(dob) {
        var todate = new Date()
        var fromdate = new Date(dob)
        var y = [todate.getFullYear(), fromdate.getFullYear()],
            ydiff = y[0] - y[1],
            m = [todate.getMonth(), fromdate.getMonth()],
            mdiff = m[0] - m[1],
            d = [todate.getDate(), fromdate.getDate()],
            ddiff = d[0] - d[1];
        if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) {
            --ydiff;
        }
        if (mdiff < 0) {
            mdiff += 12;
        }
        if (ddiff < 0) {
            fromdate.setMonth(m[1] + 1, 0)
            ddiff = fromdate.getDate() - d[1] + d[0]
            --mdiff;
            if (mdiff < 0) {
                mdiff += 12;
            }
        }
        return ydiff + " y " + mdiff + " m " + ddiff + " d";
    }
    
   
    changeValue = (event, type) => {
        debugger;
        switch (type) {
            case "studentDOB":
                var stddate = event.target.value
                this.setState({ studentDOB: stddate, studentAge: this.ageCalculate(stddate) })
                break;
        }
    }
    //#region save
    editStudent() {
        var message = '';
         if (this.state.sessionId == null) {
            message = "Please select Session";
        }
       else if (this.state.campusId == null) {
            message = "Please select Campus";
        }
       else if (this.state.classId == null) {
            message = "Please select Class";
        }
        else if (this.state.fatherName == '') {
            message = "Please Enter Father Name";
        }
        else if (this.state.fatherNIC == '') {
             message = "Please Enter Father CNIC";
        }
        else if (this.state.fatherMobileNo == '') {
            message = "Please Enter Father Mobile Number";
        }
        else if (this.state.fatherEmail == '') {
            message = "Please Enter Father Email";
        }
        else if (this.state.motherNIC == '') {
            message = "Please Enter Mother Email";
        }
        else if (this.state.motherMobileNo == '') {
            message = "Please Enter Mother Mobile Number";
        }
        else if (this.state.motherEmail == '') {
            message = "Please Enter Mother Email";
        }
        else if (this.state.motherName == '') {
            message = "Please Enter Mother Name";
        }
        else if (this.state.registrationDate == '') {
            message = "Please Enter Registration Date";
        }
        else if (this.state.studentName == '') {
            message = "Please Enter Student Name";
        }
       if (message != '') {
            swal({
                title: "Error",
                text: message,
                icon: "error",
                button: "OK"
            });
        }
       else {
           debugger;
           axios.post(Base_URL + "/api/StudentRegistration/EditStudentRegistration?fatherNIC=" + this.state.fatherNIC + "&fatherName=" + this.state.fatherName + "&fatherEmail=" + this.state.fatherEmail + "&fatherMobileNo=" + this.state.fatherMobileNo + "&motherNIC=" + this.state.motherNIC + "&motherName=" + this.state.motherName + "&motherEmail=" + this.state.motherEmail + "&motherMobileNo=" + this.state.motherMobileNo + "&studentName=" + this.state.studentName + "&registrationDate=" + this.state.registrationDate + "&studentDOB=" + this.state.studentDOB + "&gender=" + this.state.genderId + "&sessionId=" + this.state.sessionId + "&campusId=" + this.state.campusId + "&classId=" + this.state.classId + "&sectionId=" + this.state.sectionId + "&overSeas=" + this.state.overSeas + "&RegistrationId=" + this.state.registrationId + "&regStatus=true")
               .then(json => {
                   debugger;
                   if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                       this.AlertMessage(json.data.vmResponse.status, json.data.vmResponse.message);
                   }
                   else {
                       this.AlertMessage("Error", json.data.exception.displayMessage);
                   }
               })
        }

        }
   //#endregion     
       
    async CheckAndSetStates(event, checkName) {
        debugger
        switch (checkName) {
            case "fatherNIC":
                debugger;
                this.state.fatherNIC = event.target.value
                checkName = "checkNIC"
                break;
            case "motherNIC":
                debugger;
                this.state.motherNIC = event.target.value
                checkName = "checkNIC"
                break;
            case "fathermobileNumber":
                debugger;
                this.state.fatherMobileNo = event.target.value
                checkName = "mobileNumber"
                break;
            case "mothermobileNumber":
                debugger;
                this.state.motherMobileNo = event.target.value
                checkName = "mobileNumber"
                break;
        }
        var rtnValue = await validateCheckSetVal(event, checkName, this.state);
        debugger
        for (var x = 0; x < rtnValue.length; x++) {
            debugger
            var statename = rtnValue[x].StateName;
            var statevalue = rtnValue[x].StateValue;
            this.setState({ [statename]: statevalue });
        }
    }
    

    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this[funName]()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region this Function is Called after Updating data
    LoadRegData() {
        this.hideAlert();
        this.props.LoadRegData(); //Function in StdEditRegistration.jsx
    }
    //#endregion

   
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { overSeas, registrationDateState, studentDOBState } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="info" icon>
                                        <CardIcon color="success">
                                            <ParentIcon />
                                        </CardIcon>
                                        <h4 className={classes.cardIconTitle}>Parent Information</h4>
                                        <hr />
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            {this.state.overSeas === false ?
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <InputMask
                                                        {...this.props}
                                                        mask="99999-9999999-9"
                                                        maskChar=""
                                                        value={this.state.fatherNIC}
                                                    >
                                                        {() => <TextField
                                                            success={this.state.fatherNICState === "success"}
                                                            error={this.state.fatherNICState === "error"}
                                                            size="small"
                                                            label="Father CNIC"
                                                            id="fatherNIC"
                                                            variant="outlined"
                                                            fullWidth
                                                            required={true}
                                                            autoComplete="off"
                                                            placeholder="00000-0000000-0"
                                                            inputProps={{
                                                                onChange: event =>
                                                                    this.CheckAndSetStates(event, "fatherNIC"),
                                                                maxLength: 15,
                                                            }}
                                                        />
                                                        }
                                                    </InputMask>
                                                </GridTextbox> :
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        success={this.state.fatherNICState === "success"}
                                                        error={this.state.fatherNICState === "error"}
                                                        value={this.state.fatherNIC}
                                                        size="small"
                                                        label="Father CNIC"
                                                        id="fatherNIC"
                                                        variant="outlined"
                                                        fullWidth
                                                        required={true}
                                                        autoComplete="off"
                                                        inputProps={{
                                                            onChange: event =>
                                                                this.CheckAndSetStates(event, "fatherNIC"),
                                                            maxLength: 15,
                                                        }}
                                                    />
                                                </GridTextbox>
                                            }
                                            {this.state.overSeas === false ?
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <InputMask
                                                        {...this.props}
                                                        mask="99999-9999999-9"
                                                        maskChar=""
                                                        value={this.state.motherNIC}
                                                    >
                                                        {() => <TextField
                                                            success={this.state.motherNICState === "success"}
                                                            error={this.state.motherNICState === "error"}
                                                            size="small"
                                                            label="Mother CNIC"
                                                            id="motherNIC"
                                                            variant="outlined"
                                                            fullWidth
                                                            required={true}
                                                            autoComplete="off"
                                                            placeholder="00000-0000000-0"
                                                            inputProps={{
                                                                onChange: event =>
                                                                    this.CheckAndSetStates(event, "motherNIC"),
                                                                maxLength: 15,
                                                            }}
                                                        />
                                                        }
                                                    </InputMask>
                                                </GridTextbox> :
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        success={this.state.motherNICState === "success"}
                                                        error={this.state.motherNICState === "error"}
                                                        value={this.state.motherNIC}
                                                        size="small"
                                                        label="Mother CNIC"
                                                        id="motherNIC"
                                                        variant="outlined"
                                                        fullWidth
                                                        required={true}
                                                        autoComplete="off"

                                                        inputProps={{
                                                            onChange: event =>
                                                                this.CheckAndSetStates(event, "motherNIC"),
                                                            maxLength: 15,
                                                        }}
                                                    />
                                                </GridTextbox>
                                            }
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField
                                                    success={this.state.fatherNameState === "success"}
                                                    error={this.state.fatherNameState === "error"}
                                                    id="fatherName"
                                                    size="small"
                                                    label="Father Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.fatherName}
                                                    onChange={this.setValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField
                                                    success={this.state.motherNameState === "success"}
                                                    error={this.state.motherNameState === "error"}
                                                    id="motherName"
                                                    size="small"
                                                    label="Mother Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.motherName}
                                                    onChange={this.setValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <InputMask
                                                    {...this.props}
                                                    mask="\3999999999"
                                                    maskChar=""
                                                    value={this.state.fatherMobileNo}
                                                >
                                                    {() => <TextField
                                                        success={this.state.fatherMobileNoState === "success"}
                                                        error={this.state.fatherMobileNoState === "error"}
                                                        size="small"
                                                        label="Father Mobile No"
                                                        id="fatherMobileNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        required={true}
                                                        autoComplete="off"
                                                        placeholder="3001234567"
                                                        inputProps={{
                                                            onChange: event =>
                                                                this.CheckAndSetStates(event, "fathermobileNumber"),
                                                            maxLength: 10,
                                                        }}
                                                    // onInput={(e) => {
                                                    //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    // }}
                                                    />
                                                    }
                                                </InputMask>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <InputMask
                                                    {...this.props}
                                                    mask="\3999999999"
                                                    maskChar=""
                                                    value={this.state.motherMobileNo}
                                                >
                                                    {() => <TextField
                                                        success={this.state.motherMobileNoState === "success"}
                                                        error={this.state.motherMobileNoState === "error"}
                                                        size="small"
                                                        label="Mother Mobile No"
                                                        id="motherMobileNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        required={true}
                                                        autoComplete="off"
                                                        placeholder="3001234567"
                                                        inputProps={{
                                                            onChange: event =>
                                                                this.CheckAndSetStates(event, "mothermobileNumber"),
                                                            maxLength: 10,
                                                        }}
                                                    />
                                                    }
                                                </InputMask>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField
                                                    success={this.state.fatherEmailState === "success"}
                                                    error={this.state.fatherEmailState === "error"}
                                                    id="fatherEmail"
                                                    size="small"
                                                    label="Father Email"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder={"abcd@gmail.com"}
                                                    value={this.state.fatherEmail}
                                                    onChange={(event, value) => { this.CheckAndSetStates(event, "email") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField
                                                    success={this.state.motherEmailState === "success"}
                                                    error={this.state.motherEmailState === "error"}
                                                    id="motherEmail"
                                                    size="small"
                                                    label="Mother Email"
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder={"abcd@gmail.com"}
                                                    value={this.state.motherEmail}
                                                    onChange={(event, value) => { this.CheckAndSetStates(event, "email") }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={6} md={6}>
                                <Card>
                                    <CardHeader color="info" icon>
                                        <CardIcon color="info">
                                            <StdIcon />
                                        </CardIcon>
                                        <h4 className={classes.cardIconTitle}>Student Registration Detail</h4>
                                        <hr />
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={8}>
                                                <TextField
                                                    success={this.state.registrationNoState === "success"}
                                                    error={this.state.registrationNoState === "error"}
                                                    id="registrationNo"
                                                    size="small"
                                                    label="Registration No"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.registrationNo}
                                                    onChange={this.setValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={8}>
                                                <TextField
                                                    success={this.state.studentNameState === "success"}
                                                    error={this.state.studentNameState === "error"}
                                                    id="studentName"
                                                    size="small"
                                                    label="Student Name(as Form B)"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.studentName}
                                                    onChange={this.setValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.genderDD}
                                                    getOptionLabel={(option) => option.name}
                                                    value={this.state.genderDD[this.state.genderIdIndex]}
                                                    id="genderId"
                                                    disableClearable={true}
                                                    disabled={this.state.genderDisabled}
                                                    onChange={(event, value) => this.change("genderId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Gender *" variant="outlined"
                                                        success={this.state.genderState === "success"}
                                                        error={this.state.genderState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField success={registrationDateState === 'success'}
                                                    error={registrationDateState === 'error'}
                                                    id="registrationDate"
                                                    size="small"
                                                    label="Registration Date"
                                                    variant="outlined"
                                                    fullWidth
                                                    type="date"
                                                    value={Moment(this.state.registrationDate).format('YYYY-MM-DD')}
                                                    onChange={this.setValue}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField success={studentDOBState === 'success'}
                                                    error={studentDOBState === "error"}
                                                    id="studentDOB"
                                                    size="small"
                                                    label={"DOB(as Form B): " + this.state.studentAge}
                                                    variant="outlined"
                                                    fullWidth
                                                    type="date"
                                                    value={Moment(this.state.studentDOB).format('YYYY-MM-DD')}
                                                    onChange={(event, value) => { this.CheckAndSetStates(event, "studentDOB") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={12} lg={12}>
                                                <AllDropDowns data={this.state} xsm={12} smm={4} mdm={4} lgm={4} handleStateChange={this.handleChange} />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={3} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "overSeas")} value={overSeas}
                                                        checked={overSeas}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Overseas</span>
                                                </label>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "45%" }}>
                                <Button color="facebook" disabled={this.state.saveUpdateBtnDisabled} onClick={() => this.editStudent()}><SaveOutlined /> Update </Button>
                            </GridTextbox>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(EditRegistration);
