//Import from Globel Component";
import {
    React, axios, Moment, ReactToggle, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;
class FuelAllowance extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            hideFuelTypeDD: 'none',
            fuelID: 0,
            FuelAllowanceList: [],
            amount: "",
            amountIdState: "",
            fromDate: new Date(),
            fromDateState: "",
            status: true,
            fuelTypeId: "",
            fuelTypeIdIndex: 0,
            fuelTypeIdState: "",
            typeId: "",
            typeIdIndex: 0,
            typeIdState: "",
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            FuelQtyAmountDD: [{ typeId: -1, Type: "Select" }],
            FuelTypeDD: [{ fuelTypeId: -1, fuelType: "Select" }],
            generalError: false, alert: null,
        }
    }
    async componentDidMount() {
        debugger
        this.LoadFuelAllowanceDD();
        this.LoadFuelQtyAmountDD();
        this.LoadFuelAllowanceData();
    }
    async LoadFuelAllowanceDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/FuelAllowanceDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ fuelTypeId: -1, fuelType: "Select" });
                allList = allList.concat(data.fuelAllowanceDDList);
                this.setState({
                    FuelTypeDD: allList
                });
                if (data.length > 0) {
                    this.setState({
                        fuelTypeId: this.state.FuelTypeDD.find(x => x.fuelTypeId === this.state.fuelTypeId),
                        fuelTypeIdIndex: this.state.FuelTypeDD.findIndex(x => x.fuelTypeId === this.state.fuelTypeId),
                        fuelTypeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        fuelTypeId: allList[0]['fuelTypeId'],
                        fuelTypeIdIndex: 0,
                        fuelTypeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadFuelQtyAmountDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/FuelQtyAmountDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    FuelQtyAmountDD: data.fuelQtyAmountDDList
                });
                if (data.fuelQtyAmountDDList.length > 0) {
                    this.setState({
                        typeId: data.fuelQtyAmountDDList[0].typeId,
                        typeIdIndex: 0,
                        typeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        typeId: 0,
                        typeIdIndex: -1,
                        typeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadFuelAllowanceData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadFuelAllowanceData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    FuelAllowanceList: data.fuelAllowancedataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    SaveFuelAllowanceData = () => {
        debugger;
        if ((this.state.amount <= 0) || (this.state.amountIdState === "error")) {
            this.setState({ amountIdState: "error", generalError: true });
            return;
        }
        else if (this.state.StartDate === "" || this.state.fromDateState === "error") {
            this.setState({ fromDateState: "error", generalError: true });
            return;
        }
        else if (this.state.typeId === "" || this.state.typeId == 0 || this.state.typeIdState === "error") {
            this.setState({ typeIdState: "error", generalError: true });
            return;
        }
        else if (this.state.fuelTypeId === 0) {
            this.setState({ fuelTypeIdState: "error", generalError: true });
            return;
        }
        if (this.state.fuelTypeId === "" || (this.state.fuelTypeId === -1)) {

            this.state.fuelTypeId = null;
        }
        let FuelAllowanceInfo = {
            ID: this.state.fuelID,
            EmployeeId: this.state.employeeId,
            FuelTypeId: this.state.fuelTypeId,
            TypeId: this.state.typeId,
            AmountOrQty: parseInt(this.state.amount),
            StartDate: Moment(this.state.fromDate).format('YYYY-MM-DD'),
            Status: this.state.status,
        };
        console.log(FuelAllowanceInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeFuelAllowance', FuelAllowanceInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.FuelAllowanceList.find(o => o.id === id);
        if (obj != null) {
            this.setState({
                fuelID: obj.id, typeId: obj.typeId, amount: obj.amountOrQty, fromDate: Moment(obj.startDate).format('YYYY-MM-DD'), status: obj.status, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearincTextBtn: 'block',
                typeIdIndex: this.state.FuelQtyAmountDD.findIndex(o => o.typeId === obj.typeId),
            });
        }
        else {
            this.setState({
                fuelID: 0,
                amount: "",
                status: true,
                fromDate: new Date(),
            });
            // this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
        if (this.state.FuelTypeDD.find(x => x.fuelTypeId === obj.fuelTypeId) != null) {
            this.setState({
                hideFuelTypeDD: 'block',
            });
            this.state.fuelTypeId = obj.fuelTypeId;
            this.state.fuelTypeIdIndex = this.state.FuelTypeDD.findIndex(x => x.fuelTypeId === obj.fuelTypeId);
            this.state.fuelTypeIdState = "success";
        }
        else {
            this.setState({
                hideFuelTypeDD: 'none',
                fuelTypeId: -1,
                fuelTypeIdIndex: 0,
            });
        }
    }
    AlertMessage(status, msg) {
        debugger
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ),
                saveUpdateBtnDisabled: false,
                saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            alert: null,
            fuelID: 0,
            amount: "",
            fromDate: new Date(),
            saveUpdateBtnText: 'save',
            clearTextBtn: 'none',
            hideFuelTypeDD: 'none',
            fuelTypeId: -1,

        });
        this.LoadFuelQtyAmountDD();
        this.LoadFuelAllowanceData();
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    verifyNumber(value) {
        debugger
        var FuelRex = /^[0-9]+$/;
        if (FuelRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "typeId":
                debugger
                if (this.state.FuelAllowanceList.find(x => x.typeId === value.typeId)) {

                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.FuelQtyAmountDD.findIndex(x => x[stateName] === value[stateName]),
                        typeId: value.typeId,
                        [stateName + "State"]: "success"
                    });
                    let typeName = this.state.FuelQtyAmountDD.find(x => x[stateName] === value[stateName]).type;
                    if (typeName === 'Liter' || typeName === 'liter') {
                        this.setState({
                            hideFuelTypeDD: 'block',
                            fuelTypeId: 0,
                        });
                    }
                    else {
                        this.setState({
                            fuelTypeId: -1,
                            fuelTypeIdIndex: 0,
                            hideFuelTypeDD: 'none'
                        });
                    }

                }
                break;
            case "fuelTypeId":
                debugger
                if (this.state.FuelAllowanceList.find(x => x.fuelTypeId === value.fuelTypeId)) {

                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.FuelTypeDD.findIndex(x => x[stateName] === value[stateName]),
                        fuelTypeId: value.fuelTypeId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "amountId":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        amount: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        amount: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "fromDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "checkbox":
                this.setState({ [stateName]: event });
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { FuelAllowanceList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Fuel Allowance</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2} >
                                        <Autocomplete
                                            size="small"
                                            options={this.state.FuelQtyAmountDD}
                                            getOptionLabel={(option) => option.type}
                                            id="typeId"
                                            value={this.state.FuelQtyAmountDD[this.state.typeIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "typeId", "typeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Fuel Type" variant="outlined"
                                                success={this.state.typeIdState === "success"}
                                                error={this.state.typeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.hideFuelTypeDD + '' }}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.FuelTypeDD}
                                            getOptionLabel={(option) => option.fuelType}
                                            id="fuelTypeId"
                                            value={this.state.FuelTypeDD[this.state.fuelTypeIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "fuelTypeId", "fuelTypeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Type " variant="outlined"
                                                success={this.state.fuelTypeIdState === "success"}
                                                error={this.state.fuelTypeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.amountIdState === "success"}
                                            error={this.state.amountIdState === "error"}
                                            value={this.state.amount}
                                            size="small"
                                            label="Amount/QTY"
                                            id="amountId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "amountId", "amountId", value, 10),
                                                type: "text",
                                                maxLength: 1000000
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.fromDateState === "success"}
                                            error={this.state.fromDateState === "error"}
                                            size="small"
                                            label="Effect From Date"
                                            id="fromDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "status", "checkbox")}
                                                checked={this.state.status}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span>Active</span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <Button color="facebook" onClick={() => { this.SaveFuelAllowanceData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Fuel Allowance"}
                                    data={FuelAllowanceList}
                                    columns={[
                                        { name: "fuelType", label: "Fuel" },
                                        { name: "type", label: "Fuel Type" },
                                        { name: "amountOrQty", label: "Amount Or Qty" },
                                        { name: "startDate", label: "Effect From Date" },
                                        {
                                            name: "status",
                                            label: "Active",
                                            options: {
                                                filter: true,
                                                customBodyRender: (value) => {
                                                    return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                }
                                            }
                                        },
                                        {
                                            name: "id",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        }
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(FuelAllowance);