//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';
const BankTypeDD = [
    { bankTypeId: 'Bank', value: 'Bank' },
    { bankTypeId: 'Cash', value: 'Cash' },
]
const BankNameDD = [
    { bankNameId: 'Askari Bank', value: 'Askari Bank' },
    { bankNameId: 'Sindh Bank', value: 'Sindh Bank' },
    { bankNameId: 'Allied Bank', value: 'Allied Bank' },
    { bankNameId: 'United Bank', value: 'United Bank' },
]

var Base_URL = localStorage.BaseURL;
class Salary extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            salaryId: 0,
            incId: 0,
            eductionList: [],
            experienceList: [],
            AllowanceList: [],
            IncrementList: [],
            BankDetailList: [],
            boardName: "",
            boardNameState: "",
            years: "",
            yearsState: "",
            allDedId: "",
            allDedIdIndex: 0,
            allDedIdState: "",
            bankAccId: "",
            bankAccIdIndex: 0,
            bankAccIdState: "",
            bankTypeId: "Bank",
            bankTypeIdIndex: 0,
            bankTypeIdState: "success",
            bankNameId: "Askari Bank",
            bankNameIdIndex: 0,
            bankNameIdStatus: "success",
            degreeId: "",
            degreeIdIndex: 0,
            degreeIdState: "",
            organizationName: "",
            organizationNameState: "",
            amount: "",
            amountIdState: "",
            incAmount: "",
            incAmountIdState: "",
            accountNo: "",
            accountNoState: "",
            designationName: "",
            isChecked: true,
            isNotChecked: false,
            isdisabled: false,
            designationNameState: "",
            fromDate: new Date(),
            fromDateState: "",
            insDate: new Date(),
            insDateState: "",
            saveUpdateAlloBtnText: 'Save',
            saveUpdateAlloBtnDisabled: false,
            clearAlloTextBtn: 'none',
            saveUpdateIncBtnText: 'Save',
            saveUpdateincBtnDisabled: false,
            clearincTextBtn: 'none',
            AllDedDD: [{ allDedId: -1, allDedName: "Select" }],
            DegreeDD: [{ degreeId: -1, degreeName: "Select" }],
            BankAccDD: [{ bankAccId: -1, title: "Select" }],
            BankTypeDD: [{ bankTypeId: 0, name: "Select" }],
            BankNameDD: [{ bankNameId: 0, name: "Select" }],
            alert: null, show: false, generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadAllowanceDeductionDD();
        this.LoadBankAccDD();
        this.LoadAllowanceData();
        this.LoadIncrementData()
        this.LoadBankDetailData();
    }
    async LoadAllowanceDeductionDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/AllowanceDeductionDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AllDedDD: data.allowanceDeductionDDList
                });
                if (data.allowanceDeductionDDList.length > 0) {
                    this.setState({
                        allDedId: data.allowanceDeductionDDList[0].allDedId,
                        allDedIdState: "success"
                    });
                }
                else {
                    this.setState({
                        allDedId: 0,
                        allDedIdIndex: -1,
                        allDedIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadBankAccDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/BankAccDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    BankAccDD: data.bankAccDDList
                });
                if (data.bankAccDDList.length > 0) {
                    this.setState({
                        bankAccId: data.bankAccDDList[0].bankAccId,
                        bankAccIdIndex: 0,
                        bankAccIdState: "success"
                    });
                }
                else {
                    this.setState({
                        bankAccId: 0,
                        bankAccIdIndex: -1,
                        bankAccIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadAllowanceData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadAllowanceData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    AllowanceList: data.allowancedataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadIncrementData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadIncrementData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    IncrementList: data.incrementdataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadBankDetailData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadBankDetailData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    BankDetailList: data.bankDetaildataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    setDDValue = (event, value) => {
        debugger;
        let stateName = event.target.id.split("-")[0];
        let dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    SaveAllowanceData = () => {
        debugger;
        if ((this.state.allDedId === 0) || (this.state.allDedIdState === "error")) {
            this.setState({ allDedIdState: "error", generalError: true });
            return;
        }
        else if (this.state.amount <= 0 || this.state.amount === "" || this.state.amountIdState === "error") {
            this.setState({ amountIdState: "error", generalError: true });
            return;
        }
        let AllowanceInfo = {
            AllowanceId: this.state.salaryId,
            EmployeeId: this.state.employeeId,
            AlDedId: this.state.allDedId,
            Amount: parseInt(this.state.amount),
        };
        console.log(AllowanceInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeAllowance', AllowanceInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveIncrementData = () => {
        debugger;
        if (this.state.incAmount === null || this.state.incAmount <= 0 || this.state.incAmount === "" || this.state.amountIdState === "error") {
            this.setState({ incAmountIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.insDate == null) || (this.state.insDateState === "error")) {
            this.setState({ insDateState: "error", generalError: true });
            return;
        }
        let IncrementInfo = {
            IncId: this.state.incId,
            EmployeeId: this.state.employeeId,
            IncDate: Moment(this.state.insDate).format('YYYY-MM-DD'),
            IncAmount: parseInt(this.state.incAmount),
        };
        console.log(IncrementInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeIncrement', IncrementInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    SaveBankDetailData = () => {
        debugger;
        if (this.state.bankTypeId === "Bank") {

            if ((this.state.bankTypeId == "" || this.state.bankTypeId == null) || (this.state.bankTypeIdState === "error")) {
                this.setState({ bankTypeIdState: "error", generalError: true });
                return;
            }
            else if ((this.state.bankAccId == 0) || (this.state.bankAccIdState === "error")) {
                this.setState({ bankAccIdState: "error", generalError: true });
                return;
            }
            else if ((this.state.accountNo == "" || this.state.accountNo == null) || (this.state.accountNoState === "error")) {
                this.setState({ accountNoState: "error", generalError: true });
                return;
            }
        }
        else {
            if ((this.state.bankTypeId == "" || this.state.bankTypeId == null) || (this.state.bankTypeIdState === "error")) {
                this.setState({ bankTypeIdState: "error", generalError: true });
                return;
            }
            this.setState({
                accountNo: "",
            });
            this.state.bankNameId = "";
            this.state.bankAccId = "";
        }
        let BankDetailInfo = {
            EmployeeId: this.state.employeeId,
            BankName: this.state.bankNameId,
            BankAccountNo: this.state.accountNo,
            BankAccountId: parseInt(this.state.bankAccId),
            BankOrCash: this.state.bankTypeId,
        };
        console.log(BankDetailInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeBankDetail', BankDetailInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.AllowanceList.find(o => o.allDedId === id);
        this.setState({ salaryId: obj.allowanceId, educationId: id, allDedId: obj.allDedId, amount: obj.amount, amountIdState: "success", saveUpdateAlloBtnDisabled: false, saveUpdateAlloBtnText: 'Update', clearAlloTextBtn: 'block' });
        if (this.state.AllDedDD.find(x => x.allDedId === obj.allDedId) != null) {
            this.setState({
                allDedId: obj.allDedId,
                allDedIdIndex: this.state.AllDedDD.findIndex(x => x.allDedId === obj.allDedId),
                allDedIdState: "success"
            });
        }
        else {
            this.setState({
                allDedId: -1,
                allDedIdIdIndex: 0,
                allDedIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    LoadTextboxInc = (id) => {
        debugger
        let obj = this.state.IncrementList.find(o => o.incId === id);
        if (obj != null) {
            this.setState({
                incId: obj.incId, incAmount: obj.incAmount, insDate: Moment(obj.incDate).format('YYYY-MM-DD'), incAmountIdState: "success", saveUpdateincBtnDisabled: false, saveUpdateIncBtnText: 'Update', clearincTextBtn: 'block'
            });
        }

        else {
            this.setState({
                incId: 0,
                incAmount: "",
                insDate: new Date(),
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }

    AlertMessage(status, message) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ),
                saveUpdateincBtnDisabled: false,
                saveUpdateAlloBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        debugger;
        this.setState({
            salaryId: 0,
            incId: 0,
            alert: null,
            allDedId: 1,
            allDedIdIndex: 0,
            amount: "",
            incAmount: "",
            degreeId: 1,
            degreeIdIndex: 0,
            insDate: new Date(),
            accountNo: "",
            bankTypeId: "Bank",
            bankTypeIdIndex: 0,
            bankNameId: "Askari Bank",
            bankNameIdIndex: 0,
            saveUpdateAlloBtnText: 'save',
            saveUpdateIncBtnText: 'save',
            clearAlloTextBtn: 'none',
            clearincTextBtn: 'none',
        });
        this.LoadAllowanceData();
        this.LoadIncrementData();
        this.LoadBankDetailData();
        this.LoadBankAccDD();
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    verifyNumber(value) {
        debugger
        var mobileRex = /^[0-9]+$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "allDedId":
                debugger
                if (this.state.AllowanceList.find(x => x.allDedId === value.allDedId)) {

                }
                else {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.AllDedDD.findIndex(x => x[stateName] === value[stateName]),
                        allDedId: value.allDedId,
                        [stateName + "State"]: "success"
                    });

                }
                break;
            case "bankAccId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.BankAccDD.findIndex(x => x[stateName] === value[stateName]),
                    bankAccId: value.bankAccId,
                    [stateName + "State"]: "success"
                })
                break;
            case "degreeId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.DegreeDD.findIndex(x => x[stateName] === value[stateName]),
                    degreeId: value.degreeId,
                    [stateName + "State"]: "success"
                })
                break;
            case "incAmountId":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        incAmount: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        incAmount: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "amountId":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        amount: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        amount: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "accountNo":
                debugger
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        accountNo: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        accountNo: event.target.value,
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "bankTypeId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: BankTypeDD.findIndex(x => x[stateName] === value[stateName]),
                    bankTypeId: value.bankTypeId,
                    [stateName + "State"]: "success"
                })
                // this.setDDValue(event, value);
                break;
            case "bankNameId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: BankNameDD.findIndex(x => x[stateName] === value[stateName]),
                    bankNameId: value.bankNameId,
                    [stateName + "State"]: "success"
                })
                // this.setDDValue(event, value);
                break;
            case "organizationName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        [stateName + "State"]: "success",
                        organizationName: event.target.value,
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        organizationName: event.target.value
                    });

                }
                break;
            case "designationName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        designationName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        designationName: event.target.value,
                    })
                }
                break;
            case "fromDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "insDate":
                debugger;
                if (event.target.value != null) {
                    var setformate = Moment(event.target.value).format('DD-MMM-YYYY');
                    let obj = this.state.IncrementList.find(o => o.incDate === setformate);
                    if (obj != null && obj.incDate === setformate) {
                    }
                    else {
                        this.setState({
                            [stateName + "State"]: "success",
                            insDate: event.target.value,
                            stateName: event.target.value
                        });
                    }
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }

                break;
            case "checkbox":
                this.setState({ [stateName]: event });
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { AllowanceList, IncrementList, BankDetailList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Allowance & Deduction</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.AllDedDD}
                                            getOptionLabel={(option) => option.allDedName}
                                            id="allDedId"
                                            value={this.state.AllDedDD[this.state.allDedIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "allDedId", "allDedId", value)}
                                            renderInput={(params) => <TextField {...params} label="Allowance & Deduction " variant="outlined"
                                                success={this.state.allDedIdState === "success"}
                                                error={this.state.allDedIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.amountIdState === "success"}
                                            error={this.state.amountIdState === "error"}
                                            value={this.state.amount}
                                            size="small"
                                            label="Amount"
                                            id="amountId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "amountId", "amountId", value, 10),
                                                type: "text",
                                                maxLength: 1000000
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveAllowanceData() }} disabled={this.state.saveUpdateAlloBtnDisabled}><SaveOutlined /> {this.state.saveUpdateAlloBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearAlloTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Allowance/Deduction"}
                                    data={AllowanceList}
                                    columns={[
                                        {
                                            name: "allDedId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "allowanceName", label: "Allowance & Deduction" },
                                        { name: "amount", label: "Amount" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}> Increment</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.incAmountIdState === "success"}
                                            error={this.state.incAmountIdState === "error"}
                                            value={this.state.incAmount}
                                            size="small"
                                            label="Inc Amount"
                                            id="incAmountId"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "incAmountId", "incAmountId", value, 10),
                                                type: "text",
                                                maxLength: 1000000
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4}>
                                        <TextField
                                            success={this.state.insDateState === "success"}
                                            error={this.state.insDateState === "error"}
                                            size="small"
                                            label="Ins. Date"
                                            id="insDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.insDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "insDate", "insDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveIncrementData() }} disabled={this.state.saveUpdateincBtnDisabled}><SaveOutlined /> {this.state.saveUpdateIncBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearincTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Increment Details"}
                                    data={IncrementList}
                                    columns={[
                                        {
                                            name: "incId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextboxInc(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "incAmount", label: "Inc Amount" },
                                        { name: "incDate", label: "Ins. Date" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Bank Details</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={BankTypeDD}
                                            getOptionLabel={(option) => option.value}
                                            id="bankTypeId"
                                            value={BankTypeDD[this.state.bankTypeIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "bankTypeId", "bankTypeId", value)}
                                            renderInput={(params) => <TextField {...params} label="Bank/Cash " variant="outlined"
                                                success={this.state.bankTypeIdState === "success"}
                                                error={this.state.bankTypeIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.BankAccDD}
                                            getOptionLabel={(option) => option.title}
                                            id="bankAccId"
                                            value={this.state.BankAccDD[this.state.bankAccIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "bankAccId", "bankAccId", value)}
                                            renderInput={(params) => <TextField {...params} label="Company Bank" variant="outlined"
                                                success={this.state.bankAccIdState === "success"}
                                                error={this.state.bankAccIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.accountNoState === "success"}
                                            error={this.state.accountNoState === "error"}
                                            value={this.state.accountNo}
                                            size="small"
                                            label="Employee Account No"
                                            id="accountNo"
                                            variant="outlined"
                                            fullWidth
                                            required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "accountNo", "accountNo", value, 3),
                                                type: "text",
                                                maxLength: 10000
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={BankNameDD}
                                            getOptionLabel={(option) => option.value}
                                            id="bankNameId"
                                            value={BankNameDD[this.state.bankNameIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "bankNameId", "bankNameId", value)}
                                            renderInput={(params) => <TextField {...params} label=" Employee Bank Name" variant="outlined"
                                                success={this.state.bankNameIdStatus === "success"}
                                                error={this.state.bankNameIdStatus === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveBankDetailData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Save </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Bank Details"}
                                    data={BankDetailList}
                                    columns={[
                                        //{
                                        //    name: "bankId",
                                        //    label: "Edit",
                                        //    options: {
                                        //        customBodyRender: (value) => {
                                        //            return (<EditOutlined onClick={() => { this.LoadTextboxw(value) }} />);
                                        //        }
                                        //    }
                                        //},
                                        { name: "bankOrCash", label: "Bank/Cash" },
                                        {
                                            name: "active", label: "Active",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (value) === true ? <input type="checkbox" defaultChecked={this.state.isChecked} disabled="false" /> : (value) === false ? <input type="checkbox" defaultChecked={false} disabled="false" /> : "";
                                                }
                                            }
                                        },
                                        { name: "title", label: "Company Bank Account" },
                                        { name: "bankAccountNo", label: "Employee Account No" },
                                        { name: "bankName", label: "Employee Bank Name" },
                                        { name: "modifier", label: "Modifier" },
                                        { name: "modifierDate", label: "Modifier Date" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(Salary);