
/*Import from Globel Component";*/
import {
    React, axios, SweetAlert, SaveOutlined, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchSharp,
    AddIcon, Equalizer
} from '.../../components/Common/ImportAll';
import { Facebook } from '@material-ui/icons';
import Checkbox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankOutlined from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import { blackColor } from '../../assets/jss/material-dashboard-pro-react';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlined fontSize="small" />;
const checkedIcon = <CheckBoxOutlined fontSize="small" />;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
var Base_URL = localStorage.BaseURL;
const styles = {
    customWidth: {
        width: 200,
    },
};
class ERPFormLockRights extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCampus: false,
            userRights: false,
            userRightsArray: [],
            canView: false,
            canInsert: false,
            canUpdate: false,
            canDelete: false,
            userGroupName: '',
            lmsId: -1,
            //userGroupNameState: '',
            saveUpdateBtnText: 'Search',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            searchCheck: true,
            //dbStatus: false,
            //paid: true,
            //#region User Groups DropDown
            userGroupDD: [{ "userGroupId": null, "userGroupName": null, "active": null, "schoolCityId": null }],
            userGroupIdIndex: 0,
            userGroupIdSelected: -1,
            userGroupIdState: '',
            fromGroupId: 0,
            toGroupId: 0,
            toGroupIdIndex: 0,
            fromGroupIdIndex: 0,
            //#endregion

            //#region ERP Modules DropDown
            erpModuleDD: [{ "erpModuleId": null, "erpModuleName": null, "active": null }],
            erpCopyDD: [{ "erpModuleId": null, "erpModuleName": null, "active": null }],
            erpModuleIdIndex: 0,
            erpModuleId: 0,
            erpModuleIdSelected: -1,
            erpModuleIdState: '',
            erpCopyId: 0,
            erpCopyIdIndex: 0,
            //#endregion

            //#region All Campuses DropDown
            formTypeDD: [{ "formTypeId": null, "formTypeName": null }],
            formTypeIdIndex: 0,
            formTypeId: 0,
            formTypeIdState: '',
            formTypeIdSelected: -1,
            copyFormTypeId: 0,
            copyFormTypeIdIndex: 0,
            copyFormDD: [{ "formTypeId": null, "formTypeName": null }],
            //#endregion
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: 0,
            campusIdState: '',
            educationLevelDD: [{ educationLevelId: -1, levelName: 'Select' }],
            educationLevelId: -1,
            educationLevelIdState: '',
            educationLevelIdIndex: 0,

            alert: null,
            selected: true,


        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        //this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.LoadData = this.LoadData.bind(this);
        // this.onSelect = this.onSelect.bind(this);
    };

    //#region componentDidMount
    componentDidMount() {
        this.LoadUserGroups();
        this.LoadErpModules();
        this.LoadFormTypes();
        this.fillcampuses();
    }
    async LoadUserGroups() {
        try {
            const response = await (fetch(Base_URL + "/api/ITManagementCommonDD/GetUserGroups"))
            if (response.ok) {
                const data = await response.json();
                if ((data.exception.exceptionToken == null || data.exception.exceptionToken === '')
                    && (data.exception.displayMessage == null || data.exception.displayMessage === '') && data.userGroupList != null && data.userGroupList.length > 0) {
                    var arr = [{ "userGroupId": -1, "userGroupName": 'Select', "active": null, "schoolCityId": null }];
                    arr = arr.concat(data.userGroupList);
                    this.setState({
                        userGroupDD: arr
                    });
                }
                else {
                    this.setState({
                        userGroupDD: [{ "userGroupId": null, "userGroupName": null, "active": null, "schoolCityId": null }]
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadErpModules() {
        try {
            const response = await (fetch(Base_URL + "/api/ITManagementCommonDD/GetERPModules"))
            if (response.ok) {
                const data = await response.json();
                if ((data.exception.exceptionToken == null || data.exception.exceptionToken === '')
                    && (data.exception.displayMessage == null || data.exception.displayMessage === '') && data.erpModuleList != null && data.erpModuleList.length > 0) {
                    var arr = [{ "erpModuleId": -1, "erpModuleName": 'All', "active": null }];
                    var arry = [{ "erpModuleId": -1, "erpModuleName": 'Select', "active": null }];
                    arr = arr.concat(data.erpModuleList);
                    arry = arry.concat(data.erpModuleList)
                    this.setState({
                        erpModuleDD: arr,
                        erpCopyDD: arry
                    });
                }
                else {
                    this.setState({
                        erpModuleDD: [{ "erpModuleId": null, "eRPModuleName": null, "active": null }]
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadFormTypes() {
        try {
            const response = await (fetch(Base_URL + "/api/ITManagementCommonDD/GetFormTypes"))
            if (response.ok) {
                const data = await response.json();
                if ((data.exception.exceptionToken == null || data.exception.exceptionToken === '')
                    && (data.exception.displayMessage == null || data.exception.displayMessage === '') && data.fromTypesList != null && data.fromTypesList.length > 0) {
                    var arr = [{ "formTypeId": -1, "formTypeName": 'Select' }];
                    var arry = [{ "formTypeId": -1, "formTypeName": 'Select' }];
                    arr = arr.concat(data.fromTypesList);
                    arry = arry.concat(data.fromTypesList);
                    this.setState({
                        formTypeDD: arr,
                        copyFormDD: arry
                    });
                }
                else {
                    this.setState({
                        formTypeDD: [{ "formTypeId": null, "formTypeName": null }]
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async fillcampuses() {
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                debugger;
                var campusesArr = [{ campusId: -1, campusName: 'All' }];
                campusesArr = campusesArr.concat(data.campusDDList);
                this.setState({ campusDD: campusesArr, campusId: campusesArr[0]['campusId'] });
                this.filllevels(campusesArr[0]['campusId']);

            }).catch(error => {
                console.log(error);
            });
    }
    filllevels = (CampusID) => {
        fetch(Base_URL + "/api/Dropdowns/StudentLevelDD").then(response => { return response.json(); })
            .then(data => {
                debugger;
                var levelArr = [];
                levelArr = [{ educationLevelId: -1, levelName: 'Select' }];
                levelArr = levelArr.concat(data.acdamicLevelDDList);
                this.setState({ educationLevelDD: levelArr, educationLevelId: levelArr[0]['educationLevelId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    ClearTextfieldStates = () => {
        this.setState({
            userGroupIdIndex: 0,
            erpModuleIdIndex: 0,
            formTypeIdIndex: 0,
        });
        this.hideAlert();
        this.LoadData();
    }
    handleToggleChange = (event, checkTitle) => {
        debugger;
        this.setState({ [checkTitle]: event });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(lmsId) {
        debugger;
        let obj = this.state.usergrouparray.find(o => o.id === lmsId);
        this.setState({ userGroupName: obj.userGroupName, lmsId: obj.id, dbStatus: obj.dbStatus, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }
    //#region Search Data
    LoadData = () => {
        debugger;
        if (this.state.userGroupIdSelected < 0) {
            this.setState({
                userGroupIdState: "error"
            })
        }
        else if (this.state.formTypeIdSelected < 0) {
            this.setState({
                formTypeIdState: "error"
            })
        }
        else {
            fetch(Base_URL + "/api/UserRights/SearchUserRights?UserGroupId=" + this.state.userGroupIdSelected + "&ErpModuleId=" + this.state.erpModuleIdSelected + "&FormTypeId=" + this.state.formTypeIdSelected).then(response => { return response.json(); })
                .then(data => {
                    debugger;
                    if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                        this.setState({
                            userRightsArray: data.userRights,
                        });

                    }
                    else {
                        var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                        this.AlertMessage("Error", msg);
                    }
                }).catch(error => {
                    console.log(error);
                });
            //this.ClearTextfieldStates();
        }
    }
    //#endregion
    validateData = () => {
        if (this.state.campusId === -1) {
            this.setState({ campusIdState: "error" });
            return;
        }
        else if (this.state.userGroupId === -1) {
            this.setState({ userGroupIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    //#region SaveCampusRights
    SaveCampusRights() {
        debugger;
        this.hideAlert();
        axios.post(Base_URL + "/api/UserRights/SaveCampusRights?UserGroupId=" + this.state.userGroupId + "&CampusId=" + this.state.campusId)
            .then(json => {
                debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.response.status, json.data.response.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })
    }
    //#endregion
    ClearTextfieldStates = () => {
        this.setState({
            userGroupIdIndex: 0,
            erpModuleIdIndex: 0,
            formTypeIdIndex: 0,
            userGroupIdSelected: -1,
            erpModuleIdSelected: -1,
            formTypeIdSelected: -1
        });
    }
    //#region DropDown
    setDDValue = (event, value, state) => {
        debugger;

        if (state === 'fromGroupId') {
            const stateName = 'fromGroupIdIndex';
            this.state.fromGroupId = value['userGroupId'];
            this.setState({
                [stateName]: value[stateName],
                [stateName]: this.state.userGroupDD.findIndex(x => x.userGroupId === this.state.fromGroupId),
                [stateName + "State"]: "success"
            })
        }
        else if (state === 'toGroupId') {
            const stateName = 'toGroupIdIndex';
            this.state.toGroupId = value['userGroupId'];
            this.setState({
                [stateName]: value[stateName],
                [stateName]: this.state.userGroupDD.findIndex(x => x.userGroupId === this.state.toGroupId),
                [stateName + "State"]: "success"
            })
        }
        else if (state === 'erpCopyId') {
            const stateName = 'erpCopyIdIndex';
            this.state.erpCopyId = value['erpModuleId'];
            this.setState({
                [stateName]: value[stateName],
                [stateName]: this.state.erpCopyDD.findIndex(x => x.erpModuleId === this.state.erpCopyId),
                [stateName + "State"]: "success"
            })
        }
        else if (state === 'copyFormTypeId') {
            const stateName = 'copyFormTypeIdIndex';
            this.state.copyFormTypeId = value['formTypeId'];
            this.setState({
                [stateName]: value[stateName],
                [stateName]: this.state.copyFormDD.findIndex(x => x.formTypeId === this.state.copyFormTypeId),
                [stateName + "State"]: "success"
            })
        }
        else {
            const stateName = event.target.id.split("-")[0];
            const dropdownName = stateName.slice(0, -2) + "DD";
            this.setState({
                [stateName]: value[stateName],
                [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
                [stateName + "State"]: "success"
            })
            if (state === "userGroupIdSelected") {
                this.state.userGroupIdSelected = value[stateName]
            }
            if (state === "erpModuleIdSelected") {
                this.state.erpModuleIdSelected = value[stateName]
            }
            if (state === "formTypeIdSelected") {
                this.state.formTypeIdSelected = value[stateName]
            }

        }

    }


    //#endregion

    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveCampusRights()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        debugger;
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
        this.ClearTextfieldStates();
        this.LoadUserGroupData();
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        this.ClearTextfieldStates();
    }
    //#endregion
    handleChange(event, index, value) {
        debugger
        this.setState({ value })
    };
    handleChange1(event) {
        debugger;
    }
    //#region Form Body and Return
    render() {
        // var options = this.state.campusDD;
        // const isAllSelected =options.length > 0 && selected.length === options.length;
        const { classes } = this.props;
        const { userGroupDD, userGroupIdIndex, userGroupIdState, erpModuleDD, erpModuleIdIndex, erpModuleIdState, fromGroupIdIndex,
            erpCopyIdIndex, copyFormTypeIdIndex, erpCopyDD, copyFormDD, userRightsArray,
            formTypeDD, formTypeIdIndex, formTypeIdState, toGroupIdIndex, campusDD, campusIdIndex, campusIdState, selected, educationLevelIdIndex, educationLevelIdState } = this.state;
        debugger;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Equalizer />
                        </CardIcon>
                        <h4 style={{ color: "black" }}>Manage Users Group Rights</h4>
                    </CardHeader>
                    {this.state.alert}
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "0px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">Users Group Rights</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "5px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Manage Users Group Rights</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <form>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="userGroupId"
                                                            size="small"
                                                            options={userGroupDD}
                                                            getOptionLabel={(option) => option.userGroupName}
                                                            value={userGroupDD[userGroupIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value, 'userGroupIdSelected') }}
                                                            renderInput={(params) => <TextField {...params} success={userGroupIdState === "success"}
                                                                error={userGroupIdState === "error"} label="User Group" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="erpModuleId"
                                                            size="small"
                                                            options={erpModuleDD}
                                                            getOptionLabel={(option) => option.erpModuleName}
                                                            value={erpModuleDD[erpModuleIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value, "erpModuleIdSelected") }}
                                                            renderInput={(params) => <TextField {...params} success={erpModuleIdState === "success"}
                                                                error={erpModuleIdState === "error"} label="ERP Module" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={1}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="formTypeId"
                                                            size="small"
                                                            options={formTypeDD}
                                                            getOptionLabel={(option) => option.formTypeName}
                                                            value={formTypeDD[formTypeIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value, "formTypeIdSelected") }}
                                                            renderInput={(params) => <TextField {...params} success={formTypeIdState === "success"}
                                                                error={formTypeIdState === "error"} label="Form Type" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1}>
                                                        <Button color="facebook" onClick={this.LoadData} disabled={this.state.saveUpdateBtnDisabled}><SearchSharp />{this.state.saveUpdateBtnText}</Button>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1}>
                                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="campusId"
                                                            size="small"
                                                            options={campusDD}
                                                            getOptionLabel={(option) => option.campusName}
                                                            value={campusDD[campusIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value); this.filllevels(value.campusId) }}
                                                            renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                                                error={campusIdState === "error"} label="Campus" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox>
                                                        <Autocomplete
                                                            multiple
                                                            id="checkboxes-tags-demo"
                                                            options={campusDD}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(option) => option.campusName}
                                                            renderOption={(props, option) => (
                                                                <li {...props} style={{ margin: 8 }}>
                                                                    <Checkbox
                                                                        // {...label}
                                                                        //input={{
                                                                        //name: 'sampleName',
                                                                        //onChange: (e) => this.onSelect(e.target.checked),
                                                                        //value: allSelected
                                                                        //}}
                                                                        //onChange={(event, value) => { this.onSelect(event.target.checked, event, value) }}
                                                                        onClick={(event) => { this.handleChange1(event) }}
                                                                        // icon={icon}
                                                                        //  checkedIcon={checkedIcon}
                                                                        // style={{ marginRight: 8}}
                                                                        checked={false}
                                                                        uncheckedIcon={icon}
                                                                        checkedIcon={checkedIcon}

                                                                    />
                                                                    {option.campusName}
                                                                </li>
                                                            )}
                                                            style={{ width: 500 }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="Campus" placeholder="Campus" />
                                                            )}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={1}>
                                                        <Button color="facebook" onClick={this.SaveCampusRights}><SaveOutlined />Save</Button>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={1}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "showCampus")}
                                                                checked={this.state.showCampus} onColor="#3b5998" className={classes.toggleStyle}
                                                                borderRadius={4}
                                                                handleDiameter={22}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span>View</span>
                                                        </label>
                                                    </GridTextbox>
                                                </GridContainer>
                                            </form>
                                        </CardBody>
                                    </Card>
                                    {
                                        this.state.showCampus === true ?
                                            <Card style={{ marginTop: "5px", marginBottom: "0px" }}>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        title={"Campus Detail"}
                                                        columns={[
                                                            { label: "Campus" }
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </Card> : ""
                                    }
                                </GridForm>

                                {(() => {
                                    debugger;
                                    if (this.state.formTypeId == 1) {
                                        return (
                                            <GridForm xs={12} sm={12} md={12}>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        title={"Forms Detail"}
                                                        data={userRightsArray}

                                                        columns={[
                                                            {
                                                                name: "projectDirectoryId",
                                                                label: "FormId",
                                                            },
                                                            {
                                                                name: "controllerName",
                                                                label: "Controller/Method",
                                                            },
                                                            {
                                                                name: "pdFormName",
                                                                label: "Form Name",
                                                            },
                                                            {
                                                                label: "Can View",
                                                                options: {
                                                                    name: "canView",
                                                                    customBodyRender: (value) => {
                                                                        this.state.canView = value
                                                                        return (
                                                                            <GridTextbox xs={12} sm={12} md={3} color="facebook" style={{ marginLeft: "5%" }}>
                                                                                <label htmlFor="small-radius-switch">
                                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "canView")}
                                                                                        checked={this.state.canView}
                                                                                        onColor="#3b5998"
                                                                                        color="facebook"
                                                                                        className={classes.toggleStyle}
                                                                                        borderRadius={4}
                                                                                        handleDiameter={22}
                                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                                                    />
                                                                                </label>
                                                                            </GridTextbox>
                                                                        )
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                label: "Can Save",
                                                                options: {
                                                                    name: "canInsert",
                                                                    customBodyRender: (value) => {
                                                                        this.state.canInsert = value
                                                                        return (
                                                                            <GridTextbox xs={12} sm={12} md={3} color="facebook" style={{ marginLeft: "5%" }}>
                                                                                <label htmlFor="small-radius-switch">
                                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "canInsert")}
                                                                                        checked={this.state.canInsert}
                                                                                        onColor="#3b5998"
                                                                                        color="facebook"
                                                                                        className={classes.toggleStyle}
                                                                                        borderRadius={4}
                                                                                        handleDiameter={22}
                                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                                                    />
                                                                                </label>
                                                                            </GridTextbox>
                                                                        )
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                label: "Can Update",
                                                                options: {
                                                                    name: "canUpdate",
                                                                    customBodyRender: (value) => {
                                                                        this.state.canUpdate = value
                                                                        return (
                                                                            <GridTextbox xs={12} sm={12} md={3} color="facebook" style={{ marginLeft: "5%" }}>
                                                                                <label htmlFor="small-radius-switch">
                                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "canUpdate")}
                                                                                        checked={this.state.canUpdate}
                                                                                        onColor="#3b5998"
                                                                                        color="facebook"
                                                                                        className={classes.toggleStyle}
                                                                                        borderRadius={4}
                                                                                        handleDiameter={22}
                                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                                                    />
                                                                                </label>
                                                                            </GridTextbox>
                                                                        )
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                label: "Can Delete",
                                                                options: {
                                                                    name: "canDelete",
                                                                    customBodyRender: (value) => {
                                                                        this.state.canDelete = value
                                                                        return (
                                                                            <GridTextbox xs={12} sm={12} md={3} color="facebook" style={{ marginLeft: "5%" }}>
                                                                                <label htmlFor="small-radius-switch">
                                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "canDelete")}
                                                                                        checked={this.state.canDelete}
                                                                                        onColor="#3b5998"
                                                                                        color="facebook"
                                                                                        className={classes.toggleStyle}
                                                                                        borderRadius={4}
                                                                                        handleDiameter={22}
                                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                                                    />
                                                                                </label>
                                                                            </GridTextbox>
                                                                        )
                                                                    }
                                                                }
                                                            },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </GridForm>
                                        )
                                    }
                                    else if (this.state.formTypeId === 2 || this.state.formTypeId === 3) {
                                        return (
                                            <GridForm xs={12} sm={12} md={12}>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        title={"Forms Detail"}
                                                        data={userRightsArray}
                                                        data={userRightsArray}
                                                        columns={[
                                                            {
                                                                name: "FormId",
                                                                label: "projectDirectoryId",
                                                            },
                                                            {
                                                                name: "controllerName",
                                                                label: "Controller/Method",
                                                            },
                                                            {
                                                                name: "pdFormName",
                                                                label: "Form Name",
                                                            },
                                                            {
                                                                label: "Can View",
                                                                options: {
                                                                    name: "canview",
                                                                    customBodyRender: (value) => {
                                                                        this.state.canView = value
                                                                        return (
                                                                            <GridTextbox xs={12} sm={12} md={3} color="facebook" style={{ marginLeft: "5%" }}>
                                                                                <label htmlFor="small-radius-switch">
                                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "canView")}
                                                                                        checked={this.state.canView}
                                                                                        onColor="#3b5998"
                                                                                        color="facebook"
                                                                                        className={classes.toggleStyle}
                                                                                        borderRadius={4}
                                                                                        handleDiameter={22}
                                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                                                    />
                                                                                    {/* <span>Copy User Rights</span>*/}
                                                                                </label>
                                                                            </GridTextbox>
                                                                        )
                                                                    }
                                                                }
                                                            },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </GridForm>
                                        )
                                    }


                                })()}
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={3} color="facebook" style={{ marginLeft: "5%" }}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "userRights")}
                                                checked={this.state.userRights}
                                                onColor="#3b5998"
                                                color="facebook"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                            />
                                            <span>Copy User Rights</span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1} style={{ marginLeft: "18%" }}>
                                        <Button color="facebook"><SaveOutlined />Save</Button>
                                    </GridTextbox>
                                </GridContainer>
                                {this.state.userRights === true ?
                                    <GridContainer>
                                        <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                            <Autocomplete
                                                disableClearable
                                                id="fromGroupId"
                                                size="small"
                                                options={userGroupDD}
                                                getOptionLabel={(option) => option.userGroupName}
                                                value={userGroupDD[fromGroupIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value, 'fromGroupId') }}
                                                renderInput={(params) => <TextField {...params} success={userGroupIdState === "success"}
                                                    error={userGroupIdState === "error"} label="From Group" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={2}>
                                            <Autocomplete
                                                disableClearable
                                                id="toGroupId"
                                                size="small"
                                                options={userGroupDD}
                                                getOptionLabel={(option) => option.userGroupName}
                                                value={userGroupDD[toGroupIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value, 'toGroupId') }}
                                                renderInput={(params) => <TextField {...params} success={userGroupIdState === "success"}
                                                    error={userGroupIdState === "error"} label="To Group" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={2}>
                                            <Autocomplete
                                                disableClearable
                                                id="erpModuleId"
                                                size="small"
                                                options={erpCopyDD}
                                                getOptionLabel={(option) => option.erpModuleName}
                                                value={erpCopyDD[erpCopyIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value, 'erpCopyId') }}
                                                renderInput={(params) => <TextField {...params} success={erpModuleIdState === "success"}
                                                    error={erpModuleIdState === "error"} label="ERP Module" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={2}>
                                            <Autocomplete
                                                disableClearable
                                                id="formTypeId"
                                                size="small"
                                                options={copyFormDD}
                                                getOptionLabel={(option) => option.formTypeName}
                                                value={copyFormDD[copyFormTypeIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value, 'copyFormTypeId') }}
                                                renderInput={(params) => <TextField {...params} success={formTypeIdState === "success"}
                                                    error={formTypeIdState === "error"} label="Form Type" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} lg={1}>
                                            <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />Save{this.state.saveUpdateBtnText}</Button>
                                        </GridTextbox>
                                    </GridContainer> : ""
                                }
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(ERPFormLockRights);
