
//Import from Globel Component";

import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, DataUsage
} from '../../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class UnpaidChallan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            special: false,
        };
        //#endregion
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    onEditorStateChang = (editorState) => {
        this.setState({
            editorState,
        });
    };
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div style={{ paddingBottom: "7px", paddingRight: "13px", paddingLeft: "13px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                            <CardHeader>
                                <h4 className={classes.cardIconTitle}>Unpaid Challan</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            size="small"
                                            label="Challan No"
                                            id="EmpIdName"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            size="small"
                                            label="Reason"
                                            id="EmpIdName"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <Button color="facebook"><SearchSharp />Search</Button>
                                        <Button color="facebook">Update</Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={12} md={12}>
                        <MuiThemeProvider theme={muiWithToolbar()}>
                            <MUIDataTable
                                title={"Fee Installment Detail"}
                                columns={[
                                    { label: "Registration No" }, { label: "Student Name" },
                                    { label: "Challan#" }, { label: "Fee Head" },
                                    { label: "Challan Amount" }, { label: "Discount" },
                                    { label: "Fine" }, { label: "Amount Paid" },
                                    { label: "Stand ALone" }, { label: "Paid" },
                                ]}
                                options={tbleWithPrint}
                            />
                        </MuiThemeProvider>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(UnpaidChallan);