import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// @material-ui/core components
import {
    Home as HomeIcon, NavigateNext as NavigateNextIcon,
    Widgets as InventoryIcon, LocalOffer, AccountBox, FileCopy as POIIcon, GroupAdd as SupplierIcon, Description,
    Settings, Replay, TrackChanges, Category, Add, FormatLineSpacing, FormatListBulleted, ErrorOutline, ClassTwoTone,
    CalendarViewDay, Report, EmojiPeopleTwoTone, AccessibilityNewTwoTone, Computer, ShoppingCart, List, VerifiedUser, Queue,
    Pageview, Forward30, ShoppingBasket
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";

// components
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";

class Inventory extends React.Component {
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;

        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="warning" icon>
                    <CardIcon color="warning">
                        <InventoryIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Inventory Management</h4>
                </CardHeader>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Typography color="textPrimary">Inventory Management </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/managesuppliers"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <SupplierIcon />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>
                                                Suppliers
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <AccountBox />
                                    Add/Edit Suppliers
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/managecompanies"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <POIIcon />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>
                                                Companies
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <LocalOffer />
                                    Companies Management
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/purchaseorder"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <Description />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Purchase</p>
                                            <h3 className={classes.cardTitle}>
                                                Order/Invoice
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Description />
                                    Purchase Order/Invoice
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/managepurchases"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <Settings />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>
                                                Purchases
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Settings />
                                    Manage Purchases
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/issueinventory"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <Replay />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Issue/Return</p>
                                            <h3 className={classes.cardTitle}>
                                                Inventory
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Replay />
                                    Issue/Return Inventory
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/manageissuance"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <TrackChanges />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>
                                                Issuance
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <TrackChanges />
                                  Manage Issuance
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/managecategories"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <Category />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>
                                                Categories
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Category />
                                  Manage Categories
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/newitem"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Add />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>New</p>
                                            <h3 className={classes.cardTitle}>
                                                Item
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Add />
                                  New Item
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/manageitems"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <FormatLineSpacing />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>
                                                Items
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <FormatLineSpacing />
                                  Manage Items
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/practical"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <FormatListBulleted />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Practical</p>

                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <FormatListBulleted />
                                  Practical
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <CalendarViewDay />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Labracks</p>


                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <CalendarViewDay />
                                  Labracks
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><InventoryIcon style={{ marginBottom: "-5px" }} />  Issuance Reports </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><ErrorOutline style={{ color: 'Crimson', fontSize: '70' }} /></center>

                                        <h3 className={classes.cardTitle}><center>Item Wise Issuance</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><Report style={{ color: 'Olive', fontSize: '70' }} /></center>

                                        <h3 className={classes.cardTitle}><center> Issuance Full </center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><ClassTwoTone style={{ color: 'Indigo', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center> Class Wise Issuance</center> </h3>
                                        <p className={classes.cardCategory}><center>Report</center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><EmojiPeopleTwoTone style={{ color: 'Blue', fontSize: '70' }} /></center>

                                        <h3 className={classes.cardTitle}><center> Employee Wise Issuance</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><InventoryIcon style={{ marginBottom: "-5px" }} />  Purchase Reports </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><AccessibilityNewTwoTone style={{ color: 'Green', fontSize: '70' }} /></center>

                                        <h3 className={classes.cardTitle}><center>Supplier Wise Purchase</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><ShoppingCart style={{ color: 'Teal', fontSize: '70' }} /></center>

                                        <h3 className={classes.cardTitle}><center>Item Wise Purchase</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><ShoppingBasket style={{ color: 'Olive', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Purchase</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><InventoryIcon style={{ marginBottom: "-5px" }} />  Other Reports </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><List style={{ color: 'OrangeRed', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Item List</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><VerifiedUser style={{ color: 'Brown', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Item Status Wise</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><Queue style={{ color: 'Purple', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Stock</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><Pageview style={{ color: 'darkgreen', fontSize: '70' }} /></center>

                                        <h3 className={classes.cardTitle}><center>Item Ledger</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/labracks"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center><Forward30 style={{ color: 'salmon', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}><center>Employee Wise Returned Item</center> </h3>
                                        <p className={classes.cardCategory}><center>Report </center> </p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card >
        );
    }
}

Inventory.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Inventory);
