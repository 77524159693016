//Import from Globel Component";
import {
    React, axios, Moment, InputMask, SweetAlert, SaveOutlined, AddIcon, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class ReferenceAndScore extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            referenceId: 0,
            ReferenceList: [],
            fullName: "",
            fullNameState: "",
            mobileNo: "",
            mobileNoState: "",
            landLine: "",
            landLineState: "",
            emailAddress: "",
            emailAddressState: "",
            refRelationId: "",
            refRelationIdIndex: 0,
            refRelationIdState: "",
            RefRelationDD: [{ refRelationId: -1, refRelationName: "Select" }],
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null, show: false, generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadRelationDD();
        this.LoadReferenceData();
    }
    async LoadRelationDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/RefRelationDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    RefRelationDD: data.relationDDList
                });
                if (data.relationDDList.length > 0) {
                    this.setState({
                        refRelationId: data.relationDDList[0].refRelationId,
                        refRelationIdIndex: 0,
                        refRelationIdState: "success"
                    });
                }
                else {
                    this.setState({
                        refRelationId: 0,
                        refRelationIdIndex: -1,
                        refRelationIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadReferenceData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadReferenceData?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    ReferenceList: data.referencedataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    SaveReferenceData = () => {
        debugger;
        if ((this.state.refRelationId == 0) || (this.state.refRelationIdState === "error")) {
            this.setState({ refRelationIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.fullName == "") || (this.state.fullNameState === "error")) {
            this.setState({ fullNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.mobileNo == "") || (this.state.mobileNoState === "error")) {
            this.setState({ mobileNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.emailAddress == "") || (this.state.emailAddressState === "error")) {
            this.setState({ emailAddressState: "error", generalError: true });
            return;
        }
        let ReferenceInfo = {
            ReferenceId: this.state.referenceId,
            EmployeeId: this.state.employeeId,
            FullName: this.state.fullName,
            RelationId: this.state.refRelationId,
            CellNumber: this.state.mobileNo,
            EmailAddress: this.state.emailAddress,
            LandLine: this.state.landLine,
        };
        console.log(ReferenceInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeeReference', ReferenceInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    LoadTextbox = (id) => {
        debugger
        let obj = this.state.ReferenceList.find(o => o.referenceId === id);
        this.setState({ referenceId: obj.referenceId, refRelationId: obj.refRelationId, fullName: obj.fullName, mobileNo: obj.cellNumber, emailAddress: obj.emailAddress, landLine: obj.landLine, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (this.state.RefRelationDD.find(x => x.refRelationId === obj.refRelationId) != null) {
            this.setState({
                refRelationId: obj.refRelationId,
                refRelationIdIndex: this.state.RefRelationDD.findIndex(x => x.refRelationId === obj.refRelationId),
                refRelationIdState: "success"
            });
        }
        else {
            this.setState({
                refRelationId: -1,
                refRelationIdIndex: 0,
                refRelationIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    AlertMessage(status, message) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            referenceId: 0,
            fullName: "",
            mobileNo: "",
            landLine: "",
            emailAddress: "",
            incAmount: "",
            saveUpdateBtnText: 'save',
            clearTextBtn: 'none',
            alert: null,

        });
        this.LoadReferenceData();
        this.LoadRelationDD();
    }
    // function that verifies if a string is Email or not
    verifyEmail(value) {
        debugger
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Mobile No. or not
    verifyMobile(value) {
        debugger
        var mobileRex = /^([3]{1})([0-9]{9})$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "fullName":
                if (event.target.value.length >= stateNameEqualTo) {
                    this.setState({
                        stateName: stateName,
                        fullName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        fullName: event.target.value,
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "refRelationId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.RefRelationDD.findIndex(x => x[stateName] === value[stateName]),
                    refRelationId: value.refRelationId,
                    [stateName + "State"]: "success"
                })
                break;
            case "mobileNo":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        mobileNo: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        mobileNo: event.target.value,
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "landLine":
                this.setState({ [stateName + "State"]: "success", stateName: event.target.value, landLine: event.target.value });
                break;
            case "emailAddress":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        emailAddress: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        emailAddress: event.target.value,
                        [stateName + "State"]: "error"
                    });
                }
                break;
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nameUrdu: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { ReferenceList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee's Reference </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.RefRelationDD}
                                            getOptionLabel={(option) => option.refRelationName}
                                            id="refRelationId"
                                            value={this.state.RefRelationDD[this.state.refRelationIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "refRelationId", "refRelationId", value)}
                                            renderInput={(params) => <TextField {...params} label="Relation" variant="outlined"
                                                success={this.state.refRelationIdState === "success"}
                                                error={this.state.refRelationIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.fullNameState === "success"}
                                            error={this.state.fullNameState === "error"}
                                            value={this.state.fullName}
                                            size="small"
                                            label="Full Name"
                                            id="fullName"
                                            variant="outlined"
                                            fullWidth
                                            // required={true}
                                            inputProps={{
                                                onChange: (event, value) =>
                                                    this.change(event, "fullName", "fullName", value, 3),
                                                type: "text",
                                                maxLength: 100
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <InputMask
                                            {...this.props}
                                            mask="\3999999999"
                                            maskChar=""
                                            value={this.state.mobileNo}
                                        >
                                            {() => <TextField
                                                success={this.state.mobileNoState === "success"}
                                                error={this.state.mobileNoState === "error"}
                                                size="small"
                                                label="Mobile No"
                                                id="mobileNo"
                                                variant="outlined"
                                                fullWidth
                                                required={true}
                                                autoComplete="off"
                                                placeholder="3001234567"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.change(event, "mobileNo", "mobileNo"),
                                                    maxLength: 10,
                                                }}
                                            // onInput={(e) => {
                                            //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            // }}
                                            />
                                            }
                                        </InputMask>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.emailAddressState === "success"}
                                            error={this.state.emailAddressState === "error"}
                                            size="small"
                                            label="Email(Address)"
                                            placeholder="someone@mail.com"
                                            value={this.state.emailAddress}
                                            id="emailAddress"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                onChange: event => this.change(event, "emailAddress", "emailAddress")
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <TextField
                                            success={this.state.landLineState === "success"}
                                            error={this.state.landLineState === "error"}
                                            size="small"
                                            label="Land Line"
                                            id="LandLineId"
                                            variant="outlined"
                                            value={this.state.landLine}
                                            fullWidth
                                            inputProps={{
                                                onChange: event =>
                                                    this.change(event, "landLine", "landLine"),
                                                type: "number"
                                            }}
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13)
                                            }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={1}>
                                        <Button color="facebook" onClick={() => { this.SaveReferenceData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Increment Details"}
                                    data={ReferenceList}
                                    columns={[
                                        {
                                            name: "referenceId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        },
                                        { name: "fullName", label: "Full Name" },
                                        { name: "refRelationName", label: "Relation" },
                                        { name: "cellNumber", label: "Mobile No" },
                                        { name: "emailAddress", label: "Email Address" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}
export default withStyles(formStyle)(ReferenceAndScore);