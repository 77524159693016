import React, { useState } from "react";
import axios from 'axios';
var Base_URL = localStorage.BaseURL;

export async function setDDValue(event, value, StateVariable) {
    debugger;
    const stateName = event.target.id.split("-")[0];
    const dropdownName = stateName.slice(0, -2) + "DD";
    var newIndex = StateVariable[dropdownName].findIndex(x => x[stateName] === value[stateName]);
    StateVariable[stateName] = value[stateName];
    StateVariable[stateName + "Index"] = newIndex;
    StateVariable[stateName + "State"] = "success";
    StateVariable.generalError = false;
}

export async function resetDDClassSection(StateVariable) {
    //debugger;
    StateVariable.sectionDD = [{ sectionId: -1, sectionName: 'Select' }];
    StateVariable.sectionId = -1;
    StateVariable.sectionIdIndex = 0;
    StateVariable.sectionIdState = '';
    StateVariable.classDD = [{ classId: -1, className: 'Select' }];
    StateVariable.classId = -1;
    StateVariable.classIdState = '';
    StateVariable.classIdIndex = 0;
}

export async function handleToggleChange(event, checkName, StateVariable) {
    debugger;
    StateVariable({ [checkName]: event });
}

export async function verifyEmail(value) {
    debugger
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
        return true;
    }
    return false;
}
export async function verifyMobileNo(value) {
    debugger;
    var MobNoRex = /^[3]{1}[0-9]{9}$/i
    if (MobNoRex.test(value)) {
        debugger;
        return true;
    }
    return false;
}

export async function verifyNIC(value) {
    debugger;
    var CNICRex = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/i
    if (CNICRex.test(value)) {
        return true;
    }
    return false;
}
export async function ageCalculate(dob) {
  debugger;
    var todate = new Date()
    var fromdate = new Date(dob)
    var y = [todate.getFullYear(), fromdate.getFullYear()],
        ydiff = y[0] - y[1],
        m = [todate.getMonth(), fromdate.getMonth()],
        mdiff = m[0] - m[1],
        d = [todate.getDate(), fromdate.getDate()],
        ddiff = d[0] - d[1];
    if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) {
        --ydiff;
    }
    if (mdiff < 0) {
        mdiff += 12;
    }
    if (ddiff < 0) {
        fromdate.setMonth(m[1] + 1, 0)
        ddiff = fromdate.getDate() - d[1] + d[0]
        --mdiff;
        if (mdiff < 0) {
            mdiff += 12;
        }
    }
    var age = ydiff + " y " + mdiff + " m " + ddiff + " d";
    return age;
}

export async function validateCheckSetVal(event, type, StateVariable) {
    debugger;
    switch (type) {
        case "studentDOB":
            debugger;
            var stddate = event.target.value;
            var age = await ageCalculate(stddate);
            debugger;
            var arr = [{ "StateName": type + "State", "StateValue": "success" }, { "StateName": "studentDOB", "StateValue": stddate }, { "StateName": "studentAge", "StateValue": age }, { "StateName": "generalError", "StateValue": false }];
            return (arr);
            break;
        case "checkNIC":
            var CNICRex = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/i
            if (StateVariable.overSeas === false) {
                debugger;
                if (event.target.value.length===15) {
                    debugger
                    if (CNICRex.test(event.target.value)) {
                        debugger;
                        var arr = [{ "StateName": event.target.id + "State", "StateValue": "success" }, { "StateName": event.target.id, "StateValue": event.target.value }];
                        return (arr);
                    }
                }
                else {
                    var arr = [{ "StateName": event.target.id + "State", "StateValue": "error" }, { "StateName": event.target.id , "StateValue": event.target.value }];
                    return (arr);
                }
            }
            else {
                var arr = [{ "StateName": event.target.id + "State", "StateValue": "success" }, { "StateName": event.target.id, "StateValue": event.target.value }];
                return (arr);
            }
            break;
        case "email":
            var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (emailRex.test(event.target.value)){
                debugger;
                var arr = [{ "StateName": event.target.id + "State", "StateValue": "success" }, { "StateName": event.target.id, "StateValue": event.target.value }];
                return (arr);
            }
            else {
                var arr = [{ "StateName": event.target.id + "State", "StateValue": "error" }, { "StateName": event.target.id, "StateValue": event.target.value }];
                return (arr);
            }
            break;
        case "mobileNumber":
            debugger;
            var MobNoRex = /^[3]{1}[0-9]{9}$/i
            if (event.target.value.length === 10) {
                debugger;
                if (MobNoRex.test(event.target.value)) {
                    debugger;
                    var arr = [{ "StateName": event.target.id + "State", "StateValue": "success" }, { "StateName": event.target.id, "StateValue": event.target.value }];
                    return (arr);
                }
            }
            else {
                debugger;
                var arr = [{ "StateName": event.target.id + "State", "StateValue": "error" }, { "StateName": event.target.id, "StateValue": event.target.value }];
                return (arr);
            }
            break;
        default:
            break;
    }
}

