
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, Create
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class CreateVoucher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            jv: false,
        };
        //#endregion
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    onEditorStateChang = (editorState) => {
        this.setState({
            editorState,
        });
    };
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Create />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Create Journal Voucher</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/accounts" > Accounts</Link>
                            <Typography color="textPrimary">Create Journal Voucher</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Create Voucher</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer style={{ marginleft: "1px" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer style={{ marginLeft: 1 }}>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Voucher Type" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={startDateState === "success"}
                                                                error={startDateState === "error"}
                                                                size="small"
                                                                label="Date"
                                                                id="startDate"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={Moment(startDate).format('YYYY-MM-DD')}
                                                                type="date"
                                                                onChange={this.setDateValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                size="small"
                                                                label="Cheque No"
                                                                id="EmpIdName"
                                                                variant="outlined"
                                                                type="text"
                                                                fullWidth
                                                                inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                            />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                    <GridContainer style={{ marginLeft: "1px"}}>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Ledger Account" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Campus" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Department" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Head" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Sub Head" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                    <GridContainer style={{ marginLeft: "1px"}}>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={startDateState === "success"}
                                                                error={startDateState === "error"}
                                                                size="small"
                                                                label="From Date"
                                                                id="startDate"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={Moment(startDate).format('YYYY-MM-DD')}
                                                                type="date"
                                                                onChange={this.setDateValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={startDateState === "success"}
                                                                error={startDateState === "error"}
                                                                size="small"
                                                                label="To Date"
                                                                id="startDate"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={Moment(startDate).format('YYYY-MM-DD')}
                                                                type="date"
                                                                onChange={this.setDateValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <Autocomplete
                                                                id="Department"
                                                                size="small"
                                                                renderInput={(params) => <TextField {...params} label="Fuel Type" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        {/*<GridTextbox xs={12} sm={12} md={1} color="facebook">*/}
                                                        {/*    <label htmlFor="small-radius-switch">*/}
                                                        {/*        <ReactToggle onChange={(event) => this.handleToggleChange(event, "jv")}*/}
                                                        {/*            checked={this.state.jv}*/}
                                                        {/*            onColor="#3b5998"*/}
                                                        {/*            color="facebook"*/}
                                                        {/*            className={classes.toggleStyle}*/}
                                                        {/*            borderRadius={4}*/}
                                                        {/*            handleDiameter={22}*/}
                                                        {/*            uncheckedIcon={<div className={classes.toggleOff}>No</div>}*/}
                                                        {/*            checkedIcon={<div className={classes.toggleOn}>Yes</div>}*/}
                                                        {/*        />*/}
                                                        {/*        <span>JV#</span>*/}
                                                        {/*    </label>*/}
                                                        {/*</GridTextbox>*/}
                                                        {/*{*/}
                                                        {/*    this.state.jv === true ?*/}
                                                        {/*        <GridTextbox xs={12} sm={12} md={1}>*/}
                                                        {/*            <TextField*/}
                                                        {/*                size="small"*/}
                                                        {/*                label="JV#"*/}
                                                        {/*                id="EmpIdName"*/}
                                                        {/*                variant="outlined"*/}
                                                        {/*                type="text"*/}
                                                        {/*                fullWidth*/}
                                                        {/*                inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}*/}
                                                        {/*            />*/}
                                                        {/*        </GridTextbox>*/}
                                                        {/*        : ""*/}
                                                        {/*}*/}
                                                        <GridContainer style={{ marginLeft: "1px"}}>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Voucher#"
                                                                    id="EmpIdName"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    fullWidth
                                                                    inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Transaction#"
                                                                    id="EmpIdName"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    fullWidth
                                                                    inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Cheque No"
                                                                    id="EmpIdName"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    fullWidth
                                                                    inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={startDateState === "success"}
                                                                    error={startDateState === "error"}
                                                                    size="small"
                                                                    label="Date"
                                                                    id="startDate"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={Moment(startDate).format('YYYY-MM-DD')}
                                                                    type="date"
                                                                    onChange={this.setDateValue}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={3}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Particulars"
                                                                    id="EmpIdName"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    fullWidth
                                                                    inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                                />
                                                            </GridTextbox>
                                                            <GridContainer style={{ marginLeft: "1px"}}>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Debit"
                                                                    id="EmpIdName"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    fullWidth
                                                                    inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    size="small"
                                                                    label="Credit"
                                                                    id="EmpIdName"
                                                                    variant="outlined"
                                                                    type="text"
                                                                    fullWidth
                                                                    inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <Button color="facebook">Add</Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                        </GridContainer>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Vouchers Detail"}
                                            columns={[
                                                { label: "Campus" }, { label: "Department" },
                                                { label: "Ledger Account" }, { label: "Vr#" }, { label: "Tr#" },
                                                { label: "Cheque No" }, { label: "Date" }, { label: "Particulars" },
                                                { label: "Debit" }, { label: "Credit" }, { label: "Print" }, { label: "Edit" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                    <GridTextbox xs={12} sm={12} md={4} style={{ marginLeft: "50%" }}>
                                        <Button color="facebook"><SaveOutlined />Save</Button>
                                        <Button color="success">New</Button>
                                    </GridTextbox>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(CreateVoucher);