import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputLabel, FormControl, Select } from '@material-ui/core';
import SweetAlert from "react-bootstrap-sweetalert";
//import ReactToggle from "react-switch";
import { ContactMailOutlined as ParentIcon, SaveOutlined } from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
//import MUIDataTable from "mui-datatables";
//import { MuiThemeProvider } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";
import Moment from 'moment';
var Base_URL = localStorage.BaseURL;
const interviewTimeDD = [
    "--Select--", "08:00 AM", "08:30 AM",
    "09:00 AM", "09:30 AM", "10:00 AM",
    "10:30 AM", "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM", "01:00 PM",
    "01:30 PM", "02:00 PM", "02:30 PM",
    "03:00 PM", "03:30 PM", "04:00 PM"];
class InterviewDateTime extends React.Component {
    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        this.state = {
            registrationId: editRegData["registrationId"],
            regDate: editRegData["regDate"],
            regPaid: (editRegData["paid"] === "True") ? true : false,
            isTestCleared: (editRegData["isTestCleared"] === "True") ? true : false,
            testDate: (editRegData["testDate"] !== "") ? editRegData["testDate"] : "",
            existingDate: editRegData["interviewDate"],
            existingTime: editRegData["interviewTime"],
            interviewDate: (editRegData["interviewDate"] !== "") ? editRegData["interviewDate"] : new Date(),
            interviewTime: editRegData["interviewTime"],
            interviewDateState: '',
            interviewTimeState: '',
            saveUpdateBtnDisabled: true,
        };
        this.setValue = this.setValue.bind(this);
        this.LoadRegData = this.LoadRegData.bind(this);
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, saveUpdateBtnDisabled: false });
    }
    ValidateData = () => {
        var existDate = "";
        if (this.state.existingDate !== "") {
            existDate = Moment(this.state.existingDate).format('YYYY-MM-DD');
        }
        var interviewDate = Moment(this.state.interviewDate).format('YYYY-MM-DD');
        var regDate = Moment(this.state.regDate).format('YYYY-MM-DD');
        var currentDate = Moment(new Date).format('YYYY-MM-DD');
        if (interviewDate < regDate) {
            this.AlertMessage("Date Error", "You can't schedule interview before Registration Date.")
            this.setState({ interviewDateState: 'error' })
            return false;
        }
        else if (this.state.regPaid === false) {
            this.AlertMessage("Challan Error", "Student Registration Challan is not paid.")
            this.setState({ interviewDateState: 'error' })
            return false;
        }
        else if (this.state.isTestCleared === false) {
            this.AlertMessage("Test Error", "Student test is not cleared")
            this.setState({ interviewDateState: 'error' })
            return false;
        }
        else if ((currentDate === existDate) && (interviewDate === existDate) && (this.state.interviewTime === this.state.existingTime)) {
            this.AlertMessage("Detail Exist", "Interview Date & Time is same as saved.")
            return false;
        }
        else if (interviewDate < currentDate) {
            this.AlertMessage("Date Error", "You can't schedule test in previous Date.")
            return false;
        }
        else if ((this.state.interviewTime === "--Select--") || (this.state.interviewTime === ""))  {
            this.AlertMessage("Time Error", "Select Interview Time.?")
            this.setState({ interviewTimeState: 'error' })
            return false;
        }
        else {
            this.ConfirmMessage("SaveUpdateData", "Do you want to save Interview Date & Time!");
        }
    }
    SaveUpdateData = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        var interviewDate = Moment(this.state.interviewDate).format('YYYY-MM-DD')
        axios.post(Base_URL + "/api/StudentRegistration/SaveInterviewDateTime?RegistrationId=" + this.state.registrationId + "&InterviewDate=" + interviewDate + "&InterviewTime=" + this.state.interviewTime)
            .then(json => {
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.vmResponse.status, json.data.vmResponse.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })
    }
    //#region this Function is Called after Updating data
    LoadRegData() {
        this.hideAlert();
        this.props.LoadRegData(); //Function in StdEditRegistration.jsx
    }
    //#endregion
    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this[funName]()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.LoadRegData()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { interviewDateState, interviewDate, interviewTime, interviewTimeState } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody> <GridContainer style={{ placeContent: "center" }}>
                        <GridForm xs={12} sm={12} md={4}>
                            <Card>
                                <CardHeader color="info" icon>
                                    <CardIcon color="success">
                                        <ParentIcon />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>Interview Date & Time</h4>
                                    <hr />
                                </CardHeader>
                                <CardBody>
                                    <GridContainer >
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField success={interviewDateState === 'success'}
                                                error={interviewDateState === "error"}
                                                id="interviewDate"
                                                size="small"
                                                label={"Interview Date"}
                                                variant="outlined"
                                                fullWidth
                                                type="date"
                                                value={Moment(interviewDate).format('YYYY-MM-DD')}
                                                onChange={(event, value) => { this.setValue(event) }}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth success={interviewTimeState == "success"} error={interviewTimeState == "error"}>
                                                <InputLabel id="interviewTimelabel">Interview Time</InputLabel>
                                                <Select native labelId="interviewTimelabel" id="interviewTime" label="Interview Time" onChange={this.setValue} value={interviewTime} >
                                                    {interviewTimeDD.map((option) => (
                                                        <option value={option}>{option}</option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={6} lg={6} >
                                            <Button color="facebook" onClick={() => { this.ValidateData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Save </Button>
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                            <Button color="facebook" disabled={this.state.saveUpdateBtnDisabled}> Send SMS </Button>
                                        </GridTextbox>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridForm>
                    </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(InterviewDateTime);