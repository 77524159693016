
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
    SearchSharp, AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
    GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
    muiWithToolbar, tbleWithPrint, Moment, ErrorOutline, DateRange, AlternateEmail, Autocomplete, FormControl,
    InputLabel, Select, FormControlLabel, Switchb, Commute, GridItem, regularFormsStyle, NavigateNext, Home, extendedFormsStyle
} from '../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class StaffTransport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            paid: true,
            checkedA: true,
            checkedB: false,
            simpleSelect: "",
            firstname: "",
            firstnameState: "",
            lastname: "",
            lastnameState: "",
            email: "",
            emailState: ""
        };
        //#endregion
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Commute />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Staff Transport</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb"
                            style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/Transport" > Transport</Link>
                            <Typography color="textPrimary">Staff Transport</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "5px", marginBottom: "3px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Employee Detail</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Employee ID"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Employee Name"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Campus"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Sub Campus"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Department"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Designation"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Date of Joining"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Mobile No"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={8}>
                                                    <TextField
                                                        size="small"
                                                        label="Current Address"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={3} md={3}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Search Employee</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={7}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} 
                                                            label="Emp ID/ Name" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1}>
                                                    <Button color="facebook"><SearchSharp />Search</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={9} md={9}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Assign Transport</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params}
                                                            label="Mode" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params}
                                                            label="Vehicle Type" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params}
                                                            label="Vehicle" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params}
                                                            label="Zone" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} color="facebook" style={{ textAlign: "center"}}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                            checked={this.state.active}
                                                            onColor="#3b5998"
                                                            color="facebook"
                                                            className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                        />
                                                        <span>Enable</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1}>
                                                    <Button color="facebook"><SaveOutlined />Save</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Staff Transport Detail"}
                                            columns={[
                                                { label: "Mode" },
                                                { label: "Vehicle Type" },
                                                { label: "Vehicle" },
                                                { label: "Zone" },
                                                { label: "Edit" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(StaffTransport);
