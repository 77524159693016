
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class LoanManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            checkedA: true,
            checkedB: false,
            simpleSelect: "",
            firstname: "",
            firstnameState: "",
            lastname: "",
            lastnameState: "",
            email: "",
            emailState: "",
            special: true,
        };
        //#endregion 
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }

    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <PresentToAll />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Employee Loan Management</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Employee Loan Management</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Add Loan</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <div className={classes.mainDiv}>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Issue Date"
                                                            id="endDate"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={Moment(endDate).format('YYYY-MM-DD')}
                                                            type="date"
                                                            onChange={this.setDateValue}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Cheque No"
                                                            id="EmpIdName"
                                                            variant="outlined"
                                                            type="text"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Advance Amount"
                                                            id="EmpIdName"
                                                            variant="outlined"
                                                            type="text"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            size="small"
                                                            label="No of Installment"
                                                            id="EmpIdName"
                                                            variant="outlined"
                                                            type="text"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Installment Amount"
                                                            id="EmpIdName"
                                                            variant="outlined"
                                                            type="text"
                                                            fullWidth
                                                            inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <TextField
                                                            size="small"
                                                            label="Starting Month"
                                                            id="endDate"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={Moment(endDate).format('YYYY-MM-DD')}
                                                            type="date"
                                                            onChange={this.setDateValue}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={1} style={{ marginleft: "5px" }}>
                                                        <Button color="facebook" onClick={this.searchBtnClick}><SaveOutlined />Save</Button>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={2}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "special")}
                                                                checked={this.state.special}
                                                                onColor="#3b5998"
                                                                borderRadius={4}
                                                                handleDiameter={22}
                                                                className={classes.toggleStyle}
                                                                uncheckedIcon={<div
                                                                    className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span>Special</span>
                                                        </label>
                                                    </GridTextbox>
                                                </GridContainer>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Loan Detail"}
                                            columns={[
                                                { label: "Load Loan" }, { label: "Loan Id" },
                                                { label: "Issue Date" }, { label: "Advance Amount" },
                                                { label: "No of Installment" }, { label: "Instllment Amount" },
                                                { label: "Status" }, { label: "Start Date" },
                                                { label: "Cheque No" },

                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Installments Detail"}
                                            columns={[
                                                { label: "Loan Id" }, { label: "Installment Month" },
                                                { label: "Installment Amount" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} lg={12} style={{ marginLeft: "35%" }}>
                                                    <Button color="facebook">Import</Button>
                                                    <Button color="facebook">Daily</Button>
                                                    <Button color="facebook">Monthly</Button>
                                                    <Button color="facebook">Monthly Ledger</Button>
                                                    <Button color="facebook"><SaveOutlined />Save</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card >
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion 
}
export default withStyles(formStyle)(LoanManagement);