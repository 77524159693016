import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class LeaveFiscalYear  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fiscalYearId: 0,
            fiscalYearIdState: "",
            fiscalYearName: "",
            fiscalYearNameState: "",
            startDate: new Date(),
            startDateState: "",
            endDate: new Date(),
            endDateState: "",

            eOBIId: 0,
            basicSalary: 0,
            basicSalaryState: "",
            perAge: 0,
            perAgeState: "",
            fromDate: new Date(),
            fromDateState: "",
            toDate: new Date(),
            toDateState: "",
            saveActive: true,
            searchActive: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            leaveFiscalYearData:[],
            //#region Datatable Columns and Data
            //#region Datatable Columns
            columns: [{
                name: "fiscalYearName",
                label: "Leave Fiscal Year",
                options: {
                    filter: true,
                }
            },  {
                name: "startDate",
                label: "Start Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY'));
                    }
                }
            }, {
                name: "endDate",
                label: "End Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY'));
                    }
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "fiscalYearId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadFiscalYear = this.loadFiscalYear.bind(this);
        this.ClearTextfieldStates = this.ClearTextfieldStates.bind(this);

    }
    loadFiscalYear = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/LeaveFiscalYear?Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                this.setState({ leaveFiscalYearData: data.fiscalLeaveList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    change(event, stateName, type) {
        debugger;
        switch (type) {
            case "fiscalYearName":
                if (event.target.value === "") {
                    this.setState({
                        [stateName + "State"]: "error"
                    });
                } else {
                   
                         this.setState({
                             [stateName + "State"]: "success"
                    });
                }
                break;
            case "startDate":
                this.setState({
                    stateName: event.target.value
                });
                break;
            case "endDate":
                this.setState({
                    stateName: event.target.value
                });
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        debugger;
        if (this.state.fiscalYearNameState === "error" || this.state.fiscalYearNameState === "" ) {
            this.setState({
                fiscalYearNameState: "error"
            })
            return;
        }
        else if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
            this.setState({
                startDateState: "error",
                endDateState: "error"
            })
            return;
        } else {
            this.setState({
                startDateState: "success",
                endDateState: "success"
            })
            if (this.state.fiscalYearId === 0) {
                this.confirmMessageSave();
            } else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                FiscalYearId: this.state.fiscalYearId,
                FiscalYearName: this.state.fiscalYearName,
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                Active: this.state.saveActive
            })
        };
        fetch(Base_URL + "/api/primaryDataHR/LeaveFiscalYear", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Fiscal Year will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Fiscal Year will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });

        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (fiscalYearId) => {
        debugger;
        let obj = this.state.leaveFiscalYearData.find(o => o.fiscalYearId === fiscalYearId);
        this.setState({ fiscalYearId: obj.fiscalYearId, fiscalYearName: obj.fiscalYearName, fiscalYearNameState: "success",startDate: obj.startDate, endDate: obj.endDate, saveActive: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, fiscalYearId: 0, fiscalYearNameState: "", fiscalYearName:"",  startDate: new Date(), toDate: new Date(), saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.loadFiscalYear();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12} lg={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Leave Fiscal Year</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadFiscalYear} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Leave Fiscal Year</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.fiscalYearName === "success"}
                                                    error={this.state.fiscalYearName === "error"}
                                                    value={this.state.fiscalYearName}
                                                    size="small"
                                                    label="Fiscal Year Name "
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    type="text"
                                                    inputProps={{ onChange: event => this.change(event, "fiscalYearName", "fiscalYearName") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    success={this.state.startDateState === "success"}
                                                    error={this.state.startDateState === "error"}
                                                    size="small"
                                                    label="From Date"
                                                    id="startDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={Moment(this.state.startDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "startDate", "startDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    success={this.state.endDateState === "success"}
                                                    error={this.state.endDateState === "error"}
                                                    size="small"
                                                    label="End Date"
                                                    id="endDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={Moment(this.state.endDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "endDate", "endDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "saveActive")}
                                                        checked={this.state.saveActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Leave Fiscal Year"}
                                        data={this.state.leaveFiscalYearData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(LeaveFiscalYear );