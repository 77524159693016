import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined, GridContainer, GridForm,
    GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint, Moment,
    axios, SearchSharp, AddIcon
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;
class LeaveTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            leaveTypeId: 0,
            leaveType: "",
            leaveTypeState: "",
            paidLeave: true,
            saveActive: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            //#region Datatable Columns and Data
            leaveTypesData: [],
            //#region Datatable Columns
            columns: [{
                name: "leaveType",
                label: "Leave Type",
                options: {
                    filter: true,
                }
            }, {
                name: "paidLeave",
                label: "Paid Leave",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "leaveTypeId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadleaveTypesData = this.loadleaveTypesData.bind(this);
    }
    loadleaveTypesData = () => {
        fetch(Base_URL + "/api/primaryDataHR/LeaveTypes?Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                this.setState({ leaveTypesData: data.leaveTypesList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "leaveType":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        if (this.state.leaveTypeState === "" || this.state.leaveTypeState === "error" || this.state.leaveType.length == 0) {
            this.setState({ leaveTypeState: "error" });
            return;
        } else {
            if (this.state.leaveTypeId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + '/api/primaryDataHR/leaveTypes', {
            LeaveTypeId: this.state.leaveTypeId,
            LeaveType: this.state.leaveType,
            PaidLeave: this.state.paidLeave,
            Active: this.state.saveActive
        })
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Leave Type will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Leave Type will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (leaveTypeId) => {
        let obj = this.state.leaveTypesData.find(o => o.leaveTypeId === leaveTypeId);
        this.setState({ leaveTypeId: obj.leaveTypeId, leaveType: obj.leaveType, leaveTypeState: "success", saveActive: obj.active, paidLeave: obj.paidLeave, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, leaveTypeId: 0, leaveType: "", leaveTypeState: "", saveActive: false, paidLeave: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.loadleaveTypesData();
    }
    render() {
        const { classes } = this.props;

        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Leave Types</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            {/*<GridTextbox xs={12} sm={12} md={2} style={{ textalign: "center" }}>*/}
                                            {/*   <label htmlfor="small-radius-switch">*/}
                                            {/*       <reacttoggle onchange={(event) => this.handletogglechange(event, "searchactive")} checked={this.state.searchactive} oncolor="#3b5998" width={60} classname={classes.togglestyle}*/}
                                            {/*            uncheckedicon={<div className={classes.toggleOff}>No</div>}*/}
                                            {/*            checkedicon={<div className={classes.toggleOn}>Yes</div>*/}
                                            {/*           } />*/}
                                            {/*       <span> active</span>*/}
                                            {/*   </label>*/}
                                            {/*</GridTextbox>*/}
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadleaveTypesData} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Leave Type</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.leaveTypeState === "success"}
                                                    error={this.state.leaveTypeState === "error"}
                                                    value={this.state.leaveType}
                                                    size="small"
                                                    label="Leave Type"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "leaveType", "leaveType", 1), type: "text" }}
                                                />
                                            </GridTextbox>
                                            {/*<GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>*/}
                                            {/*    <label htmlFor="small-radius-switch">*/}
                                            {/*        <ReactToggle onChange={(event) => this.handleToggleChange(event, "paidLeave")} checked={this.state.paidLeave} onColor="#3b5998" width={60} className={classes.toggleStyle}*/}
                                            {/*            uncheckedIcon={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 14, color: "white", paddingRight: 2 }}>No</div>}*/}
                                            {/*            checkedIcon={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 14, color: "white", paddingRight: 1 }}>Yes</div>*/}
                                            {/*            } />*/}
                                            {/*        <span> Paid</span>*/}
                                            {/*    </label>*/}
                                            {/*</GridTextbox>*/}
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "paidLeave")}
                                                        checked={this.state.paidLeave}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span>  Paid</span>
                                                </label>
                                            </GridTextbox>
                                            {/*<GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>*/}
                                            {/*    <label htmlFor="small-radius-switch">*/}
                                            {/*        <ReactToggle onChange={(event) => this.handleToggleChange(event, "saveActive")} checked={this.state.saveActive} onColor="#3b5998" width={60} className={classes.toggleStyle}*/}
                                            {/*            uncheckedIcon={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 14, color: "white", paddingRight: 2 }}>No</div>}*/}
                                            {/*            checkedIcon={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: 14, color: "white", paddingRight: 1 }}>Yes</div>*/}
                                            {/*            } />*/}
                                            {/*        <span> Active</span>*/}
                                            {/*    </label>*/}
                                            {/*</GridTextbox>*/}
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "saveActive")}
                                                        checked={this.state.saveActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Leave Types"}
                                        data={this.state.leaveTypesData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(LeaveTypes);