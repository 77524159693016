
//Import from Globel Component";
import {
          React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
          SearchSharp, AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
          GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
          muiWithToolbar, tbleWithPrint, Moment, ErrorOutline, DateRange, AlternateEmail, Autocomplete, FormControl,
          InputLabel, Select, FormControlLabel, Switchb
} from '../../components/Common/ImportAll'

const dateVal = {
    currentDate: new Date().toISOString().substring(0, 10)
};

var Base_URL = localStorage.BaseURL;
class OverdueEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
         
        };
       
       
    };
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <AlternateEmail />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}> Overdue Email</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/library" > Library</Link>
                            <Typography color="textPrimary">Overdue Email</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                    <GridContainer>
                                        <GridForm xs={12} sm={8} md={8}>
                                           <Card style={{ marginTop: "0px" }}>
                                              <CardBody>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                        <TextField
                                                            success={startDateState === "success"}
                                                            error={startDateState === "error"}
                                                            size="small"
                                                            label="Due Date From"
                                                            id="startDate"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={Moment(startDate).format('YYYY-MM-DD')}
                                                            type="date"
                                                            onChange={this.setDateValue}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "1%" }}>
                                                        <TextField
                                                            size="small"
                                                                label="Due Date To"
                                                            id="endDate"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={Moment(endDate).format('YYYY-MM-DD')}
                                                            type="date"
                                                            onChange={this.setDateValue}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "1%" }}>
                                                        <Button color="facebook"><SearchSharp />Search </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                           
                                               </CardBody>
                                            </Card>
                                           <Card style={{ marginTop: "0px" }}>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        columns={[
                                                            { label: "Select" },
                                                            { label: "Member Name" },
                                                            { label: "Campus" },
                                                            { label: "Book Title" },
                                                            { label: "Member Type" },
                                                            { label: "Issued By" },
                                                            { label: "Issue Date" },
                                                            { label: "Due Date" },
                                                            { label: "Email" },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={4} md={4}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardHeader color="success" icon>
                                                    <h4 className={classes.cardIconTitle}>Email Mask</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Noreply@bahriatownschool.edu.pk"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Subject"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Message"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook"> Send </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        
                                         <GridContainer>
                                        
                                        </GridContainer>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(OverdueEmail);

