
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, GridContainer, GridForm, GridTextbox,
    Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint, Moment, Help, PeopleAlt
} from '../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class QuickSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    };

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <PeopleAlt />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Quick Search</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdmanagementhome" >Student Management</Link>
                            <Typography color="textPrimary">Quick Search</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={2}>
                                                            <TextField
                                                                success={this.state.NameState === "success"}
                                                                error={this.state.NameState === "error"}
                                                                size="small"
                                                                label="Registration No"
                                                                id="Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.Name}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook"><SearchSharp />Search</Button>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook">History</Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Vouchers Detail"}
                                                    columns={[
                                                        { label: "Session" },
                                                        { label: "Campus" },
                                                        { label: "Class" },
                                                        { label: "Section" },
                                                        { label: "Edit" },
                                                        { label: "Registration" },
                                                        { label: "Name" },
                                                        { label: "Voucher#" },
                                                        { label: "Paid" },
                                                        { label: "Amount" },
                                                        { label: "Balance" },
                                                        { label: "Print" },
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(QuickSearch);

