import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider,EditOutlined, SaveOutlined,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment, axios, SearchIcon
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class StdSession extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //#region Datatable Columns
            columns: [{
                name: "sessionName",
                label: "Session Name",

            }, {
                name: "startDate",
                label: "Start Date",
                options: {
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }

            }, {
                name: "endDate",
                label: "End Date",
                options: {
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }

            },
            {
                name: "active",
                label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }

            }, {
                name: "sessionId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            searchCheck: true,
            sessionsList: [],
            sessionId: -1,
            sessionIdState: '',
            sessionName: '',
            sessionNameState: '',
            startDate: new Date(),
            startDateState: '',
            endDate: new Date(), 
            active: true,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    LoadTextbox(sessionId) {
        let obj = this.state.sessionsList.find(o => o.sessionId === sessionId);
        this.setState({ sessionName: obj.sessionName, startDate: obj.startDate, endDate: obj.endDate, sessionId: obj.sessionId, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }

    SaveUpdateSession = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        var d1 = Moment(this.state.startDate).format('DD-MMM-YYYY');
        var d2 = Moment(this.state.endDate).format('DD-MMM-YYYY');
        axios.post(Base_URL + "/StudentPrimaryData/SaveUpdateSession?sessionId=" + this.state.sessionId + "&sessionName=" + this.state.sessionName + "&startDate=" + d1 + "&endDate=" + d2 + "&active=" + this.state.active)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    ClearTextfieldStates = () => {
        this.setState({
            sessionId: -1, sessionIdState: '', sessionName: '', sessionNameState: '', startDate: new Date(), endDate: new Date(), active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }

    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.sessionName === "") {
            this.setState({ sessionNameState: "error" });
            return;
        }
        else if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
            this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
            this.setState({ startDateState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    //#endregion 

    //#region Backend Data Fetching and Saving

    LoadData = () => {
        fetch(Base_URL + "/StudentPrimaryData/LoadSessions?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ sessionsList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateSession()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save session detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { sessionsList, columns, sessionName, sessionNameState, startDate, startDateState, endDate, active, searchCheck } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} borderRadius={4}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        handleDiameter={22} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Session</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={startDateState === "success"}
                                                        error={startDateState === "error"}
                                                        size="small"
                                                        label="Start Date"
                                                        id="startDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(startDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="End Date"
                                                        id="endDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(endDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={sessionNameState === "success"}
                                                        error={sessionNameState === "error"}
                                                        disabled="true"
                                                        size="small"
                                                        label="Session Name"
                                                        id="sessionName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={sessionName}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle} borderRadius={4}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Session List"}
                                        data={sessionsList}
                                        columns={columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdSession);
