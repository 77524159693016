
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Sms,
    AddIcon, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class SMSInquiry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchCheck: true,
            masksmsId: -1,
            mask: '',
            password: '',
            userId: '',
            url: '',
            active: false,
            smsInquiryList: [],
            maskState: '',
            passwordState: '',
            userIdState: '',
            urlState: '',
            alert: null,
            show: false,
            checked: false, saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0, campusId: 0, campusIdState: '',
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    ClearTextfieldStates = () => {
        this.setState({
            masksmsId: -1, mask: '', password: '', active: false, userId: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            url: '', campusIdState: '', campusId: 0, campusIdIndex: -1, maskState: '',
            passwordState: '',
            userIdState: '',
            urlState: '',
        });
        this.hideAlert();
        this.LoadData();
    }

    validateData = () => {
        if (this.state.campusId === 0) {
            this.setState({ campusIdState: "error" });
            return;
        }
        else if (this.state.mask === "") {
            this.setState({ maskState: "error" });
            return;
        }
        else if (this.state.password === '') {
            this.setState({ passwordState: "error" });
            return;
        }
        else if (this.state.userId === '') {
            this.setState({ userIdState: "error" });
            return;
        }
        else if (!this.verifyUrl(this.state.url)) {
            this.setState({ urlState: "error" });
            return;
        }

        else {
            this.ConfirmMessage();
        }
    }
    LoadData = () => {
        fetch(Base_URL + "/api/SMSInquiry/LoadSMSInquiry?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ smsInquiryList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" })
    }

    componentDidMount() {
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                this.setState({ campusDD: data, campusId: data[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    SaveUpdateSMSInquiry = () => {
        this.hideAlert();

        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/SMSInquiry/SaveUpdateSMSInquiry?MasksmsId=" + this.state.masksmsId + "&Mask=" + this.state.mask + "&UserId=" + this.state.userId + "&Url=" + this.state.url + "&CampusId=" + this.state.campusId + "&Password=" + this.state.password + "&active=" + this.state.active)
            .then(json => {

                this.SuccessMessage(json.data.status, json.data.message)

            })
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(masksmsId) {

        let obj = this.state.smsInquiryList.find(o => o.masksmsId === masksmsId);
        this.setState({ mask: obj.mask, masksmsId: obj.masksmsId, password: obj.password, userId: obj.userId, url: obj.url, campusId: obj.campusId, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ campusIdIndex: this.state.campusDD.findIndex(x => x.campusId === obj.campusId) });
    }
    // verifies if value is a valid URL
    verifyUrl(value) {
        try {
            new URL(value);
            return true;
        } catch (_) {
            return false;
        }
    }
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateSMSInquiry()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save SMS Inquiry detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { smsInquiryList, mask, password, userId, url, active, searchCheck, userIdState, maskState,
            passwordState, urlState, campusIdState, campusDD, campusIdIndex } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="warning" icon>
                        <CardIcon color="warning">
                            <Sms />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>SMS Balance Inquiry</h4>
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "3px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">SMS Inquiry</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "5px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "0px", paddingLeft: "0px" }}>
                        <CardBody>
                            {this.state.alert}
                            <GridContainer>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "1px", marginBottom: "10px" }}>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                                borderRadius={4}
                                                                handleDiameter={22}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Active</span>
                                                        </label>
                                                    </GridTextbox>
                                                    <GridTextbox>
                                                        <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                                <CardHeader>
                                                    <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                                    <h4 className={classes.cardIconTitle}>SMS Inquiry</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <form>
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={1}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    id="campusId"
                                                                    size="small"
                                                                    options={campusDD}
                                                                    getOptionLabel={(option) => option.campusName}
                                                                    value={campusDD[campusIdIndex]}
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                                                        error={campusIdState === "error"} label="Campus" variant="outlined" />}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={maskState === "success"}
                                                                    error={maskState === "error"}
                                                                    size="small"
                                                                    label="Mask"
                                                                    id="mask"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={mask}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={passwordState === "success"}
                                                                    error={passwordState === "error"}
                                                                    size="small"
                                                                    label="User Id"
                                                                    id="userId"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={userId}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={userIdState === "success"}
                                                                    error={userIdState === "error"}
                                                                    size="small"
                                                                    label="Password"
                                                                    id="password"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={password}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={urlState === "success"}
                                                                    error={urlState === "error"}
                                                                    size="small"
                                                                    label="Url"
                                                                    id="url"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={url}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" borderRadius={4}
                                                                        handleDiameter={22} className={classes.toggleStyle}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                        } />
                                                                    <span> Active</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}>Add New</Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                    </form>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"SMS Inquiry"}
                                                    data={smsInquiryList}
                                                    columns={[{
                                                        name: "campusName",
                                                        label: "Campus",
                                                    },

                                                    {
                                                        name: "mask",
                                                        label: "Mask",
                                                    },
                                                    {
                                                        name: "userId",
                                                        label: "User Id",
                                                    },
                                                    {
                                                        name: "password",
                                                        label: "Password",
                                                    },
                                                    {
                                                        name: "url",
                                                        label: "Url",
                                                    },
                                                    {
                                                        name: "balance",
                                                        label: "Balance",
                                                    },
                                                    {
                                                        name: "active",
                                                        label: "Active",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                            }
                                                        }

                                                    },

                                                    {
                                                        name: "masksmsId",
                                                        label: "Edit",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                            }
                                                        }
                                                    }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(SMSInquiry);
