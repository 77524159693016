import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment, axios, Autocomplete
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;
class LongLeaveTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quotaId: 0,
            quotaIdState:"",
            leaveTypeId: 0,
            leaveTypeIdState: "",
            quota: 0,
            quotaState: "",
            leaveType: "",
            leaveTypeState: "",
            quota: 0,
            quotaState:"",
            saveActive: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            //#region Datatable Columns and Data
            leaveTypesData: [],
            leaveTypeIdIndex: 0,
            leaveTypeId: 0,
            leaveTypeIdState: '',
            leaveTypeDD: [{ leaveTypeId: -1, leaveType: "All" }],
            //#region Datatable Columns
            columns: [{
                name: "leaveType",
                label: "Leave Type",
                options: {
                    filter: true,
                }
            }, {
                name: "quota",
                label: "Quota",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "quotaId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadleaveTypesData = this.loadleaveTypesData.bind(this);
    }
    async componentDidMount() {
        this.longLeaveDD();
    }
    async longLeaveDD() {
        fetch(Base_URL + "/api/Dropdowns/LongLeaveQuota").then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ leaveTypeDD: data.longLeaveQuotaList, leaveTypeId: data.longLeaveQuotaList[0]['leaveTypeId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    loadleaveTypesData = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/LongLeaveQuota?Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                this.setState({ leaveTypesData: data.leaveQuotaList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        debugger;
        switch (type) {
            case "quota":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        debugger;
        if (this.state.quotaState === "" || this.state.quotaState === "error"||this.state.quota.length === 0) {
            this.setState({ quotaState: "error" });
            return;
        } else {
            if (this.state.quotaId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        debugger;
        let longLeaveQuota = {
            QuotaId: this.state.quotaId,
            LeaveTypeId: this.state.leaveTypeId,
            Quota: parseInt(this.state.quota),
            Active: this.state.saveActive
        }
        console.log(longLeaveQuota);
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + '/api/PrimaryDataHR/LongLeaveQuota', longLeaveQuota)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Leave Type will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Leave Type will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    handleChange(value, type) {
        debugger;
        switch (type) {
            case "saveleaveId":
                if (value !== null) {
                    this.setState({
                        saveleaveId: value.leaveId,
                        saveleaveIdState: "success",
                        saveleaveIndex: this.state.LongLeaveQuotaDD.findIndex(x => x.leaveId === value.leaveId)
                    });
                    this.longLeaveDD();
                } else {
                    this.setState({
                        saveleaveId: 0,
                        saveleaveIdState: "error",
                        saveleaveIndex: -1
                    });
                }
                break;
            default:
                break;
        }
    };
    //#endregion
    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" })
    }

    LoadTextbox = (quotaId) => {
        debugger;
        let obj = this.state.leaveTypesData.find(o => o.quotaId === quotaId);
        this.setState({ quotaId: obj.quotaId, leaveType:obj.leaveType, quota: obj.quota, quotaState: "success", saveActive: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (this.state.leaveTypeDD.find(x => x.leaveTypeId === obj.leaveTypeId) != null) {
            debugger;
            this.setState({
                leaveTypeId: obj.leaveTypeId,
                leaveTypeIdIndex: this.state.leaveTypeDD.findIndex(x => x.leaveTypeId === obj.leaveTypeId),
                saveLevelIdState: "success"
            });
        }
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, quota: 0, quotaState: "", leaveTypeId:0, leaveTypeState: "", saveActive: true, paidLeave: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.setState({ leaveTypeIdIndex: 0, leaveTypeId: this.state.leaveTypeDD[0].leaveTypeId });
        this.loadleaveTypesData();
    }
    render() {
        const { classes } = this.props;

        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Long Leave Quota</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadleaveTypesData} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Long Leave Quota</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="leaveTypeId"
                                                    size="small"
                                                    options={this.state.leaveTypeDD}
                                                    getOptionLabel={(option) => option.leaveType}
                                                    value={this.state.leaveTypeDD[this.state.leaveTypeIdIndex]}
                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                    renderInput={(params) => <TextField {...params} success={this.state.leaveTypeIdState === "success"}
                                                        error={this.state.leaveTypeIdState === "error"} label="Long Leave Type" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.quotaState === "success"}
                                                    error={this.state.quotaState === "error"}
                                                    value={this.state.quota}
                                                    size="small"
                                                    label="Long Leave Quota"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "quota", "quota", 1), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "saveActive")}
                                                        checked={this.state.saveActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Leave Types"}
                                        data={this.state.leaveTypesData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(LongLeaveTypes);