import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PropTypes from "prop-types";
import {
    Home as HomeIcon, NavigateNext as NavigateNextIcon,
    CastForEducation as StdManagementIcon, LibraryAdd as StdIcon, PeopleAlt, HowToReg as RegIcon, Search as SearchIcon,
    FileCopy as ApplicationIcon, TrackChanges as ShiftingArea, Refresh as AdmissionFreeze, LowPriority as FeeDiscount, GroupAddRounded,
    PermIdentityOutlined, Person, Photo, Cake, AssessmentOutlined, DonutSmallRounded, FindInPage,
    TransferWithinAStation, DiscFull, Equalizer, PresentToAll, List, AcUnit, Email, Class, Details, LockOpen,
    People, ViewList
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { events } from "variables/general.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";
var Base_URL = localStorage.BaseURL;
function handleClick(event) {
    alert("Breadcrumb");
}
class StdManagementHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: events,
            CampusRights: [{ "campusRightsId": null, "allowedCampusId": null }],
            UserRightsList: [{ "groupRightsId": null, "userGroupdId": null, "directoryId": null, "formName": null, "canView": false, "canInsert": false, "canUpdate": false, "canDelete": false }]
        };
    }
    state = {
        value: 0
    };
    componentDidMount() {
        this.GetUserRights();
        //this.GetCampusRights();
    }
    async GetUserRights() {
        try {
            const response = await (fetch(Base_URL + "/api/UserRights/GetUserOperationsRights"))
            if (response.ok) {
                debugger;
                const data = await response.json();
                debugger;
                if ((data.eception.exceptionToken == null || data.eception.exceptionToken === '')
                    && (data.eception.displayMessage == null || data.eception.displayMessage === '')
                    && data.usersRightsList != null && data.usersRightsList.length > 0) {
                    debugger;
                    this.setState({
                        UserRightsList: data.usersRightsList
                    });
                }
                else {
                    this.setState({
                        UserRightsList: [{ "groupRightsId": null, "userGroupdId": null, "directoryId": null, "formName": null, "canView": false, "canInsert": false, "canUpdate": false, "canDelete": false }]
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;
        const { CampusRights, UserRightsList } = this.state;
        if (UserRightsList != null && UserRightsList.length > 0 && UserRightsList[0].groupRightsId != null) {
            debugger;
            return (
                <div>
                    <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <StdManagementIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Student Management</h4>
                        </CardHeader>
                        <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                            <hr />
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                                <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                                <Typography color="textPrimary">Student Management</Typography>
                            </Breadcrumbs>
                            <hr />
                            <CardBody>
                                <GridContainer>
                                    {(() => {
                                        debugger;
                                        var obj = UserRightsList.find(x => x.directoryId == 1 && x.canView == true);
                                        if (obj !== null && typeof obj !== 'undefined') {
                                            return (
                                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                                    <Link from={"/"} to={"/app/stdnewregistration"} style={{ textDecoration: 'none' }}>
                                                        <Card>
                                                            <CardHeader color="success" stats icon>
                                                                <CardIcon color="success">
                                                                    <RegIcon />
                                                                </CardIcon>
                                                                <p className={classes.cardCategory}>New</p>
                                                                <h3 className={classes.cardTitle}> Registration </h3>
                                                            </CardHeader>
                                                            <CardFooter stats>
                                                                <div className={classes.stats}>
                                                                    <RegIcon />
                                                                    New Student Registration
                                                                </div>
                                                            </CardFooter>
                                                        </Card>
                                                    </Link>
                                                </GridItem>
                                            );
                                        }
                                    })()}
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/allregistration"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="primary" stats icon>
                                                    <CardIcon color="primary">
                                                        <PeopleAlt />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Registration</p>
                                                    <h3 className={classes.cardTitle}>Searching</h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <PeopleAlt />
                                                        All Registration
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentapplication"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="rose" stats icon>
                                                    <CardIcon color="rose">
                                                        <ApplicationIcon />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Student</p>
                                                    <h3 className={classes.cardTitle}> Application </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <ApplicationIcon />
                                                        Student Application
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentmanagement"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="rose" stats icon>
                                                    <CardIcon color="rose">
                                                        <ApplicationIcon />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Student</p>
                                                    <h3 className={classes.cardTitle}> Management </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <ApplicationIcon />
                                                        Student Management
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/admissionfreeze"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="warning" stats icon>
                                                    <CardIcon color="warning">
                                                        <AdmissionFreeze />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Admission</p>
                                                    <h3 className={classes.cardTitle}> Freeze </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <AdmissionFreeze />
                                                        Admission Freeze
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/feediscount"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="primary" stats icon>
                                                    <CardIcon color="primary">
                                                        <FeeDiscount />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Fee</p>
                                                    <h3 className={classes.cardTitle}> Discount </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <FeeDiscount />
                                                        Apply Discount
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentattendance"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="success" stats icon>
                                                    <CardIcon color="success">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Student</p>
                                                    <h3 className={classes.cardTitle}> Attendance </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <StdIcon />
                                                        Student Attendance
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/shiftingarea"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="danger" stats icon>
                                                    <CardIcon color="danger">
                                                        <ShiftingArea />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Shifting</p>
                                                    <h3 className={classes.cardTitle}>Area </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <ShiftingArea />
                                                        Student Shifting
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/quicksearch"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="success" stats icon>
                                                    <CardIcon color="success">
                                                        <SearchIcon />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Quick</p>
                                                    <h3 className={classes.cardTitle}>Search </h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <SearchIcon />
                                                        Quick Search
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <hr />
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                                <Typography color="textPrimary"><StdManagementIcon style={{ marginBottom: "-5px" }} />  Student Administration </Typography>
                            </Breadcrumbs>
                            <hr />
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/stdprimarydata"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <CardHeader color="info" stats icon>
                                                    <CardIcon color="info">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <p className={classes.cardCategory}>Student</p>
                                                    <h3 className={classes.cardTitle}>Primary Data</h3>
                                                </CardHeader>
                                                <CardFooter stats>
                                                    <div className={classes.stats}>
                                                        <StdIcon />
                                                        Primary Data Management
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <Breadcrumbs separator={<AssessmentOutlined fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                                <Typography color="textPrimary"><PermIdentityOutlined style={{ marginBottom: "-5px" }} />  Profile Reports </Typography>
                            </Breadcrumbs>
                            <hr />
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentsibling"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center> <People style={{ color: 'green', fontSize: '70' }} /> </center>
                                                <h3 className={classes.cardTitle}><center>Student Sibling</center> </h3>
                                                <p className={classes.cardCategory} ><center>Report</center></p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/bloodgroupwise"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center> <ViewList style={{ color: 'red', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Blood Group Wise</center> </h3>
                                                <p className={classes.cardCategory} ><center>Student List </center></p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/formatletterwise"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><Person style={{ color: 'magenta', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Format Letter Wise</center> </h3>
                                                <p className={classes.cardCategory} ><center>Student Profile </center></p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentphoto"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><Photo style={{ color: 'blue', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Photo </center> </h3>
                                                <p className={classes.cardCategory} ><center>Report </center></p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentbirthday"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><Cake style={{ color: 'purple', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Birthday </center> </h3>
                                                <p className={classes.cardCategory} ><center>Report </center></p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                                <Typography color="textPrimary"><PermIdentityOutlined style={{ marginBottom: "-5px" }} />  Other Reports </Typography>
                            </Breadcrumbs>
                            <hr />
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/registrationviewer"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><FindInPage style={{ color: 'Orange', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Registration Viewer</center> </h3>
                                                <p className={classes.cardCategory}><center>Report </center> </p>

                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentstrength"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><DonutSmallRounded style={{ color: 'Purple', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Strength</center> </h3>
                                                <p className={classes.cardCategory}><center>Report (New)</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentadmissionwithdrawal"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><TransferWithinAStation style={{ color: 'Lime', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Admission/Withdrawal</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/btscdiscounteligible"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><DiscFull style={{ color: 'Navy', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>BTSC Discount Eligible</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentfinalsettlement"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><Equalizer style={{ color: 'Brown', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Final Settlement</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentattendancelog"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><PresentToAll style={{ color: 'gold', fontSize: '70' }} /></center>

                                                <h3 className={classes.cardTitle}><center>Student Attendance Log</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studenthouselist"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><List style={{ color: 'Maroon', fontSize: '70' }} /></center>

                                                <h3 className={classes.cardTitle}><center>Student House List</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentadmissionfreeze"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center><AcUnit style={{ color: 'Red', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Admission Freeze</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>

                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentletters"} style={{ textDecoration: 'none' }}>
                                            <Card>

                                                <center><Email style={{ color: 'Red', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Letters</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/classteacher"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center> <Class style={{ color: 'Brown', fontSize: '70' }} /> </center>
                                                <h3 className={classes.cardTitle}><center>Class Teacher</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentstrengthdetail"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center> <Details style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Students Strength Detailed</center> </h3>
                                                <p className={classes.cardCategory}><center>Report</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={3} >
                                        <Link from={"/"} to={"/app/studentportallogin"} style={{ textDecoration: 'none' }}>
                                            <Card>
                                                <center> <LockOpen style={{ color: 'Dark Blue', fontSize: '70' }} /></center>
                                                <h3 className={classes.cardTitle}><center>Student Portal Login</center> </h3>
                                                <p className={classes.cardCategory}><center>Log</center> </p>
                                            </Card>
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </div>
                    </Card >
                </div>
            );
        }
        else {
            debugger;
            return (
                <div></div>
            );
        }
    }
}

StdManagementHome.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(StdManagementHome);
