import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment, axios, Edit, Autocomplete
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;
class EducationLevels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            educationLevelId: 0,
            levelName: "",
            levelNameState: "",
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            searchDegreeId: 0,
            searchDegreeIdIndex: 0,
            saveDegreeId: 0,
            saveDegreeIdState: "",
            saveDegreeIndex: 0,
            degreeLevelDD: [{ degreeId: 0, degreeName: "Select" }],
            degreeLevelDropDown: [{ degreeId: -1, degreeName: "All" }],
            //#region Datatable Columns and Data
            educationLevelsData: [],
            //#region Datatable Columns
            columns: [{
                name: "levelName",
                label: "Level Name",
                options: {
                    filter: true,
                }
            }, {
                name: "degreeName",
                label: "Degree Level",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "educationLevelId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadEducationLevels = this.loadEducationLevels.bind(this);
    }
    componentDidMount() {
        this.LoadDegreeLevels();
        this.DegreeLevelsDD();
    }
    async DegreeLevelsDD() {
        debugger;
        fetch(Base_URL + "/api/HR/DegreeLevels").then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ degreeLevelDD: data.degreeLevelsList });
                this.setState({ saveDegreeId: data.degreeLevelsList[0].degreeId, saveDegreeIdState: "success" });
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadDegreeLevels() {
        fetch(Base_URL + "/api/HR/DegreeLevels").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ degreeId: -1, degreeName: "All" });
                allList = allList.concat(data.degreeLevelsList);
                this.setState({
                    degreeLevelDropDown: allList,
                    searchDegreeId: allList[0]['degreeId'],
                    searchDegreeIdIndex:0
                });
            }).catch(error => {
                console.log(error);
            });
    }
    loadEducationLevels = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/EducationLevels?DegreeLevelId=" + this.state.searchDegreeId + "&Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ educationLevelsData: data.educationLevelsList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleChange(value, type) {
        debugger;
        switch (type) {
            case "searchDegreeId":
                if (value !== null) {
                    this.setState({
                        searchDegreeId: value.degreeId,
                        searchDegreeIdIndex: this.state.degreeLevelDropDown.findIndex(x => x.degreeId === value.degreeId),
                    });
                } else {
                    this.setState({
                        searchDegreeId: 0,
                        searchDegreeIdIndex:0,
                    });
                }
                break;
            case "saveDegreeId":
                if (value !== null) {
                    this.setState({
                        saveDegreeId: value.degreeId,
                        saveDegreeIdState: "success",
                        saveDegreeIndex: this.state.degreeLevelDD.findIndex(x => x.degreeId === value.degreeId)
                    });
                } else {
                    this.setState({ saveDegreeId: 0, saveDegreeIdState: "error" });
                }
                break;
            default:
                break;
        }
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "levelName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        if (this.state.levelNameState === "" || this.state.levelNameState === "error") {
            this.setState({ levelNameState: "error" });
            return;
        } else if (this.state.saveDegreeIdState === "" || this.state.saveDegreeIdState === "error") {
            this.setState({ saveDegreeIdState: "error" });
            return;
        } else {
            if (this.state.educationLevelId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + '/api/primaryDataHR/EducationLevels', {
            EducationLevelId: this.state.educationLevelId,
            LevelName: this.state.levelName,
            DegreeLevelId: this.state.saveDegreeId,
            Active: this.state.active
        })
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Education Level will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Education Level will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                )
            });

        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (educationLevelId) => {
        let obj = this.state.educationLevelsData.find(o => o.educationLevelId === educationLevelId);
        this.setState({ educationLevelId: obj.educationLevelId, levelName: obj.levelName, levelNameState: "success", saveDegreeId: obj.degreeLevelId, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (obj.degreeLevelId == null) {
            this.setState({ saveDegreeIdState: "" });
        }
        else {
            this.setState({ saveDegreeIdState: "success" });
        }
        this.setState({ saveDegreeIndex: this.state.degreeLevelDD.findIndex(x => x.degreeId === obj.degreeLevelId) });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, educationLevelId: 0, levelName: "", levelNameState: "", active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.setState({ saveDegreeIndex: 0, saveDegreeId: this.state.degreeLevelDD[0].degreeId });
        this.loadEducationLevels();
    }
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Education Levels</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.degreeLevelDropDown}
                                                    value={this.state.degreeLevelDropDown[this.state.searchDegreeIdIndex]}
                                                    getOptionLabel={(option) => option.degreeName}
                                                    onChange={(event, value) => this.handleChange(value, "searchDegreeId")}
                                                    renderInput={(params) => <TextField {...params} label="Degree Level" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadEducationLevels} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Education Level</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.levelNameState === "success"}
                                                    error={this.state.levelNameState === "error"}
                                                    value={this.state.levelName}
                                                    size="small"
                                                    label="Level Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "levelName", "levelName", 2), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.degreeLevelDD}
                                                    getOptionLabel={(option) => option.degreeName}
                                                    value={this.state.degreeLevelDD[this.state.saveDegreeIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveDegreeId")}
                                                    renderInput={(params) => <TextField {...params} label="Degree Level" variant="outlined"
                                                        success={this.state.saveDegreeIdState === "success"}
                                                        error={this.state.saveDegreeIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Education Levels"}
                                        data={this.state.educationLevelsData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(EducationLevels);