import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import ReactToggle from "react-switch";
import TextField from '@material-ui/core/TextField';
import { GroupAdd as  EditOutlined, AddCircle as AddIcon, Home as HomeIcon, NavigateNext as NavigateNextIcon, Add, Photo } from "@material-ui/icons";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import { formStyle, muiWithToolbar, tbleWithPrint, StyledBreadcrumb } from "assets/jss/customStyle/allCustomStyle";
var Base_URL = localStorage.BaseURL;
class NewItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manageCompaniesdata: [],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<Button color="facebook" onClick={() => this.LoadTextbox(value)}><EditOutlined /> Edit </Button>);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false,
            //#endregion
        };
    };
    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.Name === "") {
            this.setState({ NameState: "error" });
            return;
        }
        this.handleToggleChange = this.handleToggleChange.bind(this);
    }
    //#endregion 
    handleToggleChange(isVendor) {
        this.setState({ isVendor });
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <Add />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>New Item</h4>
                </CardHeader>
                {this.state.alert}
                <div className={classes.formDiv}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/inventory" >Inventory</Link>
                        <Typography color="textPrimary">Add New Item </Typography>
                    </Breadcrumbs>
                    <hr />
                    <GridContainer>
                        {this.state.alert}
                        <div className={classes.mainDiv}>
                            <CardBody>
                                <GridContainer>
                                    <GridForm xs={12} sm={12} md={8}>
                                        <Card>
                                            <CardHeader color="info" icon>
                                                <CardIcon color="success">
                                                    <Add />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Add New Inventory Item</h4>
                                                <hr />
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <TextField
                                                            success={this.state.NameState === "success"}
                                                            error={this.state.NameState === "error"}
                                                            size="small"
                                                            label="Code Prefix"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.Name}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <TextField
                                                            size="small"
                                                            label="Serial No"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.Name}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <TextField
                                                            size="small"
                                                            label="ItemName"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.Name}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }} >
                                                        <Autocomplete
                                                            size="small"
                                                            renderInput={(params) => <TextField {...params} label="Item Type" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <Autocomplete
                                                            size="small"
                                                            renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }} >
                                                        <TextField
                                                            size="small"
                                                            label="Specification"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.Name}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <Autocomplete
                                                            size="small"
                                                            renderInput={(params) => <TextField {...params} label="Company" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }} >
                                                        <Autocomplete
                                                            size="small"
                                                            renderInput={(params) => <TextField {...params} label="Unit" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <TextField
                                                            size="small"
                                                            label="Unit Price"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.Name}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={3} style={{ marginLeft: "3%" }}>
                                                        <TextField
                                                            size="small"
                                                            label="Alerts Qty"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.Name}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "3%" }}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")} 
                                                                onColor="#3b5998"
                                                                className={classes.toggleStyle}
                                                                borderRadius={3}
                                                                handleDiameter={22}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Active</span>
                                                        </label>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} lg={1} style={{marginLeft:"11%"}}>
                                                        <Button color="facebook" >Save </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    </GridForm>
                                    <GridForm xs={12} sm={12} md={4}>
                                        <Card>
                                            <CardHeader color="info" icon>
                                                <CardIcon color="success">
                                                    <Photo />
                                                </CardIcon>
                                                <h4 className={classes.cardIconTitle}>Photo</h4>
                                                <hr />
                                            </CardHeader>
                                            <CardBody>
                                                <GridContainer xs={12} sm={12} md={12}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <PictureUpload />
                                                        </GridItem>
                                                        <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "41%" }}>
                                                            <Button color="facebook" onClick={() => { this.myalert() }}>Reset</Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    </GridForm>
                                </GridContainer>
                            </CardBody>
                        </div>
                    </GridContainer>
                </div>
            </Card>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(NewItem);

