import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp, axios,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar,
    tbleWithPrint, Autocomplete, Moment
} from '../../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;
class Towns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            townId: 0,
            townName: "",
            townNameState: "",
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            searchCountryId: 0,
            searchCityId: 0,
            saveCityId: 0,
            saveCityIdNew: 0,
            saveCountryId: 0,
            saveCountryIdState: "",
            saveCityIdState: "",
            searchCountryIndex:0,
            searchCityIndex:0,
            saveCountryIndex: 0,
            saveCityIndex: 0,
            countryDD: [{ countryId: -1, countryName: "All" }],
            getCountryDD: [{ countryId: 0, countryName: "Select" }],
            cityDD: [{ cityId: -1, cityName: "All" }],
            saveCityDD: [{ cityId: 0, cityName: "Select" }],
            //#region Datatable Columns and Data
            townsData: [],
            //#region Datatable Columns
            columns: [{
                name: "countryName",
                label: "Country",
                options: {
                    filter: true,
                }
            }, {
                name: "cityName",
                label: "City",
                options: {
                    filter: true,
                }
            }, {
                name: "townName",
                label: "Town Name",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "townId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadTowns = this.loadTowns.bind(this);
        this.loadCitiesDD = this.loadCitiesDD.bind(this);
        this.loadSaveCityDD = this.loadSaveCityDD.bind(this);
        this.loadCitiesDD = this.loadCitiesDD.bind(this);
    }
    async componentDidMount() {
        this.getCountries();
        this.loadCountries();
       // this.loadCitiesDD();
    }
    //#region Search Dropdowns
    async loadCountries() {
        fetch(Base_URL + "/api/Dropdowns/Countries").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList = this.state.countryDD;
                allList = allList.concat(data.countryDDList);
                this.setState({ countryDD: allList, saveCountryId: allList[0]['countryId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    async loadCitiesDD(countryId) {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId).then(response => { return response.json(); })
            .then(data => {
                debugger;
                let allList = [];
                allList.push({ cityId: -1, cityName: "All" });
                allList = allList.concat(data.citiesDDList);
                this.setState({ cityDD: allList, saveCityId: allList[0]['cityId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion

    async getCountries() {

        const response = await (fetch(Base_URL + "/api/Dropdowns/Countries"))
        const data = await response.json();
        this.setState({ getCountryDD: data.countryDDList });
        this.setState({ saveCountryId: data.countryDDList[0].countryId, saveCountryIdState: "success" });
        this.loadSaveCityDD(data.countryDDList[0].countryId);
    }
    async loadSaveCityDD(countryId) {
        const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
        const data = await response.json();
        this.setState({ saveCityDD: data.citiesDDList });
        if (data.citiesDDList.length > 0) {
            this.setState({
                saveCityId: data.citiesDDList[0].cityId,
                saveCityIndex: 0,  
                saveCityIdState: "success"
            });
        }
        else {
            this.setState({
                cityId: 0,
                saveCityIndex: -1,
                saveCityIdState: ""
            })
        }
    }

    async loadEditCityDD(countryId, cityId) {
        const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
        const data = await response.json();
        this.setState({ saveCityDD: data.citiesDDList });
        debugger
        if (this.state.saveCityDD.find(x => x.cityId === cityId) != null) {
            this.setState({
                saveCityIndex: this.state.saveCityDD.findIndex(x => x.cityId === cityId),
                saveCityId: cityId,
                saveCityIdState: "success"
            })
        }
        else {
            this.setState({
                saveCityIndex: -1,
                saveCityId: 0,
                saveCityIdState: "error"
            })
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    loadTowns = () => {
        fetch(Base_URL + "/api/primaryData/Towns?CountryId=" + this.state.searchCountryId + "&CityId=" + this.state.searchCityId + "&Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                this.setState({ townsData: data.townsList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleChange(value, type) {
        debugger
        switch (type) {
            case "searchCountryId":
                if (value !== null) {
                    this.setState({
                        searchCountryId: value.countryId,
                        searchCountryIndex: this.state.countryDD.findIndex(x => x.countryId === value.countryId),
                    });
                    this.loadCitiesDD(value.countryId);
                } else {
                    this.setState({ searchCountryId: 0 });
                }
                break;
            case "saveCountryId":
                if (value !== null) {
                    this.setState({
                        saveCountryId: value.countryId,
                        saveCountryIdState: "success",
                        saveCountryIndex: this.state.getCountryDD.findIndex(x => x.countryId === value.countryId)
                    });
                    this.loadSaveCityDD(value.countryId);
                } else {
                    this.setState({ saveCountryId: 0, saveCountryIdState: "error" });
                }
                break;
            case "searchCityId":
                if (value !== null) {
                    this.setState({
                        searchCityId: value.cityId,
                        searchCityIndex: this.state.cityDD.findIndex(x => x.cityId === value.cityId),
                    });
                } else {
                    this.setState({ searchCityId: 0 });
                }
                break;
            case "saveCityId":
                if (value !== null) {
                    this.setState({
                        saveCityId: value.cityId,
                        saveCityIdState: "success",
                        saveCityIndex: this.state.saveCityDD.findIndex(x => x.cityId === value.cityId)
                    });
                } else {
                    this.setState({ saveCityId: 0, saveCityIdState: "error" });
                }
                break;
            default:
                break;
        }
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "townName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        debugger
        if (this.state.saveCountryIdState === "" || this.state.saveCountryIdState === "error") {
            this.setState({ saveCountryIdState: "error" });
            return;
        } else if (this.state.saveCityIdState === "" || this.state.saveCityIdState === "error") {
            this.setState({ saveCityIdState: "error" });
            return;
        }
        else if (this.state.townNameState === "" || this.state.townNameState === "error") {
            this.setState({ townNameState: "error" });
            return;
        } else {
            if (this.state.townId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + '/api/primaryData/Towns', {
            townId: this.state.townId,
            townName: this.state.townName,
            CityId: this.state.saveCityId,
            Active: this.state.active
        })
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new Town will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Town will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                )
            });

        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (townId) => {
        debugger
        let obj = this.state.townsData.find(o => o.townId === townId);
        this.setState({
            townId: obj.townId,
            saveCityId: obj.cityId,
            townName: obj.townName,
            townNameState: "success",
            active: obj.active,
            saveUpdateBtnDisabled: false,
            saveUpdateBtnText: 'Update',
            clearTextBtn: 'block'
        });
        if (this.state.countryDD.find(x => x.countryId === obj.countryId) != null) {
            this.setState({
                saveCountryId: obj.countryId,
                saveCountryIdState: "success",
                saveCountryIndex: this.state.getCountryDD.findIndex(x => x.countryId === obj.countryId)
            });
        }
        else {
            this.setState({
                saveCountryIdState: "error",
                saveCountryIndex: -1,
                saveCountryId: 0
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
        this.loadEditCityDD(obj.countryId, obj.cityId);
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null,
            townId: 0,
            townName: "",
            townNameState: "",
            active: false,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none'
        });
        if (this.state.countryDD.length > 0) {
            this.setState({
                saveCountryIndex: 0,
                saveCountryId: this.state.countryDD[0].countryId,
                saveCountryIdState: "success"
            })
            this.loadSaveCityDD(this.state.countryDD[0].countryId);
        }
        else {
            this.setState({
                saveCountryIndex: -1,
                saveCountryId: 0,
                saveCountryIdState: "",
                cityId: 0,
                saveCityIndex: -1,
                saveCityIdState: ""
            })
        }
        this.loadTowns();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.countryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.countryDD[this.state.searchCountryIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchCountryId")}
                                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.cityDD}
                                                    getOptionLabel={(option) => option.cityName}
                                                    value={this.state.cityDD[this.state.searchCityIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchCityId")}
                                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadTowns} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Town</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.getCountryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.getCountryDD[this.state.saveCountryIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveCountryId")}
                                                    renderInput={(params) => <TextField {...params} label="Country" variant="outlined"
                                                        success={this.state.saveCountryIdState === "success"}
                                                        error={this.state.saveCountryIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.saveCityDD}
                                                    getOptionLabel={(option) => option.cityName}
                                                    value={this.state.saveCityDD[this.state.saveCityIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.handleChange(value, "saveCityId")}
                                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined"
                                                        success={this.state.saveCityIdState === "success"}
                                                        error={this.state.saveCityIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.townNameState === "success"}
                                                    error={this.state.townNameState === "error"}
                                                    value={this.state.townName}
                                                    size="small"
                                                    label="Town Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "townName", "townName", 2), type: "text" }}
                                                />
                                            </GridTextbox>

                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Towns"}
                                        data={this.state.townsData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(Towns);