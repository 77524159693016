
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Sms,
    AddIcon, EditOutlined, CheckCircleOutlineOutlined, TableContainer
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseU
class AutomatedReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            automatedreportsarray: [],
            name: "title",
            label: "Title",
            lmsId: -1,
            reportFile: '',
            reportFileState: '',
            fromEmail: '',
            fromEmailState: '',
            subject: '',
            subjectState: '',
            to: '',
            toState: '',
            cc: '',
            ccState: '',
            msg: '',
            msgState: '',
            timeStatus: '',
            timeStatusState: '',
            eSettingIdState: '',
            eSettingDD: [{ eSettingId: -1, emailFrom: 'Select' }],
            eSettingIdIndex: 0,
            eSettingId: 0,
            timeIdState: '',
            timeDD: [{ timeId: -1, minutes: 'Select' }],
            timeIdIndex: 0,
            timeId: 0,
            hoursIdState: '',
            hoursDD: [{ hoursId: -1, HourNo: 'Select' }],
            hoursIdIndex: 0,
            hoursId: 0,

            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            searchCheck: true,
            status: false,
        };

        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    };

    async componentDidMount() {
        this.loadMinutesDD();
        this.loadhours();
        this.loadEmail();
    }
    // Dropdown Region
    async loadhours() {
        fetch(Base_URL + "/api/Dropdowns/LoadHours").then(response => { return response.json(); })
            .then(data => {
                this.setState({ hoursDD: data, hoursId: data[0]['hoursId'] });
            }).catch(error => {
                console.log(error);
            });
    }

    async loadMinutesDD() {
        fetch(Base_URL + "/api/Dropdowns/TotalMinutes").then(response => { return response.json(); })
            .then(data => {
                this.setState({ timeDD: data, timeId: data[0]['timeId'] });
            }).catch(error => {
                console.log(error);
            });
    }

    async loadEmail() {
        //debugger;
        fetch(Base_URL + "/api/Dropdowns/FromEmails").then(response => { return response.json(); })
            .then(data => {
                this.setState({ eSettingDD: data, eSettingId: data[0]['eSettingId'] });
            }).catch(error => {
                console.log(error);
            });
    }


    ClearTextfieldStates = () => {
        this.setState({
            lmsId: -1, status: false, reportFile: '', fromEmail: '', subject: '', mints: '', to: '', cc: '', timeStatus: '', hours: '', msg: '',
            reportFileState: '', fromEmailState: '', subjectState: '', toState: '', ccState: '', timeStatusState: '', msgState: '',
            eSettingId: 0, eSettingIdState: '', eSettingIdIndex: -1,
            hoursId: 0, hoursIdState: '', hoursIdIndex: -1,
            timeId: 0, timeIdState: '', timeIdIndex: -1,
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        debugger;
        if (this.state.reportFile === "") {
            this.setState({ reportFileState: "error" });
            return;
        }
        if (this.state.to === "") {
            this.setState({ toState: "error" });
            return;
        }
        if (this.state.cc === "") {
            this.setState({ ccState: "error" });
            return;
        }
        if (this.state.msg === "") {
            this.setState({ msgState: "error" });
            return;
        }
        if (this.state.timeStatus === "") {
            this.setState({ timeStatusState: "error" });
            return;
        }

        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkTitle) => {
        this.setState({ [checkTitle]: event });
    }
    LoadData = () => {

        fetch(Base_URL + "/api/AutomatedReports/AutomatedReportsList?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ automatedreportsarray: data });
            }).catch(error => {
                console.log(error);
            });
    }
    AddAutomatedReport = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        if (this.state.saveUpdateBtnText === 'Update') {
            axios.post(Base_URL + "/api/AutomatedReports/UpdateautomatedReports?ReportFile" + this.state.reportFile + "&FromEmail=" + this.state.fromEmail +
                "&Subject=" + this.state.subject + "&To=" + this.state.to + "&Cc=" + this.state.cc +
                "&Message=" + this.state.message + "&TimeStatus=" + this.state.timeStatus +
                "&Hours=" + this.state.hours + "&Minutes=" + this.state.minutes)
                .then(json => {
                    this.SuccessMessage(json.data.status, json.data.message)
                });
        }
        else {
            axios.post(Base_URL + "/api/AutomatedReports/SaveautomatedReports?ReportFile" + this.state.reportFile + "&FromEmail=" + this.state.fromEmail +
                "&Subject=" + this.state.subject + "&To=" + this.state.to + "&Cc=" + this.state.cc +
                "&Message=" + this.state.message + "&TimeStatus=" + this.state.timeStatus +
                "&Hours=" + this.state.hours + "&Minutes=" + this.state.minutes)
                .then(json => {
                    this.SuccessMessage(json.data.status, json.data.message)
                });
        }

    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }

    AddreportFile = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        if (this.state.saveUpdateBtnText === 'Update') {
            axios.post(Base_URL + "/api/ AutomatedReports/UpdateautomatedReports?ReportFile=" + this.state.reportFile + "&FromEmail=" + this.state.fromEmail + "&Subject=" + this.state.subject + "&To=" + this.state.to + "&Cc=" + this.state.cc + "&Msg=" + this.state.msg + "&TimeStatus=" + this.state.timeStatus + "&Hours=" + this.state.hoursId + "&Minutes=" + this.state.minutes + "&Status=" + this.state.status)
                .then(json => {
                    this.SuccessMessage(json.data.status, json.data.message)
                });
        }
        else {
            axios.post(Base_URL + "/api/ AutomatedReports/SaveautomatedReports?UserGroupName=" + this.state.userGroupName + "&status=" + this.state.status)
                .then(json => {
                    this.SuccessMessage(json.data.status, json.data.message)
                });
        }

    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(lmsId) {
        debugger;
        let obj = this.state.automatedreportsarray.find(o => o.id === lmsId);
        this.setState({ reportFile: obj.reportFile, subject: obj.subject, hours: obj.hours, timeStatus: obj.timeStatus, to: obj.to, lmsId: obj.id, status: obj.status, obj: obj.msg, cc: obj.cc, eSettingId: obj.eSettingId, timeId: obj.timeId, hoursId: obj.hoursId, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ eSettingIdIndex: this.state.eSettingDD.findIndex(x => x.eSettingId === obj.eSettingId) });
        this.setState({ timeIdIndex: this.state.timeDD.findIndex(x => x.timeId === obj.timeId) });
        this.setState({ hoursIdIndex: this.state.hoursDD.findIndex(x => x.hoursId === obj.hoursId) });
    }
    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" })
    }


    //#region Alert Messages
    ConfirmMessage() {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.AddAutomatedReport()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save User Group!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { automatedreportsarray, reportFile, reportFileState, subject, subjectState, hoursDD, hoursIdIndex, hoursIdState, to, toState, cc, ccState, timeStatus, timeStatusState, eSettingIdState, eSettingDD, eSettingIdIndex, timeIdState, timeDD, timeIdIndex, timeId, searchCheck, status, msg, msgState } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="danger" icon>
                        <CardIcon color="danger">
                            <CheckCircleOutlineOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Automated Reports</h4>
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "3px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">Automated Reports</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "5px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "0px", paddingLeft: "0px" }}>
                        <CardBody>
                            <GridContainer>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px", marginBottom: "10px" }}>
                                                <CardHeader>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                            <label htmlFor="small-radius-switch">
                                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                                    borderRadius={4}
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                    } />
                                                                <span> Active</span>
                                                            </label>
                                                        </GridTextbox>
                                                        <GridTextbox>
                                                            <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                    <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                                    <h4 className={classes.cardIconTitle}>Add Detail</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    <form>
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={reportFileState === "success"}
                                                                    error={reportFileState === "error"}
                                                                    size="small"
                                                                    label="Report File"
                                                                    id="reportFile"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={reportFile}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={subjectState === "success"}
                                                                    error={subjectState === "error"}
                                                                    size="small"
                                                                    label="Subject"
                                                                    id="subject"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={subject}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={3}>
                                                                <TextField
                                                                    success={msgState === "success"}
                                                                    error={msgState === "error"}
                                                                    size="small"
                                                                    label="Message"
                                                                    id="msg"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={msg}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={3}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    id="eSettingId"
                                                                    size="small"
                                                                    options={eSettingDD}
                                                                    getOptionLabel={(option) => option.emailFrom}
                                                                    value={eSettingDD[eSettingIdIndex]}
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} success={eSettingIdState === "success"}
                                                                        error={eSettingIdState === "error"} label="From Email" variant="outlined" />}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={toState === "success"}
                                                                    error={toState === "error"}
                                                                    size="small"
                                                                    label="To"
                                                                    id="to"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={to}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={ccState === "success"}
                                                                    error={ccState === "error"}
                                                                    size="small"
                                                                    label="CC"
                                                                    id="cc"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={cc}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>

                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={timeStatusState === "success"}
                                                                    error={timeStatusState === "error"}
                                                                    size="small"
                                                                    label="Time Status"
                                                                    id="timeStatus"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={timeStatus}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={1}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    id="hoursId"
                                                                    size="small"
                                                                    options={hoursDD}
                                                                    getOptionLabel={(option) => option.hourNo}
                                                                    value={hoursDD[hoursIdIndex]}
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} success={hoursIdState === "success"}
                                                                        error={hoursIdState === "error"} label="Hours" variant="outlined" />}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={1}>
                                                                <Autocomplete
                                                                    disableClearable
                                                                    id="timeId"
                                                                    size="small"
                                                                    options={timeDD}
                                                                    getOptionLabel={(option) => option.minutes}
                                                                    value={timeDD[timeIdIndex]}
                                                                    onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                    renderInput={(params) => <TextField {...params} success={timeIdState === "success"}
                                                                        error={timeIdState === "error"} label="Minutes" variant="outlined" />}
                                                                />
                                                            </GridTextbox>

                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "status")}
                                                                        checked={status} onColor="#3b5998" borderRadius={4}
                                                                        handleDiameter={22} className={classes.toggleStyle}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                        } />
                                                                    <span> Active</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}>
                                                                    <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="success" onClick={this.ClearTextfieldStates}
                                                                    style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                    </form>

                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            {/* <TableContainer style={{ width: 1200 }}>*/}
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Automated Reports"}
                                                    data={automatedreportsarray}

                                                    columns={[
                                                        {
                                                            name: "reportFile",
                                                            label: "Report File",
                                                            minWidth: 40,
                                                        },
                                                        {
                                                            name: "subject",
                                                            label: "Subject",

                                                        },
                                                        {
                                                            name: "msg",
                                                            label: "Message",

                                                        },
                                                        {
                                                            name: "fromEmail",
                                                            label: "From Email",

                                                        },
                                                        {
                                                            name: "to",
                                                            label: "To Address",

                                                        },
                                                        {
                                                            name: "cc",
                                                            label: "CC Address",

                                                        },
                                                        {
                                                            name: "timeStatus",
                                                            label: "Time Status",

                                                        },
                                                        {
                                                            name: "hours",
                                                            label: "Hours",

                                                        },
                                                        {
                                                            name: "mints",
                                                            label: "Minutes",

                                                        },
                                                        {
                                                            name: "status",
                                                            label: "Active",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                                }
                                                            }
                                                        },
                                                        {
                                                            name: "id",
                                                            label: "Edit",
                                                            options: {
                                                                customBodyRender: (value) => {
                                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                                }
                                                            }
                                                        }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                            {/*</TableContainer>*/}
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>

                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    #endregion
}
export default withStyles(formStyle)(AutomatedReports);
