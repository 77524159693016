import {
    container,
    cardTitle,
    primaryColor,
    whiteColor,
    grayColor,
    blackColor,
    hexToRgb
} from "assets/jss/material-dashboard-pro-react.jsx";

const loginPageStyle = theme => ({
    //checkbox design
    checkRoot: {
        padding: "14px"
    },
    checked: {
        color: primaryColor[0] + "!important"
    },
    checkedIcon: {
        width: "20px",
        height: "20px",
        border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
        borderRadius: "3px"
    },
    uncheckedIcon: {
        width: "0px",
        height: "0px",
        padding: "9px",
        border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
        borderRadius: "3px"
    },
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    cardTitle: {
        ...cardTitle,
        color: whiteColor
    },
    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: whiteColor
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    inputAdornment: {
        marginRight: "18px"
    },
    inputAdornmentIcon: {
        color: grayColor[6]
    },
    cardHidden: {
        opacity: "0",
        transform: "translate3d(0, -60px, 0)"
    },
    cardHeader: {
        marginBottom: "20px"
    },
    socialLine: {
        padding: "0.9375rem 0"
    },
    //authStyle
    wrapper: {
        height: "auto",
        minHeight: "100vh",
        position: "relative",
        top: "0"
    },
    fullPage: {
        padding: "120px 0",
        position: "relative",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        color: whiteColor,
        alignItems: "center",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            minHeight: "fit-content!important"
        },
        "& footer": {
            position: "absolute",
            bottom: "0",
            width: "100%",
            border: "none !important"
        },
        "&:before": {
            backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
        },
        "&:before,&:after": {
            display: "block",
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            zIndex: "2"
        }
    },
    fullPageRoute: {
        padding: "0px",
        position: "relative",
        minHeight: "100vh",
        display: "flex!important",
        margin: "0",
        border: "0",
        color: whiteColor,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            minHeight: "fit-content!important"
        },
        "& footer": {
            position: "absolute",
            bottom: "0",
            width: "auto",
            border: "none !important"
        },
        "&:before": {
            backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)"
        },
        "&:before,&:after": {
            display: "block",
            content: '""',
            position: "absolute",
            width: "auto",
            height: "auto",
            top: "0",
            left: "0",
            zIndex: "2"
        }
    }
});

export default loginPageStyle;
