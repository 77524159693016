import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment, Autocomplete
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class Designation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            designationId: 0,
            designationName: "",
            designationNameState: "",
            departmentUrduName: "",
            designationUrduName: "",
            designationNameState:"",
            searchDepId: 0,
            saveLevelId: 0,
            saveLevelIdState: "",
            saveLevelIndex: 0,
            searchDepIdIndex: 0,
            departmentIdDD: [{ departmentId: 0, departmentName: "Select" }],
            DepartmentDropDown: [{ departmentId: -1, departmentName: "All" }],
            active: true,
            alert: null,
            saveActive:true,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            //#region Datatable Columns and Data
            designationData: [],
            //#region Datatable Columns
            columns: [{
                name: "departmentName",
                label: "Department Name",
                options: {
                    filter: true,
                }
            },
                {
                name: "designationName",
                label: "Designation Name",
                options: {
                    filter: true,
                }
            },  {
                    name: "designationNameUrdu",
                    label: "Designation Urdu Name",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                    name: "designationId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadDesignations = this.loadDesignations.bind(this);
    }
    async componentDidMount() {
        this.loadDepartmentDD();
        this.loadDepartmentDropDown();
    }
    async loadDepartmentDD() {
        debugger
        const response = await (fetch(Base_URL + "/api/Dropdowns/DepartmentDD"))
        const data = await response.json();
        debugger
        this.setState({
            departmentIdDD: data.departmentDDList
        });
        this.setState({
            saveLevelId: data.departmentDDList[0].departmentId,
            saveLevelIdState: "success",
        });
    }
    async loadDepartmentDropDown() {
        fetch(Base_URL + "/api/Dropdowns/DepartmentDD").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ departmentId: -1, departmentName: "All" });
                allList = allList.concat(data.departmentDDList);
                this.setState({ DepartmentDropDown: allList, searchDepId: allList[0]['departmentId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    loadDesignations = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/Designations?DepartmentId= " + this.state.searchDepId + "&Active=" + this.state.searchActive)
            .then(response => { return response.json() })
            .then(data => {
                debugger;
                this.setState({
                    designationData: data.designationsList
                })
            })
            .catch(error => { console.log(error) });
    }
    handleChange(value, type) {
        debugger
        switch (type) {
            case "searchDepId":
                if (value !== null) {
                    debugger
                    this.setState({
                        searchDepId: value.departmentId,
                        searchDepIdIndex: this.state.DepartmentDropDown.findIndex(x => x.departmentId === value.departmentId)
                    });
                } else {
                    this.setState({ searchDepId: 0 });
                }
                break;
            case "saveLevelId":
                if (value !== null) {
                    debugger;
                    this.setState({
                        saveLevelId: value.departmentId,
                        saveLevelIdState: "success",
                        saveLevelIndex: this.state.departmentIdDD.findIndex(x => x.departmentId === value.departmentId)
                    });
                } else {
                    this.setState({
                        saveLevelId: 0,
                        saveLevelIdState: "error",
                        saveLevelIndex: -1
                    });
                }
                break;
            default:
                break;
        }
    };
    handleToggleChange = (event, checkName) => {
        debugger;
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        debugger
        switch (type) {
            case "designationName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        stateName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "designationUrduName":
                this.setState({ stateName: event.target.value });
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        debugger;
        if (this.state.saveLevelIdState === "" || this.state.saveLevelIdState === "error") {
            this.setState({ saveLevelIdState: "error" });
            return;
        } else if (this.state.designationNameState !== "success") {
            this.setState({ designationNameState: "error" });
            return;
        } else {
            if (this.state.designationId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        }
    }
    save = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ DepartmentId: this.state.saveLevelId, DesignationId: this.state.designationId, DesignationName: this.state.designationName, DesignationNameUrdu: this.state.designationUrduName, Active: this.state.saveActive })
        };
        fetch(Base_URL + "/api/primaryDataHR/Designations", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new designation will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    designation will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}
                    </SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (designationId) => {
        debugger
        let obj = this.state.designationData.find(o => o.designationId === designationId);
        this.setState({ designationId: obj.designationId, designationName: obj.designationName, designationNameState: "success", active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        if (obj.departmentUrduName !== null) {
            this.setState({ designationUrduName: obj.designationNameUrdu });
        }
        else {
            this.setState({ designationUrduName: "" });
        }
        if (this.state.departmentIdDD.find(x => x.departmentId === obj.departmentId) != null) {
            debugger;
            this.setState({
                saveLevelId: obj.departmentId,
                saveLevelIndex: this.state.departmentIdDD.findIndex(x => x.departmentId === obj.departmentId),
                saveLevelIdState: "success"
            });
        }
        else {
            this.setState({
                saveLevelIndex: -1,
                saveLevelId: 0,
                saveLevelIdState: "error"
            });
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, designationId:0, departmentId: 0, designationName: "", designationNameState: "", designationNameUrdu: "", searchActive: true, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.setState({ saveLevelIndex: 0, saveLevelId: this.state.departmentIdDD[0].departmentId });
        this.loadDesignations();
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Designations</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.DepartmentDropDown}
                                                    getOptionLabel={(option) => option.departmentName}
                                                    value={this.state.DepartmentDropDown[this.state.searchDepIdIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "searchDepId")}
                                                    renderInput={(params) => <TextField {...params} label="Department" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.loadDesignations} > <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Designation</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.departmentIdDD}
                                                    getOptionLabel={(option) => option.departmentName}
                                                    value={this.state.departmentIdDD[this.state.saveLevelIndex]}
                                                    onChange={(event, value) => this.handleChange(value, "saveLevelId")}
                                                    renderInput={(params) => <TextField {...params} label="Departement" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.designationNameState === "success"}
                                                    error={this.state.designationNameState === "error"}
                                                    value={this.state.designationName}
                                                    size="small"
                                                    label="Designation Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "designationName", "designationName", 3), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    value={this.state.designationUrduName}
                                                    size="small"
                                                    label="Designation Urdu Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.change(event, "designationUrduName", "designationUrduName"), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "saveActive")}
                                                        checked={this.state.saveActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Designation"}
                                        data={this.state.designationData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(Designation);