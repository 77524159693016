
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon,
    AddIcon, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class UserGroups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            moduleId: -1,
            moduleArray: [],
            moduleName: '',
            moduleNameState: '',
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            searchCheck: true,
            dbStatus: false,

        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.LoadERPModuleData = this.LoadERPModuleData.bind(this);
    };
    ClearTextfieldStates = () => {
        this.setState({
            moduleId: -1, dbStatus: false, moduleName: '', saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        //this.hideAlert();
        // this.LoadData();
    }
    validateData = () => {
        if (this.state.moduleName === "") {
            this.setState({ moduleNameState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkTitle) => {
        this.setState({ [checkTitle]: event });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    LoadTextbox(moduleId) {
        debugger;
        let obj = this.state.moduleArray.find(o => o.erpModuleId === moduleId);
        this.setState({ moduleId: obj.erpModuleId, moduleName: obj.erpModuleName, dbStatus: obj.erpModuleActive, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }
    //#region Save Update Search Data
    SaveUpdateERPModule() {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/ITAdministration/ERPModuleSaveUpdate?ModuleID=" + this.state.moduleId + "&ModuleName=" + this.state.moduleName + "&Active=" + this.state.dbStatus)
            .then(json => {
                debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.response.status, json.data.response.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })
    }
    LoadERPModuleData() {
        debugger;
        fetch(Base_URL + "/api/ITAdministration/LoadERPModules?Active=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({ moduleArray: data.erpModule })
                }
                else {
                    this.AlertMessage("Error", data.exception.displayMessage);
                }
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateERPModule()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        debugger;
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
        this.ClearTextfieldStates();
        this.LoadERPModuleData();
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        this.ClearTextfieldStates();
    }
    //#endregion
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { moduleName, moduleNameState, searchCheck, dbStatus, userGroup, clearTextBtn, moduleArray } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <CardHeader>
                                        <h4 style={{ marginLeft: "1%" }} className={classes.cardIconTitle}>Load User Group</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ marginLeft: "1%" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")}
                                                        checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadERPModuleData}> <SearchIcon /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add User Group</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        success={moduleNameState === "success"}
                                                        error={moduleNameState === "error"}
                                                        size="small"
                                                        label="Module Name"
                                                        id="moduleName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={moduleName}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "dbStatus")}
                                                            checked={dbStatus} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"User Group"}
                                        data={moduleArray}
                                        columns={[
                                            {
                                                name: "erpModuleId",
                                                label: "Module ID",
                                            },
                                            {
                                                name: "erpModuleName",
                                                label: "Module Name",
                                            },
                                            {
                                                name: "erpModuleActive",
                                                label: "Active",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                    }
                                                }
                                            },
                                            {
                                                name: "erpModuleId",
                                                label: "Edit",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                    }
                                                }
                                            }
                                        ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(UserGroups);
