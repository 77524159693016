import {
    React, GridContainer, Card, CardHeader, CardBody, Box, Typography, PropTypes, CardIcon, Link, Tab, Breadcrumbs,
    NavigateNextIcon, Tabs, HomeIcon, PermDataSettingOutlined
} from '../../components/Common/ImportAll'
//#region Import Employee Views
import Departments from "./PrimaryDataTabs/Departments.jsx";
import Designation from "./PrimaryDataTabs/Designation.jsx";
import DocumentType from "./PrimaryDataTabs/DocumentType.jsx";
import EducationLevels from "./PrimaryDataTabs/EducationLevels.jsx";
import HrLeaveQuota from "./PrimaryDataTabs/HrLeaveQuota";
import HrShifts from "./PrimaryDataTabs/HrShifts";
import EOBI from "./PrimaryDataTabs/EOBI.jsx";
import FuelRates from "./PrimaryDataTabs/FuelRates.jsx";
import FuelTypes from "./PrimaryDataTabs/FuelTypes.jsx";
import HealthInsurancePlans from "./PrimaryDataTabs/HealthInsurancePlans.jsx";
import LeaveFiscalYear from "./PrimaryDataTabs/LeaveFiscalYear.jsx";
import LeaveTypes from "./PrimaryDataTabs/LeaveTypes.jsx";
import LongLeaveQouta from "./PrimaryDataTabs/LongLeaveQouta.jsx";
import LongLeaveType from "./PrimaryDataTabs/LongLeaveType.jsx";
import PlanType from "./PrimaryDataTabs/PlanType.jsx";
import EmployeeLeavingReasons from "./PrimaryDataTabs/EmployeeLeavingReasons.jsx";
import HRLevels from "./PrimaryDataTabs/HRLevels.jsx";
import DegreeLevels from "./PrimaryDataTabs/DegreeLevels.jsx"
import ApplicationStatuses from "./PrimaryDataTabs/ApplicationStatuses.jsx";
import MaritalStatuses from "./PrimaryDataTabs/MaritalStatuses.jsx";
import EmploymentStatuses from "./PrimaryDataTabs/EmploymentStatuses.jsx";
import HiringJustifications from "./PrimaryDataTabs/HiringJustifications.jsx";
import HRRelationships from "./PrimaryDataTabs/HRRelationships.jsx";
//#endregion

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ScrollableTabsButtonAuto() {
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="info" icon>
                    <CardIcon color="success">
                        <PermDataSettingOutlined />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>HR<small> Management</small></h4>
                    <hr style={{ marginTop: "15px", marginBottom: "4px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/HRPayroll" >HR & Payroll</Link>
                        <Typography color="textPrimary">HR Management</Typography>
                    </Breadcrumbs>

                    <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                    <CardBody>
                        <GridContainer>
                            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="on" style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary" textColor="primary" wrapped >
                                <Tab label="Levels" {...a11yProps(0)} />
                                <Tab label="Departments" {...a11yProps(1)} />
                                <Tab label="Designation" {...a11yProps(2)} />
                                <Tab label="Leave Quota" {...a11yProps(3)} />
                                <Tab label="Shifts" {...a11yProps(4)} />
                                <Tab label="Leave Types" {...a11yProps(5)} />
                                <Tab label="Long Leave Type" {...a11yProps(6)} />
                                <Tab label="Long Leave Qouta" {...a11yProps(7)} />
                                <Tab label="Leave Fiscal Year" {...a11yProps(8)} />
                                <Tab label="Degree Levels" {...a11yProps(9)} />
                                <Tab label="Education Levels" {...a11yProps(10)} />
                                <Tab label="Document Type" {...a11yProps(11)} />
                                <Tab label="Employee Leaving Reasons" {...a11yProps(12)} />
                                <Tab label="EOBI" {...a11yProps(13)} />
                                <Tab label="Plan Type" {...a11yProps(14)} />
                                <Tab label="Health Insurance Plan" {...a11yProps(15)} />
                                <Tab label="Fuel Types" {...a11yProps(16)} />
                                <Tab label="Fuel Rates" {...a11yProps(17)} />
                                <Tab label="Application Statuses" {...a11yProps(18)} />
                                <Tab label="Marital Statuses" {...a11yProps(19)} />
                                <Tab label="Employment Statuses" {...a11yProps(20)} />
                                <Tab label="Hiring Justifications" {...a11yProps(21)} />
                                <Tab label="Relationships" {...a11yProps(22)} />
                            </Tabs>
                            {selectedTab === 0 && <HRLevels />}
                            {selectedTab === 1 && <Departments />}
                            {selectedTab === 2 && <Designation />}
                            {selectedTab === 3 && <HrLeaveQuota />}
                            {selectedTab === 4 && <HrShifts />}
                            {selectedTab === 5 && <LeaveTypes />}
                            {selectedTab === 6 && <LongLeaveType />}
                            {selectedTab === 7 && <LongLeaveQouta />}
                            {selectedTab === 8 && <LeaveFiscalYear />}
                            {selectedTab === 9 && <DegreeLevels />}
                            {selectedTab === 10 && <EducationLevels />}
                            {selectedTab === 11 && <DocumentType  />}
                            {selectedTab === 12 && <EmployeeLeavingReasons  />}
                            {selectedTab === 13 && <EOBI />}
                            {selectedTab === 14 && <PlanType />}
                            {selectedTab === 15 && <HealthInsurancePlans />}
                            {selectedTab === 16 && <FuelTypes />}
                            {selectedTab === 17 && <FuelRates />}
                            {selectedTab === 18 && <ApplicationStatuses />}
                            {selectedTab === 19 && <MaritalStatuses />}
                            {selectedTab === 20 && <EmploymentStatuses />}
                            {selectedTab === 21 && <HiringJustifications />}
                            {selectedTab === 22 && <HRRelationships />}
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}
