
//Import from Globel Component";
import {
         React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
         SearchSharp, AddIcon, FindInPage, Breadcrumbs, PermContactCalendar, NavigateNextIcon, Link, HomeIcon, Typography,
         GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
         muiWithToolbar, tbleWithPrint,Moment
       } from '../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class ManageBook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };

    };

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <PermContactCalendar />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}> Manage Book</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/library" > Library</Link>
                            <Typography color="textPrimary">Manage Book</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                   <GridContainer>
                                     <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "0px" }}>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Category"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Author"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Publisher"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Campus"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Sub Sector"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Title/ISBN/Barcode"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook"><SearchSharp />Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                     <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            columns={[
                                                { label: "ISBN" },
                                                { label: "Campus" },
                                                { label: "Title" },
                                                { label: "Author " },
                                                { label: "Category" },
                                                { label: "Publisher" },
                                                { label: "QTY" },
                                                { label: "Rack Name" },
                                                { label: "Librarian Remarks" },
                                                { label: "Edit" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                    <CardHeader >
                                        <h4><small>Records Found: 0</small> </h4>
                                    </CardHeader>
                                        </GridForm>
                                   </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(ManageBook);

