
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Sms,
    AddIcon, EditOutlined, Attachment
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class LMSIPAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lmsIpList: [], searchCheck: true,
            name: "ipName",
            label: "IP Name",
            ipNameState: '',
            ipAddressState: '',
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            alert: null,
            show: false,
            checked: false,
            active: false,
            ipId: -1,
            ipName: '',
            ipAddress: '',
        }
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    LoadTextbox(ipId) {
        let obj = this.state.lmsIpList.find(o => o.ipId === ipId);
        this.setState({ ipName: obj.ipName, ipId: obj.ipId, ipAddress: obj.ipAddress, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }

    SaveUpdateLmsIpAdress = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/LMSIP/SaveUpdateLmsIpAdress?IPId=" + this.state.ipId + "&IPName=" + this.state.ipName + "&IPAddress=" + this.state.ipAddress + "&active=" + this.state.active)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    ClearTextfieldStates = () => {
        this.setState({
            ipId: -1, ipName: '', ipAddress: '', active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    validateData = () => {
        if (this.state.ipName === "") {
            this.setState({ ipNameState: "error" });
            return;
        }
        else if (this.state.ipAddress === '') {
            this.setState({ ipAddressState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    LoadData = () => {
        fetch(Base_URL + "/LMSIP/LoadLmsIpAdress?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ lmsIpList: data });
            }).catch(error => {
                console.log(error);
            });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateLmsIpAdress()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save IP detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { lmsIpList, ipName, ipAddress, active, searchCheck, ipNameState, ipAddressState } = this.state;
        return (
            <div >
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Attachment />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>LMS IP Address</h4>
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "3px", marginBottom: "7px" }} />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/itmanagement" > IT Management</Link>
                            <Typography color="textPrimary">LMS IP Address</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "7px", marginBottom: "5px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "0px", paddingLeft: "0px" }}>
                        <CardBody>
                            <GridContainer>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "1px", marginBottom: "10px" }}>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                        <label htmlFor="small-radius-switch">
                                                            <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")}
                                                                checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                                borderRadius={4}
                                                                handleDiameter={22}
                                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                } />
                                                            <span> Active</span>
                                                        </label>
                                                    </GridTextbox>
                                                    <GridTextbox>
                                                        <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                                    </GridTextbox>
                                                </GridContainer>
                                                <cardHeader>
                                                    <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                                    <h4 className={classes.cardIconTitle}>Add LMS IP Address</h4>
                                                </cardHeader>
                                                <CardBody>
                                                    <form>
                                                        {this.state.alert}
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={ipNameState === "success"}
                                                                    error={ipNameState === "error"}
                                                                    size="small"
                                                                    label="IP Name"
                                                                    id="ipName"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={ipName}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={ipAddressState === "success"}
                                                                    error={ipAddressState === "error"}
                                                                    size="small"
                                                                    label="IP Address"
                                                                    id="ipAddress"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={ipAddress}
                                                                    onChange={this.textChange}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" borderRadius={4}
                                                                        handleDiameter={22} className={classes.toggleStyle}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                        } />
                                                                    <span> Active</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                            </GridTextbox>
                                                        </GridContainer>
                                                    </form>
                                                </CardBody>
                                            </Card>
                                        </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"LMS IP List"}
                                                    data={lmsIpList}
                                                    columns={[{
                                                        name: "ipName",
                                                        label: "IP Name",
                                                    },
                                                    {
                                                        name: "ipAddress",
                                                        label: "IP Address",
                                                    },
                                                    {
                                                        name: "active",
                                                        label: "Active",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                            }
                                                        }

                                                    },

                                                    {
                                                        name: "ipId",
                                                        label: "Edit",
                                                        options: {
                                                            customBodyRender: (value) => {
                                                                return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                            }
                                                        }
                                                    }
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(LMSIPAddress);
