import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Tabs, Tab, Typography } from '@material-ui/core';
import { Home as HomeIcon, CastForEducation as StdIcon, NavigateNext as NavigateNextIcon } from "@material-ui/icons";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// Import Registration Views
import EditRegistration from "./StdRegistrationTabs/EditRegistration.jsx";
import RegistrationAmount from "./StdRegistrationTabs/RegistrationAmount.jsx";
import TestDateTime from "./StdRegistrationTabs/TestDateTime.jsx";
import TestAssessment from "./StdRegistrationTabs/TestAssessment.jsx";
import InterviewDateTime from "./StdRegistrationTabs/InterviewDateTime.jsx";
import history from '../../context/History';
import axios from 'axios';
var Base_URL = localStorage.BaseURL;
function TabContainer(props) {
    return (
        <Typography component="div" style={{ width: "100%", alignContent:"center" }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});
class StdEditRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            editRegData: [],
            assessmentStdSubject:[],
            selectedTab: 0,
        }
        this.LoadRegData = this.LoadRegData.bind(this);
        this.LoadRegData();
    };
    LoadRegData() {
        if ((this.props.location["stdIdinfo"]) !== undefined) {
            axios.get(Base_URL + '/api/StudentRegistration/LoadRegData?StudentId=' + this.props.location["stdIdinfo"])
                .then(json => {
                    if (json.data.exception.displayMessage === null || json.data.exception.displayMessage === "" || json.data.exception.exceptionToken === null || json.data.exception.exceptionToken === "") {
                        this.setState({
                            editRegData: JSON.parse(json.data.regData),
                            isLoading: false,
                        });
                    }
                    else {
                        var msg = json.data.exception.exceptionToken + "<br />" + json.data.exception.displayMessage;
                        this.AlertMessage("Error", msg);
                    }
                
                })
        }
        else {
            history.push('/app/allregistration');
        }
    }
    LoadAssessmentSubjects() {

    }
    handleChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    render() {
        const { selectedTab, editRegData } = this.state;
        return (

            <div>
                {(this.state.isLoading === true) ? <i className="fa fa-spinner fa-spin"></i> :
                    <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <CardHeader color="info" icon>
                            <CardIcon color="success">
                                <StdIcon />
                            </CardIcon>
                            <h4 style={{ color: "black" }}>Update Registration Detail</h4>
                            <hr style={{ marginBottom: "8px" }} />
                        </CardHeader>
                        <div style={{ padding: "5px 15px" }}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                                <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                                <Link to="/app/stdmanagement" >Student Management</Link>
                                <Typography color="textPrimary">Edit Registration</Typography>
                            </Breadcrumbs>
                            <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                        </div>
                        <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                            <CardBody>
                                <GridContainer>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={this.handleChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        style={{ backgroundColor: "#d4e2f8", width: "100%" }}
                                        indicatorColor="primary"
                                        textColor="primary" wrapped >

                                        <Tab label="Registration" />
                                        <Tab label="Registration Amount" />
                                        <Tab label="Test Date & Time" />
                                        <Tab label="Test Assessment" />
                                        <Tab label="Interview Date & Time" />
                                    </Tabs>
                                    {selectedTab === 0 && <TabContainer><EditRegistration editRegData={editRegData} /></TabContainer>}
                                    {selectedTab === 1 && <TabContainer><RegistrationAmount editRegData={{ paidReason: editRegData[0]["paidReason"], registrationId: editRegData[0]["registrationId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 2 && <TabContainer><TestDateTime editRegData={{ testDate: editRegData[0]["testDate"], testTime: editRegData[0]["testTime"], regId: editRegData[0]["registrationId"], paid: editRegData[0]["paid"], regDate: editRegData[0]["registrationDate"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 3 && <TabContainer><TestAssessment editRegData={{ isTestCleared: editRegData[0]["isTestCleared"], paid: editRegData[0]["paid"], registrationId: editRegData[0]["registrationId"], levelId: editRegData[0]["LevelId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 4 && <TabContainer><InterviewDateTime editRegData={{ isTestCleared: editRegData[0]["isTestCleared"],  interviewDate: editRegData[0]["interviewDate"], testDate: editRegData[0]["testDate"], paid: editRegData[0]["paid"], interviewTime: editRegData[0]["interviewTime"], registrationId: editRegData[0]["registrationId"], regDate: editRegData[0]["registrationDate"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                </GridContainer>
                            </CardBody>
                        </div>
                    </Card>
                }
            </div>

        );
    }
}

StdEditRegistration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StdEditRegistration);