import {
    React, Typography, NavigateNextIcon, Breadcrumbs, GridContainer, Card, CardHeader, CardBody, CardIcon, PropTypes, makeStyles,
    RecentActorsIcon, HomeIcon, Tabs, Tab, Box, Link, GridForm
} from '../../components/Common/ImportAll';

//#region Student Profile Edit Tabs
import MainStdProfile from "../../views/StudentManagement/StudentApplicationTabs/MainStdProfile";
//import StudentCredentials from "../../views/StudentManagement/StudentApplicationTabs/StudentCredentials.jsx";
import AdmissionChallan from "../../views/StudentManagement/StudentApplicationTabs/AdmissionChallan.jsx";
import InActiveInfo from "../../views/StudentManagement/StudentApplicationTabs/InActiveInfo.jsx";
import Feestructure from "../../views/StudentManagement/StudentApplicationTabs/Feestructure.jsx";
import MedicalDetails from "../../views/StudentManagement/StudentApplicationTabs/MedicalDetails.jsx";
import StudentClubs from "../../views/StudentManagement/StudentApplicationTabs/StudentClubs.jsx";
import PickupPerson from "../../views/StudentManagement/StudentApplicationTabs/PickupPerson.jsx";
import MonthPlan from "../../views/StudentManagement/StudentApplicationTabs/MonthPlan.jsx";
import AdmissionFreezeReason from "../../views/StudentManagement/StdPrimaryDataTabs/AdmissionFreezeReason.jsx";
import StdTestAssessment from "../../views/StudentManagement/StdPrimaryDataTabs/StdTestAssessment.jsx";
//#endregion

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index}
            id={'scrollable-auto-tabpanel-${index}'}
            aria-labelledby={'scrollable-auto-tab-${index}'} {...other} >

            {value === index && (<Box p={3}> <Typography>{children}</Typography> </Box>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: 'scrollable-auto-tab-${index}',
        'aria-controls': 'scrollable-auto-tabpanel-${index}',
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonAuto(props) {
    const classes = useStyles();
    const [selectedTab, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const StudentTabs = () => {
        if (props.location.state.IsConfirmation === false) {
            return (
                <Tabs value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    style={{ backgroundColor: "#d4e2f8" }}
                    indicatorColor="primary"
                    textColor="primary"
                    wrapped >
                    <Tab label="Student Profile" {...a11yProps(0)} />
                    <Tab label="Student Credentials" {...a11yProps(1)} />
                    <Tab label="Admission Challan" {...a11yProps(2)} />
                </Tabs>
            )
        }
        else {
            return (
                <Tabs value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    style={{ backgroundColor: "#d4e2f8" }}
                    indicatorColor="primary"
                    textColor="primary"
                    wrapped >
                    <Tab label="Student Profile" {...a11yProps(0)} />
                    <Tab label="Student Credentials" {...a11yProps(1)} />
                    <Tab label="Admission Challan" {...a11yProps(2)} />
                    <Tab label="Inactive Info" {...a11yProps(3)} />
                    <Tab label="Fee Structure" {...a11yProps(4)} />
                    <Tab label="Medical Details" {...a11yProps(5)} />
                    <Tab label="Clubs & Pick Up Person" {...a11yProps(6)} />
                    <Tab label="Pick Up Persons" {...a11yProps(7)} />
                    <Tab label="Fee Months Plans " {...a11yProps(8)} />
                </Tabs>
            )
        }
    }
    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="info" icon>
                    <CardIcon color="success">
                        <RecentActorsIcon />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Student Profile</h4>
                    <hr style={{ marginBottom: "3px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/allregistration" >All Registration</Link>
                        <Typography color="textPrimary">Student Profile</Typography>
                    </Breadcrumbs>
                    <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                </div>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb" style={{ backgroundColor: "#d4e2f8", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px", margin: "0px" }}>
                        <Typography variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" wrapped>STUDENT PLACEMENT DETAIL</Typography>
                    </Breadcrumbs>
                    <div style={{ paddingBottom: "25px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <GridContainer xs={12} sm={12} md={12} >
                            <GridForm xs={12} sm={2} md={2}>
                                <GridContainer>
                                    <div style={{ width: "80%", marginLeft: "15px" }}>
                                        <h5 className={classes.cardIconTitle}>Registration No</h5>
                                        <hr style={{ width: "90%" }} />
                                        <span></span>
                                    </div>
                                    <div style={{
                                        borderLeft: " 1px solid 	#808080",
                                        height: "59px",
                                        marginTop: "11px"
                                    }}></div>
                                    <hr />
                                </GridContainer>
                            </GridForm>
                            <GridForm xs={12} sm={2} md={2}>
                                <GridContainer>
                                    <div style={{ width: "80%", marginLeft: "15px" }}>
                                        <h5 className={classes.cardIconTitle}>Student Name</h5>
                                        <hr style={{ width: "90%" }} />
                                        <span></span>
                                    </div>
                                    <div style={{
                                        borderLeft: " 1px solid 	#808080",
                                        height: "59px",
                                        marginTop: "11px"
                                    }}></div>
                                </GridContainer>
                            </GridForm>
                            <GridForm xs={12} sm={2} md={2}>
                                <GridContainer>
                                    <div style={{ width: "80%", marginLeft: "15px" }}>
                                        <h5 className={classes.cardIconTitle}>Session</h5>
                                        <hr style={{ width: "90%" }} />
                                        <span></span>
                                    </div>
                                    <div style={{
                                        borderLeft: " 1px solid 	#808080",
                                        height: "59px",
                                        marginTop: "11px"
                                    }}></div>
                                </GridContainer>
                            </GridForm>
                            <GridForm xs={12} sm={2} md={2}>
                                <GridContainer>
                                    <div style={{ width: "80%", marginLeft: "15px" }}>
                                        <h5 className={classes.cardIconTitle}>Campus</h5>
                                        <hr style={{ width: "90%" }} />
                                        <span></span>
                                    </div>
                                    <div style={{
                                        borderLeft: " 1px solid 	#808080",
                                        height: "59px",
                                        marginTop: "11px"
                                    }}></div>
                                </GridContainer>
                            </GridForm>
                            <GridForm xs={12} sm={2} md={2}>
                                <GridContainer>
                                    <div style={{ width: "80%", marginLeft: "15px" }}>
                                        <h5 className={classes.cardIconTitle}>Class</h5>
                                        <hr style={{ width: "90%" }} />
                                        <span></span>
                                    </div>
                                    <div style={{
                                        borderLeft: " 1px solid #808080",
                                        height: "59px",
                                        marginTop: "11px"
                                    }}></div>
                                </GridContainer>
                            </GridForm>
                            <GridForm xs={12} sm={2} md={2}>
                                <GridContainer>
                                    <div style={{ width: "80%", marginLeft: "15px" }}>
                                        <h5 className={classes.cardIconTitle}>Section</h5>
                                        <hr style={{ width: "90%" }} />
                                        <span></span>
                                    </div>
                                </GridContainer>
                            </GridForm>
                        </GridContainer>
                    </div>
                    <hr style={{ marginTop: "-23px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "25px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <div style={{ padding: "5px 15px" }}>
                        <GridContainer>
                            <StudentTabs />
                            {selectedTab === 0 && <MainStdProfile data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
{/*                            {selectedTab === 1 && <StudentCredentials data={{ RegistrationNo: props.location.state.RegistrationNo }} />}*/}
                            {selectedTab === 2 && <AdmissionChallan data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {selectedTab === 3 && <InActiveInfo data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {selectedTab === 4 && <Feestructure data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {selectedTab === 5 && <MedicalDetails data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {selectedTab === 6 && <StudentClubs data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {selectedTab === 7 && <PickupPerson data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {selectedTab === 8 && <MonthPlan data={{ RegistrationNo: props.location.state.RegistrationNo }} />}
                            {/*{selectedTab === 9 && <AdmissionFreezeReason data={{ RegistrationNo: props.location.state.RegistrationNo }} />}*/}
                            {/*{selectedTab === 10 && <StdTestAssessment data={{ RegistrationNo: props.location.state.RegistrationNo }} />}*/}

                        </GridContainer>
                    </div>
                </div>
            </Card>
        </div>
    );
}