
//Import from Globel Component";
import {
         React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
         AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, GridContainer, GridForm, GridTextbox,
         Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,Moment
       } from '../../components/Common/ImportAll'

var Base_URL = localStorage.BaseURL;

class SearchBook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            manageItemdata: [],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<Button color="facebook" onClick={() => this.LoadTextbox(value)}><EditOutlined /> Edit </Button>);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false,
            //#endregion
        };

    };

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testing } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <FindInPage />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}> Search Book</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/library" > Library</Link>
                            <Typography color="textPrimary">Search Book</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <div className={classes.mainDiv}>
                                     <GridContainer>
                                        <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                               <CardBody>
                                                  <GridContainer>
                                                     <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                       <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="By Book Title"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                       />
                                                     </GridTextbox>
                                                       <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                         <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Keywords"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                        />
                                                       </GridTextbox>
                                                          <GridTextbox xs={12} sm={12} lg={1}>
                                                             <Button color="facebook"><SearchSharp />Search </Button>
                                                        </GridTextbox>
                                                      </GridContainer>
                                                  </CardBody>
                                            </Card>
                                </GridForm>
                                        <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            columns={[
                                                { label: "ISBN" },
                                                { label: "Book Title" },
                                                { label: "Author" },
                                                { label: "Total Quantity" },
                                                { label: "Quantity in library" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                    <CardHeader >
                                        <h4><small>Records Found: 0</small> </h4>
                                    </CardHeader>
                                        </GridForm>
                                     </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(SearchBook);

