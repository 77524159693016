import React from "react";
import PropTypes from "prop-types";
import {Link } from "react-router-dom";

// @material-ui/core components
import {PersonAddOutlined,GroupOutlined, NavigateNext, Home } from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

// components
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";

class SetupManagement extends React.Component {
    state = {
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };
    render() {
        const { classes } = this.props;
       
            return (
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <GroupOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Setup Management</h4>
                        <hr style={{ marginBottom: "10px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Typography color="textPrimary">Setup Management</Typography>
                    </Breadcrumbs>

                    <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/primarydata"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <PersonAddOutlined />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Primary Data</p>
                                                <h3 className={classes.cardTitle}>
                                                    Management
                            </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <PersonAddOutlined />
                                                    Primary Data
                            </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            );
      

    }
}

SetupManagement.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(SetupManagement);
