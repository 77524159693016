
//Import from Globel Component";
import {
    React, Moment, Link, ReactToggle, withStyles, Typography, Autocomplete, TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card,
    CardHeader, CardIcon, CardBody, MUIDataTable, Button, formStyle, muiWithToolbar, tbleWithPrint, NavigateNextIcon, HomeIcon, PeopleAlt, SearchSharp,
    TextsmsIcon, AllDropDowns
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class StudentAttendance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regDataList: [],
            nameorfatherNIC: '',
            studentRegNo: '',
            IsCampus: true,
            IsLevel: true,
            IsClass: true,
            IsSection: true,
            startDate: new Date(), startDateState: '', endDate: new Date(), regStatus: 1,
            alert: null, show: false, generalError: true,
        };
       this.handleToggleChange = this.handleToggleChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setDateValue = this.setDateValue.bind(this);
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    resetClassSec = () => {
        this.setState({
            sectionDD: [{ sectionId: -1, sectionName: 'All' }],
            sectionId: -1, sectionIdIndex: 0,
            classDD: [{ classId: -1, className: 'All' }],
            classId: -1, classIdIndex: 0,
        });
    }
    LoadTextbox = () => {

    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const {  classDD,regStatus, startDate, startDateState, endDate } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <PeopleAlt />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Student Attendance</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/stdmanagementhome" >Student Management</Link>
                            <Typography color="textPrimary">Student Attendance</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                {this.state.alert}
                                <div className={classes.mainDiv}>
                                   <GridContainer>
                                      <GridForm xs={12} sm={12} md={12}>
                                            <Card style={{ marginTop: "0px" }}>
                                                <CardHeader>
                                                    <h4 className={classes.cardIconTitle}>Student Attendance</h4>
                                                </CardHeader>
                                                    <CardBody>
                                                        <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12} lg={12}>
                                                            <AllDropDowns data={this.state} xsm={12} smm={6} mdm={2} lgm={2} />
                                                        </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <Autocomplete
                                                                    id="Sector"
                                                                    size="small"
                                                                    options={classDD}
                                                                    getOptionLabel={(option) => option.title}
                                                                    onChange={(event, value) => { this.setDDValue(event, value); this.resetClassSec() }}
                                                                    renderInput={(params) => <TextField {...params} label="New/Edit" variant="outlined" />}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    success={startDateState === "success"}
                                                                    error={startDateState === "error"}
                                                                    size="small"
                                                                    label="Date"
                                                                    id="startDate"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={Moment(startDate).format('YYYY-MM-DD')}
                                                                    type="date"
                                                                    onChange={this.setDateValue}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2}>
                                                                <TextField
                                                                    size="small"
                                                                    label="To Date"
                                                                    id="endDate"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={Moment(endDate).format('YYYY-MM-DD')}
                                                                    type="date"
                                                                    onChange={this.setDateValue}
                                                                />
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")} value={regStatus}
                                                                        checked={regStatus}
                                                                        onColor="#3b5998"
                                                                        className={classes.toggleStyle}
                                                                        borderRadius={4}
                                                                        handleDiameter={22}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>On</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Off</div>}
                                                                    />
                                                                    <span> Show All </span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")} value={regStatus}
                                                                        checked={regStatus}
                                                                        onColor="#3b5998"
                                                                        className={classes.toggleStyle}
                                                                        borderRadius={4}
                                                                        handleDiameter={22}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>E-Learning</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Offline</div>}
                                                                    />
                                                                    <span>Status</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} md={2} >
                                                                <label htmlFor="small-radius-switch">
                                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")} value={regStatus}
                                                                        checked={regStatus}
                                                                        onColor="#3b5998"
                                                                        className={classes.toggleStyle}
                                                                        borderRadius={4}
                                                                        handleDiameter={22}
                                                                        uncheckedIcon={<div className={classes.toggleOff}>On</div>}
                                                                        checkedIcon={<div className={classes.toggleOn}>Off</div>
                                                                        } />
                                                                    <span>Multi Data Marketing</span>
                                                                </label>
                                                            </GridTextbox>
                                                            <GridTextbox xs={12} sm={12} lg={2}>
                                                                <Button color="facebook" onClick={() => {this.LoadData()}}><SearchSharp />Search</Button>
                                                            </GridTextbox>
                                                        </GridContainer>

                                                    </CardBody>
                                                </Card>
                                            </GridForm>
                                      <GridForm xs={12} sm={12} md={12}>
                                                <MuiThemeProvider theme={muiWithToolbar()}>
                                                    <MUIDataTable
                                                        columns={[
                                                            {label: "Ignore" },{label: "Present" },
                                                            {label: "Leave" },{label: "Holiday" },
                                                            {label: "AF" },
                                                            { name: "registrationNo", label: "RegNo" },
                                                            { name: "name", label: "Name" },
                                                            { name: "sessionName", label: "Session" },
                                                            { name: "sectorDD", label: "Sector" },
                                                            { name: "Sub Sector", label: "SubSector" },
                                                            { name: "className", label: "Class" },
                                                            { name: "sectionName", label: "Section" },
                                                            {
                                                                name: "registrationDate", label: "Joining Date",
                                                                options: {
                                                                    customBodyRender: (value) => {
                                                                        return (Moment(value).format('DD-MMM-YYYY'));
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                name: "Fine", label: "Fine",
                                                                options: {
                                                                    customBodyRender: (value) => {
                                                                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                                    }
                                                                }
                                                            },
                                                        ]}
                                                        options={tbleWithPrint}
                                                    />
                                                </MuiThemeProvider>
                                            </GridForm>
                                      <GridForm xs={12} sm={12} md={12}>
                                                <Card>
                                                    <CardBody>
                                                        <GridContainer>
                                                            <GridTextbox xs={12} sm={12} lg={12}>
                                                                <Button color="facebook"><TextsmsIcon/>Send SMS </Button>
                                                                <Button color="facebook">Summary </Button>
                                                                <Button color="facebook">Monthly Fine </Button>
                                                                <Button color="facebook">Daily </Button>
                                                                <Button color="facebook">Monthly </Button>
                                                                <Button color="facebook">Monthly Comulative</Button>
                                                                <Button color="facebook">Comulative</Button>
                                                                <Button color="facebook">Save</Button>
                                                                <Button color="facebook">Delete</Button>
                                                                <Button color="facebook">Multi Delete</Button>
                                                            </GridTextbox>
                                                         </GridContainer>
                                                     </CardBody>
                                                </Card >
                                            </GridForm>
                                   </GridContainer>
                                </div>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(StudentAttendance);
