
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, Description
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class ImportAttendance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
             //#region Other Variables and states
            checkedA: true,
            checkedB: false,
            simpleSelect: "",
            firstname: "",
            firstnameState: "",
            lastname: "",
            lastnameState: "",
            email: "",
            emailState: ""
        };
         //#endregion 
    }
   //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Description />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Import Attendance</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Import Attendance</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                              <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "0px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Connection with Biometric Device</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                              <GridTextbox xs={12} sm={12} md={2}>
                                            <Autocomplete
                                                size="small"
                                                getOptionLabel={(option) => option.title}
                                                renderInput={(params) => <TextField {...params} label="Device Name" variant="outlined" />}
                                            />
                                                </GridTextbox>
                                              <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="IP Adress"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                              <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Port"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                              <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Status"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                              <GridTextbox xs={12} sm={12} lg={12}>
                                                    <Button color="facebook">Connect</Button>
                                                    <Button color="facebook">Att Transfer To DB</Button>
                                                    <Button color="facebook">Restart Device</Button>
                                                    <Button color="facebook">Clear Att Data (Device)</Button>
                                                    <Button color="facebook">Device User To DB</Button>
                                                    <Button color="facebook">DB Users To Device</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card >
                        </GridForm>
                              <GridForm xs={12} sm={8} md={8}>
                            <Card>
                                <GridTextbox xs={12} sm={12} lg={12}>
                                    <Button color="facebook">Load DB User</Button>
                                    <Button color="facebook">Load Device Users</Button>
                                </GridTextbox>
                                        <MuiThemeProvider theme={muiWithToolbar()}>

                                            <MUIDataTable
                                                title={"Employees Detail with Fingerprints "}
                                        columns={[
                                            { label: "Update" }, { label: "Edit" },
                                            { label: "Emp ID" }, { label: "Name" },
                                            { label: "PIN" }, { label: "Privilege" },
                                            { label: "Card No." }, { label: "FP1" },
                                            { label: "FP2" },{ label: "FP3" },
                                            { label: "FP4" }, { label: "FP5" },
                                            { label: "FP6" },{ label: "FP7" },
                                            { label: "FP8" }, { label: "FP9" },
                                            { label: "FP10" },

                                    ]}
                                    options={tbleWithPrint}
                                />
                                </MuiThemeProvider>
                                </Card>
                                </GridForm>
                              <GridForm xs={12} sm={4} md={4}>
                                    <Card >
                                        <CardHeader>
                                            <h4>Fingerprints Enrolment in Biometric Device</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        size="small"
                                                        label="Emp ID"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        size="small"
                                                        label="User ID"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        size="small"
                                                        label="Employee Name"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        size="small"
                                                        label="PIN"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        size="small"
                                                        label="Card No"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <TextField
                                                        size="small"
                                                        label="Privilege"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={4}>
                                                    <Button color="facebook" >Update</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={5}>
                                                    <TextField
                                                        size="small"
                                                        label="Fingure Index"
                                                        id="Port"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Button color="facebook">Start Enroll</Button>
                                                    </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                        </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(extendedFormsStyle)(ImportAttendance);