import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import ReactToggle from "react-switch";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GroupAdd as ManageCategoryIcon, EditOutlined, SaveOutlined, AddCircle as AddIcon, Home as HomeIcon, NavigateNext as NavigateNextIcon, Category, SearchSharp} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { MuiThemeProvider } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { formStyle, muiWithToolbar, tbleWithPrint, StyledBreadcrumb } from "assets/jss/customStyle/allCustomStyle";
var Base_URL = localStorage.BaseURL;
class ManageCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manageCategoriesdata: [],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<Button color="facebook" onClick={() => this.LoadTextbox(value)}><EditOutlined /> Edit </Button>);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false,
            //#endregion
        };
    };
    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.Name === "") {
            this.setState({ NameState: "error" });
            return;
        }
        this.handleToggleChange = this.handleToggleChange.bind(this);
    }
    //#endregion 
    handleToggleChange(isVendor) {
        this.setState({ isVendor });
    }
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="danger" icon>
                    <CardIcon color="danger">
                        <Category />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Manage Categories</h4>
                </CardHeader>
                {this.state.alert}
                <div className={classes.formDiv}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/inventory" >Inventory</Link>
                        <Typography color="textPrimary">Manage Categories </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Item Type*" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Category Name*"
                                                        id="Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        size="small"
                                                        label="CodePrefix"
                                                        variant="outlined"
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Debit Account*" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2} style={{ marginLeft: "1%" }}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={this.handleToggleChange} checked={this.state.isVendor} onColor="#3b5998" width={50}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Status </span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook">Save </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                               <GridForm xs={12} sm={12} md={12}>
                                   <Card>
                                     <CardBody>
                                         <GridContainer>
                                           <GridTextbox xs={12} sm={12} md={4}style={{ marginLeft: "1%" }}>
                                            <TextField
                                                success={this.state.NameState === "success"}
                                                error={this.state.NameState === "error"}
                                                size="small"
                                                label="Category Name"
                                                id="Name"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.Name}
                                                onChange={this.textChange}
                                            />
                                          </GridTextbox>
                                          <GridTextbox xs={12} sm={12} lg={2} style={{ marginLeft: "1%" }}>
                                          <label htmlFor="small-radius-switch">
                                                <ReactToggle onChange={this.handleToggleChange} checked={this.state.isVendor} onColor="#3b5998" width={50}
                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                    } />
                                                <span> Status </span>
                                            </label>
                                          </GridTextbox>
                                            <GridTextbox xs={12} sm={12} lg={1}>
                                                <Button color="facebook"><SearchSharp />Search </Button>
                                            </GridTextbox>
                                       </GridContainer>
                                          </CardBody>
                                        </Card>
                                  </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        columns={[
                                            { label: "Item Type" }, { label: "Category Name" },
                                            { label: "CodePrefix" }, { label: "Debit Account" },
                                            { label: "Active" }, { label: "Edit" },
                                        ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                                <CardHeader >
                                    <h4><small>Records Found: 0</small> </h4>
                                </CardHeader>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(ManageCategories);
