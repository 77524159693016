import React, { Children } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import LoginPage from "views/Login/LoginPage.jsx";
import ForgotPassword from "views/ForgotPassword/ForgotPassword.jsx";
import RoutePage from "views/RoutingPage/RoutePage.jsx";
import AdminLayout from "layouts/Admin.jsx";
import axios from 'axios';
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import history from '../src/context/History';


GetFormRights();
ReactDOM.render(
    <Router history={history}>
        <Switch>
            <PrivateRoute path="/app" component={AdminLayout} />
            <Route path="/loginpage" component={LoginPage} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/" component={RoutePage} />
        </Switch>
    </Router>,
    document.getElementById("root")
);
//window.addEventListener("popstate", e => {
//    //if (history.index !== -1) {
//        history.goBack();
//    //}
    
//});
window.onbeforeunload = function (e) {
    //debugger;
    GetFormRights();
}
function PrivateRoute({ component, ...rest }) {
    var isAunthentic = localStorage.getItem('isAuthenticated');
    if (isAunthentic && rest.location.pathname === '/app') {
        return (
            <Redirect to="/app/dashboard" />
        );
    }
    else if (isAunthentic && rest.location.pathname === '/app/') {
        return (
            <Redirect to="/app/dashboard" />
        );
    }
    else if (isAunthentic !== "true" && rest.location.pathname === '/app/dashboard') {
        return (
            <Redirect from="/" to="/loginpage" />
        );
    }
    else if (isAunthentic !== "true") {
        return (
            <Redirect from="/" to="/loginpage" />
        );
    }
    else {
        return (
            <Route path={rest.location.pathname} component={component} />
        );
    }
}
async function GetFormRights() {
    try {
        var formIds = [596, 599, 597, 598, 637, 617, 660, 661, 1025, 600];
        var Base_URL = window.location.origin;
        if (Base_URL === 'http://localhost:58487') {
            Base_URL = Base_URL + '/GlobalData/GetSideBarRights?formId=' + formIds + '';
        }
        else {
            Base_URL = Base_URL + '/btsmis/GlobalData/GetSideBarRights?formId=' + formIds + '';
        }
        const resp = await axios.get(Base_URL);
        if (resp != null) {
            //debugger;
            localStorage.removeItem('RightSideBar');
            if (resp.data[0].formId === -1 && resp.data[0].canView === false) {
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('RightSideBar');
            }
            else {
                localStorage.setItem('RightSideBar', JSON.stringify(resp.data));
            }
        }
        
    }
    catch (err) {
        console.error(err);
    }
}

