import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import { ContactMailOutlined as ParentIcon, SaveOutlined } from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";
var Base_URL = localStorage.BaseURL;
class RegistrationAmount extends React.Component {
    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        this.state = {
            registrationId: editRegData["registrationId"],
            paidReason: editRegData["paidReason"],
            paidReasonState: '',
            saveUpdateBtnDisabled: true, alert: null, show: false
        };
        this.setValue = this.setValue.bind(this);
        this.SaveUpdateData = this.SaveUpdateData.bind(this);
        this.ValidateData = this.ValidateData.bind(this);
        this.LoadRegData = this.LoadRegData.bind(this);
    };
  
    setValue = (event) => {
        if (event.target.value !== '') {
            this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value, saveUpdateBtnDisabled: false });

        } else {
            this.setState({ [event.target.id]: event.target.value, saveUpdateBtnDisabled: true });
        }

    }
    ValidateData = () => {
        if (this.state.paidReason === "") {
            this.AlertMessage("Error", "You can't save without reason")
            return false;
        }
        else {
            this.ConfirmMessage("SaveUpdateData", "Do you want to save Registration reason!");
        }
    }
    SaveUpdateData = () => {
        debugger;
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        axios.post(Base_URL + "/api/StudentRegistration/SaveRegAmountReason?RegistrationId=" + this.state.registrationId + "&PaidReason=" + this.state.paidReason)
            .then(json => {
                debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.vmResponse.status, json.data.vmResponse.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })

    }
    //#region this Function is Called after Updating data
    LoadRegData() {
        this.hideAlert();
        this.props.LoadRegData(); //Function in StdEditRegistration.jsx
    }
    //#endregion
    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        debugger;
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this[funName]()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.LoadRegData()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer style={{ placeContent: "center" }}>
                            <GridForm xs={12} sm={12} md={4}>
                                <Card>
                                    <CardHeader color="info" icon>
                                        <CardIcon color="success">
                                            <ParentIcon />
                                        </CardIcon>
                                        <h4 className={classes.cardIconTitle}>Paid Reason</h4>
                                        <hr />
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer >
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.paidReasonState === "success"}
                                                    error={this.state.paidReasonState === "error"}
                                                    multiline
                                                    rows={5}
                                                    id="paidReason"
                                                    size="small"
                                                    required="true"
                                                    value={this.state.paidReason}
                                                    label="Amount Paid Reason"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.setValue(event) }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={12} lg={12} style={{ marginLeft: "40%" }}>
                                                <Button color="facebook" onClick={() => { this.ValidateData() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Save </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>

                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(RegistrationAmount);
