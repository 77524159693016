//Import from Globel Component";
import {
    React, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, Autocomplete,
    TextField, Breadcrumbs, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody,
    Button, formStyle, SaveOutlined, GridItem, PictureUpload, PersonAddOutlined, NavigateNext, Home, InputMask
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}

class NewEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            nicExpiry: new Date().setFullYear(dateVal.currentYear, dateVal.currentMonth, dateVal.currentDate + 1),
            dob: new Date().setFullYear(dateVal.currentYear - 18, dateVal.currentMonth, dateVal.currentDate),
            minDOB: new Date().setFullYear(dateVal.currentYear - 18, dateVal.currentMonth, dateVal.currentDate),
            NewDate: new Date(),
            age: this.ageCalculate(new Date().setFullYear(dateVal.currentYear - 18, dateVal.currentMonth, dateVal.currentDate)),

            //Sec 1
            employeeRegNo: 0,
            employeeRegNoState: "",
            employeeName: "",
            employeeNameState: "",
            titleId: -1,
            titleIdIndex: 0,
            titleIdState: "",
            genderId: 1,
            genderIdIndex: 0,
            genderIdState: "",
            dobState: "",
            dobValue: "",
            genderDisabled: true,
            nicValue: null,
            nicState: "",
            nicExpiryState: "",
            nicExpiryValue: "",
            mobileNo: "",
            mobileNoState: "",
            otherMobileNo: "",
            otherMobileNoState: "",
            landLine: null,
            landLineState: "",
            emailPersonal: null,
            emailPersonalState: "",

            //Sec 2
            house: "",
            houseState: "",
            street: "",
            streetState: "",
            countryId: -1,
            countryIdIndex: 0,
            countryIdState: "",
            cityId: -1,
            cityIdIndex: 0,
            cityIdState: "",
            townId: -1,
            townIdIndex: 0,
            townIdState: "",
            sectorId: -1,
            sectorIdIndex: 0,
            sectorIdState: "",
            blockId: -1,
            blockIdIndex: 0,
            blockIdState: "",
            BlockName: "",
            currentAddress: "",
            currentAddressState: "",
            permanentAddress: "",
            permanentAddressState: "",

            //Sec 3
            applicationStatusId: 1,
            applicationStatusIdIndex: 0,
            applicationStatusIdState: "",
            joiningDate: null,
            joiningDateState: "",
            isSupervisor1: false,
            isSupervisor2: false,

            //Sec 4
            fatherName: "",
            fatherNameState: "",
            maritalStatusId: 1,
            maritalStatusIdIndex: 0,
            maritalStatusIdState: "",
            maritalStatusName:"",
            spouseName: null,
            spouseNameState: "",
            spouseNameDisabled: false,
            placeOfBirth: null,
            placeOfBirthState: "",
            bloodGroupId: -1,
            bloodGroupIdIndex: 0,
            bloodGroupIdState: "",
            religionId: 1,
            religionIdIndex: 0,
            religionIdState: "",
            employmentStatusId: 1,
            employmentStatusIdIndex: 0,
            employmentStatusIdState: "",
            confirmationDate: new Date().setFullYear(dateVal.currentYear, dateVal.currentMonth + 3, dateVal.currentDate),
            confirmationDateState: "",
            justificationId: 1,
            justificationIdIndex: 0,
            justificationIdState: "",
            employeeId: null,
            employeeIdIndex: 0,
            employeeIdState: "",
            nameUrdu: null,
            nameUrduState: "",
            fatherOrSpouseNameUrdu: null,
            fatherOrSpouseNameUrduState: "",
            applicationDate: new Date(),
            applicationDateState: "",
            appointmentDate: new Date(),
            appointmentDateState: "",
            supervisorOneId: -1,
            supervisorOneIdIndex: 0,
            supervisorOneIdState: "",
            supervisorTwoId: -1,
            supervisorTwoIdIndex: 0,
            supervisorTwoIdState: "",


            //Others
            nicBlackListed: "",
            userName: "",
            userNameState: "",
            password: "",
            passwordState: "",
            emailProfessional: "",
            emailProfessionalState: "",
            employeeIdDisabled: true,
            employeePassword: "",
            userPortalLogin: "",
            userGroupId: 0,
            alert: null,
            show: false,
            generalError: false,

            //dropdowns
            applicationStatusDD: [{ applicationStatusId: -1, applicationStatus: "Select" }],
            maritalStatusDD: [{ maritalStatusId: -1, maritalStatus: "Select" }],
            justificationDD: [{ justificationId: -1, justification: "Select" }],
            employmentStatusDD: [{ employmentStatusId: -1, employmentStatus: "Select" }],
            employeeDD: [{ employeeId: -1, name: "Select" }],
            countryDD: [{ countryId: -1, countryName: "Select" }],
            cityDD: [{ cityId: -1, cityName: "Select" }],
            townDD: [{ townId: -1, townName: "Select" }],
            sectorDD: [{ sectorId: -1, sectorName: "Select" }],
            blockDD: [{ blockId: -1, blockName: "Select" }],
            religionDD: [{ religionId: -1, religionName: "Select" }],
            bloodGroupDD: [{ bloodGroupId: -1, name: "Select" }],
            titleDD: [{ titleId: -1, name: "Select" }],
            genderDD: [{ genderId: -1, name: "Select" }],
            supervisorOneDD: [{ employeeId: -1, name: "Select" }],
            supervisorTwoDD: [{ employeeId: -1, name: "Select" }],

        }
        this.validateData = this.validateData.bind(this);
    };
    ageCalculate(dob) {
        var todate = new Date()
        var fromdate = new Date(dob)
        var y = [todate.getFullYear(), fromdate.getFullYear()],
            ydiff = y[0] - y[1],
            m = [todate.getMonth(), fromdate.getMonth()],
            mdiff = m[0] - m[1],
            d = [todate.getDate(), fromdate.getDate()],
            ddiff = d[0] - d[1];
        if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) {
            --ydiff;
        }
        if (mdiff < 0) {
            mdiff += 12;
        }
        if (ddiff < 0) {
            fromdate.setMonth(m[1] + 1, 0)
            ddiff = fromdate.getDate() - d[1] + d[0]
            --mdiff;
            if (mdiff < 0) {
                mdiff += 12;
            }
        }
        return ydiff + " y " + mdiff + " m " + ddiff + " d";
    }
    async EmployeeRegNo() {
        debugger
        axios.get(Base_URL + '/api/newEmployee/employeeRegNo')
            .then(json => {
                this.setState({
                    employeeRegNo: json.data.employeeRegNo,
                    employeePassword: json.data.employeeRegNo,
                    userPortalLogin: json.data.employeeRegNo,
                    employeeRegNoState: "success"
                });
            })
    }
    // function that verifies if a string is CNIC or not
    async VerifyNIC(nic) {
        debugger;
        try {
            const response = await (fetch(Base_URL + "/api/NewEmployee/VerifyNIC?NIC=" + nic));
            if (response.ok) {
                debugger
                const data = await response.json();
                debugger;
                //this.AlertMessage(data.status,data.message)
                this.confirmAutoFill(data.status, data.massage);
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    confirmAutoFill(status, massage) {
        debugger
        if (status === "Duplicate") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status + "Employee"}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        cancelBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.danger
                        }
                        confirmBtnText="Do you Want to Autofill the Form?"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        Duplicate NIC found.
                    </SweetAlert>
                ),
                nicBlackListed: ""
            });
        }
        else if (status === "Blacklisted") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{massage}</SweetAlert>
                ),
                nicState: "error",
                nicBlackListed: "Black listed Employee"
            });
        }
        else {
            this.setState({
                nicBlackListed: ""
            })
        }

    }
    hideAlert() {
        this.setState({
            alert: null,
           // employeeRegNo: ""
        });
    }
    ClearTextfieldStates = () => {
        debugger;
        this.setState({
            employeeRegNo: 0,
            employeeRegNoState: "",
            employeeName: "",
            titleIdIndex: 0,
            genderIdIndex: 0,
            genderDisabled: false,
            nicValue: null,
            nicState: "",
        })
        this.hideAlert()
    }

    //#region Dropdowns
    ShowHide = () => {
        this.setState({
            clearTextBtn: 'none',
        });
    }
    async AutoFillForm() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/NewEmployee/AutoFill?NIC=" + this.state.nic))
            if (response.ok) {
                const data = await response.json();
                this.confirmAutoFill(data.status, data.message)
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async componentDidMount() {
        this.EmployeeRegNo();
        this.loadCountryDD();
        this.loadApplicationStatusDD();
        this.loadMaritalStatusDD();
        this.loadReligionsDD();
        this.loadBloodGroupDD();
        this.loadTitleDD();
        this.loadGenderDD();
        this.loadSupperOneDD();
        this.loadSupperTwoDD();
        this.loadEmploymentStatusDD();
        this.loadJustificationDD();
    }
    async loadApplicationStatusDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/ApplicationStatuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    applicationStatusDD: data.applicationStatuses
                });
                if (data.applicationStatuses.length > 0) {
                    this.setState({
                        applicationStatusIdIndex: 0,
                        applicationStatusId: data.applicationStatuses[0].applicationStatusId,
                        applicationStatusIdState: "success"
                    });
                }
                else {
                    this.setState({
                        applicationStatusId: 0,
                        applicationStatusIdIndex: -1,
                        applicationStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadMaritalStatusDD() {
        debugger;
        try {
            const response = await (fetch(Base_URL + "/api/HR/MaritalStatuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    maritalStatusDD: data.maritalStatuses
                });
                if (data.maritalStatuses.length > 0) {
                    this.setState({
                        maritalStatusId: data.maritalStatuses[0].maritalStatusId,
                        maritalStatusName: data.maritalStatuses[0].maritalStatus,
                        //maritalStatusIdIndex: -1,
                        maritalStatusIdState: "success"
                    });
                }
                else {
                    this.setState({
                        maritalStatusId: 0,
                        maritalStatusIdIndex: -1,
                        maritalStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadReligionsDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/ReligionsDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    religionDD: data.religionsDDList
                });
                if (data.religionsDDList.length > 0) {
                    this.setState({
                        religionIdState: "success"
                    });
                }
                else {
                    this.setState({
                        religionId: 0,
                        religionIdIndex: -1,
                        religionIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadBloodGroupDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/BloodGroupDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ bloodGroupId: -1, name: "Select" });
                allList = allList.concat(data.bloodGroupDDList);
                this.setState({
                    bloodGroupDD: allList
                });
                if (allList.length > 0) {
                    debugger
                    this.setState({
                        bloodGroupId: allList[0]['bloodGroupId'],
                    });
                }
                else {
                    this.setState({
                        bloodGroupId: 0,
                        bloodGroupIdIndex: -1,
                        bloodGroupIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadTitleDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/TitleDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ titleId: -1, name: "Select" });
                allList = allList.concat(data.titleDDList);
                this.setState({
                    titleDD: allList
                });
                if (allList.length > 0) {
                    this.setState({
                        titleId: allList[0]['titleId'],
                        titleIdState: "success",
                    });
                }
                else {
                    this.setState({
                        titleId:0,
                        titleIdIndex: -1,
                        titleIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadGenderDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/GenderDD"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    genderDD: data.genderDDList
                });
                if (data.genderDDList.length > 0) {
                    debugger
                    this.setState({
                        genderId: data.genderDDList[0].genderId,
                        genderIdIndex: 0,
                        genderIdState: "success"
                    });
                }
                else {
                    this.setState({
                        genderId: -1,
                        genderIdIndex: -1,
                        genderIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSupperOneDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/SuppervisorOneDD"))
            if (response.ok) {
                debugger
                const data = await response.json();
                let allList = [];
                allList.push({ employeeId: -1, name: "Select" });
                allList = allList.concat(data.supervisorDDList);
                this.setState({
                    supervisorOneDD: allList
                });
                if (allList.length > 0) {
                    this.setState({
                        supervisorOneId: allList[0]['employeeId'],
                        supervisorOneIdState: "success"
                    });
                }
                else {
                    this.setState({
                        supervisorOneId: 0,
                        supervisorOneIdIndex: -1,
                        supervisorOneIdState: ""
                    })
                }
            }
            else {
                debugger
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSupperTwoDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/SuppervisorTwoDD"))
            if (response.ok) {
                debugger
                const data = await response.json();
                let allList = [];
                allList.push({ employeeId: -1, name: "Select" });
                allList = allList.concat(data.supervisorDDList);
                this.setState({
                    supervisorTwoDD: allList
                });
                if (allList.length > 0) {
                    this.setState({
                        supervisorTwoId: allList[0]['employeeId'],
                        supervisorTwoIdState: "success"
                    });
                }
                else {
                    this.setState({
                        supervisorTwoId: 0,
                        supervisorTwoIdIndex: -1,
                        supervisorTwoIdState: ""
                    })
                }
            }
            else {
                debugger
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadEmploymentStatusDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/EmploymentStatuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    employmentStatusDD: data.employmentStatuses
                });
                if (data.employmentStatuses.length > 0) {
                    debugger
                    this.setState({
                        employmentStatusIdIndex: 0,
                        employmentStatusId: data.employmentStatuses[0].employmentStatusId,
                        employmentStatusIdState: "success"
                    });
                }
                else {
                    this.setState({
                        employmentStatusId: 0,
                        employmentStatusIdIndex: -1,
                        employmentStatusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadJustificationDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/HRHiringJustifications"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    justificationDD: data.hRHiringJustifications
                });
                if (data.hRHiringJustifications.length > 0) {
                    this.setState({
                        justificationIdIndex: 0,
                        justificationId: data.hRHiringJustifications[0].justificationId,
                        justificationIdState: "success"
                    });
                }
                else {
                    this.setState({
                        justificationId: 0,
                        justificationIdIndex: -1,
                        justificationIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadAllEmployeesDD() {
        try {
            const response = await (fetch(Base_URL + "/api/HR/AllEmployees"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    employeeDD: data.employees,
                });
                if (data.employees.length > 0) {
                    debugger;
                    this.setState({
                        employeeIdIndex: 1,
                        employeeId: data.employees[1].employeeId,
                        employeeIdState: "success"
                    });
                }
                else {
                    this.setState({
                        employeeId: 0,
                        employeeIdIndex: -1,
                        employeeIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadCountryDD() {
        try {
            debugger;
            const response = await (fetch(Base_URL + "/api/Dropdowns/Countries"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ countryId: -1, countryName: "Select" });
                allList = allList.concat(data.countryDDList);
                this.setState({
                    countryDD: allList,
                });
                if (allList.length > 0) {
                    this.setState({
                        countryId: allList[0]['countryId'],
                    });
                    //this.state.countryId = allList[0]['countryId'];
                }
                else {
                    this.setState({
                        countryId: 0,
                        countryIdIndex: -1,
                        countryIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadCityDD(countryId) {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + countryId))
            if (response.ok) {
                const data = await response.json();
                debugger
                let allList = [];
                allList.push({ cityId: -1, cityName: "Select" });
                allList = allList.concat(data.citiesDDList);
                this.setState({
                    cityDD: allList,
                });
                if (allList.length > 0) {
                    this.setState({
                        cityId: allList[0]['cityId'],
                        cityIdIndex: 0,
                        cityIdState: ""
                    });
                }
                else {
                    this.setState({
                        cityId: 0,
                        cityIdIndex: -1,
                        cityIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadTownDD(cityId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + cityId))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ townId: -1, townName: "Select" });
                allList = allList.concat(data.townDDList);
                this.setState({
                    townDD: allList
                });
                if (allList.length > 0) {
                    this.setState({
                        townId: allList[0]['townId'],
                        townIdIndex: 0,
                        townIdState: ""
                    });
                }
                else {
                    this.setState({
                        townId: 0,
                        townIdIndex: -1,
                        townIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSectorDD(townId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Sectors?TownId=" + townId))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ sectorId: -1, sectorName: "Select" });
                allList = allList.concat(data.sectorsDDList);
                this.setState({
                    sectorDD: allList
                });
                if (allList.length > 0) {
                    this.setState({
                        sectorId: allList[0]['sectorId'] ,
                        sectorIdIndex: 0,
                        sectorIdState: ""
                    });
                }
                else {
                    this.setState({
                        sectorId: 0,
                        sectorIdIndex: -1,
                        sectorIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadBlockDD(sectorId) {
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/Blocks?sectorId=" + sectorId))
            if (response.ok) {
                debugger;
                const data = await response.json();
                let allList = [];
                allList.push({ blockId: -1, blockName: "Select" });
                allList = allList.concat(data.blockDDList);
                this.setState({
                    blockDD: allList
                });
                if (allList.length > 0) {
                    this.setState({
                        blockId: allList[0]['blockId'],
                        blockIdIndex: 0,
                        blockIdState: "success"
                    });
                }
                else {
                    this.setState({
                        blockId: 0,
                        blockIdIndex: -1,
                        blockIdState: "",
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    //#endregion

    //#region Validations
    verifyDate(value) {
        debugger
        var DateRex = /\d\d\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/;
        if (DateRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Email or not
    verifyEmail(value) {
        debugger
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Mobile No. or not
    verifyMobile(value) {
        debugger
        var mobileRex = /^([3]{1})([0-9]{9})$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }

    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        debugger
        if (value === length) {
            return true;
        }
        return false;
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true;
        }
        return false;
    }
        // function that verifies if it's a string or not  
    verifyAlphabets(value) {
        debugger
        var AlphabetsRex  = /^[a-zA-Z ]*$/;
        if (AlphabetsRex.test(value)) {
            return true;
        }
        return false;
    }
    

    // function that setState of Dynamic Dropdown
    setDDValue = (event, value) => {
        debugger;
        let stateName = event.target.id.split("-")[0];
        let dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    setAddress() {
        debugger
        var house = "";
        if (this.state.houseState === "success") {
            house = "House No. " + this.state.house;
        }
        var street = "";
        if (this.state.streetState === "success") {
            street = ", Street No. " + this.state.street;
        }
        var blockId = this.state.blockId;
        var blockName = "";
        if (this.state.blockId != -1 && this.state.blockId != "") {
            blockName = ", " + this.state.blockDD.find(x => x.blockId === blockId).blockName;
            blockId = "";
        }
        var sectorId = this.state.sectorId;
        var sectorName = "";
        if (this.state.sectorIdState === "success" && this.state.sectorId != -1) {
            sectorName = ", Sector " + this.state.sectorDD.find(x => x.sectorId === sectorId).sectorName;
        }
        var townId = this.state.townId;
        var townName = "";
        if (this.state.townIdState === "success" && this.state.townId != -1) {
            townName = ", " + this.state.townDD.find(x => x.townId === townId).townName;
        }
        var cityId = this.state.cityId;
        var cityName = "";
        if (this.state.cityIdState === "success") {
            cityName = ", " + this.state.cityDD.find(x => x.cityId === cityId).cityName;
        }
        var countryId = this.state.countryId;
        var countryName = "";
        if (this.state.countryIdState === "success") {
            countryName = this.state.countryDD.find(x => x.countryId === countryId).countryName;
        }
        var CompleteAddress = house + " " + street + " " + blockName + " " + sectorName + " " + townName + " " + cityName + " " + countryName
        if (CompleteAddress.trim().length > 10) {
            debugger
            this.setState({
                currentAddress: CompleteAddress.trim(),
                currentAddressState: "success"
            })
        }
        else {
            this.setState({
                currentAddress: CompleteAddress.trim(),
                currentAddressState: ""
            })
        }
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "employeeName":
                if (this.verifyAlphabets(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        employeeName: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        employeeName: "",
                        [stateName + "State"]: "error"
                    });
                }
                break;
            case "dob":
                var date = new Date(event.target.value)
                if (new Date(this.state.minDOB) > date) {
                    Moment(event.target.value).format('YYYY-MM-DD');
                    this.setState({
                        age: this.ageCalculate(date),
                        dob: event.target.value,
                        dobValue: event.target.value,
                        dobState: "success"
                    })
                }
                else {
                    this.setState({
                        dobState: "error",
                        dob: date,
                        age: "Age is less then 18 Y"
                    })
                }
                break;
            case "applicationDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        applicationDate: event.target.value,
                        applicationDateState: "success"
                    })
                }
                else {
                    this.setState({
                        applicationDateState: "error",
                    })
                }
                break;
            case "AppointmentDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        appointmentDate: event.target.value,
                        appointmentDateState: "success"
                    })
                }
                else {
                    this.setState({
                        appointmentDateState: "error",
                    })
                }
                break;
            case "confirmationDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        confirmationDate: event.target.value,
                        confirmationDatetate: "success"
                    })
                }
                else {
                    this.setState({
                        confirmationDateState: "error",
                    })
                }
                break;
            case "titleId":
                this.setState({
                    stateName: value["name"],
                    [stateName + "Index"]: this.state.titleDD.findIndex(x => x[stateName] === value[stateName]),
                    [stateName + "State"]: "success"
                })
                if (value["name"] === "Mr.") {
                    this.setState({
                        genderIdIndex: this.state.genderDD.findIndex(x => x.genderId === value.titleId),
                        titleId: value.titleId,
                        genderId: 1,
                        genderDisabled: true
                    });
                } else if (value["name"] === "Mrs." || value["name"] === "Miss.") {
                    this.setState({
                        genderIdIndex: this.state.genderDD.findIndex(x => x.genderId === 2),
                        titleId: value.titleId,
                        genderId: 2,
                        genderDisabled: true
                    });
                } else if (value["name"] === "Dr.") {
                    this.setState({
                        genderIdIndex: 0,
                        titleId: value.titleId,
                        genderId: 1,
                        genderDisabled: false
                    });
                }
                else {
                    this.setState({
                        titleId: -1,
                    });
                }
                break;
            case "genderId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.genderDD.findIndex(x => x[stateName] === value[stateName]),
                    genderId: value.genderId,
                    [stateName + "State"]: "success"
                })
                break;
            case "nic":

                if (this.verifyLength((event.target.value.length), 15) == true) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nicValue: event.target.value,

                    });
                    this.VerifyNIC(event.target.value);
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "nicExpiry":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nicExpiryValue: event.target.value,
                        nicExpiry: event.target.value,
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "mobileNo":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        mobileNo: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "otherMobileNo":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        otherMobileNo: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "permanentAddress":
                debugger;
                if (stateName != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        permanentAddress: event.target.value,
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "landLine":
                this.setState({ [stateName + "State"]: "success", stateName: event.target.value, landLine: event.target.value });
                break;
            case "emailPersonal":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        emailPersonal: event.target.value,
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "joiningDate":
                debugger;
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        joiningDate: event.target.value,
                        stateName: event.target.value,
                        confirmationDate: this.addMonths(new Date(event.target.value),3).toString(),
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "house":
                debugger;
                if (event.target.value != null && event.target.value != "") {
                    this.state.houseState = "success";
                    this.state.house = event.target.value;
                    this.setAddress();
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "street":
                if (event.target.value != null && event.target.value != "") {
                    this.state.streetState = "success";
                    this.state.street = event.target.value;
                    this.setAddress();
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "countryId":
                if (value !== null) {
                    this.state.countryId = value.countryId;
                    this.state.countryIdState = "success";
                    this.loadCityDD(value.countryId);
                }
                else {
                    this.setState({
                        cityDD: [{ cityId: 0, cityName: " " }],
                        cityId: 0,
                        cityIdIndex: -1,
                        cityIdState: ""
                    })
                }
                this.setDDValue(event, value);
                this.setAddress();
                break;
            case "cityId":
                if (value !== null) {
                    this.state.cityId = value.cityId;
                    this.state.cityIdState = "success";
                    this.loadTownDD(value.cityId);
                }
                else {
                    this.setState({
                        townDD: [{ townId: 0, townName: " " }],
                    })
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "townId":
                if (value !== null) {
                    this.state.townId = value.townId;
                    this.state.townIdState = "success";
                    this.loadSectorDD(value.townId);
                }
                else {
                    this.setState({
                        sectorDD: [{ sectorId: 0, sectorName: " " }],
                    })
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "sectorId":
                if (value !== null) {
                    debugger;
                    this.state.sectorId = value.sectorId;
                    this.state.sectorIdState = "success";
                    this.loadBlockDD(value.sectorId);
                }
                else {
                    this.setState({
                        sectorDD: [{ sectorId: 0, blockName: " " }],
                    })
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "blockId":
                if (value !== null) {
                    debugger;
                    this.state.blockId = value.blockId;
                    this.state.blockIdState = "success";
                }
                else {
                    this.setState({
                        [stateName + "State"]: " "
                    });
                }
                this.setDDValue(event, value);
                this.setAddress();
                break;
            case "fatherName":
                if (this.verifyAlphabets(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fatherName: event.target.value,
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "maritalStatusId":
                if (value.maritalStatus === "Single") {
                    this.setState({
                        spouseNameDisabled: true,
                        maritalStatusId: value.maritalStatusId,
                        spouseNameState: "success",
                        //spouseName: "",
                        //spouseNameId:""
                    });
                    this.state.spouseName = null;
                    this.state.maritalStatusName = value.maritalStatus;
                   // this.state.spouseNameState = 'success';
                }
                else {
                    this.setState({
                        spouseNameDisabled: false,
                    });
                    this.state.maritalStatusName = value.maritalStatus;
                }
                this.setDDValue(event, value);
                break;
            case "spouseName":
                if (this.verifyAlphabets(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        spouseName: event.target.value,
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                    });
                }
                break;
            case "PlaceofBirth":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        placeOfBirth: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "applicationStatusId":
                if (value.applicationStatusId != null) {
                    this.setState({
                        applicationStatusId: value.applicationStatusId,
                        applicationStatusIdState: "success"
                    })
                }
                else {
                    this.setState({
                        confirmationDateState: "error",
                    })
                }
                this.setDDValue(event, value);
                break;
            case "supervisorOneId":
                if (value.employeeId != null) {
                    this.setState({
                        supervisorOneId: value.employeeId,
                        supervisorOneIdIndex: this.state.supervisorOneDD.findIndex(x => x.employeeId === value.employeeId),
                        supervisorOneIdState: "success"
                    })
                }
                else {
                    this.setState({
                        supervisorOneId: "error",
                    })
                }
                break;
            case "supervisorTwoId":
                if (value.employeeId != null) {
                    this.setState({
                        supervisorTwoId: value.employeeId,
                        supervisorTwoIdIndex: this.state.supervisorTwoDD.findIndex(x => x.employeeId === value.employeeId),
                        supervisorTwoIdState: "success"
                    })
                }
                else {
                    this.setState({
                        supervisorTwoIdState: "error",
                    })
                }
                break;
            case "NameUrdu":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        nameUrdu: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "FatherSpouseNameUrdu":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fatherOrSpouseNameUrdu: event.target.value,
                    });
                } else {
                    this.state({
                        [stateName + "State"]: "success",
                    });
                }
                break;
            case "justificationId":
                if (value.justification === "Replacement") {
                    this.setState({
                        employeeIdDisabled: false
                    });
                    this.loadAllEmployeesDD();
                }
                else {
                    this.setState({
                        employeeDD: [{ employeeId: -1, name: "Select" }],
                        employeeIdIndex: -1,
                        employeeId: 0,
                        employeeIdState: "",
                        employeeIdDisabled: true,
                    });
                }
                this.setDDValue(event, value);
                break;
            case "equalTo":
                if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
                    this.setState({ [stateName + "State"]: "success" });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "checkbox":
                this.setState({ [stateName]: event });
                break;
        }
    }
      //Function Add Three Month of Seleted Date
    addMonths(date, months) {
        debugger
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}
    //Validation function
    validateData = () => {
        debugger;
        if ((this.state.employeeRegNo === 0) || (this.state.employeeRegNo === "error")) {
            this.setState({ employeeRegNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.titleId === 0) || (this.state.titleId === -1) || (this.state.titleIdState === "error")) {
            this.setState({ titleIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.genderId === 0) || (this.state.genderId === -1) || (this.state.genderIdState === "error")) {
            this.setState({ genderIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.employeeName === "") || (this.state.employeeNameState === "error")) {
            this.setState({ employeeNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.dob === "") || (this.state.dobState === "error")) {
            this.setState({ dobState: "error", generalError: true });
            return;
        }
        else if ((this.state.nicValue === null) || (this.state.nicState === "error")) {
            this.setState({ nicState: "error", generalError: true });
            return;
        }
        else if ((this.state.nicExpiry === null) || (this.state.nicExpiryState === "error")) {
            this.setState({ nicExpiryState: "error", generalError: true });
            return;
        }
        else if ((this.state.mobileNo === "") || (this.state.mobileNoState === "error")) {
            this.setState({ mobileNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.otherMobileNo === "") || (this.state.otherMobileNoState === "error")) {
            this.setState({ otherMobileNoState: "error", generalError: true });
            return;
        }
        //Sev 2
        else if ((this.state.house === "") || (this.state.houseState === "error")) {
            this.setState({ houseState: "error", generalError: true });
            return;
        }
        else if ((this.state.street === "") || (this.state.streetState === "error")) {
            this.setState({ streetState: "error", generalError: true });
            return;
        }
        else if ((this.state.countryId === 0) || (this.state.countryId === -1)) {
            this.setState({ countryIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.cityId === 0) || (this.state.cityId === -1)) {
            this.setState({ cityIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.currentAddress === "") || (this.state.currentAddressState === "error")) {
            this.setState({ currentAddressState: "error", generalError: true });
            return;
        }
        else if ((this.state.permanentAddress === "") || (this.state.permanentAddressState === "error")) {
            this.setState({ permanentAddressState: "error", generalError: true });
            return;
        }

        //sec 3 
        else if ((this.state.applicationStatusId === 0) || (this.state.applicationStatusId === -1)) {
            this.setState({ applicationStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.joiningDate === null) || (this.state.joiningDateState === "error")) {
            this.setState({ joiningDateState: "error", generalError: true });
            return;
        }

        //4th sec 
        else if ((this.state.fatherName === "") || (this.state.fatherName === null) || (this.state.fatherNameState === "error")) {
            this.setState({ fatherNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.maritalStatusName != 'Single') && (this.state.spouseName === null)) {
            this.setState({ spouseNameState: "error", generalError: true });
            return;
        }
        else if ((this.state.maritalStatusId === 0) || (this.state.maritalStatusId === -1)) {
            this.setState({ maritalStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.religionId === 0) || (this.state.religionId === -1)) {
            this.setState({ religionIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.employmentStatusId === 0) || (this.state.employmentStatusId === -1)) {
            this.setState({ employmentStatusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.confirmationDate === null) || (this.state.confirmationDateState === "error")) {
            this.setState({ confirmationDateState: "error", generalError: true });
            return;
        }

        else if ((this.state.justificationId === 0) || (this.state.justificationId === -1)) {
            this.setState({ justificationIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.applicationDate === null) || (this.state.applicationDateState === "error")) {
            this.setState({ applicationDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.appointmentDate === null) || (this.state.appointmentDateState === "error")) {
            this.setState({ appointmentDateState: "error", generalError: true });
            return;
        }
        else if ((this.state.supervisorOneId === 0) || (this.state.supervisorOneId === -1)) {
            this.setState({ supervisorOneIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.supervisorTwoId === 0) || (this.state.supervisorTwoId === -1)) {
            this.setState({ supervisorTwoIdState: "error", generalError: true });
            return;
        }
        let DateOfbirth = (this.state.dobValue == "") ? new Date() : this.state.dobValue;
        let Cnicexpiryvalue = (this.state.nicExpiryValue == "") ? new Date() : this.state.nicExpiryValue;
        if (this.state.townId === 0 || this.state.townId === -1) {
            this.state.townId = null;
        }
        if (this.state.sectorId === 0 || this.state.sectorId === -1) {
            this.state.sectorId = null;
        }
        if (this.state.BlockId === 0 || this.state.BlockId === -1) {
            this.state.BlockId = null;
        }
        if (this.state.bloodGroupId === 0 || this.state.bloodGroupId === -1) {
            this.state.bloodGroupId = null;
        }
        if (this.state.employeeId === 0 || this.state.employeeId === -1) {
            this.state.employeeId = null;
        }
        let emploInfo = {
            RegNo: this.state.employeeRegNo,
            EmployeePassword: this.state.employeePassword,
            UserPortalLogin: this.state.userPortalLogin,
            UserGroupId: this.state.userGroupId,
            TitleId: this.state.titleId,
            Name: this.state.employeeName,
            GenderId: this.state.genderId,
            DOB: DateOfbirth,
            CNIC: this.state.nicValue,
            CNICExpireDate: Cnicexpiryvalue,
            MobileNo: this.state.mobileNo,
            OtherMobileNo: this.state.otherMobileNo,
            LandLine: this.state.landLine,
            PersonalEmail: this.state.emailPersonal,
            House: this.state.house,
            Street: this.state.street,
            CountryId: this.state.countryId,
            CityId: this.state.cityId,
            TownId: this.state.townId,
            SectorId: this.state.sectorId,
            BlockId: this.state.blockId,
            CurrentAddress: this.state.currentAddress,
            PermanentAddress: this.state.permanentAddress,
            ApplicationStatus: this.state.applicationStatusId,
            JoiningDate: this.state.joiningDate,
            CanBeSupOne: this.state.isSupervisor1,
            CanBeSupTwo: this.state.isSupervisor2,
            FatherHusbandName: this.state.fatherName,
            MaritialStatusId: this.state.maritalStatusId,
            SpouseName: this.state.spouseName,
            BirthPlace: this.state.placeOfBirth,
            BloodGroupId: this.state.bloodGroupId,
            ReligionId: this.state.religionId,
            EmploymentStatusId: this.state.employmentStatusId,
            ConfirmedDate: Moment(this.state.confirmationDate).format('YYYY-MM-DD'),
            JustificationId: this.state.justificationId,
            ReplaceEmployeeId: this.state.employeeId,
            NameUrdu: this.state.nameUrdu,
            FatherNameUrdu: this.state.fatherOrSpouseNameUrdu,
            ApplicationDate: this.state.applicationDate,
            AppointmentDate: this.state.appointmentDate,
            SupOneId: this.state.supervisorOneId,
            SupTwoId: this.state.supervisorTwoId

        };
        console.log(emploInfo);
        axios.post(Base_URL + '/api/NewEmployee/SaveNewEmployee', emploInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.massage)
            })
    }
    AlertMessage(status, massage) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{massage}</SweetAlert>
                )
            });
            this.state.employeeRegNo= "";
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{massage}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    //#endregion

    render() {
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <PersonAddOutlined />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>New Employee</h4>
                    <hr style={{ marginBottom: "3px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />}
                        aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/HRPayroll" >HR & Payroll</Link>
                        <Typography color="textPrimary">New Employee</Typography>
                    </Breadcrumbs>
                    <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={6} md={6}>
                                {this.state.alert}
                                <Card style={{ marginTop: "7px", marginBottom: "7px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Personal Information</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.employeeRegNoState === "success"}
                                                    error={this.state.employeeRegNoState === "error"}
                                                    value={this.state.employeeRegNo}
                                                    size="small"
                                                    label="Registration No"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    disabled
                                                    type="number"
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.titleDD}
                                                    getOptionLabel={(option) => option.name}
                                                    value={this.state.titleDD[this.state.titleIdIndex]}
                                                    id="titleId"
                                                    required={true}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "titleId", "titleId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Title *" variant="outlined"
                                                        success={this.state.titleIdState === "success"}
                                                        error={this.state.titleIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.employeeNameState === "success"}
                                                    error={this.state.employeeNameState === "error"}
                                                    size="small"
                                                    label="Employee Name"
                                                    id="employeeName"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "employeeName", "employeeName", value, 3),
                                                        type: "text",
                                                        maxLength: 100
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.genderDD}
                                                    getOptionLabel={(option) => option.name}
                                                    value={this.state.genderDD[this.state.genderIdIndex]}
                                                    id="genderId"
                                                    disableClearable={true}
                                                    disabled={this.state.genderDisabled}
                                                    onChange={(event, value) => this.change(event, "genderId", "genderId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Gender *" variant="outlined"
                                                        success={this.state.genderState === "success"}
                                                        error={this.state.genderState === "error"} />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.dobState === "success"}
                                                    error={this.state.dobState === "error"}
                                                    size="small"
                                                    label={"DOB: " + this.state.age}
                                                    id="dobId"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    value={Moment(this.state.dob).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "dob", "dob") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <InputMask
                                                    {...this.props}
                                                    mask="99999-9999999-9"
                                                    maskChar=""
                                                >
                                                    {() => <TextField
                                                        success={this.state.nicState === "success"}
                                                        error={this.state.nicState === "error"}
                                                        size="small"
                                                        label={"NIC"}
                                                        helperText={this.state.nicBlackListed}
                                                        variant="outlined"
                                                        id="nic"
                                                        fullWidth
                                                        value={this.state.nic}
                                                        placeholder="00000-0000000-0"
                                                        required={true}
                                                        autoComplete="off"
                                                        inputProps={{
                                                            onChange: event =>
                                                                this.change(event, "nic", "nic"),
                                                            maxLength: 15
                                                        }}
                                                    />}
                                                </InputMask>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.nicExpiryState === "success"}
                                                    error={this.state.nicExpiryState === "error"}
                                                    size="small"
                                                    label="NIC Expiry"
                                                    id="nicExpiry"
                                                    variant="outlined"
                                                    required={true}
                                                    fullWidth
                                                    value={Moment(this.state.nicExpiry).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "nicExpiry", "nicExpiry") }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <InputMask
                                                    {...this.props}
                                                    mask="\3999999999"
                                                    maskChar=""
                                                >
                                                    {() => <TextField
                                                        success={this.state.mobileNoState === "success"}
                                                        error={this.state.mobileNoState === "error"}
                                                        size="small"
                                                        label="Mobile No"
                                                        id="mobileNo"
                                                        variant="outlined"
                                                        fullWidth
                                                        required={true}
                                                        autoComplete="off"
                                                        placeholder="3001234567"
                                                        inputProps={{
                                                            onChange: event =>
                                                                this.change(event, "mobileNo", "mobileNo"),
                                                            maxLength: 10,
                                                        }}
                                                    // onInput={(e) => {
                                                    //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    // }}
                                                    />
                                                    }
                                                </InputMask>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <InputMask
                                                    {...this.props}
                                                    mask="\3999999999"
                                                    maskChar=""
                                                >
                                                    {() =>
                                                        <TextField
                                                            success={this.state.otherMobileNoState === "success"}
                                                            error={this.state.otherMobileNoState === "error"}
                                                            size="small"
                                                            label="Other Mobile No"
                                                            id="otherMobileNo"
                                                            variant="outlined"
                                                            fullWidth
                                                            required={true}
                                                            autoComplete="off"
                                                            placeholder="3001234567"
                                                            inputProps={{
                                                                onChange: event =>
                                                                    this.change(event, "otherMobileNo", "otherMobileNo"),
                                                                maxLength: 10,
                                                            }}
                                                        />
                                                    }
                                                </InputMask>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.landLineState === "success"}
                                                    error={this.state.landLineState === "error"}
                                                    size="small"
                                                    label="Land Line"
                                                    id="LandLineId"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.change(event, "landLine", "landLine"),
                                                        type: "number"
                                                    }}
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13)
                                                    }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.emailPersonalState === "success"}
                                                    error={this.state.emailPersonalState === "error"}
                                                    size="small"
                                                    label="Email(Personal)"
                                                    placeholder="someone@mail.com"
                                                    id="emailPersonal"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{
                                                        onChange: event => this.change(event, "emailPersonal", "emailPersonal")
                                                    }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={6} md={6}>
                                <Card style={{ marginTop: "7px", marginBottom: "7px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Employee Joining  Information</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <PictureUpload />
                                                <hr style={{ marginBottom: "25px" }} />
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer xs={12} sm={12} md={12}>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.applicationStatusDD}
                                                    getOptionLabel={(option) => option.applicationStatus}
                                                    id="applicationStatusId"
                                                    value={this.state.applicationStatusDD[this.state.applicationStatusIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "applicationStatusId", "applicationStatusId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Application Status *" variant="outlined"
                                                        success={this.state.applicationStatusIdState === "success"}
                                                        error={this.state.applicationStatusIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <TextField
                                                    success={this.state.joiningDateState === "success"}
                                                    error={this.state.joiningDateState === "error"}
                                                    size="small"
                                                    label="Joining Date"
                                                    id="joiningDate"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer xs={12} sm={12} md={12}>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.change(event, "isSupervisor1", "checkbox")}
                                                        checked={this.state.isSupervisor1}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Supervisor 1</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={6}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.change(event, "isSupervisor2", "checkbox")}
                                                        checked={this.state.isSupervisor2}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Supervisor 2</span>
                                                </label>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                        </GridContainer>
                        <GridContainer>
                            <GridForm xs={12} sm={6} md={6}>
                                <Card style={{ marginTop: "7px", marginBottom: "7px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Address Information</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.houseState === "success"}
                                                    error={this.state.houseState === "error"}
                                                    size="small"
                                                    label="House"
                                                    id="houseId"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "house", "house", value, 1),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.streetState === "success"}
                                                    error={this.state.streetState === "error"}
                                                    size="small"
                                                    label="Street"
                                                    id="streetId"
                                                    variant="outlined"
                                                    fullWidth
                                                    required="true"
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "street", "street", value, 1),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="countryId"
                                                    options={this.state.countryDD}
                                                    getOptionLabel={(option) => option.countryName}
                                                    value={this.state.countryDD[this.state.countryIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "countryId", "countryId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Country *" variant="outlined"
                                                        success={this.state.countryIdState === "success"}
                                                        error={this.state.countryIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="cityId"
                                                    options={this.state.cityDD}
                                                    getOptionLabel={(option) => option.cityName}
                                                    value={this.state.cityDD[this.state.cityIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "cityId", "cityId", value)}
                                                    renderInput={(params) => <TextField {...params} label="City *" variant="outlined"
                                                        success={this.state.cityIdState === "success"}
                                                        error={this.state.cityIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="townId"
                                                    options={this.state.townDD}
                                                    getOptionLabel={(option) => option.townName}
                                                    value={this.state.townDD[this.state.townIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "townId", "townId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Town" variant="outlined"
                                                        success={this.state.townIdState === "success"}
                                                        error={this.state.townIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="sectorId"
                                                    options={this.state.sectorDD}
                                                    getOptionLabel={(option) => option.sectorName}
                                                    value={this.state.sectorDD[this.state.sectorIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "sectorId", "sectorId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Sector" variant="outlined"
                                                        success={this.state.sectorIdState === "success"}
                                                        error={this.state.sectorIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="blockId"
                                                    options={this.state.blockDD}
                                                    getOptionLabel={(option) => option.blockName}
                                                    value={this.state.blockDD[this.state.blockIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "blockId", "blockId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Block" variant="outlined"
                                                        success={this.state.saveblockIdState === "success"}
                                                        error={this.state.saveblockIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.currentAddressState === "success"}
                                                    error={this.state.currentAddressState === "error"}
                                                    size="small"
                                                    label="Current Address"
                                                    id="currentAddressId"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.currentAddress}
                                                    multiline={true}
                                                    rows={2}
                                                    required="true"
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "currentAddress", "currentAddress", value, 5),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.permanentAddressState === "success"}
                                                    error={this.state.permanentAddressState === "error"}
                                                    size="small"
                                                    label="Permanent Address"
                                                    id="permanentAddressId"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline={true}
                                                    rows={2}
                                                    required="true"
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "permanentAddress", "permanentAddress", value, 5),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={6} md={6}>
                                <Card style={{ marginTop: "7px", marginBottom: "7px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Other Information</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.fatherNameState === "success"}
                                                    error={this.state.fatherNameState === "error"}
                                                    size="small"
                                                    label="Father Name"
                                                    id="fatherNameId"
                                                    variant="outlined"
                                                    fullWidth
                                                    required="true"
                                                    required={true}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "fatherName", "fatherName", value, 3),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="maritalStatusId"
                                                    options={this.state.maritalStatusDD}
                                                    getOptionLabel={(option) => option.maritalStatus}
                                                    value={this.state.maritalStatusDD[this.state.maritalStatusIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "maritalStatusId", "maritalStatusId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Marital Status *" variant="outlined"
                                                        success={this.state.maritalStatusIdState === "success"}
                                                        error={this.state.maritalStatusIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.spouseNameState === "success"}
                                                    error={this.state.spouseNameState === "error"}
                                                    size="small"
                                                    label="Spouse Name"
                                                    id="spouseNameId"
                                                    variant="outlined"
                                                    fullWidth
                                                    disabled={this.state.spouseNameDisabled}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "spouseName", "spouseName", value, 3),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.placeOfBirthState === "success"}
                                                    error={this.state.placeOfBirthState === "error"}
                                                    size="small"
                                                    label="Place of Birth"
                                                    id="placeOfBirth"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.change(event, "PlaceofBirth", "PlaceofBirth"), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="bloodGroupId"
                                                    options={this.state.bloodGroupDD}
                                                    getOptionLabel={(option) => option.name}
                                                    value={this.state.bloodGroupDD[this.state.bloodGroupIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.setDDValue(event, value)}
                                                    renderInput={(params) => <TextField {...params} label="Blood Group" variant="outlined"
                                                        success={this.state.bloodGroupIdState === "success"}
                                                        error={this.state.bloodGroupIdState === "error"}
                                                    />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="religionId"
                                                    options={this.state.religionDD}
                                                    getOptionLabel={(option) => option.religionName}
                                                    value={this.state.religionDD[this.state.religionIdIndex]}
                                                    disableClearable={true}
                                                    required={true}
                                                    onChange={(event, value) => this.setDDValue(event, value)}
                                                    renderInput={(params) => <TextField {...params} label="Religion *" variant="outlined"
                                                        success={this.state.religionIdState === "success"}
                                                        error={this.state.religionIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="employmentStatusId"
                                                    options={this.state.employmentStatusDD}
                                                    getOptionLabel={(option) => option.employmentStatus}
                                                    value={this.state.employmentStatusDD[this.state.employmentStatusIdIndex]}
                                                    onChange={(event, value) => this.setDDValue(event, value)}
                                                    renderInput={(params) => <TextField {...params} label="Employment Status *" variant="outlined"
                                                        success={this.state.employmentStatusIdState === "success"}
                                                        error={this.state.employmentStatusIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.confirmationDateState === "success"}
                                                    error={this.state.confirmationDateState === "error"}
                                                    size="small"
                                                    label={"confirmationDate"}
                                                    id="confirmationDateId"
                                                    variant="outlined"
                                                    required={true}
                                                    fullWidth
                                                    value={Moment(this.state.confirmationDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    inputProps={{ onChange: event => this.change(event, "confirmationDate", "confirmationDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="justificationId"
                                                    options={this.state.justificationDD}
                                                    getOptionLabel={(option) => option.justification}
                                                    value={this.state.justificationDD[this.state.justificationIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "justificationId", "justificationId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Justification for Hiring *" variant="outlined"
                                                        success={this.state.justificationIdState === "success"}
                                                        error={this.state.justificationIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="employeeId"
                                                    options={this.state.employeeDD}
                                                    getOptionLabel={(option) => option.name}
                                                    value={this.state.employeeDD[this.state.employeeIdIndex]}
                                                    disableClearable={true}
                                                    disabled={this.state.employeeIdDisabled}
                                                    onChange={(event, value) => this.setDDValue(event, value)}
                                                    renderInput={(params) => <TextField {...params} label="Employees List" variant="outlined"
                                                        success={this.state.employeeIdState === "success"}
                                                        error={this.state.employeeIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.nameUrduState === "success"}
                                                    error={this.state.nameUrduState === "error"}
                                                    size="small"
                                                    label="Name Urdu"
                                                    id="NameUrdu"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.change(event, "NameUrdu", "NameUrdu"), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    success={this.state.fatherOrSpouseNameUrduState === "success"}
                                                    error={this.state.fatherOrSpouseNameUrduState === "error"}
                                                    size="small"
                                                    label="Father/Spouse Name Urdu"
                                                    id="FatherSpouseNameUrdu"
                                                    variant="outlined"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.change(event, "FatherSpouseNameUrdu", "FatherSpouseNameUrdu"), type: "text" }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    size="small"
                                                    label="Application Date"
                                                    id="ApplicationDate"
                                                    variant="outlined"
                                                    required={true}
                                                    // defaultValue={dateVal.currentDate}
                                                    value={Moment(this.state.ApplicationDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.change(event, "ApplicationDate", "applicationDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <TextField
                                                    size="small"
                                                    label="Appointment Date"
                                                    id="AppointmentDate"
                                                    variant="outlined"
                                                    required={true}
                                                    // defaultValue={dateVal.currentDate}
                                                    value={Moment(this.state.appointmentDate).format('YYYY-MM-DD')}
                                                    type="date"
                                                    fullWidth
                                                    inputProps={{ onChange: event => this.change(event, "AppointmentDate", "AppointmentDate") }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="supervisorOneId"
                                                    options={this.state.supervisorOneDD}
                                                    value={this.state.supervisorOneDD[this.state.supervisorOneIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "supervisorOneId", "supervisorOneId", value)}
                                                    // onChange={(event, value) => this.setSuperDDValue(event, value)}
                                                    getOptionLabel={(option) => option.name}
                                                    required={true}
                                                    renderInput={(params) => <TextField {...params} label="Supervisor 1 *" variant="outlined"
                                                        success={this.state.supervisorOneIdState === "success"}
                                                        error={this.state.supervisorOneIdState === "error"}
                                                    />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    options={this.state.supervisorTwoDD}
                                                    value={this.state.supervisorTwoDD[this.state.supervisorTwoIdIndex]}
                                                    disableClearable={true}
                                                    onChange={(event, value) => this.change(event, "supervisorTwoId", "supervisorTwoId", value)}
                                                    // onChange={(event, value) => this.setDDValue(event, value)}
                                                    getOptionLabel={(option) => option.name}
                                                    id="supervisorTwoId"
                                                    renderInput={(params) => <TextField {...params} label="Supervisor 2 *" variant="outlined"
                                                        success={this.state.supervisorTwoIdState === "success"}
                                                        error={this.state.supervisorTwoIdState === "error"}
                                                    />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridTextbox xs={12} sm={12} md={4} style={{ marginLeft: "47%" }}>
                                <Button color="facebook" onClick={() => { this.validateData() }}
                                    disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />Save</Button>
                            </GridTextbox>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        );
    }
}

export default withStyles(formStyle)(NewEmployee);