import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    axios, SearchSharp, Autocomplete, FormControl, InputLabel, Select
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class StdTestAssessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Datatable Columns
            columns: [{ name: "testAssessmentText", label: "Assessment Text" },
            { name: "levelName", label: "Level Name" },
            {
                name: "active", label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            },
                {
                    name: "testAssessmentTypeText", label: "Options",
                },
            {
                name: "testAssessmentId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                    }
                }
            }],
            //#endregion
            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false, searchCheck: true, 
            testAssessmentList: [], levelDD: [{ levelId: -1, levelName: 'Select' }],
            testAssessmentId: -1, isYesNo: true, levelId: -1, levelIdState: '', levelIndex: 0, testAssessmentText: '', testAssessmentTextState: '', active: true,
            assessmentDD: { resultTypeId: -1, testAssessmentTypeText: 'Select' }, resultTypeId: -1, resultTypeIdState: '', resultTypeIndex:0
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#region Button,Validaition
    validateData = () => {
        if (this.state.testAssessmentText === "") {
            this.setState({ testAssessmentTextState: "error" });
            return;
        }
        else if (this.state.levelId === -1) {
            this.setState({ levelIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }

    SaveSTestAssessment = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const formData = new FormData()
        formData.append("TestAssessmentId", this.state.testAssessmentId)
        formData.append("TestAssessmentText", this.state.testAssessmentText)
        formData.append("LevelId", this.state.levelId)
        formData.append("ResultTypeId", this.state.resultTypeId)
        formData.append("Active", this.state.active)
        axios.post(Base_URL + "/StudentPrimaryData/SaveSTestAssessment", formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    LoadTextbox(id) {
        debugger;
        let obj = this.state.testAssessmentList.find(o => o.testAssessmentId === id);
        this.setState({
            testAssessmentText: obj.testAssessmentText, levelId: obj.levelId, isYesNo: obj.isYesNo, resultTypeId: obj.resultTypeId,
            active: obj.active, testAssessmentId: obj.testAssessmentId, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block',
            levelIndex: this.state.levelDD.findIndex(x => x.levelId === obj.levelId),
            resultTypeIdIndex: this.state.assessmentDD.findIndex(x => x.resultTypeId === obj.resultTypeId)
        });
        //this.setState({ levelIndex: this.state.levelDD.findIndex(x => x.levelId === obj.levelId) });
        //var index = this.state.assessmentDD.findIndex(x => x.resultTypeId === obj.resultTypeId);
        //this.setState({ resultTypeIdIndex: index});
    }
    ClearTextfieldStates = () => {
        debugger;
        this.setState({ testAssessmentId: -1, testAssessmentText: '', testAssessmentTextState: '', levelId: -1, levelIdState: '', levelIndex: 0, resultTypeId: -1, resultTypeIdState: '', resultTypeIdIndex: 0, active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', });
        this.hideAlert();
        this.LoadData();
    }
    //#endregion 

    //#region Backend Data Fetching and Saving

    LoadData = () => {
        fetch(Base_URL + "/StudentPrimaryData/LoadStudentTestAssessment?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ testAssessmentList: data });
            }).catch(error => {
                console.log(error);
            });
    }
    componentDidMount() {
        this.loadlevelDD();
        this.loadtestassessmentDD();
    }
    async loadlevelDD() {
        fetch(Base_URL + "/api/Dropdowns/LevelDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ levelDD: data.levelsDDList });
            }).catch(error => {
                console.log(error);
            });
    }
    async loadtestassessmentDD() {
        fetch(Base_URL + "/api/Dropdowns/AssessmentResultDD").then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ assessmentDD: data.testTypesDD});
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion 
    handleChange(event, value) {
        //debugger;
        const stateName = event.target.id.split("-")[0];
        this.setState({
            [stateName]: value[stateName],
            [stateName + "State"]: "success",
            generalError: false
        })
    }
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveSTestAssessment()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Do you want to save Test Assessment detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { testAssessmentList, levelDD, columns, testAssessmentText, testAssessmentTextState, levelIdState, levelIndex, isYesNo, active, searchCheck, clearTextBtn, assessmentDD, resultTypeIndex, resultTypeIdState, resultTypeId } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Student Test Assessments </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                               
                                                <GridTextbox xs={12} sm={12} md={4}>
                                                    <TextField
                                                        success={testAssessmentTextState === "success"}
                                                        error={testAssessmentTextState === "error"}
                                                        size="small"
                                                        label="Assessment Text"
                                                        id="testAssessmentText"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={testAssessmentText}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="levelId"
                                                        size="small"
                                                        options={levelDD}
                                                        getOptionLabel={(option) => option.levelName}
                                                        value={levelDD[levelIndex]}
                                                        onChange={(event, value) => { this.setState({ levelId: value.levelId, levelIdState: "success", levelIndex: levelDD.findIndex(x => x.levelId === value.levelId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={levelIdState === "success"}
                                                            error={levelIdState === "error"} label="Level Name" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                {/*<GridTextbox xs={12} sm={12} md={2}>*/}
                                                {/*    <Autocomplete*/}
                                                {/*        disableClearable*/}
                                                {/*        id="resultTypeId"*/}
                                                {/*        size="small"*/}
                                                {/*        options={assessmentDD}*/}
                                                {/*        getOptionLabel={(option) => option.testAssessmentTypeText}*/}
                                                {/*        value={assessmentDD[resultTypeIdIndex]}*/}
                                                {/*        onChange={(event, value) => { this.setState({ resultTypeId: value.resultTypeId, resultTypeIdState: "success", resultTypeIdIndex: assessmentDD.findIndex(x => x.resultTypeId === value.resultTypeId) }) }}*/}
                                                {/*        renderInput={(params) => <TextField {...params} success={resultTypeIdState === "success"}*/}
                                                {/*            error={resultTypeIdState === "error"} label="Options" variant="outlined" />}*/}
                                                {/*    />*/}
                                                {/*</GridTextbox>*/}
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="resultTypeId"
                                                        size="small"
                                                        options={assessmentDD}
                                                        getOptionLabel={(option) => option.testAssessmentTypeText}
                                                        value={assessmentDD[resultTypeIndex]}
                                                        onChange={(event, value) => { this.setState({ resultTypeId: value.resultTypeId, resultTypeIdState: "success", resultTypeIndex: assessmentDD.findIndex(x => x.resultTypeId === value.resultTypeId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={resultTypeIdState === "success"}
                                                            error={resultTypeIdState === "error"} label="Result Type" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Student Test Assessments"}
                                        data={testAssessmentList}
                                        columns={columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdTestAssessment);
