import {
    React, GridContainer, Card, CardHeader, CardBody, Box, Typography, PropTypes, CardIcon, Link, Tab, Breadcrumbs,
    NavigateNextIcon, Tabs, HomeIcon, PermDataSettingOutlined
} from '../../components/Common/ImportAll'
//#region Import Employee Views
import ACRDepartment from "./ACRPrimarydataTabs/ACRDepartment";
import ACRDepartmentLink from "./ACRPrimarydataTabs/ACRDepartmentLink";
import ACRDesignation from "./ACRPrimarydataTabs/ACRDesignation";
import ACRDesignationLink from "./ACRPrimarydataTabs/ACRDesignationLink";
import ACRDepDesigLink from "./ACRPrimarydataTabs/ACRDepDesigLink";
import ACRHeads from "./ACRPrimarydataTabs/ACRHeads";
import ACRSubHeads from "./ACRPrimarydataTabs/ACRSubHeads";
import ACRRemarks from "./ACRPrimarydataTabs/ACRRemarks";
//#endregion

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ScrollableTabsButtonAuto() {
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="info" icon>
                    <CardIcon color="success">
                        <PermDataSettingOutlined />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>ACR Management</h4>
                    <hr style={{ marginTop: "15px", marginBottom: "4px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/HRPayroll" >HR & Payroll</Link>
                        <Typography color="textPrimary">ACR Management</Typography>
                    </Breadcrumbs>
                    <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                    <CardBody>
                        <GridContainer>
                            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable"
                                scrollButtons="on" style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary"
                                textColor="primary" wrapped >
                                <Tab label="ACR Department" {...a11yProps(0)} />
                                <Tab label="ACR Department Link" {...a11yProps(1)} />
                                <Tab label="ACR Designation" {...a11yProps(2)} />
                                <Tab label="ACR Designation Link" {...a11yProps(3)} />
                                <Tab label="ACR Dept & Desig Link" {...a11yProps(4)} />
                                <Tab label="ACR Heads" {...a11yProps(5)} />
                                <Tab label="ACR Sub Heads" {...a11yProps(6)} />
                                <Tab label="ACR Remarks" {...a11yProps(7)} />
                            </Tabs>
                            {selectedTab === 0 && <ACRDepartment />}
                            {selectedTab === 1 && <ACRDepartmentLink />}
                            {selectedTab === 2 && <ACRDesignation />}
                            {selectedTab === 3 && <ACRDesignationLink />}
                            {selectedTab === 4 && <ACRDepDesigLink />}
                            {selectedTab === 5 && <ACRHeads />}
                            {selectedTab === 6 && <ACRSubHeads />}
                            {selectedTab === 7 && <ACRRemarks />}
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}
