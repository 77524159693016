//Import from Globel Component";
import {
    React, axios, Moment, ReactToggle, SweetAlert, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint, PictureUpload
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;

class EmployeeInactivation extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData[0].EmployeeId),
            inactiveEmployeeList: [],
            leavingDate: editRegData[0].LeavingDate === '' ? new Date() : editRegData[0].LeavingDate,
            leavingDateState: "",
            isBlackList: editRegData[0].isBlacklisted === 'True' ? 1 : 0,
            isActive: editRegData[0].Active === 'True' ? 1 : 0,
            reasonId: editRegData[0].ReasonForLeavingId === '0' ? 0 : parseInt(editRegData[0].ReasonForLeavingId),
            reasonIdIndex: 0,
            reasonIdState: "success",
            ReasonDD: [{ reasonId: -1, reason: "Select" }],
            generalError: false, alert: null,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadEmpLeftReasonDD();
        this.LoadInactiveEmployee();

    }
    async LoadEmpLeftReasonDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/Dropdowns/EmpLeftReasonDD"))
            if (response.ok) {
                const data = await response.json();
                let allList = [];
                allList.push({ reasonId: -1, reason: "Select" });
                allList = allList.concat(data.empLeftReasonDDList);
                this.setState({
                    ReasonDD: allList
                });
                if (allList.length > 0) {
                    if (this.state.reasonId != 0) {
                        this.setState({
                            reasonId: this.state.ReasonDD.find(x => x.reasonId === this.state.reasonId),
                            reasonIdIndex: this.state.ReasonDD.findIndex(x => x.reasonId === this.state.reasonId),
                            reasonIdState: "success"
                        });
                    }
                    else {
                        this.setState({
                            reasonId: allList[0]['reasonId'],
                            reasonIdIndex: 0,
                            reasonIdState: "success"
                        })
                    }
                }
                else {
                    this.setState({
                        reasonId: 0,
                        reasonIdIndex: -1,
                        reasonIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadInactiveEmployee() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadInactiveEmployee?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    inactiveEmployeeList: data.inactiveEmployeeList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        //onConfirm={() => this.ClearTextfieldStates()}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ),
                saveUpdateincBtnDisabled: false,
                saveUpdateAlloBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null,
        });
        this.LoadInactiveEmployee();
    }
    ClearTextfieldStates = () => {
        this.hideAlert()
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.UpdateEmployeeStatus()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, Update it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Employee will be updated!
                </SweetAlert>
            )
        });
    }
    UpdateEmployeeStatus = () => {
        debugger;
        //this.hideAlert();
        if (this.state.isActive === true) {
            this.state.leavingDate = null;
            this.state.reasonId = 0;
            this.state.isActive = true;
            this.state.isBlackList = false;
            this.state.reasonIdIndex = -1
            //this.setState({
            //    isBlackList: false,
            //    isActive: true,
            //    reasonId: 0,
            //    reasonIdIndex:-1
            //})
        }
        else {

            //this.setState({
            //    isBlackList: this.state.isBlackList === 1 ? true : false,
            //})
            if ((this.state.reasonId === -1) || (this.state.reasonId === "") || (this.state.reasonId === 0)) {
                this.setState({ reasonIdState: "error", generalError: true });
                return;
            }
            else if (this.state.leavingDate === "" || this.state.leavingDate === null || this.state.leavingDateState === "error") {
                this.setState({ leavingDateState: "error", generalError: true });
                return;
            }
            Moment(this.state.leavingDate).format('YYYY-MM-DD');
        }
        let EmployeeInfo = {
            Active: this.state.isActive,
            EmployeeId: this.state.employeeId,
            Blacklisted: this.state.isBlackList === 1 ? true : false,
            LeavingDate: this.state.leavingDate,
            ReasonForLeavingId: this.state.reasonId,
        };
        console.log(EmployeeInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/UpdateEmployeeStatus', EmployeeInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
        EmployeeInfo = "";
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "leavingDate":
                debugger;
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        leavingDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
            case "reasonId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.ReasonDD.findIndex(x => x[stateName] === value[stateName]),
                    reasonId: value.reasonId,
                    [stateName + "State"]: "success"

                })
                break;
            case "checkbox":
                this.setState({
                    [stateName]: event
                });
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { inactiveEmployeeList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Photo</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <PictureUpload />
                                        <hr style={{ marginBottom: "0px" }} />
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Inactivation Detail</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.leavingDateState === "success"}
                                            error={this.state.leavingDateState === "error"}
                                            size="small"
                                            label="Leaving Date"
                                            id="leavingDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.leavingDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "leavingDate", "leavingDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.ReasonDD}
                                            getOptionLabel={(option) => option.reason}
                                            id="reasonId"
                                            value={this.state.ReasonDD[this.state.reasonIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "reasonId", "reasonId", value)}
                                            renderInput={(params) => <TextField {...params} label="Reason For Leaving" variant="outlined"
                                                success={this.state.reasonIdState === "success"}
                                                error={this.state.reasonIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <hr />
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "isBlackList", "checkbox")}
                                                checked={this.state.isBlackList}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> isBlack Listed </span>
                                        </label>
                                        <hr />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <hr />
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.change(event, "isActive", "checkbox")}
                                                checked={this.state.isActive}
                                                onColor="#3b5998"
                                                className={classes.toggleStyle}
                                                borderRadius={4}
                                                handleDiameter={22}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span> Active </span>
                                        </label>
                                        <hr />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <hr />
                                        <Button color="facebook" onClick={() => { this.confirmMessageUpdate() }}> Update </Button>
                                        <hr />
                                    </GridTextbox>
                                    <hr />
                                    {/*<GridItem xs={12} sm={12} md={12}>*/}
                                    {/*    <MuiThemeProvider theme={muiWithToolbar()}>*/}
                                    {/*        <MUIDataTable*/}
                                    {/*            title={"Employee Details"}*/}
                                    {/*            data={inactiveEmployeeList}*/}
                                    {/*            columns={[*/}
                                    {/*                { name: "name", label: "Name" },*/}
                                    {/*                { name: "professionalEmail", label: "Professional Email" },*/}
                                    {/*                {*/}
                                    {/*                    name: "active",*/}
                                    {/*                    label: "Active",*/}
                                    {/*                    options: {*/}
                                    {/*                        filter: true,*/}
                                    {/*                        customBodyRender: (value) => {*/}
                                    {/*                            return (value) === true ? "Yes" : (value) === false ? "No" : "";*/}
                                    {/*                        }*/}
                                    {/*                    }*/}
                                    {/*                },*/}
                                    {/*                {*/}
                                    {/*                    name: "reason",*/}
                                    {/*                    label: "Reason",*/}
                                    {/*                    options: {*/}
                                    {/*                        filter: true,*/}
                                    {/*                        customBodyRender: (value) => {*/}
                                    {/*                            return (value) === "" ? "No Reason" : (value) === null ? "No Reason" : (value) === "NULL" ? "No Reason" : value;*/}
                                    {/*                        }*/}
                                    {/*                    }*/}
                                    {/*                },*/}
                                    {/*                //{ name: "reason", label: "Reason" },*/}
                                    {/*                { name: "leavingDate", label: "Leaving Date" },*/}
                                    {/*            ]}*/}
                                    {/*            options={tbleWithPrint}*/}
                                    {/*        />*/}
                                    {/*    </MuiThemeProvider>*/}
                                    {/*</GridItem>*/}
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(EmployeeInactivation);