import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint
    , DeleteIcon
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class HrLeaveQuota extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lcId:"",
            leaveName: "",
            leaveNameState:"",
            casualLeave: "",
            casualLeaveState:"",
            saturdayLeave: "",
            saturdayLeaveState:"",
            saturdayActive: true,
            sundayActive: true,
            compensatoryActive: true,
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            activeDisabled:false,
            clearTextBtn: 'none',

            //#region Datatable Columns and Data
            hrLeaveQuotaData: [],
            AssignDataList:[],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Quota Name",
                options: {
                    filter: true,
                }
            },
                {
                name: "casualLeave",
                label: "Casual Leave",
                options: {
                    filter: true,
                }
            }, {
                name: "saturdayLeave",
                label: "Saturday Leave",
                options: {
                    filter: true,
                }
                },
                 {
                    name: "saturday",
                    label: "Saturday",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
                },{
                    name: "sunday",
                    label: "Sunday",
                    options: {
                        filter: true,
                        customBodyRender: (value) => {
                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                        }
                    }
                },{
                    name: "compensatory",
                    label: "Compensatory",
                    options: {
                        filter: true,
                        customBodyRender: (value) => {
                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                        }
                    }
                },
                {
                    name: "active",
                    label: "Active",
                    options: {
                        filter: true,
                        customBodyRender: (value) => {
                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                        }
                    }
                },
                {
                    name: "modifierName",
                    label: "Modifier",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "modifiyDate",
                    label: "Modified Date",
                    options: {
                        filter: true,
                    }
                },
                    {
                    name: "lcId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
                },
                {
                    name: "lcId",
                    label: "Delete",
                    options: {
                        customBodyRender: (value) => {
                            return (< DeleteIcon color = "rose"onClick={() => this.confirmMessageDelete(value)} />);
                        }
                    }
                }
            ],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
       this.LoadhrLeaveQuota = this.LoadhrLeaveQuota.bind(this);
    }
    async componentDidMount() {
        this.LoadhrLeaveQuota();
    }
    LoadhrLeaveQuota = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/HrLeaveQuata")
            .then(response => { return response.json() })
            .then(data => {
                this.setState({
                    hrLeaveQuotaData: data.hrLeaveQuataList
                })
            })
            .catch(error => { console.log(error) });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    verifyNumber(value) {
        debugger
        var mobileRex = /^[0-9]+$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "leaveName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        leaveName: event.target.value
                    });
                } else {
                    this.setState({
                        leaveName: event.target.value,
                        [stateName + "State"]: "error"
                    });
                }
                break; 
            case "casualLeave":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        casualLeave: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        casualLeave: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            case "saturdayLeave":
                debugger
                if (this.verifyNumber(event.target.value)) {
                    this.setState({
                        stateName: stateName,
                        saturdayLeave: event.target.value,
                        [stateName + "State"]: "success",
                    })
                }
                else {
                    this.setState({
                        stateName: "",
                        saturdayLeave: "",
                        [stateName + "State"]: "error"
                    })
                }
                break;
            default:
                break;
        }
    }
    isValidated = () => {
        debugger
        if (this.state.leaveName === "" || this.state.leaveNameState === "error") {
            this.setState({ leaveNameState: "error" });
            return;
        }
        else if (this.state.casualLeave === "" || this.state.casualLeaveState === "error") {
            this.setState({ casualLeaveState: "error" });
            return;
        }
        else if (this.state.saturdayLeave === "" || this.state.saturdayLeaveState === "error") {

            this.setState({ saturdayLeaveState: "error" });
            return;
        }
        if (this.state.lcId === "" || this.state.lcId === 0) {
            this.save();
        }
            else {
            this.confirmMessageUpdate();
            }
        
    }
    Delete = (value) => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/DeleteHrLeaveQuota?LcId=" + value)
            .then(response => { return response.json() })
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            })
            .catch(error => { console.log(error) });
        this.hideAlert();
    }
    save = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Name: this.state.leaveName, CasualLeave: this.state.casualLeave, SaturdayLeave: this.state.saturdayLeave, Saturday: this.state.saturdayActive, Sunday: this.state.sundayActive, Compensatory: this.state.compensatoryActive, Active: this.state.active })
        };
        fetch(Base_URL + "/api/primaryDataHR/HrLeaveQuata", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    update = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ LcId: this.state.lcId, Name: this.state.leaveName, CasualLeave: this.state.casualLeave, SaturdayLeave: this.state.saturdayLeave, Saturday: this.state.saturdayActive, Sunday: this.state.sundayActive, Compensatory: this.state.compensatoryActive, Active: this.state.active })
        };
        fetch(Base_URL + "/api/primaryDataHR/UpdatehrLeaveQuata", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new department will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure to Update It?"
                    onConfirm={() => this.update()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Department will be updated!
                </SweetAlert>
            )
        });
    }
    confirmMessageDelete(value) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-170px" }}
                    title="Are you sure to delete It?"
                    onConfirm={() => this.Delete(value)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes !"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Leave Quota will be Deleted!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        debugger
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}
                    </SweetAlert>
                )
            });
        }
        else if (status === "Assign") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}
                    </SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion
    LoadTextbox = (lcId) => {
        debugger
        this.AssignQuotaData(lcId);
        let obj = this.state.hrLeaveQuotaData.find(o => o.lcId === lcId);
        if (obj != null && obj != undefined && obj != "") {
            this.setState({ lcId: obj.lcId, leaveName: obj.name, casualLeave: obj.casualLeave, saturdayLeave: obj.saturdayLeave, active: obj.active, leaveNameState: "success", saturdayLeaveState: "success", casualLeaveState: "success", saturdayActive: obj.saturday, sundayActive: obj.sunday, compensatoryActive: obj.compensatory, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        }
        else {
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    AssignQuotaData = (value) => {
        debugger
        fetch(Base_URL + "/api/primaryDataHR/AssignQuotaData?LcId=" + value)
            .then(response => { return response.json() })
            .then(data => {
                this.setState({
                    AssignDataList: data.hrAssignList
                })
                if (data.hrAssignList.length > 0) {
                    this.setState({
                        activeDisabled: true,
                    });
                }
                else {
                    this.setState({
                        activeDisabled: false,
                    });
                }
            })
            .catch(error => { console.log(error) });
    }
    ClearTextfieldStates = () => {
        debugger
        this.setState({
            alert: null, lcId: "", leaveName: "", casualLeave: "", saturdayLeave: "", saturdayActive: true, sundayActive: true, compensatoryActive: true, active: true, activeDisabled: false ,saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
       this.LoadhrLeaveQuota();
    }
    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Add Leave Quata</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.leaveNameState === "success"}
                                                    error={this.state.leaveNameState === "error"}
                                                    value={this.state.leaveName}
                                                    size="small"
                                                    label="Leave Name"
                                                    variant="outlined"
                                                    id="leaveName"
                                                    fullWidth
                                                    required={true}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "leaveName", "leaveName", value, 3),
                                                        type: "text",
                                                        maxLength: 50
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.casualLeaveState === "success"}
                                                    error={this.state.casualLeaveState === "error"}
                                                    value={this.state.casualLeave}
                                                    size="small"
                                                    label="Casual Leave "
                                                    variant="outlined"
                                                    id="casualLeave"
                                                    fullWidth
                                                    required={true}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "casualLeave", "casualLeave",value, 10),
                                                        type: "text",
                                                        maxLength: 10000
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.saturdayLeaveState === "success"}
                                                    error={this.state.saturdayLeaveState === "error"}
                                                    value={this.state.saturdayLeave}
                                                    size="small"
                                                    label="Saturday Leave"
                                                    variant="outlined"
                                                    id="saturdayLeave"
                                                    fullWidth
                                                    required
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "saturdayLeave", "saturdayLeave", value, 10),
                                                        type: "text",
                                                        maxLength: 1000000
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "saturdayActive")}
                                                        checked={this.state.saturdayActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <hr />
                                                    <span>Saturday</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "sundayActive")}
                                                        checked={this.state.sundayActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <hr />
                                                    <span>Sunday</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "compensatoryActive")}
                                                        checked={this.state.compensatoryActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <hr />
                                                    <span>Compensatory</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        disabled={this.state.activeDisabled}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <hr />
                                                    <span>Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} >
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled} > <SearchSharp /> {this.state.saveUpdateBtnText}  </Button>
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2} >
                                              <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardHeader>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Departments"}
                                        data={this.state.hrLeaveQuotaData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(HrLeaveQuota);