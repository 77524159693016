
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, QueuePlayNext, GridItem, PictureUpload, GetAppIcon, DeleteIcon, swal, Edit, PersonAddOutlined
    , NavigateNext, Home, InputMask, extendedFormsStyle, PresentToAll
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class Addnewletter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            paid: true,
        };
        //#endregion
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    onEditorStateChang = (editorState) => {
        this.setState({
            editorState,
        });
    };

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <PersonAddOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Add Letter</h4>
                        <hr style={{ marginBottom: "3px" }} />
                    </CardHeader>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Add Letter</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "7px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Add New Letter</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Letter Type" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Designation"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Sub Letter Type" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1} color="facebook" style={{ marginLeft: "1%" }}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "paid")}
                                                            checked={this.state.paid}
                                                            onColor="#3b5998"
                                                            color="facebook"
                                                            className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                        />
                                                        <span>Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <Button color="facebook"><SaveOutlined />Save</Button>
                                                    <Button color="facebook">Update</Button>
                                                    <Button color="facebook">Reset</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                {/*<div>*/}
                                {/*    <Editor*/}
                                {/*        editorState={this.state.editorState}*/}
                                {/*        wrapperClassName="demo-wrapper"*/}
                                {/*        editorClassName="demo-editor"*/}
                                {/*        onEditorStateChange={this.onEditorStateChange}*/}
                                {/*    />*/}
                                {/*</div>*/}
                                {/*<GridForm xs={12} sm={12} md={12}>*/}
                                {/*    <MuiThemeProvider theme={muiWithToolbar()}>*/}
                                {/*        <MUIDataTable*/}
                                {/*            columns={[*/}
                                {/*               { label: "Print" },*/}
                                {/*            ]}*/}
                                {/*            options={tbleWithPrint}*/}
                                {/*        />*/}
                                {/*    </MuiThemeProvider>*/}
                                {/*</GridForm>*/}
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(Addnewletter);