//Import from Globel Component";
import {
    React, Moment, GridItem, formStyle, withStyles, MuiThemeProvider, GridContainer, GridForm, Card, CardHeader,
    CardBody, MUIDataTable, muiWithToolbar, tbleWithPrint, EditOutlined
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class ChildInfo extends React.Component {

    constructor(props) {
        debugger;
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            employeeId: parseInt(editRegData["EmployeeId"]),
            ChildrenList: [],
        }
    };
    async componentDidMount() {
        debugger
        this.LoadChildrenData();
    }
    async LoadChildrenData() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadChildrenData?EmployeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    ChildrenList: data.childrendataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    render() {
        const { classes } = this.props;
        const { ChildrenList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Chaildren Details "}
                                    data={ChildrenList}
                                    columns={[
                                        //{
                                        //    name: "studentId",
                                        //    label: "Edit",
                                        //    options: {
                                        //        customBodyRender: (value) => {
                                        //            return (<EditOutlined onClick={() => { this.LoadTextboxw(value) }} />);
                                        //        }
                                        //    }
                                        //},
                                        { name: "name", label: "Student Name" },
                                        { name: "registrationNo", label: "Registration No" },
                                        /*{ name: "address", label: "Address" },*/
                                        { name: "sessionName", label: "Session Name" },
                                        { name: "sectorName", label: "School Campus" },
                                        { name: "className", label: "Class Name" },
                                        { name: "sectionName", label: "Section Name" },
                                        { name: "concessionRatio", label: "Discount  Ratio" },
                                    ]}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(ChildInfo);