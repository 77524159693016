import {
    React, GridContainer, Card, CardHeader, CardBody, Box, Typography, PropTypes, CardIcon, Link, PermDataSettingOutlined, Tab, Breadcrumbs,
    NavigateNextIcon, Tabs, HomeIcon
    
} from '../../components/Common/ImportAll'
//#region Import Primary Data Views
import Campus from "./PrimaryDataTabs/Campus";
import Countries from "./PrimaryDataTabs/Countries";
import Cities from "./PrimaryDataTabs/Cities"; 
import Towns from "./PrimaryDataTabs/Towns"; 
import Sectors from "./PrimaryDataTabs/Sectors";
import Blocks from "./PrimaryDataTabs/Blocks";
import VisaType from "./PrimaryDataTabs/VisaType";
import Religion from './PrimaryDataTabs/Religions';
import Nationalities from './PrimaryDataTabs/Nationalities';
import SchoolCities from './PrimaryDataTabs/SchoolCities';
//#endregion

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ScrollableTabsButtonAuto() {
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="info" icon>
                    <CardIcon color="success">
                        <PermDataSettingOutlined />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Primary Data<small> Management</small></h4>
                    <hr style={{ marginBottom: "8px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link  to="/app/setupManagement" >Setup Management</Link>
                        <Typography color="textPrimary">Primary Data</Typography>
                    </Breadcrumbs>

                    <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <CardBody>
                        <GridContainer>
                        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="on" style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary" textColor="primary" wrapped >
                                <Tab label="School Cities" {...a11yProps(0)} />
                                <Tab label="Campus" {...a11yProps(1)} />
                                <Tab label="Countries" {...a11yProps(2)} />
                                <Tab label="Cities" {...a11yProps(3)} />
                                <Tab label="Towns" {...a11yProps(4)} />
                                <Tab label="Sectors" {...a11yProps(5)} />
                                <Tab label="Blocks" {...a11yProps(6)} />
                                <Tab label="Visa Type" {...a11yProps(7)} />
                                <Tab label="Religion" {...a11yProps(8)} />
                                <Tab label ="Nationalities" {...a11yProps(9)} />
                            </Tabs>
                            {selectedTab === 0 && <SchoolCities />}
                            {selectedTab === 1 && <Campus />}
                            {selectedTab === 2 && <Countries />}
                            {selectedTab === 3 && <Cities />}
                            {selectedTab === 4 && <Towns />}
                            {selectedTab === 5 && <Sectors />}
                            {selectedTab === 6 && <Blocks />}
                            {selectedTab === 7 && <VisaType />}
                            {selectedTab === 8 && <Religion />}
                            {selectedTab === 9 && <Nationalities />}
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}
