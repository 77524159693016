import React from 'react';
/*import 'date-fns';*/
import Grid from '@material-ui/core/Grid';
/*import DateFnsUtils from '@date-io/date-fns';*/
// react component used to create sweet alerts
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from 'react-datetime';
// @material-ui/core components
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
/*import "react-datetime/css/react-datetime.css";*/
// @material-ui/icons
import { NavigateNext as NavigateNextIcon  } from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";
import Moment from 'moment';
import $ from 'jquery';
//import "react-datepicker/dist/react-datepicker.css";
//import {
//    MuiPickersUtilsProvider,
//    KeyboardTimePicker,
//    KeyboardDatePicker,
//} from '@material-ui/pickers';
//import PickDate from 'views/StudentManagement/PickDate'

var Base_URL = localStorage.BaseURL;
//const [startDate, setStartDate] = useState(new Date());
class AdmissionChallan extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            //selectedDate: new Date('2014-08-18T21:11:54'),
            //setSelectedDate: '',
           
            //  #region Dropdown
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0,
            sessionId: 0,
            sessionIdState: '',
            sectorDD: [{ sectorId: -1, sectorName: 'Select' }],
            sectorIdIndex: 0,
            sectorId: 0,
            sectorIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classIdIndex: 0,
            classId: 0,
            classIdState: '',
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionIdIndex: 0,
            sectionId: 0,
            sectionIdState: '',
            levelDD: [{ levelId: -1, levelName: 'Select' }],
            levelIdIndex: 0,
            levelId: 0,
            levelIdState: '',
            networkDD: [{ networkId: -1, networkName: "Select" }],
            networkId: 0,
            networkIdIndex: -1,
            networkIdState: "",
            //#endregion
        };
    }
    async componentDidMount() {
        this.LoadNetworks();
        this.LoadSessionDD();
      
    }
    //handleDateChange = (date) => {
    //    this.setState({ selectedDate: date });
    //};
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
          
            
            case "joiningDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        joiningDate: event.target.value,
                        joiningDateState: "success"
                    })
                }
                else {
                    this.setState({
                        joiningDateState: "error",
                    })
                }
                break;
          
            // default:
            //     if ([stateName + "State"] === "success") {
            //         this.setState({ stateName: event.target.value })
            //     }
            //     break;
        }
    }
                //verifyDate(value) {
                //    debugger
                //    var DateRex = /\d\d\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/;
                //    if (DateRex.test(value)) {
                //        return true;
                //    }
                //    return false;
                //}
   
    async LoadSessionDD() {
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sessionDD: data, sessionId: data[0]['sessionId'] });
                this.state.sessionId = data[0]['sessionId'];
                this.LoadSectors();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSectors() {
        fetch(Base_URL + "/api/Dropdowns/BehriaTownSectors").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectorDD: data, sectorId: data[0]['sectorId'] });
                this.state.sectorId = data[0]['sectorId'];
                this.LoadSubSectors();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSubSectors() {
        /*    debugger;*/
        fetch(Base_URL + "/api/Dropdowns/LevelDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.sectorId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ levelDD: data, levelId: data[0]['levelId'] });
                this.setState.levelId = data[0]['levelId']
                this.LoadClasses();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadClasses() {
        /* debugger;*/
        fetch(Base_URL + "/api/Dropdowns/ClassDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.sectorId + "&LevelId=" + this.state.levelId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ classDD: data, classId: data[0]['classId'] });
                this.state.classId = data[0]['classId'];
                this.LoadSections();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSections() {
        /*   debugger;*/
        fetch(Base_URL + "/api/Dropdowns/SectionDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.sectorId + "&ClassId=" + this.state.classId + "&LevelId=" + this.state.levelId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectionDD: data, sectionId: data[0]['sectionId'] });
                this.state.sectionId = data[0]['sectionId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadNetworks() {
        fetch(Base_URL + "/api/EditStudentProfile/NetworkList").then(response => { return response.json(); })
            .then(data => {
                this.setState({ networkDD: data, networkId: data[0]['networkId'] });
            }).catch(error => {
                console.log(error);
            });
    }

  
    confirmAutoFill(status, message) {
        debugger
        if (status === "Duplicate") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status + "Employee"}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        cancelBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.danger
                        }
                        confirmBtnText="Do you Want to Autofill the Form?"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        Duplicate NIC found.
                    </SweetAlert>
                ),
                nicBlackListed: ""
            });
        }
        else if (status === "Blacklisted") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                ),
                nicState: "error",
                nicBlackListed: "Black listed Employee"
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    ShowHide = () => {
        this.setState({
            clearTextBtn: 'none',
        });
    }
   
    setDDValue = (event, value) => {
        /*        debugger;*/
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
        if (dropdownName === 'sessionDD') {
            this.state.sessionId = value['sessionId'];
            this.LoadSectors();
        }
        else if (dropdownName === 'sectorDD') {
            this.state.sectorId = value['sectorId'];
            this.LoadSubSectors();
        }
        else if (dropdownName == 'levelDD') {
            this.state.levelId = value['levelId'];
            this.LoadClasses();
        }
        else if (dropdownName == 'classDD') {
            this.state.classId = value['classId'];
            this.LoadSections();
        }
        else if (dropdownName == 'sectionDD') {
            this.state.sectionId = value['sectionId'];
        }
    }
  

    render() {
        const { classes } = this.props;
        const { networkDD, networkIdIndex, networkIdState, sectionIdState, sectionDD, sectionIdIndex, classIdState, sectorIdIndex, sectorDD,
            classDD, classIdIndex, sectorIdState, sessionDD, sessionIdIndex, sessionIdState, startDate, setStartDate, selectedDate, setSelectedDate
            } = this.state;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <div style={{ padding: "5px 15px" }}>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ padding: "5px 15px" }}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Typography color="textPrimary">Admission Challan</Typography>
                        </Breadcrumbs>

                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                </CardHeader>
                <GridContainer>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }} className={classes.mainDiv}>
                    <CardBody>
                            <GridContainer >
                        <GridForm xs={12} sm={6} md={3}>
                            {this.state.alert}
                            <Card>
                                <CardHeader color="info" icon>
                                    <h4 className={classes.cardIconTitle}>Requested Class</h4>
                                </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 20, paddingTop:20 }}>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                disabled
                                                disableClearable
                                                id="networkId"
                                                size="small"
                                                options={networkDD}
                                                getOptionLabel={(option) => option.networkName}
                                                value={networkDD[networkIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={networkIdState === "success"}
                                                        error={networkIdState === "error"} label="Requested Class" variant="outlined" />}
                                                   />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                disabled
                                                disableClearable
                                                id="networkId"
                                                size="small"
                                                options={networkDD}
                                                getOptionLabel={(option) => option.networkName}
                                                value={networkDD[networkIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={networkIdState === "success"}
                                                    error={networkIdState === "error"} label="Fee Package" variant="outlined" />}
                                            />
                                            </GridTextbox>
                                            </div>
                                </CardBody>
                            </Card>
                            </GridForm>
                            <GridForm xs={12} sm={6} md={3}>
                                {this.state.alert}
                                <Card>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Assigend Classes</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                disabled
                                                disableClearable
                                                id="sessionId"
                                                size="small"
                                                options={sessionDD}
                                                getOptionLabel={(option) => option.sessionName}
                                                value={sessionDD[sessionIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={sessionIdState === "success"}
                                                    error={sessionIdState === "error"} label="Session" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                disabled
                                                disableClearable
                                                id="sectorId"
                                                size="small"
                                                options={sectorDD}
                                                getOptionLabel={(option) => option.sectorName}
                                                value={sectorDD[sectorIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={sectorIdState === "success"}
                                                    error={sectorIdState === "error"} label="Sector" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                disabled
                                                disableClearable
                                                id="classId"
                                                size="small"
                                                options={classDD}
                                                getOptionLabel={(option) => option.className}
                                                value={classDD[classIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={classIdState === "success"}
                                                    error={classIdState === "error"} label="Class" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                disabled
                                                disableClearable
                                                id="sectionId"
                                                size="small"
                                                options={sectionDD}
                                                getOptionLabel={(option) => option.sectionName}
                                                value={sectionDD[sectionIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={sectionIdState === "success"}
                                                    error={sectionIdState === "error"} label="Section" variant="outlined" />}
                                            />
                                            </GridTextbox>
                                            </div>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={6} md={3}>
                                {this.state.alert}
                                <Card>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Challan Date</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.joiningDateState === "success"}
                                                error={this.state.joiningDateState === "error"}
                                                size="small"
                                                label="Start Date"
                                                id="joiningDate"
                                                variant="outlined"
                                                fullWidth
                                                value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                                type="date"
                                                inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.joiningDateState === "success"}
                                                error={this.state.joiningDateState === "error"}
                                                size="small"
                                                label="End Date"
                                                id="joiningDate"
                                                variant="outlined"
                                                fullWidth
                                                value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                                type="date"
                                                inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.joiningDateState === "success"}
                                                error={this.state.joiningDateState === "error"}
                                                size="small"
                                                label="Issue Date"
                                                id="joiningDate"
                                                variant="outlined"
                                                fullWidth
                                                value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                                type="date"
                                                inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.joiningDateState === "success"}
                                                error={this.state.joiningDateState === "error"}
                                                size="small"
                                                label="Due  Date"
                                                id="joiningDate"
                                                variant="outlined"
                                                fullWidth
                                                value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                                type="date"
                                                inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                            />
                                            </GridTextbox>
                                        <span>Select Date and Create Challan</span>
                                        <div style={{ textAlign:"center" }}>
                                            <Button color="facebook" style={{ marginBottom: 20 }}>Create Challan</Button>
                                            </div>
                                            </div>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={6} md={3}>
                                {this.state.alert}
                                <Card>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Apply Discount</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 20, paddingTop: 20 }}>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                disableClearable
                                                id="networkId"
                                                size="small"
                                                options={networkDD}
                                                getOptionLabel={(option) => option.networkName}
                                                value={networkDD[networkIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={networkIdState === "success"}
                                                    error={networkIdState === "error"} label="Discount Type" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                disableClearable
                                                id="networkId"
                                                size="small"
                                                options={networkDD}
                                                getOptionLabel={(option) => option.networkName}
                                                value={networkDD[networkIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={networkIdState === "success"}
                                                    error={networkIdState === "error"} label="Disc Ratio" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.joiningDateState === "success"}
                                                error={this.state.joiningDateState === "error"}
                                                size="small"
                                                label="Start Date"
                                                id="joiningDate"
                                                variant="outlined"
                                                fullWidth
                                                value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                                 type="date"
                                                inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                            />
                                                </GridTextbox>
                                                {/*<GridTextbox xs={12} sm={12} md={12}>*/}
                                                {/*    <KeyboardDatePicker*/}
                                                {/*        disableToolbar*/}
                                                {/*        variant="inline"*/}
                                                {/*        format="d/MMM/yyyy"*/}
                                                {/*        margin="normal"*/}
                                                {/*        id="date-picker-inline"*/}
                                                {/*        label="Date picker inline"*/}
                                                {/*        value={selectedDate}*/}
                                                {/*        onChange={this.handleDateChange()}*/}
                                                {/*        KeyboardButtonProps={{*/}
                                                {/*            'aria-label': 'change date',*/}
                                                {/*        }}*/}
                                                {/*    />*/}
                                                {/*</GridTextbox>*/}
                                    
                                        <GridItem xs={12} sm={12} md={12}>
                                            <PictureUpload />
                                            <div style={{ textAlign: "center" }}>
                                                <Button color="danger">Reset</Button>
                                                </div>
                                            <hr style={{ marginBottom: "25px" }} />
                                                </GridItem>
                                                <span>Select the discount options, and click on apply discount.</span>
                                                <div style={{ textAlign: "center" }}>
                                                    <Button color="facebook" style={{ marginBottom: 20 }}>Apply Discount</Button>
                                                </div>
                                            </div>
                                    </CardBody>
                                </Card>
                            </GridForm>
                        
                    </GridContainer>
                </CardBody>
                    </div>
                    </GridContainer>
            </Card>
            );
    }
}
AdmissionChallan.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(formStyle)(AdmissionChallan);