//Import from Globel Component";
import {
    React, axios, PropTypes, Tab, Link, HomeIcon, StdIcon, CardIcon, Tabs, Typography, Breadcrumbs, NavigateNextIcon, withStyles, GridContainer, GridForm, Card, CardHeader,
    CardBody, formStyle,
} from '.../../components/Common/ImportAll';

// Import EmployeesTab Views
import EditHrEmployee from "./EditEmployeeTabs/EditHrEmployee.jsx";
import EmployeeCredentials from "./EditEmployeeTabs/EmployeeCredentials.jsx";
import EmployeeInactivation from "./EditEmployeeTabs/EmployeeInactivation.jsx";
import EductionOrExperience from "./EditEmployeeTabs/EductionOrExperience.jsx";
import Salary from "./EditEmployeeTabs/Salary.jsx";
import ReferenceAndScore from "./EditEmployeeTabs/ReferenceAndScore.jsx";
import ChildInfo from "./EditEmployeeTabs/ChildInfo.jsx";
import EOBI from "./EditEmployeeTabs/EOBI.jsx";
import LeaveQuota from "./EditEmployeeTabs/LeaveQuota.jsx";
import Shifts from "./EditEmployeeTabs/Shifts.jsx";
import OtherInfo from "./EditEmployeeTabs/OtherInfo.jsx";
import HealthInsurance from "./EditEmployeeTabs/HealthInsurance.jsx";
import Placement from "./EditEmployeeTabs/Placement.jsx";
import EmployeeLetters from "./EditEmployeeTabs/EmployeeLetters.jsx";
import FuelAllowance from "./EditEmployeeTabs/FuelAllowance.jsx";
import MobileAllowance from "./EditEmployeeTabs/MobileAllowance.jsx";
import Scores from "./EditEmployeeTabs/Scores.jsx";
import history from '../../context/History';
//import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import axios from 'axios';
var Base_URL = localStorage.BaseURL;
function TabContainer(props) {
    return (
        <Typography component="div" style={{ width: "100%", alignContent: "center" }}>
            {props.children}
        </Typography>
    );
}
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});
class EditEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            editRegData: [],
            selectedTab: 0,
            Id: "",
            Name: " ",
            CampusName: "",
            HrLevelName: "",
            DepartmentName: "",
            DesignationName: "",
        }
        this.LoadRegData = this.LoadRegData.bind(this);
        this.LoadRegData();
    };
    LoadRegData() {
        debugger
        if ((this.props.location["empIdinfo"]) !== undefined) {
            axios.get(Base_URL + '/api/LoadEmployeeData/LoadEmployeeData?EmployeeId=' + this.props.location["empIdinfo"])
                .then(json => {
                    const data = JSON.parse(json.data.regData);
                    this.setState({
                        editRegData: JSON.parse(json.data.regData),
                        isLoading: false,
                    });
                    if (data.length > 0) {
                        debugger;
                        this.setState({
                            Id: data[0]["RegNo"],
                            Name: data[0]["Name"],
                            CampusName: data[0]["CampusName"],
                            HrLevelName: data[0]["HrLevelName"],
                            DepartmentName: data[0]["DepartmentName"],
                            DesignationName: data[0]["DesignationName"]
                        });

                    }
                })
        }
        else {
            history.push('/app/employeeapplication');
        }
    }
    handleChange = (event, value) => {
        this.setState({ selectedTab: value });
    };
    render() {
        const { selectedTab, editRegData } = this.state;
        const { classes } = this.props;
        const { ChildrenList } = this.state;
        return (
            <div>
                {(this.state.isLoading === true) ? <i className="fa fa-spinner fa-spin"></i> :
                    <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                        <CardHeader color="info" icon>
                            <CardIcon color="success">
                                <StdIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Update Employee Info</h4>
                            <hr style={{ marginBottom: "3px" }} />
                        </CardHeader>
                        <div style={{ padding: "5px 15px" }}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                                <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                                <Link to="/app/HRPayroll" >HR & Payroll</Link>
                                <Typography color="textPrimary">Edit Employee Info</Typography>
                            </Breadcrumbs>
                            <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                        </div>
                        <div style={{ padding: "5px 15px" }}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb" style={{ backgroundColor: "#d4e2f8", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px", margin: "0px" }}>
                                <Typography variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" wrapped>EMPLOYEE PLACEMENT DETAIL</Typography>
                            </Breadcrumbs>
                            <div style={{ paddingBottom: "25px", paddingRight: "15px", paddingLeft: "15px" }}>
                                <GridContainer xs={12} sm={12} md={12} >
                                    <GridForm xs={12} sm={2} md={2}>
                                        <GridContainer>
                                            <div style={{ width: "80%", marginLeft: "15px" }}>
                                                <h5 className={classes.cardIconTitle}>Employee Id</h5>
                                                <hr style={{ width: "90%" }} />
                                                <span>{this.state.Id}</span>
                                            </div>
                                            <div style={{
                                                borderLeft: " 1px solid 	#808080",
                                                height: "59px",
                                                marginTop: "11px"
                                            }}></div>
                                            <hr />
                                        </GridContainer>
                                    </GridForm>
                                    <GridForm xs={12} sm={2} md={2}>
                                        <GridContainer>
                                            <div style={{ width: "80%", marginLeft: "15px" }}>
                                                <h5 className={classes.cardIconTitle}>Name</h5>
                                                <hr style={{ width: "90%" }} />
                                                <span>{this.state.Name}</span>
                                            </div>
                                            <div style={{
                                                borderLeft: " 1px solid 	#808080",
                                                height: "59px",
                                                marginTop: "11px"
                                            }}></div>
                                        </GridContainer>
                                    </GridForm>
                                    <GridForm xs={12} sm={2} md={2}>
                                        <GridContainer>
                                            <div style={{ width: "80%", marginLeft: "15px" }}>
                                                <h5 className={classes.cardIconTitle}>Campus</h5>
                                                <hr style={{ width: "90%" }} />
                                                <span>{this.state.CampusName}</span>
                                            </div>
                                            <div style={{
                                                borderLeft: " 1px solid 	#808080",
                                                height: "59px",
                                                marginTop: "11px"
                                            }}></div>
                                        </GridContainer>
                                    </GridForm>
                                    <GridForm xs={12} sm={2} md={2}>
                                        <GridContainer>
                                            <div style={{ width: "80%", marginLeft: "15px" }}>
                                                <h5 className={classes.cardIconTitle}>Sub Campus</h5>
                                                <hr style={{ width: "90%" }} />
                                                <span>{this.state.HrLevelName}</span>
                                            </div>
                                            <div style={{
                                                borderLeft: " 1px solid 	#808080",
                                                height: "59px",
                                                marginTop: "11px"
                                            }}></div>
                                        </GridContainer>
                                    </GridForm>
                                    <GridForm xs={12} sm={2} md={2}>
                                        <GridContainer>
                                            <div style={{ width: "80%", marginLeft: "15px" }}>
                                                <h5 className={classes.cardIconTitle}>Department </h5>
                                                <hr style={{ width: "90%" }} />
                                                <span>{this.state.DepartmentName}</span>
                                            </div>
                                            <div style={{
                                                borderLeft: " 1px solid #808080",
                                                height: "59px",
                                                marginTop: "11px"
                                            }}></div>
                                        </GridContainer>
                                    </GridForm>
                                    <GridForm xs={12} sm={2} md={2}>
                                        <GridContainer>
                                            <div style={{ width: "80%", marginLeft: "15px" }}>
                                                <h5 className={classes.cardIconTitle}>Designation</h5>
                                                <hr style={{ width: "90%" }} />
                                                <span>{this.state.DesignationName}</span>
                                            </div>
                                        </GridContainer>
                                    </GridForm>
                                </GridContainer>
                            </div>
                            <hr style={{ marginTop: "-23px", marginBottom: "0px" }} />
                        </div>
                        <div style={{ paddingBottom: "25px", paddingRight: "15px", paddingLeft: "15px" }}>
                            <div style={{ padding: "5px 15px" }}>
                                <GridContainer>
                                    <Tabs
                                        value={selectedTab}
                                        onChange={this.handleChange}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        style={{ backgroundColor: "#d4e2f8", width: "100%" }}
                                        indicatorColor="primary"
                                        textColor="primary" wrapped >
                                        <Tab label=" Employee Info" />
                                        <Tab label=" Employee Credentials" />
                                        <Tab label=" Employee In Activation" />
                                        <Tab label="Eduction Or Experience" />
                                        <Tab label="Salary" />
                                        <Tab label="Reference" />
                                        <Tab label="Child Info" />
                                        <Tab label="EOBI & S.S" />
                                        <Tab label="Leave Quota" />
                                        <Tab label="Shifts" />
                                        <Tab label="Other Info" />
                                        <Tab label="Health Insurance" />
                                        <Tab label="Placement" />
                                        <Tab label="Employee Letters" />
                                        <Tab label="Fuel Allowance" />
                                        <Tab label="Mobile Allowance" />
                                        <Tab label="Scores" />
                                    </Tabs>
                                    {selectedTab === 0 && <TabContainer><EditHrEmployee editRegData={editRegData} /></TabContainer>}
                                    {selectedTab === 1 && <TabContainer><EmployeeCredentials editRegData={editRegData} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 2 && <TabContainer><EmployeeInactivation editRegData={editRegData} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 3 && <TabContainer><EductionOrExperience editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 4 && <TabContainer><Salary editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 5 && <TabContainer><ReferenceAndScore editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 6 && <TabContainer><ChildInfo editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 7 && <TabContainer><EOBI editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 8 && <TabContainer><LeaveQuota editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 9 && <TabContainer><Shifts editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 10 && <TabContainer><OtherInfo editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 11 && <TabContainer><HealthInsurance editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 12 && <TabContainer><Placement editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 13 && <TabContainer><EmployeeLetters editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 14 && <TabContainer><FuelAllowance editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 15 && <TabContainer><MobileAllowance editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                    {selectedTab === 16 && <TabContainer><Scores editRegData={{ EmployeeId: editRegData[0]["EmployeeId"] }} LoadRegData={this.LoadRegData} /></TabContainer>}
                                </GridContainer>
                            </div>
                        </div>
                    </Card>
                }
            </div>
        );
    }
}

EditEmployee.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(formStyle)(EditEmployee);