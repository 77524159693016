
//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint,
    CardIcon, AllInbox, Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, ReactToggle, SearchIcon, Group,
    AddIcon, EditOutlined, Sms, events, CardFooter, Equalizer, GraphicEq, Email, Attachment, CheckCircleOutlineOutlined,
    PermDataSettingOutlined, PersonAdd, DraftsOutlined, BugReportOutlined, TableChartOutlined, GroupOutlined,
    Computer, Person, ReportProblem, Delete, DoneAll, VpnKey, VerifiedUser, Note, LocationOnOutlined, PropTypes, dashboardStyle,
    Drafts, Sort, PermIdentity, Textsms
} from '.../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;

class ItManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: events
        };
    }
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <Computer />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>IT Management</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Typography color="textPrimary">IT Management</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                {/*<GridItem xs={12} sm={6} md={6} lg={3} >*/}
                                {/*    <Link from={"/"} to={"/app/usergroups"} style={{ textDecoration: 'none' }}>*/}
                                {/*        <Card>*/}
                                {/*            <CardHeader color="success" stats icon>*/}
                                {/*                <CardIcon color="success">*/}
                                {/*                    <Group />*/}
                                {/*                </CardIcon>*/}
                                {/*                <p className={classes.cardCategory}>User</p>*/}
                                {/*                <h3 className={classes.cardTitle}> Groups </h3>*/}
                                {/*            </CardHeader>*/}
                                {/*            <CardFooter stats>*/}
                                {/*                <div className={classes.stats}>*/}
                                {/*                    <Group />*/}
                                {/*                   User Groups*/}
                                {/*                </div>*/}
                                {/*            </CardFooter>*/}
                                {/*        </Card>*/}
                                {/*    </Link>*/}
                                {/*</GridItem>*/}
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/userrights"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Equalizer />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>User</p>
                                                <h3 className={classes.cardTitle}>Rights</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Equalizer />
                                                    User Rights
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/ERPFormLockRights"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <Equalizer />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>ERP</p>
                                                <h3 className={classes.cardTitle}>Form Lock Rights</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Equalizer />
                                                    ERP Form Lock Rights
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/attendanceips"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <GraphicEq />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Biometric</p>
                                                <h3 className={classes.cardTitle}>Management</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <GraphicEq />
                                                    Biometric Management
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/smsinquiry"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <Sms />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>SMS</p>
                                                <h3 className={classes.cardTitle}> Inquiry </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Sms />
                                                    SMS Inquiry
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/emailsettings"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <Email />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Email </p>
                                                <h3 className={classes.cardTitle}> Settings </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Email />
                                                    Email Settings
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/lmslimitations"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <AllInbox />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Staff Portal</p>
                                                <h3 className={classes.cardTitle}>Limitations</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <AllInbox />
                                                    Staff Portal Limitations
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/lmsipaddress"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Attachment />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Staff Portal</p>
                                                <h3 className={classes.cardTitle}>Address</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Attachment />
                                                    Staff Portal Address
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/automatedreports"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <CheckCircleOutlineOutlined />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>System Generated</p>
                                                <h3 className={classes.cardTitle}>Reports </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <CheckCircleOutlineOutlined />
                                                    System Generated Reports
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                {/*<GridItem xs={12} sm={6} md={6} lg={3} >*/}
                                {/*    <Link from={"/"} to={"/app/manageforms"} style={{ textDecoration: 'none' }}>*/}
                                {/*        <Card>*/}
                                {/*            <CardHeader color="success" stats icon>*/}
                                {/*                <CardIcon color="success">*/}
                                {/*                    <PermDataSettingOutlined />*/}
                                {/*                </CardIcon>*/}
                                {/*                <p className={classes.cardCategory}>Manage</p>*/}
                                {/*                <h3 className={classes.cardTitle}>Forms </h3>*/}
                                {/*            </CardHeader>*/}
                                {/*            <CardFooter stats>*/}
                                {/*                <div className={classes.stats}>*/}
                                {/*                    <PermDataSettingOutlined />*/}
                                {/*                     Manage Forms*/}
                                {/*                </div>*/}
                                {/*            </CardFooter>*/}
                                {/*        </Card>*/}
                                {/*    </Link>*/}
                                {/*</GridItem>*/}
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Systemgeneratedemails"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <DraftsOutlined />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>System Generated</p>
                                                <h3 className={classes.cardTitle}>Emails</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <DraftsOutlined />
                                                    System Generated Emails
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/utility"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <PersonAdd />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Systematic Processing</p>
                                                <h3 className={classes.cardTitle}>Utility</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <PersonAdd />
                                                   Systematic Processing Utility
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/exceptions"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <BugReportOutlined />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>ERP</p>
                                                <h3 className={classes.cardTitle}>Exceptions</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <BugReportOutlined />
                                                    ERP Exceptions
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                {/*<GridItem xs={12} sm={6} md={6} lg={3} >*/}
                                {/*    <Link from={"/"} to={"/app/exceptionsdatatable"} style={{ textDecoration: 'none' }}>*/}
                                {/*        <Card>*/}
                                {/*            <CardHeader color="danger" stats icon>*/}
                                {/*                <CardIcon color="danger">*/}
                                {/*                    <TableChartOutlined />*/}
                                {/*                </CardIcon>*/}
                                {/*                <p className={classes.cardCategory}>Exceptions</p>*/}
                                {/*                <h3 className={classes.cardTitle}>Data Table </h3>*/}
                                {/*            </CardHeader>*/}
                                {/*            <CardFooter stats>*/}
                                {/*                <div className={classes.stats}>*/}
                                {/*                    <TableChartOutlined />*/}
                                {/*                    Exceptions Data Table*/}
                                {/*                </div>*/}
                                {/*            </CardFooter>*/}
                                {/*        </Card>*/}
                                {/*    </Link>*/}
                                {/*</GridItem>*/}
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><GroupOutlined style={{ marginBottom: "-5px" }} /> IT Administration</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/primarydatait"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <Computer />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>IT </p>
                                                <h3 className={classes.cardTitle}>
                                                    Administration
                                                </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <BugReportOutlined />
                                                    IT Administration
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>
                                </GridItem>
                                {/*<GridItem xs={12} sm={6} md={6} lg={3} >*/}
                                {/*    <Link from={"/"} to={"/app/ACRManagement"} style={{ textDecoration: 'none' }}>*/}
                                {/*        <Card>*/}
                                {/*            <CardHeader color="primary" stats icon>*/}
                                {/*                <CardIcon color="primary">*/}
                                {/*                    <DescriptionOutlined />*/}
                                {/*                </CardIcon>*/}
                                {/*                <p className={classes.cardCategory}>ACR </p>*/}
                                {/*                <h3 className={classes.cardTitle}>*/}
                                {/*                    Management*/}
                                {/*                </h3>*/}
                                {/*            </CardHeader>*/}
                                {/*            <CardFooter stats>*/}
                                {/*                <div className={classes.stats}>*/}
                                {/*                    <DescriptionOutlined />*/}
                                {/*                    ACR Management*/}
                                {/*                </div>*/}
                                {/*            </CardFooter>*/}
                                {/*        </Card>*/}
                                {/*    </Link>*/}
                                {/*</GridItem>*/}
                                {/*<GridItem xs={12} sm={6} md={6} lg={3} >*/}
                                {/*    <Link from={"/"} to={"/app/LetterManagement"} style={{ textDecoration: 'none' }}>*/}
                                {/*        <Card>*/}
                                {/*            <CardHeader color="rose" stats icon>*/}
                                {/*                <CardIcon color="rose">*/}
                                {/*                    <DescriptionOutlined />*/}
                                {/*                </CardIcon>*/}
                                {/*                <p className={classes.cardCategory}>Letter</p>*/}
                                {/*                <h3 className={classes.cardTitle}>*/}
                                {/*                    Management*/}
                                {/*                </h3>*/}
                                {/*            </CardHeader>*/}
                                {/*            <CardFooter stats>*/}
                                {/*                <div className={classes.stats}>*/}
                                {/*                    <DescriptionOutlined />*/}
                                {/*                    Letter Management*/}
                                {/*                </div>*/}
                                {/*            </CardFooter>*/}
                                {/*        </Card>*/}
                                {/*    </Link>*/}
                                {/*</GridItem>*/}
                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><Computer style={{ marginBottom: "-5px" }} />   Reports </Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Allemployeerights"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <DoneAll style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>All Employee Rights</center> </h3>
                                            <p className={classes.cardCategory}><center>Details</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Useroperationlog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <ReportProblem style={{ color: 'seagreen', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>User Operation Log</center> </h3>
                                            <p className={classes.cardCategory}><center>Details</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Studentportallog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Person style={{ color: 'sienna', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Student Portal Log</center> </h3>
                                            <p className={classes.cardCategory}><center>Details</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Deletionlog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Delete style={{ color: 'indianred', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center> Deletion Log</center> </h3>
                                            <p className={classes.cardCategory}><center>Details</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Studentrelogin"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <VpnKey style={{ color: 'deeppink', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Student Relogin </center> </h3>
                                            <p className={classes.cardCategory}><center>Report</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Allusergrouprights"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <VerifiedUser style={{ color: 'blueviolet', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>All User Group Rights </center> </h3>
                                            <p className={classes.cardCategory}><center>Report</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Staffportallog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Note style={{ color: 'maroon', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Staff Portal Log </center> </h3>
                                            <p className={classes.cardCategory}><center>Report</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Dailyattendancereport"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <LocationOnOutlined style={{ color: '	Crimson', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Daily Attendance Report  </center> </h3>
                                            <p className={classes.cardCategory}><center>IP Wise</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Useroperationslog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Sort style={{ color: 'Navy', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>User Operations Log </center> </h3>
                                            <p className={classes.cardCategory}><center> Report Sorted</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/SMSlog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Sms style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>SMS Log </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/SMSsummary"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Sms style={{ color: 'Magenta', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>SMS Summary </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/SentSMS"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Sms style={{ color: 'Red', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center> Sent SMS </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Studentidcardlog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <PermIdentity style={{ color: 'OrangeRed', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Student ID Card Log </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Employeeidcardlog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Person style={{ color: 'DarkMagenta', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Employee ID Card Log </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/EmployeessentSMS"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Textsms style={{ color: 'MidnightBlue', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Employees Sent SMS </center> </h3>
                                            <p className={classes.cardCategory}><center> Report </center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/Emailsendinglog"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Drafts style={{ color: 'darkolivegreen', fontSize: '70' }} /></center>
                                            <h3 className={classes.cardTitle}><center>Email Sending Log </center> </h3>
                                            <p className={classes.cardCategory}><center>Report</center> </p>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
}

ItManagement.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(ItManagement);
