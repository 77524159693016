import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @material-ui/core components
import {
    Home as HomeIcon,
    Commute as TransportIcon, DirectionsBus as StdIcon, AccountBalanceWallet, Receipt, Whatshot, EmojiTransportation, NavigateNext as NavigateNextIcon,
    AirportShuttle, DriveEta, AirlineSeatReclineNormal, FormatListBulleted, ListAlt, ListAltOutlined, DirectionsBus, Details, Commute, EvStation,
    RateReview
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
// components
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

class Transport extends React.Component {
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <TransportIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Transport Management</h4>
                </CardHeader>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Typography color="textPrimary">Transport Management</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/studenttransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <StdIcon />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Student</p>
                                            <h3 className={classes.cardTitle}>
                                                Transport
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <StdIcon />
                                    Student Transport
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>

                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Stafftransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <TransportIcon />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Staff</p>
                                            <h3 className={classes.cardTitle}>
                                                Transport
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <TransportIcon />
                                  Staff Transport
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/studenttransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <AccountBalanceWallet />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>Transport
                                            
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <AccountBalanceWallet />
                                  Manage Transport
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/studenttransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <Receipt />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>Fuel Rates
                                            
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Receipt />
                                  Manage Fuel Rates
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/studenttransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <Whatshot />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Fuel</p>
                                            <h3 className={classes.cardTitle}>Consumption
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Whatshot />
                                  Fuel Consumption
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/studenttransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <EmojiTransportation />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Manage</p>
                                            <h3 className={classes.cardTitle}>Routes
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <EmojiTransportation />
                                  Manage Routes
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/newvehicle"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <AirportShuttle />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>New</p>
                                            <h3 className={classes.cardTitle}>Vehicle
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <AirportShuttle />
                                 New Vehicle
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/studenttransport"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <DriveEta />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Search</p>
                                            <h3 className={classes.cardTitle}>Vehicle
                                    </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <DriveEta />
                                 Search Vehicle
                                    </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"> <AccountBalanceWallet style={{ marginBottom: "-5px" }} /> Transport Primary Data</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/transportprimarydata"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <AccountBalanceWallet />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Primary Data</p>
                                            <h3 className={classes.cardTitle}>
                                               Transport 
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <AccountBalanceWallet />
                                    Transport Management 
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>

                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"> <AccountBalanceWallet style={{ marginBottom: "-5px" }} /> Student Transport Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Availableseatsinbus"} style={{ textDecoration: 'none' }}>
                                    <Card>

                                        <center> <AirlineSeatReclineNormal style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Available Seats in Bus</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Studentlistbusewisesumary"} style={{ textDecoration: 'none' }}>
                                    <Card>

                                        <center> <FormatListBulleted style={{ color: 'Dark Blue', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Student List Bus Wise</center></h3>
                                        <p className={classes.cardCategory}><center>Summary</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Studentlistbusewise"} style={{ textDecoration: 'none' }}>
                                    <Card>

                                        <center> <ListAlt style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Student List Bus Wise</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Zonelist"} style={{ textDecoration: 'none' }}>
                                    <Card>

                                        <center> <ListAltOutlined style={{ color: 'Brown', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Zones List</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Studenttransportroutewise"} style={{ textDecoration: 'none' }}>
                                    <Card>

                                        <center> <DirectionsBus style={{ color: 'Red', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Student Transport Route </center></h3>
                                        <p className={classes.cardCategory}><center>Wise</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"> <AccountBalanceWallet style={{ marginBottom: "-5px" }} /> Employee Transport Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeelistbuswise"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <FormatListBulleted style={{ color: 'Maroon', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee List Bus Wise</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"> <AccountBalanceWallet style={{ marginBottom: "-5px" }} /> Other Transport Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Allroutesdetails"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Details style={{ color: 'gold', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> All Routes Details</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Allvehicles"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Commute style={{ color: 'Green', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> All Vehicles</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Fuelconsumptiondetails"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <EvStation style={{ color: 'Light Red', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Fuel Consumption Details</center></h3>
                                        <p className={classes.cardCategory}><center>Details</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Fuelrates"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <RateReview style={{ color: 'Indigo', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Fuel Rates</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card >

        );
    }
}

Transport.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Transport);
