import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
// @material-ui/core components
import {
    Home as HomeIcon, NavigateNext as NavigateNextIcon,
    PersonAddOutlined, GroupOutlined, AssignmentIndOutlined, Description,
    PresentToAll, Extension, AddCircle, Event, Edit, CheckCircle, List, InsertDriveFileOutlined,
    CardTravel, Queue, RecentActors, DescriptionOutlined, Person, RemoveFromQueue, CastForEducation, PlaylistAddCheck,
    Photo, SettingsApplications, Cake, PersonAddDisabled, Drafts, PersonOutline, ImportContactsTwoTone, DetailsTwoTone,
    Rowing, DiscFull, BugReport, Fingerprint, TableChart, DepartureBoard, TimerOff, LockOpen, SupervisedUserCircle,
    CancelPresentation, DoneAll, TouchApp, Cancel, ThumbUp, TimerOffOutlined, Waves, Timeline, ChangeHistoryTwoTone,
    History, Work, CalendarTodayOutlined, AttachMoneyOutlined, AddCircleOutlineOutlined, RemoveCircleOutlineOutlined, FitnessCenter, Lock, Devices,
    Clear, ListAlt, ArrowForward, EventNoteTwoTone
}
    from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
// components
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { StyledBreadcrumb } from "assets/jss/customStyle/allCustomStyle";

class HR extends React.Component {
    state = {
        value: 0
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };
    render() {
        const { classes } = this.props;

        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <GroupOutlined />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>HR & Payroll Management</h4>
                </CardHeader>
                <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Typography color="textPrimary">HR & Payroll</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/newemployee"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <PersonAddOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>New</p>
                                            <h3 className={classes.cardTitle}>
                                                Employee
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <PersonAddOutlined />
                                                New Employee
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/employeeapplication"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <AssignmentIndOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Application </p>
                                            <h3 className={classes.cardTitle}>
                                                Mangement
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <AssignmentIndOutlined />
                                                 Application Management
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/employeemanagement"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="warning" stats icon>
                                            <CardIcon color="warning">
                                                <GroupOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Employee</p>
                                            <h3 className={classes.cardTitle}>
                                                Management
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <GroupOutlined />
                                                Employee Management
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/ImportAttendance"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Description />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Import</p>
                                            <h3 className={classes.cardTitle}>
                                                Attendance
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Description />
                                                Import Attendance
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/EmployeeAttendance"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <PresentToAll />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Employee</p>
                                            <h3 className={classes.cardTitle}>
                                                Attendance
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <PresentToAll />
                                                Employee Attendance
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/AllowanceDeduction"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <Extension />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Allowance</p>
                                            <h3 className={classes.cardTitle}>
                                                /Deduction
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Extension />
                                                Allowance/Deduction
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/AdvancePayment"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <AddCircle />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Adv</p>
                                            <h3 className={classes.cardTitle}>
                                                Payment
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <AddCircle />
                                                Adv Payment
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/ImportSalaryDaysHeads"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <Event />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Import Days</p>
                                            <h3 className={classes.cardTitle}>
                                                &Heads
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Event />
                                               Import Days& Heads
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/CreateSalary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <Edit />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Create </p>
                                            <h3 className={classes.cardTitle}>
                                                Salary
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Edit />
                                               Create Salary
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/PostSalary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <Edit />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Post </p>
                                            <h3 className={classes.cardTitle}>
                                                Salary
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Edit />
                                               Post Salary
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/SalaryPayment"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <CheckCircle />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Salary </p>
                                            <h3 className={classes.cardTitle}>
                                                Payment
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <CheckCircle />
                                               Salary Payment
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/EmployeeSupList"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="success" stats icon>
                                            <CardIcon color="success">
                                                <List />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Supervisor </p>
                                            <h3 className={classes.cardTitle}>
                                                List
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <List />
                                             Supervisor List
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/EmployeeACRForm"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <InsertDriveFileOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>ACR </p>
                                            <h3 className={classes.cardTitle}>
                                                Form
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <InsertDriveFileOutlined />
                                            ACR Form
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/CardBackground"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <CardTravel />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Card </p>
                                            <h3 className={classes.cardTitle}>
                                                Background
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <CardTravel />
                                            Card Background
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Addnewletter"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="danger" stats icon>
                                            <CardIcon color="danger">
                                                <Queue />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Add </p>
                                            <h3 className={classes.cardTitle}>
                                                New Letter
                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Queue />
                                            Add New Letter
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/SearchLetter"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="info" stats icon>
                                            <CardIcon color="info">
                                                <Queue />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Search</p>
                                            <h3 className={classes.cardTitle}>
                                                Letter
                                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <Queue />
                                             Seach Letter
                                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>


                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><GroupOutlined style={{ marginBottom: "-5px" }} /> HR & Payroll Administration</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/primarydatahr"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <RecentActors />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>HR </p>
                                            <h3 className={classes.cardTitle}>
                                                Management
                                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <RecentActors />
                                             HR Management
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/ACRManagement"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="primary" stats icon>
                                            <CardIcon color="primary">
                                                <DescriptionOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>ACR </p>
                                            <h3 className={classes.cardTitle}>
                                                Management
                                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <DescriptionOutlined />
                                             ACR Management
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/LetterManagement"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <CardHeader color="rose" stats icon>
                                            <CardIcon color="rose">
                                                <DescriptionOutlined />
                                            </CardIcon>
                                            <p className={classes.cardCategory}>Letter</p>
                                            <h3 className={classes.cardTitle}>
                                                Management
                                            </h3>
                                        </CardHeader>
                                        <CardFooter stats>
                                            <div className={classes.stats}>
                                                <DescriptionOutlined />
                                             Letter Management
                            </div>
                                        </CardFooter>
                                    </Card>
                                </Link>
                            </GridItem>


                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><GroupOutlined style={{ marginBottom: "-5px" }} /> Employee Profile Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Allemployee"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Person style={{ color: 'Orange', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> All Employee</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/departmentdesgination"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <RemoveFromQueue style={{ color: 'MidnightBlue', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Department & Designation </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeedcuationsumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <CastForEducation style={{ color: 'DarkRed', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Education List </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeebloodgrouplist"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <PlaylistAddCheck style={{ color: 'Deep Pink', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Blood Group List </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeephotos"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Photo style={{ color: 'Crimson', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Photos </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeapplicationlist"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <SettingsApplications style={{ color: 'Peru', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Application List</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeebirthday"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Cake style={{ color: 'DodgerBlue', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Birthday</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeejoiningleaving"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <PersonAddDisabled style={{ color: 'DarkGoldenrod', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Joining & Leaving </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Contractletter"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Drafts style={{ color: 'MediumVioletRed', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Contract Letter </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeletter"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <PersonOutline style={{ color: 'darkgreen', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Letter </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeebasicdetail"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Description style={{ color: 'darkviolet', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Basic Detail </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeedcuationsumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <ImportContactsTwoTone style={{ color: 'mediumvioletred', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Education Summary </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeacrsumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <DetailsTwoTone style={{ color: 'lightgoldenrod4', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee ACR Summary</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeestatus"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Rowing style={{ color: 'chocolate', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Status</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeechildfeediscount"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <DiscFull style={{ color: 'firebrick', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Child Fee Discount</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeissuecustom"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <BugReport style={{ color: 'Teal', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Issue Custom</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><GroupOutlined style={{ marginBottom: "-5px" }} /> Employee Attendance Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/dailyattendance"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <PresentToAll style={{ color: 'Red', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Daily Attendance</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/attendancedetail"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Fingerprint style={{ color: 'Indigo	', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee  Attendance Detail </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>

                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/attendancesumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <TableChart style={{ color: 'Orange', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee  Attendance Summary </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Earlydeparturesumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <DepartureBoard style={{ color: 'Maroon', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Early Departure Summary </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/primarydatahr"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <TimerOff style={{ color: 'Salmon', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Late Arrrival </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/primarydatahr"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Description style={{ color: 'lightseagreen', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Leave </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Notlogout"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <LockOpen style={{ color: 'tomato1', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Not Logout </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeeabsence"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <SupervisedUserCircle style={{ color: 'DarkMagenta', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Absence</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Notenrolled"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <CancelPresentation style={{ color: 'fuchsia', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Not Enrolled </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Leaveapplied"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <DoneAll style={{ color: 'FireBrick', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Leave Applied</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Attendancedetailsumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <TouchApp style={{ color: 'maroon', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Attendance Detail </center></h3>
                                        <p className={classes.cardCategory}><center> Summary Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Notcheckedout"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Cancel style={{ color: 'darkgreen', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Not Checked Out </center></h3>
                                        <p className={classes.cardCategory}><center>  Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Attendancesumarynew"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <ThumbUp style={{ color: 'deeppink1', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Attendance Summary </center></h3>
                                        <p className={classes.cardCategory}><center> Report  New</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Stafflatearrival"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <TimerOffOutlined style={{ color: 'Navy', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Staff Late Arrival</center></h3>
                                        <p className={classes.cardCategory}><center> Trend </center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Supervisorwaivedoff"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Waves style={{ color: 'gold4', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Supervisor Waived off </center></h3>
                                        <p className={classes.cardCategory}><center> Report </center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Employeerubricdeduction"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Extension style={{ color: 'lightseagreen', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Employee Rubric Deduction </center></h3>
                                        <p className={classes.cardCategory}><center> Report </center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Typography color="textPrimary"><GroupOutlined style={{ marginBottom: "-5px" }} /> Employee Other Reports</Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Last5increment"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Timeline style={{ color: 'darkorange', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Last 5 Increment</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/primarydatahr"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <History style={{ color: 'mediumaquamarine	', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee </center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Placementhistory"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <ChangeHistoryTwoTone style={{ color: 'purple	', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Experience History</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Jobperiod"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Work style={{ color: 'Green', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Job Period</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Leavedetailsumary"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <CalendarTodayOutlined style={{ color: 'Magenta', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Leave Detail </center></h3>
                                        <p className={classes.cardCategory}><center> Summary Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Bounusdetails"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <AttachMoneyOutlined style={{ color: 'steelblue', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Bounus Details</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Benefit"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <AddCircleOutlineOutlined style={{ color: 'Olive', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Benefit</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/EmployeeLoan"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <RemoveCircleOutlineOutlined style={{ color: '	Crimson	', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Loan</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Healthinsurance"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <FitnessCenter style={{ color: 'Coral', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center> Employee Health Insurance</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Staffportalloginlog"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Lock style={{ color: 'redViolet', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Staff Portal Login Log</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Fuelmobileallowance"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Devices style={{ color: 'Brown', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Fuel/Mobile Allowance</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Penalitydeduction"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Clear style={{ color: 'Navy', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Employee Penality Deduction</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Leavededuction"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <Extension style={{ color: 'Slategray', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Employee Leave Deduction</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Salaryauditsheet"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <ListAlt style={{ color: 'Chocolate', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Salary Audit Sheet</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>     <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Supervisorleaveapplication"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <ArrowForward style={{ color: 'purple', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Supervisor Leave Application</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={3} >
                                <Link from={"/"} to={"/app/Leavedetail"} style={{ textDecoration: 'none' }}>
                                    <Card>
                                        <center> <EventNoteTwoTone style={{ color: 'Blue', fontSize: '70' }} /></center>
                                        <h3 className={classes.cardTitle}> <center>Employee Leave Detail</center></h3>
                                        <p className={classes.cardCategory}><center>Report</center></p>
                                    </Card>
                                </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        );


    }
}

HR.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(HR);
