//Import from Globel Component";
import { card } from '../../../assets/jss/material-dashboard-pro-react';
import {
    React, SweetAlert, ReactToggle, axios, Typography, withStyles, TextField, NavigateNextIcon,
    Breadcrumbs, Autocomplete, GridContainer, GridItem, GridForm, GridTextbox, Card, CardHeader, CardBody, PictureUpload, Button,
    formStyle, Moment, DeleteIcon, GetAppIcon, PropTypes, SaveOutlined, InputMask, validateCheckSetVal,
} from '../../../components/Common/ImportAll';
const dateVal = {
    currentDate: new Date().getDate(),
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
}
var Base_URL = localStorage.BaseURL;
class MainStdProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Student Info
            generalError: false,
            saveUpdateBtnDisabled: false,
            studentId: 0,
            alert: null,
            dob: new Date(),
            dobState: "",
            age: this.ageCalculate(new Date()),
            mobileNo: "",
            mobileNoState: "",
            otherMobileNo: "",
            otherMobileNoState: "",
            landLine: "",
            landLineState: "",
            emailPersonal: "",
            emailPersonalState: "",
            joiningDate: new Date(),
            joiningDateState: "",
            userName: "",
            userNameState: "",
            password: "",
            passwordState: "",
            otherthnBehria: false,
            showpassword: true,
            currentAddress: "",
            currentAddressState: "",
            permanentAddress: "",
            permanentAddressState: "",
            applicationStatus: "",
            applicationStatusState: '',



            registrationNo: "",
            registrationNoState: "",
            oldregistrationNo: "",
            oldregistrationNoState: "",
            registrationDate: '',
            registrationDateState: '',
            moreThanOneWife: false,
            //gender: true,
            nTN: '',
            nTNState: '',
            name: '',
            nameState: '',
            house: "",
            houseState: "",
            street: "",
            streetState: "",
            active: true,
            admissionDate: new Date(),
            admissionDateState: '',
            status: true,
            studentUserNameState: '',
            studentUserName: '',
            isOtherWife: false,
            guardianAddressState: '',
            guardianAddress: '',
            overSeas: false,
            isSingleparent: false,
            selectedOption: '',
            familyIncome: 0,
            reason: "",
            showGuardianData: false,
            //#endregion

            //#region Father Info
            fatherMobileNo: '123',
            fatherMobileNoState: '',
            fatherOtherMobileState: '',
            fatherNameState: '',
            fatherName: '',
            fatherResidenceState: '',
            fatherResidence: '',
            fatherEmail: '',
            fatherEmailState: '',
            fatherNICState: '',
            fatherNIC: '',
            fathermobileNoSMSState: '',
            fathermobileNoSMS: '',
            //#endregion

            //#region Mother Info
            motherNicState: '',
            motherNic: '',
            motherMobileNoState: '',
            motherMobileNo: '',
            motherNameState: '',
            motherName: '',
            motherResidence: '',
            motherResidenceState: '',
            motherEmail: '',
            motherMobileOtherState: '',
            motherMobileOther: '',
            mothermobileNoSMSState: '',
            mothermobileNoSMS: '',
            //#endregion

            //#region Guardian Info
            guardianInformation: false,
            guardianRelationShip: '',
            guardianRelationShipState: '',
            guardianPhoneNoState: '',
            guardianPhoneNo: '',
            guardianName: '',
            guardianNameState: '',
            guardianEmail: '',
            guardianEmailState: "",
            guardianAddress: '',
            guardianAddressState: '',
            guardianNIC: '',
            guardianNICState: '',
            guardianDisabled: true,
            guardianNIC: '',
            guardianNICState: '',
            //#endregion

            //#region Dropdowns
            //GuardianRelation
            relationDD: [{ relationId: -1, relationName: 'Select' }],
            relationIdIndex: 0,
            relationId: 0,
            relationIdState: '',
            //payment
            paymentModeDD: [{ paymentModeId: -1, paymentModeName: 'Select' }],
            paymentModeIdIndex: 0,
            paymentModeId: 0,
            paymentModeIdState: '',
            //Transport
            transportModeDD: [{ transportModeId: -1, name: 'Select' }],
            transportModeIdIndex: 0,
            transportModeId: 0,
            transportModeIdState: '',
            //Nationality
            countryDD: [{ countryId: -1, countryName: 'Select' }],
            countryIdIndex: 0,
            countryId: 0,
            countryIdState: '',
            //religion
            religionDD: [{ religionId: -1, religionName: 'Select' }],
            religionId: 0,
            religionIdIndex: 0,
            religionIdState: "",
            // Cities
            cityDD: [{ cityId: -1, cityName: "Select" }],
            cityId: 0,
            cityIdIndex: 0,
            cityIdState: "",
            //town
            townDD: [{ townId: -1, townName: "Select" }],
            townId: 0,
            townIdIndex: 0,
            townIdState: "",
            //sectors
            sectorDD: [{ sectorId: -1, sectorName: "Select" }],
            sectorId: 0,
            sectorIdIndex: 0,
            sectorIdState: "",
            // Block
            blockDD: [{ blockId: -1, blockName: "Select" }],
            blockId: 0,
            blockIdIndex: 0,
            blockIdState: "",
            // UserGroups
            userGroupDD: [{ userGroupId: -1, userGroupName: "Select" }],
            userGroupId: 0,
            userGroupIdIndex: -1,
            userGroupIdState: "",
            //Parent Profession
            professionFatherDD: [{ professionFatherID: -1, profession: "Select" }],
            professionFatherID: 0,
            professionFatherIDIndex: 0,
            professionFatherIDState: "",
            professionMotherDD: [{ professionMotherID: -1, profession: "Select" }],
            professionMotherID: 0,
            professionMotherIDIndex: 0,
            professionMotherIDState: "",
            professionGuardianDD: [{ professionGuardianID: -1, profession: "Select" }],
            professionGuardianID: 0,
            professionGuardianIDIndex: 0,
            professionGuardianIDState: "",
            // Network
            networkFatherDD: [{ networkFatherId: -1, networkName: "Select" }],
            networkFatherId: 0,
            networkFatherIdIndex: 0,
            networkFatherIdState: "",
            networkMotherDD: [{ networkMotherId: -1, networkName: "Select" }],
            networkMotherId: 0,
            networkMotherIdIndex: 0,
            networkMotherIdState: "",
            // gender
            genderDD: [{ genderId: -1, name: "Select" }],
            genderId: 0,
            genderIdIndex: 0,
            genderIdState: "",
            //#endregion

        };
        this.onValueChange = this.onValueChange.bind(this);
        this.loadStudentBasicData = this.loadStudentBasicData.bind(this);
        //this.loadGenderDD = this.loadGenderDD.bind(this);
    };
    componentDidMount() {
        this.LoadGuardianProfession();
        this.LoadGuardianRelation();
        this.loadStudentBasicData();
        //this.loadGenderDD();
        //this.LoadPaymentMode();
        //this.LoadTransportMode();
        //this.LoadCountries();
        //this.LoadReligion();
        this.loadMainDD();
        this.loadCityDD();
        this.LoadTowns();
        this.LoadSectors();
        this.loadBlockDD();
        this.LoadUserGroups();
        this.LoadFatherProfession();
        this.LoadMotherProfession();
        this.LoadFatherNetworks();
        this.LoadMotherNetworks();

    }
    loadStudentBasicData() {
        debugger;
        fetch(Base_URL + '/api/StudentRegistration/LoadStudentData?RegistrationNo=' + this.props.data.RegistrationNo)
            .then(response => { return response.json() })
            .then(data => {
                debugger;
                if ((data.exception.exceptionToken !== '' || data.exception.exceptionToken !== null) && (data.exception.displayMessage !== ''
                    || data.exception.displayMessage !== '') && data.studentProfileData !== null) {
                    this.setState({
                        studentId: data.studentProfileData.studentId,
                        registrationNo: data.studentProfileData.registrationNo,
                        oldregistrationNo: data.studentProfileData.oldRegistrationNo,
                        registrationDate: data.studentProfileData.registrationDate,
                        genderId: data.studentProfileData.gender,
                        nTN: data.studentProfileData.ntn,
                        name: data.studentProfileData.name,
                        dob: data.studentProfileData.dob,
                        currentAddress: data.studentProfileData.address,
                        admissionDate: data.studentProfileData.admissionDate,
                        joiningDate: data.studentProfileData.schoolJoiningDate,
                        fatherName: data.studentProfileData.fatherName,
                        fatherResidence: data.studentProfileData.fatherResidence,
                        fatherEmail: data.studentProfileData.fatherEmail,
                        fatherNIC: data.studentProfileData.fatherNIC,
                        familyIncome: data.studentProfileData.familyIncome,
                        motherName: data.studentProfileData.motherName,
                        motherResidence: data.studentProfileData.motherResidence,
                        motherEmail: data.studentProfileData.motherEmail,
                        motherNic: data.studentProfileData.motherNIC,
                        fatherMobileNo: data.studentProfileData.fatherMobileNo,
                        motherMobileNo: data.studentProfileData.motherMobileNo,
                        networkFatherId: data.studentProfileData.fatherMobileNetworkid,
                        networkMotherId: data.studentProfileData.motherMobileNetworkid,
                        professionFatherID: data.studentProfileData.fatherProfessionId,
                        professionMotherID: data.studentProfileData.motherProfessionId,
                        applicationStatus: data.studentProfileData.statusName,
                        guardianName: data.studentProfileData.guardianName,
                        guardianNIC: data.studentProfileData.guardianNIC,
                        guardianPhoneNo: data.studentProfileData.guardianMobileNo,
                        guardianEmail: data.studentProfileData.guardianEmail,
                        guardianAddress: data.studentProfileData.guardianAddress,
                        guardianRelationShip: data.studentProfileData.gaurdianRelation,
                        professionGuardianID: data.studentProfileData.guardianProfessionId,
                        relationId: data.studentProfileData.guardianType,
                        otherthnBehria: data.studentProfileData.isOtherThanBahria,
                        status: data.studentProfileData.active,
                        isOtherWife: data.studentProfileData.isOtherWife,
                        isSingleparent: data.studentProfileData.isSingleParent,
                        paymentModeId: data.studentProfileData.paymentModeId,
                        transportModeId: data.studentProfileData.transportModeId,
                        religionId: data.studentProfileData.religionId,
                        countryId: data.studentProfileData.nationalityId,
                        house: data.studentProfileData.house,
                        street: data.studentProfileData.street,
                        cityId: data.studentProfileData.cityId,
                        townId: data.studentProfileData.townId,
                        sectorId: data.studentProfileData.sectorId,
                        blockId: data.studentProfileData.blockId,
                        mothermobileNoSMS: data.studentProfileData.motherPhoneSMS,
                        fathermobileNoSMS: data.studentProfileData.fatherPhoneSMS,

                    });
                    if (this.state.relationId !== null || this.state.relationId < 0) {
                        this.setState({
                            guardianInformation: true,
                            showGuardianData: true
                        })
                    }
                    if (this.state.relationId == 1) {
                        this.onValueChange(null, "Father");
                    }
                    else if (this.state.relationId == 2) {
                        this.onValueChange(null, "Mother");
                    }
                    else {
                        this.onValueChange(null, "Other");
                    }
                }
                else {
                    this.AlertMessage("Error", data.exception.displayMessage)
                }
            }).catch(error => {
                console.log(error);
            });

    }
    //#region Update Student
    updateMainStudentForm = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        var studentDOB = Moment(this.state.dob).format('YYYY-MM-DD');
        var admissionDate = Moment(this.state.admissionDate).format('YYYY-MM-DD');
        var schoolJoiningDate = Moment(this.state.joiningDate).format('YYYY-MM-DD');
        if (typeof this.state.oldregistrationNo === "undefined") {
            this.state.oldregistrationNo = null
        }
        let ProfileData = {
            StudentId: this.state.studentId,
            RegistrationNo: this.state.registrationNo,
            OldRegistrationNo: this.state.oldregistrationNo,
            Gender: this.state.genderId,
            NTN: this.state.nTN,
            DOB: studentDOB,
            House: this.state.house,
            Street: this.state.street,
            CityId: this.state.cityId,
            IsOtherThanBahria: this.state.otherthnBehria,
            TownId: this.state.townId,
            SectorId: this.state.sectorId,
            BlockId: this.state.blockId,
            Address: this.state.currentAddress,
            FamilyIncome: Number(this.state.familyIncome),
            TransportModeId: this.state.transportModeId,
            ReligionId: this.state.religionId,
            NationalityId: this.state.countryId,
            PaymentModeId: this.state.paymentModeId,
            AdmissionDate: admissionDate,
            SchoolJoiningDate: schoolJoiningDate,
            FatherNIC: this.state.fatherNIC,
            FatherMobileNo: this.state.fatherMobileNo,
            FatherName: this.state.fatherName,
            FatherResidence: this.state.fatherResidence,
            FatherEmail: this.state.fatherEmail,
            IsOtherWife: this.state.moreThanOneWife,
            MotherNIC: this.state.motherNic,
            MotherMobileNo: this.state.motherMobileNo,
            MotherName: this.state.motherName,
            MotherResidence: this.state.motherResidence,
            MotherEmail: this.state.motherEmail,
            MotherMobileNetworkid: this.state.networkMotherId,
            FatherMobileNetworkid: this.state.networkFatherId,
            MotherProfessionId: this.state.professionMotherID,
            FatherProfessionId: this.state.professionFatherID,
            GuardianName: this.state.guardianName,
            GuardianNIC: this.state.guardianNIC,
            GuardianMobileNo: this.state.guardianPhoneNo,
            GuardianEmail: this.state.guardianEmail,
            GuardianAddress: this.state.guardianAddress,
            GuardianReason: this.state.reason,
            //GaurdianRelation: this.state.guardianRelationShip,
            GuardianProfessionId: this.state.professionGuardianID,
            GuardianType: this.state.relationId,
            FatherPhoneSMS: this.state.fathermobileNoSMS,
            MotherPhoneSMS: this.state.mothermobileNoSMS,
            IsSingleParent: this.state.isSingleparent,
        }
        //debugger
        axios.post(Base_URL + "/api/StudentRegistration/UpdateStudentMainForm", ProfileData)
            .then(json => {
                debugger;
                if (json.data.exception.displayMessage === null && json.data.exception.exceptionToken === null) {
                    this.AlertMessage(json.data.vmResponse.status, json.data.vmResponse.message);
                }
                else {
                    this.AlertMessage("Error", json.data.exception.displayMessage);
                }
            })
    }
    //#endregion
    //#region dropdown
    //LoadPaymentMode() {
    //    fetch(Base_URL + "/api/Dropdowns/PaymentModesDD").then(response => { return response.json(); })
    //        .then(data => {
    //            if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
    //                if (this.state.paymentModeId !== 0 && this.state.paymentModeId !== null && this.state.paymentModeId !== -1) {
    //                    this.setState({ paymentModeDD: data.paymentModeDDList });
    //                    var PaymentIndex = this.state.paymentModeDD.findIndex(o => o.paymentModeId === this.state.paymentModeId);
    //                    this.setState({
    //                        paymentModeIdIndex: PaymentIndex,
    //                        paymentModeIdState: 'success'
    //                    })
    //                }
    //                else {
    //                    this.setState({ paymentModeDD: data.paymentModeDDList, paymentModeId: data.paymentModeDDList[0]['paymentModeId'] });
    //                }
    //            }
    //            else {
    //                var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
    //                this.AlertMessage("Error", msg);
    //            }

    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    // LoadTransportMode() {
    //    fetch(Base_URL + "/api/Dropdowns/TransportModeDD").then(response => { return response.json(); })
    //        .then(data => {
    //            if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
    //                if (this.state.transportModeId !== 0 && this.state.transportModeId !== null && this.state.transportModeId !== -1) {
    //                    this.setState({ transportModeDD: data.transportModeDDList });
    //                    var TransportIndex = this.state.transportModeDD.findIndex(o => o.transportModeId === this.state.transportModeId);
    //                    this.setState({
    //                        transportModeIdIndex: TransportIndex,
    //                        transportModeIdState: 'success'
    //                    })
    //                }
    //                else {
    //                    this.setState({ transportModeDD: data.transportModeDDList, transportModeId: data.transportModeDDList[0]['transportModeId'] });
    //                }

    //            }
    //            else {
    //                var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
    //                this.AlertMessage("Error", msg);
    //            }

    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    // LoadCountries() {

    //    fetch(Base_URL + "/api/Dropdowns/Countries").then(response => { return response.json(); })
    //        .then(data => {

    //            if (data.eception.displayMessage === null && data.eception.exceptionToken === null) {
    //                if (this.state.countryId !== 0 && this.state.countryId !== null && this.state.countryId !== -1) {
    //                    this.setState({ countryDD: data.countryDDList});
    //                    var CountryIndex = this.state.countryDD.findIndex(o => o.countryId === this.state.countryId);
    //                    this.setState({
    //                        countryIdIndex: CountryIndex,
    //                        countryIdState: 'success'
    //                    })
    //                }
    //                else {
    //                    this.setState({ countryDD: data.countryDDList, countryId: data.countryDDList[0]['countryId'] });
    //                }
    //                this.state.countryId = data.countryDDList[0]['countryId'];
    //                this.loadCityDD();
    //            }
    //            else {
    //                var msg = data.exception.exceptionToken + "<br />" + data.exception.displayMessage;
    //                this.AlertMessage("Error", msg);
    //            }

    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    // LoadReligion() {
    //    fetch(Base_URL + "/api/Dropdowns/ReligionsDD").then(response => { return response.json(); })
    //        .then(data => {
    //            if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
    //                if (this.state.religionId !== 0 && this.state.religionId !== null && this.state.religionId !== -1) {
    //                    this.setState({ religionDD: data.religionsDDList });
    //                    var ReligionIndex = this.state.religionDD.findIndex(o => o.religionId === this.state.religionId);
    //                    this.setState({
    //                        religionIdIndex: ReligionIndex,
    //                        religionIdState: 'success'
    //                    })
    //                }
    //                else {
    //                    this.setState({ religionDD: data.religionsDDList, religionId: data.religionsDDList[0]['religionId'] });
    //                }
    //            }
    //            else {
    //                var msg = data.exception.exceptionToken + "<br />" + data.exception.displayMessage;
    //                this.AlertMessage("Error", msg);
    //            }
    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    // loadGenderDD() {
    //    fetch(Base_URL + "/api/Dropdowns/GenderDD").then(response => { return response.json(); })
    //        .then(data => {
    //            if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
    //                if (this.state.genderId !== 0 && this.state.genderId !== null && this.state.genderId !== -1) {
    //                    this.setState({ genderDD: data.genderDDList });
    //                    var GenderIndex = this.state.genderDD.findIndex(o => o.genderId === this.state.genderId);
    //                    this.setState({
    //                        genderIdIndex: GenderIndex,
    //                        genderIdState: 'success'
    //                    })
    //                }
    //                else {
    //                    this.setState({ genderDD: data.genderDDList, genderId: data.genderDDList[0]['genderId'] });
    //                }

    //            }
    //            else {
    //                var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
    //                this.AlertMessage("Error", msg);
    //            }

    //        }).catch(error => {
    //            console.log(error);
    //        });
    //}
    loadMainDD() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/StudentMainProfileDD").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                        if (this.state.paymentModeId !== 0 && this.state.paymentModeId !== null && this.state.paymentModeId !== -1) {
                            this.setState({ paymentModeDD: data.paymentModeDDList });
                            var PaymentIndex = this.state.paymentModeDD.findIndex(o => o.paymentModeId === this.state.paymentModeId);
                            this.setState({
                                paymentModeIdIndex: PaymentIndex,
                                paymentModeIdState: 'success'
                            })
                        }
                        else {
                            this.setState({ paymentModeDD: data.paymentModeDDList, paymentModeId: data.paymentModeDDList[0]['paymentModeId'] });
                        }
                        if (this.state.transportModeId !== 0 && this.state.transportModeId !== null && this.state.transportModeId !== -1) {
                            this.setState({ transportModeDD: data.transportModeDDList });
                            var TransportIndex = this.state.transportModeDD.findIndex(o => o.transportModeId === this.state.transportModeId);
                            this.setState({
                                transportModeIdIndex: TransportIndex,
                                transportModeIdState: 'success'
                            })
                        }
                        else {
                            this.setState({ transportModeDD: data.transportModeDDList, transportModeId: data.transportModeDDList[0]['transportModeId'] });
                        }
                        if (this.state.countryId !== 0 && this.state.countryId !== null && this.state.countryId !== -1) {
                            this.setState({ countryDD: data.countryDDList });
                            var CountryIndex = this.state.countryDD.findIndex(o => o.countryId === this.state.countryId);
                            this.setState({
                                countryIdIndex: CountryIndex,
                                countryIdState: 'success'
                            })
                        }
                        else {
                            this.setState({ countryDD: data.countryDDList, countryId: data.countryDDList[0]['countryId'] });
                        }
                        this.state.countryId = data.countryDDList[0]['countryId'];
                        this.loadCityDD();
                        if (this.state.religionId !== 0 && this.state.religionId !== null && this.state.religionId !== -1) {
                            this.setState({ religionDD: data.religionsDDList });
                            var ReligionIndex = this.state.religionDD.findIndex(o => o.religionId === this.state.religionId);
                            this.setState({
                                religionIdIndex: ReligionIndex,
                                religionIdState: 'success'
                            })
                        }
                        else {
                            this.setState({ religionDD: data.religionsDDList, religionId: data.religionsDDList[0]['religionId'] });
                        }
                        if (this.state.genderId !== 0 && this.state.genderId !== null && this.state.genderId !== -1) {
                            this.setState({ genderDD: data.genderDDList });
                            var GenderIndex = this.state.genderDD.findIndex(o => o.genderId === this.state.genderId);
                            this.setState({
                                genderIdIndex: GenderIndex,
                                genderIdState: 'success'
                            })
                        }
                        else {
                            this.setState({ genderDD: data.genderDDList, genderId: data.genderDDList[0]['genderId'] });
                        }
                    }
                    //if ((this.state.genderId !== 0 && this.state.genderId !== null && this.state.genderId !== -1)||
                    //    this.state.paymentModeId !== 0 && this.state.paymentModeId !== null && this.state.paymentModeId !== -1&&
                    //    this.state.transportModeId !== 0 && this.state.transportModeId !== null && this.state.transportModeId !== -1&&
                    //    this.state.countryId !== 0 && this.state.countryId !== null && this.state.countryId !== -1&&
                    //    this.state.religionId !== 0 && this.state.religionId !== null && this.state.religionId !== -1) {
                    //    this.setState({
                    //        genderDD: data.genderDDList,
                    //        paymentModeDD: data.paymentModeDDList,
                    //        transportModeDD: data.transportModeDDList,
                    //        countryDD: data.countryDDList,
                    //        religionDD: data.religionsDDList
                    //    });
                    //    var GenderIndex = this.state.genderDD.findIndex(o => o.genderId === this.state.genderId);
                    //    var PaymentIndex = this.state.paymentModeDD.findIndex(o => o.paymentModeId === this.state.paymentModeId);
                    //    var TransportIndex = this.state.transportModeDD.findIndex(o => o.transportModeId === this.state.transportModeId);
                    //    var CountryIndex = this.state.countryDD.findIndex(o => o.countryId === this.state.countryId);
                    //    var ReligionIndex = this.state.religionDD.findIndex(o => o.religionId === this.state.religionId);
                    //    this.setState({
                    //        genderIdIndex: GenderIndex,
                    //        genderIdState: 'success',
                    //        paymentModeIdIndex: PaymentIndex,
                    //        paymentModeIdState: 'success',
                    //        transportModeIdIndex: TransportIndex,
                    //        transportModeIdState: 'success',
                    //        countryIdIndex: CountryIndex,
                    //        countryIdState: 'success',
                    //        religionIdIndex: ReligionIndex,
                    //        religionIdState: 'success'
                    //    })
                    //    }
                    //    else {
                    //        this.setState({
                    //            genderDD: data.genderDDList, genderId: data.genderDDList[0]['genderId'],
                    //            paymentModeDD: data.paymentModeDDList, paymentModeId: data.paymentModeDDList[0]['paymentModeId'],
                    //            transportModeDD: data.transportModeDDList, transportModeId: data.transportModeDDList[0]['transportModeId'],
                    //            countryDD: data.countryDDList, countryId: data.countryDDList[0]['countryId'],
                    //            religionDD: data.religionsDDList, religionId: data.religionsDDList[0]['religionId']
                    //        });
                    //    }
                    //    this.state.countryId = data.countryDDList[0]['countryId'];
                    //    this.loadCityDD();
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }

    loadCityDD() {
        fetch(Base_URL + "/api/Dropdowns/Cities?CountryId=" + this.state.countryId).then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.cityId !== 0 && this.state.cityId !== null && this.state.cityId !== -1) {
                        this.setState({ cityDD: data.citiesDDList });
                        var CityIndex = this.state.cityDD.findIndex(o => o.cityId === this.state.cityId);
                        this.setState({
                            cityIdIndex: CityIndex,
                            cityIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ cityDD: data.citiesDDList, cityId: data.citiesDDList[0]['cityId'] });
                        this.state.cityId = data.citiesDDList[0]['cityId'];
                    }
                    this.LoadTowns();
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadTowns() {
        //debugger
        fetch(Base_URL + "/api/Dropdowns/Towns?CityId=" + this.state.cityId).then(response => { return response.json(); })
            .then(data => {
                //debugger
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.townId !== 0 && this.state.townId !== null && this.state.townId !== -1) {
                        this.setState({ townDD: data.townDDList });
                        var TownIndex = this.state.townDD.findIndex(o => o.townId === this.state.townId);
                        this.setState({
                            townIdIndex: TownIndex,
                            townIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ townDD: data.townDDList, townId: data.townDDList[0]['townId'] });
                        this.state.townId = data.townDDList[0]['townId'];
                    }
                    this.LoadSectors();
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadSectors() {
        fetch(Base_URL + "/api/Dropdowns/Sectors?TownId=" + this.state.townId).then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.sectorId !== 0 && this.state.sectorId !== null && this.state.sectorId !== -1) {
                        this.setState({ sectorDD: data.sectorsDDList });
                        var SectorIndex = this.state.sectorDD.findIndex(o => o.sectorId === this.state.sectorId);
                        this.setState({
                            sectorIdIndex: SectorIndex,
                            sectorIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ sectorDD: data.sectorsDDList, sectorId: data.sectorsDDList[0]['sectorId'] });
                        this.state.sectorId = data.sectorsDDList[0]['sectorId'];
                    }
                    this.loadBlockDD();
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    loadBlockDD() {
        //debugger
        fetch(Base_URL + "/api/Dropdowns/Blocks?SectorId=" + this.state.sectorId).then(response => { return response.json(); })
            .then(data => {
                //debugger
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.blockId !== 0 && this.state.blockId !== null && this.state.blockId !== -1) {
                        this.setState({ blockDD: data.blockDDList });
                        var BlockIndex = this.state.blockDD.findIndex(o => o.blockId === this.state.blockId);
                        this.setState({
                            blockIdIndex: BlockIndex,
                            blockIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ blockDD: data.blockDDList, blockId: data.blockDDList[0]['blockId'] });
                    }
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadUserGroups() {
        fetch(Base_URL + "/api/Dropdowns/UserGroupDD").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    this.setState({ userGroupDD: data.userGroupDDList, userGroupId: data.userGroupDDList[0]['userGroupId'] });
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }


            }).catch(error => {
                console.log(error);
            });
    }
    LoadFatherProfession() {
        fetch(Base_URL + "/api/StudentRegistration/FatherProfession").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.professionFatherID !== 0 && this.state.professionFatherID !== null && this.state.professionFatherID !== -1) {
                        this.setState({ professionFatherDD: data.professionlist });
                        var FatherProfessionIndex = this.state.professionFatherDD.findIndex(o => o.professionFatherID === this.state.professionFatherID);
                        this.setState({
                            professionFatherIDIndex: FatherProfessionIndex,
                            professionFatherIDState: 'success'
                        })
                    }
                    else {
                        this.setState({ professionFatherDD: data.professionlist, professionFatherID: data.professionlist[0]['professionFatherID'] });
                    }
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadMotherProfession() {
        fetch(Base_URL + "/api/StudentRegistration/MotherProfession").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.professionMotherID !== 0 && this.state.professionMotherID !== null && this.state.professionMotherID !== -1) {
                        this.setState({ professionMotherDD: data.professionlist });
                        var MotherProfessionIndex = this.state.professionMotherDD.findIndex(o => o.professionMotherID === this.state.professionMotherID);
                        this.setState({
                            professionMotherIDIndex: MotherProfessionIndex,
                            professionMotherIDState: 'success'
                        })
                    }
                    else {
                        this.setState({ professionMotherDD: data.professionlist, professionMotherID: data.professionlist[0]['professionMotherID'] });
                    }
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadGuardianProfession() {
        fetch(Base_URL + "/api/StudentRegistration/GuardianProfession").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.professionGuardianID !== 0 && this.state.professionGuardianID !== null && this.state.professionGuardianID !== -1) {
                        this.setState({ professionGuardianDD: data.professionlist });
                        var GuardianIndex = this.state.professionGuardianDD.findIndex(o => o.professionGuardianID === this.state.professionGuardianID);
                        this.setState({
                            professionGuardianIDIndex: GuardianIndex,
                            professionGuardianIDState: 'success'
                        })
                    }
                    else {
                        this.setState({ professionGuardianDD: data.professionlist, professionGuardianID: data.professionlist[0]['professionGuardianID'] });
                    }
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadFatherNetworks() {
        fetch(Base_URL + "/api/StudentRegistration/FatherNetworkList").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.networkFatherId !== 0 && this.state.networkFatherId !== null && this.state.networkFatherId !== -1) {
                        this.setState({ networkFatherDD: data.networklist });
                        var FatherIndex = this.state.networkFatherDD.findIndex(o => o.networkFatherId === this.state.networkFatherId);
                        this.setState({
                            networkFatherIdIndex: FatherIndex,
                            networkFatherIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ networkFatherDD: data.networklist, networkFatherId: data.networklist[0]['networkFatherId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadMotherNetworks() {
        fetch(Base_URL + "/api/StudentRegistration/MotherNetworkList").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.networkMotherId !== 0 && this.state.networkMotherId !== null && this.state.networkMotherId !== -1) {
                        this.setState({ networkMotherDD: data.networklist });
                        var MotherIndex = this.state.networkMotherDD.findIndex(o => o.networkMotherId === this.state.networkMotherId);
                        this.setState({
                            networkMotherIdIndex: MotherIndex,
                            networkMotherIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ networkMotherDD: data.networklist, networkMotherId: data.networklist[0]['networkMotherId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    LoadGuardianRelation() {
        fetch(Base_URL + "/api/StudentRegistration/GuardianRealation").then(response => { return response.json(); })
            .then(data => {
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    if (this.state.relationId !== 0 && this.state.relationId !== null && this.state.relationId !== -1) {
                        this.setState({ relationDD: data.relationlist });
                        var GuardianIndex = this.state.relationDD.findIndex(o => o.relationId === this.state.relationId);
                        this.setState({
                            relationIdIndex: GuardianIndex,
                            relationIdState: 'success'
                        })
                    }
                    else {
                        this.setState({ relationDD: data.relationlist, relationId: data.relationlist[0]['relationId'] });
                    }

                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }


    //#endregion
    validateData = (event) => {
        this.state.generalError = false;
        debugger;
        var RegistrationDate = Moment(this.state.registrationDate).format('YYYY-MM-DD')
        var StudentDOB = Moment(this.state.dob).format('YYYY-MM-DD');
        var AdmissionDate = Moment(this.state.admissionDate).format('YYYY-MM-DD');
        var JoiningDate = Moment(this.state.joiningDate).format('YYYY-MM-DD');
        if ((this.state.registrationNo === "") || (this.state.registrationNoState === "error")) {
            this.setState({ oldregistrationNoState: "error", generalError: true });
            return;
        }
        else if ((this.state.oldregistrationNo === "") || (this.state.oldregistrationNoState === "error")) {
            this.setState({ oldregistrationNoState: "error", generalError: true });
            return;
        }
        else if (StudentDOB === RegistrationDate) {
            this.setState({ dobState: "error", generalError: true });
            return;
        }
        else if (this.state.genderId < 0) {
            this.setState({ genderIdState: "error", generalError: true });
            return;
        }
        else if (this.state.paymentModeId < 0) {
            this.setState({ paymentModeIdState: "error", generalError: true });
            return;
        }
        else if (this.state.transportModeId < 0) {
            this.setState({ paymentModeIdState: "error", generalError: true });
            return;
        }
        else if (this.state.countryId < 0) {
            this.setState({ countryIdState: "error", generalError: true });
            return;
        }
        else if (this.state.religionId < 0) {
            this.setState({ religionIdState: "error", generalError: true });
            return;
        }
        if ((this.state.currentAddress === "") || (this.state.currentAddressState === "error")) {
            this.setState({ currentAddressState: "error", generalError: true });
            return;
        }
        if ((this.state.fatherNIC === "") || (this.state.fatherNICState === "error")) {
            this.setState({ fatherNICState: "error", generalError: true });
            return;
        }
        else if (this.state.networkFatherId < 0) {
            this.setState({ networkFatherIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.fatherMobileNo === "") || (this.state.fatherMobileNoState === "error")) {
            this.setState({ fatherMobileNoState: "error", generalError: true });
            return;
        }
        else if (this.state.professionFatherID < 0) {
            this.setState({ professionFatherIDState: "error", generalError: true });
            return;
        }
        //else if ((this.state.fathermobileNoSMS === "") || (this.state.fathermobileNoSMSState === "error")) {
        //    this.setState({ fathermobileNoSMSState: "error", generalError: true });
        //    return;
        //}
        else if ((this.state.fatherEmail === "") || (this.state.fatherEmailState === "error")) {
            this.setState({ fatherEmailState: "error", generalError: true });
            return;
        }
        else if (this.state.fatherName === "" || (this.state.fatherNameState === "error")) {
            this.setState({ fatherNameState: "error", generalError: true });
            return;
        }
        else if (this.state.fatherResidence === "" || (this.state.fatherResidenceState === "error")) {
            this.setState({ fatherResidenceState: "error", generalError: true });
            return;
        }
        else if (this.state.familyIncome < 0) {
            this.setState({ familyIncomeState: "error", generalError: true });
            return;
        }
        else if ((this.state.fatherMobileNo === "") || (this.state.fatherMobileNoState === "error")) {
            this.setState({ fatherMobileNoState: "error", generalError: true });
            return;
        }
        if ((this.state.motherNic === "") || (this.state.motherNicState === "error")) {
            this.setState({ motherNicState: "error", generalError: true });
            return;
        }
        else if (this.state.networkMotherId < 0) {
            this.setState({ networkMotherIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.motherMobileNo === "") || (this.state.motherMobileNoState === "error")) {
            this.setState({ motherMobileNoState: "error", generalError: true });
            return;
        }
        else if (this.state.professionMotherID < 0) {
            this.setState({ professionMotherIDState: "error", generalError: true });
            return;
        }
        //else if ((this.state.mothermobileNoSMS === "") || (this.state.mothermobileNoSMSState === "error")) {
        //    this.setState({ mothermobileNoSMSState: "error", generalError: true });
        //    return;
        //}
        else if ((this.state.motherEmail === "") || (this.state.motherEmailState === "error")) {
            this.setState({ motherEmailState: "error", generalError: true });
            return;
        }
        else if (this.state.motherName === "" || (this.state.motherNameState === "error")) {
            this.setState({ motherNameState: "error", generalError: true });
            return;
        }
        else if (this.state.motherResidence === "" || (this.state.motherResidenceState === "error")) {
            this.setState({ motherResidenceState: "error", generalError: true });
            return;
        }
        else if ((this.state.mothermobileNo === "") || (this.state.mothermobileNoState === "error")) {
            this.setState({ mothermobileNoState: "error", generalError: true });
            return;
        }
        else if (this.state.professionGuardianID < 0) {
            this.setState({ professionGuardianIDState: "error", generalError: true });
            return;
        }
        else if ((this.state.guardianEmail === "") || (this.state.guardianEmailState === "error")) {
            this.setState({ guardianEmailState: "error", generalError: true });
            return;
        }
        else if ((this.state.guardianMobileNo === "") || (this.state.guardianMobileNoState === "error")) {
            this.setState({ guardianMobileNotate: "error", generalError: true });
            return;
        }
        else if (this.state.guardianName === "" || (this.state.guardianNameState === "error")) {
            this.setState({ guardianNameState: "error", generalError: true });
            return;
        }
        else if (this.state.guardianAddress === "" || (this.state.guardianAddressState === "error")) {
            this.setState({ guardianAddressState: "error", generalError: true });
            return;
        }
        //else if ((this.state.reason === "") || (this.state.reasonState === "error")) {
        //    this.setState({ reasonState: "error", generalError: true });
        //    return;
        //}
        else if (StudentDOB === RegistrationDate) {
            this.setState({ studentDOBState: "error", generalError: true });
            return;
        }
        else if (this.state.studentDOBState === 'error') {
            debugger;
            this.AlertMessage('DOB Error', 'Please enter correct value in DOB')
            return;
        }
        else if (RegistrationDate > Moment(new Date()).format('YYYY-MM-DD')) {
            this.setState({ registrationDateState: "error" });
            this.AlertMessage('Error', 'Future registration Date is not allowed.')
            return;
        }
        else if (this.state.generalError === true) {
            this.AlertMessage('Error', 'Please enter the value in required fields.')
            return;
        }
        else {
            this.ConfirmMessage("Update Data", "Do you want to update the Student!");
        }
    }
    handleToggle = (event, checkName) => {
        debugger;
        this.setState({ [checkName]: event });
        if (checkName === 'guardianInformation') {
            if (event === true) {
                this.setState({
                    showGuardianData: true
                })
            }
            else {
                this.setState({
                    showGuardianData: false
                })
            }
        }
        if (checkName === "isSingleparent") {
            if (this.state.isSingleparent === true) {
                if ((this.state.fatherNIC !== null || this.state.fatherNIC !== "") || (this.state.motherNic === null || this.state.motherNic === "")) {
                    this.setState({
                        motherNIC: this.state.fatherNIC,
                        networkMotherId: this.state.networkFatherId,
                        mothermobileNoSMS: this.state.fathermobileNoSMS,
                        professionMotherID: this.state.professionFatherID,
                        motherEmail: this.state.fatherEmail,
                        motherResidence: this.state.fatherResidence,
                        motherMobileNo: this.state.fatherMobileNo,
                    })
                    var fathernetworkindex = this.state.networkMotherDD.findIndex(x => x.networkMotherId === this.state.networkMotherId);
                    this.state.networkMotherIdIndex = fathernetworkindex;
                    var fatherprofessionIndex = this.state.professionMotherDD.findIndex(x => x.professionMotherID === this.state.professionMotherID);
                    this.state.professionMotherIDIndex = fatherprofessionIndex;
                }
                else {
                    this.setState({
                        fatherNIC: this.state.motherNIC,
                        networkFatherId: this.state.networkMotherId,
                        fathermobileNoSMS: this.state.mothermobileNoSMS,
                        professionFatherID: this.state.professionMotherID,
                        fatherEmail: this.state.motherEmail,
                        fatherResidence: this.state.motherResidence,
                        fatherMobileNo: this.state.motherMobileNo,
                    })
                    var mothernetworkindex = this.state.networkFatherDD.findIndex(x => x.networkFatherId === this.state.networkFatherId);
                    this.state.networkFatherIdIndex = mothernetworkindex;
                    var motherprofessionIndex = this.state.professionFatherDD.findIndex(x => x.professionFatherID === this.state.professionFatherID);
                    this.state.professionFatherIDIndex = motherprofessionIndex;
                }
            }
        }
    }
    //#region Guardian radio button
    onValueChange(event, RelationName) {
        debugger;
        var Check = "";
        if (RelationName === null) {
            Check = event.target.value
            this.setState({
                selectedOption: event.target.value
            });
        }
        else {
            this.setState({
                selectedOption: RelationName
            });
            Check = RelationName
        }
        if (Check === 'Father') {
            this.setState({
                relationId: 1,
                guardianDisabled: true,
                guardianPhoneNo: this.state.fatherMobileNo,
                guardianName: this.state.fatherName,
                guardianEmail: this.state.fatherEmail,
                guardianAddress: this.state.fatherResidence,
                guardianNIC: this.state.fatherNIC
            })
            var GuardianRelationShip = this.state.relationDD.findIndex(o => o.relationId === 1);
            this.state.relationIdIndex = GuardianRelationShip;
            var Guardianindex = this.state.professionGuardianDD.findIndex(o => o.professionGuardianID === this.state.professionFatherID);
            this.state.professionGuardianIDIndex = Guardianindex;
        }
        else if (Check === 'Mother') {
            this.setState({
                relationId: 2,
                guardianDisabled: true,
                guardianPhoneNo: this.state.motherMobileNo,
                guardianName: this.state.motherName,
                guardianEmail: this.state.motherEmail,
                guardianAddress: this.state.motherResidence,
                guardianNIC: this.state.motherNic
            })
            var GuardianRelationShip = this.state.relationDD.findIndex(o => o.relationId === 2);
            this.state.relationIdIndex = GuardianRelationShip;
            var Guardianindex = this.state.professionGuardianDD.findIndex(o => o.professionGuardianID === this.state.professionMotherID);
            this.state.professionGuardianIDIndex = Guardianindex;
        }
        else if (Check === 'Other') {
            this.state.guardianDisabled = false;
            if (this.state.relationId !== 1 && this.state.relationId !== 2 && this.state.guardianName !== null && this.state.guardianName !== "" && this.state.guardianNIC !== null && this.state.guardianNIC !== "") {
                var GuardianRelationShip = this.state.relationDD.findIndex(o => o.relationId === this.state.relationId);
                this.state.relationIdIndex = GuardianRelationShip;
                var Guardianindex = this.state.professionGuardianDD.findIndex(o => o.professionGuardianID === this.state.professionGuardianID);
                this.state.professionGuardianIDIndex = Guardianindex;
            }
            else {
                this.setState({
                    guardianDisabled: false,
                    guardianRelationShip: "",
                    guardianPhoneNo: "",
                    guardianName: "",
                    guardianEmail: "",
                    guardianAddress: "",
                    guardianNIC: ""

                })
                var GuardianRelationShip = this.state.relationDD.findIndex(o => o.relationId === this.state.relationId);
                this.state.relationIdIndex = GuardianRelationShip;
                var Guardianindex = this.state.professionGuardianDD.findIndex(o => o.professionGuardianID === this.state.professionGuardianID);
                this.state.professionGuardianIDIndex = Guardianindex;
            }
        }
    }
    //#endregion
    //#region Validations
    ageCalculate(dob) {
        var todate = new Date()
        var fromdate = new Date(dob)
        var y = [todate.getFullYear(), fromdate.getFullYear()],
            ydiff = y[0] - y[1],
            m = [todate.getMonth(), fromdate.getMonth()],
            mdiff = m[0] - m[1],
            d = [todate.getDate(), fromdate.getDate()],
            ddiff = d[0] - d[1];
        if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) {
            --ydiff;
        }
        if (mdiff < 0) {
            mdiff += 12;
        }
        if (ddiff < 0) {
            fromdate.setMonth(m[1] + 1, 0)
            ddiff = fromdate.getDate() - d[1] + d[0]
            --mdiff;
            if (mdiff < 0) {
                mdiff += 12;
            }
        }
        return ydiff + " y " + mdiff + " m " + ddiff + " d";
    }
    verifyDate(value) {
        debugger
        var DateRex = /\d\d\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/;
        if (DateRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Email or not
    verifyEmail(value) {
        debugger
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is Mobile No. or not
    verifyMobile(value) {
        debugger;
        var mobileRex = /^([3]{1})([0-9]{9})$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    verifyMobileNo(value) {
        var MobNoRex = /^[3]{1}[0-9]{9}$/i
        if (MobNoRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string is CNIC or not
    verifyNIC(value) {
        debugger
        var nicRex = /^([0-9]{5})([-])([[0-9]{7}])([-])([[0-9]{1}])$/;
        if (nicRex.test(value)) {
            return true;
        }
        return false;
    }
    // function that verifies if a string has a given length or not
    verifyLength(value, length) {
        debugger
        if (value === length) {
            return true;
        }
        return false;
    }
    // function that verifies if two strings are equal
    compare(string1, string2) {
        if (string1 === string2) {
            return true;
        }
        return false;
    }
    // function that setState of Dynamic Dropdown
    setDDValue = (event, value) => {
        debugger;
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        this.setState({
            [stateName]: value[stateName],
            [stateName + "Index"]: this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]),
            [stateName + "State"]: "success"
        })
    }
    setAddress() {
        debugger;
        var house = "";
        if (this.state.houseState === "success") {
            house = "House No. " + this.state.house;
        }
        var street = "";
        if (this.state.streetState === "success") {
            street = "Street No. " + this.state.street;
        }
        var blockId = this.state.blockId;
        var blockName = "";
        if (this.state.blockIdState === "success") {
            blockName = "Block " + this.state.blockDD.find(x => x.blockId === blockId).blockName;
        }
        var sectorId = this.state.sectorId;
        var sectorName = "";
        if (this.state.sectorIdState === "success") {
            sectorName = "Sector " + this.state.sectorDD.find(x => x.sectorId === sectorId).sectorName;
        }
        var townId = this.state.townId;
        var townName = "";
        if (this.state.townIdState === "success") {
            townName = this.state.townDD.find(x => x.townId === townId).townName;
        }
        var cityId = this.state.cityId;
        var cityName = "";
        if (this.state.cityIdState === "success") {
            cityName = this.state.cityDD.find(x => x.cityId === cityId).cityName;
        }
        var countryId = this.state.countryId;
        var countryName = "";
        if (this.state.countryIdState === "success") {
            countryName = this.state.countryDD.find(x => x.countryId === countryId).countryName;
        }
        var CompleteAddress = house + " " + street + " " + blockName + " " + sectorName + " " + townName + " " + cityName + " " + countryName
        if (CompleteAddress.trim().length > 10) {
            this.setState({
                currentAddress: CompleteAddress.trim(),
                currentAddressState: "success"
            })
        }
        else {
            this.setState({
                currentAddress: CompleteAddress.trim(),
                currentAddressState: ""
            })
        }
    }
    textChange = (event) => {
        debugger;
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger;
        switch (type) {
            case "registrationDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        registrationDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "admissionDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        admissionDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "joiningDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        joiningDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "fatherEmail":
                var Email = event.target.value;
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        fatherEmailState: "success",
                        fatherEmail: Email
                    });
                } else {
                    this.setState({
                        fatherEmailState: "error",
                        fatherEmail: Email
                    });
                }
                break;
            case "motherEmail":
                var Email = event.target.value;
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        motherEmailState: "success",
                        motherEmail: Email
                    });
                } else {
                    this.setState({
                        motherEmailState: "error",
                        motherEmail: Email
                    });
                }
                break;
            case "guardianEmail":
                var Email = event.target.value;
                if (this.verifyEmail(event.target.value)) {
                    this.setState({
                        guardianEmailState: "success",
                        guardianEmail: Email
                    });
                } else {
                    this.setState({
                        guardianEmailState: "error",
                        guardianEmail: Email
                    });
                }
                break;
            case "fatherMobileNo":
                debugger
                if (this.verifyMobile(event.target.value)) {
                    debugger
                    this.setState({
                        fatherMobileNoState: "success",
                        fatherMobileNo: event.target.value
                    });
                } else {
                    debugger
                    this.setState({
                        fatherMobileNoState: "error",
                        fatherMobileNo: event.target.value
                    });
                }
                break;
            case "motherMobileNo":
                debugger
                if (this.verifyMobile(event.target.value)) {
                    debugger
                    this.setState({
                        motherMobileNoState: "success",
                        motherMobileNo: event.target.value
                    });
                } else {
                    debugger
                    this.setState({
                        /* motherMobileNoState: "error",*/
                        motherMobileNo: event.target.value
                    });
                }
                break;
            case "admissionDate":
                if (this.verifyDate(Moment(event.target.value).format('YYYY-MM-DD'))) {
                    this.setState({
                        [stateName + "State"]: "success",
                        admissionDate: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: event.target.value
                    });
                }
                break;
            case "motherMobileNo":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "fatherphone":
                if (this.verifyMobile(event.target.value)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "guardianNo":
                if (this.verifyMobile(event.target.value)) {
                    debugger;
                    this.setState({
                        [stateName + "State"]: "success",
                        stateName: event.target.value
                    });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            case "house":
                debugger;
                //this.setState({ house: event.target.value });
                this.state.house = event.target.value;
                this.state.houseState = "success";
                this.setAddress();
                break;
            case "street":
                this.state.street = event.target.value;
                this.state.streetState = "success";
                this.setAddress();
                break;
            case "countryId":
                if (value !== null) {
                    this.loadCityDD(value.countryId);
                }
                else {
                    this.setState({
                        cityDD: [{ cityId: 0, cityName: " " }],
                        cityId: 0,
                        cityIdIndex: -1,
                        cityIdState: ""
                    })
                }
                this.setDDValue(event, value);
                this.setAddress();
                break;
            case "cityId":
                if (value !== null) {
                    this.state.cityId = value.cityId;
                    this.state.cityIdState = "success";
                    this.LoadTowns();
                }

                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "townId":
                if (value !== null) {
                    this.state.townId = value.townId;
                    this.state.townIdState = "success";
                    this.LoadSectors();
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "sectorId":
                if (value !== null) {
                    this.state.sectorId = value.sectorId;
                    this.state.sectorIdState = "success";
                    this.loadBlockDD();
                }
                this.setDDValue(event, value)
                this.setAddress();
                break;
            case "blockId":
                if (value !== null) {
                    this.state.blockId = value.blockId;
                    this.state.blockIdState = "success";
                }
                this.setAddress();
                break;
        }
    }
    changeValue = (event, type) => {
        debugger
        switch (type) {
            case "dob":
                debugger
                var stddate = event.target.value
                var StudentAge = this.ageCalculate(stddate)
                this.setState({
                    dob: stddate,
                    studentAge: StudentAge
                })
                break;
            case "nic":
                if (this.state.overSeas === false) {
                    debugger;
                    if (this.verifyNIC(event.target.value)) {
                        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });

                    } else {
                        debugger;
                        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
                    }
                } else {
                    debugger;
                    this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
                }
                break;
            case "email":
                if (this.verifyEmail(event.target.value)) {
                    this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
                } else {
                    this.setState({ [event.target.id + "State"]: "error", [event.target.id]: event.target.value });
                }
                break;
            case "mobileNumber":
                if (this.verifyMobileNo(event.target.value)) {
                    this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
                } else {
                    this.setState({ [event.target.id + "State"]: "error", [event.target.id]: event.target.value });
                }
                break;
            default:
                break;
        }
    }
    //#endregion
    //#region Alert Messages
    ConfirmMessage(funName, msg) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.updateMainStudentForm()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    AlertMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
        // this.ClearTextfieldStates();
    }

    //#endregion
    async CheckAndSetStates(event, checkName) {
        switch (checkName) {
            case "fatherNIC":
                debugger;
                this.state.fatherNIC = event.target.value
                checkName = "checkNIC"
                break;
            case "motherNic":
                debugger;
                this.state.motherNic = event.target.value
                checkName = "checkNIC"
                break;
            case "fathermobileNumber":
                debugger;
                this.state.fatherMobileNo = event.target.value
                checkName = "mobileNumber"
                break;
            case "mothermobileNumber":
                debugger;
                this.state.motherMobileNo = event.target.value
                checkName = "mobileNumber"
                break;
            case "fathermobileNoSMS":
                debugger;
                this.state.fathermobileNoSMS = event.target.value
                checkName = "mobileNumber"
                break;
            case "mothermobileNoSMS":
                debugger;
                this.state.mothermobileNoSMS = event.target.value
                checkName = "mobileNumber"
                break;
        }
        var rtnValue = await validateCheckSetVal(event, checkName, this.state);
        debugger
        for (var x = 0; x < rtnValue.length; x++) {
            debugger
            var statename = rtnValue[x].StateName;
            var statevalue = rtnValue[x].StateValue;
            this.setState({ [statename]: statevalue });
        }
    }
    //#endregion

    render() {
        const { classes } = this.props;
        const { paymentModeIdState, paymentModeIdIndex, paymentModeDD, transportModeDD, transportModeIdIndex, transportModeIdState, countryIdState, countryIdIndex,
            countryDD, religionDD, religionIdIndex, religionIdState, isOtherWife, status, userGroupDD, overSeas, isSingleparent, guardianInformation, otherthnBehria,
            showpassword, registrationDateState, userGroupIdIndex, userGroupIdState, dobState, admissionDateState, networkFatherDD, networkFatherIdIndex,
            networkFatherIdState, networkMotherDD, networkMotherIdIndex, networkMotherIdState, professionFatherIDState, professionFatherIDIndex, professionFatherDD,
            professionMotherDD, professionMotherIDIndex, professionMotherIDState, professionGuardianDD, professionGuardianIDIndex, professionGuardianIDState, relationDD,
            relationIdIndex, relationIdState, genderId
        } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Student Personal Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <div style={{ paddingLeft: "12px" }}>
                                            <GridItem>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="religionId"
                                                            size="small"
                                                            options={religionDD}
                                                            getOptionLabel={(option) => option.religionName}
                                                            value={religionDD[religionIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                                            renderInput={(params) => <TextField {...params} success={religionIdState === "success"}
                                                                error={religionIdState === "error"} label="Religion" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="countryId"
                                                            size="small"
                                                            options={countryDD}
                                                            getOptionLabel={(option) => option.countryName}
                                                            value={countryDD[countryIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                                            renderInput={(params) => <TextField {...params} success={countryIdState === "success"}
                                                                error={countryIdState === "error"} label="Nationality" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                </GridContainer>
                                            </GridItem>
                                        </div>
                                        <div style={{ paddingLeft: "12px" }}>
                                            <GridItem>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <TextField success={dobState === 'success'}
                                                            error={dobState === "error"}
                                                            id="dob"
                                                            size="small"
                                                            label={"Student DOB"}
                                                            variant="outlined"
                                                            fullWidth
                                                            type="date"
                                                            value={Moment(this.state.dob).format('YYYY-MM-DD')}
                                                            onChange={(event) => { this.changeValue(event, "dob") }}
                                                        />
                                                    </GridTextbox >
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <Autocomplete
                                                            size="small"
                                                            options={this.state.genderDD}
                                                            getOptionLabel={(option) => option.name}
                                                            value={this.state.genderDD[this.state.genderIdIndex]}
                                                            id="genderId"
                                                            disableClearable={true}
                                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                                            renderInput={(params) => <TextField {...params} label="Gender *" variant="outlined"
                                                                success={this.state.genderState === "success"}
                                                                error={this.state.genderState === "error"} />}
                                                        />
                                                    </GridTextbox>
                                                </GridContainer>
                                            </GridItem>
                                        </div>
                                        <div style={{ paddingLeft: "0px" }}>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.nameState === "success"}
                                                    error={this.state.nameState === "error"}
                                                    size="small"
                                                    label="Student Name"
                                                    id="name"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.name}
                                                    onChange={this.textChange}
                                                    disabled
                                                />
                                            </GridTextbox>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <PictureUpload />
                                        {/*<div style={{ textAlign: "center" }}>*/}
                                        {/*    <Button color="success" ><GetAppIcon />Download</Button>*/}
                                        {/*    <Button color="danger"><DeleteIcon />Remove</Button>*/}
                                        {/*</div>*/}
                                        {/*<hr style={{ marginBottom: "0px" }} />*/}
                                    </GridItem>
                                </GridContainer>
                                <div style={{ paddingLeft: "0px" }}>
                                    <GridItem>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    success={this.state.houseState === "success"}
                                                    error={this.state.houseState === "error"}
                                                    size="small"
                                                    label="House"
                                                    id="house"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    value={this.state.house}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "house", "house", value, 1),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <TextField
                                                    success={this.state.streetState === "success"}
                                                    error={this.state.streetState === "error"}
                                                    size="small"
                                                    label="Street"
                                                    id="street"
                                                    variant="outlined"
                                                    fullWidth
                                                    required="true"
                                                    value={this.state.street}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "street", "street", value, 1),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="cityId"
                                                    options={this.state.cityDD}
                                                    getOptionLabel={(option) => option.cityName}
                                                    value={this.state.cityDD[this.state.cityIdIndex]}
                                                    onChange={(event, value) => this.change(event, "cityId", "cityId", value)}
                                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined"
                                                        success={this.state.saveCityIdState === "success"}
                                                        error={this.state.saveCityIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} lg={4}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggle(event, "otherthnBehria")} checked={otherthnBehria} onColor="#3b5998" borderRadius={4}
                                                        handleDiameter={22} className={classes.toggleStyle}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Other than Bahria</span>
                                                </label>
                                            </GridTextbox>
                                        </GridContainer>
                                    </GridItem>
                                </div>
                                <div style={{ paddingLeft: "0px" }}>
                                    <GridItem>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="townId"
                                                    options={this.state.townDD}
                                                    getOptionLabel={(option) => option.townName}
                                                    value={this.state.townDD[this.state.townIdIndex]}
                                                    onChange={(event, value) => this.change(event, "townId", "townId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Town" variant="outlined"
                                                        success={this.state.saveTownIdState === "success"}
                                                        error={this.state.saveTownIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="sectorId"
                                                    options={this.state.sectorDD}
                                                    getOptionLabel={(option) => option.sectorName}
                                                    value={this.state.sectorDD[this.state.SectorIdIndex]}
                                                    onChange={(event, value) => this.change(event, "sectorId", "sectorId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Sector" variant="outlined"
                                                        success={this.state.saveSectorIdState === "success"}
                                                        error={this.state.saveSectorIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={4}>
                                                <Autocomplete
                                                    size="small"
                                                    id="blockId"
                                                    options={this.state.blockDD}
                                                    getOptionLabel={(option) => option.blockName}
                                                    value={this.state.blockDD[this.state.blockIdIndex]}
                                                    onChange={(event, value) => this.change(event, "blockId", "blockId", value)}
                                                    renderInput={(params) => <TextField {...params} label="Block" variant="outlined"
                                                        success={this.state.saveblockIdState === "success"}
                                                        error={this.state.saveblockIdState === "error"} />}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </GridItem>
                                </div>
                                <div style={{ paddingLeft: "0px" }}>
                                    <GridItem>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={12}>
                                                <TextField
                                                    success={this.state.currentAddressState === "success"}
                                                    error={this.state.currentAddressState === "error"}
                                                    size="small"
                                                    label="Current Address"
                                                    id="currentAddressId"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={this.state.currentAddress}
                                                    multiline={true}
                                                    rows={2}
                                                    required="true"
                                                    disabled={!otherthnBehria}
                                                    inputProps={{
                                                        onChange: (event, value) =>
                                                            this.change(event, "currentAddress", "currentAddress", value, 5),
                                                        type: "text"
                                                    }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </GridItem>
                                </div>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Student School Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer >
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <TextField
                                            success={this.state.registrationNoState === "success"}
                                            error={this.state.registrationNoState === "error"}
                                            value={this.state.registrationNo}
                                            size="small"
                                            label="Registration No"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <TextField
                                            success={this.state.oldregistrationNoState === "success"}
                                            error={this.state.oldregistrationNoState === "error"}
                                            value={this.state.oldregistrationNo}
                                            size="small"
                                            id="oldregistrationNo"
                                            label=" Old Registration No"
                                            value={this.state.oldregistrationNo}
                                            onChange={this.textChange}
                                            variant="outlined"
                                            fullWidth

                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <TextField success={registrationDateState === 'success'}
                                            error={registrationDateState === "error"}
                                            id="registrationDate"
                                            size="small"
                                            label={"Registration Date"}
                                            variant="outlined"
                                            fullWidth
                                            type="date"
                                            value={Moment(this.state.registrationDate).format('YYYY-MM-DD')}
                                            inputProps={{ onChange: event => this.change(event, "registrationDate", "registrationDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <TextField success={admissionDateState === 'success'}
                                            error={admissionDateState === "error"}
                                            id="admissionDate"
                                            size="small"
                                            label={"Admission Date"}
                                            variant="outlined"
                                            fullWidth
                                            type="date"
                                            value={Moment(this.state.admissionDate).format('YYYY-MM-DD')}
                                            inputProps={{ onChange: event => this.change(event, "admissionDate", "admissionDate") }}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <TextField
                                            success={this.state.joiningDateState === "success"}
                                            error={this.state.joiningDateState === "error"}
                                            size="small"
                                            label="Joining Date"
                                            id="joiningDate"
                                            variant="outlined"
                                            fullWidth
                                            value={Moment(this.state.joiningDate).format('YYYY-MM-DD')}
                                            type="date"
                                            inputProps={{ onChange: event => this.change(event, "joiningDate", "joiningDate") }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <TextField
                                            success={this.state.applicationStatusState === "success"}
                                            error={this.state.applicationStatusState === "error"}
                                            size="small"
                                            label={"Admission Status"}
                                            id="applicationStatus"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.applicationStatus}
                                            disabled
                                        />
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <Autocomplete
                                            disableClearable
                                            id="transportModeId"
                                            size="small"
                                            options={transportModeDD}
                                            getOptionLabel={(option) => option.name}
                                            value={transportModeDD[transportModeIdIndex]}
                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                            renderInput={(params) => <TextField {...params} success={transportModeIdState === "success"}
                                                error={transportModeIdState === "error"} label="Transport Mode" variant="outlined" />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={6} style={{ marginTop: "16px" }}>
                                        <Autocomplete
                                            disableClearable
                                            id="paymentModeId"
                                            size="small"
                                            options={paymentModeDD}
                                            getOptionLabel={(option) => option.paymentModeName}
                                            value={paymentModeDD[paymentModeIdIndex]}
                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                            renderInput={(params) => <TextField {...params} success={paymentModeIdState === "success"}
                                                error={paymentModeIdState === "error"} label="Payment Mode" variant="outlined" />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} lg={3} style={{ marginTop: "16px" }}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.handleToggle(event, "status")} checked={status} onColor="#3b5998" borderRadius={4}
                                                handleDiameter={22} className={classes.toggleStyle}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                            <span>Active</span>
                                        </label>
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
                <div style={{ padding: "5px 0px", margin: "10px -11px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb" style={{ backgroundColor: "#d4e2f8", paddingBottom: "0px", paddingTop: "0px", paddingLeft: "0px", margin: "0px" }}>
                        {/*<Typography variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary" wrapped>STUDENT PLACEMENT DETAIL</Typography>*/}
                        <GridContainer>
                            <ul style={{ listStyle: "none" }}>
                                <li style={{ float: "left" }}>
                                    <GridTextbox xs={12} sm={12} lg={2}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.handleToggle(event, "overSeas")} checked={overSeas} onColor="#3b5998" borderRadius={4}
                                                handleDiameter={22} className={classes.toggleStyle}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                        </label>
                                    </GridTextbox>
                                </li>
                                <li style={{ float: "left", marginRight: "10px", marginTop: "17px" }}>
                                    <span style={{}}> Over Seas</span>
                                </li>
                                <li style={{ float: "left" }}>
                                    <GridTextbox xs={12} sm={12} lg={2}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.handleToggle(event, "isSingleparent")} checked={isSingleparent} onColor="#3b5998" borderRadius={4}
                                                handleDiameter={22} className={classes.toggleStyle}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                        </label>
                                    </GridTextbox>
                                </li>
                                <li style={{ float: "left", marginRight: "10px", marginTop: "17px" }}>
                                    <span> Single Parent</span>
                                </li>
                            </ul>
                        </GridContainer>
                    </Breadcrumbs>
                </div>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Father Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    {this.state.overSeas === false ?
                                        <GridTextbox xs={12} sm={12} md={6}>
                                            <InputMask
                                                {...this.props}
                                                mask="99999-9999999-9"
                                                maskChar=""
                                                value={this.state.fatherNIC}
                                            >
                                                {() => <TextField
                                                    success={this.state.fatherNICState === "success"}
                                                    error={this.state.fatherNICState === "error"}
                                                    size="small"
                                                    label="Father CNIC"
                                                    id="fatherNIC"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    autoComplete="off"
                                                    placeholder="00000-0000000-0"
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.CheckAndSetStates(event, "fatherNIC"),
                                                        maxLength: 15,
                                                    }}
                                                />
                                                }
                                            </InputMask>
                                        </GridTextbox> :
                                        <GridTextbox xs={12} sm={12} md={6}>
                                            <TextField
                                                success={this.state.fatherNICState === "success"}
                                                error={this.state.fatherNICState === "error"}
                                                value={this.state.fatherNIC}
                                                size="small"
                                                label="Father CNIC"
                                                id="fatherNIC"
                                                variant="outlined"
                                                fullWidth
                                                required={true}
                                                autoComplete="off"
                                                inputProps={{
                                                    onChange: event =>
                                                        this.CheckAndSetStates(event, "fatherNIC"),
                                                    maxLength: 15,
                                                }}
                                            />
                                        </GridTextbox>
                                    }
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.fatherNameState === "success"}
                                            error={this.state.fatherNameState === "error"}
                                            size="small"
                                            label={"Father Name"}
                                            id="fatherName"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.fatherName}
                                            onChange={this.textChange}
                                        />
                                    </GridTextbox>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                disableClearable
                                                id="professionFatherID"
                                                size="small"
                                                options={professionFatherDD}
                                                getOptionLabel={(option) => option.profession}
                                                value={professionFatherDD[professionFatherIDIndex]}
                                                required={true}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={professionFatherIDState === "success"}
                                                    error={professionFatherIDState === "error"} label="Father Profession" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.fatherEmailState === "success"}
                                                error={this.state.fatherEmailState === "error"}
                                                size="small"
                                                label="Father Email"
                                                placeholder="someone@mail.com"
                                                id="fatherEmail"
                                                variant="outlined"
                                                required={true}
                                                value={this.state.fatherEmail}
                                                fullWidth
                                                inputProps={{
                                                    onChange: event =>
                                                        this.CheckAndSetStates(event, "email"),
                                                    maxLength: 15,
                                                }}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                disableClearable
                                                id="networkFatherId"
                                                size="small"
                                                required={true}
                                                options={networkFatherDD}
                                                getOptionLabel={(option) => option.networkName}
                                                value={networkFatherDD[networkFatherIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={networkFatherIdState === "success"}
                                                    error={networkFatherIdState === "error"} label="Network" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <InputMask
                                                {...this.props}
                                                mask="\3999999999"
                                                maskChar=""
                                                value={this.state.fathermobileNoSMS}
                                            >
                                                {() => <TextField
                                                    success={this.state.fathermobileNoSMSState === "success"}
                                                    error={this.state.fathermobileNoSMSState === "error"}
                                                    size="small"
                                                    label="Father Phone No. for SMS"
                                                    id="fathermobileNoSMS"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    autoComplete="off"
                                                    placeholder="3001234567"
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.CheckAndSetStates(event, "fathermobileNoSMS"),
                                                        maxLength: 10,
                                                    }}
                                                />
                                                }
                                            </InputMask>
                                        </GridTextbox>
                                        <div style={{ paddingLeft: "11px" }}>
                                            <GridItem>
                                                <GridContainer>
                                                    <GridTextbox xs={12} sm={12} md={8}>
                                                        <InputMask
                                                            {...this.props}
                                                            mask="\3999999999"
                                                            maskChar=""
                                                            value={this.state.fatherMobileNo}
                                                        >
                                                            {() => <TextField
                                                                success={this.state.fatherMobileNoState === "success"}
                                                                error={this.state.fatherMobileNoState === "error"}
                                                                size="small"
                                                                label="Father Phone No"
                                                                id="fatherMobileNo"
                                                                variant="outlined"
                                                                fullWidth
                                                                required={true}
                                                                autoComplete="off"
                                                                placeholder="3001234567"
                                                                inputProps={{
                                                                    onChange: event =>
                                                                        this.CheckAndSetStates(event, "fathermobileNumber"),
                                                                    maxLength: 10,
                                                                }}
                                                            />
                                                            }
                                                        </InputMask>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={4}>
                                                        <TextField
                                                            success={this.state.familyIncomeState === "success"}
                                                            error={this.state.familyIncomeState === "error"}
                                                            value={this.state.familyIncome}
                                                            id="familyIncome"
                                                            size="small"
                                                            label="Family Income"
                                                            variant="outlined"
                                                            fullWidth
                                                            required={true}
                                                            type="number"
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                </GridContainer>
                                            </GridItem>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <PictureUpload />
                                        <div style={{ textAlign: "center" }}>
                                            <Button color="success" ><GetAppIcon />Download</Button>
                                            <Button color="danger"><DeleteIcon />Remove</Button>
                                        </div>
                                        <hr style={{ marginBottom: "25px" }} />
                                        <GridTextbox xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                                            <label htmlFor="small-radius-switch">
                                                <ReactToggle onChange={(event) => this.handleToggle(event, "isOtherWife")} checked={isOtherWife} onColor="#3b5998" borderRadius={4}
                                                    handleDiameter={22} className={classes.toggleStyle}
                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                    } />
                                                <span> More Than One Wife</span>
                                            </label>
                                        </GridTextbox>
                                    </GridItem>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                        <TextField
                                            success={this.state.fatherResidenceState === "success"}
                                            error={this.state.fatherResidenceState === "error"}
                                            size="small"
                                            label={"Residence"}
                                            id="fatherResidence"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.fatherResidence}
                                            onChange={this.textChange}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                    <GridForm xs={12} sm={6} md={6}>
                        <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Mother Information</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    {this.state.overSeas === false ?
                                        <GridTextbox xs={12} sm={12} md={6}>
                                            <InputMask
                                                {...this.props}
                                                mask="99999-9999999-9"
                                                maskChar=""
                                                value={this.state.motherNic}
                                            >
                                                {() => <TextField
                                                    success={this.state.motherNicState === "success"}
                                                    error={this.state.motherNicState === "error"}
                                                    size="small"
                                                    label="Mother CNIC"
                                                    id="motherNic"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    autoComplete="off"
                                                    placeholder="00000-0000000-0"
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.CheckAndSetStates(event, "motherNic"),
                                                        maxLength: 15,
                                                    }}
                                                />
                                                }
                                            </InputMask>
                                        </GridTextbox> :
                                        <GridTextbox xs={12} sm={12} md={6}>
                                            <TextField
                                                success={this.state.motherNicState === "success"}
                                                error={this.state.motherNicState === "error"}
                                                value={this.state.motherNic}
                                                size="small"
                                                label="Mother CNIC"
                                                id="motherNic"
                                                variant="outlined"
                                                fullWidth
                                                required={true}
                                                autoComplete="off"

                                                inputProps={{
                                                    onChange: event =>
                                                        this.CheckAndSetStates(event, "motherNic"),
                                                    maxLength: 15,
                                                }}
                                            />
                                        </GridTextbox>
                                    }
                                    <GridTextbox xs={12} sm={12} md={6}>
                                        <TextField
                                            success={this.state.motherNameState === "success"}
                                            error={this.state.motherNameState === "error"}
                                            size="small"
                                            required={true}
                                            label={"Mother Name"}
                                            id="motherName"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.motherName}
                                            onChange={this.textChange}
                                        />
                                    </GridTextbox>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                disableClearable
                                                id="professionMotherID"
                                                size="small"
                                                required={true}
                                                options={professionMotherDD}
                                                getOptionLabel={(option) => option.profession}
                                                value={professionMotherDD[professionMotherIDIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={professionMotherIDState === "success"}
                                                    error={professionMotherIDState === "error"} label="Mother Profession" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <TextField
                                                success={this.state.motherEmailState === "success"}
                                                error={this.state.motherEmailState === "error"}
                                                size="small"
                                                label="Mother Email"
                                                placeholder="someone@mail.com"
                                                id="motherEmail"
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.motherEmail}
                                                inputProps={{
                                                    onChange: event =>
                                                        this.CheckAndSetStates(event, "email"),
                                                    maxLength: 10,
                                                }}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <Autocomplete
                                                disableClearable
                                                id="networkMotherId"
                                                size="small"
                                                required={true}
                                                options={networkMotherDD}
                                                getOptionLabel={(option) => option.networkName}
                                                value={networkMotherDD[networkMotherIdIndex]}
                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                renderInput={(params) => <TextField {...params} success={networkMotherIdState === "success"}
                                                    error={networkMotherIdState === "error"} label="Mobile Network for SMS" variant="outlined" />}
                                            />
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <InputMask
                                                {...this.props}
                                                mask="\3999999999"
                                                maskChar=""
                                                value={this.state.mothermobileNoSMS}
                                            >
                                                {() => <TextField
                                                    success={this.state.mothermobileNoSMSState === "success"}
                                                    error={this.state.mothermobileNoSMSState === "error"}
                                                    size="small"
                                                    label="Mother Phone No. for SMS"
                                                    id="mothermobileNoSMS"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    autoComplete="off"
                                                    placeholder="3001234567"
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.CheckAndSetStates(event, "mothermobileNoSMS"),
                                                        maxLength: 10,
                                                    }}
                                                />
                                                }
                                            </InputMask>
                                        </GridTextbox>
                                        <GridTextbox xs={12} sm={12} md={12}>
                                            <InputMask
                                                {...this.props}
                                                mask="\3999999999"
                                                maskChar=""
                                                value={this.state.motherMobileNo}
                                            >
                                                {() => <TextField
                                                    success={this.state.motherMobileNoState === "success"}
                                                    error={this.state.motherMobileNoState === "error"}
                                                    size="small"
                                                    label="Mother Mobile No"
                                                    id="motherMobileNo"
                                                    variant="outlined"
                                                    fullWidth
                                                    required={true}
                                                    autoComplete="off"
                                                    placeholder="3001234567"
                                                    inputProps={{
                                                        onChange: event =>
                                                            this.CheckAndSetStates(event, "mothermobileNumber"),
                                                        maxLength: 10,
                                                    }}
                                                />
                                                }
                                            </InputMask>
                                        </GridTextbox>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <PictureUpload />
                                        <div style={{ textAlign: "center" }}>
                                            <Button color="success" ><GetAppIcon />Download</Button>
                                            <Button color="danger"><DeleteIcon />Remove</Button>
                                        </div>
                                        <hr style={{ marginBottom: "25px" }} />
                                    </GridItem>
                                    <GridTextbox xs={12} sm={12} md={12}>
                                        <TextField
                                            success={this.state.motherResidenceState === "success"}
                                            error={this.state.motherResidenceState === "error"}
                                            required={true}
                                            size="small"
                                            label={"Residence"}
                                            id="motherResidence"
                                            variant="outlined"
                                            fullWidth
                                            value={this.state.motherResidence}
                                            onChange={this.textChange}
                                        />
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridForm>
                </GridContainer>
                <div style={{ padding: "5px 0px", margin: "10px -11px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb" style={{ backgroundColor: "#d4e2f8", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "0px", margin: "0px" }}>
                        <GridContainer>
                            <ul style={{ listStyle: "none" }}>
                                <li style={{ float: "left" }}>
                                    <GridTextbox xs={12} sm={12} md={3}>
                                        <label htmlFor="small-radius-switch">
                                            <ReactToggle onChange={(event) => this.handleToggle(event, "guardianInformation")}
                                                checked={guardianInformation} onColor="#3b5998" borderRadius={4}
                                                handleDiameter={22} className={classes.toggleStyle}
                                                uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                } />
                                        </label>
                                    </GridTextbox>
                                </li>
                                <li style={{ float: "left", marginRight: "10px", marginTop: "17px" }}>
                                    <span> Guardian Information</span>
                                </li>
                            </ul>
                            <GridTextbox xs={12} sm={12} md={5} style={{ marginTop: "10px", marginBottom: "0px" }}>
                                <input
                                    type="radio"
                                    name="guardian"
                                    value="Father"
                                    checked={this.state.selectedOption === "Father"}
                                    onChange={(e) => this.onValueChange(e, null)}
                                />
                                <span style={{ color: "black" }}>  Father</span>
                                <input
                                    type="radio"
                                    value="Mother"
                                    name="guardian"
                                    checked={this.state.selectedOption === "Mother"}
                                    onChange={(e) => this.onValueChange(e, null)}

                                />
                                <span style={{ color: "black" }}> Mother</span>
                                <input
                                    type="radio"
                                    value="Other"
                                    name="guardian"
                                    checked={this.state.selectedOption === "Other"}
                                    onChange={(e) => this.onValueChange(e, null)}
                                />
                                <span style={{ color: "black" }}> Other</span>
                            </GridTextbox>
                        </GridContainer>
                    </Breadcrumbs>
                </div>
                <GridContainer>
                    <GridForm xs={12} sm={6} md={12}>
                        {this.state.alert}
                        {this.state.showGuardianData === true ?
                            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                                <CardHeader color="info" icon>
                                    <h4 className={classes.cardIconTitle}>Guardian Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={8}>
                                            <div style={{ paddingLeft: "11px" }}>
                                                <GridItem>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <Autocomplete
                                                                disabled={this.state.guardianDisabled}
                                                                disableClearable
                                                                id="relationId"
                                                                size="small"
                                                                options={relationDD}
                                                                getOptionLabel={(option) => option.relationName}
                                                                value={relationDD[relationIdIndex]}
                                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                renderInput={(params) => <TextField {...params} success={relationIdState === "success"}
                                                                    error={relationIdState === "error"} label="Guardian RelationShip" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <TextField
                                                                disabled={this.state.guardianDisabled}
                                                                success={this.state.guardianNICState === "success"}
                                                                error={this.state.fatherNICState === "error"}
                                                                size="small"
                                                                label={"Guardian NIC"}
                                                                id="guardianNIC"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.guardianNIC}
                                                                required={true}
                                                                onChange={(event) => this.changeValue(event, "nic", "nic")}
                                                                placeholder="00000-0000000-0"
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <TextField
                                                                disabled={this.state.guardianDisabled}
                                                                success={this.state.guardianNameState === "success"}
                                                                error={this.state.guardianNameState === "error"}
                                                                size="small"
                                                                label={"Guardian Name"}
                                                                id="guardianName"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.guardianName}
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </GridItem>
                                            </div>
                                            <div style={{ paddingLeft: "11px" }}>
                                                <GridItem>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <Autocomplete
                                                                disabled={this.state.guardianDisabled}
                                                                disableClearable
                                                                id="professionGuardianID"
                                                                size="small"
                                                                options={professionGuardianDD}
                                                                getOptionLabel={(option) => option.profession}
                                                                value={professionGuardianDD[professionGuardianIDIndex]}
                                                                onChange={(event, value) => { this.setDDValue(event, value) }}
                                                                renderInput={(params) => <TextField {...params} success={professionGuardianIDState === "success"}
                                                                    error={professionGuardianIDState === "error"} label="Guardian Profession" variant="outlined" />}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <TextField
                                                                disabled={this.state.guardianDisabled}
                                                                success={this.state.guardianPhoneNoState === "success"}
                                                                error={this.state.guardianPhoneNoState === "error"}
                                                                id="guardianPhoneNo"
                                                                size="small"
                                                                label="Guardian Mobile No"
                                                                variant="outlined"
                                                                fullWidth
                                                                placeholder={"31117654321"}
                                                                value={this.state.guardianPhoneNo}
                                                                onChange={(event, value) => { this.changeValue(event, "mobileNumber") }}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4}>
                                                            <TextField
                                                                disabled={this.state.guardianDisabled}
                                                                success={this.state.guardianEmailState === "success"}
                                                                error={this.state.guardianEmailState === "error"}
                                                                size="small"
                                                                label="Guardian Email"
                                                                placeholder="someone@mail.com"
                                                                id="guardianEmail"
                                                                variant="outlined"
                                                                value={this.state.guardianEmail}
                                                                fullWidth
                                                                inputProps={{
                                                                    onChange: event => this.change(event, "guardianEmail", "guardianEmail")
                                                                }}
                                                            />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </GridItem>
                                            </div>
                                            <div style={{ paddingLeft: "11px" }}>
                                                <GridItem>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12}>
                                                            <TextField
                                                                disabled={this.state.guardianDisabled}
                                                                success={this.state.guardianAddressState === "success"}
                                                                error={this.state.guardianAddressState === "error"}
                                                                size="small"
                                                                label="Guardian Address"
                                                                id="guardianAddress"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={this.state.guardianAddress}
                                                                multiline={true}
                                                                rows={1}
                                                                required="true"
                                                                onChange={this.textChange}
                                                            />
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </GridItem>
                                            </div>
                                        </GridItem >
                                        <GridItem xs={12} sm={12} md={4}>
                                            <PictureUpload />
                                            <div style={{ textAlign: "center" }}>
                                                <Button color="success" ><GetAppIcon />Download</Button>
                                                <Button color="danger"><DeleteIcon />Remove</Button>
                                            </div>
                                            <hr style={{ marginBottom: "0px" }} />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card> : ""}
                        <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "45%" }}>
                            <Button color="facebook" onClick={(event) => { this.validateData(event) }}
                                disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Update </Button>
                        </GridTextbox>
                    </GridForm>
                </GridContainer>

            </div>
        );
    }
}
MainStdProfile.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(formStyle)(MainStdProfile);