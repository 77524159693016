
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined,
    SearchSharp, AddIcon, FindInPage, Breadcrumbs, NavigateNextIcon, Settings, Link, HomeIcon, Typography,
    GridContainer, GridForm, GridTextbox, Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle,
    muiWithToolbar, tbleWithPrint, Moment, ErrorOutline, DateRange, AlternateEmail, Autocomplete, FormControl,
    InputLabel, Select, FormControlLabel, Switchb, Commute, GridItem, regularFormsStyle, NavigateNext, Home, extendedFormsStyle,
    axios, SearchIcon
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class TransportMail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', campusIndex: 0, employeeIndex: 0,
            alert: null, show: false, checked: false, searchCheck: true, employeeDD: [{ employeeId: 0, name: 'None' }],
            campusDD: [{ campusId: -1, campusName: 'Select' }], transportMailList: [], autoId: -1, campusId: '',
            campusIdState: '', employeeId: '', employeeIdState: '', active: false,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.getdropdowns();
    };
    LoadTextbox(autoId) {
        let obj = this.state.transportMailList.find(o => o.autoId === autoId);
        this.setState({ autoId: obj.autoId, campusId: obj.campusId, employeeId: obj.employeeId, active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
        this.setState({ campusIndex: this.state.campusDD.findIndex(x => x.campusId === obj.campusId) });
        this.setState({ employeeIndex: this.state.employeeDD.findIndex(x => x.employeeId === obj.employeeId) });
    }

    SaveUpdateTMail = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        var formData = new FormData(); {
            formData.append('AutoId', this.state.autoId)
            formData.append('CampusId', this.state.campusId)
            formData.append('EmployeeId', this.state.employeeId)
            formData.append('Active', this.state.active)
        }
        axios.post(Base_URL + '/TransportPrimaryData/SaveUpdateTMail', formData)
            .then(json => {
                this.SuccessMessage(json.data.status, json.data.message)
            })
    }
    ClearTextfieldStates = () => {
        this.setState({
            campusIndex: -1, employeeIndex: -1,
            autoId: -1, campusId: '', campusIdState: '', employeeId: '', employeeIdState: '', active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }

    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.campusId === "") {
            this.setState({ campusIdState: "error" });
            return;
        }
        else if (this.state.employeeId === "") {
            this.setState({ employeeIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    textChange = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    //#endregion 

    //#region Backend Data Fetching and Saving

    LoadData = () => {
        fetch(Base_URL + "/TransportPrimaryData/TransportMail?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ transportMailList: data });
            }).catch(error => {
                console.log(error);
            });
    }
    getdropdowns = () => {
        fetch(Base_URL + "/api/Dropdowns/EmployeeDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ employeeDD: data, employeeId: data[0]['employeeId'] });
            }).catch(error => {
                console.log(error);
            });

        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                this.setState({ campusDD: data, campusId: data[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateTMail()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    closeOnClickOutside={false}
                    showCancel
                >
                    Do you want to save Transport Mail Details!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { transportMailList, campusIndex, employeeIndex, employeeDD, employeeIdState, campusIdState, campusDD, active, searchCheck } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Load Data</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchIcon /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add/Edit Transport Mail</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="campusId"
                                                        size="small"
                                                        options={campusDD}
                                                        getOptionLabel={(option) => option.campusName}
                                                        value={campusDD[campusIndex]}
                                                        onChange={(event, value) => { this.setState({ campusId: value.campusId, campusIdState: "success", campusIndex: campusDD.findIndex(x => x.campusId === value.campusId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                                            error={campusIdState === "error"} label="Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} >
                                                    <Autocomplete
                                                        disableClearable
                                                        id="employeeId"
                                                        size="small"
                                                        options={employeeDD}
                                                        getOptionLabel={(option) => option.name}
                                                        value={employeeDD[employeeIndex]}
                                                        onChange={(event, value) => { this.setState({ employeeId: value.employeeId, employeeIdState: "success", employeeIndex: employeeDD.findIndex(x => x.employeeId === value.employeeId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={employeeIdState === "success"}
                                                            error={employeeIdState === "error"} label="Employee" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1} style={{ textAlign: "center" }}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}><AddIcon /> Add New </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Transport Mail"}
                                        data={transportMailList}
                                        columns={[{
                                            name: "campusName",
                                            label: "Campus Name",

                                        }, {
                                            name: "employeeName",
                                            label: "Employee",

                                        },
                                        {
                                            name: "active",
                                            label: "Active",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                }
                                            }

                                        }, {
                                            name: "autoId",
                                            label: "Edit",
                                            options: {
                                                customBodyRender: (value) => {
                                                    return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                }
                                            }
                                        }]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(TransportMail);
