import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    axios, SearchSharp, FormControl, InputLabel, Select
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class StdClubs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Datatable Columns
            columns: [{ name: "clubType", label: "Club Type" }, { name: "clubName", label: "Club Name" },
            { name: "clubFee", label: "Club Fee" }, { name: "remarks", label: "Remarks", },
            {
                name: "active", label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            },
            {
                name: "clubId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false, searchCheck: true,
            clubList: [], clubId: -1, clubName: '', clubNameState: '', clubType: 'Physical Club', clubFee: 0, clubFeeState: '', remarks: '', active: true,
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#region Button,Validaition
    validateData = () => {
        if (this.state.clubName === "") {
            this.setState({ clubNameState: "error" });
            return;
        }
        else if (this.state.clubFee === "") {
            this.setState({ clubFeeState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }

    SaveUpdateClubs = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const formData = new FormData()
        formData.append("ClubId", this.state.clubId)
        formData.append("ClubName", this.state.clubName)
        formData.append("ClubType", this.state.clubType)
        formData.append("ClubFee", this.state.clubFee)
        formData.append("Remarks", this.state.remarks)
        formData.append("Active", this.state.active)
        axios.post(Base_URL + "/StudentPrimaryData/SaveUpdateClubs", formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    LoadTextbox(clubId) {
        debugger;
        let obj = this.state.clubList.find(o => o.clubId === clubId);
        this.setState({ clubName: obj.clubName, clubType: obj.clubType, active: obj.active, clubId: obj.clubId, clubFee: obj.clubFee, remarks: obj.remarks, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' })
    }
    ClearTextfieldStates = () => {
        this.setState({ clubId: -1, clubName: '', clubNameState: '', clubType: 'Physical Club', clubFee: 0, clubFeeState: '', remarks: '', active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', });
        this.hideAlert();
        this.LoadData();
    }
    //#endregion 

    //#region Backend Data Fetching and Saving

    LoadData = () => {
        fetch(Base_URL + "/StudentPrimaryData/LoadClubs?searchCheck=" + this.state.searchCheck).then(response => { return response.json(); })
            .then(data => {
                this.setState({ clubList: data });
            }).catch(error => {
                console.log(error);
            });
    }

    //#endregion 

    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveUpdateClubs()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    closeOnClickOutside={false}
                    showCancel
                >
                    Do you want to save Club detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { clubList,columns, clubName, clubNameState, clubType, clubFee, clubFeeState, remarks, active, searchCheck, clearTextBtn } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Add Club</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                                                        <InputLabel id="clubTypelabel">Tax Status</InputLabel>
                                                        <Select native labelId="clubTypelabel" id="clubType" label="Club Type" onChange={this.setValue} value={clubType} isSearchable={true} >
                                                            <option value={"Physical Club"}>Physical Club</option>
                                                            <option value={"Non Physical Club"}>Non Physical Club</option>
                                                        </Select>
                                                    </FormControl>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={clubNameState === "success"}
                                                        error={clubNameState === "error"}
                                                        size="small"
                                                        label="Club Name"
                                                        id="clubName"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={clubName}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={clubFeeState === "success"}
                                                        error={clubFeeState === "error"}
                                                        size="small"
                                                        label="Club Fee"
                                                        id="clubFee"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={clubFee}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Remarks"
                                                        id="remarks"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={remarks}
                                                        onChange={this.setValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Club List"}
                                        data={clubList}
                                        columns={columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}

export default withStyles(formStyle)(StdClubs);
