
//Import from Globel Component";
import {
    React,ReactToggle, withStyles, TextField, MuiThemeProvider,Breadcrumbs, NavigateNextIcon, Link, HomeIcon, Typography, GridContainer, GridForm, GridTextbox,
    Card, CardIcon, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint, Moment, PeopleAlt, StdIcon, Autocomplete, FormControl,
    InputLabel, Select, SearchIcon, AllDropDowns
} from '../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class shiftingArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regDataList: [],
            nameorfatherNIC: '',
            studentRegNo: '',
            IsCampus: true,
            IsLevel: true,
            IsClass: true,
            IsSection: true,
            startDate: new Date(), startDateState: '', endDate: new Date(), regStatus: 1,
            alert: null, show: false, generalError: true,
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.setValue = this.setValue.bind(this);
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    searchData = () => {
        var startDate = Moment(this.state.startDate).format('YYYY-MM-DD');
        var endDate = Moment(this.state.endDate).format('YYYY-MM-DD');
        var nameorfatherNIC = -1;
        var studentRegNo = -1;
        if (this.state.nameorfatherNIC !== '') {
            nameorfatherNIC = this.state.nameorfatherNIC;
        }
        if (this.state.studentRegNo !== '') {
            studentRegNo = this.state.studentRegNo;
        }
        fetch(Base_URL + "/api/StudentRegistration/SearchAllRegistration?regStatus=" + this.state.regStatus + "&sessionId=" + this.state.sessionId + "&campusId=" + this.state.campusId
            + "&classId=" + this.state.classId + "&sectionId=" + this.state.sectionId + "&nameorfatherNIC=" + nameorfatherNIC + "&studentRegNo=" + studentRegNo
            + "&startDate=" + startDate + "&endDate=" + endDate
        ).then(response => { return response.json(); })
            .then(data => {
                this.setState({ regDataList: data });
            }).catch(error => {
                console.log(error);
            });
    }
    LoadTextbox = () => {

    }
    //#region Form Body and Return
    render() {

        const { classes } = this.props;
        const { sessionDD, campusDD, campusIdIndex, sessionIdIndex, classIdIndex, campusIdState, classDD, classIdState, sectionDD, sectionIdIndex, studentRegNo, regStatus } = this.state;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <PeopleAlt />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}> Shifting Area</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>

                            <Link to="/app/stdmanagementhome" >Student Management</Link>
                            <Typography color="textPrimary">Shifting Area</Typography>
                        </Breadcrumbs>
                        <hr />
                        <GridContainer>
                            {this.state.alert}
                            <div className={classes.mainDiv}>
                                <CardBody>
                                    <GridContainer>
                                        <GridForm xs={12} sm={6} md={6}>
                                            <Card>
                                                <CardHeader color="info" icon>
                                                    <CardIcon color="success">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Student Shifting From</h4>
                                                    <hr />
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12} lg={12}>
                                                            <AllDropDowns data={this.state} xsm={12} smm={6} mdm={2} lgm={2} />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                            <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                                                                <InputLabel id="genderlabel">Gender</InputLabel>
                                                                <Select native labelId="genderlabel" id="gender" label="Gender" onChange={this.setValue} >
                                                                    <option value={true}>Male</option>
                                                                    <option value={false}>Female</option>
                                                                </Select>
                                                            </FormControl>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={3}>
                                                            <TextField
                                                                size="small"
                                                                label="RegNo/Name"
                                                                id="studentRegNo"
                                                                variant="outlined"
                                                                value={studentRegNo}
                                                                fullWidth
                                                                onChange={this.setValue}
                                                            />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
                                                            <label htmlFor="small-radius-switch">
                                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")} value={regStatus}
                                                                    checked={regStatus}
                                                                    onColor="#3b5998"
                                                                    className={classes.toggleStyle}
                                                                    borderRadius={4}
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                    } />
                                                                <span>Active in Class</span>
                                                            </label>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook" onClick={() => { this.searchData() }}><SearchIcon />Search</Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Students List"}
                                                    columns={[
                                                        { label: "Select" },
                                                        { label: "Session" },
                                                        { label: "Campus" },
                                                        { label: "Class" },
                                                        { label: "Section" },
                                                        { label: "Reg No" },
                                                        { label: "Name" },
                                                        { label: "Active" },
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                        <GridForm xs={12} sm={6} md={6}>
                                            <Card>
                                                <CardHeader color="info" icon>
                                                    <CardIcon color="success">
                                                        <StdIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Student Shifting to</h4>
                                                    <hr />
                                                </CardHeader>
                                                <CardBody>
                                                    <GridContainer>
                                                        <GridTextbox xs={12} sm={12} md={12} lg={12}>
                                                            <AllDropDowns data={this.state} xsm={12} smm={6} mdm={2} lgm={2} />
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} md={4} style={{ textAlign: "center" }}>
                                                            <label htmlFor="small-radius-switch">
                                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "Active")} value={regStatus}
                                                                    checked={regStatus}
                                                                    onColor="#3b5998"
                                                                    className={classes.toggleStyle}
                                                                    borderRadius={4}
                                                                    handleDiameter={22}
                                                                    uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                                    checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                                    } />
                                                                <span> To earlier session </span>
                                                            </label>
                                                        </GridTextbox>
                                                        <GridTextbox xs={12} sm={12} lg={1}>
                                                            <Button color="facebook" onClick={() => { this.searchData() }}>Shift Students </Button>
                                                        </GridTextbox>
                                                    </GridContainer>
                                                </CardBody>
                                            </Card>
                                            <MuiThemeProvider theme={muiWithToolbar()}>
                                                <MUIDataTable
                                                    title={"Students List"}
                                                    columns={[
                                                        { label: "Select" },
                                                        { label: "Session" },
                                                        { label: "Campus" },
                                                        { label: "Class" },
                                                        { label: "Section" },
                                                        { label: "Reg No" },
                                                        { label: "Name" },
                                                        { label: "Active" },
                                                    ]}
                                                    options={tbleWithPrint}
                                                />
                                            </MuiThemeProvider>
                                        </GridForm>
                                    </GridContainer>
                                </CardBody>
                            </div>
                        </GridContainer>
                    </div>
                </Card >
            </div>
        );
    }
    //#endregion
}

export default withStyles(formStyle)(shiftingArea);
