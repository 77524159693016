
//Import from Globel Component";
import {
    React, SweetAlert, ReactToggle, axios, Typography, withStyles, TextField, NavigateNextIcon,
    Breadcrumbs, Autocomplete, GridContainer, GridItem, GridForm, GridTextbox, Card, CardHeader, CardBody, PictureUpload, Button,
    formStyle, Moment, DeleteIcon, GetAppIcon, PropTypes, SaveOutlined, InputMask, validateCheckSetVal, EditOutlined, MuiThemeProvider,
    muiWithToolbar, MUIDataTable, tbleWithPrint
} from '../../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class StudentClubs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clubDD: [{ clubId: -1, clubName: "Select" }],
            clubId: -1,
            clubIdIndex: 0,
            clubIdState: "",
            active: false,
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
    };
    //#region ComponentDidMount
    async componentDidMount() {
        this.clubs();
    }
    clubs() {
        debugger;
        fetch(Base_URL + "/api/StudentRegistration/StudentClub").then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.exception.displayMessage === null && data.exception.exceptionToken === null) {
                    var arr = [{ clubId: -1, clubName: "Select" }];
                    arr = arr.concat(data.studentClubs);
                    //if (this.state.reasonId !== 0 && this.state.reasonId !== null && this.state.reasonId !== -1) {
                    //    this.setState({ reasonDD: arr });
                    //    var ReasonIndex = this.state.reasonDD.findIndex(o => o.cityId === this.state.cityId);
                    //    this.setState({
                    //        reasonIdIndex: ReasonIndex,
                    //        reasonIdState: 'success'
                    //    })
                    //}
                    //else {
                    this.setState({ clubDD: arr, clubId: arr[0]['clubId'] });
                    this.state.clubIdbt = arr[0]['clubId'];
                    /* }*/
                }
                else {
                    var msg = data.exception.exceptionToken + "<br />" + data.excption.displayMessage;
                    this.AlertMessage("Error", msg);
                }

            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    //#region Function
    handleToggleChange = (event, checkName) => {
        debugger;
        this.setState({ [checkName]: event });
    }
    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
    }
    //#endregion
    //#region Button,Validaition
    validateData = () => {
        if (this.state.clubId < 0) {
            this.setState({ clubIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage();
        }
    }
    //#endregion 
    //#region Alert Messages
    ConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title="Are you sure?"
                    onConfirm={() => this.SaveClubs()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    closeOnClickOutside={false}
                    showCancel
                >
                    Do you want to save Profession detail!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { clubIdIndex, clubDD, clubIdState, active } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={6}>
                                <Card>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Student Clubs</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={6}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="clubId"
                                                        size="small"
                                                        options={clubDD}
                                                        getOptionLabel={(option) => option.clubName}
                                                        value={clubDD[clubIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params} success={clubIdState === "success"}
                                                            error={clubIdState === "error"} label="Select Clubs" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={3}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Enable/Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />Save</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            columns={[
                                                { label: "Student Club" }, { label: "Active" }, { label: "Edit" },
                                                { label: "Modifier" }, { label: "Modified Date" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={6}>
                                {this.state.alert}
                                <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                                    <CardHeader color="info" icon>
                                        <h4 className={classes.cardIconTitle}>Pickup Person</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={3}>
                                                    {/*<GridTextbox xs={12} sm={12} md={12}>*/}
                                                    {/*    <Autocomplete*/}
                                                    {/*        disableClearable*/}
                                                    {/*        id="relationId"*/}
                                                    {/*        size="small"*/}
                                                    {/*        options={relationDD}*/}
                                                    {/*        getOptionLabel={(option) => option.relationName}*/}
                                                    {/*        value={relationDD[relationIdIndex]}*/}
                                                    {/*        onChange={(event, value) => { this.setDDValue(event, value) }}*/}
                                                    {/*        renderInput={(params) => <TextField {...params} success={relationIdState === "success"}*/}
                                                    {/*            error={relationIdState === "error"} label="RelationShip" variant="outlined" />}*/}
                                                    {/*    />*/}
                                                    {/*</GridTextbox>*/}
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <TextField
                                                            success={this.state.nameOneState === "success"}
                                                            error={this.state.nameOneState === "error"}
                                                            size="small"
                                                            label={"Name"}
                                                            id="nameOne"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={this.state.nameOne}
                                                            onChange={this.textChange}
                                                        />
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={12}>
                                                        <InputMask
                                                            {...this.props}
                                                            mask="99999-9999999-9"
                                                            maskChar=""
                                                        >
                                                            {() => <TextField
                                                                success={this.state.nicFirstState === "success"}
                                                                error={this.state.nicFirstState === "error"}
                                                                size="small"
                                                                label={"NIC"}
                                                                variant="outlined"
                                                                fullWidth
                                                                //value={this.state.nicFirst}
                                                                id="nicFirst"
                                                                placeholder="00000-0000000-0"
                                                                required={true}
                                                                autoComplete="off"
                                                                inputProps={{
                                                                    onChange: event =>
                                                                        this.CheckAndSetStates(event, "nicFirst"),
                                                                    maxLength: 15,
                                                                }}
                                                            />}
                                                        </InputMask>
                                                    </GridTextbox>
                                                    <GridTextbox xs={12} sm={12} md={2}>
                                                        <Button color="facebook" onClick={(event) => { this.validateData(event) }}
                                                            disabled={this.state.saveUpdateBtnDisabled}>{/*<SaveOutlined />*/} Update </Button>
                                                    </GridTextbox>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6} style={{ marginLeft: "10%" }}>
                                                    <PictureUpload />
                                                    <div style={{ textAlign: "center" }}>
                                                        <Button color="success" ><GetAppIcon />Download</Button>
                                                        <Button color="danger"><DeleteIcon />Remove</Button>
                                                    </div>
                                                </GridItem>
                                                
                                            </GridContainer>
                                        </div>
                                    </CardBody>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            columns={[
                                                { label: "Relation" }, { label: "Name" },
                                                { label: "NIC" }, { label: "Photo" }, { label: "Edit" },
                                                { label: "Modifier" }, { label: "Modified Date" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                </Card>
                            </GridForm>
                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}
StudentClubs.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(formStyle)(StudentClubs);
