import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from '@material-ui/core';
import {
    NavigateNext as NavigateNextIcon,
    Home as HomeIcon,
    MenuBook, Description, Book, Bookmark,
    Details, FormatListNumbered, Help, ContactSupport, ShowChart, FileCopy,
    BookmarkBorder, PersonOutline, PermIdentityOutlined, Report, QuestionAnswer, AccountBalance
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { events } from "variables/general.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { StyledBreadcrumb } from "assets/jss/customStyle/allCustomStyle";
function handleClick(event) {
    alert("Breadcrumb");
}
class EMS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: events
        };
    }
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };
    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <FileCopy />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>EMS</h4>
                    </CardHeader>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Typography color="textPrimary">EMS </Typography>
                        </Breadcrumbs>
                        <hr />

                    </div>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/definebooks"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <MenuBook />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Define </p>
                                                <h3 className={classes.cardTitle}> Books </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <MenuBook />
                                                   Define Books
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/defineterm"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <Description />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Define </p>
                                                <h3 className={classes.cardTitle}>Term</h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Description />
                                                    Define Term
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/defineexam"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <Book />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Define</p>
                                                <h3 className={classes.cardTitle}>Exam </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Book />
                                                 Define Exam
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/definechapter"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="danger" stats icon>
                                                <CardIcon color="danger">
                                                    <Bookmark />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Define </p>
                                                <h3 className={classes.cardTitle}> Chapter </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Bookmark />
                                                   Define Chapter
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/chaptertopics"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="warning" stats icon>
                                                <CardIcon color="warning">
                                                    <BookmarkBorder />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Chapter </p>
                                                <h3 className={classes.cardTitle}>Topics </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <BookmarkBorder />
                                                  Chapter Topics
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/chapterlearning"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <Details />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Chapter  </p>
                                                <h3 className={classes.cardTitle}>Learning </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Details />
                                           Chapter Learning
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/difficultylevels"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="info" stats icon>
                                                <CardIcon color="info">
                                                    <FormatListNumbered />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Difficulty  </p>
                                                <h3 className={classes.cardTitle}>Levels </h3>

                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <FormatListNumbered />
                                          Difficulty Levels
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/questioncategory"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="primary" stats icon>
                                                <CardIcon color="primary">
                                                    <Help />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Question  </p>
                                                <h3 className={classes.cardTitle}>Category </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <Help />
                                           Question Category
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/questiontype"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="rose" stats icon>
                                                <CardIcon color="rose">
                                                    <ContactSupport />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Question  </p>
                                                <h3 className={classes.cardTitle}>Type </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <ContactSupport />
                                           Question Type
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/complexitylevel"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <CardHeader color="success" stats icon>
                                                <CardIcon color="success">
                                                    <ShowChart />
                                                </CardIcon>
                                                <p className={classes.cardCategory}>Complexity</p>
                                                <h3 className={classes.cardTitle}>Level </h3>
                                            </CardHeader>
                                            <CardFooter stats>
                                                <div className={classes.stats}>
                                                    <ShowChart />
                                           Complexity Level
                                                </div>
                                            </CardFooter>
                                        </Card>
                                    </Link>

                                </GridItem>



                            </GridContainer>
                        </CardBody>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Typography color="textPrimary"><FileCopy style={{ marginBottom: "-5px" }} /> Reports</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/studentadministration"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <PersonOutline style={{ color: 'Indigo', fontSize: '70' }} /> </center>
                                            <h3 className={classes.cardTitle}><center>SLO Detailed</center> </h3>
                                            <p className={classes.cardCategory} ><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/studentadministration"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <Report style={{ color: 'Purple', fontSize: '70' }} /> </center>
                                            <h3 className={classes.cardTitle}><center>SLO Summary</center> </h3>
                                            <p className={classes.cardCategory} ><center>Report</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/studentadministration"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <QuestionAnswer style={{ color: 'RedViolet', fontSize: '70' }} /> </center>
                                            <h3 className={classes.cardTitle}><center>Question Bank</center> </h3>
                                            <p className={classes.cardCategory} ><center>  Detailed</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>

                                <GridItem xs={12} sm={6} md={6} lg={3} >
                                    <Link from={"/"} to={"/app/studentadministration"} style={{ textDecoration: 'none' }}>
                                        <Card>
                                            <center> <AccountBalance style={{ color: 'Brown', fontSize: '70' }} /> </center>
                                            <h3 className={classes.cardTitle}><center>Question Bank Summary</center> </h3>
                                            <p className={classes.cardCategory} ><center> Detailed</center></p>
                                        </Card>
                                    </Link>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card >
            </div>
        );
    }
}
EMS.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(EMS);
