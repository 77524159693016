import {
    React, GridContainer, Card, CardHeader, CardBody, Box, Typography, PropTypes, CardIcon, Link, Tab, Breadcrumbs,
    NavigateNextIcon, Tabs, HomeIcon, PermDataSettingOutlined, Note, AccountTree
} from '../../components/Common/ImportAll'
//#region Import Employee Views
import FiscalYear from './PrimaryDataTabs/FiscalYear';
import FeeHeads from './PrimaryDataTabs/FeeHeads';
import FeeConcessions from './PrimaryDataTabs/FeeConcessions';
import FeePackages from './PrimaryDataTabs/FeePackages';
import FeeStructurePackages from './PrimaryDataTabs/FeeStructurePackages';
import FeeStructure from './PrimaryDataTabs/FeeStructure';
import DefineHolidays from './PrimaryDataTabs/DefineHolidays';
import LateFeeFine from './PrimaryDataTabs/LateFeeFine';
import AccountsHeads from './PrimaryDataTabs/AccountsHeads';
import AccountsSubHeads from './PrimaryDataTabs/AccountsSubHeads';
import FeeChallanMonths from './PrimaryDataTabs/FeeChallanMonths';
//////#endregion
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ScrollableTabsButtonAuto() {
    const [selectedTab, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                        <AccountTree />
                    </CardIcon>
                    <h4 style={{ color: "black" }}>Primary Data Accounts</h4>
                    <hr style={{ marginTop: "15px", marginBottom: "4px" }} />
                </CardHeader>
                <div style={{ padding: "5px 15px" }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/accounts" > Accounts</Link>
                        <Typography color="textPrimary">Primary Data Accounts</Typography>
                    </Breadcrumbs>
                    <hr style={{ marginTop: "7px", marginBottom: "0px" }} />
                </div>
                <div style={{ paddingBottom: "7px", paddingRight: "7px", paddingLeft: "7px" }}>
                    <CardBody>
                        <GridContainer>
                            <Tabs value={selectedTab} onChange={handleChange} variant="scrollable"
                                scrollButtons="on" style={{ backgroundColor: "#d4e2f8" }} indicatorColor="primary"
                                textColor="primary" wrapped >
                                <Tab label="Fiscal Year" {...a11yProps(0)} />
                                <Tab label="Fee Heads" {...a11yProps(1)} />
                                <Tab label="Fee Concessions" {...a11yProps(2)} />
                                <Tab label="Fee Packages" {...a11yProps(3)} />
                                <Tab label="Fee Structure Packages" {...a11yProps(4)} />
                                <Tab label="Fee Structure" {...a11yProps(5)} />
                                <Tab label="Define Holidays" {...a11yProps(6)} />
                                <Tab label="Late Fee Fine" {...a11yProps(7)} />
                                <Tab label="Accounts Heads" {...a11yProps(8)} />
                                <Tab label="Accounts Sub Heads" {...a11yProps(9)} />
                                <Tab label="Fee Challan Months" {...a11yProps(10)} />
                            </Tabs>
                            {selectedTab === 0 && <FiscalYear />}
                            {selectedTab === 1 && <FeeHeads />}
                            {selectedTab === 2 && <FeeConcessions />}
                            {selectedTab === 3 && <FeePackages />}
                            {selectedTab === 4 && <FeeStructurePackages />}
                            {selectedTab === 5 && <FeeStructure />}
                            {selectedTab === 6 && <DefineHolidays />}
                            {selectedTab === 7 && <LateFeeFine />}
                            {selectedTab === 8 && <AccountsHeads />}
                            {selectedTab === 9 && <AccountsSubHeads />}
                            {selectedTab === 10 && <FeeChallanMonths />}
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        </div>
    );
}
