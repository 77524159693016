
//#region Views Import for Sidebar (main routes)
import StdManagementHome from "views/StudentManagement/StdManagementHome.jsx";
import Studentmanagement from "views/StudentManagement/Studentmanagement.jsx";
import Transport from "views/Transport/Transport.jsx";
import Accounts from "views/Accounts/Accounts.jsx";
import HRPayroll from "views/HR/HRPayroll.jsx";
import Academic from "views/Academic/Academic.jsx";
import EMS from "views/EMS/EMS.jsx";
import Library from "views/Library/Library.jsx";
import ItManagement from "views/ItManagement/ItManagement.jsx";
import SetupManagement from "views/SetupManagement/SetupManagement.jsx"
import UploadFiles from "views/UploadFile/UploadFiles.jsx";
import Inventory from "views/Inventory/Inventory.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
//#region @material-ui/icons
import {
    CastForEducation, Commute as TransportIcon, SupervisorAccount as HRIcon, LocalLibrary as LibraryIcon
    , School as AcademicIcon, FileCopy as EMSIcon,
    CloudUpload as UplodIcon, Computer as ITIcon,
    Dashboard as DashboardIcon, Widgets as InventoryIcon,
    AccountBalanceWallet as AccountsIcon,
    People as StudentSiblingIcon,


} from "@material-ui/icons";
//#endregion

//#endregion

//#region Views Import Page Routes (proutes)
//#region Transport
import StudentTransport from "views/Transport/StudentTransport.jsx";
import StaffTransport from "views/Transport/StaffTransport.jsx";
import TransportPrimaryData from "views/Transport/TransportPrimaryData.jsx";
//Reports
import Availableseatsinbus from "views/Transport/Reports/Availableseatsinbus.jsx";
import Studentlistbusewisesumary from "views/Transport/Reports/Studentlistbusewisesumary.jsx";
import Studentlistbusewise from "views/Transport/Reports/Studentlistbusewise.jsx";
import Zonelist from "views/Transport/Reports/Zonelist.jsx";
import Studenttransportroutewise from "views/Transport/Reports/Studenttransportroutewise.jsx";
import Employeelistbuswise from "views/Transport/Reports/Employeelistbuswise.jsx";
import Allroutesdetails from "views/Transport/Reports/Allroutesdetails.jsx";
import Allvehicles from "views/Transport/Reports/Allvehicles.jsx";
import Fuelconsumptiondetails from "views/Transport/Reports/Fuelconsumptiondetails.jsx";
import Fuelrates from "views/Transport/Reports/Fuelrates.jsx";
//#endregion

//#region HR
import NewEmployee from "views/HR/NewEmployee.jsx";
import EmployeeApplication from "views/HR/EmployeeApplication.jsx";
import EditEmployee from "views/HR/EditEmployee.jsx";
import EmployeeManagement from "views/HR/EmployeeManagement.jsx";
import ImportAttendance from "views/HR/ImportAttendance.jsx";
import EmployeeAttendance from "views/HR/EmployeeAttendance.jsx";
import AllowanceDeduction from "views/HR/AllowanceDeduction.jsx";
import ImportSalaryDaysHeads from "views/HR/ImportSalaryDaysHeads.jsx";
import AdvancePayment from "views/HR/AdvancePayment.jsx";
import LoanManagement from "views/HR/LoanManagement.jsx";
import CreateSalary from "views/HR/CreateSalary.jsx";
import PostSalary from "views/HR/PostSalary.jsx";
import SalaryPayment from "views/HR/SalaryPayment.jsx";
import EmployeeSupList from "views/HR/EmployeeSupList.jsx";
import EmployeeACRForm from "views/HR/EmployeeACRForm.jsx";
import EmpACRData from "views/HR/EmpACRData.jsx";
import CardBackground from "views/HR/CardBackground.jsx";
import Addnewletter from "views/HR/Addnewletter.jsx";
import SearchLetter from "views/HR/SearchLetter.jsx";
import PrimaryDataHR from "views/HR/PrimaryDataHR.jsx";
import ACRManagement from "views/HR/ACRManagement.jsx";
import LetterManagement from "views/HR/LetterManagement.jsx";

//Reports
import Allemployee from "views/HR/Reports/Employeeprofilereports/Allemployee.jsx";
import departmentdesgination from "views/HR/Reports/Employeeprofilereports/departmentdesgination.jsx";
import Employeebloodgrouplist from "views/HR/Reports/Employeeprofilereports/Employeebloodgrouplist.jsx";
import Employeeeducationlist from "views/HR/Reports/Employeeprofilereports/Employeeeducationlist.jsx";
import Employeephotos from "views/HR/Reports/Employeeprofilereports/Employeephotos.jsx";
import Employeeapplicationlist from "views/HR/Reports/Employeeprofilereports/Employeeapplicationlist.jsx";
import Employeebirthday from "views/HR/Reports/Employeeprofilereports/Employeebirthday.jsx";
import Employeejoiningleaving from "views/HR/Reports/Employeeprofilereports/Employeejoiningleaving.jsx";
import Contractletter from "views/HR/Reports/Employeeprofilereports/Contractletter.jsx";
import Employeeletter from "views/HR/Reports/Employeeprofilereports/Employeeletter.jsx";
import Employeebasicdetail from "views/HR/Reports/Employeeprofilereports/Employeebasicdetail.jsx";
import Employeeedcuationsumary from "views/HR/Reports/Employeeprofilereports/Employeeedcuationsumary.jsx";
import Employeeacrsumary from "views/HR/Reports/Employeeprofilereports/Employeeacrsumary.jsx";
import Employeestatus from "views/HR/Reports/Employeeprofilereports/Employeestatus.jsx";
import Employeechildfeediscount from "views/HR/Reports/Employeeprofilereports/Employeechildfeediscount.jsx";
import Employeeissuecustom from "views/HR/Reports/Employeeprofilereports/Employeeissuecustom.jsx";
import dailyattendance from "views/HR/Reports/Employeeattendancereports/dailyattendance.jsx";
import attendancedetail from "views/HR/Reports/Employeeattendancereports/attendancedetail.jsx";
import attendancesumary from "views/HR/Reports/Employeeattendancereports/attendancesumary.jsx";
import Earlydeparturesumary from "views/HR/Reports/Employeeattendancereports/Earlydeparturesumary.jsx";
import Latearrrival from "views/HR/Reports/Employeeattendancereports/Latearrrival.jsx";
import Employeeleave from "views/HR/Reports/Employeeattendancereports/Employeeleave.jsx";
import Notlogout from "views/HR/Reports/Employeeattendancereports/Notlogout.jsx";
import Employeeabsence from "views/HR/Reports/Employeeattendancereports/Employeeabsence.jsx";
import Notenrolled from "views/HR/Reports/Employeeattendancereports/Notenrolled.jsx";
import Leaveapplied from "views/HR/Reports/Employeeattendancereports/Leaveapplied.jsx";
import Attendancedetailsumary from "views/HR/Reports/Employeeattendancereports/Attendancedetailsumary.jsx";
import Notcheckedout from "views/HR/Reports/Employeeattendancereports/Notcheckedout.jsx";
import Attendancesumarynew from "views/HR/Reports/Employeeattendancereports/Attendancesumarynew.jsx";
import Stafflatearrival from "views/HR/Reports/Employeeattendancereports/Stafflatearrival.jsx";
import Supervisorwaivedoff from "views/HR/Reports/Employeeattendancereports/Supervisorwaivedoff.jsx";
import Employeerubricdeduction from "views/HR/Reports/Employeeattendancereports/Employeerubricdeduction.jsx";
import Last5increment from "views/HR/Reports/Employeeotherreports/Last5increment.jsx";
import Placementhistory from "views/HR/Reports/Employeeotherreports/Placementhistory.jsx";
import Jobperiod from "views/HR/Reports/Employeeotherreports/Jobperiod.jsx";
import Leavedetailsumary from "views/HR/Reports/Employeeotherreports/Leavedetailsumary.jsx";
import Bounusdetails from "views/HR/Reports/Employeeotherreports/Bounusdetails.jsx";
import Benefit from "views/HR/Reports/Employeeotherreports/Benefit.jsx";
import EmployeeLoan from "views/HR/Reports/Employeeotherreports/EmployeeLoan.jsx";
import Healthinsurance from "views/HR/Reports/Employeeotherreports/Healthinsurance.jsx";
import Staffportalloginlog from "views/HR/Reports/Employeeotherreports/Staffportalloginlog.jsx";
import Fuelmobileallowance from "views/HR/Reports/Employeeotherreports/Fuelmobileallowance.jsx";
import Penalitydeduction from "views/HR/Reports/Employeeotherreports/Penalitydeduction.jsx";
import Leavededuction from "views/HR/Reports/Employeeotherreports/Leavededuction.jsx";
import Salaryauditsheet from "views/HR/Reports/Employeeotherreports/Salaryauditsheet.jsx";
import Supervisorleaveapplication from "views/HR/Reports/Employeeotherreports/Supervisorleaveapplication.jsx";
import Leavedetail from "views/HR/Reports/Employeeotherreports/Leavedetail.jsx";
//#endregion

//#region Student Management
//Forms
import AllRegistration from "views/StudentManagement/AllRegistration.jsx";
import StdNewRegistration from "views/StudentManagement/StdNewRegistration.jsx";
import StdEditRegistration from "views/StudentManagement/StdEditRegistration.jsx";
import StudentApplication from "views/StudentManagement/StudentApplication.jsx";
//Reports
import StdPrimaryData from "views/StudentManagement/StdPrimaryData.jsx";
import AdmissionFreeze from "views/StudentManagement/AdmissionFreeze.jsx";
import FeeDiscount from "views/StudentManagement/FeeDiscount.jsx";
import StudentAttendance from "views/StudentManagement/StudentAttendance.jsx";
import ShiftingArea from "views/StudentManagement/ShiftingArea.jsx";
import QuickSearch from "views/StudentManagement/QuickSearch.jsx";
import StudentAdministration from "views/StudentManagement/StudentAdministration.jsx";
import StudentSibling from "views/StudentManagement/Reports/ProfileReports/StudentSibling.jsx";
import BloodGroupWise from "views/StudentManagement/Reports/ProfileReports/BloodGroupWise.jsx";
import FormatLetterWise from "views/StudentManagement/Reports/ProfileReports/FormatLetterWise.jsx";
import StudentPhoto from "views/StudentManagement/Reports/ProfileReports/StudentPhoto.jsx";
import StudentBirthday from "views/StudentManagement/Reports/ProfileReports/StudentBirthday.jsx";
import RegistrationViewer from "views/StudentManagement/Reports/ProfileReports/RegistrationViewer.jsx";
import StudentStrength from "views/StudentManagement/Reports/ProfileReports/StudentStrength.jsx";
import StudentAdmissionWithdrawal from "views/StudentManagement/Reports/ProfileReports/StudentAdmissionWithdrawal.jsx";
import BTSCDiscountEligible from "views/StudentManagement/Reports/ProfileReports/BTSCDiscountEligible.jsx";
import StudentFinalSettlement from "views/StudentManagement/Reports/ProfileReports/StudentFinalSettlement.jsx";
import StudentAttendanceLog from "views/StudentManagement/Reports/ProfileReports/StudentAttendanceLog.jsx";
import StudentHouseList from "views/StudentManagement/Reports/ProfileReports/StudentHouseList.jsx";
import StudentAdmissionFreeze from "views/StudentManagement/Reports/ProfileReports/StudentAdmissionFreeze.jsx";
import StudentLetters from "views/StudentManagement/Reports/ProfileReports/StudentLetters.jsx";
import ClassTeacher from "views/StudentManagement/Reports/ProfileReports/ClassTeacher.jsx";
import StudentStrengthDetail from "views/StudentManagement/Reports/ProfileReports/StudentStrengthDetail.jsx";
import StudentPortalLogin from "views/StudentManagement/Reports/ProfileReports/StudentPortalLogin.jsx";
import StudentProfileEdit from "views/StudentManagement/StudentProfileEdit.jsx";
//import SendEmail from "views/Common/SendEmail.jsx";
//#endregion

//#region Accounts
import ChallanCreation from "views/Accounts/ChallanCreation.jsx";
import MultiFeePayment from "views/Accounts/MultiFeePayment.jsx";
import OtherHeadCharges from "views/Accounts/OtherHeadCharges.jsx";
import FinalSettlement from "views/Accounts/FinalSettlement.jsx";
import FeeAdjustmentForm from "views/Accounts/FeeAdjustmentForm.jsx";
import StudentFeeStructure from "views/Accounts/StudentFeeStructure.jsx";
import BRS from "views/Accounts/BRS.jsx";
import ChequeEditing from "views/Accounts/ChequeEditing.jsx";
import SearchVoucher from "views/Accounts/SearchVoucher.jsx";
import CreateVoucher from "views/Accounts/CreateVoucher.jsx";
import SearchReceipt from "views/Accounts/SearchReceipt.jsx";
import BankPayment from "views/Accounts/BankPayment.jsx";
import BankReceipt from "views/Accounts/BankReceipt.jsx";
import CashPayment from "views/Accounts/CashPayment.jsx";
import CashReceipt from "views/Accounts/CashReceipt.jsx";
import ChallanManagement from "views/Accounts/ChallanManagement.jsx";
import AccountsManagement from "views/Accounts/AccountsManagement.jsx";
import FeeInstallment from "views/Accounts/FeeInstallment.jsx";
import CreateSingleChallan from "views/Accounts/CreateSingleChallan.jsx";
import TaxCalculator from "views/Accounts/TaxCalculator.jsx";
import SettlementStructureEditing from "views/Accounts/SettlementStructureEditing.jsx";
import AuditedTrialBalance from "views/Accounts/AuditedTrialBalance.jsx";
import PrimaryDataAccounts from "views/Accounts/PrimaryDataAccounts.jsx";
import LedgerRights from "views/Accounts/LedgerRights.jsx";
//Reports
import Challanmonthlybalance from "views/Accounts/Reports/Challanmonthlybalance.jsx";
import Missingchallan from "views/Accounts/Reports/Missingchallan.jsx";
import Feechallandetail from "views/Accounts/Reports/Feechallandetail.jsx";
import FeeChallanDetailReport from "views/Accounts/Reports/FeeChallanDetailReport.jsx";
import Admissionvouchernotpaid from "views/Accounts/Reports/Admissionvouchernotpaid.jsx";
import Monthlyfeechallan from "views/Accounts/Reports/Monthlyfeechallan.jsx";
import Latefeefine from "views/Accounts/Reports/Latefeefine.jsx";
import Studentpacakagesdiscount from "views/Accounts/Reports/Studentpacakagesdiscount.jsx";
import Feeadjustmentlog from "views/Accounts/Reports/Feeadjustmentlog.jsx";
import Finalsettlementunpassed from "views/Accounts/Reports/Finalsettlementunpassed.jsx";
import Feepayment from "views/Accounts/Reports/Feepayment.jsx";
import Feecollection from "views/Accounts/Reports/Feecollection.jsx";
import Feecollectionnew from "views/Accounts/Reports/Feecollectionnew.jsx";
import Feedefaulter from "views/Accounts/Reports/Feedefaulter.jsx";
import Feedefaultersumary from "views/Accounts/Reports/Feedefaultersumary.jsx";
import Advancefeepayment from "views/Accounts/Reports/Advancefeepayment.jsx";
import Monthlyfeepayment from "views/Accounts/Reports/Monthlyfeepayment.jsx";
import Dailybankpayment from "views/Accounts/Reports/Dailybankpayment.jsx";
import Securityfeepayment from "views/Accounts/Reports/Securityfeepayment.jsx";
import Allstudentpayementhistory from "views/Accounts/Reports/Allstudentpayementhistory.jsx";
import Discountapproval from "views/Accounts/Reports/Discountapproval.jsx";
import Employeededuction from "views/Accounts/Reports/Employeededuction.jsx";
import Ledger from "views/Accounts/Reports/Ledger.jsx";
import Trialbalance from "views/Accounts/Reports/Trialbalance.jsx";
import Otherfeeheads from "views/Accounts/Reports/Otherfeeheads.jsx";
import Readmission from "views/Accounts/Reports/Readmission.jsx";
import Finalsettlementedit from "views/Accounts/Reports/Finalsettlementedit.jsx";
import Revenue from "views/Accounts/Reports/Revenue.jsx";
import Transactionbook from "views/Accounts/Reports/Transactionbook.jsx";
import Securityfee from "views/Accounts/Reports/Securityfee.jsx";
import Ledgersummary from "views/Accounts/Reports/Ledgersummary.jsx";
import Feebilled from "views/Accounts/Reports/Feebilled.jsx";
import Finalsettlemetremoval from "views/Accounts/Reports/Finalsettlemetremoval.jsx";
import Bankreconciliationstatement from "views/Accounts/Reports/Bankreconciliationstatement.jsx";
import Expense from "views/Accounts/Reports/Expense.jsx";
//#endregion

//#region Library
import NewBook from "views/Library/NewBook.jsx";
import SearchBook from "views/Library/SearchBook.jsx";
import ManageBook from "views/Library/ManageBook.jsx";
import IssueReturn from "views/Library/IssueReturn.jsx";
import IssuedBooks from "views/Library/IssuedBooks.jsx";
import ManageRacks from "views/Library/ManageRacks.jsx";
import ManageStatus from "views/Library/ManageStatus.jsx";
import ManageProductType from "views/Library/ManageProductType.jsx";
import ManageDueDays from "views/Library/ManageDueDays.jsx";
import OverdueEmail from "views/Library/OverdueEmail.jsx";
import OverdueSMS from "views/Library/OverdueSMS.jsx";
import ManageAuthors from "views/Library/ManageAuthors.jsx";
import ManagePublishers from "views/Library/ManagePublishers.jsx";
//#endregion

//#region Academic
import TimeTable from "views/Academic/TimeTable.jsx";
import SubtitleTimeTable from "views/Academic/SubtitleTimeTable.jsx";
import SearchTeacher from "views/Academic/SearchTeacher.jsx";
import DateSheet from "views/Academic/DateSheet.jsx";
import NewResult from "views/Academic/NewResult.jsx";
import StudentRemarks from "views/Academic/StudentRemarks.jsx";
import AllResultReport from "views/Academic/AllResultReport.jsx";
import PreSchoolReport from "views/Academic/PreSchoolReport.jsx";
import SearchResult from "views/Academic/SearchResult.jsx";
import PreSchoolResult from "views/Academic/PreSchoolResult.jsx";
import PreSchoolRemarks from "views/Academic/PreSchoolRemarks.jsx";
import PrimaryDataAcademic from "views/Academic/PrimaryDataAcademic.jsx";
import EventEntry from "views/Academic/EventEntry.jsx";
import Syllabus from "views/Academic/Syllabus.jsx";

//Reports
import Teachertimetablesummary from "views/Academic/Reports/Teachertimetablesummary.jsx";
import Teachermissing from "views/Academic/Reports/Teachermissing.jsx";
import Teachertimetableclasssection from "views/Academic/Reports/Teachertimetableclasssection.jsx";
import Timetable from "views/Academic/Reports/Timetable.jsx";
//#endregion

//#region EMS
import DefineBooks from "views/EMS/DefineBooks.jsx";
import DefineTerm from "views/EMS/DefineTerm.jsx";
import DefineExam from "views/EMS/DefineExam.jsx";
import DefineChapter from "views/EMS/DefineChapter.jsx";
import ChapterTopics from "./views/EMS/ChapterTopics.jsx";
import ChapterLearning from "./views/EMS/ChapterLearning.jsx";
import DifficultyLevels from "./views/EMS/DifficultyLevels.jsx";
import QuestionCategory from "./views/EMS/QuestionCategory.jsx";
import QuestionType from "./views/EMS/QuestionType.jsx";
import ComplexityLevel from "./views/EMS/ComplexityLevel.jsx";
//#endregion

//#region Inventory
import ManageSuppliers from "views/Inventory/ManageSuppliers.jsx";
import ManageCompanies from "views/Inventory/ManageCompanies.jsx";
import ManageCategories from "views/Inventory/ManageCategories.jsx";
import PurchaseOrder from "views/Inventory/PurchaseOrder.jsx";
import ManagePurchases from "views/Inventory/ManagePurchases.jsx";
import IssueInventory from "views/Inventory/IssueInventory.jsx";
import ManageIssuance from "views/Inventory/ManageIssuance.jsx";
import NewItem from "views/Inventory/NewItem.jsx";
import ManageItems from "views/Inventory/ManageItems.jsx";
import Practical from "views/Inventory/Practical.jsx";
import Labracks from "views/Inventory/Labracks.jsx";
//#endregion

//#region IT Management
import AttendanceIPs from "views/ItManagement/AttendanceIPs.jsx";
import UserGroups from "views/ItManagement/UserGroups.jsx";
import UserRights from "views/ItManagement/UserRights.jsx";
import ERPFormLockRights from "views/ItManagement/ERPFormLockRights.jsx";
import SMSInquiry from "views/ItManagement/SMSInquiry.jsx";
import emailsettings from "views/ItManagement/EmailSettings.jsx";
import LMSLimitations from "views/ItManagement/LMSLimitations.jsx";
import LMSIPAddress from "views/ItManagement/LMSIPAddress.jsx";
import AutomatedReports from "views/ItManagement/AutomatedReports.jsx";
/*import ManageForms from "views/ItManagement/ManageForms.jsx";*/
import Utility from "views/ItManagement/Utility.jsx";
import Systemgeneratedemails from "views/ItManagement/Systemgeneratedemails.jsx";
import Exceptions from "views/ItManagement/Exceptions.jsx";
import ExceptionsDataTable from "views/ItManagement/ExceptionsDataTable.jsx";
import primarydatait from "views/ItManagement/Primarydatatabs/primarydatait.jsx";

// Reports
import Allemployeerights from "views/ItManagement/Reports/Allemployeerights.jsx";
import Useroperationlog from "views/ItManagement/Reports/Useroperationlog.jsx";
import Studentportallog from "views/ItManagement/Reports/Studentportallog.jsx";
import Deletionlog from "views/ItManagement/Reports/Deletionlog.jsx";
import Studentrelogin from "views/ItManagement/Reports/Studentrelogin.jsx";
import Allusergrouprights from "views/ItManagement/Reports/Allusergrouprights.jsx";
import Staffportallog from "views/ItManagement/Reports/Staffportallog.jsx";
import Dailyattendancereport from "views/ItManagement/Reports/Dailyattendancereport.jsx";
import Useroperationslog from "views/ItManagement/Reports/Useroperationslog.jsx";
import SMSlog from "views/ItManagement/Reports/SMSlog.jsx";
import SMSsummary from "views/ItManagement/Reports/SMSsummary.jsx";
import SentSMS from "views/ItManagement/Reports/SentSMS.jsx";
import Studentidcardlog from "views/ItManagement/Reports/Studentidcardlog.jsx";
import Employeeidcardlog from "views/ItManagement/Reports/Employeeidcardlog.jsx";
import EmployeessentSMS from "views/ItManagement/Reports/EmployeessentSMS.jsx";
import Emailsendinglog from "views/ItManagement/Reports/Emailsendinglog.jsx";
//#endregion

//#region Setup Management
import PrimaryData from "views/SetupManagement/PrimaryData.jsx";
//#endregion

//#endregion


const dashRoutes = [
    //#region Dashboard
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/app"
    },

    //#endregion

    //#region Student Management
    {
        path: "/stdManagementHome",
        name: "Student Management",
        icon: CastForEducation,
        component: StdManagementHome,
        layout: "/app"
    },

    //#endregion

    //#region Transport Management
    {
        path: "/transport",
        name: "Transport",
        icon: TransportIcon,
        component: Transport,
        layout: "/app"
    },

    //#endregion

    //#region Accounts Management
    {
        path: "/accounts",
        name: "Accounts",
        icon: AccountsIcon,
        component: Accounts,
        layout: "/app"
    },

    //#endregion

    //#region HR & Payroll
    {
        path: "/hrpayroll",
        name: "HR & Payroll",
        icon: HRIcon,
        component: HRPayroll,
        layout: "/app"
    },

    //#endregion

    //#region Library Management
    {
        path: "/library",
        name: "Library",
        icon: LibraryIcon,
        component: Library,
        layout: "/app"
    },

    //#endregion

    //#region Academic Management
    {
        path: "/academic",
        name: "Academic",
        icon: AcademicIcon,
        component: Academic,
        layout: "/app"
    },

    //#endregion

    //#region EMS
    {
        path: "/ems",
        name: "EMS",
        icon: EMSIcon,
        component: EMS,
        layout: "/app"
    },

    //#endregion

    //#region Inventory Management

    {
        path: "/inventory",
        name: "Inventory",
        icon: InventoryIcon,
        component: Inventory,
        layout: "/app"
    },

    //#endregion

    //#region Uploadfile

    {
        path: "/uploadfile",
        name: "Upload Files",
        icon: UplodIcon,
        component: UploadFiles,
        layout: "/app"
    },

    //#endregion

    //#region IT Management

    {
        path: "/itmanagement",
        name: "IT Management",
        icon: ITIcon,
        component: ItManagement,
        layout: "/app"
    },

    //#endregion

    //#region Setup Management
    {
        path: "/setupManagement",
        name: "Setup Management",
        icon: ITIcon,
        component: SetupManagement,
        layout: "/app"
    },

    //#endregion

];

const pRoutes = [
    //#region Transport
    {
        path: "/StudentTransport",
        component: StudentTransport,
        layout: "/app"
    },
    {
        path: "/StaffTransport",
        component: StaffTransport,
        layout: "/app"
    },
    {
        path: "/transportprimarydata",
        component: TransportPrimaryData,
        layout: "/app"
    },
    {
        path: "/Availableseatsinbus",
        component: Availableseatsinbus,
        layout: "/app"
    },
    {
        path: "/Studentlistbusewisesumary",
        component: Studentlistbusewisesumary,
        layout: "/app"
    },
    {
        path: "/Studentlistbusewise",
        component: Studentlistbusewise,
        layout: "/app"
    },
    {
        path: "/Zonelist",
        component: Zonelist,
        layout: "/app"
    },
    {
        path: "/Studenttransportroutewise",
        component: Studenttransportroutewise,
        layout: "/app"
    },
    {
        path: "/Employeelistbuswise",
        component: Employeelistbuswise,
        layout: "/app"
    },
    {
        path: "/Allroutesdetails",
        component: Allroutesdetails,
        layout: "/app"
    },
    {
        path: "/Allvehicles",
        component: Allvehicles,
        layout: "/app"
    },
    {
        path: "/Fuelconsumptiondetails",
        component: Fuelconsumptiondetails,
        layout: "/app"
    },
    {
        path: "/Fuelrates",
        component: Fuelrates,
        layout: "/app"
    },


    //#endregion

    //#region Accounts
    {
        path: "/Challanmonthlybalance",
        component: Challanmonthlybalance,
        layout: "/app"
    },
    {
        path: "/Missingchallan",
        component: Missingchallan,
        layout: "/app"
    },
    {
        path: "/Feechallandetail",
        component: Feechallandetail,
        layout: "/app"
    },
    {
        path: "/Admissionvouchernotpaid",
        component: Admissionvouchernotpaid,
        layout: "/app"
    },
    {
        path: "/Monthlyfeechallan",
        component: Monthlyfeechallan,
        layout: "/app"
    },
    {
        path: "/Latefeefine",
        component: Latefeefine,
        layout: "/app"
    },
    {
        path: "/Studentpacakagesdiscount",
        component: Studentpacakagesdiscount,
        layout: "/app"
    },
    {
        path: "/Feeadjustmentlog",
        component: Feeadjustmentlog,
        layout: "/app"
    },
    {
        path: "/Finalsettlementunpassed",
        component: Finalsettlementunpassed,
        layout: "/app"
    },
    {
        path: "/Feepayment",
        component: Feepayment,
        layout: "/app"
    },
    {
        path: "/Feecollection",
        component: Feecollection,
        layout: "/app"
    },
    {
        path: "/Feecollectionnew",
        component: Feecollectionnew,
        layout: "/app"
    },
    {
        path: "/Feedefaulter",
        component: Feedefaulter,
        layout: "/app"
    },
    {
        path: "/Feedefaultersumary",
        component: Feedefaultersumary,
        layout: "/app"
    },
    {
        path: "/Advancefeepayment",
        component: Advancefeepayment,
        layout: "/app"
    },
    {
        path: "/Monthlyfeepayment",
        component: Monthlyfeepayment,
        layout: "/app"
    },
    {
        path: "/Dailybankpayment",
        component: Dailybankpayment,
        layout: "/app"
    },
    {
        path: "/Securityfeepayment",
        component: Securityfeepayment,
        layout: "/app"
    },
    {
        path: "/Allstudentpayementhistory",
        component: Allstudentpayementhistory,
        layout: "/app"
    },
    {
        path: "/Discountapproval",
        component: Discountapproval,
        layout: "/app"
    },
    {
        path: "/Employeededuction",
        component: Employeededuction,
        layout: "/app"
    },
    {
        path: "/Ledger",
        component: Ledger,
        layout: "/app"
    },
    {
        path: "/Trialbalance",
        component: Trialbalance,
        layout: "/app"
    },
    {
        path: "/Otherfeeheads",
        component: Otherfeeheads,
        layout: "/app"
    },
    {
        path: "/Readmission",
        component: Readmission,
        layout: "/app"
    },
    {
        path: "/Readmission",
        component: Dailybankpayment,
        layout: "/app"
    },
    {
        path: "/Finalsettlementedit",
        component: Finalsettlementedit,
        layout: "/app"
    },
    {
        path: "/Revenue",
        component: Revenue,
        layout: "/app"
    },
    {
        path: "/Transactionbook",
        component: Transactionbook,
        layout: "/app"
    },
    {
        path: "/Securityfee",
        component: Securityfee,
        layout: "/app"
    },
    {
        path: "/Ledgersummary",
        component: Ledgersummary,
        layout: "/app"
    },
    {
        path: "/Feebilled",
        component: Feebilled,
        layout: "/app"
    },
    {
        path: "/Finalsettlemetremoval",
        component: Finalsettlemetremoval,
        layout: "/app"
    },
    {
        path: "/Bankreconciliationstatement",
        component: Bankreconciliationstatement,
        layout: "/app"
    },
    {
        path: "/Expense",
        component: Expense,
        layout: "/app"
    },



    //#endregion

    //#region HR
    {
        path: "/newemployee",
        component: NewEmployee,
        layout: "/app"
    },
    {
        path: "/editemployee",
        component: EditEmployee,
        layout: "/app"
    },
    {
        path: "/employeeapplication",
        component: EmployeeApplication,
        layout: "/app"
    },
    {
        path: "/employeemanagement",
        component: EmployeeManagement,
        layout: "/app"
    },
    {
        path: "/ImportAttendance",
        component: ImportAttendance,
        layout: "/app"
    },
    {
        path: "/EmployeeAttendance",
        component: EmployeeAttendance,
        layout: "/app"
    },
    {
        path: "/AllowanceDeduction",
        component: AllowanceDeduction,
        layout: "/app"
    },
    {
        path: "/AdvancePayment",
        component: AdvancePayment,
        layout: "/app"
    },
    {
        path: "/LoanManagement",
        component: LoanManagement,
        layout: "/app"
    },
    {
        path: "/ImportSalaryDaysHeads",
        component: ImportSalaryDaysHeads,
        layout: "/app"
    },
    {
        path: "/CreateSalary",
        component: CreateSalary,
        layout: "/app"
    },
    {
        path: "/PostSalary",
        component: PostSalary,
        layout: "/app"
    },
    {
        path: "/SalaryPayment",
        component: SalaryPayment,
        layout: "/app"
    },
    {
        path: "/EmployeeSupList",
        component: EmployeeSupList,
        layout: "/app"
    },
    {
        path: "/EmployeeACRForm",
        component: EmployeeACRForm,
        layout: "/app"
    },
    {
        path: "/EmpACRData",
        component: EmpACRData,
        layout: "/app"
    },
    {
        path: "/CardBackground",
        component: CardBackground,
        layout: "/app"
    },
    {
        path: "/Addnewletter",
        component: Addnewletter,
        layout: "/app"
    },
    {
        path: "/SearchLetter",
        component: SearchLetter,
        layout: "/app"
    },
    {
        path: "/PrimaryDataHR",
        component: PrimaryDataHR,
        layout: "/app"
    },
    {
        path: "/ACRManagement",
        component: ACRManagement,
        layout: "/app"
    }, {
        path: "/LetterManagement",
        component: LetterManagement,
        layout: "/app"
    },
    {
        path: "/Allemployee",
        component: Allemployee,
        layout: "/app"
    },
    {
        path: "/departmentdesgination",
        component: departmentdesgination,
        layout: "/app"
    },
    {
        path: "/Employeeeducationlist",
        component: Employeeeducationlist,
        layout: "/app"
    },
    {
        path: "/Employeebloodgrouplist",
        component: Employeebloodgrouplist,
        layout: "/app"
    },
    {
        path: "/Employeephotos",
        component: Employeephotos,
        layout: "/app"
    },
    {
        path: "/Employeeapplicationlist",
        component: Employeeapplicationlist,
        layout: "/app"
    },
    {
        path: "/Employeebirthday",
        component: Employeebirthday,
        layout: "/app"
    },
    {
        path: "/Employeejoiningleaving",
        component: Employeejoiningleaving,
        layout: "/app"
    },
    {
        path: "/Contractletter",
        component: Contractletter,
        layout: "/app"
    },
    {
        path: "/Employeeletter",
        component: Employeeletter,
        layout: "/app"
    },
    {
        path: "/Employeebasicdetail",
        component: Employeebasicdetail,
        layout: "/app"
    },
    {
        path: "/Employeeedcuationsumary",
        component: Employeeedcuationsumary,
        layout: "/app"
    },
    {
        path: "/Employeeacrsumary",
        component: Employeeacrsumary,
        layout: "/app"
    },
    {
        path: "/Employeestatus",
        component: Employeestatus,
        layout: "/app"
    },
    {
        path: "/Employeechildfeediscount",
        component: Employeechildfeediscount,
        layout: "/app"
    },
    {
        path: "/Employeeissuecustom",
        component: Employeeissuecustom,
        layout: "/app"
    },
    {
        path: "/dailyattendance",
        component: dailyattendance,
        layout: "/app"
    },
    {
        path: "/attendancedetail",
        component: attendancedetail,
        layout: "/app"
    },
    {
        path: "/attendancesumary",
        component: attendancesumary,
        layout: "/app"
    },
    {
        path: "/Earlydeparturesumary",
        component: Earlydeparturesumary,
        layout: "/app"
    },
    {
        path: "/Latearrrival",
        component: Latearrrival,
        layout: "/app"
    },
    {
        path: "/Employeeleave",
        component: Employeeleave,
        layout: "/app"
    },
    {
        path: "/Notlogout",
        component: Notlogout,
        layout: "/app"
    },
    {
        path: "/Employeeabsence",
        component: Employeeabsence,
        layout: "/app"
    },
    {
        path: "/Notenrolled",
        component: Notenrolled,
        layout: "/app"
    },
    {
        path: "/Leaveapplied",
        component: Leaveapplied,
        layout: "/app"
    },
    {
        path: "/Attendancedetailsumary",
        component: Attendancedetailsumary,
        layout: "/app"
    },
    {
        path: "/Notcheckedout",
        component: Notcheckedout,
        layout: "/app"
    },
    {
        path: "/Attendancesumarynew",
        component: Attendancesumarynew,
        layout: "/app"
    },
    {
        path: "/Stafflatearrival",
        component: Stafflatearrival,
        layout: "/app"
    },
    {
        path: "/Supervisorwaivedoff",
        component: Supervisorwaivedoff,
        layout: "/app"
    },
    {
        path: "/Employeerubricdeduction",
        component: Employeerubricdeduction,
        layout: "/app"
    },
    {
        path: "/Last5increment",
        component: Last5increment,
        layout: "/app"
    },
    {
        path: "/Placementhistory",
        component: Placementhistory,
        layout: "/app"
    },
    {
        path: "/Jobperiod",
        component: Jobperiod,
        layout: "/app"
    },
    {
        path: "/Leavedetailsumary",
        component: Leavedetailsumary,
        layout: "/app"
    },
    {
        path: "/Bounusdetails",
        component: Bounusdetails,
        layout: "/app"
    },
    {
        path: "/Benefit",
        component: Benefit,
        layout: "/app"
    },
    {
        path: "/EmployeeLoan",
        component: EmployeeLoan,
        layout: "/app"
    },
    {
        path: "/Healthinsurance",
        component: Healthinsurance,
        layout: "/app"
    },
    {
        path: "/Staffportalloginlog",
        component: Staffportalloginlog,
        layout: "/app"
    },
    {
        path: "/Fuelmobileallowance",
        component: Fuelmobileallowance,
        layout: "/app"
    },
    {
        path: "/Penalitydeduction",
        component: Penalitydeduction,
        layout: "/app"
    },
    {
        path: "/Leavededuction",
        component: Leavededuction,
        layout: "/app"
    },
    {
        path: "/Salaryauditsheet",
        component: Salaryauditsheet,
        layout: "/app"
    },
    {
        path: "/Supervisorleaveapplication",
        component: Supervisorleaveapplication,
        layout: "/app"
    },
    {
        path: "/Leavedetail",
        component: Leavedetail,
        layout: "/app"
    },






    //#endregion

    //#region Student Management
    {
        path: "/stdprimarydata",
        component: StdPrimaryData,
        layout: "/app"
    },
    {
        path: "/studentapplication",
        component: StudentApplication,
        layout: "/app"
    },
    {
        path: "/studentmanagement",
        component: Studentmanagement,
        layout: "/app"
    },
    {
        path: "/admissionfreeze",
        component: AdmissionFreeze,
        layout: "/app"
    },
    {
        path: "/feediscount",
        component: FeeDiscount,
        layout: "/app"
    },
    {
        path: "/studentattendance",
        component: StudentAttendance,
        layout: "/app"
    },
    {
        path: "/shiftingarea",
        component: ShiftingArea,
        layout: "/app"
    },
    {
        path: "/quicksearch",
        component: QuickSearch,
        layout: "/app"
    },

    {
        path: "/studentadministration",
        component: StudentAdministration,
        layout: "/app"
    },
    {
        path: "/allregistration",
        component: AllRegistration,
        layout: "/app"
    },
    {
        path: "/stdnewregistration",
        component: StdNewRegistration,
        layout: "/app"
    },
    {
        path: "/stdeditregistration",
        component: StdEditRegistration,
        layout: "/app"
    },

    {
        path: "/studentsibling",
        component: StudentSibling,
        layout: "/app"
    },


    {
        path: "/bloodgroupwise",
        component: BloodGroupWise,
        layout: "/app"
    },

    {
        path: "/formatletterwise",
        component: FormatLetterWise,
        layout: "/app"
    },

    {
        path: "/studentphoto",
        component: StudentPhoto,
        layout: "/app"
    },
    {
        path: "/studentbirthday",
        component: StudentBirthday,
        layout: "/app"
    },
    {
        path: "/registrationviewer",
        component: RegistrationViewer,
        layout: "/app"
    },

    {
        path: "/studentstrength",
        component: StudentStrength,
        layout: "/app"
    },

    {
        path: "/studentadmissionwithdrawal",
        component: StudentAdmissionWithdrawal,
        layout: "/app"
    },
    {
        path: "/btscdiscounteligible",
        component: BTSCDiscountEligible,
        layout: "/app"
    },

    {
        path: "/studentfinalsettlement",
        component: StudentFinalSettlement,
        layout: "/app"
    },
    {
        path: "/studentattendancelog",
        component: StudentAttendanceLog,
        layout: "/app"
    },
    {
        path: "/studenthouselist",
        component: StudentHouseList,
        layout: "/app"
    },
    {
        path: "/studentadmissionfreeze",
        component: StudentAdmissionFreeze,
        layout: "/app"
    },
    {
        path: "/studentletters",
        component: StudentLetters,
        layout: "/app"
    },
    {
        path: "/classteacher",
        component: ClassTeacher,
        layout: "/app"
    },
    {
        path: "/studentstrengthdetail",
        component: StudentStrengthDetail,
        layout: "/app"
    },
    {
        path: "/studentportallogin",
        component: StudentPortalLogin,
        layout: "/app"
    },
    {
        path: "/StudentProfileEdit",
        component: StudentProfileEdit,
        layout: "/app"
    },
    //{
    //    path: "/sendEmail",
    //    component: SendEmail,
    //    layout: "/app"
    //},
    //#endregion

    //#region Accounts
    {
        path: "/ChallanCreation",
        component: ChallanCreation,
        layout: "/app"
    },
    //{
    //    path: "/DetailsReport",
    //    component: DetailsReport,
    //    layout: "/app"
    //},
    {
        path: "/multifeepayment",
        component: MultiFeePayment,
        layout: "/app"
    },
    {
        path: "/otherheadcharges",
        component: OtherHeadCharges,
        layout: "/app"
    },
    {
        path: "/finalsettlement",
        component: FinalSettlement,
        layout: "/app"
    },
    {
        path: "/FeeAdjustmentForm",
        component: FeeAdjustmentForm,
        layout: "/app"
    },
    {
        path: "/studentfeestructure",
        component: StudentFeeStructure,
        layout: "/app"
    },
    {
        path: "/brs",
        component: BRS,
        layout: "/app"
    },
    {
        path: "/chequeediting",
        component: ChequeEditing,
        layout: "/app"
    },
    {
        path: "/searchvoucher",
        component: SearchVoucher,
        layout: "/app"
    },
    {
        path: "/createvoucher",
        component: CreateVoucher,
        layout: "/app"
    },
    {
        path: "/searchreceipt",
        component: SearchReceipt,
        layout: "/app"
    },
    {
        path: "/bankpayment",
        component: BankPayment,
        layout: "/app"
    },
    {
        path: "/bankreceipt",
        component: BankReceipt,
        layout: "/app"
    },
    {
        path: "/cashpayment",
        component: CashPayment,
        layout: "/app"
    },
    {
        path: "/cashreceipt",
        component: CashReceipt,
        layout: "/app"
    },
    {
        path: "/challanmanagement",
        component: ChallanManagement,
        layout: "/app"
    },
    {
        path: "/accountsmanagement",
        component: AccountsManagement,
        layout: "/app"
    },
    {
        path: "/feeinstallment",
        component: FeeInstallment,
        layout: "/app"
    },
    {
        path: "/createsinglechallan",
        component: CreateSingleChallan,
        layout: "/app"
    },
    {
        path: "/taxcalculator",
        component: TaxCalculator,
        layout: "/app"
    },
    {
        path: "/SettlementStructureEditing",
        component: SettlementStructureEditing,
        layout: "/app"
    },
    {
        path: "/AuditedTrialBalance",
        component: AuditedTrialBalance,
        layout: "/app"
    },
    {
        path: "/PrimaryDataAccounts",
        component: PrimaryDataAccounts,
        layout: "/app"
    },
    {
        path: "/ledgerrights",
        component: LedgerRights,
        layout: "/app"
    },

    //#endregion

    //#region Library
    {
        path: "/newbook",
        component: NewBook,
        layout: "/app"
    },
    {
        path: "/searchbook",
        component: SearchBook,
        layout: "/app"
    },
    {
        path: "/managebook",
        component: ManageBook,
        layout: "/app"
    },
    {
        path: "/issuereturn",
        component: IssueReturn,
        layout: "/app"
    },
    {
        path: "/issuedbooks",
        component: IssuedBooks,
        layout: "/app"
    },
    {
        path: "/manageracks",
        component: ManageRacks,
        layout: "/app"
    },
    {
        path: "/managestatus",
        component: ManageStatus,
        layout: "/app"
    },
    {
        path: "/ManageProductType",
        component: ManageProductType,
        layout: "/app"
    },
    {
        path: "/manageduedays",
        component: ManageDueDays,
        layout: "/app"
    },
    {
        path: "/overdueemail",
        component: OverdueEmail,
        layout: "/app"
    },
    {
        path: "/overduesms",
        component: OverdueSMS,
        layout: "/app"
    },
    {
        path: "/manageauthors",
        component: ManageAuthors,
        layout: "/app"
    },
    {
        path: "/managepublishers",
        component: ManagePublishers,
        layout: "/app"
    },
    //#endregion

    //#region Academic
    {
        path: "/timetable",
        component: TimeTable,
        layout: "/app"
    },
    {
        path: "/subtitletimetable",
        component: SubtitleTimeTable,
        layout: "/app"
    },
    {
        path: "/preschoolreoport",
        component: PreSchoolReport,
        layout: "/app"
    },
    {
        path: "/searchteacher",
        component: SearchTeacher,
        layout: "/app"
    },
    {
        path: "/datesheet",
        component: DateSheet,
        layout: "/app"
    },
    {
        path: "/newresult",
        component: NewResult,
        layout: "/app"
    },
    {
        path: "/studentremarks",
        component: StudentRemarks,
        layout: "/app"
    },
    {
        path: "/allresultreport",
        component: AllResultReport,
        layout: "/app"
    },
    {
        path: "/searchresult",
        component: SearchResult,
        layout: "/app"
    },
    {
        path: "/preschoolresult",
        component: PreSchoolResult,
        layout: "/app"
    },
    {
        path: "/preschoolremarks",
        component: PreSchoolRemarks,
        layout: "/app"
    },
    {
        path: "/primarydataacademic",
        component: PrimaryDataAcademic,
        layout: "/app"
    },
    {
        path: "/evententry",
        component: EventEntry,
        layout: "/app"
    },
    {
        path: "/syllabus",
        component: Syllabus,
        layout: "/app"
    },
    {
        path: "/Teachertimetablesummary",
        component: Teachertimetablesummary,
        layout: "/app"
    },
    {
        path: "/Teachermissing",
        component: Teachermissing,
        layout: "/app"
    },
    {
        path: "/Teachertimetableclasssection",
        component: Teachertimetableclasssection,
        layout: "/app"
    }, {
        path: "/Timetable",
        component: Timetable,
        layout: "/app"
    },
    //#endregion

    //#region EMS
    {
        path: "/definebooks",
        component: DefineBooks,
        layout: "/app"
    },
    {
        path: "/defineterm",
        component: DefineTerm,
        layout: "/app"
    },
    {
        path: "/defineexam",
        component: DefineExam,
        layout: "/app"
    },
    {
        path: "/definechapter",
        component: DefineChapter,
        layout: "/app"
    },
    {
        path: "/chaptertopics",
        component: ChapterTopics,
        layout: "/app"
    },
    {
        path: "/chapterlearning",
        component: ChapterLearning,
        layout: "/app"
    },
    {
        path: "/difficultylevels",
        component: DifficultyLevels,
        layout: "/app"
    },
    {
        path: "/questioncategory",
        component: QuestionCategory,
        layout: "/app"
    },
    {
        path: "/questiontype",
        component: QuestionType,
        layout: "/app"
    },
    {
        path: "/complexitylevel",
        component: ComplexityLevel,
        layout: "/app"
    },
    //#endregion

    //#region Inventory
    {
        path: "/managesuppliers",
        component: ManageSuppliers,
        layout: "/app"
    },
    {
        path: "/managecompanies",
        component: ManageCompanies,
        layout: "/app"
    },
    {
        path: "/purchaseorder",
        component: PurchaseOrder,
        layout: "/app"
    },
    {
        path: "/managepurchases",
        component: ManagePurchases,
        layout: "/app"
    },
    {
        path: "/issueinventory",
        component: IssueInventory,
        layout: "/app"
    },
    {
        path: "/manageissuance",
        component: ManageIssuance,
        layout: "/app"
    },
    {
        path: "/managecategories",
        component: ManageCategories,
        layout: "/app"
    },
    {
        path: "/newitem",
        component: NewItem,
        layout: "/app"
    },
    {
        path: "/manageitems",
        component: ManageItems,
        layout: "/app"
    },
    {
        path: "/practical",
        component: Practical,
        layout: "/app"
    },
    {
        path: "/labracks",
        component: Labracks,
        layout: "/app"
    },

    //#endregion

    //#region IT Management
    {
        path: "/attendanceips",
        component: AttendanceIPs,
        layout: "/app"
    },
    {
        path: "/userrights",
        component: UserRights,
        layout: "/app"
    },
    {
        path: "/ERPFormLockRights",
        component: ERPFormLockRights,
        layout: "/app"
    },
    {
        path: "/usergroups",
        component: UserGroups,
        layout: "/app"
    },
    {
        path: "/smsinquiry",
        component: SMSInquiry,
        layout: "/app"
    },
    {
        path: "/emailsettings",
        component: emailsettings,
        layout: "/app"
    },
    {
        path: "/lmslimitations",
        component: LMSLimitations,
        layout: "/app"
    },
    {
        path: "/lmsipaddress",
        component: LMSIPAddress,
        layout: "/app"
    },
    {
        path: "/automatedreports",
        component: AutomatedReports,
        layout: "/app"
    },
    //{
    //    path: "/manageforms",
    //    component: ManageForms,
    //    layout: "/app"
    //},
    {
        path: "/utility",
        component: Utility,
        layout: "/app"
    },
    {
        path: "/Systemgeneratedemails",
        component: Systemgeneratedemails,
        layout: "/app"
    },
    {
        path: "/exceptions",
        component: Exceptions,
        layout: "/app"
    },
    {
        path: "/exceptionsdatatable",
        component: ExceptionsDataTable,
        layout: "/app"
    },
    {
        path: "/primarydatait",
        component: primarydatait,
        layout: "/app"
    },
    {
        path: "/Allemployeerights",
        component: Allemployeerights,
        layout: "/app"
    },
    {
        path: "/Useroperationlog",
        component: Useroperationlog,
        layout: "/app"
    },
    {
        path: "/Studentportallog",
        component: Studentportallog,
        layout: "/app"
    },
    {
        path: "/Deletionlog",
        component: Deletionlog,
        layout: "/app"
    },
    {
        path: "/Studentrelogin",
        component: Studentrelogin,
        layout: "/app"
    },
    {
        path: "/Allusergrouprights",
        component: Allusergrouprights,
        layout: "/app"
    },
    {
        path: "/Staffportallog",
        component: Staffportallog,
        layout: "/app"
    },
    {
        path: "/Dailyattendancereport",
        component: Dailyattendancereport,
        layout: "/app"
    },
    {
        path: "/Useroperationslog",
        component: Useroperationslog,
        layout: "/app"
    },
    {
        path: "/SMSlog",
        component: SMSlog,
        layout: "/app"
    },
    {
        path: "/SMSsummary",
        component: SMSsummary,
        layout: "/app"
    },
    {
        path: "/SentSMS",
        component: SentSMS,
        layout: "/app"
    },
    {
        path: "/Studentidcardlog",
        component: Studentidcardlog,
        layout: "/app"
    },
    {
        path: "/Employeeidcardlog",
        component: Employeeidcardlog,
        layout: "/app"
    },
    {
        path: "/EmployeessentSMS",
        component: EmployeessentSMS,
        layout: "/app"
    },
    {
        path: "/Emailsendinglog",
        component: Emailsendinglog,
        layout: "/app"
    },

    //#endregion

    //#region Setup Management
    {
        path: "/primarydata",
        component: PrimaryData,
        layout: "/app"
    },
    //#endregion


];
export { dashRoutes, pRoutes }
