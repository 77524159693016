import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined, GridContainer, GridForm,
    GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint, DeleteIcon
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;

class HrShifts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shiftId:"",
            shiftName: "",
            shiftNameState: "",
            timeIn: "",
            timeInStste: "",
            timeOut: "",
            timeOutState:"",
            fridayTimeIn: "",
            fridayTimeInState: "",
            fridayTimeOut: "",
            fridayTimeOutState:"",
            saturdayTimeIn: "",
            saturdayTimeInState: "",
            saturdayTimeOut: "",
            saturdayTimeOutState:"",


            hrLevelArr: [],
            departmentId: 0,
            departmentName: "",
            departmentNameState: "",
            departmentUrduName: "",
            searchLevelId: 0,
            saveLevelId: 0,
            saveLevelIdState: "",
            saveLevelIndex: 0,
            hrlevelDD: [{ hrLevelId: 0, hrLevelName: "Select" }],
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            activeDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            //#region Datatable Columns and Data
            hRShiftsData: [],
            AssignshiftDataList: [],
            //#region Datatable Columns
            columns: [{
                name: "shiftName",
                label: "Shift",
                options: {
                    filter: true,
                }
            }, {
                name: "timeIn",
                label: "In",
                options: {
                    filter: true,
                }
            }, {
                name: "timeOut",
                label: "Out",
                options: {
                    filter: true,
                }
                },
                {
                    name: "fridayTimeIn",
                    label: "Firday In",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "fridayTimeOut",
                    label: "Friday Out",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "saturdayTimeIn",
                    label: "Saturday In",
                    options: {
                        filter: true,
                    }
                },
                {
                    name: "saturdayTimeOut",
                    label: "Saturday Out",
                    options: {
                        filter: true,
                    }
                },
                {
                name: "shiftId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
                },
                {
                    name: "active",
                    label: "Active",
                    options: {
                        filter: true,
                        customBodyRender: (value) => {
                            return (value) === true ? "Yes" : (value) === false ? "No" : "";
                        }
                    }
                },
                     {
                    name: "shiftId",
                    label: "Delete",
                    options: {
                        customBodyRender: (value) => {
                            return (<DeleteIcon color="rose" onClick={() => this.confirmMessageDelete(value)} />);
                        }
                    }
                }
            ],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadHRShifts = this.loadHRShifts.bind(this);
    }
    async componentDidMount() {
      this.loadHRShifts();
    }
    loadHRShifts = () => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/HRShifts")
            .then(response => { return response.json() })
            .then(data => {
                this.setState({
                    hRShiftsData: data.hrShiftsList
                })
            })
            .catch(error => { console.log(error) });
    }
    handleChange(value, type) {
        debugger
        switch (type) {
            case "searchLevelId":
                if (value !== null) {
                    debugger
                    this.setState({ searchLevelId: value.hrLevelId });
                } else {
                    this.setState({ searchLevelId: 0 });
                }
                break;
            case "saveLevelId":
                if (value !== null) {
                    this.setState({
                        saveLevelId: value.hrLevelId,
                        saveLevelIdState: "success",
                        saveLevelIndex: this.state.hrlevelDD.findIndex(x => x.hrLevelId === value.hrLevelId)
                    });
                } else {
                    this.setState({
                        saveLevelId: 0,
                        saveLevelIdState: "error",
                        saveLevelIndex: -1
                    });
                }
                break;
            default:
                break;
        }
    };
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        debugger
        switch (type) {
            case "shiftName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({
                        [stateName + "State"]: "success",
                        shiftName: event.target.value
                    });
                } else {
                    this.setState({
                        [stateName + "State"]: "error",
                        shiftName: event.target.value
                    });
                }
                break;
            case "timeIn":
                this.setState({
                    timeInStste: "success",
                    timeIn: event.target.value,
                });
                break;
            case "timeOut":
                this.setState({
                    timeOutState: "success",
                    timeOut: event.target.value,
                });
                break;
            case "fridayTimeIn":
                this.setState({
                    fridayTimeInState: "success",
                    fridayTimeIn: event.target.value,
                });
                break;
            case "fridayTimeOut":
                this.setState({
                    fridayTimeOutState: "success",
                    fridayTimeOut: event.target.value,
                });
                break;
            case "saturdayTimeIn":
                this.setState({
                    saturdayTimeInState: "success",
                    saturdayTimeIn: event.target.value,
                });
                break;
            case "saturdayTimeOut":
                this.setState({
                    saturdayTimeOutState: "success",
                    saturdayTimeOut: event.target.value,
                });
                break;
            default:
                break;
        }
    }
    isValidated = () => {
        debugger
        if (this.state.shiftName === "" || this.state.shiftNameState === "")
        {
            this.setState({ shiftNameState: "error" });
            return;
        }
        else if (this.state.timeIn === "" || this.state.timeInStste === "") {
            this.setState({ timeInStste: "error" });
            return;
        }
        else if (this.state.timeOut === "" || this.state.timeOutState === "") {
            this.setState({ timeOutState: "error" });
            return;
        }
        else if (this.state.fridayTimeIn === "" || this.state.fridayTimeInState === "") {
            this.setState({ fridayTimeInState: "error" });
            return;
        }
        else if (this.state.fridayTimeOut === "" || this.state.fridayTimeOutState === "") {
            this.setState({ fridayTimeOutState: "error" });
            return;
        }
        else if (this.state.saturdayTimeIn === "" || this.state.fridayTimeInState === "") {
            this.setState({ fridayTimeInState: "error" });
            return;
        }
        else if (this.state.saturdayTimeOut === "" || this.state.saturdayTimeOutState === "") {
            this.setState({ saturdayTimeOutState: "error" });
            return;
        }
        if (this.state.shiftId === "" || this.state.shiftId === 0) {
            this.save();
        }
        else {
            this.confirmMessageUpdate();
        }
           
        }

    save = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ShiftName: this.state.shiftName, TimeIn: this.state.timeIn, TimeOut: this.state.timeOut, FridayTimeIn: this.state.fridayTimeIn, FridayTimeOut: this.state.fridayTimeOut, SaturdayTimeIn: this.state.saturdayTimeIn, SaturdayTimeOut: this.state.saturdayTimeOut, Active: this.state.active })
        };
        console.log(requestOptions);
        fetch(Base_URL + "/api/primaryDataHR/HRShifts", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    Delete = (value) => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/DeleteHRShifts?ShiftId=" + value)
            .then(response => { return response.json() })
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            })
            .catch(error => { console.log(error) });
        this.hideAlert();
    }
    update = () => {

        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ShiftId: this.state.shiftId, ShiftName: this.state.shiftName, TimeIn: this.state.timeIn, TimeOut: this.state.timeOut, FridayTimeIn: this.state.fridayTimeIn, FridayTimeOut: this.state.fridayTimeOut, SaturdayTimeIn: this.state.saturdayTimeIn, SaturdayTimeOut: this.state.saturdayTimeOut, Active: this.state.active })
        };
        console.log(requestOptions);
        fetch(Base_URL + "/api/primaryDataHR/UpdateHRShifts", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new department will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.update()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Department will be updated!
                </SweetAlert>
            )
        });
    }
    confirmMessageDelete(value) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-170px" }}
                    title="Are you sure you want to delete It?"
                    onConfirm={() => this.Delete(value)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes !"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    Shift will be Deleted!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}
                    </SweetAlert>
                )
            });
        }
        else if (status === "Assign") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}
                    </SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion
    verifyNumber(value) {
        debugger
        var mobileRex = /^[0-9]+$/;
        if (mobileRex.test(value)) {
            return true;
        }
        return false;
    }
    LoadTextbox = (shiftId) => {
        debugger
        this.AssignShiftData(shiftId);
        let obj = this.state.hRShiftsData.find(o => o.shiftId === shiftId);
        if (obj != null && obj != undefined && obj != "") {
            obj.timeIn = this.setTheValue(obj.timeIn);
            obj.timeOut = this.setTheValue(obj.timeOut);
            obj.fridayTimeIn = this.setTheValue(obj.fridayTimeIn);
            obj.fridayTimeOut = this.setTheValue(obj.fridayTimeOut);
            obj.saturdayTimeIn = this.setTheValue(obj.saturdayTimeIn);
            obj.saturdayTimeOut = this.setTheValue(obj.saturdayTimeOut);
            this.setState({ shiftId: obj.shiftId, shiftName: obj.shiftName, timeIn: this.convertTime12to24(obj.timeIn), timeOut: this.convertTime12to24(obj.timeOut), fridayTimeIn: this.convertTime12to24(obj.fridayTimeIn), fridayTimeOut: this.convertTime12to24(obj.fridayTimeOut), saturdayTimeIn: this.convertTime12to24(obj.saturdayTimeIn), saturdayTimeOut: this.convertTime12to24(obj.saturdayTimeOut), active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
        }
        else {
            this.SuccessMessage('Alert', 'Highlighted field is InActive');
        }
    }
    setTheValue = (value) => {
        let str = value;
        let stringToAdd = " ";
        if (value.length <= 6) {
            value = this.addStr(str, 4, stringToAdd);
        }
        else {
            value = this.addStr(str, 5, stringToAdd);
        }
        return value;
    }
    addStr = (str, index, stringToAdd) => {
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }
    padLeadingZeros = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}
convertTime12to24 = (time12h) => {
    let str = time12h;
    const myArr = str.split(":");
    if (myArr[0] != "12") {
        var AddZero = this.padLeadingZeros(myArr[0], 2);
        time12h = AddZero + ':' + myArr[1];
    }
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }
    AssignShiftData = (value) => {
        debugger;
        fetch(Base_URL + "/api/primaryDataHR/AssignShiftData?ShiftId=" + value)
            .then(response => { return response.json() })
            .then(data => {
                debugger;
                this.setState({
                    AssignshiftDataList: data.hrShiftAssignList
                })
                if (data.hrShiftAssignList.length > 0) {
                    this.setState({
                        activeDisabled: true,
                    });
                }
                else {
                    this.setState({
                        activeDisabled: false,
                    });
                }
            })
            .catch(error => { console.log(error) });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, shiftId: "", shiftName: "", timeIn: "", timeOut: "", fridayTimeIn: "", fridayTimeOut: "", saturdayTimeIn: "", saturdayTimeOut: "", active: true, activeDisabled: false ,saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none'
        });
       this.loadHRShifts();
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Add Shifts</h4>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.shiftNameState === "success"}
                                                    error={this.state.shiftNameState === "error"}
                                                    value={this.state.shiftName}
                                                    size="small"
                                                    label="Shift Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "shiftName", "shiftName", 3), type: "text" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.timeInStste === "success"}
                                                    error={this.state.timeInStste === "error"}
                                                    value={this.state.timeIn}
                                                    size="small"
                                                    label="Time In"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "timeIn", "timeIn"), type: "time" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.timeOutState === "success"}
                                                    error={this.state.timeOutState === "error"}
                                                    value={this.state.timeOut}
                                                    size="small"
                                                    label="Time out"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "timeOut", "timeOut"), type: "time" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.fridayTimeInState === "success"}
                                                    error={this.state.fridayTimeInState === "error"}
                                                    value={this.state.fridayTimeIn}
                                                    size="small"
                                                    label="Friday Time In"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "fridayTimeIn", "fridayTimeIn", 3), type: "time" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.fridayTimeOutState === "success"}
                                                    error={this.state.fridayTimeOutState === "error"}
                                                    value={this.state.fridayTimeOut}
                                                    size="small"
                                                    label="Friday Time out"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "fridayTimeOut", "fridayTimeOut"), type: "time" }}
                                                />
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.saturdayTimeInState === "success"}
                                                    error={this.state.saturdayTimeInState === "error"}
                                                    value={this.state.saturdayTimeIn}
                                                    size="small"
                                                    label="Saturday Time In"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "saturdayTimeIn", "saturdayTimeIn"), type: "time" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={6} md={2}>
                                                <TextField
                                                    success={this.state.saturdayTimeOutState === "success"}
                                                    error={this.state.saturdayTimeOutState === "error"}
                                                    value={this.state.saturdayTimeOut}
                                                    size="small"
                                                    label="Saturday Time out"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    inputProps={{ onChange: event => this.change(event, "saturdayTimeOut", "saturdayTimeOut", 3), type: "time" }}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        disabled={this.state.activeDisabled}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                {this.state.alert}
                                                <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> Add </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                    </CardBody>
                                </Card>

                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"HRShifts"}
                                        data={this.state.hRShiftsData}
                                        columns={this.state.columns}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(HrShifts);