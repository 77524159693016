//Import from Globel Component";
import {
    React, Moment, Link, withStyles, Typography, Autocomplete, TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardIcon, CardBody, MUIDataTable,Button, formStyle, muiWithToolbar, tbleWithPrint,  EditOutlined,  GridItem, swal, Edit,NavigateNext, Home, AssignmentIndOutlined
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;
class EmployeeApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ApplicationDataList:[],
            sessionId:'',
            campusDD: [{ campusId: -1, campusName: 'Select' }],
            campusIdIndex: 0,
            campusId: 0,
            campusIdState: "",
            hrLevelDD: [{ hrLevelId: -1, hrLevelName: 'All' }],
            hrLevelIdIndex: 0,
            hrLevelId: -1,
            hrLevelIdState: "",
            applicationStatusDD: [{ applicationStatusId: -1, applicationStatus: "Select" }],
            applicationStatusId: 0,
            applicationStatusIdIndex: 0,
            applicationStatusIdState: "",
            fromDateState: "",
            fromDate: Moment(new Date()).format('YYYY-MM-DD'),
            toDateState: "",
            toDate: Moment(new Date()).format('YYYY-MM-DD'),
        };
    }
    async componentDidMount() {
        this.LoadCampuses();
        this.LoadSubLevel();
        this.LoadApplicationStatus();
    } 
    async LoadCampuses() {
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ campusId: -1, campusName: 'All' });
                allList = allList.concat(data.campusDDList);
                this.setState({
                    campusDD: allList,
                    campusId: allList[0]['campusId']
                });
                //this.state.campusId = allList[0]['campusId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSubLevel() {
        fetch(Base_URL + "/api/Dropdowns/HRLevel")
            .then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ hrLevelId: -1, hrLevelName: 'All' });
                allList = allList.concat(data.hrLevelDDList);
                this.setState({
                    hrLevelDD: allList,
                    hrLevelId: allList[0]['hrLevelId'],
                    hrLevelIdIndex: 0
                });
                //this.setState.hrLevelId = data[0]['hrLevelId']
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadApplicationStatus() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/ApplicationStatusDD")
            .then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ applicationStatusId: -1, applicationStatus: "All" });
                allList = allList.concat(data.hrAppStatusDDList);
                this.setState({
                    applicationStatusDD: allList,
                    applicationStatusId: allList[0]['applicationStatusId'],
                    applicationStatusIdIndex: 0
                });
            }).catch(error => {
                console.log(error);
            });
        //try {
        //    const response = await (fetch(Base_URL + "/api/Dropdowns/ApplicationStatusDD"))
        //    if (response.ok) {
        //        const data = await response.json();
        //        this.setState({
        //            applicationStatusDD: data.hRAppStatusDDList
        //        });
        //        if (data.hRAppStatusDDList.length > 0) {
        //            this.setState({
        //                applicationStatusIdIndex: 0,
        //                applicationStatusId: data.hRAppStatusDDList[0].applicationStatusId,
        //                applicationStatusIdState: "success"
        //            });
        //        }
        //        else {
        //            this.setState({
        //                applicationStatusId: 0,
        //                applicationStatusIdIndex: -1,
        //                applicationStatusIdState: ""
        //            })
        //        }
        //    }
        //    else {
        //        throw Error(response.statusText)
        //    }
        //}
        //catch (error) {
        //    console.error(error);
        //}
    }
    async SaveOfferLetterData() {
        try {
            swal("Comming soon");
        
        }
        catch (error) {
            console.error(error);
        }
    }
    LoadData = () => {
        fetch(Base_URL + "/api/EmployeeApplication/LoadEmployeeApplicationData?CampusId=" + this.state.campusId + "&LevelId=" + this.state.hrLevelId + " &ApplicationStatusId=" + this.state.applicationStatusId + " &FromDate=" + this.state.fromDate + " &ToDate=" + this.state.toDate + " ")
            .then(response => {
                return response.json();
            })
            .then(data => {
                this.setState({
                    ApplicationDataList: data.employeeApplicationList
                });
            })
            .catch(error => {
                console.log(error);
            });
    }
    setDDValue = (event, value) => {
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
        if (dropdownName === 'campusDD') {
            this.state.campusId = value['campusId'];
            this.LoadSubLevel();
        }
        else if (dropdownName == 'hrLevelDD') {
            this.state.hrLevelId = value['hrLevelId'];
        }
        else if (dropdownName == 'applicationStatusDD') {
            this.state.applicationStatusId = value['applicationStatusId'];
        }
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "fromDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        fromDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
                //if (event.target.value != null) {
                //    this.setState({
                //        [stateName + "State"]: "success",
                //        enrollmentDate: event.target.value,
                //        stateName: event.target.value
                //    });
                //}
                //else {
                //    this.setState({
                //        [stateName + "State"]: "error",
                //        stateName: "",
                //    });
                //}
                break;
            case "toDate":
                if (event.target.value != null) {
                    this.setState({
                        [stateName + "State"]: "success",
                        toDate: event.target.value,
                        stateName: event.target.value
                    });
                }
                else {
                    this.setState({
                        [stateName + "State"]: "error",
                        stateName: "",
                    });
                }
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    render() {
        const { classes } = this.props;
        const { ApplicationDataList } = this.state;
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />

                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="success" icon>
                        <CardIcon color="success">
                            <AssignmentIndOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Employee Application</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb"
                            style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                            <Link to="/app/dashboard" ><Home style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/HRPayroll" >HR & Payroll</Link>
                            <Typography color="textPrimary">Employee Application</Typography>
                        </Breadcrumbs>
                        <hr />
                        <CardBody>
                           <GridContainer>
                              <div className={classes.mainDiv}>
                                 <GridContainer>
                                    <GridForm xs={12} sm={12} md={12}>
                                       <Card style={{ marginTop: "0px" }}>
                                          <CardHeader>
                                                    <h4 className={classes.cardIconTitle}>Employees Application Detail</h4>
                                                </CardHeader>
                                          <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="campusId"
                                                        size="small"
                                                        options={this.state.campusDD}
                                                        getOptionLabel={(option) => option.campusName}
                                                        value={this.state.campusDD[this.state.campusIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.campusIdState === "success"}
                                                            error={this.state.campusIdState === "error"}
                                                            label="Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="hrLevelId"
                                                        size="small"
                                                        options={this.state.hrLevelDD}
                                                        getOptionLabel={(option) => option.hrLevelName}
                                                        value={this.state.hrLevelDD[this.state.hrLevelIdIndex]}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                        renderInput={(params) => <TextField {...params}
                                                            success={this.state.hrLevelIdState === "success"}
                                                            error={this.state.hrLevelIdState === "error"}
                                                            label="Sub Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        size="small"
                                                        options={this.state.applicationStatusDD}
                                                        getOptionLabel={(option) => option.applicationStatus}
                                                        id="applicationStatusId"
                                                        value={this.state.applicationStatusDD[this.state.applicationStatusIdIndex]}
                                                        disableClearable={true}
                                                        onChange={(event, value) => { this.setDDValue(event, value) }}
                                                       // onChange={(event, value) => this.change(event, "applicationStatusId", "applicationStatusId", value)}
                                                        renderInput={(params) => <TextField {...params} label="Status" variant="outlined"
                                                            success={this.state.applicationStatusIdState === "success"}
                                                            error={this.state.applicationStatusIdState === "error"} />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.fromDateState === "success"}
                                                        error={this.state.fromDateState === "error"}
                                                        size="small"
                                                        label="Recieved From"
                                                        id="fromDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.fromDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "fromDate", "fromDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={this.state.toDateState === "success"}
                                                        error={this.state.toDateState === "error"}
                                                        size="small"
                                                        label="Recieved To"
                                                        id="toDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(this.state.toDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        inputProps={{ onChange: event => this.change(event, "toDate", "toDate") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Button color="facebook" onClick={() => { this.LoadData() }} disabled={this.state.saveUpdateBtnDisabled}> Search </Button>
                                                </GridTextbox>
                                                </GridContainer>
                                           </CardBody>
                                       </Card>
                                    </GridForm>
                                      <GridItem xs={12}>
                                        <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Employees Application List"}
                                            data={ApplicationDataList}
                                            columns={[
                                                { name: "regNo", label: "EmployeeId" },
                                                { name: "name", label: "Name" },
                                                { name: "campusName", label: "Sector" },
                                                { name: "hrLevelName", label: "SubSector" },
                                                { name: "departmentName", label: "Department" },
                                                { name: "designationName", label: "Designation" },
                                                { name: "joiningDate", label: "JoiningDate" },
                                                { name: "applicationStatus", label: "ApplicationStatus" },
                                                {
                                                    name: "employeeId",
                                                    label: "",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (<Button color="facebook" onClick={() => { this.SaveOfferLetterData() }} >Letter</Button>);
                                                        }
                                                    }
                                                },
                                                {
                                                    name: "employeeId",
                                                    label: "Edit",
                                                    options: {
                                                        customBodyRender: (value) => {
                                                            return (<Link to={{ pathname: '/app/EditEmployee', empIdinfo: value }}><EditOutlined /></Link>)
                                                            
                                                        }
                                                    }
                                                },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                      </GridItem>
                                 </GridContainer>
                             </div>
                           </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(formStyle)(EmployeeApplication);
