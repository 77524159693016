import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, EditOutlined, SaveOutlined,
    GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    axios, SearchSharp, Autocomplete, DeleteIcon
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class Groups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none', employeeDD: [{ employeeId: 0, name: 'None' }], employeeId: 0, employeeIdState: '', employeeIndex: 0,
            alert: null, show: false, checked: false, searchCheck: true, sessionDD: [{ sessionId: -1, sessionName: 'Select' }], campusDD: [{ campusId: -1, campusName: 'Select' }], sessionIndex: 0, sessionId: 0,
            sessionIdState: '', campusIndex: 0, campusId: 0, campusIdState: '', searchCampusIndex: 0, searchCampusLoadId: 0, searchCampusIdState: '',
            groupsList: [], levelDD: [{ levelId: -1, levelName: 'Select' }], levelId: -1, levelIdState: '', levelIndex: 0, active: true, classId: 0, classIdState: '', classIndex: 0,
            classDD: [{ classId: -1, className: 'Select' }], sectionDD: [{ sectionId: -1, sectionName: 'Select' }], sectionId: 0, sectionIdState: '',
            sectionIndex: 0, Id: -1, searchSessionIndex: 0, searchSessionId: 0, searchSessionIdState: '',
            ctSessionIndex: -1, ctSessionId: 0, ctSessionIdState: '', ctCampusIndex: -1, ctCampusId: 0, ctCampusIdState: '', cfSessionIndex: -1,
            cfSessionId: 0, cfSessionIdState: '', cfCampusIndex: -1, cfCampusId: 0, cfCampusIdState: '',
            copyButtonDisabled: false, sessionDropdown: [{ sessionId: -1, sessionName: 'All' }], levelDropDown: [{ levelId: -1, levelName: 'Select' }],
            campusDropDown: [{ campusId: -1, campusName: 'All' }], searchCampusId: 0, not: false, saveCampusId: 0, saveLevelId: 0
            //#endregion
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.validateData = this.validateData.bind(this);
        this.ConfirmMessage = this.ConfirmMessage.bind(this);
        this.validateCopy = this.validateCopy.bind(this);
        this.DeleteData = this.DeleteData.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.getdropdowns();
    };
    //#region Dropdowns
    componentDidMount() {
        this.loadCampusesDropdown();
    }
    handleChange(value, type) {
        debugger;
        switch (type) {
            case "campusId":
                if (value !== null) {
                    //debugger;
                    this.setState({
                        campusId: value.campusId,
                        campusIdState: "success",
                        campusIndex: this.state.campusDD.findIndex(x => x.campusId === value.campusId)
                    });
                } else {
                    this.setState({ campusId: -1, campusName: 'Select' });
                }
                break;
            case "levelId":
                if (value !== null) {
                    this.setState({
                        levelId: value.levelId,
                        levelIdState: "success",
                        levelIndex: this.state.levelDD.findIndex(x => x.levelId === value.levelId)
                    });
                    this.LoadClasses(value.levelId);
                } else {
                    this.setState({ levelId: -1, levelName: 'Select' });
                }
                break;
            case "classId":
                if (value !== null) {
                    this.setState({
                        classId: value.classId,
                        classIdState: "success",
                        classIndex: this.state.classDD.findIndex(x => x.classId === value.classId)
                    });
                } else {
                    this.setState({ classId: -1, className: 'Select' });
                }
                break;
            default:
                break;
        }
    };
    //Save
    LoadClasses(levelId) {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/Class?LevelId=" + levelId).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.length <= 0) {
                   // this.state.classDD = [{ classId: -1, className: 'Select' }];
                   this.setState({ classDD :[{ classId: -1, className: 'Select' }]})
                }
                else {
                    //this.state.classDD = data;
                    //this.state.classId= data[0]['classId']
                   this.setState({ classDD: data, classId: data[0]['classId'] })
                    
                }
            }).catch(error => {
                console.log(error);
            });
    }
    getdropdowns = () => {
        fetch(Base_URL + "/api/Dropdowns/EmployeeDD").then(response => { return response.json(); })
            .then(data => {
                let newList = [];
                newList = this.state.employeeDD;
                newList = newList.concat(data);
                this.setState({ employeeDD: newList, employeeId: newList[0]['employeeId'] });
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                this.state.sessionDD = data;
                this.state.searchSessionId = data[0]['sessionId'];
                this.state.sessionId = data[0]['sessionId'];
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                this.state.campusDD = data;
                this.state.campusId = data[0]['campusId'];
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/LevelDD").then(response => { return response.json(); })
            .then(data => {
                if (data.length <= 0) {
                    this.state.levelDD = data;
                    this.state.levelId = 0;
                    this.this.LoadClasses(0);
                }
                else {
                    this.state.levelDD = data;
                    this.state.levelId = data[0]['levelId'];
                    this.LoadClasses(this.state.levelId);
                }
            }).catch(error => {
                console.log(error);
            });
        fetch(Base_URL + "/api/Dropdowns/SectionDD").then(response => { return response.json(); })
            .then(data => {
                this.state.sectionDD = data;
                this.state.sectionId = data[0]['sectionId'];
            }).catch(error => {
                console.log(error);
            });
    }
    //load data dropdown
    loadCampusesDropdown() {
        debugger;
        fetch(Base_URL + "/api/Dropdowns/Campuses").then(response => { return response.json(); })
            .then(data => {
                let allList = [];
                allList.push({ campusId: -1, campusName: 'All' });
                allList = allList.concat(data);
                this.setState({ campusDropDown: allList, searchCampusLoadId: allList[0]['campusId'], searchCampusId: allList[0]['campusId'] });
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadClassesAfterLoad(levelId) {
        await fetch(Base_URL + "/api/Dropdowns/Class?LevelId=" + levelId).then(response => { return response.json(); })
            .then(data => {
                debugger;
                if (data.length <= 0) {
                   this.state.classDD = [{ classId: -1, className: 'Select' }];
                }
                else {
                   this.state.classDD = data;
                }
            }).catch(error => {
                console.log(error);
            });
    }
    //#endregion
    //#region Button,Validaition
    validateData = () => {
        if (this.state.sessionId === 0) {
            this.setState({ sessionIdState: "error" });
            return;
        }
        else if (this.state.campusId === 0) {
            this.setState({ campusIdState: "error" });
            return;
        }
        else if (this.state.classId === 0) {
            this.setState({ classIdState: "error" });
            return;
        }
        else if (this.state.sectionId === 0) {
            this.setState({ sectionIdState: "error" });
            return;
        }
        else if (this.state.employeeId === "") {
            this.setState({ employeeIdState: "error" });
            return;
        }
        else if (this.state.levelId === 0) {
            this.setState({ levelIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage('Save Data', 'Do you want to save Group Detail?', 0);
        }
    }
    validateCopy = () => {
        debugger;
        if (this.state.cfSessionId === 0) {
            this.setState({ cfSessionIdState: "error" });
            return;
        }
        else if (this.state.cfSessionId === this.state.ctSessionId) {
            this.setState({ cfSessionIdState: "error", ctSessionIdState: "error" });
            this.SuccessMessage('Same Session!', 'Can not copy data from same session.!')
            return;
        }
        else if (this.state.cfSessionId > this.state.ctSessionId) {
            this.setState({ cfSessionIdState: "error", ctSessionIdState: "error" });
            this.SuccessMessage('Previous Session!', 'Can not copy data to Previous Session.!')
            return;
        }
        else if (this.state.ctSessionId === 0) {
            this.setState({ ctSessionIdState: "error" });
            return;
        }
        else if (this.state.cfCampusId === 0) {
            this.setState({ cfCampusIdState: "error" });
            return;
        }
        else if (this.state.ctCampusId === 0) {
            this.setState({ ctCampusIdState: "error" });
            return;
        }
        else {
            this.ConfirmMessage('Copy Data', 'Do you want to Copy data from Selected session and campus?', 0);
        }
    }
    CopyGroupData = () => {
        debugger;
        this.hideAlert();
        this.setState({ copyButtonDisabled: true });
        axios.post(Base_URL + "/StudentPrimaryData/CopyGroupData?SessionIdFrom=" + this.state.cfSessionId + "&CampusIdFrom=" + this.state.cfCampusId + "&SessionIdTo=" + this.state.ctSessionId + "&CampusIdTo=" + this.state.ctCampusId).then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    async LoadTextbox(id) {
        this.state.not = true;
        let obj = this.state.groupsList.find(o => o.id === id);
        this.setState({
            Id: id, sessionId: obj.sessionCode, campusId: obj.campusId, classId: obj.classId, sectionId: obj.sectionCode, employeeId: obj.classTeacherId, levelId: obj.levelId,
            active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block'
        })
        this.setState({ sessionIndex: this.state.sessionDD.findIndex(x => x.sessionId === obj.sessionCode) });
        this.setState({ campusIndex: this.state.campusDD.findIndex(x => x.campusId === obj.campusId) });
        this.setState({ levelIndex: this.state.levelDD.findIndex(x => x.levelId === obj.levelId) });
        if (obj.levelId !== null) {
            await this.LoadClassesAfterLoad(obj.levelId);
            this.setState({ classIndex: this.state.classDD.findIndex(x => x.classId === obj.classId) });
        }
        this.setState({ sectionIndex: this.state.sectionDD.findIndex(x => x.sectionId === obj.sectionCode) });
        if (obj.classTeacherId !== null) {
            this.setState({ employeeIndex: this.state.employeeDD.findIndex(x => x.employeeId === obj.classTeacherId) });
        }
        else {
            this.setState({ employeeIndex: 0, employeeId: 0 });
        }
    }
    ClearTextfieldStates = () => {
        this.state.not = false;
        this.setState({
            employeeId: 0, employeeIdState: '', employeeIndex: -1,
            sessionIndex: -1, sessionId: 0, sessionIdState: '', campusIndex: -1, campusId: 0, campusIdState: '',
            levelId: 0, levelIdState: '', levelIndex: -1, active: false, classId: 0, classIdState: '', classIndex: -1,
            sectionId: 0, sectionIdState: '', sectionIndex: -1, Id: -1,
            active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.hideAlert();
        this.LoadData();
    }
    setValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    //#endregion 
    //#region Backend Data Fetching,Saving and Deleting
    LoadData = () => {
        fetch(Base_URL + "/StudentPrimaryData/LoadGroups?searchCheck=" + this.state.searchCheck + "&sessionId=" + this.state.searchSessionId + "&campusId=" + this.state.searchCampusId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ groupsList: data });
            }).catch(error => {
                console.log(error);
            });
    }
    SaveUpdateGroups = () => {
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const formData = {
            Id: this.state.Id,
            SectionCode: this.state.sectionId,
            ClassId: this.state.classId,
            SessionCode: this.state.sessionId,
            CampusId: this.state.campusId,
            ClassTeacherId: this.state.employeeId,
            Active: this.state.active,
        }
        axios.post(Base_URL + "/StudentPrimaryData/SaveUpdateGroups", formData, {}).then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    DeleteData = (GroupId) => {
        axios.post(Base_URL + "/StudentPrimaryData/deleteGroups?GroupId=" + GroupId).then(json => {
            this.SuccessMessage(json.data.status, json.data.message)
        })
    }
    //#endregion 
    //#region Alert Messages
    confirmDelete = (GroupId) => {
        if (GroupId > 0) {
            this.ConfirmMessage('Delete Data', 'Do you want to Delete data?', GroupId);
        }
    }
    ConfirmMessage(title, msg, id) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-200px" }}
                    title={title}
                    onConfirm={() => (title) === 'Save Data' ? this.SaveUpdateGroups() : (title) === 'Copy Data' ? this.CopyGroupData() : (title) === 'Delete Data' ? this.DeleteData(id) : this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    {msg}
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, msg) {
        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false, copyButtonDisabled: false,
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false, copyButtonDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion
    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { sessionDropdown, groupsList, not, classDD, classIndex, classIdState, sectionDD, sectionIndex, campusDropDown, sectionIdState, levelDD, sessionDD, sessionIndex, sessionIdState, searchSessionIndex, searchSessionIdState, campusIdState, searchCampusIdState, searchCampusIndex, campusDD, employeeDD, employeeIdState, employeeIndex, campusIndex, levelIdState, levelIndex, active, searchCheck, clearTextBtn } = this.state;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="searchSessionId"
                                                    size="small"
                                                    options={sessionDD}
                                                    getOptionLabel={(option) => option.sessionName}
                                                    value={sessionDD[searchSessionIndex]}
                                                    onChange={(event, value) => { this.setState({ searchSessionId: value.sessionId, searchSessionIdState: "success", searchSessionIndex: sessionDD.findIndex(x => x.sessionId === value.sessionId) }) }}
                                                    renderInput={(params) => <TextField {...params} success={searchSessionIdState === "success"}
                                                        error={searchSessionIdState === "error"} label="Session" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="searchCampusId"
                                                    size="small"
                                                    options={campusDropDown}
                                                    getOptionLabel={(option) => option.campusName}
                                                    value={campusDropDown[searchCampusIndex]}
                                                    onChange={(event, value) => { this.setState({ searchCampusId: value.campusId, searchCampusIdState: "success", searchCampusIndex: campusDropDown.findIndex(x => x.campusId === value.campusId) }) }}
                                                    renderInput={(params) => <TextField {...params} success={searchCampusIdState === "success"}
                                                        error={searchCampusIdState === "error"} label="Campus" variant="outlined" />}
                                                />
                                            </GridTextbox>

                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                                <label htmlFor="small-radius-switch">
                                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchCheck")} checked={searchCheck} onColor="#3b5998" className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                                    <span> Active</span>
                                                </label>
                                            </GridTextbox>
                                            <GridTextbox>
                                                <Button color="facebook" onClick={this.LoadData}> <SearchSharp /> Load </Button>
                                            </GridTextbox>
                                        </GridContainer>
                                        <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                        <h4 className={classes.cardIconTitle}>Class Teacher Gruops</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <form>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="sessionId"
                                                        size="small"
                                                        options={sessionDD}
                                                        getOptionLabel={(option) => option.sessionName}
                                                        value={sessionDD[sessionIndex]}
                                                        onChange={(event, value) => { this.setState({ sessionId: value.sessionId, sessionIdState: "success", sessionIndex: sessionDD.findIndex(x => x.sessionId === value.sessionId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={sessionIdState === "success"}
                                                            error={sessionIdState === "error"} label="Session" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="campusId"
                                                        size="small"
                                                        options={campusDD}
                                                        getOptionLabel={(option) => option.campusName}
                                                        value={campusDD[campusIndex]}
                                                        onChange={(event, value) => this.handleChange(value, "campusId")}
                                                        //onChange={(event, value) => { this.state.campusId = value.campusId, this.state.campusIdState="success", this.state.campusIndex=campusDD.findIndex(x => x.campusId === value.campusId), this.LoadLevels(this.state.campusId) }}
                                                        renderInput={(params) => <TextField {...params} success={campusIdState === "success"}
                                                            error={campusIdState === "error"} label="Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>

                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="levelId"
                                                        size="small"
                                                        options={levelDD}
                                                        getOptionLabel={(option) => option.levelName}
                                                        value={levelDD[levelIndex]}
                                                        disabled={not}
                                                        onChange={(event, value) => this.handleChange(value, "levelId")}
                                                        renderInput={(params) => <TextField {...params} success={levelIdState === "success"}
                                                            error={levelIdState === "error"} label="Level Name" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="classId"
                                                        size="small"
                                                        options={classDD}
                                                        getOptionLabel={(option) => option.className}
                                                        value={classDD[classIndex]}
                                                        onChange={(event, value) => this.handleChange(value, "classId")}
                                                        renderInput={(params) => <TextField {...params} success={classIdState === "success"}
                                                            error={classIdState === "error"} label="Class" variant="outlined" />}
                                                    />
                                                </GridTextbox>

                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="sectionId"
                                                        size="small"
                                                        options={sectionDD}
                                                        getOptionLabel={(option) => option.sectionName}
                                                        value={sectionDD[sectionIndex]}
                                                        onChange={(event, value) => { this.setState({ sectionId: value.sectionId, sectionIdState: "success", sectionIndex: sectionDD.findIndex(x => x.sectionId === value.sectionId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={sectionIdState === "success"}
                                                            error={sectionIdState === "error"} label="Section" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        disableClearable
                                                        id="employeeId"
                                                        size="small"
                                                        options={employeeDD}
                                                        getOptionLabel={(option) => option.name}
                                                        value={employeeDD[employeeIndex]}
                                                        onChange={(event, value) => { this.setState({ employeeId: value.employeeId, employeeIdState: "success", employeeIndex: employeeDD.findIndex(x => x.employeeId === value.employeeId) }) }}
                                                        renderInput={(params) => <TextField {...params} success={employeeIdState === "success"}
                                                            error={employeeIdState === "error"} label="Teacher" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={2}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")} checked={active} onColor="#3b5998" className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> Active</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="facebook" onClick={this.validateData} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined />{this.state.saveUpdateBtnText}</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1}>
                                                    <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + clearTextBtn + '' }}>Add New</Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        title={"Groups List"}
                                        data={groupsList}
                                        columns={[
                                            { name: "sessionName", label: "Session" },
                                            { name: "campusName", label: "Campus" },
                                            { name: "className", label: "Class" },
                                            { name: "sectionName", label: "Section" },
                                            {
                                                name: "teacherName", label: "Class Teacher",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return value ? value : "None";
                                                    }
                                                }
                                            },
                                            {
                                                name: "levelName",
                                                label: "Level"
                                            },
                                            {
                                                name: "active", label: "Active",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                                                    }
                                                }
                                            },
                                            {
                                                name: "id",
                                                label: "Edit",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return (<EditOutlined onClick={() => { this.LoadTextbox(value) }} />);
                                                    }
                                                }
                                            },
                                            {
                                                name: "id",
                                                label: "Delete",
                                                options: {
                                                    customBodyRender: (value) => {
                                                        return (<DeleteIcon color="rose" onClick={() => { this.confirmDelete(value) }} />);
                                                    }
                                                }
                                            }
                                        ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                            </GridForm>

                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <h4 className={classes.cardIconTitle}>Copy Gruops Detail</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <CardHeader>
                                                <h4 className={classes.cardIconTitle}>Copy From:</h4>
                                            </CardHeader>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="cfSessionId"
                                                    size="small"
                                                    options={sessionDD}
                                                    getOptionLabel={(option) => option.sessionName}
                                                    value={sessionDD[this.state.cfSessionIndex]}
                                                    onChange={(event, value) => { this.setState({ cfSessionId: value.sessionId, cfSessionIdState: "success", cfSessionIndex: sessionDD.findIndex(x => x.sessionId === value.sessionId) }) }}
                                                    renderInput={(params) => <TextField {...params} success={this.state.cfSessionIdState === "success"}
                                                        error={this.state.cfSessionIdState === "error"} label="Session" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="cfCampusId"
                                                    size="small"
                                                    options={campusDD}
                                                    getOptionLabel={(option) => option.campusName}
                                                    value={campusDD[this.state.cfCampusIndex]}
                                                    onChange={(event, value) => { this.setState({ cfCampusId: value.campusId, cfCampusIdState: "success", cfCampusIndex: campusDD.findIndex(x => x.campusId === value.campusId) }) }}
                                                    renderInput={(params) => <TextField {...params} success={this.state.cfCampusIdState === "success"}
                                                        error={this.state.cfCampusIdState === "error"} label="Campus" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <CardHeader>
                                                <h4 className={classes.cardIconTitle}>Copy To:</h4>
                                            </CardHeader>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="ctSessionId"
                                                    size="small"
                                                    options={sessionDD}
                                                    getOptionLabel={(option) => option.sessionName}
                                                    value={sessionDD[this.state.ctSessionIndex]}
                                                    onChange={(event, value) => { this.setState({ ctSessionId: value.sessionId, ctSessionIdState: "success", ctSessionIndex: sessionDD.findIndex(x => x.sessionId === value.sessionId) }) }}
                                                    renderInput={(params) => <TextField {...params} success={this.state.ctSessionIdState === "success"}
                                                        error={this.state.ctSessionIdState === "error"} label="Session" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    id="ctCampusId"
                                                    size="small"
                                                    options={campusDD}
                                                    getOptionLabel={(option) => option.campusName}
                                                    value={campusDD[this.state.ctCampusIndex]}
                                                    onChange={(event, value) => { this.setState({ ctCampusId: value.campusId, ctCampusIdState: "success", ctCampusIndex: campusDD.findIndex(x => x.campusId === value.campusId) }) }}
                                                    renderInput={(params) => <TextField {...params} success={this.state.ctCampusIdState === "success"}
                                                        error={this.state.ctCampusIdState === "error"} label="Campus" variant="outlined" />}
                                                />
                                            </GridTextbox>
                                            <GridTextbox xs={12} sm={12} md={1}>
                                                <Button color="facebook" onClick={() => this.validateCopy()} disabled={this.state.copyButtonDisabled}> <SearchSharp /> Copy </Button>
                                            </GridTextbox>

                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridForm>

                        </GridContainer>

                    </CardBody>
                </div>
            </GridContainer>

        );
    }
    //#endregion
}
export default withStyles(formStyle)(Groups);
