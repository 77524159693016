import React from "react";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
import ReactToggle from "react-switch";
import axios from 'axios';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
// @material-ui/core components
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons
import { RecentActorsIcon, NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import ViewArrayIcon from '@material-ui/icons/ViewArray';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { formStyle } from "assets/jss/customStyle/allCustomStyle";
import Moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import Checkbox from '@material-ui/core/Checkbox';
import Cities from "../../SetupManagement/PrimaryDataTabs/Cities";
import UserGroups from "../../ItManagement/UserGroups";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { muiWithToolbar, tbleWithPrint } from "assets/jss/customStyle/allCustomStyle";


var Base_URL = localStorage.BaseURL;
class MonthPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            smsInquiryList: [],
            //  #region Dropdown
            sessionDD: [{ sessionId: -1, sessionName: 'Select' }],
            sessionIdIndex: 0,
            sessionId: 0,
            sessionIdState: '',
            sectorDD: [{ sectorId: -1, sectorName: 'Select' }],
            sectorIdIndex: 0,
            sectorId: 0,
            sectorIdState: '',
            classDD: [{ classId: -1, className: 'Select' }],
            classIdIndex: 0,
            classId: 0,
            classIdState: '',
            sectionDD: [{ sectionId: -1, sectionName: 'Select' }],
            sectionIdIndex: 0,
            sectionId: 0,
            sectionIdState: '',
            levelDD: [{ levelId: -1, levelName: 'Select' }],
            levelIdIndex: 0,
            levelId: 0,
            levelIdState: '',
            networkDD: [{ networkId: -1, networkName: "Select" }],
            networkId: 0,
            networkIdIndex: -1,
            networkIdState: "",
            //#endregion
            medicalproblem: true,
            chronicalmedicalproblem: true,
        };
    }
    async componentDidMount() {
        this.LoadNetworks();
        this.LoadSessionDD();
    }
    async LoadSessionDD() {
        fetch(Base_URL + "/api/Dropdowns/SessionDD").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sessionDD: data, sessionId: data[0]['sessionId'] });
                this.state.sessionId = data[0]['sessionId'];
                this.LoadSectors();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSectors() {
        fetch(Base_URL + "/api/Dropdowns/BehriaTownSectors").then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectorDD: data, sectorId: data[0]['sectorId'] });
                this.state.sectorId = data[0]['sectorId'];
                this.LoadSubSectors();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSubSectors() {
        /*    debugger;*/
        fetch(Base_URL + "/api/Dropdowns/LevelDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.sectorId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ levelDD: data, levelId: data[0]['levelId'] });
                this.setState.levelId = data[0]['levelId']
                this.LoadClasses();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadClasses() {
        /* debugger;*/
        fetch(Base_URL + "/api/Dropdowns/ClassDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.sectorId + "&LevelId=" + this.state.levelId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ classDD: data, classId: data[0]['classId'] });
                this.state.classId = data[0]['classId'];
                this.LoadSections();
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadSections() {
        /*   debugger;*/
        fetch(Base_URL + "/api/Dropdowns/SectionDD?SessionId=" + this.state.sessionId + "&CampusId=" + this.state.sectorId + "&ClassId=" + this.state.classId + "&LevelId=" + this.state.levelId).then(response => { return response.json(); })
            .then(data => {
                this.setState({ sectionDD: data, sectionId: data[0]['sectionId'] });
                this.state.sectionId = data[0]['sectionId'];
            }).catch(error => {
                console.log(error);
            });
    }
    async LoadNetworks() {
        debugger;
        fetch(Base_URL + "/api/EditStudentProfile/NetworkList").then(response => { return response.json(); })
            .then(data => {
                debugger;
                this.setState({ networkDD: data, networkId: data[0]['networkId'] });
            }).catch(error => {
                console.log(error);
            });
    }


    confirmAutoFill(status, message) {
        debugger
        if (status === "Duplicate") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status + "Employee"}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        cancelBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.danger
                        }
                        confirmBtnText="Do you Want to Autofill the Form?"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        Duplicate NIC found.
                    </SweetAlert>
                ),
                nicBlackListed: ""
            });
        }
        else if (status === "Blacklisted") {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                    >{message}</SweetAlert>
                ),
                nicState: "error",
                nicBlackListed: "Black listed Employee"
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    ShowHide = () => {
        this.setState({
            clearTextBtn: 'none',
        });
    }
    setDDValue = (event, value) => {
        /*        debugger;*/
        const stateName = event.target.id.split("-")[0];
        const dropdownName = stateName.slice(0, -2) + "DD";
        var newIndex = this.state[dropdownName].findIndex(x => x[stateName] === value[stateName]);
        this.setState({ [stateName]: value[stateName], [stateName + "Index"]: newIndex, [stateName + "State"]: "success" });
        if (dropdownName === 'sessionDD') {
            this.state.sessionId = value['sessionId'];
            this.LoadSectors();
        }
        else if (dropdownName === 'sectorDD') {
            this.state.sectorId = value['sectorId'];
            this.LoadSubSectors();
        }
        else if (dropdownName == 'levelDD') {
            this.state.levelId = value['levelId'];
            this.LoadClasses();
        }
        else if (dropdownName == 'classDD') {
            this.state.classId = value['classId'];
            this.LoadSections();
        }
        else if (dropdownName == 'sectionDD') {
            this.state.sectionId = value['sectionId'];
        }
    }


    render() {
        const { classes } = this.props;
        const { networkDD, networkId, networkName, networkIdIndex, networkIdState, medicalproblem, chronicalmedicalproblem
        } = this.state;
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
               
                <GridContainer>
                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }} className={classes.mainDiv}>
                        <CardBody>
                            <Card>
                                <CardHeader color="success" icon>
                                    <div style={{ padding: "5px 15px" }}>
                                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                                    </div>
                                    <div style={{ padding: "5px 15px" }}>
                                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" style={{ backgroundColor: "#f5f5f5", paddingBottom: "5px", paddingTop: "5px", paddingLeft: "5px" }}>
                                            <Typography color="textPrimary">Fee Month Plan</Typography>
                                        </Breadcrumbs>

                                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                                    </div>
                                </CardHeader>
                                <GridContainer>
                                    <div style={{ paddingBottom: "15px", paddingRight: "15px", paddingLeft: "15px" }} className={classes.mainDiv}>
                                        <CardBody>
                                            <GridContainer>
                            <GridItem xs={12} sm={12} md={ 4}>
                            <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                <label htmlFor="small-radius-switch">
                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                        handleDiameter={22} className={classes.toggleStyle}
                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                        } />
                                    <span> September</span>
                                </label>
                                </GridTextbox>
                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                <label htmlFor="small-radius-switch">
                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                        handleDiameter={22} className={classes.toggleStyle}
                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                        } />
                                    <span> October</span>
                                </label>
                                </GridTextbox>
                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                <label htmlFor="small-radius-switch">
                                    <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                        handleDiameter={22} className={classes.toggleStyle}
                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                        } />
                                    <span> November</span>
                                </label>
                                </GridTextbox>
                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                    <label htmlFor="small-radius-switch">
                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                            handleDiameter={22} className={classes.toggleStyle}
                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                            } />
                                        <span> December</span>
                                    </label>
                                </GridTextbox>
                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                    <label htmlFor="small-radius-switch">
                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                            handleDiameter={22} className={classes.toggleStyle}
                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                            } />
                                        <span> January</span>
                                    </label>
                                </GridTextbox>
                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                    <label htmlFor="small-radius-switch">
                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                            handleDiameter={22} className={classes.toggleStyle}
                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                            } />
                                        <span> Feburary</span>
                                    </label>
                                </GridTextbox>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> March</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> April</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> May</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> June</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> July</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={6} lg={6}>
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "chronicalmedicalproblem")} checked={chronicalmedicalproblem} onColor="#3b5998" borderRadius={4}
                                                            handleDiameter={22} className={classes.toggleStyle}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                            } />
                                                        <span> August</span>
                                                    </label>
                                                </GridTextbox>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={4}>
                                                    <GridTextbox xs={12} sm={12} md={6}>
                                                        <Autocomplete
                                                            disableClearable
                                                            id="networkId"
                                                            size="small"
                                                            options={networkDD}
                                                            getOptionLabel={(option) => option.networkName}
                                                            value={networkDD[networkIdIndex]}
                                                            onChange={(event, value) => { this.setDDValue(event, value) }}
                                                            renderInput={(params) => <TextField {...params} success={networkIdState === "success"}
                                                                error={networkIdState === "error"} label="Fee Months" variant="outlined" />}
                                                        />
                                                    </GridTextbox>
                                                    </GridItem>
                                                </GridContainer>
                                        </CardBody>
                                    </div>
                                    </GridContainer>
                                </Card>
                            </CardBody>
                    </div>
                </GridContainer>
            </Card>
        );
    }
}
MonthPlan.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(formStyle)(MonthPlan);