import {
    React, SweetAlert, ReactToggle, withStyles, TextField, MuiThemeProvider, Edit, EditOutlined, SaveOutlined, SearchSharp,
    AddIcon, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardBody, Button, MUIDataTable, formStyle, muiWithToolbar, tbleWithPrint,
    Moment
} from '../../../components/Common/ImportAll'
var Base_URL = localStorage.BaseURL;
class SchoolCity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolCityId: 0,
            schoolCityName: "",
            schoolCityNameState: "",
            active: true,
            alert: null,
            saveUpdateBtnText: 'Save',
            saveUpdateBtnDisabled: false,
            clearTextBtn: 'none',
            searchActive: true,
            //#region Datatable Columns and Data
            schoolCitiesData: [],
            //#region Datatable Columns
            columns: [{
                name: "schoolCityName",
                label: "School City",
                options: {
                    filter: true,
                }
            }, {
                name: "active",
                label: "Active",
                options: {
                    filter: true,
                    customBodyRender: (value) => {
                        return (value) === true ? "Yes" : (value) === false ? "No" : "";
                    }
                }
            }, {
                name: "modifier",
                label: "Modifier",
                options: {
                    filter: true,
                }
            }, {
                name: "modifiedDate",
                label: "Modified Date",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (Moment(value).format('DD-MMM-YYYY, hh:mm A'));
                    }
                }
            }, {
                name: "schoolCityId",
                label: "Edit",
                options: {
                    customBodyRender: (value) => {
                        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                    }
                }
            }],
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.loadSchoolCities = this.loadSchoolCities.bind(this);
    }
    loadSchoolCities = () => {
            fetch(Base_URL + "/api/primaryData/SchoolCities?Active=" + this.state.searchActive).then(response => { return response.json(); })
            .then(data => {
                this.setState({ schoolCitiesData: data.schoolCitiesList });
            }).catch(error => {
                console.log(error);
            });
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }
    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "schoolCityName":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "State"]: "success" });
                    this.setState({ stateName: event.target.value });
                } else {
                    this.setState({ [stateName + "State"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }
    isValidated = () => {
        debugger
        if (this.state.schoolCityNameState === "success") {
            if (this.state.schoolCityId === 0) {
                this.confirmMessageSave();
            }
            else {
                this.confirmMessageUpdate();
            }
        } else {
            if (this.state.schoolCityNameState !== "success") {
                this.setState({ schoolCityNameState: "error" });
            }
        }
    }
    save = () => {
        debugger
        this.hideAlert();
        this.setState({ saveUpdateBtnDisabled: true });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                schoolCityId: this.state.schoolCityId, 
                schoolCityName: this.state.schoolCityName, 
                Active: this.state.active })
        };
        fetch(Base_URL + "/api/primaryData/SchoolCities", requestOptions)
            .then(response => response.json())
            .then(data => {
                this.SuccessMessage(data.status, data.message)
            }
            );
    }
    //#region Alert Messages
    confirmMessageSave() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    A new School City will be added!
                </SweetAlert>
            )
        });
    }
    confirmMessageUpdate() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.save()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, save it!"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    School City will be updated!
                </SweetAlert>
            )
        });
    }
    SuccessMessage(status, message) {
        if (status === "Success") {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside = {false}
                    >{message}</SweetAlert>
                )
            });
            
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside = {false}
                    >{message}</SweetAlert>
                ), saveUpdateBtnDisabled: false
            });
        }
    }
    hideAlert() {
        this.setState({
            alert: null
        });
    }
    //#endregion

    LoadTextbox = (schoolCityId) => {
        let obj = this.state.schoolCitiesData.find(o => o.schoolCityId === schoolCityId);
        this.setState({ schoolCityId: obj.schoolCityId, schoolCityName: obj.schoolCityName, schoolCityNameState: "success", active: obj.active, saveUpdateBtnDisabled: false, saveUpdateBtnText: 'Update', clearTextBtn: 'block' });
    }
    ClearTextfieldStates = () => {
        this.setState({
            alert: null, schoolCityId: 0, schoolCityName: "", schoolCityNameState: "", active: false, saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
        });
        this.loadSchoolCities();
    }

    render() {
        const { classes } = this.props;
        return (
            <GridContainer>
                {this.state.alert}
                <div className={classes.mainDiv}>
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <GridContainer>
                                            <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                        <label htmlFor="small-radius-switch">
                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "searchActive")}
                                                        checked={this.state.searchActive}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                            <span> Active</span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="facebook" onClick={this.loadSchoolCities} > <SearchSharp /> Load </Button>
                                    </GridTextbox>
                                </GridContainer>
                                <hr style={{ marginBottom: "3px", marginTop: "3px" }} />
                                <h4 className={classes.cardIconTitle}>Add School City</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={6} md={2}>
                                        <TextField
                                            success={this.state.schoolCityNameState === "success"}
                                            error={this.state.schoolCityNameState === "error"}
                                            value={this.state.schoolCityName}
                                            size="small"
                                            label="School City"
                                            id="schoolCityName"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            inputProps={{ onChange: event => this.change(event, "schoolCityName", "schoolCityName", 1), type: "text" }}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2} style={{ textAlign: "center" }}>
                                        <label htmlFor="small-radius-switch">
                                                <ReactToggle onChange={(event) => this.handleToggleChange(event, "active")}
                                                        checked={this.state.active}
                                                        onColor="#3b5998"
                                                        className={classes.toggleStyle}
                                                        borderRadius={4}
                                                        handleDiameter={22}
                                                        uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                        checkedIcon={<div className={classes.toggleOn}>Yes</div>
                                                        } />
                                            <span> Active</span>
                                        </label>
                                    </GridTextbox>
                                    <GridTextbox>
                                        {this.state.alert}
                                        <Button color="facebook" onClick={this.isValidated} disabled={this.state.saveUpdateBtnDisabled}> <SaveOutlined /> {this.state.saveUpdateBtnText} </Button>
                                    </GridTextbox>
                                    <GridTextbox>
                                        <Button color="success" onClick={this.ClearTextfieldStates} style={{ display: '' + this.state.clearTextBtn + '' }}> <AddIcon /> Add New </Button>
                                    </GridTextbox>
                                </GridContainer>
                            </CardBody>
                        </Card>

                    </GridForm>
                    <GridForm xs={12} sm={12} md={12}>
                        <MuiThemeProvider theme={muiWithToolbar()}>
                            <MUIDataTable
                                title={"School Cities"}
                                data={this.state.schoolCitiesData}
                                columns={this.state.columns}
                                options={tbleWithPrint}
                            />
                        </MuiThemeProvider>
                    </GridForm>
                </GridContainer>
                </CardBody>
            </div>
            </GridContainer>
        );
    }
}

export default withStyles(formStyle)(SchoolCity);