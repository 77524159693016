import React from "react";
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GroupAdd as EditOutlined, AddCircle as AddIcon, Home as HomeIcon, NavigateNext as NavigateNextIcon, TrackChanges, SearchSharp } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridForm from "components/Grid/GridForm.jsx";
import GridTextbox from "components/Grid/GridTextbox.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { MuiThemeProvider } from '@material-ui/core/styles';
import Moment from 'moment';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { formStyle, muiWithToolbar, tbleWithPrint, StyledBreadcrumb } from "assets/jss/customStyle/allCustomStyle";
var Base_URL = localStorage.BaseURL;
class ManageIssuance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manageItemdata: [],
            //#region Datatable Columns
            columns: [{
                name: "name",
                label: "Name",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (<Button color="facebook" onClick={() => this.LoadTextbox(value)}><EditOutlined /> Edit </Button>);
                    }
                }
            }],
            //#endregion

            //#region Other Variables and states
            saveUpdateBtnText: 'Save', saveUpdateBtnDisabled: false, clearTextBtn: 'none',
            alert: null, show: false, checked: false,
            //#endregion
        };
    };
    //#region Button,Validaition and Change Events
    validateData = () => {
        if (this.state.Name === "") {
            this.setState({ NameState: "error" });
            return;
        }
    }
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }
    //#endregion 

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        return (
            <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                <CardHeader color="primary" icon>
                    <CardIcon color="primary">
                        <TrackChanges />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Manage Issuance</h4>
                </CardHeader>
                {this.state.alert}
                <div className={classes.formDiv}>
                    <hr />
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                        <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                        <Link to="/app/inventory" > Inventory</Link>
                        <Typography color="textPrimary">Manage Issuance </Typography>
                    </Breadcrumbs>
                    <hr />
                    <CardBody>
                        <GridContainer>
                            <GridForm xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <form disabled={this.state.saveUpdateBtnDisabled}>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        success={startDateState === "success"}
                                                        error={startDateState === "error"}
                                                        size="small"
                                                        label="From Date"
                                                        id="startDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(startDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.setDateValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        size="small"
                                                        label="To Date"
                                                        id="endDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(endDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.setDateValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <Autocomplete
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Issuance To" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2} style={{ marginLeft: "1%" }}>
                                                    <TextField
                                                        success={this.state.NameState === "success"}
                                                        error={this.state.NameState === "error"}
                                                        size="small"
                                                        label="Issue No"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={this.state.Name}
                                                        onChange={this.textChange}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} lg={1} style={{ marginLeft: "1%" }}>
                                                    <Button color="facebook"><SearchSharp />Search </Button>
                                                </GridTextbox>
                                            </GridContainer>
                                        </form>
                                    </CardBody>
                                </Card>
                            </GridForm>
                            <GridForm xs={12} sm={12} md={12}>
                                <MuiThemeProvider theme={muiWithToolbar()}>
                                    <MUIDataTable
                                        columns={[
                                            { label: "Issue No" }, { label: "Employee" },
                                            { label: "Department" }, 
                                            { label: "Date" }, { label: "Edit" },
                                            { label: "Print" },
                                        ]}
                                        options={tbleWithPrint}
                                    />
                                </MuiThemeProvider>
                                <CardHeader >
                                    <h4><small>Number of Records: 0</small> </h4>
                                </CardHeader>
                            </GridForm>
                        </GridContainer>
                    </CardBody>
                </div>
            </Card>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(ManageIssuance);


