//Import from Globel Component";
import {
    React, axios, Moment, SweetAlert, SaveOutlined, GridItem, formStyle, withStyles, Autocomplete, TextField, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader,
    CardBody, MUIDataTable, Button, muiWithToolbar, tbleWithPrint
} from '.../../components/Common/ImportAll';

var Base_URL = localStorage.BaseURL;
class Placement extends React.Component {
    constructor(props) {
        super(props);
        const { editRegData } = this.props;
        console.log(editRegData);
        this.state = {
            hideSpecialDD: 'none',
            employeeId: parseInt(editRegData["EmployeeId"]),
            employeePlacementList: [],

            //Id stats
            campusId: "",
            campusIdIndex: 0,
            campusIdState: "",
            hrLevelId: "",
            hrLevelIdIndex: 0,
            hrLevelIdState: "",
            departmentId: "",
            departmentIdIndex: 0,
            departmentIdState: "",
            designationId: "",
            designationIdIndex: 0,
            designationIdState: "",
            specialId: "",
            specialIdIndex: 0,
            specialIdState: "",
            campusIdDD: [{ campusId: -1, campusName: "Select" }],
            hrLevelIdDD: [{ hrLevelId: -1, hrLevelName: "Select" }],
            departmentIdDD: [{ departmentId: -1, departmentName: "Select" }],
            designationIdDD: [{ designationId: -1, designationName: "Select" }],
            specialIdDD: [{ specialId: -1, specialityName: "Select" }],
            disabled: false,

            columns: [{
                name: "name",
                label: "Employee",
                options: {
                    filter: true,
                }
            }, {
                name: "campusName",
                label: "Campus",
                options: {
                    filter: true,
                }
            }, {
                name: "hrLevelName",
                label: "HR Level",
                options: {
                    filter: true,
                }
            },
            {
                name: "departmentName",
                label: "Department",
                options: {
                    filter: true,
                }
            },
            {
                name: "designationName",
                label: "Designation",
                options: {
                    filter: true,
                }
            },
            {
                name: "specialityName", label: "Speciality",
                options: {
                    customBodyRender: (value) => {
                        return (value) === null ? 'No' : (value) === "" ? 'No' : value;
                    }
                }
            },
            {
                name: "active", label: "Active",
                options: {
                    customBodyRender: (value) => {
                        return (value) === true ? 'Yes' : (value) === false ? 'No' : "";
                    }
                }
            },
            {
                name: "fromDate",
                label: "From",
                options: {
                    filter: true,
                }
            },
            {
                name: "toDate",
                label: "To",
                options: {
                    filter: true,
                }
            }
                //{
                //name: "ShiftId",
                //label: "Edit",
                //options: {
                //    customBodyRender: (value) => {
                //        return (<EditOutlined onClick={() => this.LoadTextbox(value)} />);
                //    }
                //}
                //}
            ],
            alert: null, show: false, generalError: false,
        }
    };
    async componentDidMount() {
        debugger
        this.LoadEmployeePlacement()
        this.LoadCampusesDD()
        this.loadHRLevelDD()
    }
    async LoadCampusesDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/Campuses"))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    campusIdDD: data.campusDDList
                });
                if (data.campusDDList.length > 0) {
                    this.setState({
                        campusId: data.campusDDList[0].campusId,
                        campusIdIndex: 0,
                        campusIdState: "success"
                    });
                }
                else {
                    this.setState({
                        campusId: 0,
                        campusIdIndex: -1,
                        campusIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadHRLevelDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/HRLevel"))
            if (response.ok) {
                const data = await response.json();
                if (data.hrLevelDDList.length > 0) {
                    let allList = [];
                    allList.push({ hrLevelId: -1, hrLevelName: "Select" });
                    allList = allList.concat(data.hrLevelDDList);
                    this.setState({
                        hrLevelIdDD: allList,
                        hrLevelId: allList[0]['hrLevelId'],
                        hrLevelIdIndex: 0,
                        hrLevelIdState: "success"
                    });
                }
                else {
                    this.setState({
                        hrLevelId: 0,
                        hrLevelIdIndex: -1,
                        hrLevelIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async loadSpecialityDD() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/Speciality"))
            if (response.ok) {
                const data = await response.json();
                if (data.specialityDDList.length > 0) {
                    this.setState({
                        specialIdDD: data.specialityDDList,
                        specialId: data.specialityDDList.specialId,
                        specialIdIndex: 0,
                        specialIdState: "success"
                    });
                }
                else {
                    this.setState({
                        specialId: 0,
                        specialIdIndex: -1,
                        specialIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadDepartmentDD(hrLevelId) {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/Department?hrLevelId=" + hrLevelId))
            if (response.ok) {
                const data = await response.json();
                if (data.departmentDDList.length > 0) {
                    let allList = [];
                    allList.push({ departmentId: -1, departmentName: "Select" });
                    allList = allList.concat(data.departmentDDList);
                    this.setState({
                        departmentIdDD: allList,
                        departmentId: allList[0]['departmentId'],
                        departmentIdIndex: 0,
                        departmentIdState: "success"
                    });
                }
                else {
                    this.setState({
                        departmentId: 0,
                        departmentIdIndex: -1,
                        departmentIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadDesignationDD(DepartmentId) {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/Designation?DepartmentId=" + DepartmentId))
            if (response.ok) {
                const data = await response.json();
                if (data.designationDDList.length > 0) {
                    let allList = [];
                    allList.push({ designationId: -1, designationName: "Select" });
                    allList = allList.concat(data.designationDDList);
                    this.setState({
                        designationIdDD: allList,
                        designationId: allList[0]['designationId'],
                        designationIdIndex: 0,
                        designationIdState: "success"
                    });
                }
                else {
                    this.setState({
                        designationId: 0,
                        designationIdIndex: -1,
                        designationIdState: ""
                    })
                }
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async LoadEmployeePlacement() {
        debugger
        try {
            const response = await (fetch(Base_URL + "/api/LoadEmployeeData/LoadEmployeePlacement?employeeId=" + this.state.employeeId))
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    employeePlacementList: data.placementdataList
                });
            }
            else {
                throw Error(response.statusText)
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    SaveEmployeePlacement = () => {
        debugger;
        if ((this.state.campusId === "") || (this.state.shiftId === 0) || (this.state.campusIdState === "error")) {
            this.setState({ campusIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.hrLevelId === "") || (this.state.hrLevelId === 0) || (this.state.hrLevelId === -1) || (this.state.hrLevelIdState === "error")) {
            this.setState({ hrLevelIdState: "error", generalError: true });

            return;
        }
        else if ((this.state.departmentId === "") || (this.state.departmentId === 0) || (this.state.departmentId === -1) || (this.state.departmentIdState === "error")) {
            this.setState({ departmentIdState: "error", generalError: true });
            return;
        }
        else if ((this.state.designationId === "") || (this.state.designationId === 0) || (this.state.designationId === -1) || (this.state.designationIdState === "error")) {
            this.setState({ designationIdState: "error", generalError: true });
            return;
        }
        if (this.state.specialId === "" || (this.state.specialId === 0) || (this.state.specialId === -1)) {
            this.setState({ specialId: null });
        }
        let EmployeePlacementInfo = {
            EmployeeId: this.state.employeeId,
            CampusId: this.state.campusId,
            HrLevelId: this.state.hrLevelId,
            DepartmentId: this.state.departmentId,
            DesignationId: this.state.designationId,
            SpecialityId: this.state.specialId,
        };
        console.log(EmployeePlacementInfo);
        axios.post(Base_URL + '/api/EditNewEmployee/SaveEmployeePlacement', EmployeePlacementInfo)
            .then(json => {
                this.AlertMessage(json.data.status, json.data.message)
            })
    }
    AlertMessage(status, msg) {
        debugger

        if (status === 'Success') {
            this.setState({
                alert: (
                    <SweetAlert
                        success
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.ClearTextfieldStates()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                )
            });
        }
        else {
            this.setState({
                alert: (
                    <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-200px" }}
                        title={status}
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        closeOnClickOutside={false}
                    >{msg}</SweetAlert>
                ), saveUpdateBtnDisabled: false,
            });

        }
    }
    hideAlert() {
        this.setState({
            alert: null,

        });
        this.LoadEmployeePlacement();
        this.LoadCampusesDD();
        this.loadHRLevelDD();
    }
    ClearTextfieldStates = () => {
        debugger;
        this.hideAlert()
    }
    change(event, stateName, type, value, stateNameEqualTo, maxValue) {
        debugger
        switch (type) {
            case "shiftId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.shiftIdDD.findIndex(x => x[stateName] === value[stateName]),
                    shiftId: value.shiftId,
                    [stateName + "State"]: "success"
                })
                break;
            case "campusId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.campusIdDD.findIndex(x => x[stateName] === value[stateName]),
                    campusId: value.campusId,
                    [stateName + "State"]: "success"
                })
                break;
            case "hrLevelId":
                debugger
                if (value !== null) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.hrLevelIdDD.findIndex(x => x[stateName] === value[stateName]),
                        hrLevelId: value.hrLevelId,
                        [stateName + "State"]: "success",
                        designationId: 0,
                        designationIdIndex: -1,
                    })
                    this.LoadDepartmentDD(value.hrLevelId);

                }
                break;
            case "departmentId":
                debugger
                if (value !== null) {
                    this.setState({
                        stateName: value[stateName],
                        [stateName + "Index"]: this.state.departmentIdDD.findIndex(x => x[stateName] === value[stateName]),
                        departmentId: value.departmentId,
                        [stateName + "State"]: "success"
                    })
                    this.LoadDesignationDD(value.departmentId);
                    let getDepartmentName = this.state.departmentIdDD.find(x => x[stateName] === value[stateName]).departmentName;
                    if (getDepartmentName === 'Faculty' || getDepartmentName === 'faculty') {
                        this.loadSpecialityDD();
                        this.setState({
                            hideSpecialDD: 'block'
                        });
                    }
                    else {
                        this.setState({
                            specialId: 0,
                            specialIdIndex: -1,
                            hideSpecialDD: 'none'
                        })
                    }
                }
                break;
            case "designationId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.designationIdDD.findIndex(x => x[stateName] === value[stateName]),
                    designationId: value.designationId,
                    [stateName + "State"]: "success"
                })
                break;
            case "specialId":
                debugger
                this.setState({
                    stateName: value[stateName],
                    [stateName + "Index"]: this.state.specialIdDD.findIndex(x => x[stateName] === value[stateName]),
                    specialId: value.specialId,
                    [stateName + "State"]: "success"
                })
                break;
        }
    }
    render() {
        const { classes } = this.props;
        const { employeePlacementList } = this.state;
        return (
            <div style={{ paddingBottom: "0px", paddingRight: "10px", paddingLeft: "10px" }}>
                <GridContainer>
                    <GridForm xs={12} sm={12} md={12}>
                        {this.state.alert}
                        <Card style={{ marginTop: "10px", marginBottom: "0px" }}>
                            <CardHeader color="info" icon>
                                <h4 className={classes.cardIconTitle}>Employee Placement</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.campusIdDD}
                                            getOptionLabel={(option) => option.campusName}
                                            id="campusId"
                                            value={this.state.campusIdDD[this.state.campusIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "campusId", "campusId", value)}
                                            renderInput={(params) => <TextField {...params} label=" Campus*" variant="outlined"
                                                success={this.state.campusIdState === "success"}
                                                error={this.state.campusIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.hrLevelIdDD}
                                            getOptionLabel={(option) => option.hrLevelName}
                                            id="hrLevelId"
                                            value={this.state.hrLevelIdDD[this.state.hrLevelIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "hrLevelId", "hrLevelId", value)}
                                            renderInput={(params) => <TextField {...params} label=" HR Level*" variant="outlined"
                                                success={this.state.hrLevelIdState === "success"}
                                                error={this.state.hrLevelIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.departmentIdDD}
                                            getOptionLabel={(option) => option.departmentName}
                                            id="departmentId"
                                            value={this.state.departmentIdDD[this.state.departmentIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "departmentId", "departmentId", value)}
                                            renderInput={(params) => <TextField {...params} label=" Department*" variant="outlined"
                                                success={this.state.departmentIdState === "success"}
                                                error={this.state.departmentIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.designationIdDD}
                                            getOptionLabel={(option) => option.designationName}
                                            id="designationId"
                                            value={this.state.designationIdDD[this.state.designationIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "designationId", "designationId", value)}
                                            renderInput={(params) => <TextField {...params} label=" Designation*" variant="outlined"
                                                success={this.state.designationIdState === "success"}
                                                error={this.state.designationIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2} style={{ display: '' + this.state.hideSpecialDD + '' }}>
                                        <Autocomplete
                                            size="small"
                                            options={this.state.specialIdDD}
                                            getOptionLabel={(option) => option.specialityName}
                                            id="specialId"
                                            value={this.state.specialIdDD[this.state.specialIdIndex]}
                                            disableClearable={true}
                                            onChange={(event, value) => this.change(event, "specialId", "specialId", value)}
                                            renderInput={(params) => <TextField {...params} label=" Speciality*" variant="outlined"
                                                success={this.state.specialIdState === "success"}
                                                error={this.state.specialIdState === "error"} />}
                                        />
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={2}>
                                        <Button color="facebook" onClick={() => { this.SaveEmployeePlacement() }} disabled={this.state.saveUpdateBtnDisabled}><SaveOutlined /> Save </Button>
                                    </GridTextbox>
                                </GridContainer>
                                <GridContainer >
                                </GridContainer>
                            </CardBody>
                            <MuiThemeProvider theme={muiWithToolbar()}>
                                <MUIDataTable
                                    title={"Employee Placement Details"}
                                    data={employeePlacementList}
                                    columns={this.state.columns}
                                    options={tbleWithPrint}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </GridForm>
                </GridContainer>
            </div>
        );
    }
}

export default withStyles(formStyle)(Placement);