
//Import from Globel Component";
import {
    React, LazyLoad, axios, Moment, Link, SweetAlert, ReactToggle, withStyles, Typography, InputLabel, FormControl, Select, Autocomplete,
    TextField, Breadcrumbs, MuiThemeProvider, GridContainer, GridForm, GridTextbox, Card, CardHeader, CardIcon, CardBody, MUIDataTable,
    Button, formStyle, muiWithToolbar, tbleWithPrint, ParentIcon, NavigateNextIcon, SaveOutlined, StdIcon, RegIcon, HomeIcon, AcdamicSession
    , CampusesDD, ClassesDD, ageCalculate, validateCheckSetVal, SelectFilterDisplay, Suspense, PeopleAlt, SearchSharp, EditOutlined, FileCopy
    , SearchIcon, AlternateEmailIcon, TextsmsIcon, LibraryAddOutlined, Edit, AllDropDowns
} from '../../components/Common/ImportAll';
var Base_URL = localStorage.BaseURL;


class OtherHeadCharges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //#region Other Variables and states
            status: true,
            month: true,
        };
        //#endregion
    }
    handleToggleChange = (event, checkName) => {
        this.setState({ [checkName]: event });
    }
    onEditorStateChang = (editorState) => {
        this.setState({
            editorState,
        });
    };
    setDateValue = (event) => {
        this.setState({ [event.target.id + "State"]: "success", [event.target.id]: event.target.value });
        if (event.target.id === 'startDate') {
            var d2 = Moment(this.state.endDate).format('YYYY');
            var d1 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
        if (event.target.id === 'endDate') {
            var d1 = Moment(this.state.startDate).format('YYYY');
            var d2 = Moment(event.target.value).format('YYYY');
            if (new Date(d1) > new Date(d2)) {
                this.SuccessMessage('Validation', 'Start Date must be less then end Date.!')
                this.setState({ startDateState: "error" });
                return;
            }
            else {
                this.setState({ sessionName: d1 + '-' + d2 })
            }
        }
    }

    //#region Form Body and Return
    render() {
        const { classes } = this.props;
        const { startDate, startDateState, endDate } = this.state
        const fillButtons = [
            { color: "facebook", icon: Edit, name: "Edit" }
        ].map((prop, key) => {
            return (
                <Button color={prop.color} className={classes.actionButton} key={key}>
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
        return (
            <div>
                <Card style={{ marginTop: "0px", marginBottom: "0px" }}>
                    <CardHeader color="info" icon>
                        <CardIcon color="info">
                            <LibraryAddOutlined />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Other Head Charges</h4>
                    </CardHeader>
                    <div className={classes.formDiv}>
                        <hr />
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumStyle}>
                            <Link to="/app/dashboard" ><HomeIcon style={{ marginBottom: "-5px" }} /> Dashboard</Link>
                            <Link to="/app/accounts" > Accounts</Link>
                            <Typography color="textPrimary">Other Head Charges</Typography>
                        </Breadcrumbs>
                        <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                    </div>
                    <div style={{ paddingBottom: "0px", paddingRight: "7px", paddingLeft: "7px" }}>
                        <CardBody>
                            <GridContainer>
                                <GridForm xs={12} sm={12} md={12}>
                                    <Card style={{ marginTop: "0px", marginBottom: "10px" }}>
                                        <CardHeader>
                                            <h4 className={classes.cardIconTitle}>Other Head Charge</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <GridContainer>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Session" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Sub Campus" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Class" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Section" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Button color="facebook"><SaveOutlined />Search</Button>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        success={startDateState === "success"}
                                                        error={startDateState === "error"}
                                                        size="small"
                                                        label="Challan Month"
                                                        id="startDate"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={Moment(startDate).format('YYYY-MM-DD')}
                                                        type="date"
                                                        onChange={this.setDateValue}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <Autocomplete
                                                        id="Department"
                                                        size="small"
                                                        renderInput={(params) => <TextField {...params} label="Fee Heads" variant="outlined" />}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={2}>
                                                    <TextField
                                                        size="small"
                                                        label="Fee Amount"
                                                        id="EmpIdName"
                                                        variant="outlined"
                                                        type="text"
                                                        fullWidth
                                                        inputProps={{ onChange: event => this.change(event, "RecievedFrom", "text") }}
                                                    />
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1} color="facebook">
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "status")}
                                                            checked={this.state.status}
                                                            onColor="#3b5998"
                                                            color="facebook"
                                                            className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                        />
                                                        <span>Status</span>
                                                    </label>
                                                </GridTextbox>
                                                <GridTextbox xs={12} sm={12} md={1} color="facebook">
                                                    <label htmlFor="small-radius-switch">
                                                        <ReactToggle onChange={(event) => this.handleToggleChange(event, "month")}
                                                            checked={this.state.month}
                                                            onColor="#3b5998"
                                                            color="facebook"
                                                            className={classes.toggleStyle}
                                                            borderRadius={4}
                                                            handleDiameter={22}
                                                            uncheckedIcon={<div className={classes.toggleOff}>No</div>}
                                                            checkedIcon={<div className={classes.toggleOn}>Yes</div>}
                                                        />
                                                        <span>Month</span>
                                                    </label>
                                                </GridTextbox>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </GridForm>
                                <GridForm xs={12} sm={12} md={12}>
                                    <MuiThemeProvider theme={muiWithToolbar()}>
                                        <MUIDataTable
                                            title={"Student Other Head Charges Detail"}
                                            columns={[
                                                { label: "Select" }, { label: "Student Name" },
                                                { label: "Registration No" }, { label: "Session" },
                                                { label: "Class" }, { label: "Section" },
                                                { label: "Campus" }, { label: "Fee Amount" },
                                            ]}
                                            options={tbleWithPrint}
                                        />
                                    </MuiThemeProvider>
                                    <GridTextbox xs={12} sm={12} md={4} style={{ marginLeft: "40%" }}>
                                        <Button color="facebook">Save</Button>
                                        <Button color="facebook">Post</Button>
                                    </GridTextbox>
                                    <GridTextbox xs={12} sm={12} md={4} style={{ marginTop: "0px" }}>
                                        <h4><small>Select Records: 0</small></h4>
                                        <h4><small>Total Records: 0</small></h4>
                                    </GridTextbox>
                                </GridForm>
                            </GridContainer>
                        </CardBody>
                    </div>
                </Card>
            </div>
        );
    }
    //#endregion
}
export default withStyles(formStyle)(OtherHeadCharges);